/*eslint-disable*/
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp, faLock } from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";
import PropTypes from "prop-types";
import { NavLink, useLocation } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import styles from "assets/jss/material-dashboard-react/components/sidebarStyle.js";
import { Collapse, useMediaQuery, useTheme } from "@mui/material";

const useStyles = makeStyles(styles);

export default function Sidebar(props) {
  const classes = useStyles();
  let location = useLocation();
  const theme = useTheme();
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("custom"));

  function activeRoute(key, pageName) {
    const isActiveRoute = location.pathname.includes(key);
    if (isActiveRoute) {
      document.title = `Kairum | ${pageName}`;
    }
    return isActiveRoute;
  }

  const [openIndex, setOpenIndex] = React.useState(-1);

  const handleClick = (index) => {
    if (index === openIndex) {
      setOpenIndex(-1);
    } else {
      setOpenIndex(index);
    }
  };

  const renderNavLink = (key, prop, itemLink, whiteFontClasses, listItemClasses) => {
    return (
      !prop.locked && (
        <NavLink
          to={prop.layout + prop.path}
          className={classes.item}
          activeClassName="active"
          key={key}
        >
          <ListItem button className={itemLink + listItemClasses}>
            {/* <prop.icon className={classNames(classes.itemIcon, whiteFontClasses)} /> */}
            <FontAwesomeIcon icon={prop.icon} className={classes.itemIcon} />
            <ListItemText
              primary={prop.name}
              className={classNames(classes.itemText, whiteFontClasses)}
              disableTypography={true}
            />
            {/*{prop.locked && (*/}
            {/*  <FontAwesomeIcon*/}
            {/*    icon={faLock}*/}
            {/*    className={classes.itemIcon}*/}
            {/*    title="Seu usuário não possui acesso para esse módulo. Entre em contato com o suporte!"*/}
            {/*  />*/}
            {/*)}*/}
          </ListItem>
        </NavLink>
      )
    );
  };

  const { color, logo, image, logoText, routes } = props;
  var links = (
    <List className={classes.list}>
      {routes.map((prop, key) => {
        const { layout, path, menus } = prop;
        const url = `${layout}${path}`;

        var listItemClasses;

        listItemClasses = classNames({
          [" " + classes[color]]: activeRoute(prop.layout + "/" + prop.key, prop.name),
        });

        let whiteFontClasses = classNames({
          [" " + classes.whiteFont]: activeRoute(prop.layout + prop.path, prop.name),
        });

        if (menus && menus.filter((menu) => !menu.locked).length > 0) {
          return (
            <>
              <ListItem
                onClick={() => handleClick(key)}
                button
                className={classes.itemLink /* + listItemClassesParent */}
              >
                {/* <prop.icon className={classNames(classes.itemIcon, whiteFontClasses)} /> */}
                <FontAwesomeIcon icon={prop.icon} className={classes.itemIcon} />
                <ListItemText
                  primary={prop.name}
                  className={classNames(classes.itemText, whiteFontClasses)}
                  disableTypography={true}
                />
                {openIndex === key ? (
                  <FontAwesomeIcon icon={faAngleUp} fontSize={isMediumScreen ? 14 : 20} color="#ffff" />
                ) : (
                  <FontAwesomeIcon icon={faAngleDown} fontSize={isMediumScreen ? 14 : 20} color="#ffff" />
                )}
              </ListItem>
              <Collapse in={openIndex === key} timeout="auto" unmountOnExit>
                {menus.map((prop, key) => {
                  // if (prop.hideOnMenu) return;
                  listItemClasses = classNames({
                    [" " + classes[color]]: activeRoute(prop.layout + "/" + prop.key, prop.name),
                  });

                  whiteFontClasses = classNames({
                    [" " + classes.whiteFont]: activeRoute(prop.layout + prop.path, prop.name),
                  });

                  return renderNavLink(
                    key,
                    prop,
                    classes.itemSubLink,
                    whiteFontClasses,
                    listItemClasses
                  );
                })}
              </Collapse>
            </>
          );
        }
        return renderNavLink(key, prop, classes.itemLink, whiteFontClasses, listItemClasses);
      })}
    </List>
  );
  var brand = (
    <div className={classes.logo}>
      <span
        className={classNames(classes.logoLink, {
          [classes.logoLinkRTL]: props.rtlActive,
        })}
        style={{ maxWidth: "170px", textOverflow: "ellipsis", overflow: "hidden" }}
      >
        {logoText}
      </span>
    </div>
  );

  return (
    <div>
      <Drawer
        // variant={props.open ? "permanent" : "temporary"}
        variant="persistent"
        anchor="left"
        transitionDuration={{ enter: 1300, exit: 600 }}
        // hideBackdrop
        open={props.open}
        classes={{ paper: classes.drawerPaper }}
        onClose={props.handleDrawerToggle}
        //If mobile hides the menu when clicks
        // onClick={isMobile() ? props.handleDrawerToggle : null}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
      >
        {brand}
        <div className={classes.sidebarWrapper}>{links}</div>
        {image !== undefined ? (
          <div className={classes.background} style={{ backgroundImage: "url(" + image + ")" }} />
        ) : null}
      </Drawer>
    </div>
  );
}

Sidebar.propTypes = {
  rtlActive: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
  bgColor: PropTypes.oneOf(["purple", "blue", "green", "orange", "red"]),
  logo: PropTypes.string,
  image: PropTypes.string,
  logoText: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object),
  open: PropTypes.bool,
};
