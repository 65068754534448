import moment from "moment";
import { removeMask } from "../../../config/util";
import { NotaFiscal } from "../model/NotaFiscal";

const toRequest = ({
  entity,
  dadosGerais,
  dadosDestinatario,
  dadosEnderecoEntrega,
  dadosTransporte,
  dadosRetencaoIcmsTransporte,
  dadosVolume,
  dadosFormaPagamentoList,
  dadosNotaFiscalProdutos,
}) => {
  const { id, version, active, status, informacoesComplementares } = entity;

  let newDadosDestinatario = {};

  // dados destinatario
  const {
    cidadeDestinatario,
    telefoneDestinatario,
    cepDestinatario,
    documentoDestinatario,
  } = dadosDestinatario;

  const newCidadeDestinatario = cidadeDestinatario ? { id: cidadeDestinatario } : null;

  newDadosDestinatario = {
    ...dadosDestinatario,
    cidadeDestinatario: newCidadeDestinatario,
    telefoneDestinatario: removeMask(telefoneDestinatario),
    cepDestinatario: removeMask(cepDestinatario),
    documentoDestinatario: removeMask(documentoDestinatario),
  };

  // dados endereco retirada
  const {
    cepEnderecoEntrega,
    documentoEnderecoEntrega,
    cidadeEnderecoEntrega,
  } = dadosEnderecoEntrega;
  const newCidadeEnderecoEntrega = cidadeEnderecoEntrega ? { id: cidadeEnderecoEntrega } : null;

  const newDadosEnderecoEntrega = {
    ...dadosEnderecoEntrega,
    cidadeEnderecoEntrega: newCidadeEnderecoEntrega,
    cepEnderecoEntrega: removeMask(cepEnderecoEntrega),
    documentoEnderecoEntrega: removeMask(documentoEnderecoEntrega),
  };

  // dados transporte
  const { documentoTransporte, cidadeTransporte, totalFrete } = dadosTransporte;

  const newCidadeTransporte = cidadeTransporte ? { id: cidadeTransporte } : null;

  const newDadosTransporte = {
    ...dadosTransporte,
    documentoTransporte: removeMask(documentoTransporte),
    cidadeTransporte: newCidadeTransporte,
  };

  // dados retencao icms transporte
  const { municipioIcmsRetencaoIcmsTransporte } = dadosRetencaoIcmsTransporte;
  const newMunicipioIcmsRetencaoIcmsTransporte = municipioIcmsRetencaoIcmsTransporte
    ? { id: municipioIcmsRetencaoIcmsTransporte }
    : null;

  const newDadosRetencaoIcmsTransporte = {
    ...dadosRetencaoIcmsTransporte,
    municipioIcmsRetencaoIcmsTransporte: newMunicipioIcmsRetencaoIcmsTransporte,
  };

  const notaFiscalFormaPagamentos = dadosFormaPagamentoList.map((item) => {
    const { vencimento } = item;
    return {
      ...item,
      vencimento: vencimento !== null ? moment(vencimento).format("YYYY-MM-DD") : null,
    };
  });

  return {
    id,
    ...dadosGerais,
    ...newDadosDestinatario,
    ...newDadosEnderecoEntrega,
    ...newDadosTransporte,
    ...newDadosRetencaoIcmsTransporte,
    ...dadosVolume,
    notaFiscalFormaPagamentos,
    notaFiscalProdutos: dadosNotaFiscalProdutos,
    informacoesComplementares,
    version,
    status,
    active,
    totalFrete,
  };
};

// eslint-disable-next-line arrow-body-style
const fromResponse = (response) => {
  if (response === null) return new NotaFiscal();

  const {
    id,
    // dados gerais
    numero,
    naturezaOperacao,
    tipo,
    modelo,
    operacaoConsumidorFinal,
    finalidade,
    tipoEmissao,
    identificadorLocalDestinoOperacao,
    indicadorPresencaComprador,
    chaveNfeReferenciada,
    desconto,
    // dados destinatario
    cliente,
    tipoDocumentoDestinatario,
    documentoDestinatario,
    nomeDestinatario,
    indicadorIeDestinatario,
    inscricaoEstadualDestinatario,
    inscricaoSuframaDestinatario,
    inscricaoMunicipalDestinatario,
    cepDestinatario,
    logradouroDestinatario,
    numeroDestinatario,
    complementoDestinatario,
    bairroDestinatario,
    cidadeDestinatario,
    telefoneDestinatario,
    emailDestinatario,
    // dados emitente
    tipoDocumentoEmitente,
    documentoEmitente,
    nomeEmitente,
    indicadorIeEmitente,
    inscricaoEstadualEmitente,
    inscricaoMunicipalEmitente,
    cepEmitente,
    logradouroEmitente,
    numeroEmitente,
    complementoEmitente,
    bairroEmitente,
    cidadeEmitente,
    telefoneEmitente,

    possuiEnderecoEntrega,
    // dados end entrega
    tipoDocumentoEnderecoEntrega,
    documentoEnderecoEntrega,
    cepEnderecoEntrega,
    logradouroEnderecoEntrega,
    numeroEnderecoEntrega,
    complementoEnderecoEntrega,
    bairroEnderecoEntrega,
    cidadeEnderecoEntrega,
    // forma pagamento
    notaFiscalFormaPagamentos,
    // dados volume
    quantidadeVolume,
    pesoLiquidoVolume,
    pesoBrutoVolume,
    especieVolume,
    marcaVolume,
    numeracaoVolume,
    // dados transporte
    modalidadeFreteTransporte,
    transportadoraTransporte,
    tipoDocumentoTransporte,
    inscricaoEstadualTransporte,
    enderecoCompletoTransporte,
    placaVeiculoTransporte,
    registroNacionalTransporteCargaTransporte,
    totalFrete,
    possuiRetencaoIcmsTransporte,
    razaoSocialTransporte,
    documentoTransporte,
    ufVeiculoTransporte,
    cidadeTransporte,
    // dados retencao icms transporte
    valorServicoRetencaoIcmsTransporte,
    bcRetencaoIcmsTransporte,
    aliquotaRetencaoIcmsTransporte,
    valorIcmsRetidoRetencaoIcmsTransporte,
    cfopRetencaoIcmsTransporte,
    municipioIcmsRetencaoIcmsTransporte,
    // dadosNotaFiscalProdutos
    notaFiscalImportada,
    notaFiscalProdutos,
    notaFiscalHistoricoList,
    version,
    status,
    active,
    //
    fornecedor,
    despesaImportada,
    informacoesComplementares,
  } = response;

  const dadosGerais = {
    numero,
    naturezaOperacao,
    tipo,
    modelo,
    operacaoConsumidorFinal,
    finalidade,
    tipoEmissao,
    identificadorLocalDestinoOperacao,
    indicadorPresencaComprador,
    chaveNfeReferenciada,
    desconto,
  };

  let cidadeDestinatarioId = null;
  let stateDestinatarioId = null;
  if (cidadeDestinatario != null) {
    cidadeDestinatarioId = cidadeDestinatario.id;
    stateDestinatarioId = cidadeDestinatario.state.id;
  }
  const dadosDestinatario = {
    cliente,
    tipoDocumentoDestinatario,
    documentoDestinatario,
    nomeDestinatario,
    indicadorIeDestinatario,
    inscricaoEstadualDestinatario,
    inscricaoSuframaDestinatario,
    inscricaoMunicipalDestinatario,
    cepDestinatario,
    logradouroDestinatario,
    numeroDestinatario,
    complementoDestinatario,
    bairroDestinatario,
    cidadeDestinatario: cidadeDestinatarioId,
    state: stateDestinatarioId,
    telefoneDestinatario,
    emailDestinatario,
    possuiEnderecoEntrega,
  };

  let cidadeEmitenteId = null;
  let stateEmitenteId = null;
  if (cidadeEmitente != null) {
    cidadeEmitenteId = cidadeEmitente.id;
    stateEmitenteId = cidadeEmitente.state.id;
  }

  const dadosEmitente = {
    cliente,
    tipoDocumentoEmitente,
    documentoEmitente,
    nomeEmitente,
    indicadorIeEmitente,
    inscricaoEstadualEmitente,
    inscricaoMunicipalEmitente,
    cepEmitente,
    logradouroEmitente,
    numeroEmitente,
    complementoEmitente,
    bairroEmitente,
    cidadeEmitente: cidadeEmitenteId,
    state: stateEmitenteId,
    telefoneEmitente,
    possuiEnderecoEntrega,
  };

  let cidadeEnderecoEntregaId = null;
  let stateEnderecoEntregaId = null;
  if (cidadeEnderecoEntrega != null) {
    cidadeEnderecoEntregaId = cidadeEnderecoEntrega.id;
    stateEnderecoEntregaId = cidadeEnderecoEntrega.state.id;
  }
  const dadosEnderecoEntrega = {
    tipoDocumentoEnderecoEntrega,
    documentoEnderecoEntrega,
    cepEnderecoEntrega,
    logradouroEnderecoEntrega,
    numeroEnderecoEntrega,
    complementoEnderecoEntrega,
    bairroEnderecoEntrega,
    cidadeEnderecoEntrega: cidadeEnderecoEntregaId,
    state: stateEnderecoEntregaId,
  };

  const dadosFormaPagamentoList = notaFiscalFormaPagamentos.map((item, index) => ({
    ...item,
    index,
  }));

  const notaFiscalProdutosList = notaFiscalProdutos.map((item, index) => ({
    ...item,
    index,
  }));

  const dadosVolume = {
    quantidadeVolume,
    pesoLiquidoVolume,
    pesoBrutoVolume,
    especieVolume,
    marcaVolume,
    numeracaoVolume,
  };

  let cidadeTransporteId = null;
  let stateTransporteId = null;
  if (cidadeTransporte != null) {
    cidadeTransporteId = cidadeTransporte.id;
    stateTransporteId = cidadeTransporte.state.id;
  }

  const dadosTransporte = {
    modalidadeFreteTransporte,
    transportadoraTransporte,
    tipoDocumentoTransporte,
    inscricaoEstadualTransporte,
    enderecoCompletoTransporte,
    placaVeiculoTransporte,
    registroNacionalTransporteCargaTransporte,
    totalFrete,
    possuiRetencaoIcmsTransporte,
    razaoSocialTransporte,
    documentoTransporte,
    ufVeiculoTransporte,
    cidadeTransporte: cidadeTransporteId,
    stateTransporte: stateTransporteId,
  };

  let municipioIcmsRetencaoIcmsTransporteId = null;
  let stateIcmsRetencaoIcmsTransporteId = null;
  if (municipioIcmsRetencaoIcmsTransporte != null) {
    municipioIcmsRetencaoIcmsTransporteId = municipioIcmsRetencaoIcmsTransporte.id;
    stateIcmsRetencaoIcmsTransporteId = municipioIcmsRetencaoIcmsTransporte.state.id;
  }

  const dadosRetencaoIcmsTransporte = {
    valorServicoRetencaoIcmsTransporte,
    bcRetencaoIcmsTransporte,
    aliquotaRetencaoIcmsTransporte,
    valorIcmsRetidoRetencaoIcmsTransporte,
    cfopRetencaoIcmsTransporte,
    municipioIcmsRetencaoIcmsTransporte: municipioIcmsRetencaoIcmsTransporteId,
    state: stateIcmsRetencaoIcmsTransporteId,
  };

  return new NotaFiscal({
    id,
    dadosGerais,
    dadosDestinatario,
    dadosEmitente,
    dadosEnderecoEntrega,
    notaFiscalFormaPagamentos: dadosFormaPagamentoList,
    notaFiscalProdutos: notaFiscalProdutosList,
    notaFiscalHistoricoList,
    notaFiscalImportada,
    dadosVolume,
    dadosTransporte,
    dadosRetencaoIcmsTransporte,
    version,
    status,
    active,
    //
    fornecedor,
    despesaImportada,
    informacoesComplementares,
  });
};

export { toRequest, fromResponse };
