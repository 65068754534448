/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useEffect, useReducer } from "react";
import { makeStyles } from "@mui/styles";
import CustomComponentsStyle from "assets/jss/material-dashboard-react/components/customComponentsStyle";
import CardStyle from "assets/jss/material-dashboard-react/components/cardStyle";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import Button from "components/CustomButtons/Button";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import CardFooter from "components/Card/CardFooter";
import { useHistory, useParams } from "react-router-dom";
import {
  Box,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { dangerColor } from "assets/jss/material-dashboard-react";
import { PaymentMethod, NFIndicadorFormaPagamento, NFMeioPagamento } from "./model/PaymentMethod";
import { toRequest, fromResponse } from "./converter/PaymentMethodConverter";
import PaymentMethodService from "./service/PaymentMethodService";
import { handlingResponseErrors } from "../../config/util";
import MESSAGES from "../../config/messages";
import Loading from "../../components/loading/Loading";
import TextInput from "../../components/textInput/TextInput";
import reducer from "./reducer/reducer";

const { generalMessages, paymentMethodMessages } = MESSAGES;

const useCardStyle = makeStyles(CardStyle);

export default function SupplierForm() {
  const useCustomComponentsStyle = makeStyles(CustomComponentsStyle);
  const classesCustomComponentsStyle = useCustomComponentsStyle();
  const classesCardStyle = useCardStyle();
  const history = useHistory();
  const routerParams = useParams();
  const { enqueueSnackbar } = useSnackbar();

  const initialState = { item: new PaymentMethod({ active: true }), errors: {}, loading: false };
  const [state, dispatch] = useReducer(reducer, initialState);
  const { item, errors, loading } = state;

  const fetchById = useCallback(async () => {
    try {
      dispatch({ type: "setLoading", value: true });
      const { id } = routerParams || {};
      if (id != null) {
        const result = await PaymentMethodService.fetchById(id);
        const response = fromResponse(result?.data?.data);
        dispatch({ type: "setItem", value: response });
      }
    } finally {
      dispatch({ type: "setLoading", value: false });
    }
  }, [routerParams]);

  useEffect(() => {
    fetchById();
  }, [fetchById]);

  const goBack = useCallback(() => {
    history.push("/app/payment-methods");
  }, [history]);

  const onSave = useCallback(async () => {
    try {
      const { item: itemState } = state;
      dispatch({ type: "setLoading", value: true });
      const { id } = itemState;
      if (id != null) {
        await PaymentMethodService.update(id, toRequest(itemState));
      } else {
        await PaymentMethodService.save(toRequest(itemState));
      }
      enqueueSnackbar(generalMessages.saveSuccess, {
        variant: "success",
        autoHideDuration: 3000,
      });
      goBack();
    } catch (error) {
      const { message, validationErrors } = handlingResponseErrors(error);
      enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
      if (validationErrors != null) {
        dispatch({ type: "setErrors", value: validationErrors });
      }
    } finally {
      dispatch({ type: "setLoading", value: false });
    }
  }, [state, goBack, enqueueSnackbar]);

  const renderNFIndicadorFormaPagamento = () => (
    <GridItem xs={12} sm={12} md={6}>
      <FormControl
        fullWidth
        className={classesCustomComponentsStyle.formControlSelect}
        size="small"
        variant="outlined"
        required
      >
        <InputLabel>Forma de pagamento</InputLabel>
        <Select
          label="Forma de pagamento"
          id="nfFormOfPayment"
          autoWidth
          value={item.nfFormOfPayment == null ? "" : item.nfFormOfPayment}
          onChange={(event) => {
            const {
              target: { value },
            } = event;
            return dispatch({
              type: "handleChange",
              field: "nfFormOfPayment",
              value,
            });
          }}
        >
          {Object.keys(NFIndicadorFormaPagamento).map((key) => {
            const { code, name } = NFIndicadorFormaPagamento[key];
            return (
              <MenuItem key={code} value={code}>
                {name}
              </MenuItem>
            );
          })}
        </Select>
        <FormHelperText style={{ color: dangerColor[0] }}>{errors.nfFormOfPayment}</FormHelperText>
      </FormControl>
    </GridItem>
  );

  const renderNFMeioPagamento = () => (
    <GridItem xs={12} sm={12} md={6}>
      <FormControl
        fullWidth
        className={classesCustomComponentsStyle.formControlSelect}
        size="small"
        variant="outlined"
        required
      >
        <InputLabel>Meio de pagamento</InputLabel>
        <Select
          label="Meio de pagamento"
          id="nfPaymentMethod"
          autoWidth
          value={item.nfPaymentMethod == null ? "" : item.nfPaymentMethod}
          onChange={(event) => {
            const {
              target: { value },
            } = event;
            return dispatch({
              type: "handleChange",
              field: "nfPaymentMethod",
              value,
            });
          }}
        >
          {Object.keys(NFMeioPagamento).map((key) => {
            const { code, name } = NFMeioPagamento[key];
            return (
              <MenuItem key={code} value={code}>
                {name}
              </MenuItem>
            );
          })}
        </Select>
        <FormHelperText style={{ color: dangerColor[0] }}>{errors.nfPaymentMethod}</FormHelperText>
      </FormControl>
    </GridItem>
  );

  const renderSwitch = (actionType, value = false, field, label, disabled = false) => (
    <FormGroup style={{ paddingTop: "8px" }}>
      <FormControlLabel
        control={
          <Switch
            color="secondary"
            checked={value}
            onChange={(event) =>
              dispatch({
                type: actionType,
                field,
                value: event.target.checked,
              })
            }
          />
        }
        label={label}
        disabled={disabled}
      />
    </FormGroup>
  );

  return (
    <>
      <GridContainer style={{ justifyContent: "center" }}>
        <GridItem xs={12} sm={12} md={10}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classesCardStyle.cardTitle}>{paymentMethodMessages.payment}</h4>
              <p className={classesCardStyle.cardCategory}>{paymentMethodMessages.paymentForm}</p>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <TextInput
                    autoFocus
                    id="name"
                    label={paymentMethodMessages.name}
                    value={item.name}
                    onChange={(field, value) => dispatch({ type: "handleChange", field, value })}
                    required
                    errors={errors}
                  />
                </GridItem>

                <Divider style={{ width: "100%", marginBottom: 4 }} />

                <GridItem xs={12} sm={12} md={4}>
                  {renderSwitch(
                    "handleChangeCreditCard",
                    item.creditCard,
                    "creditCard",
                    paymentMethodMessages.creditCard
                  )}
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  {renderSwitch(
                    "handleChangeAnticipatedDays",
                    item.anticipatedReceipt,
                    "anticipatedReceipt",
                    paymentMethodMessages.anticipatedReceipt,
                    !item.creditCard
                  )}
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <TextInput
                    disabled={!item.creditCard || !item.anticipatedReceipt}
                    id="anticipatedDays"
                    label={paymentMethodMessages.amountDays}
                    value={item.anticipatedDays}
                    onChange={(field, value) =>
                      dispatch({ type: "handleChange", field: "anticipatedDays", value })
                    }
                    required
                    errors={errors}
                    type="number"
                  />
                </GridItem>

                <GridItem xs={12} sm={12} md={4}>
                  {renderSwitch(
                    "handleChangeAllowsInstallments",
                    item.allowsInstallments,
                    "allowsInstallments",
                    paymentMethodMessages.allowsInstallments
                  )}
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <TextInput
                    disabled={!item.allowsInstallments}
                    id="maximumAmountInstallments"
                    label={paymentMethodMessages.maximumAmountInstallments}
                    value={item.maximumAmountInstallments}
                    onChange={(field, value) =>
                      dispatch({ type: "handleChange", field: "maximumAmountInstallments", value })
                    }
                    required
                    errors={errors}
                    type="number"
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4} />
                <GridItem xs={12} sm={12} md={12}>
                  {renderSwitch(
                    "handleChange",
                    item.allowsDifference,
                    "allowsDifference",
                    "Permite troco"
                  )}
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  {renderSwitch(
                    "handleChange",
                    item.standard,
                    "standard",
                    paymentMethodMessages.standard
                  )}
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  {renderSwitch(
                    "handleChange",
                    item.active,
                    "active",
                    paymentMethodMessages.active
                  )}
                </GridItem>
                <GridContainer xs={12} sm={12} md={12} style={{ marginTop: 20 }}>
                  <GridItem xs={12} sm={12} md={12}>
                    <Divider style={{ width: "100%", marginBottom: 4 }} />
                    <Typography style={{ fontSize: 18, fontWeight: 500 }}>
                      Informações tributárias adicionais
                    </Typography>
                  </GridItem>
                  {renderNFIndicadorFormaPagamento()}
                  {renderNFMeioPagamento()}
                </GridContainer>
              </GridContainer>
            </CardBody>
            <CardFooter>
              <Button color="primary" onClick={goBack}>
                {generalMessages.cancel}
              </Button>
              <Button color="primary" onClick={onSave}>
                {generalMessages.save}
              </Button>
            </CardFooter>
          </Card>
        </GridItem>
        <Loading loading={loading} />
      </GridContainer>
    </>
  );
}
