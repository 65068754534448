import {
  NaturezaOperacao,
  TipoExcecao,
  NFRegimeTributario,
  SituacaoTributariaIcms,
  SituacaoOperacionalSimplesNacional,
} from "../model/NaturezaOperacao";
import Util from "./NaturezaOperacaoConverterUtil";

const buildExcecoesFromRequest = (excecoes) => {
  const excecoesByType = excecoes.map((ex, index) => ({
    ...ex,
    ncms: ex.ncms.map((ncm) => (typeof ncm === "string" ? ncm : ncm.ncm)),
    index,
  }));
  return excecoesByType;
};

const toRequest = ({
  natureza,
  simples,
  ipi,
  // issqn,
  pis,
  cofins,
  icms,

  advancedConfigs,
  excecoesSimples,
  excecoesIpi,
  // excecoesIssqn,
  excecoesPis,
  excecoesCofins,
  excecoesIcms,
}) => {
  const {
    id,
    descricao,
    observacaoNaturezaOperacao,
    finalidade,
    regimeTributario,
    operacaoConsumidorFinal,
    tipo,
    version,
    active,
    padrao,
  } = natureza;

  const excecoes = [
    ...excecoesSimples,
    ...excecoesIpi,
    // ...excecoesIssqn,
    ...excecoesPis,
    ...excecoesCofins,
    ...excecoesIcms,
  ];

  let requestIcms = {};

  if (
    regimeTributario === NFRegimeTributario.SIMPLES_NACIONAL_EXCESSO_RECEITA.code ||
    regimeTributario === NFRegimeTributario.NORMAL.code
  ) {
    requestIcms = Util.toRequestIcms(icms);
  }

  return {
    id,
    descricao,
    observacaoNaturezaOperacao,
    finalidade,
    regimeTributario,
    operacaoConsumidorFinal,
    tipo,
    padrao,
    //
    ...Util.toRequestSimples(simples),
    ...Util.toRequestIpi(ipi),
    // ...issqn,
    ...Util.toRequestPis(pis),
    ...Util.toRequestCofins(cofins),
    ...requestIcms,
    //
    ...advancedConfigs,
    //
    excecoes: buildExcecoesFromRequest(excecoes),
    //
    version,
    active,
  };
};

const buildExcecoesFromResponse = (excecoes, type) => {
  const excecoesByType = excecoes
    .filter((item) => item.tipoExcecao === type)
    .map((ex, index) => ({
      ...ex,
      index,
    }));
  return excecoesByType;
};

const fromResponse = (response) => {
  if (response === null) return new NaturezaOperacao();

  const {
    id,
    descricao,
    observacaoNaturezaOperacao,
    finalidade,
    regimeTributario,
    operacaoConsumidorFinal,
    tipo,
    padrao,
    //  Inicio SIMPLES
    situacaoOperacaoSnSimples,
    partilhaIcmsInterestadualSimples,
    cfopSimples,
    aliquotaCalculoCreditoSimples,
    observacaoSimples,
    aliquotaIcmsSimples,
    baseIcmsSimples,
    aliquotaIcmsStSimples,
    baseCalculoIcmsStSimples,
    margemAdicionalIcmsStSimples,
    aliquotaFcpIcmsStSimples,
    obterIcmsStRetidoAnteriormenteNfCompraSimples,
    modalidadeBcIcmsStSimples,
    modalidadeBcIcmsSimples,
    percentualReducaoBcSimples,
    percentualReducaoBcIcmsStSimples,
    percentualReducaoBcEfetivaSimples,
    aliquotaIcmsEfetivaSimples,
    baseIcmsEfetivaSimples,

    //  Inicio IPI
    situacaoTributariaIpi,
    aliquotaIpi,
    codigoEnquadramentoIpi,
    observacaoIpi,
    tipoCalculoIpi,
    valorIpi,

    //  Inicio ISSQN
    // situacaoTributariaIssqn,
    // aliquotaIssqn,
    // baseCalculoIssqn,
    // descontarIssIssqn,
    // observacaoIssqn,

    //  Inicio PIS
    situacaoTributariaPis,
    aliquotaPis,
    valorPis,
    tipoCalculoPis,
    baseCalculoPis,
    observacaoPis,

    //  Inicio COFINS
    situacaoTributariaCofins,
    aliquotaCofins,
    baseCalculoCofins,
    valorCofins,
    tipoCalculoCofins,
    observacaoCofins,

    // Inicio ICMS
    situacaoTributariaIcms,
    cfopIcms,
    aliquotaIcms,
    partilhaIcmsInterestadualIcms,
    aliquotaFcpIcms,
    observacaoIcms,
    aliquotaIcmsStIcms,
    baseCalculoIcmsStIcms,
    mvaIvaIcms,
    aliquotaFcpIcmsStIcms,
    aliquotaPisIcms,
    aliquotaCofinsIcms,
    baseIcms,
    baseDiferimentoIcms,
    presumidoIcms,
    aliquotaIcmsEfetivoIcms,
    baseIcmsEfetivoIcms,
    obterIcmsStRetidoAnteriormenteNfCompraIcms,
    tipoCalculoIcms,
    modalidadeBcIcmsStIcms,
    percentualReducaoBcIcmsStIcms,
    modalidadeBcIcmsIcms,
    percentualReducaoBcIcms,
    percentualReducaoBcEfetivaIcms,

    // Advanced

    considerarComoReceitaNoDre,
    considerarCvmNoDre,
    compraDeProdutorRural,
    permiteLancamentoDeEstoque,
    aliquotaFunrural,
    descontarFunruralDoTotalFaturado,
    vendaParaZonaFrancaDeManaus,
    reducaoDaBaseDeCalculoDoIcmsSt,
    descontarDesoneracaoDoTotalDaNotaFiscal,
    itemCompoemValorTotalNfe,
    //
    excecoes,
    //
    version,
    active,
  } = response;

  const simples = {
    situacaoOperacaoSnSimples:
      situacaoOperacaoSnSimples == null
        ? SituacaoOperacionalSimplesNacional.TRIBUTADA_SEM_PERMISSAO_CREDITO.value
        : situacaoOperacaoSnSimples,
    partilhaIcmsInterestadualSimples,
    cfopSimples,
    aliquotaCalculoCreditoSimples,
    observacaoSimples,
    aliquotaIcmsSimples,
    baseIcmsSimples,
    aliquotaIcmsStSimples,
    baseCalculoIcmsStSimples,
    margemAdicionalIcmsStSimples,
    aliquotaFcpIcmsStSimples,
    obterIcmsStRetidoAnteriormenteNfCompraSimples,
    modalidadeBcIcmsStSimples,
    modalidadeBcIcmsSimples,
    percentualReducaoBcSimples,
    percentualReducaoBcIcmsStSimples,
    percentualReducaoBcEfetivaSimples,
    aliquotaIcmsEfetivaSimples,
    baseIcmsEfetivaSimples,
  };

  const ipi = {
    situacaoTributariaIpi,
    aliquotaIpi,
    codigoEnquadramentoIpi,
    observacaoIpi,
    tipoCalculoIpi,
    valorIpi,
  };

  // const issqn = {
  //   situacaoTributariaIssqn,
  //   aliquotaIssqn,
  //   baseCalculoIssqn,
  //   descontarIssIssqn,
  //   observacaoIssqn,
  // };

  const pis = {
    situacaoTributariaPis,
    aliquotaPis,
    baseCalculoPis,
    observacaoPis,
    tipoCalculoPis,
    valorPis,
  };

  const cofins = {
    situacaoTributariaCofins,
    aliquotaCofins,
    baseCalculoCofins,
    observacaoCofins,
    tipoCalculoCofins,
    valorCofins,
  };

  const icms = {
    situacaoTributariaIcms:
      situacaoTributariaIcms == null
        ? SituacaoTributariaIcms.TRIBUTACAO_INTEGRALMENTE.value
        : situacaoTributariaIcms,
    cfopIcms,
    aliquotaIcms,
    partilhaIcmsInterestadualIcms,
    aliquotaFcpIcms,
    observacaoIcms,
    aliquotaIcmsStIcms,
    baseCalculoIcmsStIcms,
    mvaIvaIcms,
    aliquotaFcpIcmsStIcms,
    aliquotaPisIcms,
    aliquotaCofinsIcms,
    baseIcms,
    baseDiferimentoIcms,
    presumidoIcms,
    aliquotaIcmsEfetivoIcms,
    baseIcmsEfetivoIcms,
    obterIcmsStRetidoAnteriormenteNfCompraIcms,
    tipoCalculoIcms,
    modalidadeBcIcmsStIcms,
    percentualReducaoBcIcmsStIcms,
    modalidadeBcIcmsIcms,
    percentualReducaoBcIcms,
    percentualReducaoBcEfetivaIcms,
  };

  const advancedConfigs = {
    considerarComoReceitaNoDre,
    considerarCvmNoDre,
    compraDeProdutorRural,
    permiteLancamentoDeEstoque,
    aliquotaFunrural,
    descontarFunruralDoTotalFaturado,
    vendaParaZonaFrancaDeManaus,
    reducaoDaBaseDeCalculoDoIcmsSt,
    descontarDesoneracaoDoTotalDaNotaFiscal,
    itemCompoemValorTotalNfe,
  };

  const excecoesSimples = buildExcecoesFromResponse(excecoes, TipoExcecao.SIMPLES);
  const excecoesIpi = buildExcecoesFromResponse(excecoes, TipoExcecao.IPI);
  // const excecoesIssqn = buildExcecoesFromResponse(excecoes, TipoExcecao.ISSQN);
  const excecoesPis = buildExcecoesFromResponse(excecoes, TipoExcecao.PIS);
  const excecoesCofins = buildExcecoesFromResponse(excecoes, TipoExcecao.COFINS);
  const excecoesIcms = buildExcecoesFromResponse(excecoes, TipoExcecao.ICMS);

  return new NaturezaOperacao({
    id,
    descricao,
    observacaoNaturezaOperacao,
    finalidade,
    padrao,
    //
    simples,
    ipi,
    // issqn,
    pis,
    cofins,
    icms,
    //
    regimeTributario,
    operacaoConsumidorFinal,
    tipo,
    //
    advancedConfigs,
    //
    excecoesSimples,
    excecoesIpi,
    // excecoesIssqn,
    excecoesPis,
    excecoesCofins,
    excecoesIcms,
    //
    version,
    active,
  });
};

export { toRequest, fromResponse };
