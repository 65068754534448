const reducer = (state, action) => {
  switch (action.type) {
    case "handleChange":
      return {
        ...state,
        item: {
          ...state.item,
          [action.field]: action.value,
        },
      };
    case "setLoading":
      return {
        ...state,
        loading: action.value,
      };
    case "setItem":
      return {
        ...state,
        item: action.value,
      };
    case "setErrors":
      return {
        ...state,
        errors: action.value,
      };
    default:
      throw new Error("Action not defined");
  }
};
export default reducer;
