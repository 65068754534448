import moment from "moment";
import { PersonType, Person } from "../model/Person";

const toRequest = (person) => {
  const {
    city,
    code,
    street,
    number,
    complement,
    zipCode,
    neighborhood,
    personType,
    id,
    name,
    cpf,
    rg,
    genderType,
    birthday,
    phone,
    mobile,
    addressId,
    addressVersion,
    email,
    tradingName,
    taxpayerType,
    cnpj,
    foundationDate,
    inscricaoEstadualSubstitutoTributario,
    inscricaoEstadual,
    registrationType,
    discount,
    version,
    active,
  } = person;

  const address = {
    id: addressId,
    city:
      city != null
        ? {
            id: city,
          }
        : null,
    street,
    number,
    complement,
    zipCode,
    neighborhood,
    version: addressVersion,
  };

  const registrationTypeList = [];
  Object.keys(registrationType).forEach((property) => {
    const value = registrationType[property];
    if (value) {
      registrationTypeList.push(property);
    }
  });

  const cnpjWM = cnpj?.replace(/[^0-9]+/g, "") || null;
  const cpfWM = cpf?.replace(/[^0-9]+/g, "") || null;
  const mobileWM = mobile?.replace(/[^0-9]+/g, "") || null;
  const phoneWM = phone?.replace(/[^0-9]+/g, "") || null;
  if (personType === PersonType.PF.code) {
    return {
      id,
      code,
      personType,
      name,
      cpf: cpfWM,
      rg,
      genderType,
      phone: phoneWM,
      mobile: mobileWM,
      email,
      address,
      discount,
      birthday: birthday ? moment(birthday).format("YYYY-MM-DD") : null,
      inscricaoEstadualSubstitutoTributario,
      inscricaoEstadual,
      registrationType: registrationTypeList,
      version,
      active,
      taxpayerType,
    };
  }

  return {
    id,
    code,
    personType,
    name,
    tradingName,
    taxpayerType,
    cnpj: cnpjWM,
    phone: phoneWM,
    mobile: mobileWM,
    email,
    address,
    foundationDate: foundationDate ? moment(foundationDate).format("YYYY-MM-DD") : null,
    inscricaoEstadualSubstitutoTributario,
    inscricaoEstadual,
    registrationType: registrationTypeList,
    version,
    active,
  };
};

// eslint-disable-next-line arrow-body-style
const fromResponse = (response) => {
  if (response === null) return new Person();

  const {
    id,
    code,
    personType,
    name,
    tradingName,
    taxpayerType,
    cnpj,
    foundationDate,
    cpf,
    rg,
    genderType,
    phone,
    mobile,
    email,
    address,
    discount,
    birthday,
    inscricaoEstadualSubstitutoTributario,
    inscricaoEstadual,
    registrationType,
    version,
    standard,
    active,
  } = response;
  const {
    id: addressId,
    city,
    street,
    number,
    complement,
    zipCode,
    neighborhood,
    version: addressVersion,
  } = address || {};
  let cityId = null;
  let stateId = null;
  if (city != null) {
    cityId = city.id;
    stateId = city.state.id;
  }

  const registrationTypeObj = registrationType.reduce(
    (acc, value) => ({ ...acc, [value]: true }),
    {}
  );

  return new Person({
    id,
    code,
    personType,
    name,
    cpf,
    rg,
    tradingName,
    taxpayerType,
    cnpj,
    foundationDate,
    genderType,
    phone,
    mobile,
    email,
    address,
    discount,
    birthday,
    state: stateId,
    city: cityId,
    addressId,
    street,
    number,
    complement,
    zipCode,
    neighborhood,
    version,
    standard,
    inscricaoEstadualSubstitutoTributario,
    inscricaoEstadual,
    registrationType: registrationTypeObj,
    active,
    addressVersion,
  });
};

export { toRequest, fromResponse };
