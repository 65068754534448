import { NotaFiscalPacoteDownload } from "../model/NotaFiscalPacoteDownload";

const toRequest = (item) => {
  const { id, startDate, endDate, status, version } = item;
  return {
    id,
    startDate,
    endDate,
    status,
    version,
    active: true,
  };
};

const fromResponse = (response) => {
  if (response === null) return new NotaFiscalPacoteDownload();

  const { id, startDate, endDate, status, version, active, createdAt } = response;

  return new NotaFiscalPacoteDownload({
    id,
    startDate,
    endDate,
    status,
    version,
    active,
    createdAt,
  });
};

export { toRequest, fromResponse };
