/* eslint-disable react/prop-types */
import React from "react";
import { makeStyles } from "@mui/styles";
import CustomComponentsStyle from "assets/jss/material-dashboard-react/components/customComponentsStyle";
import {
  Box,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  Typography,
} from "@mui/material";
import GridItem from "components/Grid/GridItem";
import Autocomplete from "components/autocomplete/Autocomplete";
import TextInput from "components/textInput/TextInput";
import CurrencyTextInput from "components/textInput/CurrencyTextInput";
import MESSAGES from "config/messages";
import GridContainer from "components/Grid/GridContainer";
import CfopService from "views/cfop/service/CfopService";
import { SituacaoTributariaIcms, YesNo } from "../model/NaturezaOperacao";
import SelectNFNotaInfoItemModalidadeBCICMSST from "./SelectNFNotaInfoItemModalidadeBCICMSST";
import SelectNFNotaInfoItemModalidadeBCICMS from "./SelectNFNotaInfoItemModalidadeBCICMS";

const { naturezaOperacaoMessages } = MESSAGES;
const CHARACTER_LIMIT = 4000;

const NOIcmsTab = ({ data, handleChange, errors = {} }) => {
  const useCustomComponentsStyle = makeStyles(CustomComponentsStyle);
  const classesCustomComponentsStyle = useCustomComponentsStyle();

  const {
    situacaoTributariaIcms,
    cfopIcms,
    aliquotaIcms,
    partilhaIcmsInterestadualIcms,
    aliquotaFcpIcms,
    observacaoIcms,
    aliquotaIcmsStIcms,
    baseCalculoIcmsStIcms,
    mvaIvaIcms,
    aliquotaFcpIcmsStIcms,
    aliquotaPisIcms,
    aliquotaCofinsIcms,
    baseIcms,
    baseDiferimentoIcms,
    presumidoIcms,
    aliquotaIcmsEfetivoIcms,
    baseIcmsEfetivoIcms,
    obterIcmsStRetidoAnteriormenteNfCompraIcms,
    tipoCalculoIcms,
    modalidadeBcIcmsStIcms,
    percentualReducaoBcIcmsStIcms,
    modalidadeBcIcmsIcms,
    percentualReducaoBcIcms,
    percentualReducaoBcEfetivaIcms,
  } = data;

  const renderCfopIcms = () => (
    <GridItem xs={12} sm={12} md={4}>
      <Box display="flex" flexGrow={1}>
        <Autocomplete
          freeSolo
          autoSelect
          width="100%"
          variant="outlined"
          fieldsToShowAfterSelectItem={["shortDescription"]}
          label={naturezaOperacaoMessages.icms.cfopIcms}
          placeholder={naturezaOperacaoMessages.icms.autocompleteCfopIcms}
          service={CfopService}
          value={cfopIcms}
          onChange={(value) => {
            if (typeof value === "string" || value == null) {
              handleChange("cfopIcms", value);
            } else {
              handleChange("cfopIcms", value.cfop);
            }
          }}
        />
      </Box>
    </GridItem>
  );

  const renderAliquotaIcms = () => (
    <GridItem xs={8} sm={8} md={3}>
      <CurrencyTextInput
        currencySymbol="%"
        id="aliquotaIcms"
        label={naturezaOperacaoMessages.icms.aliquotaIcms}
        value={aliquotaIcms || "0"}
        onChange={handleChange}
        errors={errors}
      />
    </GridItem>
  );

  const renderPartilhaIcmsInterestadualIcms = () => (
    <GridItem xs={12} sm={12} md={3}>
      <FormControl
        fullWidth
        className={classesCustomComponentsStyle.formControlSelect}
        size="small"
      >
        <InputLabel>{naturezaOperacaoMessages.icms.partilhaIcmsInterestadualIcms}</InputLabel>
        <Select
          id="finalidade"
          label={naturezaOperacaoMessages.icms.partilhaIcmsInterestadualIcms}
          autoWidth
          value={partilhaIcmsInterestadualIcms === true ? YesNo.YES.code : YesNo.No.code}
          onChange={(event) => {
            const {
              target: { value },
            } = event;
            return handleChange("partilhaIcmsInterestadualIcms", value);
          }}
          error={errors.partilhaIcmsInterestadualIcms}
          helperText={errors.partilhaIcmsInterestadualIcms}
        >
          {Object.keys(YesNo).map((key) => {
            const { code, name } = YesNo[key];
            return (
              <MenuItem key={code} value={code}>
                {name}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </GridItem>
  );

  const renderAliquotaFcpIcms = () => (
    <GridItem xs={8} sm={8} md={3}>
      <CurrencyTextInput
        decimalPlaces={4}
        currencySymbol="%"
        id="aliquotaFcpIcms"
        label={naturezaOperacaoMessages.icms.aliquotaFcpIcms}
        value={aliquotaFcpIcms || "0"}
        onChange={handleChange}
        errors={errors}
      />
    </GridItem>
  );

  const renderObservacaoIcms = () => (
    <GridItem xs={12} sm={12} md={12}>
      <TextInput
        id="observacaoIcms"
        label={naturezaOperacaoMessages.icms.observacaoIcms}
        value={observacaoIcms}
        maxLength={CHARACTER_LIMIT}
        onChange={handleChange}
        isCounter
        multiline
        rows={4}
      />
    </GridItem>
  );

  const renderAliquotaIcmsStIcms = () => (
    <GridItem xs={8} sm={8} md={3}>
      <CurrencyTextInput
        currencySymbol="%"
        id="aliquotaIcmsStIcms"
        label={naturezaOperacaoMessages.icms.aliquotaIcmsStIcms}
        value={aliquotaIcmsStIcms || "0"}
        onChange={handleChange}
        errors={errors}
      />
    </GridItem>
  );

  const renderBaseCalculoIcmsStIcms = () => (
    <GridItem xs={8} sm={8} md={3}>
      <CurrencyTextInput
        decimalPlaces={4}
        currencySymbol="%"
        id="baseCalculoIcmsStIcms"
        label={naturezaOperacaoMessages.icms.baseCalculoIcmsStIcms}
        value={baseCalculoIcmsStIcms || "0"}
        onChange={handleChange}
        errors={errors}
      />
    </GridItem>
  );

  const renderMvaIvaIcms = () => (
    <GridItem xs={8} sm={8} md={3}>
      <CurrencyTextInput
        currencySymbol="%"
        id="mvaIvaIcms"
        label={naturezaOperacaoMessages.icms.mvaIvaIcms}
        value={mvaIvaIcms || "0"}
        onChange={handleChange}
        errors={errors}
      />
    </GridItem>
  );

  const renderAliquotaFcpIcmsStIcms = () => (
    <GridItem xs={8} sm={8} md={3}>
      <CurrencyTextInput
        currencySymbol="%"
        id="aliquotaFcpIcmsStIcms"
        label={naturezaOperacaoMessages.icms.aliquotaFcpIcmsStIcms}
        value={aliquotaFcpIcmsStIcms || "0"}
        onChange={handleChange}
        errors={errors}
      />
    </GridItem>
  );

  const renderAliquotaPisIcms = () => (
    <GridItem xs={8} sm={8} md={3}>
      <CurrencyTextInput
        currencySymbol="%"
        id="aliquotaPisIcms"
        label={naturezaOperacaoMessages.icms.aliquotaPisIcms}
        value={aliquotaPisIcms || "0"}
        onChange={handleChange}
        errors={errors}
      />
    </GridItem>
  );

  const renderAliquotaCofinsIcms = () => (
    <GridItem xs={8} sm={8} md={3}>
      <CurrencyTextInput
        currencySymbol="%"
        id="aliquotaCofinsIcms"
        label={naturezaOperacaoMessages.icms.aliquotaCofinsIcms}
        value={aliquotaCofinsIcms || "0"}
        onChange={handleChange}
        errors={errors}
      />
    </GridItem>
  );

  const renderBase = () => (
    <GridItem xs={8} sm={8} md={3}>
      <CurrencyTextInput
        decimalPlaces={4}
        currencySymbol="%"
        id="baseIcms"
        label={naturezaOperacaoMessages.icms.baseIcms}
        value={baseIcms || "0"}
        onChange={handleChange}
        errors={errors}
      />
    </GridItem>
  );

  const renderBaseDiferimentoIcms = () => (
    <GridItem xs={8} sm={8} md={3}>
      <CurrencyTextInput
        currencySymbol="%"
        decimalPlaces={4}
        id="baseDiferimentoIcms"
        label={naturezaOperacaoMessages.icms.baseDiferimentoIcms}
        value={baseDiferimentoIcms || "0"}
        onChange={handleChange}
        errors={errors}
      />
    </GridItem>
  );

  const renderPresumidoIcms = () => (
    <GridItem xs={8} sm={8} md={3}>
      <CurrencyTextInput
        decimalPlaces={4}
        currencySymbol="%"
        id="presumidoIcms"
        label={naturezaOperacaoMessages.icms.presumidoIcms}
        value={presumidoIcms || "0"}
        onChange={handleChange}
        errors={errors}
      />
    </GridItem>
  );

  const renderAliquotaIcmsEfetivoIcms = () => (
    <GridItem xs={8} sm={8} md={3}>
      <CurrencyTextInput
        currencySymbol="%"
        decimalPlaces={4}
        id="aliquotaIcmsEfetivoIcms"
        label={naturezaOperacaoMessages.icms.aliquotaIcmsEfetivoIcms}
        value={aliquotaIcmsEfetivoIcms || "0"}
        onChange={handleChange}
        errors={errors}
      />
    </GridItem>
  );

  const renderBaseIcmsEfetivoIcms = () => (
    <GridItem xs={8} sm={8} md={3}>
      <CurrencyTextInput
        decimalPlaces={4}
        currencySymbol="%"
        id="baseIcmsEfetivoIcms"
        label={naturezaOperacaoMessages.icms.baseIcmsEfetivoIcms}
        value={baseIcmsEfetivoIcms || "0"}
        onChange={handleChange}
        errors={errors}
      />
    </GridItem>
  );

  const renderModalidadeBcIcmsIcms = () => (
    <GridItem xs={8} sm={8} md={4}>
      <SelectNFNotaInfoItemModalidadeBCICMS
        field="modalidadeBcIcmsIcms"
        valueSelected={modalidadeBcIcmsIcms}
        handleChange={handleChange}
      />
    </GridItem>
  );

  const renderModalidadeBcIcmsStIcms = () => (
    <GridItem xs={8} sm={8} md={6}>
      <SelectNFNotaInfoItemModalidadeBCICMSST
        field="modalidadeBcIcmsStIcms"
        valueSelected={modalidadeBcIcmsStIcms}
        handleChange={handleChange}
      />
    </GridItem>
  );

  const renderPercentualReducaoBcIcms = () => (
    <GridItem xs={8} sm={8} md={4}>
      <CurrencyTextInput
        decimalPlaces={4}
        currencySymbol="%"
        id="percentualReducaoBcIcms"
        label="% redução da BC ICMS"
        value={percentualReducaoBcIcms || "0"}
        onChange={handleChange}
        errors={errors}
      />
    </GridItem>
  );

  const renderPercentualReducaoBcIcmsStIcms = () => (
    <GridItem xs={8} sm={8} md={6}>
      <CurrencyTextInput
        decimalPlaces={4}
        currencySymbol="%"
        id="percentualReducaoBcIcmsStIcms"
        label="% Redução da BC ICMS ST"
        value={percentualReducaoBcIcmsStIcms || "0"}
        onChange={handleChange}
        errors={errors}
      />
    </GridItem>
  );

  const renderPercentualReducaoBcEfetivaIcms = () => (
    <GridItem xs={8} sm={8} md={3}>
      <CurrencyTextInput
        decimalPlaces={4}
        currencySymbol="%"
        id="percentualReducaoBcEfetivaIcms"
        label="% Redução da BC Efetivo"
        value={percentualReducaoBcEfetivaIcms || "0"}
        onChange={handleChange}
        errors={errors}
      />
    </GridItem>
  );

  const renderObterIcmsStRetidoAnteriormenteNfCompraIcms = (
    value = false,
    field,
    label,
    disabled = false
  ) => (
    <GridItem xs={8} sm={8} md={12}>
      <FormGroup style={{ paddingTop: "8px" }}>
        <FormControlLabel
          control={
            <Switch
              color="secondary"
              checked={value}
              onChange={(event) => handleChange(field, event.target.checked)}
            />
          }
          label={label}
          disabled={disabled}
        />
      </FormGroup>
    </GridItem>
  );

  const renderSubstituicaoTributaria = () => (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12} style={{ marginTop: 12 }}>
        <Divider style={{ width: "100%", marginBottom: 4 }} />
        <Typography style={{ fontSize: 18, fontWeight: 500 }}>Substituição tributária</Typography>
      </GridItem>

      {renderModalidadeBcIcmsStIcms()}
      {renderPercentualReducaoBcIcmsStIcms()}
      {renderAliquotaIcmsStIcms()}
      {renderBaseCalculoIcmsStIcms()}
      {renderMvaIvaIcms()}
      {renderAliquotaFcpIcmsStIcms()}
      {renderAliquotaPisIcms()}
      {renderAliquotaCofinsIcms()}
    </GridContainer>
  );

  const renderSubstituicaoTributariaRetidaAnteriormente = () => (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12} style={{ marginTop: 12 }}>
        <Divider style={{ width: "100%", marginBottom: 4 }} />
        <Typography style={{ fontSize: 18, fontWeight: 500 }}>
          Substituição tributária retida anteriormente
        </Typography>
      </GridItem>

      {renderAliquotaIcms()}
      {renderBase()}
      {renderAliquotaIcmsStIcms()}
      {renderBaseCalculoIcmsStIcms()}
      {renderMvaIvaIcms()}
      {renderAliquotaFcpIcmsStIcms()}
      {renderAliquotaPisIcms()}
      {renderAliquotaCofinsIcms()}
    </GridContainer>
  );

  const renderBySituacaoTributariaIcms = () => {
    // if (situacaoTributariaIcms) {
    const code = parseInt(SituacaoTributariaIcms[situacaoTributariaIcms].code, 10);

    if (code === 0) {
      return [
        renderCfopIcms(),
        renderModalidadeBcIcmsIcms(),
        renderAliquotaIcms(),
        renderPartilhaIcmsInterestadualIcms(),
        renderAliquotaFcpIcms(),
        renderObservacaoIcms(),
      ];
    }

    if (code === 10) {
      return [
        renderCfopIcms(),
        renderModalidadeBcIcmsIcms(),
        renderAliquotaIcms(),
        renderPartilhaIcmsInterestadualIcms(),
        renderAliquotaFcpIcms(),

        renderObservacaoIcms(),
        renderSubstituicaoTributaria(),
      ];
    }

    if (code === 20) {
      return [
        renderCfopIcms(),
        renderModalidadeBcIcmsIcms(),
        renderPercentualReducaoBcIcms(),
        renderAliquotaIcms(),
        renderBase(),
        renderPartilhaIcmsInterestadualIcms(),
        renderAliquotaFcpIcms(),
        renderObservacaoIcms(),
      ];
    }

    if (code === 30) {
      return [
        renderCfopIcms(),
        renderAliquotaIcms(),
        renderPartilhaIcmsInterestadualIcms(),
        renderSubstituicaoTributaria(),
      ];
    }

    if (code === 40 || code === 41 || code === 50) {
      return [
        renderCfopIcms(),
        renderAliquotaIcms(),
        renderPartilhaIcmsInterestadualIcms(),
        renderObservacaoIcms(),
      ];
    }

    if (code === 51) {
      return [
        renderCfopIcms(),
        renderModalidadeBcIcmsIcms(),
        renderPercentualReducaoBcIcms(),
        renderAliquotaIcms(),
        renderBase(),
        renderPartilhaIcmsInterestadualIcms(),
        renderBaseDiferimentoIcms(),
        renderAliquotaFcpIcms(),
        renderObservacaoIcms(),
      ];
    }

    if (code === 60) {
      return [
        renderCfopIcms(),
        renderPercentualReducaoBcEfetivaIcms(),
        renderAliquotaIcmsEfetivoIcms(),
        renderBaseIcmsEfetivoIcms(),
        renderPartilhaIcmsInterestadualIcms(),

        renderObservacaoIcms(),

        renderObterIcmsStRetidoAnteriormenteNfCompraIcms(
          obterIcmsStRetidoAnteriormenteNfCompraIcms,
          "obterIcmsStRetidoAnteriormenteNfCompraIcms",
          "Obter ICMS-ST retido anteriormente a partir de nota fiscal de compra"
        ),
        !obterIcmsStRetidoAnteriormenteNfCompraIcms &&
          renderSubstituicaoTributariaRetidaAnteriormente(),
      ];
    }

    if (code === 70) {
      return [
        renderCfopIcms(),
        renderModalidadeBcIcmsIcms(),
        renderPercentualReducaoBcIcms(),
        renderAliquotaIcms(),
        renderBase(),
        renderPartilhaIcmsInterestadualIcms(),
        renderAliquotaFcpIcms(),
        renderObservacaoIcms(),

        renderSubstituicaoTributaria(),
      ];
    }

    if (code === 90) {
      return [
        renderCfopIcms(),
        renderModalidadeBcIcmsIcms(),
        renderPercentualReducaoBcIcms(),
        renderAliquotaIcms(),
        renderBase(),
        renderPartilhaIcmsInterestadualIcms(),
        renderBaseDiferimentoIcms(),
        renderPresumidoIcms(),
        renderAliquotaFcpIcms(),
        renderObservacaoIcms(),

        renderSubstituicaoTributaria(),
      ];
    }
    // }
    return <Box />;
  };

  // const renderTipoCalculo = () => (
  //   <GridItem xs={8} sm={8} md={3} style={{ marginTop: -6 }}>
  //     <SelectTipoCalculo
  //       field="tipoCalculoIcms"
  //       valueSelected={tipoCalculoIcms}
  //       handleChange={handleChange}
  //     />
  //   </GridItem>
  // );

  const renderSituacaoTributariaIcms = () => (
    <GridItem xs={12} sm={12} md={8}>
      <FormControl
        fullWidth
        className={classesCustomComponentsStyle.formControlSelect}
        size="small"
        variant="outlined"
      >
        <InputLabel>{naturezaOperacaoMessages.icms.situacaoTributariaIcms}</InputLabel>
        <Select
          id="situacaoTributariaIcms"
          label={naturezaOperacaoMessages.icms.situacaoTributariaIcms}
          autoWidth
          value={situacaoTributariaIcms == null ? "" : situacaoTributariaIcms}
          onChange={(event) => {
            const {
              target: { value },
            } = event;
            return handleChange("situacaoTributariaIcms", value);
          }}
          error={errors.situacaoTributariaIcms}
          helperText={errors.situacaoTributariaIcms}
        >
          {Object.keys(SituacaoTributariaIcms).map((key) => {
            const { code, name, value } = SituacaoTributariaIcms[key];
            return (
              <MenuItem key={code} value={value}>
                {code} - {name}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </GridItem>
  );

  return (
    <GridContainer>
      {renderSituacaoTributariaIcms()}
      {renderBySituacaoTributariaIcms()}
      {/* {renderTipoCalculo()} */}
    </GridContainer>
  );
};

export default React.memo(NOIcmsTab);
