import { PaymentMethod } from "../model/PaymentMethod";

const toRequest = (request) => {
  const {
    id,
    name,
    creditCard,
    anticipatedReceipt,
    anticipatedDays,
    allowsInstallments,
    maximumAmountInstallments,
    standard,
    active,
    allowsDifference,
    nfFormOfPayment,
    nfPaymentMethod,
    version,
  } = request;

  return {
    id,
    name,
    creditCard,
    anticipatedReceipt,
    anticipatedDays,
    allowsInstallments,
    maximumAmountInstallments,
    standard,
    allowsDifference,
    nfFormOfPayment,
    nfPaymentMethod,
    active,
    version,
  };
};

const fromResponse = (response) => {
  if (response === null) return new PaymentMethod();

  const {
    id,
    name,
    creditCard,
    anticipatedReceipt,
    anticipatedDays,
    allowsInstallments,
    maximumAmountInstallments,
    standard,
    allowsDifference,
    nfFormOfPayment,
    nfPaymentMethod,
    active,
    version,
  } = response;

  return new PaymentMethod({
    id,
    name,
    creditCard,
    anticipatedReceipt,
    anticipatedDays,
    allowsInstallments,
    maximumAmountInstallments,
    standard,
    active,
    allowsDifference,
    nfFormOfPayment,
    nfPaymentMethod,
    version,
  });
};

export { toRequest, fromResponse };
