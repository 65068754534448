/* eslint-disable react/prop-types */
import React from "react";

import { Dialog, CircularProgress } from "@mui/material";
import { primaryColor } from "../../assets/jss/material-dashboard-react";

const Loading = ({ loading = false }) => (
  <Dialog
    disableEscapeKeyDown
    open={loading}
    PaperProps={{
      style: {
        backgroundColor: "transparent",
        boxShadow: "none",
      },
    }}
    BackdropProps={{ style: { opacity: 0.6 } }}
  >
    <div
      style={{
        width: 200,
        height: 200,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress style={{ color: primaryColor[0] }} size={80} />
    </div>
  </Dialog>
);

export default React.memo(Loading);
