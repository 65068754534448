/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import CustomComponentsStyle from "assets/jss/material-dashboard-react/components/customComponentsStyle";
import { TextField } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";

const useCustomComponentsStyle = makeStyles(CustomComponentsStyle);

const TextPassword = ({
  id,
  autoFocus,
  label,
  value,
  required,
  onChange,
  errors = {},
  onKeyPress,
  autoComplete = "off",
  disabled = false,
}) => {
  const classesCustomComponentsStyle = useCustomComponentsStyle();

  const [values, setValues] = useState({
    showPassword: false,
  });

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <TextField
      size="small"
      variant="outlined"
      autoComplete={autoComplete}
      autoFocus={autoFocus}
      id={id}
      label={label}
      onKeyPress={onKeyPress}
      fullWidth
      InputLabelProps={{
        className: classesCustomComponentsStyle.labelTextField,
      }}
      className={classesCustomComponentsStyle.textField}
      value={value == null ? "" : value}
      onChange={(val) => onChange(id, val.currentTarget.value)}
      required={required}
      error={errors[id]}
      helperText={errors[id]}
      type={values.showPassword ? "text" : "password"}
      disabled={disabled}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              tabIndex="-1"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
            >
              {values.showPassword ? (
                <FontAwesomeIcon
                  icon={faEyeSlash}
                  fontSize={15}
                  title="Ocultar senha"
                  color="#686868"
                />
              ) : (
                <FontAwesomeIcon icon={faEye} fontSize={15} title="Exibir senha" color="#686868" />
              )}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default React.memo(TextPassword);
