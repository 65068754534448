/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useCallback, useState } from "react";
import CustomComponentsStyle from "assets/jss/material-dashboard-react/components/customComponentsStyle";
import { makeStyles } from "@mui/styles";
import AutocompleteStyle from "components/autocomplete/AutocompleteStyle";
import {
  Box,
  Button as ButtonUI,
  Grid,
  Divider,
  Typography,
  Modal,
  ListItem,
  ListItemText,
} from "@mui/material";
import { useSnackbar } from "notistack";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { primaryColor } from "assets/jss/material-dashboard-react";
import DeleteIcon from "components/icons/DeleteIcon";
import Autocomplete from "components/autocomplete/Autocomplete";
import MESSAGES from "config/messages";
import PersonService from "views/person/service/PersonService";
import { RegistrationType } from "views/person/model/Person";
import PersonForm from "views/person/PersonForm";
import { AUTOCOMPLETE } from "../../../config/constants";

const { productMessages, autocompleteMessages } = MESSAGES;

const useAutocompleteStyle = makeStyles(AutocompleteStyle);

const ProductSuppliers = ({ items = [], handleChange, errors }) => {
  const useCustomComponentsStyle = makeStyles(CustomComponentsStyle);
  const classesCustomComponentsStyle = useCustomComponentsStyle();
  const classesAutocompleteStyle = useAutocompleteStyle();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [onTypedChange, setOnTypedChange] = useState(null);
  const [currentIndexQuickAdd, setCurrentIndexQuickAdd] = useState(null);

  const [quickAddCustomerModalOpen, setQuickAddCustomerModalOpen] = useState(false);

  const add = useCallback(() => {
    const newItem = { index: items.length };
    const newItems = [...items, newItem];
    handleChange(newItems);
  }, [handleChange, items]);

  const remove = useCallback(
    (i) => {
      let newItems = [...items];
      const index = newItems.findIndex((item) => item.index === i);
      newItems.splice(index, 1);
      // Changing the indexes
      newItems = newItems.map((item, idx) => ({ ...item, index: idx }));
      handleChange(newItems);
    },
    [handleChange, items]
  );

  const onChange = useCallback(
    (value, index) => {
      const newItems = [...items];

      const item = newItems[index];
      let newItem = {};

      newItem = {
        ...item,
        ...value,
      };

      newItems[index] = newItem;
      handleChange(newItems);
    },
    [handleChange, items]
  );

  const renderQuickAddCustomer = () => (
    <Modal style={{ overflow: "auto" }} disableEscapeKeyDown open={quickAddCustomerModalOpen}>
      <PersonForm
        name={onTypedChange}
        registrationTypeParam={RegistrationType.SUPPLIER.code}
        onCancel={() => {
          setQuickAddCustomerModalOpen(false);
          setCurrentIndexQuickAdd(null);
        }}
        afterSave={(customer) => onChange(customer, currentIndexQuickAdd)}
      />
    </Modal>
  );
  const renderAddButton = () => (
    <GridItem xs={12} sm={12} md={3}>
      <Box display="flex" style={{ marginTop: 12 }}>
        <ButtonUI style={{ color: primaryColor[0] }} onClick={add}>
          Adicionar
        </ButtonUI>
      </Box>
    </GridItem>
  );

  const renderDelete = (item) => (
    <Box>
      <DeleteIcon onClick={() => remove(item.index)} />
    </Box>
  );

  const renderQuickAddText = (index) => (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      className={classesAutocompleteStyle.emptyContainer}
    >
      <Box>{autocompleteMessages.emptyText}</Box>
      <Box>
        <ButtonUI
          style={{ color: primaryColor[0] }}
          onMouseDown={() => {
            setQuickAddCustomerModalOpen(true);
            setCurrentIndexQuickAdd(index);
          }}
        >
          Adicionar
        </ButtonUI>
      </Box>
    </Box>
  );

  const renderOption = (props, item, index) => {
    const { id, name } = item;

    if (id === AUTOCOMPLETE.QUICK_ADD) {
      return renderQuickAddText(index);
    }

    return (
      <ListItem {...props}>
        <ListItemText primary={name} />
      </ListItem>
    );
  };

  const renderItem = (item) => (
    <GridContainer>
      <GridItem xs={12} sm={12} md={11}>
        <Box display="flex" flexGrow={1}>
          <Autocomplete
            width="100%"
            variant="outlined"
            fieldsToShowAfterSelectItem={["name"]}
            label={productMessages.supplier}
            placeholder={productMessages.autocompleteSupplier}
            service={PersonService}
            extraSearch={{ registrationType: RegistrationType.SUPPLIER.code }}
            value={item}
            onChange={(value) => onChange(value, item.index)}
            onTypedChange={(value) => setOnTypedChange(value)}
            renderOption={(props, option) => renderOption(props, option, item.index)}
            quickAdd
          />
        </Box>
      </GridItem>

      <GridItem xs={12} sm={12} md={1}>
        <Box
          style={{ marginTop: 6, alignItems: "center", height: "100%" }}
          flexDirection="row"
          display="flex"
        >
          {renderDelete(item)}
        </Box>
      </GridItem>
    </GridContainer>
  );

  const renderItems = () => {
    if (items.length === 0) {
      return add();
    }
    return items.map((item) => renderItem(item));
  };

  return (
    <Grid item xs={12} sm={12} md={12}>
      <GridItem xs={12} sm={12} md={12} style={{ marginTop: 30 }}>
        <Divider style={{ width: "100%", marginBottom: 4 }} />
        <Typography style={{ fontSize: 18, fontWeight: 500 }}>Fornecedores</Typography>
      </GridItem>
      {renderItems()}
      {renderAddButton()}
      {renderQuickAddCustomer()}
    </Grid>
  );
};

export default React.memo(ProductSuppliers);
