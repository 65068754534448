/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import CustomComponentsStyle from "assets/jss/material-dashboard-react/components/customComponentsStyle";
import {
  Chip,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import InputMask from "react-input-mask";
import GridItem from "components/Grid/GridItem";
import TextInput from "components/textInput/TextInput";
import GridContainer from "components/Grid/GridContainer";
import SelectEnum from "components/selectEnum/SelectEnum";
import { RegistrationType, PersonType } from "views/person/model/Person";
import PersonService from "views/person/service/PersonService";
import Autocomplete from "components/autocomplete/Autocomplete";
import {
  DocumentType,
  NFModalidadeFrete,
  DFUnidadeFederativa,
} from "views/notaFiscal/model/NotaFiscal";
import { dangerColor } from "../../../assets/jss/material-dashboard-react";
import AddressService from "../../../components/address/service/AddressService";
import CurrencyTextInput from "../../../components/textInput/CurrencyTextInput";

const NotaFiscalTransporte = ({
  data = {},
  handleChange,
  handleChangeDadosVolume,
  errors = {},
  disabled = false,
  states = [],
}) => {
  const useCustomComponentsStyle = makeStyles(CustomComponentsStyle);
  const classesCustomComponentsStyle = useCustomComponentsStyle();
  const [cities, setCities] = useState([]);

  const fetchCitiesByState = useCallback(async (stateId) => {
    const result = await AddressService.fetchCitiesByState(stateId);
    const { data: dataResponse } = result;
    return dataResponse;
  }, []);

  useEffect(() => {
    const asyncFetch = async () => {
      if (data.stateTransporte) {
        const citiesResult = await fetchCitiesByState(data.stateTransporte);
        setCities(citiesResult);
      }
    };
    asyncFetch();
  }, [data.stateTransporte, fetchCitiesByState]);

  const onChange = useCallback(
    (field, value) => {
      if (field == null) {
        handleChange({
          ...data,
          ...value,
        });
      } else {
        handleChange({
          ...data,
          [field]: value,
        });
      }
    },
    [data, handleChange]
  );

  const getEmptyObject = useCallback(() => {
    const empty = {
      transportadoraTransporte: null,
      razaoSocialTransporte: null,
      tipoDocumentoTransporte: DocumentType.CPF.code,
      ufVeiculoTransporte: null,
      documentoTransporte: null,
      enderecoCompletoTransporte: null,
      stateTransporte: null,
      cidadeTransporte: null,
      inscricaoEstadualTransporte: null,
    };
    return empty;
  }, []);

  const beforePersonChange = useCallback(
    async (person) => {
      let newPerson = getEmptyObject();

      if (person != null) {
        const { id } = person;
        const response = await PersonService.fetchById(id);
        const result = response?.data?.data || {};
        const { name, personType, cpf, cnpj, inscricaoEstadual, address } = result;

        newPerson = {
          transportadoraTransporte: person,
          razaoSocialTransporte: name,
        };

        if (personType === PersonType.PF.code) {
          newPerson.documentoTransporte = cpf;
          newPerson.tipoDocumentoTransporte = DocumentType.CPF.code;
        }
        if (personType === PersonType.PJ.code) {
          newPerson.tipoDocumentoTransporte = DocumentType.CNPJ.code;
          newPerson.documentoTransporte = cnpj;
          newPerson.inscricaoEstadualTransporte = inscricaoEstadual;
        }

        if (address) {
          // ufVeiculoTransporte
          // const newAddress = {
          //   // zipCode: address.zipCode,
          //   state: address?.city?.state || null,
          //   city: address?.city?.id || null,
          //   street: address.street,
          //   neighborhood: address.neighborhood,
          //   number: address.number,
          //   complement: address.complement,
          // };
          const enderecoCompletoTransporte = `${address.street} - No.${address.number || ""} - ${
            address.neighborhood || ""
          }`;
          newPerson = {
            ...newPerson,
            // ...newAddress,
            enderecoCompletoTransporte,
            stateTransporte: address?.city?.state?.id || null,
            cidadeTransporte: address?.city?.id || null,
            ufVeiculoTransporte: DFUnidadeFederativa[address?.city?.state?.uf].code,
          };
        }
      }

      onChange(null, newPerson);
    },
    [getEmptyObject, onChange]
  );

  const onChangeModalidadeFreteTransporte = useCallback(
    (field, value) => {
      if (value === NFModalidadeFrete.SEM_OCORRENCIA_TRANSPORTE.code) {
        const empty = {
          ...getEmptyObject(),
          [field]: value,
        };
        handleChangeDadosVolume({});
        return onChange(null, empty);
      }
      return onChange(field, value);
    },
    [getEmptyObject, onChange, handleChangeDadosVolume]
  );

  const renderModalidadeFreteTransporte = () => (
    <GridItem xs={12} sm={12} md={6}>
      <SelectEnum
        label="Modalidade"
        field="modalidadeFreteTransporte"
        Enum={NFModalidadeFrete}
        valueSelected={data.modalidadeFreteTransporte}
        handleChange={onChangeModalidadeFreteTransporte}
        errors={errors}
        required
        disabled={disabled}
      />
    </GridItem>
  );

  const renderTransportadora = () => (
    <GridItem xs={12} sm={12} md={4}>
      <Autocomplete
        autoSelect
        width="100%"
        variant="outlined"
        fieldsToShowAfterSelectItem={["name"]}
        label="Transportador"
        placeholder="Digite para pesquisar ..."
        service={PersonService}
        extraSearch={{ registrationType: RegistrationType.CARRIER.code }}
        value={data.transportadoraTransporte}
        onChange={(value) => beforePersonChange(value)}
        required
        disabled={disabled}
      />
    </GridItem>
  );

  const renderTipoDocumentoTransporte = () => (
    <GridItem xs={12} sm={12} md={3}>
      <SelectEnum
        label="Tipo de documento"
        field="tipoDocumentoTransporte"
        Enum={DocumentType}
        valueSelected={data.tipoDocumentoTransporte}
        handleChange={onChange}
        errors={errors}
        required
        disabled={disabled}
      />
    </GridItem>
  );

  const renderUFVeiculoTransporte = () => (
    <GridItem xs={12} sm={12} md={3}>
      <SelectEnum
        label="UF do veículo"
        field="ufVeiculoTransporte"
        Enum={DFUnidadeFederativa}
        valueSelected={data.ufVeiculoTransporte}
        handleChange={onChange}
        errors={errors}
        disabled={disabled}
      />
    </GridItem>
  );

  const renderCpf = ({ field }) => (
    <GridItem xs={12} sm={12} md={3}>
      <InputMask
        mask="999.999.999-99"
        disabled={disabled}
        value={data[field] == null ? "" : data[field]}
        onChange={(value) => onChange(field, value.currentTarget.value)}
      >
        <TextField
          size="small"
          variant="outlined"
          id={field}
          label="CPF"
          fullWidth
          InputLabelProps={{
            className: classesCustomComponentsStyle.labelTextField,
          }}
          className={classesCustomComponentsStyle.textField}
          error={errors[field]}
          helperText={errors[field]}
        />
      </InputMask>
    </GridItem>
  );

  const renderCnpj = ({ field }) => (
    <GridItem xs={12} sm={12} md={3}>
      <InputMask
        mask="99.999.999/9999-99"
        disabled={disabled}
        value={data[field] == null ? "" : data[field]}
        onChange={(value) => onChange(field, value.currentTarget.value)}
      >
        <TextField
          size="small"
          variant="outlined"
          id={field}
          label="CNPJ"
          fullWidth
          InputLabelProps={{
            className: classesCustomComponentsStyle.labelTextField,
          }}
          className={classesCustomComponentsStyle.textField}
          error={errors[field]}
          helperText={errors[field]}
        />
      </InputMask>
    </GridItem>
  );

  const renderName = ({ label, field }) => (
    <GridItem xs={12} sm={12} md={6}>
      <TextInput
        id={field}
        label={label}
        value={data[field]}
        onChange={onChange}
        required
        errors={errors}
        maxLength={60}
        disabled={disabled}
      />
    </GridItem>
  );

  const renderDocumentoTransporte = () => {
    if (data.tipoDocumentoTransporte === DocumentType.CPF.code) {
      return renderCpf({ field: "documentoTransporte" });
    }
    if (data.tipoDocumentoTransporte === DocumentType.CNPJ.code) {
      return renderCnpj({ field: "documentoTransporte" });
    }
    return <></>;
  };

  const renderNomeRazaoSocialTransportador = () => {
    if (data.tipoDocumentoTransporte === DocumentType.CPF.code) {
      return renderName({
        field: "razaoSocialTransporte",
        label: "Nome completo",
      });
    }
    if (data.tipoDocumentoTransporte === DocumentType.CNPJ.code) {
      return renderName({
        field: "razaoSocialTransporte",
        label: "Razão social",
      });
    }
    return <></>;
  };

  const renderEnderecoCompletoTransporte = () => (
    <GridItem xs={12} sm={12} md={4}>
      <TextInput
        id="enderecoCompletoTransporte"
        label="Endereço completo"
        value={data.enderecoCompletoTransporte}
        onChange={onChange}
        required
        errors={errors}
        maxLength={60}
        disabled={disabled}
      />
    </GridItem>
  );

  const renderPlacaVeiculoTransporte = () => (
    <GridItem xs={12} sm={12} md={4}>
      <TextInput
        id="placaVeiculoTransporte"
        label="Placa do veículo"
        value={data.placaVeiculoTransporte}
        onChange={onChange}
        errors={errors}
        maxLength={7}
        disabled={disabled}
      />
    </GridItem>
  );

  const renderRegistroNacionalTransporteCargaTransporte = () => (
    <GridItem xs={12} sm={12} md={4}>
      <TextInput
        id="registroNacionalTransporteCargaTransporte"
        label="Reg. Nac. Trans. Carga"
        value={data.registroNacionalTransporteCargaTransporte}
        onChange={onChange}
        errors={errors}
        maxLength={20}
        disabled={disabled}
      />
    </GridItem>
  );

  const renderValorFrete = () => (
    <GridItem xs={12} sm={12} md={3}>
      <CurrencyTextInput
        disabled={disabled}
        decimalPlaces={2}
        id="totalFrete"
        label="Valor total do frete"
        value={data.totalFrete || "0"}
        onChange={onChange}
      />
    </GridItem>
  );

  const renderInscricaoEstadual = ({ field }) => (
    <GridItem xs={12} sm={12} md={3}>
      <TextInput
        id={field}
        label="Inscrição estadual"
        value={data[field]}
        onChange={onChange}
        errors={errors}
        maxLength={14}
        disabled={disabled}
      />
    </GridItem>
  );

  const renderState = () => (
    <GridItem xs={12} sm={12} md={2}>
      <FormControl
        disabled={disabled}
        fullWidth
        className={classesCustomComponentsStyle.formControlSelect}
        size="small"
        variant="outlined"
        required
      >
        <InputLabel>Estado</InputLabel>
        <Select
          label="Estado"
          id="stateTransporte"
          autoWidth
          value={data.stateTransporte == null ? "" : data.stateTransporte}
          onChange={(value) => {
            onChange("stateTransporte", value.target.value);
          }}
          error={errors.stateTransporte}
        >
          {states.map((item) => (
            <MenuItem value={item.id}>{item.name}</MenuItem>
          ))}
        </Select>
        <FormHelperText style={{ color: dangerColor[0] }}>{errors.stateTransporte}</FormHelperText>
      </FormControl>
    </GridItem>
  );

  const renderMunicipio = () => (
    <GridItem xs={12} sm={12} md={3}>
      <FormControl
        disabled={disabled}
        fullWidth
        className={classesCustomComponentsStyle.formControlSelect}
        size="small"
        variant="outlined"
        required
      >
        <InputLabel>Cidade</InputLabel>
        <Select
          label="Cidade"
          id="cidadeTransporte"
          autoWidth
          value={data.cidadeTransporte == null ? "" : data.cidadeTransporte}
          onChange={(value) => {
            onChange("cidadeTransporte", value.target.value);
          }}
          disabled={disabled || cities.length === 0}
          error={errors.cidadeTransporte}
        >
          {cities.map((item) => (
            <MenuItem value={item.id}>{item.name}</MenuItem>
          ))}
        </Select>
        <FormHelperText style={{ color: dangerColor[0] }}>{errors.cidadeTransporte}</FormHelperText>
      </FormControl>
    </GridItem>
  );

  const renderSwitch = (value = false, field, label, isDisabled = false) => (
    <GridItem xs={12} sm={12} md={12}>
      <FormGroup style={{ paddingTop: "8px" }}>
        <FormControlLabel
          control={
            <Switch
              color="secondary"
              checked={value}
              onChange={(event) => onChange(field, event.target.checked)}
            />
          }
          label={label}
          disabled={isDisabled}
        />
      </FormGroup>
    </GridItem>
  );

  const importedMarker = () => (
    <GridItem xs={12} sm={12} md={2} style={{ marginTop: 18 }}>
      <Chip
        size="small"
        label="Importado"
        style={{ color: "#2196f3", backgroundColor: "#ffffff", borderColor: "#2196f3" }}
        variant="outlined"
      />
    </GridItem>
  );
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12} style={{ marginTop: 12 }}>
        <Divider style={{ width: "100%", marginBottom: 4 }} />
        <Typography style={{ fontSize: 18, fontWeight: 500 }}>Transporte</Typography>
      </GridItem>
      {renderModalidadeFreteTransporte()}
      {data.modalidadeFreteTransporte !== NFModalidadeFrete.SEM_OCORRENCIA_TRANSPORTE.code && (
        <>
          {renderTransportadora()}
          {data.transportadoraTransporte && importedMarker()}
          {renderTipoDocumentoTransporte()}
          {renderDocumentoTransporte()}
          {renderNomeRazaoSocialTransportador()}
          {renderInscricaoEstadual({ field: "inscricaoEstadualTransporte" })}
          {renderEnderecoCompletoTransporte()}
          {renderState()}
          {renderMunicipio()}
          {renderUFVeiculoTransporte()}
          {renderPlacaVeiculoTransporte()}
          {renderRegistroNacionalTransporteCargaTransporte()}
          {renderValorFrete()}
          {renderSwitch(
            data.possuiRetencaoIcmsTransporte,
            "possuiRetencaoIcmsTransporte",
            "Informar retenções de ICMS de transporte",
            disabled
          )}
        </>
      )}
    </GridContainer>
  );
};

export default React.memo(NotaFiscalTransporte);
