const ProductPriceChangeHistoryOriginType = {
  PRODUCT_CREATE: {
    name: "Cadastro do produto",
  },
  PRODUCT_EDIT: {
    name: "Edição do produto",
  },
  INVENTORY_MOVEMENT_ENTRY: {
    name: "Entrada no estoque",
  },
  INVENTORY_MOVEMENT_COST_ADJUST: {
    name: "Ajuste de custo médio no estoque",
  },
  MANUALLY: {
    name: "Manual",
  },
};

const UpdateCostType = {
  ENTRY: {
    code: "ENTRY",
    name: "Custo por entrada",
  },
  MEDIUM: {
    code: "MEDIUM",
    name: "Custo médio",
  },
  NOT_UPDATE: {
    code: "NOT_UPDATE",
    name: "Não atualizado",
  },
  MANUALLY: {
    name: "Manual",
  },
};

const UpdatePriceType = {
  MARGIN: {
    code: "MARGIN",
    name: "Margem",
  },
  CUSTOM: {
    code: "CUSTOM",
    name: "Personalizado",
  },
  NOT_UPDATE: {
    code: "NOT_UPDATE",
    name: "Não atualizado",
  },
  MANUALLY: {
    name: "Manual",
  },
};

const UpdatePriceMinimumType = {
  MARGIN: {
    code: "MARGIN",
    name: "Percentual de aumento",
  },
  CUSTOM: {
    code: "CUSTOM",
    name: "Personalizado",
  },
  NOT_UPDATE: {
    code: "NOT_UPDATE",
    name: "Não atualizado",
  },
  MANUALLY: {
    name: "Manual",
  },
};

class ProductPriceChangeHistory {
  constructor(source = {}) {
    if (source == null) return this;

    this.product = source.product;
    this.inventoryMovement = source.inventoryMovement;
    this.price = source.price;
    this.newPrice = source.newPrice;
    this.cost = source.cost;
    this.newCost = source.newCost;
    this.priceMinimum = source.priceMinimum;
    this.newPriceMinimum = source.newPriceMinimum;
    this.updateCostType = source.updateCostType;
    this.updatePriceType = source.updatePriceType;
    this.updatePriceMinimumType = source.updatePriceMinimumType;
    this.originType = source.originType;
    this.user = source.user;
  }
}

export {
  ProductPriceChangeHistory,
  ProductPriceChangeHistoryOriginType,
  UpdateCostType,
  UpdatePriceType,
  UpdatePriceMinimumType,
};
