import axios from "axios";
import { APIS } from "../../../config/constants";
import BaseService from "../../../core/BaseService";

const { register: registerAPI } = APIS;

class CompanyService extends BaseService {
  constructor() {
    super(registerAPI.company);
  }

  fetch = () => axios.get(`${this.endpoint}/current`);

  fetchStates = () => axios.get(`${registerAPI.state}?size=50`);

  fetchCitiesByState = (stateId) => axios.get(`${registerAPI.city}/state/${stateId}`);

  uploadLogo = (file) =>
    axios.post(`${this.endpoint}/logo`, file, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

  deleteLogo = () => axios.delete(`${this.endpoint}/logo`);

  loadLogo = () =>
    axios.get(`${this.endpoint}/logo/thumbnail`, {
      responseType: "arraybuffer",
    });
}

export default new CompanyService();
