/* eslint-disable react/prop-types */
import React from "react";
import moment from "moment";
import GridContainer from "components/Grid/GridContainer";
import { faBell } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  List,
  ListItem,
  Box,
  Divider,
} from "@mui/material";
import { primaryColor } from "../../assets/jss/material-dashboard-react";

const NotificationModal = ({ open, onClose, notifications }) => {
  const renderGrid = () => (
    <List style={{ width: "100%" }}>
      {notifications && notifications.length === 0 && "Sem notificações."}
      {notifications.map(({ startDate, text }) => (
        <>
          <ListItem disablePadding>
            <Box display="flex" flexDirection="row" justifyContent="space-between" width="100%">
              <Box style={{ paddingRight: 10, alignSelf: "center" }}>
                <FontAwesomeIcon icon={faBell} color={primaryColor[0]} />
              </Box>
              <Box width="10%" style={{ alignSelf: "center", minWidth: 100 }}>
                {moment(startDate).format("DD/MM/YYYY")}
              </Box>
              <Box width="85%">{text}</Box>
            </Box>
          </ListItem>
          <Divider style={{ width: "100%" }} />
        </>
      ))}
    </List>
  );

  return (
    <>
      <Dialog disableEscapeKeyDown open={open} fullWidth maxWidth="md">
        <DialogTitle>Notificações</DialogTitle>
        <DialogContent>
          <GridContainer>{renderGrid()}</GridContainer>
        </DialogContent>
        <DialogActions>
          <div style={{ flexGrow: 1, display: "flex", justifyContent: "space-between" }}>
            <Button style={{ color: primaryColor[0] }} onClick={onClose}>
              Voltar
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default React.memo(NotificationModal);
