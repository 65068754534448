/* eslint-disable no-new */
import React, { useState, useCallback, useEffect } from "react";
import moment from "moment";
import { makeStyles } from "@mui/styles";
import CardStyle from "assets/jss/material-dashboard-react/components/cardStyle";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import Button from "components/CustomButtons/Button";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import CardFooter from "components/Card/CardFooter";
import { useHistory, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import AddressService from "components/address/service/AddressService";
import { Box, Paper } from "@mui/material";
import HistoryIcon from "components/icons/HistoryIcon";
import NotaFiscalDadosGerais from "views/notaFiscal/components/NotaFiscalDadosGerais";
import NotaFiscalCancelamentoModal from "views/notaFiscal/components/dialog/NotaFiscalCancelamentoModal";
import NotaFiscalTotais from "views/notaFiscal/components/NotaFiscalTotais";
import NotaFiscalVolumes from "views/notaFiscal/components/NotaFiscalVolumes";
import NotaFiscalFormaPagamento from "views/notaFiscal/components/NotaFiscalFormaPagamento";
import NotaFiscalRetencaoIcmsTransporte from "views/notaFiscal/components/NotaFiscalRetencaoIcmsTransporte";
import NotaFiscalTransporte from "views/notaFiscal/components/NotaFiscalTransporte";
import NotaFiscalProduto from "views/notaFiscal/components/NotaFiscalProduto";
import NotaFiscalEnderecoEntrega from "views/notaFiscal/components/NotaFiscalEnderecoEntrega";
import NotaFiscalHistorico from "views/notaFiscal/components/dialog/NotaFiscalHistorico";
import DropDownButton from "components/dropDownButton/DropDownButton";
import NotaFiscalDadosEmitente from "views/notaFiscal/components/NotaFiscalDadosEmitente";
import NotaFiscalDadosDestinatario from "views/notaFiscal/components/NotaFiscalDadosDestinatario";
import { NaturezaOperacaoPadraoType } from "views/naturezaOperacao/model/NaturezaOperacao";
import NaturezaOperacaoService from "views/naturezaOperacao/service/NaturezaOperacaoService";
import NotaFiscalEnvioEmailModal from "views/notaFiscal/components/dialog/NotaFiscalEnvioEmailModal";
import {
  NotaFiscal,
  NFFinalidade,
  NFTipo,
  NFOperacaoConsumidorFinal,
  NFIdentificadorLocalDestinoOperacao,
  NFIndicadorPresencaComprador,
  DocumentType,
  NFModalidadeFrete,
  NFIndicadorIEDestinatario,
  NotaFiscalStatusType,
  NotaFiscalImportacaoOrigemType,
  NFModelo,
} from "../notaFiscal/model/NotaFiscal";
import { fromResponse, toRequest } from "../notaFiscal/converter/NotaFiscalConverter";
import NotaFiscalService from "../notaFiscal/service/NotaFiscalService";
import { handlingResponseErrors } from "../../config/util";
import MESSAGES from "../../config/messages";
import Loading from "../../components/loading/Loading";
import NFeImportacaoService from "./service/NFeImportacaoService";
import NFeEntradaLancarDespesas from "./components/importarXML/NFeEntradaLancarDespesas";

const { generalMessages } = MESSAGES;

const useCardStyle = makeStyles(CardStyle);

// eslint-disable-next-line react/prop-types
export default function NFeEntradaForm({ onCancel }) {
  const [loading, setLoading] = useState(false);
  const [motivoCancelamento, setMotivoCancelamento] = useState("");
  const [openSendEmail, setOpenSendEmail] = useState(false);
  const [openCancelamentoNotaFiscalModal, setOpenCancelamentoNotaFiscalModal] = useState(false);
  const classesCardStyle = useCardStyle();
  const history = useHistory();
  const routerParams = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [errors, setErrors] = useState({});
  const [showButtonsImport, setShowButtonsImport] = useState({
    fornecedor: true,
    transportadoraTransporte: true,
    despesas: true,
  });
  const [states, setStates] = useState([]);
  const [readOnly, setReadOnly] = useState(null);
  const [notaFiscalHistoricoList, setNotaFiscalHistoricoList] = useState([]);
  const [naturezasOperacao, setNaturezasOperacao] = useState([]);

  const getDadosGeraisInitial = () => ({
    tipo: NFTipo.ENTRADA.code,
    modelo: NFModelo.NFE.code,
    operacaoConsumidorFinal: NFOperacaoConsumidorFinal.SIM.code,
    finalidade: NFFinalidade.NORMAL.code,
    identificadorLocalDestinoOperacao: NFIdentificadorLocalDestinoOperacao.OPERACAO_INTERNA.code,
    indicadorPresencaComprador: NFIndicadorPresencaComprador.OPERACAO_PRESENCIAL.code,
    desconto: 0,
  });

  const getDadosEmitente = () => ({
    tipoDocumentoEmitente: DocumentType.CNPJ.code,
    possuiEnderecoEntrega: false,
    indicadorIeEmitente: NFIndicadorIEDestinatario.NAO_CONTRIBUINTE.code,
  });

  const getDadosTransporte = () => ({
    tipoDocumentoTransporte: DocumentType.CNPJ.code,
    modalidadeFreteTransporte: NFModalidadeFrete.SEM_OCORRENCIA_TRANSPORTE.code,
  });

  const getDadosVolumes = () => ({});

  const getDadosTotais = () => ({});

  const getDadosEnderecoEntrega = () => ({
    tipoDocumentoEnderecoEntrega: DocumentType.CPF.code,
  });

  const getDadosRetencaoIcmsTransporte = () => ({});

  const getDadosFormaPagamentoList = () => [
    {
      index: 0,
      vencimento: moment(),
    },
  ];

  const getDadosNotaFiscalProdutos = () => [];

  const getDadosDestinatario = () => ({
    tipoDocumentoDestinatario: DocumentType.CNPJ.code,
    possuiEnderecoEntrega: false,
    indicadorIeDestinatario: NFIndicadorIEDestinatario.NAO_CONTRIBUINTE.code,
  });

  const [nfeEntrada, setNfeEntrada] = useState(
    new NotaFiscal({
      id: null,
      active: true,
    })
  );
  const [dadosGerais, setDadosGerais] = useState(getDadosGeraisInitial());
  const [dadosEmitente, setDadosEmitente] = useState(getDadosEmitente());
  const [dadosDestinatario, setDadosDestinatario] = useState(getDadosDestinatario());
  const [dadosTransporte, setDadosTransporte] = useState(getDadosTransporte());
  const [dadosVolume, setDadosVolume] = useState(getDadosVolumes());
  const [dadosTotais, setDadosTotais] = useState(getDadosTotais());
  const [dadosEnderecoEntrega, setDadosEnderecoEntrega] = useState(getDadosEnderecoEntrega());
  const [dadosRetencaoIcmsTransporte, setDadosRetencaoIcmsTransporte] = useState(
    getDadosRetencaoIcmsTransporte()
  );
  const [dadosFormaPagamentoList, setDadosFormaPagamentoList] = useState(
    getDadosFormaPagamentoList()
  );

  const [dadosNotaFiscalProdutos, setDadosNotaFiscalProdutos] = useState(
    getDadosNotaFiscalProdutos()
  );
  const [ambiente, setAmbiente] = useState();

  const [openHistoricoNotaFiscalModal, setOpenHistoricoNotaFiscalModal] = useState(false);
  const [openLancarDespesasModal, setOpenLancarDespesasModal] = useState(false);

  const fetchStates = useCallback(async () => {
    try {
      setLoading(true);
      const result = await AddressService.fetchStates();
      const { data } = result;
      const { content } = data;
      setStates(content);
    } finally {
      setLoading(false);
    }
  }, []);

  const fetchNaturezasOperacao = useCallback(async () => {
    const result = await NaturezaOperacaoService.fetchAllWithoutTerm(NFTipo.ENTRADA.code);
    const { data: dResult } = result;
    const naturezaList = dResult?.content || [];
    setNaturezasOperacao(naturezaList);
    const naturezaOperacao = naturezaList.find(
      (item) => item.padrao === NaturezaOperacaoPadraoType.COMPRA.code
    );
    if (naturezaOperacao) {
      setDadosGerais((prevDadosGerais) => {
        const newDadosGerais = {
          ...prevDadosGerais,
          naturezaOperacao,
        };

        return newDadosGerais;
      });
    }
  }, []);

  const fetchAmbiente = useCallback(async () => {
    const result = await NotaFiscalService.ambiente();
    setAmbiente(result?.data);
  }, []);

  useEffect(() => {
    fetchAmbiente();
    fetchStates();
    fetchNaturezasOperacao();
  }, [fetchStates, fetchNaturezasOperacao, fetchAmbiente]);

  const isCancelada = useCallback((status) => NotaFiscalStatusType.CANCELADA.code === status, []);

  const isNotaImportada = useCallback(() => nfeEntrada.notaFiscalImportada, [nfeEntrada]);

  const isEmitida = useCallback((status) => NotaFiscalStatusType.EMITIDA.code === status, []);

  const isNew = useCallback((id) => id == null, []);

  const getById = useCallback(
    async (id) => {
      if (id != null) {
        const result = await NotaFiscalService.fetchById(id);
        const response = fromResponse(result?.data?.data);
        const {
          dadosGerais: dadosGeraisResponse,
          dadosEmitente: dadosEmitenteResponse,
          dadosDestinatario: dadosDestinatarioResponse,
          notaFiscalFormaPagamentos: notaFiscalFormaPagamentosResponse,
          dadosVolume: dadosVolumeResponse,
          dadosTransporte: dadosTransporteResponse,
          dadosRetencaoIcmsTransporte: dadosRetencaoIcmsTransporteResponse,
          dadosEnderecoEntrega: dadosEnderecoEntregaResponse,
          notaFiscalProdutos: notaFiscalProdutosResponse,
          ...rest
        } = response;

        setNfeEntrada(rest);
        setDadosGerais(dadosGeraisResponse);
        setDadosEmitente(dadosEmitenteResponse);
        setDadosDestinatario(dadosDestinatarioResponse);
        setDadosFormaPagamentoList(notaFiscalFormaPagamentosResponse);
        setDadosVolume(dadosVolumeResponse);
        setDadosTransporte(dadosTransporteResponse);
        setDadosRetencaoIcmsTransporte(dadosRetencaoIcmsTransporteResponse);
        setDadosEnderecoEntrega(dadosEnderecoEntregaResponse);
        setDadosNotaFiscalProdutos(notaFiscalProdutosResponse);

        const { status } = rest;
        const readOnlyByStatus = isEmitida(status) || isCancelada(status);
        setReadOnly(readOnlyByStatus);

        const { fornecedor, despesaImportada } = rest;
        const { transportadoraTransporte } = dadosTransporteResponse;
        setShowButtonsImport({
          fornecedor:
            response?.notaFiscalImportada == null
              ? response?.dadosDestinatario?.cliente == null
              : fornecedor == null,
          transportadoraTransporte:
            dadosTransporteResponse?.modalidadeFreteTransporte !== "SEM_OCORRENCIA_TRANSPORTE" &&
            transportadoraTransporte == null,
          despesas: !despesaImportada,
        });
      } else {
        setReadOnly(false);
      }
    },
    [isCancelada, isEmitida]
  );

  const fetchById = useCallback(async () => {
    try {
      setLoading(true);
      const { id } = routerParams || {};
      await getById(id);
    } finally {
      setLoading(false);
    }
  }, [getById, routerParams]);

  useEffect(() => {
    fetchById();
  }, [fetchById]);

  const handleChangeDadosGerais = useCallback((value) => setDadosGerais(value), []);

  const handleChangeDadosEmitente = useCallback((value) => setDadosEmitente(value), []);

  const handleChangeDadosDestinatario = useCallback((value) => setDadosDestinatario(value), []);

  const handleChangeDadosTransporte = useCallback((value) => setDadosTransporte(value), []);

  const handleChangeDadosVolume = useCallback((value) => setDadosVolume(value), []);

  const handleChangeEnderecoEntrega = useCallback((value) => setDadosEnderecoEntrega(value), []);

  const handleChangeDadosRetencaoIcmsTransporte = useCallback(
    (value) => setDadosRetencaoIcmsTransporte(value),
    []
  );

  const handleFormaPagamentoList = useCallback((value) => setDadosFormaPagamentoList(value), []);

  const handleChangeTotais = useCallback(
    (totais) => {
      const { totalNf } = totais;
      handleFormaPagamentoList((prevList) => {
        if (prevList.length === 1) {
          const newDadosFormaPagamentoList = [...prevList];

          newDadosFormaPagamentoList[0].valor = totalNf;
          return newDadosFormaPagamentoList;
        }
        return prevList;
      });

      setDadosTotais(totais);
    },
    [handleFormaPagamentoList]
  );

  const handleNotaFiscalEntradaProdutos = useCallback(
    (value) => setDadosNotaFiscalProdutos(value),
    []
  );

  const goBack = useCallback(() => {
    if (onCancel) {
      onCancel();
    } else {
      history.push("/app/nfe-entradas");
    }
  }, [history, onCancel]);

  const save = useCallback(async () => {
    const { id } = nfeEntrada;
    let response = null;

    if (id != null) {
      response = await NotaFiscalService.update(
        id,
        toRequest({
          entity: nfeEntrada,
          dadosGerais,
          dadosEmitente,
          dadosDestinatario,
          dadosEnderecoEntrega,
          dadosTransporte,
          dadosRetencaoIcmsTransporte,
          dadosVolume,
          dadosFormaPagamentoList,
          dadosNotaFiscalProdutos,
        })
      );
    } else {
      response = await NotaFiscalService.save(
        toRequest({
          entity: nfeEntrada,
          dadosGerais,
          dadosEmitente,
          dadosDestinatario,
          dadosEnderecoEntrega,
          dadosTransporte,
          dadosRetencaoIcmsTransporte,
          dadosVolume,
          dadosFormaPagamentoList,
          dadosNotaFiscalProdutos,
        })
      );
    }
    return response;
  }, [
    dadosEmitente,
    dadosDestinatario,
    dadosEnderecoEntrega,
    dadosFormaPagamentoList,
    dadosGerais,
    dadosNotaFiscalProdutos,
    dadosRetencaoIcmsTransporte,
    dadosTransporte,
    dadosVolume,
    nfeEntrada,
  ]);

  const onEmitir = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await save();
      const id = data?.data?.id || null;
      /*
       Após salvar, seta o id, pois se a emissão falhar, a nota vai ficar sem id e na proxima tentativa vai criar uma nova (duplicando) 
       */
      setNfeEntrada({
        ...nfeEntrada,
        id,
      });

      try {
        await NotaFiscalService.emitir(id);
        enqueueSnackbar("Nota fiscal emitida com sucesso!", {
          variant: "success",
          autoHideDuration: 3000,
        });
        setLoading(false);
        goBack();
      } catch (error) {
        /*
          Caso a emissão não funcionar, o version da entidade está mudando, precisa recarregar. 
          Assim, na proxima tentativa o version estará atualizado
        */
        getById(id);
        const { message } = handlingResponseErrors(error);
        enqueueSnackbar(message, {
          variant: "error",
          autoHideDuration: 3000,
        });
        setLoading(false);
      }
    } catch (error) {
      const { message } = handlingResponseErrors(error);
      enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
      setLoading(false);
    }
  }, [save, nfeEntrada, enqueueSnackbar, goBack, getById]);

  const onSave = useCallback(async () => {
    try {
      setLoading(true);
      await save();
      enqueueSnackbar("Nota fiscal salva com sucesso!", {
        variant: "success",
        autoHideDuration: 3000,
      });
      goBack();
    } catch (error) {
      const { message, validationErrors } = handlingResponseErrors(error);
      enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
      if (validationErrors != null) {
        setErrors(validationErrors);
      }
    } finally {
      setLoading(false);
    }
  }, [save, enqueueSnackbar, goBack]);

  const onCancelarNotaFiscal = useCallback(async () => {
    try {
      setLoading(true);

      const { id } = nfeEntrada;
      await NotaFiscalService.cancelarNotaFiscal(id, motivoCancelamento);
      enqueueSnackbar("Nota fiscal cancelada com sucesso!", {
        variant: "success",
        autoHideDuration: 3000,
      });
      goBack();
    } catch (error) {
      const { message, validationErrors } = handlingResponseErrors(error);
      enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
      if (validationErrors != null) {
        setErrors(validationErrors);
      }
    } finally {
      setLoading(false);
    }
  }, [nfeEntrada, motivoCancelamento, enqueueSnackbar, goBack]);

  const onPrintDanfe = useCallback(async () => {
    try {
      setLoading(true);

      const { id } = nfeEntrada;
      const response = await NotaFiscalService.printDanfe(id);
      const fileName = response?.headers["file-name"] || "DANFE.pdf";
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();

      // const file = new Blob([response.data], { type: "application/pdf" });
      // const fileURL = URL.createObjectURL(file);
      // const pdfWindow = window.open();
      // pdfWindow.location.href = fileURL;
    } catch (error) {
      if (
        error.request.responseType === "blob" &&
        error.response.data instanceof Blob &&
        error.response.data.type &&
        error.response.data.type.toLowerCase().indexOf("json") !== -1
      ) {
        new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = () => {
            const message = error.response.status === 428 ? reader?.result : generalMessages.error;
            enqueueSnackbar(message, {
              variant: "error",
              autoHideDuration: 3000,
            });
          };

          reader.onerror = () => {
            reject(error);
          };

          reader.readAsText(error.response.data);
        });
      }
    } finally {
      setLoading(false);
    }
  }, [nfeEntrada, enqueueSnackbar]);

  const handleOpenHistoricoNotaFiscalModal = useCallback(async () => {
    setOpenHistoricoNotaFiscalModal(true);
    try {
      setLoading(true);
      const { data } = await NotaFiscalService.fetchHistorico(nfeEntrada.id);
      const historicoList = data?.data || [];
      setNotaFiscalHistoricoList(historicoList);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [nfeEntrada?.id]);

  const handleCloseHistoricoNotaFiscalModal = useCallback(() => {
    setOpenHistoricoNotaFiscalModal(false);
    setNotaFiscalHistoricoList([]);
  }, []);

  const handleCloseLancarDespesasModal = useCallback(() => {
    setOpenLancarDespesasModal(false);
  }, []);

  const handleOpenLancarDespesasModal = useCallback(() => {
    setOpenLancarDespesasModal(true);
  }, []);

  const goLancarEstoque = useCallback(() => {
    const { fornecedor } = nfeEntrada;
    if (
      (nfeEntrada?.notaFiscalImportada != null && fornecedor == null) ||
      (nfeEntrada?.notaFiscalImportada == null && dadosDestinatario?.cliente == null)
    ) {
      enqueueSnackbar("Antes de lançar estoque, por favor, importe o fornecedor!", {
        variant: "info",
        autoHideDuration: 5000,
      });
    } else if (
      nfeEntrada?.notaFiscalImportada == null &&
      nfeEntrada?.status !== NotaFiscalStatusType.EMITIDA.code
    ) {
      enqueueSnackbar("Antes de lançar estoque, é necessário emitir a nota!", {
        variant: "info",
        autoHideDuration: 5000,
      });
    } else {
      history.push(`/app/nfe-entrada-lancar-estoque/${nfeEntrada?.id}`);
    }
  }, [enqueueSnackbar, history, nfeEntrada, dadosDestinatario]);

  const goImportarFornecedor = useCallback(async () => {
    try {
      setLoading(true);

      const { id } = nfeEntrada;
      const response = await NFeImportacaoService.importarFornecedor(id);
      const { data } = response?.data;
      const fornecedor = data?.fornecedor;
      if (fornecedor) {
        setNfeEntrada({
          ...nfeEntrada,
          fornecedor,
        });
      }
      enqueueSnackbar("Fornecedor importado com sucesso!", {
        variant: "success",
        autoHideDuration: 3000,
      });
      setShowButtonsImport({
        ...showButtonsImport,
        fornecedor: false,
      });
    } catch (error) {
      const { message } = handlingResponseErrors(error);
      enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    } finally {
      setLoading(false);
    }
  }, [enqueueSnackbar, nfeEntrada, showButtonsImport]);

  const goImportarTransportadora = useCallback(async () => {
    try {
      setLoading(true);

      const { id } = nfeEntrada;
      await NFeImportacaoService.importarTransportador(id);
      enqueueSnackbar("Transportador importado com sucesso!", {
        variant: "success",
        autoHideDuration: 3000,
      });
      setShowButtonsImport({
        ...showButtonsImport,
        transportadoraTransporte: false,
      });
    } catch (error) {
      const { message } = handlingResponseErrors(error);
      enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    } finally {
      setLoading(false);
    }
  }, [enqueueSnackbar, nfeEntrada, showButtonsImport]);

  const getOptions = useCallback(() => {
    const { fornecedor, transportadoraTransporte, despesas } = showButtonsImport;

    const items = [];

    items.push({ label: "Lançar estoque", action: goLancarEstoque });

    if (despesas) items.push({ label: "Lançar despesas", action: handleOpenLancarDespesasModal });

    if (fornecedor) items.push({ label: "Importar fornecedor", action: goImportarFornecedor });

    if (transportadoraTransporte)
      items.push({ label: "Importar transportadora", action: goImportarTransportadora });

    return items;
  }, [
    showButtonsImport,
    goLancarEstoque,
    handleOpenLancarDespesasModal,
    goImportarFornecedor,
    goImportarTransportadora,
  ]);

  const getDadosDestinarioOuEmitente = useCallback(() => {
    const origemImportacao = nfeEntrada?.notaFiscalImportada?.origemImportacao;
    if (origemImportacao != null) {
      if (
        origemImportacao === NotaFiscalImportacaoOrigemType.UPLOAD_XML.code ||
        origemImportacao === NotaFiscalImportacaoOrigemType.AUTOMATICA.code
      ) {
        return (
          <NotaFiscalDadosEmitente
            disabled={readOnly}
            data={dadosEmitente}
            errors={errors}
            states={states}
            handleChange={handleChangeDadosEmitente}
          />
        );
      }
    }
    return (
      <NotaFiscalDadosDestinatario
        disabled={readOnly}
        data={dadosDestinatario}
        errors={errors}
        states={states}
        handleChange={handleChangeDadosDestinatario}
      />
    );
  }, [
    dadosDestinatario,
    dadosEmitente,
    errors,
    handleChangeDadosDestinatario,
    handleChangeDadosEmitente,
    nfeEntrada?.notaFiscalImportada?.origemImportacao,
    readOnly,
    states,
  ]);

  const onDownloadXML = useCallback(async () => {
    try {
      setLoading(true);

      const { id } = nfeEntrada;
      const response = await NotaFiscalService.xml(id);
      const fileName = response?.headers["file-name"] || "nota_fiscal.xml";
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();

      // const file = new Blob([response.data], { type: "application/pdf" });
      // const fileURL = URL.createObjectURL(file);
      // const pdfWindow = window.open();
      // pdfWindow.location.href = fileURL;
    } catch (error) {
      if (
        error.request.responseType === "blob" &&
        error.response.data instanceof Blob &&
        error.response.data.type &&
        error.response.data.type.toLowerCase().indexOf("json") !== -1
      ) {
        new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = () => {
            const message = error.response.status === 428 ? reader?.result : generalMessages.error;
            enqueueSnackbar(message, {
              variant: "error",
              autoHideDuration: 3000,
            });
          };

          reader.onerror = () => {
            reject(error);
          };

          reader.readAsText(error.response.data);
        });
      }
    } finally {
      setLoading(false);
    }
  }, [nfeEntrada, enqueueSnackbar]);

  return (
    <>
      <GridContainer style={{ justifyContent: "center" }}>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classesCardStyle.cardTitle}>Nota fiscal de entrada</h4>
              <p className={classesCardStyle.cardCategory}>Entrada de novas notas fiscais</p>
            </CardHeader>
            <CardBody>
              {readOnly === false && ambiente === "HOMOLOGACAO" && (
                <Paper
                  style={{
                    padding: "16px",
                    marginBottom: "16px",
                    backgroundColor: "#FCE4EC",
                    borderRadius: "8px",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  <strong>Atenção:</strong> Esta nota fiscal está sendo emitida em ambiente de
                  homologação.
                </Paper>
              )}

              <Box
                display="flex"
                style={{
                  backgroundColor: "transparent",
                  padding: "8px",
                  borderRadius: "3px",
                  justifyContent: "flex-end",
                }}
              >
                {!isNew(nfeEntrada.id) && (
                  <HistoryIcon
                    onClick={handleOpenHistoricoNotaFiscalModal}
                    style={{ padding: 6 }}
                  />
                )}
                {((nfeEntrada?.notaFiscalImportada != null && nfeEntrada?.id) ||
                  (nfeEntrada?.notaFiscalImportada == null &&
                    nfeEntrada?.status === NotaFiscalStatusType.EMITIDA.code)) && (
                  <DropDownButton options={getOptions()} />
                )}
              </Box>
              <NotaFiscalDadosGerais
                disabled={readOnly}
                data={dadosGerais}
                errors={errors}
                handleChange={handleChangeDadosGerais}
                naturezasOperacao={naturezasOperacao}
              />

              {getDadosDestinarioOuEmitente()}

              {dadosEmitente.possuiEnderecoEntrega && (
                <NotaFiscalEnderecoEntrega
                  disabled={readOnly}
                  data={dadosEnderecoEntrega}
                  states={states}
                  errors={errors}
                  handleChange={handleChangeEnderecoEntrega}
                />
              )}

              <NotaFiscalProduto
                disabled={readOnly}
                naturezaOperacao={dadosGerais.naturezaOperacao}
                items={dadosNotaFiscalProdutos}
                errors={errors}
                handleChange={handleNotaFiscalEntradaProdutos}
                handleChangeTotais={handleChangeTotais}
                dadosGerais={dadosGerais}
                dadosTransporte={dadosTransporte}
                dadosDestinatario={dadosDestinatario}
                handleChangeDadosGerais={handleChangeDadosGerais}
              />

              <NotaFiscalFormaPagamento
                disabled={readOnly}
                items={dadosFormaPagamentoList}
                errors={errors}
                handleChange={handleFormaPagamentoList}
              />

              <NotaFiscalTransporte
                disabled={readOnly}
                data={dadosTransporte}
                errors={errors}
                handleChange={handleChangeDadosTransporte}
                states={states}
                handleChangeDadosVolume={handleChangeDadosVolume}
              />

              {dadosTransporte.possuiRetencaoIcmsTransporte && (
                <NotaFiscalRetencaoIcmsTransporte
                  disabled={readOnly}
                  data={dadosRetencaoIcmsTransporte}
                  states={states}
                  errors={errors}
                  handleChange={handleChangeDadosRetencaoIcmsTransporte}
                />
              )}

              {dadosTransporte.modalidadeFreteTransporte !==
                NFModalidadeFrete.SEM_OCORRENCIA_TRANSPORTE.code && (
                <NotaFiscalVolumes
                  disabled={readOnly}
                  data={dadosVolume}
                  errors={errors}
                  handleChange={handleChangeDadosVolume}
                />
              )}

              <NotaFiscalTotais
                data={dadosTotais}
                errors={errors}
                handleChange={handleChangeTotais}
              />

              <NotaFiscalCancelamentoModal
                open={openCancelamentoNotaFiscalModal}
                onClose={() => setOpenCancelamentoNotaFiscalModal(false)}
                onCancel={onCancelarNotaFiscal}
                value={motivoCancelamento}
                handleChange={(field, value) => setMotivoCancelamento(value)}
                errors={errors}
              />
            </CardBody>
            <CardFooter>
              <Box display="flex" justifyContent="space-between" width="100%">
                <Box display="flex">
                  <Button color="primary" onClick={goBack}>
                    {generalMessages.cancel}
                  </Button>
                </Box>
                {/* <Box display="flex">{renderButtons()}</Box> */}
                <Box display="flex">
                  {!isCancelada(nfeEntrada.status) &&
                    (readOnly ? (
                      !isNotaImportada() && (
                        <>
                          <Button
                            style={{ backgroundColor: "#ed0000" }}
                            onClick={() => setOpenCancelamentoNotaFiscalModal(true)}
                          >
                            Cancelar Nota Fiscal
                          </Button>
                          <Button color="primary" onClick={onPrintDanfe}>
                            Danfe
                          </Button>
                          <Button color="primary" onClick={() => setOpenSendEmail(true)}>
                            Enviar e-mail
                          </Button>
                          <Button color="primary" onClick={onDownloadXML}>
                            XML
                          </Button>
                        </>
                      )
                    ) : (
                      <>
                        <Button color="primary" onClick={onSave}>
                          {generalMessages.save}
                        </Button>
                        <Button color="primary" onClick={onEmitir}>
                          Salvar e emitir
                        </Button>
                      </>
                    ))}
                </Box>
              </Box>
            </CardFooter>
          </Card>
        </GridItem>
        <NotaFiscalHistorico
          open={openHistoricoNotaFiscalModal}
          onClose={handleCloseHistoricoNotaFiscalModal}
          items={notaFiscalHistoricoList}
        />
        <NFeEntradaLancarDespesas
          open={openLancarDespesasModal}
          onClose={handleCloseLancarDespesasModal}
          idNfe={nfeEntrada?.id}
          dadosFormaPagamentoList={dadosFormaPagamentoList}
          setShowButtonsImport={setShowButtonsImport}
        />

        <NotaFiscalEnvioEmailModal
          notaFiscalId={nfeEntrada?.id}
          emailInit={dadosDestinatario?.emailDestinatario}
          onClose={() => setOpenSendEmail(false)}
          open={openSendEmail}
        />
        <Loading loading={loading} />
      </GridContainer>
    </>
  );
}
