/* eslint-disable react/prop-types */
import React, { useState, useEffect, useCallback } from "react";
import { makeStyles } from "@mui/styles";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import { useHistory } from "react-router-dom";
import CardStyle from "assets/jss/material-dashboard-react/components/cardStyle";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  Typography,
  Grid as GridMUI,
} from "@mui/material";
import customComponentsStyle from "assets/jss/material-dashboard-react/components/customComponentsStyle";
import BigNumber from "bignumber.js";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SelectEnum from "components/selectEnum/SelectEnum";
import TextInput from "components/textInput/TextInput";
import CurrencyTextInput from "components/textInput/CurrencyTextInput";
import { NFOrigem, YesNo } from "views/naturezaOperacao/model/NaturezaOperacao";
import { primaryColor } from "assets/jss/material-dashboard-react";
import Autocomplete from "../../../../components/autocomplete/Autocomplete";
import UnitMeasurementService from "../../../unitMeasurement/service/UnitMeasurementService";
import UnitConversionService from "../../../unitConversion/service/UnitConversionService";

const useCardStyle = makeStyles(CardStyle);

const NFeLancarEstoqueProdutoNovo = ({ item, errors, handleChange, margemProdutos }) => {
  const classesuseCardStyle = useCardStyle();
  const useCustomComponentsStyle = makeStyles(customComponentsStyle);
  const classesCustomComponentsStyle = useCustomComponentsStyle();
  const history = useHistory();

  const { id: index, produtoJaImportado } = item;

  function recalculaPrecos(value, i) {
    const quantidadeComercial = new BigNumber(item.quantidadeComercial);
    const quantidadeImportada = new BigNumber(value);
    const valorUnitario = new BigNumber(item?.notaFiscal?.valorUnitario);
    const margemPreco = new BigNumber(margemProdutos?.margemPreco);
    const margemPrecoMinimo = new BigNumber(margemProdutos?.margemPrecoMinimo);

    const custo = valorUnitario.dividedBy(quantidadeImportada.dividedBy(quantidadeComercial));
    const precoNovo = custo.plus(custo.multipliedBy(margemPreco.dividedBy(100)));
    const precoMinimoNovo = custo.plus(custo.multipliedBy(margemPrecoMinimo.dividedBy(100)));

    handleChange("custo", custo, i);
    handleChange("precoMinimo", precoMinimoNovo, i);
    handleChange("preco", precoNovo, i);
  }

  const handleChangeUnidadeMedida = useCallback(
    async (value, i) => {
      handleChange("unidadeImportada", value, i);

      if(value) {
        const result = await UnitConversionService.fatorConversao(item.unidadeComercial, value);
        const quantidadeComercial = new BigNumber(item.quantidadeComercial);
        const fatorConversao = new BigNumber(result?.data?.conversionFactor);
        const quantidadeImportada = quantidadeComercial.multipliedBy(fatorConversao);
        handleChange("quantidadeImportada", quantidadeImportada, i);
        recalculaPrecos(quantidadeImportada, i)
      } else {
        handleChange("quantidadeImportada", 0, i);
        recalculaPrecos(0, i)
      }
    },
    [handleChange, margemProdutos]
  );

  const handleChangeQuantidadeImportada = useCallback(
    async (value, i) => {
      handleChange("quantidadeImportada", value, i);

      if(value) {
        recalculaPrecos(value, i);
      }
    },
    [handleChange, margemProdutos]
  );

  const renderText = (text) => (
    <>
      <Typography style={{ fontWeight: 500, fontSize: 18 }}>{text}</Typography>
    </>
  );

  const renderCustoMedio = () => (
    <GridItem xs={12} sm={12} md={4}>
      {renderText("Custo médio")}
      <CurrencyTextInput
        id="custo"
        value={item.custo}
        onChange={(field, value) => handleChange(field, value, index)}
        errors={errors}
      />
    </GridItem>
  );

  const renderValorMinimoVenda = () => (
    <GridItem xs={12} sm={12} md={4} style={{ borderLeft: "solid 1px", borderColor: "#c2c2c2" }}>
      {renderText("Valor mínimo de venda")}
      <CurrencyTextInput
        id="precoMinimo"
        value={item.precoMinimo}
        onChange={(field, value) => handleChange(field, value, index)}
        errors={errors}
      />
    </GridItem>
  );

  const renderValorVenda = () => (
    <GridItem xs={12} sm={12} md={4} style={{ borderLeft: "solid 1px", borderColor: "#c2c2c2" }}>
      {renderText("Valor de venda")}
      <CurrencyTextInput
        id="preco"
        value={item.preco}
        onChange={(field, value) => handleChange(field, value, index)}
        errors={errors}
      />
    </GridItem>
  );

  const marker = () => (
    <GridItem xs={12} sm={12} md={2}>
      <Box style={{ display: "flex", justifyContent: "flex-end" }}>
        <Chip
          size="small"
          label={`#${item.counter} Não importado`}
          style={{
            color: "#000000",
            backgroundColor: "#e8ea4c",
            borderColor: "#e8ea4c",
          }}
          variant="outlined"
        />
      </Box>
    </GridItem>
  );

  const importedMarker = () => (
    <GridItem xs={12} sm={12} md={2}>
      <Box style={{ display: "flex", justifyContent: "flex-end" }}>
        <Chip
          size="small"
          label="Estoque lançado"
          style={{
            color: "#ffffff",
            backgroundColor: "#2196f3",
            borderColor: "#2196f3",
          }}
          variant="outlined"
        />
      </Box>
    </GridItem>
  );

  const renderControlaEstoque = () => (
    <GridItem xs={12} sm={12} md={2}>
      <FormControl
        fullWidth
        className={classesCustomComponentsStyle.formControlSelect}
        size="small"
        variant="outlined"
      >
        <InputLabel>Controla estoque</InputLabel>
        <Select
          label="Controla estoque"
          id="controlaEstoque"
          autoWidth
          value={item.controlaEstoque === true ? YesNo.YES.code : YesNo.No.code}
          onChange={(event) => {
            const {
              target: { value },
            } = event;
            return handleChange("controlaEstoque", value, index);
          }}
          // error={errors.controlaEstoque}
          // helperText={naturezaOperacaoMessages.advanced[field]}
        >
          {Object.keys(YesNo).map((key) => {
            const { code, name } = YesNo[key];
            return (
              <MenuItem key={code} value={code}>
                {name}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </GridItem>
  );

  const renderOriginIcms = () => (
    <GridItem xs={12} sm={12} md={3}>
      <SelectEnum
        label="Origem ICMS"
        field="origemIcms"
        Enum={NFOrigem}
        useValueField
        valueSelected={item.origemIcms}
        handleChange={(field, value) => handleChange(field, value, index)}
        errors={errors}
      />
    </GridItem>
  );

  return (
    <Accordion
      style={{ flexGrow: 1 }}
      expanded={produtoJaImportado ? false : item.expandido}
      onChange={(ev) => ev.stopPropagation()}
    >
      <AccordionSummary
        style={{ cursor: "default" }}
        expandIcon={
          !produtoJaImportado && (
            <FontAwesomeIcon
              icon={faAngleDown}
              fontSize={22}
              cursor="pointer"
              onClick={() => handleChange("expandido", !item.expandido, index)}
            />
          )
        }
        aria-controls="panel1a-content"
      >
        {!produtoJaImportado && (
          <FormControlLabel
            control={
              <Checkbox
                color="secondary"
                style={{
                  color: primaryColor[0],
                }}
                checked={item.importarProduto}
                onClick={(ev) => {
                  ev.stopPropagation();
                  handleChange("importarProduto", ev.target.checked, index);
                }}
              />
            }
          />
        )}
        <GridContainer style={{ width: "100%" }}>
          {!produtoJaImportado && (
            <>
              <GridItem xs={12} sm={12} md={5}>
                <TextInput
                  style={{ marginTop: 0 }}
                  id="descricao"
                  label="Nome do produto"
                  value={item.descricao}
                  onChange={(field, value) => handleChange(field, value, index)}
                  errors={errors}
                />
              </GridItem>
            </>
          )}
          {!produtoJaImportado && renderOriginIcms()}
          {!produtoJaImportado && renderControlaEstoque()}

          {produtoJaImportado &&
            <>
              <GridItem xs={12} sm={12} md={6}>
                <Typography style={{ display: "flex", alignItems: "center", flexGrow: 1 }}>
                  {item.descricao}
                </Typography>
              </GridItem>
              <GridItem xs={12} sm={12} md={3}>
                <Typography style={{ display: "flex", alignItems: "center", marginRight: 6 }}>
                  {`Quantidade: ${item.quantidadeImportada ||item.quantidadeComercial} (${item.unidadeImportada || item.unidadeComercial})`}
                </Typography>
              </GridItem>
            </>
          }

          {item.produtoJaImportado ? importedMarker() : marker()}

          {!produtoJaImportado && (
            <>
              <GridItem xs={12} sm={12} md={2}>
                <TextInput
                  style={{ marginTop: 12 }}
                  id="quantidadeComercial"
                  label="Quantidade original"
                  value={item.quantidadeComercial}
                  onChange={(field, value) => handleChange(field, value, index)}
                  errors={errors}
                  disabled="true"
                  type="number"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">{item.unidadeComercial}</InputAdornment>
                    ),
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <Box display="flex" flexGrow={1}>
                  <Autocomplete
                    width="100%"
                    variant="outlined"
                    fieldsToShowAfterSelectItem={["acronym", "name"]}
                    label="Unidade de medida destino"
                    placeholder="Digite para pesquisar as unidades ..."
                    service={UnitMeasurementService}
                    value={item.unidadeImportada}
                    onChange={(value) => handleChangeUnidadeMedida(value?.acronym, index)}
                    required
                    error={errors.unidadeImportada}
                  />
                </Box>
              </GridItem>
              <GridItem xs={12} sm={12} md={3}>
                <TextInput
                  style={{ marginTop: 12 }}
                  id="quantidadeImportada"
                  label="Quantidade a lançar"
                  value={item.quantidadeImportada}
                  onChange={(field, value) => handleChangeQuantidadeImportada(value, index)}
                  errors={errors}
                  type="number"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">{item.unidadeImportada}</InputAdornment>
                    ),
                  }}
                />
              </GridItem>
            </>
          )}

        </GridContainer>
      </AccordionSummary>

      {!produtoJaImportado &&
        <AccordionDetails>
          <GridContainer style={{ flexGrow: 1 }}>
            {renderCustoMedio()}
            {renderValorMinimoVenda()}
            {renderValorVenda()}
          </GridContainer>
        </AccordionDetails>
      }
    </Accordion>
  );
};

export default React.memo(NFeLancarEstoqueProdutoNovo);
