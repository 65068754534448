import React, { useState, useEffect, useCallback } from "react";
import { makeStyles } from "@mui/styles";
import { useHistory, useParams } from "react-router-dom";
import GridContainer from "components/Grid/GridContainer";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import Button from "components/CustomButtons/Button";
import { useSnackbar } from "notistack";
import CardStyle from "assets/jss/material-dashboard-react/components/cardStyle";
import customComponentsStyle from "assets/jss/material-dashboard-react/components/customComponentsStyle";
import { handlingResponseErrors } from "config/util";
import CardFooter from "components/Card/CardFooter";
import GridItem from "components/Grid/GridItem";
import { Box, Divider, Typography } from "@mui/material";
import CurrencyTextInput from "components/textInput/CurrencyTextInput";
import BigNumber from "bignumber.js";
import NotaFiscalService from "../notaFiscal/service/NotaFiscalService";
import MESSAGES from "../../config/messages";
import Loading from "../../components/loading/Loading";
import NFeLancarEstoqueProdutoNovo from "./components/lancarEstoque/NFeLancarEstoqueProdutoNovo";
import NFeLancarEstoqueProdutoExistente from "./components/lancarEstoque/NFeLancarEstoqueProdutoExistente";
import {
  toRequest as toRequestImportacao,
  fromResponse as fromResponseImportacao,
} from "./converter/NFeEntradaImportacaoProdutoConverter";
import NFeImportacaoService from "./service/NFeImportacaoService";
import UnitConversionService from "../unitConversion/service/UnitConversionService";

const { generalMessages } = MESSAGES;

const useCardStyle = makeStyles(CardStyle);

export default function NFeEntradaLancarEstoque() {
  const routerParams = useParams();
  const classesuseCardStyle = useCardStyle();
  const useCustomComponentsStyle = makeStyles(customComponentsStyle);
  const classesCustomComponentsStyle = useCustomComponentsStyle();
  const history = useHistory();
  const [list, setList] = useState([]);
  const [unitConversions, setUnitConversions] = useState([]);
  const [errors, setErrors] = useState({});
  const [margemProdutosNovos, setMargemProdutosNovos] = useState({
    margemPreco: 100,
    margemPrecoMinimo: 0,
  });

  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleChangeMargemProdutosNovos = useCallback(
    (field, value) => {
      setMargemProdutosNovos({
        ...margemProdutosNovos,
        [field]: value,
      });
    },
    [margemProdutosNovos]
  );

  const applyMargemProdutosNovos = useCallback(() => {
    setLoading(true);
    const newList = list.map((item) => {
      if (!item.produtoExistente) {
        const { margemPreco, margemPrecoMinimo } = margemProdutosNovos;
        const { custo } = item;

        const bnCusto = new BigNumber(custo);
        const bnMargemPreco = new BigNumber(margemPreco);
        const bnMargemPrecoMinimo = new BigNumber(margemPrecoMinimo);
        //
        const precoNovo = bnCusto.plus(bnCusto.multipliedBy(bnMargemPreco.dividedBy(100)));
        const precoMinimoNovo = bnCusto.plus(
          bnCusto.multipliedBy(bnMargemPrecoMinimo.dividedBy(100))
        );
        return {
          ...item,
          preco: precoNovo.toNumber(),
          precoMinimo: precoMinimoNovo.toNumber(),
        };
      }
      return item;
    });
    setList(newList);
    setLoading(false);
  }, [list, margemProdutosNovos]);

  const fetchById = useCallback(async () => {
    try {
      setLoading(true);
      const { id } = routerParams || {};
      if (id != null) {
        const result = await NotaFiscalService.fetchById(id);
        const response = result?.data?.data;
        const notaFiscalProdutos = response?.notaFiscalProdutos || [];
        const listImportacao = fromResponseImportacao(notaFiscalProdutos);

        listImportacao.sort((a, b) => Number(a.produtoExistente) - Number(b.produtoExistente));
        setList(listImportacao);

        const allUnitConversions = await UnitConversionService.all();
        setUnitConversions(allUnitConversions?.data);
      }
    } finally {
      setLoading(false);
    }
  }, [routerParams]);

  useEffect(() => {
    fetchById();
  }, [fetchById]);

  /*
  Optimization: It was necessary to remove the "list" dependencies from "handleChange" function. 
  It is because every time that the "list" was being updated the "handleChange" changed its reference and 
  the components need to be update.
  */
  const handleChange = useCallback((field, value, index) => {
    setList((prevList) => {
      const newList = [...prevList];
      const i = newList.findIndex((item) => item.id === index);
      let item = newList[i];
      item = {
        ...item,
        [field]: value,
      };
      newList[i] = item;
      return newList;
    });
  }, []);

  const goBack = useCallback(() => {
    const { id } = routerParams || {};
    history.push(`/app/nfe-entrada/${id}`);
  }, [history, routerParams]);

  const onSave = useCallback(async () => {
    try {
      setLoading(true);
      const { id } = routerParams;
      const request = toRequestImportacao(list);
      await NFeImportacaoService.save(request, id);

      enqueueSnackbar(generalMessages.saveSuccess, {
        variant: "success",
        autoHideDuration: 3000,
      });
      goBack();
    } catch (error) {
      const { message, validationErrors } = handlingResponseErrors(error);
      enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
      if (validationErrors != null) {
        setErrors(validationErrors);
      }
    } finally {
      setLoading(false);
    }
  }, [routerParams, list, enqueueSnackbar, goBack]);

  const renderItem = (item) =>
    item.produtoExistente ? (
      <NFeLancarEstoqueProdutoExistente errors={errors} item={item} handleChange={handleChange} unitConversions={unitConversions} />
    ) : (
      <NFeLancarEstoqueProdutoNovo errors={errors} item={item} handleChange={handleChange} margemProdutos={margemProdutosNovos} unitConversions={unitConversions}/>
    );

  const showSaveButton = useCallback(() => {
    const itensCount = list.length;
    let itensImportedCount = 0;
    list.forEach((item) => {
      if (item.produtoJaImportado) {
        itensImportedCount += 1;
      }
    });
    return itensCount !== itensImportedCount;
  }, [list]);

  return (
    <GridContainer>
      <Card>
        <CardHeader color="primary">
          <h5 className={classesuseCardStyle.cardTitleList}>Nota Fiscal - Lançar estoque</h5>
        </CardHeader>
        <CardBody>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Typography style={{ fontSize: 18, fontWeight: 500 }}>Configurações</Typography>
              <Divider style={{ width: "100%", marginBottom: 4 }} />
            </GridItem>

            <GridItem xs={12} sm={12} md={4} />
            <GridItem xs={12} sm={12} md={8}>
              <Box display="flex">
                <CurrencyTextInput
                  id="margemPrecoMinimo"
                  label="Margem de acréscimo para o preco mínimo (produtos novos)"
                  value={margemProdutosNovos.margemPrecoMinimo}
                  onChange={handleChangeMargemProdutosNovos}
                  errors={errors}
                  currencySymbol="%"
                  style={{ marginRight: 10 }}
                />
                <CurrencyTextInput
                  id="margemPreco"
                  label="Margem de lucro padrão (produtos novos)"
                  value={margemProdutosNovos.margemPreco}
                  onChange={handleChangeMargemProdutosNovos}
                  errors={errors}
                  currencySymbol="%"
                  style={{ marginRight: 10 }}
                />
                <Button
                  color="primary"
                  onClick={applyMargemProdutosNovos}
                  style={{ marginTop: 10 }}
                >
                  Aplicar
                </Button>
              </Box>
            </GridItem>

            <GridItem xs={12} sm={12} md={12}>
              <Typography style={{ fontSize: 18, fontWeight: 500, paddingTop: 20 }}>
                Produtos
              </Typography>
              <Divider style={{ width: "100%", marginBottom: 4 }} />
              {list.map((item) => renderItem(item))}
            </GridItem>
          </GridContainer>
        </CardBody>
        <CardFooter>
          <Button color="primary" onClick={goBack}>
            Voltar
          </Button>
          {showSaveButton() && (
            <Button color="primary" onClick={onSave}>
              {generalMessages.save}
            </Button>
          )}
        </CardFooter>
      </Card>
      <Loading loading={loading} />
    </GridContainer>
  );
}
