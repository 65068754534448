import axios from "axios";
import { APIS } from "../../../config/constants";
import BaseService from "../../../core/BaseService";

const { register: registerAPI } = APIS;

class CommissionService extends BaseService {
  constructor() {
    super(registerAPI.commission);
  }

  featchCommissions = ({ params, pagination, sorting }) => {
    const { page, pageSize } = pagination;
    const { field = "createdAt", sort = "desc" } = sorting || {};
    return axios.get(
      `${registerAPI.commission}/search-commissions?size=${pageSize}&page=${page}&sort=${field},${sort}&${params}`
    );
  };

  featchCommissionsValues = (params) =>
    axios.get(`${registerAPI.commission}/search-commissions-values?${params}`);

  // featchInstallments = (receiveId) =>
  //   axios.get(`${registerAPI.commission}/search-commissions/${receiveId}`);

  closure = (request) => axios.post(`${this.endpoint}/closure`, request);

  calcularMeta = (request) => axios.post(`${this.endpoint}/calcular-meta`, request);

  lastClosure = (userId) => axios.get(`${this.endpoint}/last-closure/user/${userId}`);
}

export default new CommissionService();
