import axios from "axios";
import { APIS } from "../../../config/constants";
import BaseService from "../../../core/BaseService";

const { register: registerAPI } = APIS;

class NFeInutilizacaoService extends BaseService {
  constructor() {
    super(registerAPI.nfeInutilizacao);
  }

  fetchAll = (term) =>
    axios.get(
      `${this.endpoint}/search?size=1000&sort=active,DESC&sort=createdAt,DESC&term=${term}`
    );

  inutilizar = (id) => axios.post(`${this.endpoint}/${id}/inutilizar`);

  fetchHistorico = (id) => axios.get(`${this.endpoint}/${id}/historico`);
}

export default new NFeInutilizacaoService();
