/*!

=========================================================
* Material Dashboard React - v1.10.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import {
  faDownload,
  faPercentage,
  faCalendarAlt,
  faCog,
  faTools,
  faUser,
  faUserFriends,
  faTruck,
  faMoneyBill1Wave,
  faDollarSign,
  faDollyFlatbed,
  faUserCog,
  faThList,
  faShoppingCart,
  faBoxOpen,
  faArrowTrendDown,
  faArrowTrendUp,
  faWrench,
  faChartPie,
  faSearch,
  faRuler,
  faArrowsAltH,
  faRecycle,
  faCertificate,
  faTarp,
  faShareNodes,
  faFileInvoiceDollar,
  faFileArrowDown,
  faCartArrowDown,
  faSackDollar,
  faMoneyBill
} from "@fortawesome/free-solid-svg-icons";
import Dashboard from "views/dashboards/Dashboard";
import WelcomeForm from "views/welcome/WelcomeForm";
import CompanyForm from "views/company/CompanyForm";
import Calendar from "views/calendar/Calendar";
import Expense from "views/expense/Expense";
import Receive from "views/receive/Receive";
import UserForm from "views/user/UserForm";
import UserList from "views/user/UserList";
import Reports from "views/reports/Reports";
import DigitalCertificateForm from "views/digitalCertificate/DigitalCertificateForm";
import NotaFiscalConfigForm from "views/notaFiscalConfig/NotaFiscalConfigForm";
import UnitMeasurementForm from "views/unitMeasurement/UnitMeasurementForm";
import UnitMeasurementList from "views/unitMeasurement/UnitMeasurementList";
import UnitConversionForm from "views/unitConversion/UnitConversionForm";
import UnitConversionList from "views/unitConversion/UnitConversionList";
import Forbidden from "views/forbidden/Forbidden";
import ProductForm from "views/product/ProductForm";
import ProductList from "views/product/ProductList";
import ServiceProductForm from "views/service/ServiceProductForm";
import ServiceProductList from "views/service/ServiceProductList";
import OrderForm from "views/order/OrderForm";
import OrderList from "views/order/OrderList";
import ServiceOrderForm from "views/serviceOrder/ServiceOrderForm";
import ServiceOrderList from "views/serviceOrder/ServiceOrderList";
import PaymentMethodForm from "views/paymentMethod/PaymentMethodForm";
import PaymentMethodList from "views/paymentMethod/PaymentMethodList";
import FinancialAccountsForm from "views/financialAccounts/FinancialAccountsForm";
import FinancialAccountsList from "views/financialAccounts/FinancialAccountsList";
import CommissionConfigForm from "views/commissionConfig/CommissionConfigForm";
import CommissionConfigList from "views/commissionConfig/CommissionConfigList";
import Commission from "views/commission/Commission";
import MyPlan from "views/myPlan/MyPlan";
import InventoryList from "views/inventory/InventoryList";
import StripeResult from "views/stripe/StripeResult";
import AdminCompaniesList from "viewsAdmin/companies/AdminCompaniesList";
import PriceSearch from "views/price-search/PriceSearch";
import NaturezaOperacaoForm from "views/naturezaOperacao/NaturezaOperacaoForm";
import NaturezaOperacaoList from "views/naturezaOperacao/NaturezaOperacaoList";
import PartilhaIcmsForm from "views/partilhaIcms/PartilhaIcmsForm";
import PersonForm from "views/person/PersonForm";
import PersonList from "views/person/PersonList";
import NFeEmissaoForm from "views/nfeEmissao/NFeEmissaoForm";
import NFeEmissaoList from "views/nfeEmissao/NFeEmissaoList";
import NFCeEmissaoForm from "views/nfceEmissao/NFCeEmissaoForm";
import NFCeEmissaoList from "views/nfceEmissao/NFCeEmissaoList";
import NFeInutilizacaoForm from "views/nfeInutilizacao/NFeInutilizacaoForm";
import NFeInutilizacaoList from "views/nfeInutilizacao/NFeInutilizacaoList";
import NFeEntradaForm from "views/nfeEntrada/NFeEntradaForm";
import NFeEntradaList from "views/nfeEntrada/NFeEntradaList";
import NFeEntradaLancarEstoque from "views/nfeEntrada/NFeEntradaLancarEstoque";
import NotaFiscalPacoteDownloadForm from "views/notaFiscalPacoteDownload/NotaFiscalPacoteDownloadForm";
import NotaFiscalPacoteDownloadList from "views/notaFiscalPacoteDownload/NotaFiscalPacoteDownloadList";
import Support from "views/support/Support";

const priceSearch = [
  {
    path: "/price-search",
    name: "Pesquisa de preços",
    key: "price-search",
    icon: faSearch,
    component: PriceSearch,
    layout: "/app",
  },
];

const dashboards = [
  {
    path: "/dashboards",
    name: "Dashboards",
    key: "dashboard",
    icon: faChartPie,
    component: Dashboard,
    layout: "/app",
  },
];

const orders = [
  {
    path: "/order/:id?",
    name: "Vendas",
    key: "order", // should be singular
    icon: faShoppingCart,
    component: OrderForm,
    layout: "/app",
    hideOnMenu: true,
  },
  {
    path: "/orders",
    name: "Vendas",
    key: "order",
    icon: faShoppingCart,
    component: OrderList,
    layout: "/app",
  },
];

const serviceOrders = [
  {
    path: "/service-order/:id?",
    name: "Ordem de serviço",
    key: "service-order",
    icon: faTools,
    component: ServiceOrderForm,
    layout: "/app",
    hideOnMenu: true,
  },
  {
    path: "/service-orders",
    name: "Ordens de serviço",
    key: "service-order",
    icon: faTools,
    component: ServiceOrderList,
    layout: "/app",
  },
];

/** ************** Config start ********************* */
const digitalCertificate = [
  {
    path: "/digital-certificate",
    name: "Certificado digital",
    icon: faCertificate,
    key: "digital-certificate",
    component: DigitalCertificateForm,
    layout: "/app",
  },
];

const notaFiscalConfig = [
  {
    path: "/configuracao-nfe",
    name: "Configuração Geral",
    icon: faWrench,
    key: "configuracao-nfe",
    component: NotaFiscalConfigForm,
    layout: "/app",
  },
];

const unitMeasurement = [
  {
    path: "/units-measurement",
    name: "Unidades de medida",
    icon: faRuler,
    key: "units-measurement",
    component: UnitMeasurementList,
    layout: "/app",
  },
  {
    path: "/unit-measurement/:id?",
    name: "Unidade de medida",
    icon: faRuler,
    key: "unit-measurement",
    component: UnitMeasurementForm,
    layout: "/app",
    hideOnMenu: true,
  },
];

const unitConversion = [
  {
    path: "/units-conversion",
    name: "Unidades de conversão",
    icon: faArrowsAltH,
    key: "units-conversion",
    component: UnitConversionList,
    layout: "/app",
  },
  {
    path: "/unit-conversion/:id?",
    name: "Unidades de conversão",
    icon: faArrowsAltH,
    key: "unit-conversion",
    component: UnitConversionForm,
    layout: "/app",
    hideOnMenu: true,
  },
];

const paymentMethod = [
  {
    path: "/payment-method/:id?",
    name: "Forma de pagamento ",
    key: "payment-method",
    icon: faDollarSign,
    component: PaymentMethodForm,
    layout: "/app",
    hideOnMenu: true,
  },
  {
    path: "/payment-methods",
    name: "Forma de pagamento",
    key: "payment-method",
    icon: faDollarSign,
    component: PaymentMethodList,
    layout: "/app",
  },
];

const financialAccounts = [
  {
    path: "/financial-account/:id?",
    name: "Conta financeira",
    key: "financial-account",
    icon: faMoneyBill,
    component: FinancialAccountsForm,
    layout: "/app",
    hideOnMenu: true,
  },
  {
    path: "/financial-accounts",
    name: "Conta financeira",
    key: "financial-account",
    icon: faMoneyBill,
    component: FinancialAccountsList,
    layout: "/app",
  },
];

const commissionConfig = [
  {
    path: "/commission-configuration/:id?",
    name: "Comissão ",
    key: "commission-configuration",
    icon: faPercentage,
    component: CommissionConfigForm,
    layout: "/app",
    hideOnMenu: true,
  },
  {
    path: "/commission-configurations",
    name: "Comissão",
    key: "commission-configuration",
    icon: faPercentage,
    component: CommissionConfigList,
    layout: "/app",
  },
];

const users = [
  {
    path: "/user/:id?",
    name: "Usuário",
    icon: faUser,
    key: "user",
    component: UserForm,
    layout: "/app",
    hideOnMenu: true,
  },
  {
    path: "/users",
    name: "Usuários",
    icon: faUser,
    key: "user",
    component: UserList,
    layout: "/app",
  },
];

const naturezaOperacao = [
  {
    path: "/operation-type/:id?",
    name: "Natureza Operação",
    icon: faTarp,
    component: NaturezaOperacaoForm,
    key: "operation-type",
    layout: "/app",
    hideOnMenu: true,
  },
  {
    path: "/operations-type",
    name: "Natureza Operação",
    icon: faTarp,
    component: NaturezaOperacaoList,
    key: "operations-type",
    layout: "/app",
  },
];

const nfeInutilizacao = [
  {
    path: "/nfe-inutilizacao/:id?",
    name: "NFe inutilização",
    icon: faRecycle,
    component: NFeInutilizacaoForm,
    key: "nfe-inutilizacao",
    layout: "/app",
    hideOnMenu: true,
  },
  {
    path: "/nfe-inutilizacoes",
    name: "Inutilização NFe",
    icon: faRecycle,
    component: NFeInutilizacaoList,
    key: "nfe-inutilizacoes",
    layout: "/app",
  },
];
const partilhaIcms = [
  {
    path: "/partilha-icms",
    name: "Partilha ICMS",
    icon: faShareNodes,
    component: PartilhaIcmsForm,
    key: "partilha-icms",
    layout: "/app",
  },
];

const configsNfe = [
  {
    path: "/configs-nfe",
    name: "Config. Nota Fiscal",
    key: "config-nfe",
    icon: faCog,
    component: null,
    layout: "/app",
    menus: [
      ...digitalCertificate,
      ...notaFiscalConfig,
      ...naturezaOperacao,
      ...nfeInutilizacao,
      ...partilhaIcms,
    ],
  },
];

const nfeEmissao = [
  {
    path: "/nfe-emissao/:id?",
    name: "Emissão NFe",
    icon: faSackDollar,
    component: NFeEmissaoForm,
    key: "nfe-emissao",
    layout: "/app",
    hideOnMenu: true,
  },
  {
    path: "/nfe-emissoes",
    name: "Nota fiscal (NF-e)",
    icon: faSackDollar,
    component: NFeEmissaoList,
    key: "nfe-emissoes",
    layout: "/app",
  },
];

const nfceEmissao = [
  {
    path: "/nfce-emissao/:id?",
    name: "Emissão NFCe",
    icon: faSackDollar,
    component: NFCeEmissaoForm,
    key: "nfce-emissao",
    layout: "/app",
    hideOnMenu: true,
  },
  {
    path: "/nfce-emissoes",
    name: "Cupom fiscal (NFC-e)",
    icon: faSackDollar,
    component: NFCeEmissaoList,
    key: "nfce-emissoes",
    layout: "/app",
  },
];

const nfeEntrada = [
  {
    path: "/nfe-entrada/:id?",
    name: "Entrada NFe",
    icon: faCartArrowDown,
    component: NFeEntradaForm,
    key: "nfe-entrada",
    layout: "/app",
    hideOnMenu: true,
  },
  {
    path: "/nfe-entradas",
    name: "Compras (Entrada)",
    icon: faCartArrowDown,
    component: NFeEntradaList,
    key: "nfe-entradas",
    layout: "/app",
  },
  {
    path: "/nfe-entrada-lancar-estoque/:id?",
    name: "Lançar Estoque NFe Entrada",
    icon: faFileArrowDown,
    component: NFeEntradaLancarEstoque,
    key: "nfe-entrada-lancar-estoque",
    layout: "/app",
    hideOnMenu: true,
  },
];

const notaFiscalPacoteDownload = [
  {
    path: "/nfe-pacote-download/:id?",
    name: "Download pacote NF",
    icon: faDownload,
    component: NotaFiscalPacoteDownloadForm,
    key: "nfe-pacote-download",
    layout: "/app",
    hideOnMenu: true,
  },
  {
    path: "/nfe-pacote-downloads",
    name: "Download pacote NF",
    icon: faDownload,
    component: NotaFiscalPacoteDownloadList,
    key: "nfe-pacote-download",
    layout: "/app",
  },
];

const nfe = [
  {
    path: "/nota-fiscal",
    name: "Nota Fiscal",
    key: "nota-fiscal",
    icon: faFileInvoiceDollar,
    component: null,
    layout: "/app",
    menus: [...nfeEmissao, ...nfceEmissao, ...nfeEntrada, ...notaFiscalPacoteDownload],
  },
];

const configs = [
  {
    path: "/configs",
    name: "Configurações",
    key: "config",
    icon: faCog,
    component: null,
    layout: "/app",
    menus: [
      ...paymentMethod,
      ...commissionConfig,
      ...users,
      ...unitMeasurement,
      ...unitConversion,
      ...financialAccounts
    ],
  },
];
/** ************** Config end ********************* */

/** ************** Forms start ********************* */
const products = [
  {
    path: "/product/:id?",
    name: "Produto",
    key: "product",
    icon: faBoxOpen,
    component: ProductForm,
    layout: "/app",
    hideOnMenu: true,
  },
  {
    path: "/products",
    name: "Produtos",
    icon: faBoxOpen,
    key: "product",
    component: ProductList,
    layout: "/app",
  },
];

const service = [
  {
    path: "/prodservice/:id?",
    name: "Serviço",
    key: "prodservice",
    icon: faWrench,
    component: ServiceProductForm,
    layout: "/app",
    hideOnMenu: true,
  },
  {
    path: "/prodservices",
    name: "Serviços",
    icon: faWrench,
    key: "prodservice",
    component: ServiceProductList,
    layout: "/app",
  },
];

const person = [
  {
    path: "/person/:id?",
    key: "person",
    name: "Pessoa",
    icon: faUserFriends,
    component: PersonForm,
    layout: "/app",
    hideOnMenu: true,
  },
  {
    path: "/person-list",
    key: "person",
    name: "Pessoas",
    icon: faUserFriends,
    component: PersonList,
    layout: "/app",
  },
];

const forms = [
  {
    path: "/forms",
    name: "Cadastros",
    key: "form",
    icon: faUserCog,
    component: null,
    layout: "/app",
    menus: [...products, ...service, ...person /* ...paymentMethod */],
  },
];
/** ************** Forms end ********************* */

/** ************** Financial start ********************* */
const expenses = [
  {
    path: "/expenses",
    name: "Despesas",
    key: "expense",
    icon: faArrowTrendDown,
    component: Expense,
    layout: "/app",
  },
];

const receives = [
  {
    path: "/receives",
    name: "Recebimentos",
    key: "receive",
    icon: faArrowTrendUp,
    component: Receive,
    layout: "/app",
  },
];

const commissions = [
  {
    path: "/commissions",
    name: "Comissões",
    key: "commission",
    icon: faPercentage,
    component: Commission,
    layout: "/app",
  },
];

const financial = [
  {
    path: "/financial",
    name: "Financeiro",
    key: "financial",
    icon: faMoneyBill1Wave,
    component: null,
    layout: "/app",
    menus: [expenses[0], receives[0], commissions[0]],
  },
];
/** ************** Financial end ********************* */

const reports = [
  {
    path: "/reports",
    name: "Relatórios",
    key: "report",
    icon: faThList,
    component: Reports,
    layout: "/app",
  },
];

const support = [
  {
    path: "/support",
    name: "Suporte",
    key: "support",
    icon: faThList,
    component: Support,
    layout: "/app",
    hideOnMenu: true,
  },
];

const calendars = [
  {
    path: "/calendar",
    name: "Agenda",
    key: "calendar",
    icon: faCalendarAlt,
    component: Calendar,
    layout: "/app",
  },
];

const companies = [
  {
    path: "/company",
    name: "Empresa",
    icon: faPercentage,
    component: CompanyForm,
    key: "company",
    layout: "/app",
    hideOnMenu: true,
  },
];

const myPlan = [
  {
    path: "/my-plan",
    name: "Meu plano",
    icon: faPercentage,
    component: MyPlan,
    key: "myPlan",
    layout: "/app",
    hideOnMenu: true,
  },
];

const inventory = [
  {
    path: "/inventories",
    name: "Estoque",
    key: "inventories",
    icon: faDollyFlatbed,
    component: InventoryList,
    layout: "/app",
  },
];

const defaults = [
  {
    path: "/",
    name: "Welcome",
    icon: faPercentage,
    component: WelcomeForm,
    key: "welcome",
    layout: "/welcome",
    hideOnMenu: true,
  },
  {
    path: "/forbidden",
    // name: "Forbidden",
    icon: faPercentage,
    component: Forbidden,
    key: "forbidden",
    layout: "/app",
    hideOnMenu: true,
  },
];

const stripeResult = [
  {
    // stripe?success=true&session_id=1
    path: "/stripe",
    name: "Stipe Result",
    icon: faTruck,
    key: "stripe",
    component: StripeResult,
    layout: "/app",
    hideOnMenu: true,
  },
];

const adminCompanies = [
  {
    path: "/admin-companies",
    name: "Empresas",
    icon: faCertificate,
    key: "adminCompanies",
    component: AdminCompaniesList,
    layout: "/app",
  },
];
const configsAdmin = [
  {
    path: "/admin",
    name: "Admin",
    key: "admin",
    icon: faCog,
    component: null,
    layout: "/app",
    menus: [
      ...adminCompanies
    ],
  },
];

// eslint-disable-next-line import/no-mutable-exports
let routes = [];

routes = routes.concat(dashboards);
routes = routes.concat(priceSearch);
routes = routes.concat(serviceOrders);
routes = routes.concat(orders);
routes = routes.concat(nfe);
routes = routes.concat(forms);
routes = routes.concat(inventory);
routes = routes.concat(financial);
routes = routes.concat(reports);
routes = routes.concat(support);
routes = routes.concat(calendars);
routes = routes.concat(configs);
routes = routes.concat(configsNfe);
routes = routes.concat(configsAdmin);
routes = routes.concat(companies);
routes = routes.concat(myPlan);
routes = routes.concat(defaults);
routes = routes.concat(stripeResult);

// routes = routes.concat(checkIn);

export default routes;
