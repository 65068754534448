import { format } from "date-fns";
import { ptBR } from "date-fns/locale";
import MESSAGES from "./messages";

const { generalMessages } = MESSAGES;

const breakpoints = {
  values: {
    xs: 0,
    sm: 600,
    md: 900,
    lg: 1200,
    xl: 1536,
    custom: 1367,
  },
};

const handlingValidationErrors = (errors) => {
  const error = {};
  errors.forEach((item) => {
    error[item.field] = item.error;
  });
  return error;
};

const handlingResponseErrors = (error, show500msg) => {
  const { response } = error;
  let message = generalMessages.error;
  let validationErrors = null;
  if (response != null && response?.status === 422) {
    if (response?.data?.message?.includes("409")) {
      message = generalMessages.error_keycloak;
    } else {
      message = generalMessages.validations;
      validationErrors = handlingValidationErrors(response.data.errors);
    }
  } else if (response != null && response.status === 428) {
    message = response.data;
  } else if (show500msg && response != null && response.status === 500) {
    message = response.data.message;
  }
  return { message, validationErrors };
};

const isPasswordsEquals = (pass1, pass2) => {
  if (pass1 != null && pass1 !== "") {
    if (pass1 !== pass2) {
      return false;
    }
  }
  return true;
};

const currencyFormatter = (value = 0) => {
  const valueReplaced = `${value}`; // ;.replaceAll(".", "").replaceAll(",", ".");
  const currencyFormatted = new Intl.NumberFormat("pt-BR", {
    // style: "decimal", // decimal to remove R$ or use currency
    style: "currency", // decimal to remove R$ or use currency
    currency: "BRL",
    // minimumFractionDigits: 4,
    minimumFractionDigits: 2,
  }).format(valueReplaced);
  return currencyFormatted;
};

const percentageFormatter = (value = 0) => {
  if (value) {
    const numero = Number.parseFloat(value).toFixed(2).split(".");
    numero[0] = numero[0].split(/(?=(?:...)*$)/).join(".");
    return `${numero.join(",")} %`;
  }
  return "0%";
};

function formatDate(date, dateFormat) {
  if (date) {
    return format(new Date(date), dateFormat || "dd/MM/yyyy HH:mm", { locale: ptBR });
  }
  return "";
}

function formatDateWithoutTime(date, dateFormat) {
  if (date) {
    return format(new Date(date), dateFormat || "dd/MM/yyyy", { locale: ptBR });
  }
  return "";
}

function pad(padString, length) {
  if (padString) {
    return String(padString).padStart(length, "0");
  }
  return "";
}

const applyCnpjMask = (cnpj) => {
  if (cnpj != null && cnpj !== "") {
    return cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
  }
  return null;
};

const applyCpfMask = (cpf) => {
  if (cpf != null && cpf !== "") {
    return cpf.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
  }
  return null;
};

const applyPhoneMask = (phone) => {
  if (phone != null && phone !== "") {
    return phone.replace(/^(\d{2})(\d{4})(\d{4})/, "($1) $2-$3");
  }
  return null;
};

const applyMobileMask = (mobile) => {
  if (mobile != null && mobile !== "") {
    return mobile.replace(/^(\d{2})(\d{4})(\d{4})/, "($1) $2-$3");
  }
  return null;
};

const isMobile = () => window.innerWidth <= breakpoints.values.custom;

const isEmptyString = (text) => {
  if (text && text !== null && text !== "") {
    return false;
  }
  return true;
};

const isAdmin = (roles) => {
  if (roles != null && roles.length > 0 && roles.includes("ADMIN")) {
    return true;
  }
  return false;
};

const removeMask = (text) => {
  if (text != null && text !== "") {
    return text.replace(/\D/g, "");
  }
  return null;
};

const getBrowserZoomLevel = () => Math.round(window.devicePixelRatio * 100);

export {
  applyCnpjMask,
  applyCpfMask,
  applyPhoneMask,
  applyMobileMask,
  handlingResponseErrors,
  currencyFormatter,
  formatDate,
  percentageFormatter,
  pad,
  isPasswordsEquals,
  isMobile,
  isEmptyString,
  isAdmin,
  formatDateWithoutTime,
  removeMask,
  getBrowserZoomLevel,
  breakpoints
};
