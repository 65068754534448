import { drawerWidth, transition, container } from "assets/jss/material-dashboard-react.js";

const appStyle = (theme) => ({
  wrapper: {
    position: "relative",
    top: "0",
    height: "100vh",
  },
  mainPanel: {
    // [theme.breakpoints.up("md")]: {
    width: (props) => {
      return props.openMenu ? `calc(100% - ${drawerWidth}px)` : "100%";
    },
    // },
    overflow: "auto",
    position: "relative",
    float: "right",
    transition: "width 1s",
    maxHeight: "100%",
    // width: "100%",
    overflowScrolling: "touch",
  },
  content: {
    marginTop: "30px",
    padding: "30px 0px 0px 0px",
    minHeight: "calc(100vh - 123px)",
  },
  container,
  map: {
    marginTop: "70px",
  },
});

export default appStyle;
