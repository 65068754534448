/* eslint-disable react/prop-types */
import React, { useCallback } from "react";
import { makeStyles } from "@mui/styles";
import CustomComponentsStyle from "assets/jss/material-dashboard-react/components/customComponentsStyle";
import { Divider, FormControlLabel, FormGroup, Switch, TextField, Typography } from "@mui/material";
import InputMask from "react-input-mask";
import GridItem from "components/Grid/GridItem";
import TextInput from "components/textInput/TextInput";
import GridContainer from "components/Grid/GridContainer";
import AddressForm from "components/address/AddressForm";
import { RegistrationType, PersonType } from "views/person/model/Person";
import PersonService from "views/person/service/PersonService";
import Autocomplete from "components/autocomplete/Autocomplete";
import SelectEnum from "components/selectEnum/SelectEnum";
import { DocumentType } from "views/notaFiscal/model/NotaFiscal";

const NotaFiscalDadosEmitente = ({
  data = {},
  states,
  handleChange,
  errors = {},
  disabled = false,
}) => {
  const useCustomComponentsStyle = makeStyles(CustomComponentsStyle);
  const classesCustomComponentsStyle = useCustomComponentsStyle();

  const onChange = useCallback(
    (field, value) => {
      if (field == null) {
        handleChange({
          ...data,
          ...value,
        });
      } else {
        handleChange({
          ...data,
          [field]: value,
        });
      }
    },
    [data, handleChange]
  );

  const onChangeAddress = useCallback(
    (field, value, automaticFilling) => {
      if (automaticFilling) {
        // From via cep
        handleChange({
          ...data,
          ...value,
        });
      } else {
        handleChange({
          ...data,
          [field]: value,
        });
      }
    },
    [data, handleChange]
  );

  const beforePersonChange = useCallback(
    async (person) => {
      let newPerson = {
        cliente: null,
        nomeEmitente: null,
        telefoneEmitente: null,
        emailEmitente: null,
        cepEmitente: null,
        state: null,
        cidadeEmitente: null,
        logradouroEmitente: null,
        bairroEmitente: null,
        numeroEmitente: null,
        complementoEmitente: null,
      };

      if (person != null) {
        const { id } = person;
        const response = await PersonService.fetchById(id);
        const result = response?.data?.data || {};
        const { name, personType, cpf, cnpj, inscricaoEstadual, mobile, email, address } = result;

        newPerson = {
          cliente: person,
          nomeEmitente: name,
          telefoneEmitente: mobile,
          emailEmitente: email,
        };

        if (personType === PersonType.PF.code) {
          newPerson.documentoEmitente = cpf;
          newPerson.tipoDocumentoEmitente = DocumentType.CPF.code;
        }
        if (personType === PersonType.PJ.code) {
          newPerson.tipoDocumentoEmitente = DocumentType.CNPJ.code;
          newPerson.documentoEmitente = cnpj;
          newPerson.inscricaoEstadualEmitente = inscricaoEstadual;
        }

        if (address) {
          const newAddress = {
            cepEmitente: address.zipCode,
            state: address?.city?.state?.id || null,
            cidadeEmitente: address?.city?.id || null,
            logradouroEmitente: address.street,
            bairroEmitente: address.neighborhood,
            numeroEmitente: address.number,
            complementoEmitente: address.complement,
          };

          newPerson = {
            ...newPerson,
            ...newAddress,
          };
        }
      }

      onChange(null, newPerson);
    },
    [onChange]
  );

  const renderClienteFornecedor = () => (
    <GridItem xs={12} sm={12} md={6}>
      <Autocomplete
        autoSelect
        width="100%"
        variant="outlined"
        fieldsToShowAfterSelectItem={["name"]}
        label="Fornecedor"
        placeholder="Digite para pesquisar ..."
        service={PersonService}
        extraSearch={{ registrationType: RegistrationType.SUPPLIER.code }}
        value={data.fornecedor}
        onChange={(value) => beforePersonChange(value)}
        required
        error={errors?.nomeEmitente}
        disabled={disabled}
      />
    </GridItem>
  );

  const renderTipoDocumento = () => (
    <GridItem xs={12} sm={12} md={3}>
      <SelectEnum
        label="Tipo de documento"
        field="tipoDocumentoEmitente"
        Enum={DocumentType}
        valueSelected={data.tipoDocumentoEmitente}
        handleChange={onChange}
        errors={errors}
        required
        disabled={disabled}
      />
    </GridItem>
  );

  const renderAddress = () => (
    <AddressForm
      disabled={disabled}
      fields={{
        zipCode: "cepEmitente",
        state: "state",
        city: "cidadeEmitente",
        street: "logradouroEmitente",
        neighborhood: "bairroEmitente",
        number: "numeroEmitente",
        complement: "complementoEmitente",
      }}
      errorFields={{
        zipCode: "cepEmitente",
        state: "state",
        city: "cidadeEmitente",
        street: "logradouroEmitente",
        neighborhood: "bairroEmitente",
        number: "numeroEmitente",
        complement: "complementoEmitente",
      }}
      address={data}
      states={states}
      handleChange={onChangeAddress}
      errors={errors}
      showTitle={false}
    />
  );

  const renderName = ({ label, field }) => (
    <GridItem xs={12} sm={12} md={6}>
      <TextInput
        id={field}
        label={label}
        value={data[field]}
        onChange={onChange}
        required
        errors={errors}
        maxLength={60}
        disabled={disabled}
      />
    </GridItem>
  );

  const renderCpf = ({ field }) => (
    <GridItem xs={12} sm={12} md={3}>
      <InputMask
        mask="999.999.999-99"
        value={data[field] == null ? "" : data[field]}
        onChange={(value) => onChange(field, value.currentTarget.value)}
        disabled={disabled}
      >
        <TextField
          size="small"
          variant="outlined"
          id={field}
          label="CPF"
          fullWidth
          InputLabelProps={{
            className: classesCustomComponentsStyle.labelTextField,
          }}
          className={classesCustomComponentsStyle.textField}
          error={errors[field]}
          helperText={errors[field]}
        />
      </InputMask>
    </GridItem>
  );

  const renderCnpj = ({ field }) => (
    <GridItem xs={12} sm={12} md={3}>
      <InputMask
        mask="99.999.999/9999-99"
        disabled={disabled}
        value={data[field] == null ? "" : data[field]}
        onChange={(value) => onChange(field, value.currentTarget.value)}
      >
        <TextField
          size="small"
          variant="outlined"
          id={field}
          label="CNPJ"
          fullWidth
          InputLabelProps={{
            className: classesCustomComponentsStyle.labelTextField,
          }}
          className={classesCustomComponentsStyle.textField}
          error={errors[field]}
          helperText={errors[field]}
        />
      </InputMask>
    </GridItem>
  );

  const renderNomeRazaoSocialEmitente = () => {
    if (data.tipoDocumentoEmitente === DocumentType.CPF.code) {
      return renderName({
        field: "nomeEmitente",
        label: "Nome completo",
      });
    }
    if (data.tipoDocumentoEmitente === DocumentType.CNPJ.code) {
      return renderName({
        field: "nomeEmitente",
        label: "Razão social",
      });
    }
    return <></>;
  };

  const renderDocumento = () => {
    if (data.tipoDocumentoEmitente === DocumentType.CPF.code) {
      return renderCpf({ field: "documentoEmitente" });
    }
    if (data.tipoDocumentoEmitente === DocumentType.CNPJ.code) {
      return renderCnpj({ field: "documentoEmitente" });
    }
    return <></>;
  };

  const renderPhones = () => (
    <>
      <GridItem xs={12} sm={12} md={3}>
        <InputMask
          mask="(99) 99999-9999"
          disabled={disabled}
          value={data.telefoneEmitente == null ? "" : data.telefoneEmitente}
          onChange={(value) => onChange("telefoneEmitente", value.currentTarget.value)}
        >
          <TextField
            size="small"
            variant="outlined"
            id="telefoneEmitente"
            label="Telefone"
            fullWidth
            InputLabelProps={{
              className: classesCustomComponentsStyle.labelTextField,
            }}
            className={classesCustomComponentsStyle.textField}
          />
        </InputMask>
      </GridItem>
    </>
  );

  const renderInscricaoEstadual = ({ field }) => (
    <GridItem xs={12} sm={12} md={3}>
      <TextInput
        disabled={disabled}
        id={field}
        label="Inscrição estadual"
        value={data[field]}
        onChange={onChange}
        errors={errors}
        maxLength={14}
      />
    </GridItem>
  );

  const renderInscricaoMunicipal = () => (
    <GridItem xs={12} sm={12} md={3}>
      <TextInput
        disabled={disabled}
        id="inscricaoMunicipalEmitente"
        label="Inscrição municipal"
        value={data.inscricaoMunicipalEmitente}
        onChange={onChange}
        errors={errors}
        maxLength={15}
      />
    </GridItem>
  );

  const renderSwitch = (value = false, field, label, isDisabled = false) => (
    <GridItem xs={12} sm={12} md={12}>
      <FormGroup style={{ paddingTop: "8px" }}>
        <FormControlLabel
          control={
            <Switch
              color="secondary"
              checked={value}
              onChange={(event) => onChange(field, event.target.checked)}
            />
          }
          label={label}
          disabled={isDisabled}
        />
      </FormGroup>
    </GridItem>
  );

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12} style={{ marginTop: 30 }}>
        <Divider style={{ width: "100%", marginBottom: 4 }} />
        <Typography style={{ fontSize: 18, fontWeight: 500 }}>Dados do emitente</Typography>
      </GridItem>
      {renderClienteFornecedor()}
      {renderTipoDocumento()}
      {renderDocumento()}
      {renderNomeRazaoSocialEmitente()}
      {renderInscricaoEstadual({ field: "inscricaoEstadualEmitente" })}
      {renderInscricaoMunicipal()}
      {/* <GridItem xs={12} sm={12} md={4} /> */}
      {renderAddress()}
      {renderPhones()}
      {renderSwitch(
        data.possuiEnderecoEntrega,
        "possuiEnderecoEntrega",
        "Informar endereço de entrega",
        disabled
      )}
    </GridContainer>
  );
};

export default React.memo(NotaFiscalDadosEmitente);
