/* eslint-disable react/prop-types */
import React, { forwardRef, useCallback, useImperativeHandle } from "react";
import { DataGrid, GridOverlay, ptBR } from "@mui/x-data-grid";
import DataGridStyle from "assets/jss/material-dashboard-react/components/dataGridStyle";
import { makeStyles } from "@mui/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import b from "react-input-mask/lib/react-input-mask.production.min";

const useDataGridStyle = makeStyles(DataGridStyle);
const PAGE_DEFAULT_MODEL = { page: 0, pageSize: [25] };

const DataGridCustom = forwardRef(
  (
    {
      onRowClick,
      onRowDoubleClick,
      rows,
      columns,
      getRowClassName,
      disableSelectionOnClick = true,
      getRowId,
      emptyText,
      selectedRows = [],
      hideFooter = false,
      rowCount,
      fetchAction,
      usePagination = false,
    },
    ref
  ) => {
    const classesDataGridStyle = useDataGridStyle();
    const [paginationModel, setPaginationModel] = React.useState(PAGE_DEFAULT_MODEL);

    const theme = useTheme();
    const isMediumScreen = useMediaQuery(theme.breakpoints.down("custom"));
    let breakpoints = {};
    if (isMediumScreen) {
      breakpoints = {
        cell: {
          padding: "8px",
          fontSize: "12px",
        },
        columnHeaderTitle: {
          fontSize: "12px",
        },
        footerContainer: {
          height: "25px",
          minHeight: "25px",
        },
        columnHeaderHeight: 25,
        rowHeight: 30,
        searchIconSize: 20,
      };
    }

    const getEmptyText = useCallback(() => {
      if (!emptyText) return "Nenhum item encontrado";
      return emptyText;
    }, [emptyText]);

    const getRowClass = useCallback(
      (params) => {
        if (getRowClassName) {
          return getRowClassName(params);
        }
        const { row } = params;
        return row && row.active === false ? classesDataGridStyle.muiDataGridRowInactive : "";
      },
      [classesDataGridStyle.muiDataGridRowInactive, getRowClassName]
    );

    const onPaginationModelChange = useCallback(
      (pageModel, details) => {
        const reason = details?.reason;
        setPaginationModel(pageModel);
        if (reason === "setPaginationModel" && usePagination) {
          fetchAction({ paginationModelParams: pageModel });
        }
      },
      [fetchAction, usePagination]
    );

    const onSortModelChange = useCallback(
      (sortModel) => {
        if (usePagination) {
          const sorting = sortModel?.[0] || {};
          fetchAction({ paginationModelParams: paginationModel, sorting });
        }
      },
      [fetchAction, paginationModel, usePagination]
    );
    useImperativeHandle(ref, () => ({
      resetPaginationModel() {
        onPaginationModelChange(PAGE_DEFAULT_MODEL);
      },

      getDefaultPaginationModel() {
        return PAGE_DEFAULT_MODEL;
      },
    }));

    return (
      <DataGrid
        disableColumnMenu
        hideFooter={hideFooter}
        selectionModel={selectedRows}
        onRowDoubleClick={(row) => (onRowDoubleClick ? onRowDoubleClick(row) : null)}
        onRowClick={(row) => (onRowClick ? onRowClick(row) : null)}
        rows={rows}
        getRowId={getRowId}
        columns={columns}
        getRowClassName={getRowClass}
        disableSelectionOnClick={disableSelectionOnClick}
        localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
        slots={{
          noRowsOverlay: () => (
            <GridOverlay alignItems="center" justifyContent="center">
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
              >
                <Box style={{ padding: 12 }}>
                  <FontAwesomeIcon
                    icon={faMagnifyingGlass}
                    fontSize={breakpoints?.searchIconSize || 40}
                    color="#686868"
                  />
                </Box>
                <Box>{getEmptyText()}</Box>
              </Box>
            </GridOverlay>
          ),
        }}
        paginationModel={paginationModel}
        paginationMode={usePagination ? "server" : "client"}
        onPaginationModelChange={onPaginationModelChange}
        // https://mui.com/x/react-data-grid/sorting/#server-side-sorting
        sortingMode={usePagination ? "server" : "client"}
        onSortModelChange={onSortModelChange}
        rowCount={rowCount}
        sx={{
          "& .MuiDataGrid-cell": {
            padding: breakpoints?.cell?.padding,
            fontSize: breakpoints?.cell?.fontSize,
          },
          "& .MuiDataGrid-columnHeaderTitle": {
            fontSize: breakpoints?.columnHeaderTitle?.fontSize,
          },
          "& .MuiDataGrid-footerContainer": {
            height: breakpoints?.footerContainer?.height,
            minHeight: breakpoints?.footerContainer?.minHeight,
          },
        }}
        columnHeaderHeight={breakpoints?.columnHeaderHeight || 50}
        rowHeight={breakpoints?.rowHeight}
      />
    );
  }
);

export default React.memo(DataGridCustom);
