/* eslint-disable react/prop-types */
import React, { useState, useCallback, useEffect } from "react";
import { useSnackbar } from "notistack";
import GridContainer from "components/Grid/GridContainer";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@mui/material";

import moment from "moment";
import { primaryColor } from "../../assets/jss/material-dashboard-react";
import Loading from "../../components/loading/Loading";
import MESSAGES from "../../config/messages";
import DataGridCustom from "../../components/dataGrid/DataGridCustom";
import ProductPriceChangeHistoryService from "./service/ProductPriceChangeHistoryService";
import { ProductPriceChangeHistoryOriginType } from "./model/ProductPriceChangeHistory";
import { currencyFormatter } from "../../config/util";

const { generalMessages } = MESSAGES;

const ProductPriceChangeHistoryModal = ({ open, onClose, product }) => {
  const [list, setList] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);

  const fetch = useCallback(async () => {
    setLoading(true);
    try {
      const result = await ProductPriceChangeHistoryService.findProductPriceChangeHistory(
        product.id
      );
      const { data } = result;
      setList(data?.content || []);
    } catch (error) {
      enqueueSnackbar(generalMessages.error, {
        variant: "error",
      });
    } finally {
      setLoading(false);
    }
  }, [enqueueSnackbar, product]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  const getColumns = () => {
    const columns = [];

    columns.push({
      field: "createdAt",
      headerName: "Data",
      minWidth: 150,
      flex: 1,
      valueGetter: ({ value }) => moment(value).format("DD/MM/yyyy HH:mm"),
    });

    columns.push({
      field: "originType",
      headerName: "Tipo de atualização",
      minWidth: 200,
      flex: 1,
      valueGetter: ({ value }) => (value ? ProductPriceChangeHistoryOriginType[value].name : ""),
    });

    columns.push({
      field: "cost",
      headerName: "Atualização do custo",
      minWidth: 200,
      flex: 2,
      renderCell: ({ row: item }) => (
        <p>
          De <b>{currencyFormatter(item?.cost || 0)}</b> para{" "}
          <b>{currencyFormatter(item?.newCost || 0)}</b>
        </p>
      ),
    });

    columns.push({
      field: "priceMinimum",
      headerName: "Atualização do preço mínimo",
      flex: 2,
      minWidth: 250,
      renderCell: ({ row: item }) => (
        <p>
          De <b>{currencyFormatter(item?.priceMinimum || 0)}</b> para{" "}
          <b>{currencyFormatter(item?.newPriceMinimum || 0)}</b>
        </p>
      ),
    });

    columns.push({
      field: "price",
      headerName: "Atualização do preço",
      minWidth: 200,
      flex: 2,
      renderCell: ({ row: item }) => (
        <p>
          De <b>{currencyFormatter(item?.price || 0)}</b> para{" "}
          <b>{currencyFormatter(item?.newPrice || 0)}</b>
        </p>
      ),
    });

    columns.push({
      field: "user",
      headerName: "Alterado por",
      minWidth: 180,
      flex: 1,
      valueGetter: ({ value }) => (value ? value.name : ""),
    });

    return columns;
  };

  const renderGrid = () => (
    <div
      style={{
        height: "40vh",
        width: "100%",
        marginTop: 15,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <DataGridCustom rows={list} columns={getColumns()} />
    </div>
  );

  return (
    <>
      <Dialog disableEscapeKeyDown open={open} fullWidth maxWidth="xl">
        <DialogTitle>Histórico de alterações de preço - {product?.name || ""}</DialogTitle>
        <DialogContent>
          <GridContainer>{renderGrid()}</GridContainer>
        </DialogContent>
        <DialogActions>
          <div style={{ flexGrow: 1, display: "flex", justifyContent: "space-between" }}>
            <Button style={{ color: primaryColor[0] }} onClick={onClose}>
              Voltar
            </Button>
          </div>
        </DialogActions>
      </Dialog>
      <Loading loading={loading} />
    </>
  );
};

export default React.memo(ProductPriceChangeHistoryModal);
