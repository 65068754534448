import React, { useContext, useState, useEffect } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { makeStyles } from "@mui/styles";
import Hidden from "@mui/material/Hidden";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import PlanTopMessage from "views/myPlan/components/PlanTopMessage";
import IconButton from "@mui/material/IconButton";
import AdminNavbarLinks from "./AdminNavbarLinks";
import UserContext from "../../core/UserContext";

//hooks
import { useRouteName } from "hooks";

import styles from "assets/jss/material-dashboard-react/components/headerStyle";
import { Box } from "@mui/material";

const useStyles = makeStyles(styles);

export default function Header(props) {
  const { userLogged } = useContext(UserContext);
  const [plan, setPlan] = useState({
    freePlanEndDays: null,
    freePlan: false,
    expiredPlan: false,
    expiredDaysAgo: 0,
  });
  const classes = useStyles();
  const routeName = useRouteName();
  const { color } = props;
  const appBarClasses = classNames({
    [" " + classes[color]]: color,
  });

  useEffect(() => {
    if (userLogged != null) {
      setPlan(userLogged.plan);
    }
  }, [userLogged]);

  return (
    <AppBar className={classes.appBar + appBarClasses}>
      <Toolbar className={classes.container}>
        <IconButton color="inherit" aria-label="open drawer" onClick={props.handleDrawerToggle}>
          <FontAwesomeIcon icon={faBars} fontSize={20} />
        </IconButton>
        <Box flexGrow={1}>
          <PlanTopMessage plan={plan} />
        </Box>
        <Box>
          <AdminNavbarLinks />
        </Box>
      </Toolbar>
    </AppBar>
  );
}

Header.propTypes = {
  color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
  rtlActive: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
  routes: PropTypes.arrayOf(PropTypes.object),
};
