import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock } from "@fortawesome/free-solid-svg-icons";

const Forbidden = () => (
  <>
    <div
      style={{
        display: "flex",
        flexGrow: 1,
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <FontAwesomeIcon icon={faLock} fontSize={50} color="#686868" />
      <div>Seu usuário não possui acesso para esse módulo. Entre em contato com o suporte!</div>
    </div>
  </>
);

export default Forbidden;
