import axios from "axios";
import { APIS } from "../../../config/constants";
import BaseService from "../../../core/BaseService";

const { register: registerAPI } = APIS;

class NotificationService extends BaseService {
  constructor() {
    super(registerAPI.notification);
  }

  fetchGroup = () => axios.get(`${this.endpoint}/search-notifications-group`);

  setNotificationsRead = (ids) =>
    localStorage.setItem("notification-read-ids", JSON.stringify(ids));

  getNotificationsRead = () => {
    const ids = localStorage.getItem("notification-read-ids");
    return ids === null ? [] : JSON.parse(ids);
  };
}

export default new NotificationService();
