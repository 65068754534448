import axios from "axios";
import { APIS } from "../../../config/constants";
import BaseService from "../../../core/BaseService";

const { register: registerAPI } = APIS;

class CommissionConfigService extends BaseService {
  constructor() {
    super(registerAPI.commissionConfig);
  }

  fetchAll = (term) =>
    axios.get(`${this.endpoint}/search?size=1000&sort=active,DESC&sort=startDate,ASC&term=${term}`);

  commissionConfigForUser = (userId) => axios.get(`${this.endpoint}/user/${userId}`);
}

export default new CommissionConfigService();
