/* eslint-disable react/prop-types */
import React, { useCallback, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faFilter } from "@fortawesome/free-solid-svg-icons";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

const AccordionFilters = ({ children, screen }) => {
  if (!screen) {
    throw new Error("AccordionFilters - screen is required");
  }

  const [expanded, setExpanded] = React.useState(true);
  const [title, setTitle] = React.useState("");
  const theme = useTheme();
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("custom"));

  useEffect(() => {
    setTitle(expanded ? "Ocultar filtros " : "Mostrar filtros");
  }, [expanded]);

  useEffect(() => {
    const isExpanded = localStorage.getItem(`${screen}_accordion_filters_kairum`) === "expanded";
    setExpanded(isExpanded);
  }, [screen]);

  const handleExpandClick = useCallback(() => {
    setExpanded(!expanded);
    localStorage.setItem(`${screen}_accordion_filters_kairum`, expanded ? "collapsed" : "expanded");
  }, [expanded, screen]);

  return (
    <Accordion
      style={{ flexGrow: 1, padding: 4 }}
      expanded={expanded}
      // onChange={(ev) => ev.stopPropagation()}
      onChange={handleExpandClick}
    >
      <AccordionSummary
        style={{ cursor: "default" }}
        sx={{
          height: isMediumScreen ? "20px" : "28px",
          minHeight: isMediumScreen ? "20px !important" : "28px !important",
          padding: "0px 16px 0px 2px",
        }}
        expandIcon={
          <FontAwesomeIcon
            icon={faAngleDown}
            fontSize={18}
            cursor="pointer"
            onClick={handleExpandClick}
            color="#9c9b9b"
          />
        }
      >
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          flexGrow={1}
          justifyContent="space-between"
        >
          <Box>
            <FontAwesomeIcon
              icon={faFilter}
              fontSize={16}
              cursor="pointer"
              color="#7e7b7b"
              onClick={handleExpandClick}
            />
          </Box>
          <Box>
            <Typography
              style={{
                fontSize: isMediumScreen ? 14 : 16,
                fontWeight: 500,
                paddingRight: 4,
                color: "#7e7b7b",
                cursor: "pointer",
              }}
            >
              {title}
            </Typography>
          </Box>
        </Box>
      </AccordionSummary>
      <AccordionDetails sx={{ padding: "0px 0px 0px" }}>{children}</AccordionDetails>
    </Accordion>
  );
};

export default React.memo(AccordionFilters);
