/* eslint-disable react/prop-types */

import React, { useCallback, useEffect, useMemo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BigNumber from "bignumber.js";
import { makeStyles } from "@mui/styles";
import CustomComponentsStyle from "assets/jss/material-dashboard-react/components/customComponentsStyle";
import { useSnackbar } from "notistack";
import moment from "moment";
import { faDollarSign, faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { DatePicker } from "@mui/x-date-pickers";
import {
  List,
  ListItem,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Divider,
  Grid,
  Typography,
  Box,
  Button,
} from "@mui/material";
import TextInput from "../../components/textInput/TextInput";
import CurrencyTextInput from "../../components/textInput/CurrencyTextInput";
import { primaryColor } from "../../assets/jss/material-dashboard-react";
import MESSAGES from "../../config/messages";

import { currencyFormatter } from "../../config/util";

const useCustomComponentsStyle = makeStyles(CustomComponentsStyle);

const { installmentsMessages, generalMessages } = MESSAGES;

export default function Installments({
  errors,
  paymentMethod,
  paymentMethods,
  onChangePaymentMethod,
  financialAccount,
  financialAccounts,
  onChangeFinancialAccount,
  numberInstallments,
  installments = {},
  onChange,
  onChangeNumberInstallments,
  onChangeReceivedValue,
  readOnly,
  orderTotal,
  typeOrigin,
  setValidation,
  isRecalculate,
  received = 0,
  difference = 0,
}) {
  const classesCustomComponentsStyle = useCustomComponentsStyle();
  const { enqueueSnackbar } = useSnackbar();

  const { allowsInstallments, creditCard, allowsDifference } = paymentMethod || {};

  const showNumberInstallments = useMemo(() => allowsInstallments, [allowsInstallments]);

  const showInstallments = useMemo(() => !creditCard, [creditCard]);

  const keys = Object.keys(installments);
  const quantity = keys.length;

  const totalInstallments = React.useMemo(() => {
    let totalBN = new BigNumber(0);
    Object.keys(installments).forEach((key) => {
      totalBN = totalBN.plus(new BigNumber(installments[key].value));
    });
    return totalBN.toNumber();
  }, [installments]);

  const isTotalDiffThatTotalInstalls = React.useMemo(
    () =>
      new BigNumber(orderTotal).decimalPlaces(2).toNumber() !==
      new BigNumber(totalInstallments).decimalPlaces(2).toNumber(),
    [orderTotal, totalInstallments]
  );

  const validation = React.useMemo(() => {
    if (showNumberInstallments) {
      if (isTotalDiffThatTotalInstalls && numberInstallments > 0 && showInstallments) {
        return {
          isValid: false,
          message: "Valores das parcelas não estão corretos",
        };
      }
      if (numberInstallments <= 0) {
        return {
          isValid: false,
          message: "É necessário adicionar ao menos 1 parcela",
        };
      }
    }
    return {
      isValid: true,
      message: null,
    };
  }, [isTotalDiffThatTotalInstalls, numberInstallments, showInstallments, showNumberInstallments]);

  useEffect(() => {
    setValidation(validation);
  }, [validation, setValidation]);

  const handleChangeInstallments = useCallback(
    (field, value, number) => {
      const installmentsCopy = { ...installments };
      const item = installmentsCopy[number];
      installmentsCopy[number] = {
        ...item,
        [field]: value,
      };
      onChange(installmentsCopy);
    },
    [installments, onChange]
  );

  const generateInstallments = useCallback(
    (numberInstall) => {
      const installs = [];

      const orderTotalBN = new BigNumber(orderTotal);
      const numberInstallBN = new BigNumber(numberInstall);

      if (showInstallments) {
        const valueBN = orderTotalBN.dividedBy(numberInstallBN);
        const valueRoundedBN = new BigNumber(valueBN.toFixed(2));

        const totalWithoutDecimalsBN = valueRoundedBN.multipliedBy(
          numberInstallBN.minus(new BigNumber("1"))
        );

        const valueLastInstallBN = orderTotalBN.minus(totalWithoutDecimalsBN);

        const currentDate = moment();
        for (let i = 0; i < numberInstall; i += 1) {
          const dueDate = currentDate.add(1, "M").format("YYYY-MM-DD");

          installs.push({
            installmentNumber: i + 1,
            dueDate,
            value: i === numberInstall - 1 ? valueLastInstallBN : valueRoundedBN,
          });
        }
      }
      return installs;
    },
    [orderTotal, showInstallments]
  );

  const regenerateInstallments = useCallback(
    (numberInstall) => {
      const installs = generateInstallments(numberInstall);
      onChange(installs);
    },
    [generateInstallments, onChange]
  );

  useEffect(() => {
    if (isRecalculate) {
      regenerateInstallments(numberInstallments);
    }
  }, [isRecalculate, numberInstallments, regenerateInstallments]);

  const renderDivisionButton = useCallback(
    () => (
      <Button
        style={{ color: primaryColor[0], borderColor: primaryColor[0] }}
        variant="outlined"
        onClick={() => regenerateInstallments(numberInstallments)}
      >
        {installmentsMessages.divideInstallmentCorrectly}
      </Button>
    ),
    [numberInstallments, regenerateInstallments]
  );

  const renderMessageWrongDivision = useCallback(() => {
    const orderTotalBN = new BigNumber(orderTotal).decimalPlaces(2);
    const totalInstallmentsBN = new BigNumber(totalInstallments);
    let hasDivisionButton = false;
    let message = "";
    if (totalInstallments === 0) {
      hasDivisionButton = false;
      message = "Para esse tipo de forma de pagamento é necessário adicionar ao menos 1 parcela.";
    } else if (orderTotalBN.toNumber() > totalInstallmentsBN.decimalPlaces(2).toNumber()) {
      hasDivisionButton = true;
      message = `A parcelas estão com um total de: ${currencyFormatter(
        orderTotalBN.minus(totalInstallmentsBN).toFixed(2)
      )} a menos que a ${typeOrigin}.`;
    } else {
      hasDivisionButton = true;
      message = `A parcelas estão com um total de: ${currencyFormatter(
        totalInstallmentsBN.minus(orderTotalBN).toFixed(2)
      )} a mais que a ${typeOrigin}.`;
    }

    return (
      <Box display="flex" justifyContent="space-between" alignItems="center" flexGrow={1}>
        <Box display="flex" alignItems="center">
          <Typography inline align="left" style={{ fontWeight: 400, fontSize: 16, color: "red" }}>
            <FontAwesomeIcon
              icon={faTriangleExclamation}
              fontSize={25}
              color="red"
              style={{ marginRight: 4 }}
            />
            {message}
          </Typography>
        </Box>
        <Box>{hasDivisionButton && renderDivisionButton()}</Box>
      </Box>
    );
  }, [orderTotal, totalInstallments, typeOrigin, renderDivisionButton]);

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={6}>
            <FormControl
              fullWidth
              className={classesCustomComponentsStyle.formControlSelect}
              size="small"
              required
            >
              <InputLabel>Conta financeira destino</InputLabel>
              <Select
                label="Conta financeira destino"
                id="financialAccount"
                autoWidth
                value={financialAccount == null ? "" : financialAccount.id}
                // eslint-disable-next-line arrow-body-style
                onChange={(event, child) => {
                  const { objectValue } = child.props;
                  return onChangeFinancialAccount(objectValue);
                }}
                required
                error={errors.paymentMethod}
                helperText={errors.paymentMethod}
              >
                {financialAccounts.map((item) => (
                  <MenuItem key={item.id} value={item.id} objectValue={item}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </GridItem>
          <GridItem xs={12} sm={12} md={6} />
          <GridItem xs={12} sm={12} md={6}>
            <FormControl
              fullWidth
              className={classesCustomComponentsStyle.formControlSelect}
              size="small"
            >
              <InputLabel>{generalMessages.paymentMethod}</InputLabel>
              <Select
                label={generalMessages.paymentMethod}
                id="paymentMethod"
                autoWidth
                value={paymentMethod == null ? "" : paymentMethod.id}
                // eslint-disable-next-line arrow-body-style
                onChange={(event, child) => {
                  const { objectValue } = child.props;
                  return onChangePaymentMethod(objectValue);
                }}
                required
                error={errors.paymentMethod}
                helperText={errors.paymentMethod}
              >
                {paymentMethods.map((item) => (
                  <MenuItem key={item.id} value={item.id} objectValue={item}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </GridItem>
          <GridItem xs={12} sm={12} md={6}>
            {showNumberInstallments && (
              <TextInput
                id="numberInstallments"
                label={generalMessages.amountInstallments}
                value={numberInstallments}
                onChange={(field, value) => {
                  if (value > paymentMethod.maximumAmountInstallments) {
                    enqueueSnackbar(
                      installmentsMessages.maximumAmountInstallments(
                        paymentMethod.maximumAmountInstallments
                      ),
                      {
                        variant: "info",
                        autoHideDuration: 4000,
                      }
                    );
                  } else {
                    regenerateInstallments(value);
                    onChangeNumberInstallments(value);
                  }
                }}
                type="number"
                // required
                errors={errors}
              />
            )}
          </GridItem>
        </GridContainer>
        {showInstallments && (
          <List>
            {/* <ListItem disablePadding>
            <GridItem xs={12} sm={12} md={1} />
            <GridItem xs={12} sm={12} md={2}>
              Parcela
            </GridItem>
            <GridItem xs={12} sm={12} md={3}>
              Data
            </GridItem>
            <GridItem xs={12} sm={12} md={3}>
              Valor
            </GridItem>
            <GridItem xs={12} sm={12} md={2}>
              Status
            </GridItem>
          </ListItem>
          <Divider /> */}
            {keys.map((key) => {
              const item = installments[key];
              return (
                <>
                  <ListItem>
                    <GridItem xs={12} sm={12} md={1}>
                      <FontAwesomeIcon icon={faDollarSign} fontSize={25} color={primaryColor[0]} />
                    </GridItem>

                    <GridItem
                      xs={12}
                      sm={12}
                      md={2}
                    >{`${item.installmentNumber}/${quantity}`}</GridItem>
                    {readOnly ? (
                      <>
                        <GridItem xs={12} sm={12} md={3}>
                          {item.date}
                        </GridItem>
                        <GridItem xs={12} sm={12} md={3}>
                          {currencyFormatter(item.value)}
                        </GridItem>
                        {/* <GridItem xs={12} sm={12} md={2}>
                      {item.paid === "PAID" ? "Pago" : "Não pago"}
                    </GridItem> */}
                      </>
                    ) : (
                      <>
                        <GridItem xs={12} sm={12} md={3} style={{ marginTop: "12px" }}>
                          <DatePicker
                            id="date"
                            label={installmentsMessages.date}
                            format="dd/MM/yyyy"
                            slotProps={{ textField: { size: "small" } }}
                            value={item.dueDate ? moment(item.dueDate).valueOf() : null}
                            onChange={(date) => handleChangeInstallments("dueDate", date, key)}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={3}>
                          <CurrencyTextInput
                            id="value"
                            currencySymbol="R$"
                            label={installmentsMessages.value}
                            value={item.value}
                            onChange={(field, value) => handleChangeInstallments(field, value, key)}
                            required
                            // errors={errors}
                          />
                        </GridItem>
                        {/* <GridItem xs={12} sm={12} md={2}>
                      {item.paid === "PAID" ? "Pago" : "Não pago"}
                    </GridItem> */}
                      </>
                    )}
                  </ListItem>
                  {item.installmentNumber !== quantity && <Divider />}
                </>
              );
            })}
          </List>
        )}
      </GridItem>

      <Divider style={{ width: "100%", marginTop: "10px" }} />

      <GridItem xs={12} sm={12} md={12}>
        <Box style={{ padding: 20 }}>
          {!validation.isValid && (
            <div>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
                style={{ paddingTop: 8, paddingBottom: 8 }}
              >
                {renderMessageWrongDivision()}
              </Grid>
              <Divider style={{ width: "100%" }} />
            </div>
          )}

          <GridContainer>
            <GridItem xs={12} sm={12} md={6} />
            <GridItem xs={12} sm={12} md={6}>
              <Grid container justifyContent="space-between">
                <Typography inline align="left" style={{ fontWeight: 400, fontSize: 18 }}>
                  {installmentsMessages.total(typeOrigin)}
                </Typography>
                <Typography inline align="right" style={{ fontWeight: 500, fontSize: 18 }}>
                  {currencyFormatter(orderTotal)}
                </Typography>
              </Grid>
              {allowsDifference && (
                <>
                  <Grid container>
                    <Box display="flex" flexGrow={1} paddingBottom={1} paddingTop={1}>
                      <Box display="flex" flexGrow={3} alignItems="center">
                        <Typography inline align="left" style={{ fontWeight: 400, fontSize: 18 }}>
                          Valor recebido
                        </Typography>
                      </Box>
                      <Box display="flex" width="30%" alignItems="center">
                        <CurrencyTextInput
                          style={{ marginTop: "0px" }}
                          id="received"
                          currencySymbol="R$"
                          // label={installmentsMessages.value}
                          value={received}
                          onChange={(field, value) => onChangeReceivedValue(value)}
                        />
                      </Box>
                    </Box>
                  </Grid>
                  <Grid container justifyContent="space-between">
                    <Typography inline align="left" style={{ fontWeight: 400, fontSize: 18 }}>
                      Troco
                    </Typography>
                    <Typography inline align="right" style={{ fontWeight: 500, fontSize: 18 }}>
                      {currencyFormatter(difference || 0)}
                    </Typography>
                  </Grid>
                </>
              )}
            </GridItem>
          </GridContainer>
        </Box>
      </GridItem>
    </GridContainer>
  );
}
