import React, { useCallback, useEffect, useRef, useState } from "react";
import { makeStyles } from "@mui/styles";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import { useSnackbar } from "notistack";
import CardStyle from "assets/jss/material-dashboard-react/components/cardStyle";
import moment from "moment/moment";
import { Box, TextField } from "@mui/material";
import MESSAGES from "../../config/messages";

import Loading from "../../components/loading/Loading";
import DataGridCustom from "../../components/dataGrid/DataGridCustom";
import AdminCompaniesService from "./service/AdminCompaniesService";

import ButtonCustom from "../../components/CustomButtons/Button";
import CustomComponentsStyle from "../../assets/jss/material-dashboard-react/components/customComponentsStyle";

const { generalMessages, productMessages } = MESSAGES;
const useCustomComponentsStyle = makeStyles(CustomComponentsStyle);
const useCardStyle = makeStyles(CardStyle);

export default function AdminCompaniesList() {
  const classesuseCardStyle = useCardStyle();
  const classesCustomComponentsStyle = useCustomComponentsStyle();
  const [list, setList] = useState([]);

  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);

  // Does not cause re-renders
  const firstLoad = useRef(true);
  const { enqueueSnackbar } = useSnackbar();
  const customGridRef = useRef();
  const [rowCount, setRowCount] = React.useState(0);

  const fetchAll = useCallback(
    async ({ term, paginationModelParams, sorting }) => {
      setLoading(true);

      const pagination = paginationModelParams || customGridRef.current.getDefaultPaginationModel();

      try {
        const result = await AdminCompaniesService.fetchAll({ term, pagination, sorting });
        const { data } = result;
        const { content, totalElements } = data;

        setList(content);
        setRowCount(totalElements);
      } catch (error) {
        enqueueSnackbar(generalMessages.error, {
          variant: "error",
        });
      } finally {
        setLoading(false);
      }
    },
    [enqueueSnackbar]
  );

  useEffect(() => {
    if (firstLoad.current) {
      fetchAll({ term: "" });
      firstLoad.current = false;
    }
  }, [fetchAll, firstLoad]);

  const onKeyPress = useCallback(
    (ev) => {
      if (ev.which === 13) {
        fetchAll({ term: searchTerm });
        customGridRef.current.resetPaginationModel();
      }
    },
    [fetchAll, searchTerm]
  );

  const onClear = useCallback(() => {
    fetchAll({ term: "" });
    setSearchTerm("");
    customGridRef.current.resetPaginationModel();
  }, [fetchAll]);

  const columns = [];

  columns.push({
    field: "createdAt",
    headerName: "Cadastro em",
    // eslint-disable-next-line arrow-body-style
    valueGetter: ({ value }) => moment(value).format("DD/MM/yyyy HH:mm"),
    minWidth: 160,
    flex: 1,
  });

  columns.push({
    field: "lastLogin",
    headerName: "Último login",
    // eslint-disable-next-line arrow-body-style
    valueGetter: ({ value }) => (value ? moment(value).format("DD/MM/yyyy HH:mm") : ""),
    minWidth: 160,
    flex: 1,
  });

  columns.push({
    field: "name",
    headerName: "Nome",
    // eslint-disable-next-line arrow-body-style
    valueGetter: ({ row }) => {
      return row.name;
    },
    flex: 2,
    minWidth: 300,
  });

  columns.push({
    field: "phone",
    headerName: "Telefone",
    // eslint-disable-next-line arrow-body-style
    valueGetter: ({ row }) => {
      return row.phone;
    },
    flex: 2,
    minWidth: 150,
  });

  columns.push({
    field: "persons",
    headerName: "Pessoas",
    // eslint-disable-next-line arrow-body-style
    valueGetter: ({ row }) => {
      return row.persons;
    },
    flex: 1,
    minWidth: 150,
  });

  columns.push({
    field: "sales",
    headerName: "Vendas",
    // eslint-disable-next-line arrow-body-style
    valueGetter: ({ row }) => {
      return row.sales;
    },
    flex: 1,
    minWidth: 150,
  });

  columns.push({
    field: "os",
    headerName: "OS",
    // eslint-disable-next-line arrow-body-style
    valueGetter: ({ row }) => {
      return row.os;
    },
    flex: 1,
    minWidth: 150,
  });

  columns.push({
    field: "products",
    headerName: "Produtos",
    // eslint-disable-next-line arrow-body-style
    valueGetter: ({ row }) => {
      return row.products;
    },
    flex: 1,
    minWidth: 150,
  });

  columns.push({
    field: "plan",
    headerName: "Plano",
    // eslint-disable-next-line arrow-body-style
    valueGetter: ({ row }) => {
      return row.plan;
    },
    flex: 1,
    minWidth: 150,
  });

  columns.push({
    field: "lastPaymentEndDate",
    headerName: "Pago até",
    // eslint-disable-next-line arrow-body-style
    valueGetter: ({ row }) => {
      return row.lastPaymentEndDate;
    },
    flex: 1,
    minWidth: 150,
  });

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card className="gridLayoutCard">
          <div className="gridLayoutContainer">
            <CardHeader color="primary">
              <h4 className={classesuseCardStyle.cardTitleList}>Empresas</h4>
            </CardHeader>

            <div className="gridLayoutFilters">
              <GridContainer>
                <GridItem xs={12} sm={12} md={8}>
                  <TextField
                    size="small"
                    onKeyPress={onKeyPress}
                    variant="outlined"
                    id="number"
                    label="Digite para pesquisar nome"
                    fullWidth
                    InputLabelProps={{
                      className: classesCustomComponentsStyle.labelTextField,
                    }}
                    className={classesCustomComponentsStyle.textField}
                    value={searchTerm == null ? "" : searchTerm}
                    onChange={(value) => setSearchTerm(value.currentTarget.value)}
                  />
                </GridItem>

                <GridItem xs={12} sm={12} md={4} style={{ marginTop: 6, flexBasis: 0 }}>
                  <Box display="flex">
                    <ButtonCustom
                      color="primary"
                      onClick={() => {
                        fetchAll({ term: searchTerm });
                        customGridRef.current.resetPaginationModel();
                      }}
                    >
                      {generalMessages.search}
                    </ButtonCustom>
                    <ButtonCustom color="primary" onClick={onClear}>
                      Limpar
                    </ButtonCustom>
                  </Box>
                </GridItem>
              </GridContainer>
            </div>

            <div className="gridLayoutGrid">
              <DataGridCustom
                ref={customGridRef}
                getRowId={(row) => row.id}
                rows={list}
                columns={columns}
                usePagination
                rowCount={rowCount}
                fetchAction={(params) =>
                  fetchAll({
                    ...params,
                    term: searchTerm,
                  })
                }
              />
            </div>
          </div>
        </Card>
      </GridItem>
      <Loading loading={loading} />
    </GridContainer>
  );
}
