import axios from "axios";
import { APIS } from "../../../config/constants";
import BaseService from "../../../core/BaseService";

const { register: registerAPI } = APIS;

class ServiceProductService extends BaseService {
  constructor() {
    super(registerAPI.product);
  }

  fetchByBarCode = (term) => axios.get(`${this.endpoint}/search-by-barcode/${term}`);

  fetchAll = ({ term, pagination, sorting }) => {
    const { page, pageSize } = pagination;
    const { field = "createdAt", sort = "desc" } = sorting || {};
    return axios.get(
      `${this.endpoint}/search-products?size=${pageSize}&page=${page}&sort=${field},${sort}&term=${term}&service=true`
    );
  };
}

export default new ServiceProductService();
