/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useCallback } from "react";
import CustomComponentsStyle from "assets/jss/material-dashboard-react/components/customComponentsStyle";
import { makeStyles } from "@mui/styles";
import { FormControl, InputLabel, Select, MenuItem, Box, Button as ButtonUI } from "@mui/material";
import { useSnackbar } from "notistack";
import TextInput from "components/textInput/TextInput";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { primaryColor } from "assets/jss/material-dashboard-react";
import DeleteIcon from "components/icons/DeleteIcon";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

const IESubstitutosTributarios = ({ states, items, handleChange }) => {
  const useCustomComponentsStyle = makeStyles(CustomComponentsStyle);
  const classesCustomComponentsStyle = useCustomComponentsStyle();
  const { enqueueSnackbar } = useSnackbar();

  const add = useCallback(() => {
    // // Checking if already exist a line empty
    // if (Object.keys(items?.[0] || []).length < 3) {
    //   return;
    // }

    const newItem = { index: items.length };
    const newItems = [...items, newItem];
    handleChange(newItems);
  }, [handleChange, items]);

  const remove = useCallback(
    (i) => {
      const newItems = [...items];
      const index = newItems.findIndex((item) => item.i === i);
      newItems.splice(index, 1);
      handleChange(newItems);
    },
    [handleChange, items]
  );

  const onChange = useCallback(
    (field, value, index) => {
      const newItems = [...items];

      const item = newItems[index];

      newItems[index] = {
        ...item,
        [field]: value,
      };

      handleChange(newItems);
    },
    [handleChange, items]
  );

  const isStateAdded = useCallback(
    (value) => {
      const index = items.findIndex((item) => item.state === value);
      if (index !== -1) {
        enqueueSnackbar("O estado selecionado já está adicionado!", {
          variant: "warning",
          autoHideDuration: 4000,
        });
        return true;
      }
      return false;
    },
    [enqueueSnackbar, items]
  );

  const renderAddButton = () => (
    <GridItem xs={12} sm={12} md={3}>
      <Box display="flex" style={{ marginTop: 12 }}>
        <ButtonUI style={{ color: primaryColor[0] }} onClick={add}>
          Adicionar
        </ButtonUI>
      </Box>
    </GridItem>
  );

  const renderItem = (item) => (
    <GridContainer>
      <GridItem xs={12} sm={12} md={2}>
        <FormControl
          fullWidth
          className={classesCustomComponentsStyle.formControlSelect}
          size="small"
          variant="outlined"
        >
          <InputLabel>Estado</InputLabel>
          <Select
            label="Estado"
            autoWidth
            value={item.state == null ? "" : item.state}
            onChange={(event) => {
              const {
                target: { value },
              } = event;
              return !isStateAdded(value) ? onChange("state", value, item.index) : null;
            }}
          >
            {states.map((itemState) => {
              const { id, uf } = itemState;
              return (
                <MenuItem key={id} value={itemState.uf}>
                  {uf}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </GridItem>
      <GridItem xs={12} sm={12} md={9}>
        <TextInput
          id="ie"
          label="Inscrição Estadual"
          value={item.ie}
          maxLength={14}
          onChange={(field, value) =>
            onChange(field, value.replace(/[^a-zA-Z0-9 ]/g, ""), item.index)
          }
        />
      </GridItem>
      <GridItem xs={12} sm={12} md={1}>
        <Box style={{ marginTop: 20 }}>
          <DeleteIcon onClick={() => remove(item.index)} />
        </Box>
      </GridItem>
    </GridContainer>
  );

  const renderItems = () => {
    if (items.length === 0) {
      return add();
    }
    return items.map((item) => renderItem(item));
  };

  return [renderItems(), renderAddButton()];
};

export default IESubstitutosTributarios;
