import React from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { primaryColor } from "../../assets/jss/material-dashboard-react";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modalContent: {
    backgroundColor: "#fff",
    padding: theme.spacing(2),
    borderRadius: theme.spacing(1),
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
  },
}));

function CustomModal({ open, onClose, title, actionButtonText, actionButtonFunction, children }) {
  const classes = useStyles();

  return (
    <Dialog open={open} fullWidth maxWidth="md">
      {title && <DialogTitle>{title}</DialogTitle>}
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <div style={{ flexGrow: 1, display: "flex", justifyContent: "space-between" }}>
          <Button style={{ color: primaryColor[0] }} onClick={onClose}>
            Voltar
          </Button>
        </div>
        <div style={{ flexGrow: 0, display: "flex", justifyContent: "space-between" }}>
          <Button style={{ color: primaryColor[0] }} onClick={actionButtonFunction}>
            {actionButtonText}
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
}

CustomModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  actionButtonText: PropTypes.string.isRequired,
  actionButtonFunction: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export default CustomModal;
