import axios from "axios";
import { APIS } from "../../../config/constants";

const { register: registerAPI } = APIS;

class UserCompanyService {
  save = (request) => axios.post(`${registerAPI.company}/createNewAccount`, request);
}

export default new UserCompanyService();
