/* eslint-disable no-alert */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React, { useCallback } from "react";
import { makeStyles } from "@mui/styles";
import CustomComponentsStyle from "assets/jss/material-dashboard-react/components/customComponentsStyle";
import GridItem from "components/Grid/GridItem";
import TextInput from "components/textInput/TextInput";
import GridContainer from "components/Grid/GridContainer";
import SelectEnum from "components/selectEnum/SelectEnum";
import { Autocomplete as AutocompleteMUI, Divider, TextField, Typography } from "@mui/material";
import {
  NFFinalidade,
  NFTipo,
  NFOperacaoConsumidorFinal,
  NFIdentificadorLocalDestinoOperacao,
  NFIndicadorPresencaComprador,
  NFCeIndicadorPresencaComprador,
  NFModelo,
} from "views/notaFiscal/model/NotaFiscal";

const useCustomComponentsStyle = makeStyles(CustomComponentsStyle);

const NotaFiscalDadosGerais = ({
  disabled = false,
  data = {},
  handleChange,
  handleChangeNaturezaOperacao,
  errors = {},
  naturezasOperacao,
}) => {
  const classesCustomComponentsStyle = useCustomComponentsStyle();

  const handleBlurNumeroDaNota = () => {
    alert(
      "Não é recomendado alterar o número da nota. Faça alterações somente em casos de duplicidade de nota, quando for necessário ajustar a ordem manualmente. No caso de utilizar um número maior do que a última emissão, a faixa será pulada, e a próxima nota emitida continuará após o número informado. Por exemplo, se a última nota foi a 100 e você editar o número para 150, a próxima nota emitida será a 151, e a faixa pulada precisará ser inutilizada."
    );
  };

  const onChange = useCallback(
    (field, value) => {
      handleChange({
        ...data,
        [field]: value,
      });
    },
    [data, handleChange]
  );

  const renderNumero = () => (
    <GridItem xs={12} sm={12} md={2}>
      <TextInput
        id="numero"
        label="Número"
        type="number"
        value={data.numero}
        onChange={onChange}
        errors={errors}
        disabled={disabled}
        onBlur={handleBlurNumeroDaNota}
      />
    </GridItem>
  );

  const renderNaturezaOperacao = () => (
    <GridItem xs={12} sm={12} md={6}>
      <AutocompleteMUI
        disableListWrap
        disabled={disabled}
        // openOnFocus
        id="naturezaOperacao"
        options={naturezasOperacao}
        size="small"
        getOptionLabel={(option) => option?.descricao || ""}
        value={data.naturezaOperacao == null ? "" : data.naturezaOperacao}
        onChange={(ev, value) => handleChangeNaturezaOperacao(value)}
        renderInput={(params) => (
          <TextField
            {...params}
            // autoFocus
            className={classesCustomComponentsStyle.textField}
            style={{ width: "100%" }}
            label="Natureza Operação"
            variant="outlined"
            required
            error={errors?.naturezaOperacao}
          />
        )}
      />
    </GridItem>
  );

  const renderTipo = () => (
    <GridItem xs={12} sm={12} md={3}>
      <SelectEnum
        label="Tipo"
        field="tipo"
        Enum={NFTipo}
        valueSelected={data.tipo}
        handleChange={onChange}
        errors={errors}
        required
        disabled={disabled}
      />
    </GridItem>
  );

  const renderConsumidorFinal = () => (
    <GridItem xs={12} sm={12} md={3}>
      <SelectEnum
        label="Consumidor final"
        field="operacaoConsumidorFinal"
        Enum={NFOperacaoConsumidorFinal}
        valueSelected={data.operacaoConsumidorFinal}
        handleChange={onChange}
        errors={errors}
        required
        disabled={disabled}
      />
    </GridItem>
  );

  const renderFinalidade = () => (
    <GridItem xs={12} sm={12} md={3}>
      <SelectEnum
        label="Finalidade de emissão"
        field="finalidade"
        Enum={NFFinalidade}
        valueSelected={data.finalidade}
        handleChange={onChange}
        errors={errors}
        required
        disabled={disabled}
      />
    </GridItem>
  );

  const renderDestinoOperacao = () => (
    <GridItem xs={12} sm={12} md={3}>
      <SelectEnum
        label="Destino da operação"
        field="identificadorLocalDestinoOperacao"
        Enum={NFIdentificadorLocalDestinoOperacao}
        valueSelected={data.identificadorLocalDestinoOperacao}
        handleChange={onChange}
        errors={errors}
        required
        disabled={disabled}
      />
    </GridItem>
  );

  const renderTipoAtendimento = () => (
    <GridItem xs={12} sm={12} md={3}>
      <SelectEnum
        label="Tipo de atendimento"
        field="indicadorPresencaComprador"
        Enum={
          data?.modelo === NFModelo.NFE.code
            ? NFIndicadorPresencaComprador
            : NFCeIndicadorPresencaComprador
        }
        valueSelected={data.indicadorPresencaComprador}
        handleChange={onChange}
        errors={errors}
        required
        disabled={disabled}
      />
    </GridItem>
  );

  const renderChaveNfeReferenciada = () => (
    <GridItem xs={12} sm={12} md={6}>
      <TextInput
        id="chaveNfeReferenciada"
        label="Chave de acesso nota fiscal referenciada"
        value={data.chaveNfeReferenciada}
        onChange={(field, value) =>
          onChange(field, value.replace(/[^0-9]/g, "").slice(0, 44))
        }
        // maxLength={44}
        errors={errors}
        disabled={disabled}
      />
    </GridItem>
  );

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12} style={{ marginTop: 12 }}>
        <Divider style={{ width: "100%", marginBottom: 4 }} />
        <Typography style={{ fontSize: 18, fontWeight: 500 }}>Dados gerais</Typography>
      </GridItem>
      {renderNaturezaOperacao()}
      {/* {renderTipo()} */}
      {/* {renderConsumidorFinal()} */}
      {/* {renderFinalidade()} */}

      {renderDestinoOperacao()}
      {renderTipoAtendimento()}
      {data?.naturezaOperacao?.finalidade === NFFinalidade.DEVOLUCAO_OU_RETORNO.code && renderChaveNfeReferenciada()}
      {data?.numero != null && renderNumero()}
      {/* <GridItem xs={12} sm={12} md={3} /> */}
    </GridContainer>
  );
};

export default React.memo(NotaFiscalDadosGerais);
