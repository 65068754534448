const NotaFiscalPacoteDownloadStatusType = {
  AGUARDANDO_PROCESSAMENTO: {
    code: "AGUARDANDO_PROCESSAMENTO",
    name: "Aguardando processamento",
  },
  EM_PROCESSAMENTO: {
    code: "EM_PROCESSAMENTO",
    name: "Em processamento",
  },
  PRONTO: {
    code: "PRONTO",
    name: "Pronto",
  },
  ERRO: {
    code: "ERRO",
    name: "Erro",
  },
  NENHUMA_NF_ENCONTRADA: {
    code: "NENHUMA_NF_ENCONTRADA",
    name: "Nenhuma NF encontrada",
  },
};

class NotaFiscalPacoteDownload {
  constructor(source = {}) {
    if (source == null) return this;

    this.id = source.id;
    this.startDate = source.startDate;
    this.endDate = source.endDate;
    this.status = source.status;
    this.createdAt = source.createdAt;
    this.version = source.version;
    this.active = source.active;
  }
}

export { NotaFiscalPacoteDownload, NotaFiscalPacoteDownloadStatusType };
