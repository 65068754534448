import axios from "axios";
import { APIS } from "../../../config/constants";
import BaseService from "../../../core/BaseService";

const { register: registerAPI } = APIS;

class NotaFiscalConfig extends BaseService {
  constructor() {
    super(registerAPI.notaFiscalConfig);
  }

  fetch = () => axios.get(`${this.endpoint}/current`);
}

export default new NotaFiscalConfig();
