/* eslint-disable no-new */
import React, { useState, useCallback, useEffect, useReducer, useContext, useRef } from "react";
import CustomComponentsStyle from "assets/jss/material-dashboard-react/components/customComponentsStyle";
import CardStyle from "assets/jss/material-dashboard-react/components/cardStyle";
import moment from "moment";
import { faDollarSign } from "@fortawesome/free-solid-svg-icons";
import { useSnackbar } from "notistack";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import ButtonCustom from "components/CustomButtons/Button";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  TextField,
  Chip,
  Divider,
} from "@mui/material";
import Autocomplete from "components/autocomplete/Autocomplete";
import { makeStyles } from "@mui/styles";
import { DatePicker } from "@mui/x-date-pickers";
import useConfirmDialog from "components/confirm/ConfirmDialog";
import AccordionFilters from "components/Accordion/AccordionFilters";
import CurrencyTextInput from "../../components/textInput/CurrencyTextInput";
import EditIcon from "../../components/icons/EditIcon";
import DeleteIcon from "../../components/icons/DeleteIcon";
import TextInput from "../../components/textInput/TextInput";
import Loading from "../../components/loading/Loading";
import { primaryColor } from "../../assets/jss/material-dashboard-react";
import MESSAGES from "../../config/messages";
import Service from "./service/CommissionService";
import AddButton from "../../components/addButton/AddButton";
import { Commission, OriginType } from "./model/Commission";
import { handlingResponseErrors, currencyFormatter, isEmptyString } from "../../config/util";
import { toRequest, fromResponse } from "./converter/CommissionConverter";
import UserService from "../user/service/UserService";
import ReportService from "../reports/service/ReportService";
import CommissionConfigService from "../commissionConfig/service/CommissionConfigService";
import DataGridCustom from "../../components/dataGrid/DataGridCustom";
import UserContext from "../../core/UserContext";

const Style = {
  muiDataGridRowPaymentRow: {
    backgroundColor: "#88e9bc",
  },
};

const useCustomComponentsStyle = makeStyles(CustomComponentsStyle);
const useCardStyle = makeStyles(CardStyle);
const useStyle = makeStyles(Style);

const { commissionMessages, generalMessages } = MESSAGES;

const formatDate = (date) => date.format("YYYY-MM-DD");

export default function CommissionComponent() {
  const customGridRef = useRef();
  const { userLogged } = useContext(UserContext);
  const classesuseCardStyle = useCardStyle();
  const classes = useStyle();
  const classesCustomComponentsStyle = useCustomComponentsStyle();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const [openClosure, setOpenClosure] = useState(false);
  const [values, setValues] = useState({ pending: 0, paid: 0, total: 0 });
  const [valuesPeriod, setValuesPeriod] = useState({
    pending: 0,
    paid: 0,
    total: 0,
    totalOrders: 0,
  });
  const { showConfirmDialog, closeConfirmDialog, ConfirmDialog } = useConfirmDialog();

  const [commission, setCommission] = useState(new Commission());
  const [modalClosureData, setModalClosureData] = useState(null);
  const [lastClosure, setLastClosure] = useState(null);
  const [closureConfig, setClosureConfig] = useState(null);
  const [vendasEMeta, setVendasEMeta] = useState(null);
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);

  const [errors, setErrors] = useState({});
  const [rowCount, setRowCount] = React.useState(0);

  const getInitialSearchState = useCallback(() => {
    const startDate = moment().startOf("month");
    const endDate = moment().endOf("month");
    return {
      now: moment(),
      startDate,
      endDate,
      orderCode: "",
      user: {
        id: userLogged?.userId,
        name: userLogged?.name,
      },
    };
  }, [userLogged]);

  const searchReducer = (state, action) => {
    switch (action.type) {
      case "setStartDate": {
        return {
          ...state,
          startDate: action.value ? moment(action.value) : null,
        };
      }
      case "setEndDate": {
        return {
          ...state,
          endDate: action.value ? moment(action.value) : null,
        };
      }
      case "setUser": {
        return {
          ...state,
          user: action.value,
        };
      }
      case "setOrderCode": {
        return {
          ...state,
          orderCode: action.value,
        };
      }

      case "reset": {
        return {
          ...getInitialSearchState(),
        };
      }
      default:
        throw new Error("Action not defined");
    }
  };

  const [searchState, dispatchSearch] = useReducer(searchReducer, getInitialSearchState());

  const handleChange = useCallback(
    (field, value) => {
      setCommission({
        ...commission,
        [field]: value,
      });
    },
    [commission]
  );

  const handleChangeModalClosureData = useCallback(
    (field, value) => {
      setModalClosureData({
        ...modalClosureData,
        [field]: value,
      });
    },
    [modalClosureData]
  );

  const fetchValues = useCallback(async (queryParams) => {
    const { user } = queryParams;
    const params = [];

    if (!isEmptyString(user?.id)) {
      params.push(`userId=${user.id}`);
    }

    const result = await Service.featchCommissionsValues(params.join("&"));
    const { data } = result;
    setValues(data);
  }, []);

  const fetchLastClosure = useCallback(async (userId) => {
    if (userId) {
      const result = await Service.lastClosure(userId);
      setLastClosure(result?.data?.data);
    } else {
      setLastClosure(null);
    }
  }, []);

  const fetchClosureManual = useCallback(async (userId) => {
    if (userId) {
      const result = await CommissionConfigService.commissionConfigForUser(userId);
      setClosureConfig(result?.data?.data);
    } else {
      setClosureConfig(null);
    }
  }, []);

  useEffect(() => {
    fetchLastClosure(searchState?.user?.id);
    fetchClosureManual(searchState?.user?.id);
  }, [searchState?.user]);

  const fetchValuesPeriod = useCallback(async (queryParams) => {
    const { user, startDate, endDate } = queryParams;
    const params = [];

    if (!isEmptyString(user?.id)) {
      params.push(`userId=${user.id}`);
    }
    if (startDate != null) {
      params.push(`startDate=${formatDate(startDate)}`);
    }
    if (endDate != null) {
      params.push(`endDate=${formatDate(endDate)}`);
    }

    const result = await Service.featchCommissionsValues(params.join("&"));
    const { data } = result;
    setValuesPeriod(data);
  }, []);

  const fetchSearch = useCallback(
    async ({ queryParams, paginationModelParams, sorting }) => {
      setLoading(true);
      try {
        const { user, orderCode, startDate, endDate } = queryParams;
        const params = [];
        const pagination =
          paginationModelParams || customGridRef.current.getDefaultPaginationModel();

        if (!isEmptyString(user?.id)) {
          params.push(`userId=${user.id}`);
        }
        if (!isEmptyString(orderCode)) {
          params.push(`orderCode=${orderCode}`);
        }
        if (startDate != null && startDate.isValid()) {
          params.push(`startDate=${formatDate(startDate)}`);
        }
        if (endDate != null && endDate.isValid()) {
          params.push(`endDate=${formatDate(endDate)}`);
        }

        const result = await Service.featchCommissions({
          params: params.join("&"),
          pagination,
          sorting,
        });
        const { data } = result;
        let { content } = data;
        const { totalElements } = data;
        content = content.map((item) => fromResponse(item));
        setList(content);
        fetchValues(queryParams);
        fetchValuesPeriod(queryParams);
        setRowCount(totalElements);
        setErrors({});
      } catch (error) {
        const { message, validationErrors } = handlingResponseErrors(error);
        enqueueSnackbar(message, {
          variant: "error",
          autoHideDuration: 3000,
        });
        if (validationErrors != null) {
          setErrors(validationErrors);
        }
      } finally {
        setLoading(false);
      }
    },
    [enqueueSnackbar, fetchValues, fetchValuesPeriod]
  );

  const onDelete = useCallback(
    async ({ id }) => {
      showConfirmDialog({
        title: "Comissão",
        text: generalMessages.deleteConfirm,
        onClickConfirm: async () => {
          try {
            await Service.delete(id);
            enqueueSnackbar(generalMessages.deleteSuccess, {
              variant: "success",
            });

            customGridRef.current.resetPaginationModel();
            fetchSearch({ queryParams: searchState });
          } catch (error) {
            enqueueSnackbar(generalMessages.error, {
              variant: "error",
            });
          }
          closeConfirmDialog();
        },
      });
    },
    [showConfirmDialog, closeConfirmDialog, enqueueSnackbar, fetchSearch, searchState]
  );

  const onEdit = useCallback(
    ({ id }) => {
      const itemSelected = list.find((item) => item.id === id);
      if (itemSelected.originType === OriginType.PAYMENT.code) {
        setCommission(new Commission(itemSelected));
        setOpen(true);
      }
    },
    [list]
  );

  const onClose = useCallback(() => {
    setOpen(false);
    setCommission(new Commission());
  }, []);

  const onCloseClosure = useCallback(() => {
    setOpenClosure(false);
    setCommission(new Commission());
    setModalClosureData(null);
    setVendasEMeta(null);
  }, []);

  const calcularMeta = useCallback(async () => {
    try {
      setLoading(true);

      const request = {
        userId: commission?.user?.id,
        startDate: moment(modalClosureData.startDate).format("YYYY-MM-DD"),
        endDate: moment(modalClosureData.endDate).format("YYYY-MM-DD"),
      };

      const result = await Service.calcularMeta(request);

      if (result == null) {
        enqueueSnackbar("Ocorreu um erro ao calcular comissões!", {
          variant: "error",
          autoHideDuration: 3000,
        });
      }

      setVendasEMeta(result?.data?.data);
    } catch (error) {
      enqueueSnackbar(error.response.status === 428 ? error.response.data : generalMessages.error, {
        variant: "error",
      });
    } finally {
      setLoading(false);
    }
  }, [commission, enqueueSnackbar, modalClosureData]);

  const onSaveClosure = useCallback(async () => {
    try {
      setLoading(true);

      const request = {
        userId: commission?.user?.id,
        startDate: moment(modalClosureData.startDate).format("YYYY-MM-DD"),
        endDate: moment(modalClosureData.endDate).format("YYYY-MM-DD"),
      };

      await Service.closure(request);

      setOpen(false);
      setErrors({});

      fetchLastClosure(commission?.user?.id);

      enqueueSnackbar(generalMessages.saveSuccess, {
        variant: "success",
        autoHideDuration: 3000,
      });

      setOpenClosure(false);
    } catch (error) {
      const { message, validationErrors } = handlingResponseErrors(error);
      enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
      if (validationErrors != null) {
        setErrors(validationErrors);
      }
    } finally {
      setLoading(false);
      customGridRef.current.resetPaginationModel();
      fetchSearch({ queryParams: searchState });
    }
  }, [fetchSearch, commission, enqueueSnackbar, searchState, modalClosureData]);

  const onSave = useCallback(async () => {
    try {
      setLoading(true);
      const { id } = commission;

      if (id != null) {
        await Service.update(id, toRequest(commission));
      } else {
        await Service.save(toRequest(commission));
      }
      setOpen(false);
      setErrors({});

      enqueueSnackbar(generalMessages.saveSuccess, {
        variant: "success",
        autoHideDuration: 3000,
      });
    } catch (error) {
      const { message, validationErrors } = handlingResponseErrors(error);
      enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
      if (validationErrors != null) {
        setErrors(validationErrors);
      }
    } finally {
      setLoading(false);
      customGridRef.current.resetPaginationModel();
      fetchSearch({ queryParams: searchState });
    }
  }, [fetchSearch, commission, enqueueSnackbar, searchState]);

  useEffect(() => {
    if (userLogged) {
      fetchSearch({ queryParams: getInitialSearchState() });
    }
  }, [fetchSearch, getInitialSearchState, userLogged]);

  useEffect(() => {
    if (userLogged) {
      const user = {
        id: userLogged.userId,
        name: userLogged.name,
      };
      dispatchSearch({ type: "setUser", value: user });
    }
  }, [userLogged]);

  const handleOpen = useCallback(() => {
    const { user } = searchState;
    if (user && user !== null) {
      setCommission(
        new Commission({
          user: searchState.user,
          originType: OriginType.PAYMENT.code,
          commissionDate: new Date(),
        })
      );
      setOpen(true);
    } else {
      enqueueSnackbar(
        "Para efetuar pagamentos é necessário selecionar um profissional na pesquisa",
        {
          variant: "info",
          autoHideDuration: 5000,
        }
      );
    }
  }, [searchState, enqueueSnackbar]);

  const handleOpenClosure = useCallback(() => {
    const { user } = searchState;
    if (user && user !== null) {
      setCommission(
        new Commission({
          user: searchState.user,
          originType: OriginType.PAYMENT.code,
          commissionDate: new Date(),
        })
      );

      if (lastClosure) {
        setModalClosureData({
          startDate: moment(lastClosure).clone().add(1, "day"),
          endDate: moment(),
        });
      } else {
        setModalClosureData({
          startDate: lastClosure || moment().subtract(1, "day").subtract(1, "month"),
          endDate: moment().subtract(1, "day"),
        });
      }

      setVendasEMeta(null);
      setOpenClosure(true);
    } else {
      enqueueSnackbar(
        "Para efetuar pagamentos é necessário selecionar um profissional na pesquisa",
        {
          variant: "info",
          autoHideDuration: 5000,
        }
      );
    }
  }, [searchState, enqueueSnackbar, lastClosure]);

  const formatterValue = (item) => {
    const { calculated, totalCommission, originType, commissionConfiguration } = item;

    return OriginType.PAYMENT.code === originType || calculated ? (
      currencyFormatter(totalCommission || 0)
    ) : (
      <Chip
        size="small"
        label={
          commissionConfiguration?.goalAutomaticClosing
            ? `Fechamento da meta dia ${commissionConfiguration?.goalAutomaticClosingDay}`
            : "Aguardando fechamento manual"
        }
        style={{ backgroundColor: "#0E86D4", color: "#ffff" }}
      />
    );
  };

  const formatterCommisionPorcentage = (item) => {
    const { calculated, originType, goal } = item;

    let retorno = "";

    if (OriginType.PAYMENT.code !== originType && goal && calculated) {
      if (item?.goalStartTrackProduct != null) {
        retorno = (
          <div
            style={{
              cursor: "pointer",
              justifyContent: "space-between",
              display: "flex",
              flex: 1,
              padding: "0px",
            }}
          >
            {item?.goal && item?.goalTargetCalculationMethod === "TOTAL_VALUE"
              ? `Meta ${currencyFormatter(item?.goalStartTrackProduct || 0)} à ${currencyFormatter(
                  item?.goalEndTrackProduct || 0
                )}`
              : ""}
            {item?.goal && item?.goalTargetCalculationMethod === "TOTAL_VALUE" ? <br /> : ""}
            {item?.goal && item?.goalTargetCalculationMethod === "TOTAL_VALUE"
              ? `Produtos ${item?.goalProductPercentage}%, Serviços ${item?.goalServicePercentage}%`
              : ""}

            {item?.goal && item?.goalTargetCalculationMethod === "SEPARATE_VALUES"
              ? `Produtos ${currencyFormatter(
                  item?.goalStartTrackProduct || 0
                )} à ${currencyFormatter(item?.goalEndTrackProduct || 0)} (${
                  item?.goalProductPercentage
                }%)`
              : ""}
            {item?.goal && item?.goalTargetCalculationMethod === "SEPARATE_VALUES" ? <br /> : ""}
            {item?.goal && item?.goalTargetCalculationMethod === "SEPARATE_VALUES"
              ? `Serviços ${currencyFormatter(
                  item?.goalStartTrackService || 0
                )} à ${currencyFormatter(item?.goalEndTrackService || 0)} (${
                  item?.goalServicePercentage
                }%)`
              : ""}

            {!item?.goal
              ? `Produtos ${item?.percentageProductCommission}%, Serviços ${item?.percentageServiceCommission}%`
              : ""}
          </div>
        );
      } else {
        retorno = (
          <div
            style={{
              cursor: "pointer",
              justifyContent: "space-between",
              display: "flex",
              flex: 1,
              padding: "0px",
            }}
          >
            Nenhuma meta atingida
          </div>
        );
      }
    } else {
      retorno = (
        <div
          style={{
            cursor: "pointer",
            justifyContent: "space-between",
            display: "flex",
            flex: 1,
            padding: "0px",
          }}
        >
          {!item?.goal
            ? `Produtos ${item?.percentageProductCommission}%, Serviços ${item?.percentageServiceCommission}%`
            : ""}
        </div>
      );
    }

    return retorno;
  };

  const formatterReceiveOriginType = (item) => {
    const { originType } = item;
    if (originType != null && originType !== undefined) {
      switch (originType) {
        case OriginType.PAYMENT.code:
          return (
            <Chip
              size="small"
              label={OriginType[originType].name}
              style={{ backgroundColor: "#0e850b", color: "#ffff" }}
            />
          );
        // return OriginType.PAYMENT.name;

        case OriginType.ORDER.code:
          return (
            <Chip
              size="small"
              label={OriginType[originType].initials}
              style={{ backgroundColor: "#0E86D4", color: "#ffff" }}
            />
          );

        case OriginType.SERVICE_ORDER.code:
          return (
            <Chip
              size="small"
              label={OriginType[originType].initials}
              style={{ backgroundColor: "#bc00f5", color: "#ffff" }}
            />
          );

        default:
          return "";
      }
    }
    return "";
  };

  const columns = [];
  columns.push({
    field: "commissionDate",
    headerName: "Data",
    flex: 1,
    minWidth: 100,
    valueGetter: ({ value }) => (value ? moment(value).format("DD/MM/YYYY") : ""),
  });
  columns.push({
    field: "name",
    headerName: commissionMessages.name,
    flex: 1,
    minWidth: 150,
  });
  columns.push({
    field: "goal",
    headerName: "Utiliza meta",
    flex: 1,
    minWidth: 100,
    renderCell: ({ row: item }) => (
      <div>
        {OriginType.PAYMENT.code === item?.originType && <>--</>}
        {OriginType.PAYMENT.code !== item?.originType && (item?.goal ? "Sim" : `Não`)}
      </div>
    ),
  });

  columns.push({
    field: "orderValueTotal",
    headerName: "Total da Venda/Ordem Serviço",
    flex: 1,
    minWidth: 150,
    valueGetter: ({ value }) => (value ? currencyFormatter(value || 0) : "--"),
  });

  columns.push({
    field: "totalCommission",
    headerName: "Valor",
    flex: 1,
    minWidth: 120,
    renderCell: ({ row: item }) => formatterValue(item),
  });

  columns.push({
    field: "commission",
    headerName: "Comissão Utilizada",
    flex: 2,
    minWidth: 250,
    renderCell: ({ row: item }) => formatterCommisionPorcentage(item),
  });

  columns.push({
    field: "originType",
    headerName: "Origem",
    flex: 1,
    minWidth: 80,
    renderCell: ({ row: item }) => formatterReceiveOriginType(item),
  });

  columns.push({
    field: "actions",
    headerName: generalMessages.actions,
    sortable: false,
    width: 100,
    disableClickEventBubbling: true,
    renderCell: ({ row }) => (
      <div
        style={{
          cursor: "pointer",
          justifyContent: "space-between",
          display: "flex",
          flex: 1,
          padding: "10px",
        }}
      >
        {row.originType === OriginType.PAYMENT.code && (
          <>
            <EditIcon onClick={() => onEdit(row)} />
            <DeleteIcon onClick={() => onDelete(row)} />
          </>
        )}
      </div>
    ),
  });

  const goReport = useCallback(async () => {
    try {
      setLoading(true);
      const { startDate, endDate, user } = searchState;
      const params = [];

      if (startDate != null) {
        params.push(`startDate=${moment(startDate).format("YYYY-MM-DD")}`);
      }
      if (endDate != null) {
        params.push(`endDate=${moment(endDate).format("YYYY-MM-DD")}`);
      }
      if (user != null) {
        params.push(`userId=${user.id}`);
      }

      const response = await ReportService.printCommissionPeriod(params.join("&"));

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `Relatório de comissão.pdf`);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      if (
        error.request.responseType === "blob" &&
        error.response.data instanceof Blob &&
        error.response.data.type &&
        error.response.data.type.toLowerCase().indexOf("json") !== -1
      ) {
        new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = () => {
            const { status, message: msg } = JSON.parse(reader.result);

            const message = status === 428 ? msg : generalMessages.error;
            enqueueSnackbar(message, {
              variant: "error",
              autoHideDuration: 3000,
            });
          };

          reader.onerror = () => {
            reject(error);
          };

          reader.readAsText(error.response.data);
        });
      }
    } finally {
      setLoading(false);
    }
  }, [searchState, enqueueSnackbar]);

  const renderModal = () => (
    <Dialog disableEscapeKeyDown open={open} fullWidth maxWidth="sm">
      <DialogTitle>Pagamento para o profissional: {commission?.user?.name}</DialogTitle>
      <DialogContent>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <TextInput
              autoFocus
              placeholder="Dinheiro, Pix, Juros..."
              id="name"
              label="Descrição"
              value={commission.name}
              onChange={handleChange}
              required
              errors={errors}
            />
          </GridItem>
          <GridItem xs={8} sm={8} md={6}>
            <CurrencyTextInput
              id="totalCommission"
              label="Valor"
              value={commission.totalCommission || "0"}
              onChange={handleChange}
              required
              errors={errors}
            />
          </GridItem>
          <GridItem xs={8} sm={8} md={6} style={{ marginTop: "12px" }}>
            <DatePicker
              id="start"
              label="Data"
              format="dd/MM/yyyy"
              slotProps={{ textField: { size: "small" } }}
              value={
                commission?.commissionDate ? moment(commission?.commissionDate).valueOf() : null
              }
              helperText={errors.commissionDate}
              onChange={(date) => handleChange("commissionDate", date)}
            />
          </GridItem>
        </GridContainer>
      </DialogContent>
      <DialogActions>
        <div style={{ flexGrow: 1, display: "flex", justifyContent: "space-between" }}>
          <Button style={{ color: primaryColor[0] }} onClick={onClose}>
            Cancelar
          </Button>
          <Button style={{ color: primaryColor[0] }} onClick={onSave}>
            Confirmar pagamento
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );

  const renderModalClosure = () => (
    <Dialog open={openClosure} fullWidth maxWidth="md">
      <DialogTitle>
        Fechar comissões manualmente para o profissional: {commission?.user?.name}
      </DialogTitle>
      <DialogContent>
        <GridContainer>
          <GridItem xs={6} sm={6} md={6} style={{ marginTop: "12px" }}>
            <Typography>
              Último fechamento: {lastClosure ? moment(lastClosure).format("DD/MM/YYYY") : "--"}
            </Typography>
          </GridItem>
          <GridItem xs={6} sm={6} md={6} />
          <GridItem xs={8} sm={8} md={4} style={{ marginTop: "12px" }}>
            <DatePicker
              id="start"
              label="Vendas do dia"
              format="dd/MM/yyyy"
              slotProps={{ textField: { size: "small" } }}
              value={
                modalClosureData?.startDate ? moment(modalClosureData?.startDate).valueOf() : null
              }
              helperText={errors.startDate}
              onChange={(date) => handleChangeModalClosureData("startDate", date)}
            />
          </GridItem>
          <GridItem xs={8} sm={8} md={4} style={{ marginTop: "12px" }}>
            <DatePicker
              id="start"
              label="Vendas até dia"
              format="dd/MM/yyyy"
              slotProps={{ textField: { size: "small" } }}
              value={modalClosureData?.endDate ? moment(modalClosureData?.endDate).valueOf() : null}
              helperText={errors.endDate}
              onChange={(date) => handleChangeModalClosureData("endDate", date)}
            />
          </GridItem>
          <ButtonCustom color="primary" onClick={calcularMeta}>
            Calcular vendas
          </ButtonCustom>

          <GridItem xs={12} sm={12} md={12} />
          <Divider style={{ width: "100%", marginBottom: 20, marginTop: 20 }} />

          {vendasEMeta && (
            <>
              <GridItem xs={4} sm={4} md={4} style={{ marginTop: "12px" }}>
                <Typography>
                  <strong>Total de vendas:</strong> {currencyFormatter(vendasEMeta?.ordersTotal)}
                </Typography>
              </GridItem>
              <GridItem xs={4} sm={4} md={4} style={{ marginTop: "12px" }}>
                <Typography>
                  <strong>Total de serviços:</strong>{" "}
                  {currencyFormatter(vendasEMeta?.serviceOrdersTotal)}
                </Typography>
              </GridItem>
              <GridItem xs={4} sm={4} md={4} style={{ marginTop: "12px" }}>
                <Typography>
                  <strong>Total:</strong> {currencyFormatter(vendasEMeta?.totalPeriodo)}
                </Typography>
              </GridItem>
              <GridItem xs={4} sm={4} md={4} style={{ marginTop: "12px" }}>
                <Typography>
                  <strong>Atingiu meta:</strong> {vendasEMeta?.atingiuMeta ? "Sim" : "Não"}
                </Typography>
              </GridItem>
              {vendasEMeta?.atingiuMeta && (
                <>
                  <GridItem xs={4} sm={4} md={4} style={{ marginTop: "12px" }}>
                    <Typography>
                      <strong>Comissões de produtos</strong> {vendasEMeta?.productCommission}%
                    </Typography>
                  </GridItem>
                  <GridItem xs={4} sm={4} md={4} style={{ marginTop: "12px" }}>
                    <Typography>
                      <strong>Comissões de serviços:</strong> {vendasEMeta?.serviceCommission}%
                    </Typography>
                  </GridItem>
                </>
              )}
            </>
          )}
        </GridContainer>
      </DialogContent>
      <DialogActions>
        <div style={{ flexGrow: 1, display: "flex", justifyContent: "space-between" }}>
          <Button style={{ color: primaryColor[0] }} onClick={onCloseClosure}>
            Cancelar
          </Button>
          <Button style={{ color: primaryColor[0] }} onClick={onSaveClosure}>
            Realizar Fechamento
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );

  const renderTotalCard = () => (
    <Box display="flex" justifyContent="flex-end">
      <Box display="flex" justifyContent="flex-end">
        <Box
          display="flex"
          style={{
            borderRadius: 4,
            marginLeft: 10,
            padding: 15,
            marginTop: -20,
            color: "#ffff",
            background: "linear-gradient(60deg, #61b9e4, #0E86D4)",
          }}
        >
          <Box display="flex" flexDirection="column">
            <Typography style={{ alignSelf: "center", fontSize: 16 }}>
              Total de comissões pendentes
            </Typography>
            <Typography style={{ alignSelf: "center", fontSize: 22 }}>
              {currencyFormatter(values.pending || 0)}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );

  const onKeyPressSearch = useCallback(
    (ev) => {
      if (ev.which === 13) {
        customGridRef.current.resetPaginationModel();
        fetchSearch({ queryParams: searchState });
      }
    },
    [fetchSearch, searchState]
  );

  const onSearch = useCallback(() => {
    customGridRef.current.resetPaginationModel();
    fetchSearch({ queryParams: searchState });
  }, [fetchSearch, searchState]);

  const onClear = useCallback(() => {
    dispatchSearch({ type: "reset" });
    customGridRef.current.resetPaginationModel();
    fetchSearch({ queryParams: getInitialSearchState() });
  }, [fetchSearch, getInitialSearchState]);

  const renderSearch = () => (
    <GridContainer style={{ paddingBottom: 8 }}>
      <GridItem xs={12} sm={12} md={3}>
        <TextField
          size="small"
          onKeyPress={onKeyPressSearch}
          variant="outlined"
          label={commissionMessages.orderCodeSearch}
          fullWidth
          InputLabelProps={{
            className: classesCustomComponentsStyle.labelTextField,
          }}
          className={classesCustomComponentsStyle.textField}
          value={searchState.orderCode == null ? "" : searchState.orderCode}
          onChange={(value) =>
            dispatchSearch({ type: "setOrderCode", value: value.currentTarget.value })
          }
        />
      </GridItem>
      <GridItem xs={12} sm={12} md={6}>
        <Autocomplete
          variant="outlined"
          fieldsToShowAfterSelectItem={["name"]}
          label={commissionMessages.user}
          placeholder={commissionMessages.autocompleteUser}
          service={UserService}
          value={searchState.user}
          onChange={(value) => dispatchSearch({ type: "setUser", value })}
          // renderOption={(itemOpt) => `${itemOpt.name}`}
          required
          error={errors.userId}
        />
      </GridItem>
      <GridItem xs={12} sm={12} md={3} />
      <GridItem xs={12} sm={12} md={3} style={{ marginTop: "12px" }}>
        <DatePicker
          id="start"
          label="Data inicial"
          format="dd/MM/yyyy"
          slotProps={{ textField: { size: "small" } }}
          value={searchState?.startDate ? moment(searchState?.startDate).valueOf() : null}
          helperText={errors.startDate}
          onChange={(value) => dispatchSearch({ type: "setStartDate", value })}
        />
      </GridItem>
      <GridItem xs={12} sm={12} md={3} style={{ marginTop: "12px" }}>
        <DatePicker
          id="start"
          label="Data final"
          format="dd/MM/yyyy"
          slotProps={{ textField: { size: "small" } }}
          value={searchState?.endDate ? moment(searchState?.endDate).valueOf() : null}
          helperText={errors.endDate}
          onChange={(value) => dispatchSearch({ type: "setEndDate", value })}
        />
      </GridItem>
      <GridItem
        xs={12}
        sm={12}
        md={2}
        style={{
          marginTop: "5px",
          alignItems: "center",
          display: "flex",
        }}
      >
        <ButtonCustom color="primary" onClick={onSearch}>
          {generalMessages.search}
        </ButtonCustom>
        <ButtonCustom color="primary" onClick={onClear}>
          Limpar
        </ButtonCustom>
        <ButtonCustom color="primary" onClick={goReport}>
          Imprimir
        </ButtonCustom>
      </GridItem>
    </GridContainer>
  );

  const renderPeriodTotals = () => (
    <Box display="flex" flexDirection="row" flexGrow={1}>
      <Box
        display="inline-block"
        flexDirection="column"
        style={{ paddingLeft: "10px", paddingRight: "10px" }}
      >
        <Typography style={{ alignSelf: "center", fontSize: 16 }}>
          Total de Vendas/Ordem de serviços no período
        </Typography>
        <Typography style={{ alignSelf: "center", fontSize: 22, fontWeight: 500 }}>
          {currencyFormatter(valuesPeriod.totalOrders || 0)}
        </Typography>
      </Box>
      <Box
        display="inline-block"
        flexDirection="column"
        style={{ paddingLeft: "10px", paddingRight: "10px" }}
      >
        <Typography style={{ alignSelf: "center", fontSize: 16 }}>
          Total de comissões no período
        </Typography>
        <Typography style={{ alignSelf: "center", fontSize: 22, fontWeight: 500 }}>
          {currencyFormatter(valuesPeriod.total || 0)}
        </Typography>
      </Box>
      <Box
        display="inline-block"
        flexDirection="column"
        style={{ paddingLeft: "10px", paddingRight: "10px" }}
      >
        <Typography style={{ alignSelf: "center", fontSize: 16 }}>Pago no período</Typography>
        <Typography style={{ alignSelf: "center", fontSize: 22, fontWeight: 500 }}>
          {currencyFormatter(valuesPeriod.paid || 0)}
        </Typography>
      </Box>
      {closureConfig && closureConfig.goal && (
        <Box
          display="inline-block"
          flexDirection="column"
          style={{ paddingLeft: "10px", paddingRight: "10px" }}
        >
          <Typography style={{ alignSelf: "center", fontSize: 16 }}>
            Data do último fechamento
          </Typography>
          <Typography style={{ alignSelf: "center", fontSize: 22, fontWeight: 500 }}>
            {lastClosure ? moment(lastClosure).format("DD/MM/YYYY") : "--"}
          </Typography>
        </Box>
      )}
    </Box>
  );

  const getRowClassName = (params) =>
    params.row.originType === OriginType.PAYMENT.code ? classes.muiDataGridRowPaymentRow : "";

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card className="gridLayoutCard">
          <div className="gridLayoutContainer">
            <CardHeader color="primary">
              <Box display="flex" flexDirection="row" justifyContent="space-between">
                <Box display="flex" flexDirection="column">
                  <h4 className={classesuseCardStyle.cardTitleList}>
                    {commissionMessages.titleCard}
                  </h4>
                  <p className={classesuseCardStyle.cardCategoryList}>
                    {commissionMessages.commissionsTable}
                  </p>
                </Box>
                {renderTotalCard()}
              </Box>
            </CardHeader>

            <div className="gridLayoutFilters">
              <AccordionFilters screen="commission_list">{renderSearch()}</AccordionFilters>
            </div>

            <div className="gridLayoutGrid">
              <DataGridCustom
                ref={customGridRef}
                onRowDoubleClick={onEdit}
                rows={list}
                columns={columns}
                usePagination
                rowCount={rowCount}
                fetchAction={(params) =>
                  fetchSearch({
                    ...params,
                    queryParams: searchState,
                  })
                }
                getRowClassName={getRowClassName}
              />
            </div>

            {open && renderModal()}
            {openClosure && renderModalClosure()}

            <div className="gridLayoutBottom">
              <Box display="flex" flexDirection="row" justifyContent="space-between">
                {renderPeriodTotals()}
                {closureConfig && closureConfig.goal && !closureConfig.goalAutomaticClosing && (
                  <ButtonCustom
                    style={{
                      backgroundColor: "#af2cc5",
                      color: "#FFFFFF",
                      marginTop: 8,
                      marginRight: 8,
                    }}
                    onClick={handleOpenClosure}
                  >
                    Fechar Comissões Manualmente
                  </ButtonCustom>
                )}
                <ButtonCustom
                  style={{
                    backgroundColor: "#af2cc5",
                    color: "#FFFFFF",
                    marginTop: 8,
                    marginRight: 0,
                  }}
                  onClick={handleOpen}
                >
                  Realizar Pagamento
                </ButtonCustom>
              </Box>
            </div>
          </div>
        </Card>
      </GridItem>
      <Loading loading={loading} />
      <ConfirmDialog />
    </GridContainer>
  );
}
