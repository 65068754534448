/* eslint-disable react/prop-types */
import React from "react";
import { makeStyles } from "@mui/styles";
// import useMediaQuery from "@mui/material/useMediaQuery";
// import { useTheme } from "@mui/material/styles";
import CustomComponentsStyle from "assets/jss/material-dashboard-react/components/customComponentsStyle";
import CurrencyTextField from "@lupus-ai/mui-currency-textfield";

const useCustomComponentsStyle = makeStyles(CustomComponentsStyle);

const useStyles = makeStyles({
  root: {
    // input label when focused
    "& label.Mui-focused": {
      color: "#3f51b5",
    },
    // focused color for input with variant='standard'
    "& .MuiInput-underline:after": {
      borderBottomColor: "#3f51b5",
    },
    // focused color for input with variant='filled'
    "& .MuiFilledInput-underline:after": {
      borderBottomColor: "#3f51b5",
    },
    // focused color for input with variant='outlined'
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#3f51b5",
      },
    },
  },
});

const CurrencyTextInput = ({
  id,
  label,
  autoFocus,
  value,
  required,
  onChange,
  onFocus,
  errors = {},
  currencySymbol = "R$",
  disabled = false,
  style,
  decimalPlaces = 2,
}) => {
  const classesCustomComponentsStyle = useCustomComponentsStyle();
  const classes = useStyles();
  // const theme = useTheme();
  // const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));

  // const fontSize = isMediumScreen ? 14 : null;
  // const internalPadding = isMediumScreen ? "6px 7px" : null;

  return (
    <CurrencyTextField
      size="small"
      autoFocus={autoFocus}
      label={label}
      variant="outlined"
      decimalPlaces={decimalPlaces}
      value={value}
      currencySymbol={currencySymbol}
      outputFormat="string"
      decimalCharacter=","
      digitGroupSeparator="."
      className={[classes.root, classesCustomComponentsStyle.textField]}
      onChange={(event, val) => onChange(id, val)}
      onFocus={onFocus}
      required={required}
      error={errors[id]}
      helperText={errors[id]}
      disabled={disabled}
      style={style}
      // InputProps={{
      //   sx: { fontSize },
      // }}
      // eslint-disable-next-line react/jsx-no-duplicate-props
      // inputProps={{
      //   style: {
      //     padding: internalPadding,
      //   },
      // }}
    />
  );
};

export default React.memo(CurrencyTextInput);
