class UnitConversion {
  constructor(source = {}) {
    if (source == null) return this;

    this.id = source.id;
    this.conversionFactor = source.conversionFactor;
    this.sourceUnit = source.sourceUnit;
    this.targetUnit = source.targetUnit;
    this.version = source.version;
    this.active = source.active;
  }
}

export default UnitConversion;
