import Product from "../model/Product";

const toRequest = (product, suppliers) => {
  const service = false;
  const {
    id,
    name,
    saleValue,
    additionalInformation,
    version,
    brand,
    active,
    cost,
    margin,
    inventoryQuantity,
    lastCost = 0,
    industryCode,
    barCode,
    profit,
    localization,
    commissionable,
    priceMinimum,
    controlInventory = false,
    saleLowInventory = false,
    width,
    height,
    length,
    diameter,
    grossWeight,
    netWeight,
    classificationCodeIpi,
    taxableUnit,
    eanTaxable,
    conversionFactor,
    valueIpi,
    originIcms,
    ncm,
    cest,
    unitMeasurement,
    ipiCnpjProducer,
    ipiControlSealCode,
    ipiQuantityControlStamp,
    codigoBeneficiario,
    enableForNf,
  } = product;

  let nfeInfos = {};
  if (enableForNf) {
    nfeInfos = {
      ...nfeInfos,
      originIcms,
      ncm,
      cest,
      eanTaxable,
      taxableUnit,
      conversionFactor,
      classificationCodeIpi,
      valueIpi,
      ipiCnpjProducer,
      ipiControlSealCode,
      ipiQuantityControlStamp,
      codigoBeneficiario,
      grossWeight,
      netWeight,
    };
  }

  return {
    id,
    name,
    suppliers,
    industryCode,
    barCode,
    cost,
    margin,
    saleValue,
    profit,
    inventory: {
      quantity: inventoryQuantity,
    },
    lastCost,
    additionalInformation,
    localization,
    commissionable,
    priceMinimum,
    version,
    brand,
    active,
    controlInventory,
    saleLowInventory,
    width,
    height,
    length,
    diameter,
    unitMeasurement,
    service,
    enableForNf,
    ...nfeInfos,
  };
};

const fromResponse = (response) => {
  if (response === null) return new Product();

  const {
    id,
    name,
    suppliers,
    industryCode,
    barCode,
    cost,
    margin,
    saleValue,
    profit,
    inventory,
    lastCost,
    additionalInformation,
    localization,
    commissionable,
    priceMinimum,
    version,
    brand,
    active,
    controlInventory,
    saleLowInventory,
    service,
    width,
    height,
    length,
    diameter,
    grossWeight,
    netWeight,
    classificationCodeIpi,
    taxableUnit,
    eanTaxable,
    conversionFactor,
    valueIpi,
    originIcms,
    ncm,
    cest,
    unitMeasurement,
    ipiCnpjProducer,
    ipiControlSealCode,
    ipiQuantityControlStamp,
    codigoBeneficiario,
    enableForNf,
  } = response;

  const suppliersList = suppliers.map((item, index) => ({
    ...item,
    index,
  }));

  return new Product({
    id,
    name,
    suppliers: suppliersList,
    industryCode,
    barCode,
    cost,
    margin,
    saleValue,
    profit,
    inventoryQuantity: inventory?.quantity,
    lastCost,
    additionalInformation,
    localization,
    commissionable,
    priceMinimum,
    version,
    brand,
    active,
    controlInventory,
    saleLowInventory,
    service,
    width,
    height,
    length,
    diameter,
    grossWeight,
    netWeight,
    classificationCodeIpi,
    taxableUnit,
    eanTaxable,
    conversionFactor,
    valueIpi,
    originIcms,
    ncm,
    cest,
    unitMeasurement,
    ipiCnpjProducer,
    ipiControlSealCode,
    ipiQuantityControlStamp,
    codigoBeneficiario,
    enableForNf,
  });
};

export { toRequest, fromResponse };
