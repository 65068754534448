/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useEffect, useState } from "react";
import classNames from "classnames";
import { useHistory } from "react-router-dom";
import { useHotkeys } from "react-hotkeys-hook";
import { primaryColor } from "assets/jss/material-dashboard-react";
import styles from "assets/jss/material-dashboard-react/components/headerLinksStyle";
import { makeStyles } from "@mui/styles";
import Grow from "@mui/material/Grow";
import { Box, Button, ClickAwayListener, MenuItem, MenuList, Modal, Paper } from "@mui/material";
import Poppers from "@mui/material/Popper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBell,
  faKeyboard,
  faCalendarAlt,
  faArrowTrendDown,
  faDollarSign,
  faQuestionCircle,
} from "@fortawesome/free-solid-svg-icons";
import ZoomSupportModal from "views/support/ZoomSupportModal";
import SupportService from "views/support/service/SupportService";
import { getBrowserZoomLevel } from "config/util";
import NotificationService from "./service/NotificationService";
import NotificationModal from "./NotificationModal";
import PriceSearch from "../../views/price-search/PriceSearch";

const initial = {
  totalCalendar: 0,
  totalExpenses: 0,
  globals: [],
  total: 0,
  totalGlobals: 0,
};

const isBrowserZoomLevelOk = () => {
  const zoom = getBrowserZoomLevel();
  return zoom === 100;
};

const useStyles = makeStyles(styles);
const Notification = () => {
  const [openSystemNotification, setOpenSystemNotification] = useState(false);
  const [openZoomSupportModal, setOpenZoomSupportModal] = useState(false);
  const history = useHistory();
  const [notifications, setNotifications] = useState(initial);
  const classes = useStyles();
  const [openPriceSearch, setOpenPriceSearch] = React.useState(false);
  const [openHotKeys, setOpenHotKeys] = React.useState(null);
  const [openNotification, setOpenNotification] = React.useState(null);

  useHotkeys("shift+p", () => {
    setTimeout(() => setOpenPriceSearch(true), 500);
  });

  // useEffect(() => {
  //   if (SupportService.isZoomNotificationShow() && !isBrowserZoomLevelOk())
  //     setOpenZoomSupportModal(true);
  // }, [setOpenZoomSupportModal]);

  const handleClickSupport = () => history.push(`/app/support`);

  const handleClickNotification = (event) => {
    if (openNotification && openNotification.contains(event.target)) {
      setOpenNotification(null);
    } else {
      setOpenNotification(event.currentTarget);
    }
  };

  const handleClickHotkeys = (event) => {
    if (openHotKeys && openHotKeys.contains(event.target)) {
      setOpenHotKeys(null);
    } else {
      setOpenHotKeys(event.currentTarget);
    }
  };

  const setNotificationsRead = useCallback((ids) => {
    NotificationService.setNotificationsRead(ids);
  }, []);

  const handleCloseNotification = () => {
    setOpenNotification(null);
  };

  const handleCloseHotkeys = () => {
    setOpenHotKeys(null);
  };

  const fetchNotifications = useCallback(async () => {
    try {
      const result = await NotificationService.fetchGroup();
      const { data } = result;
      const { totalCalendar, totalExpenses, globals } = data;

      const idsRead = NotificationService.getNotificationsRead();
      const ids = globals.map((item) => item.id);

      const idsNotRead = ids.filter((x) => !idsRead.includes(x));

      const totalGlobals = idsNotRead.length;

      const total = totalCalendar + totalExpenses + totalGlobals;
      setNotifications({
        ...data,
        globals,
        total,
        totalGlobals,
      });
    } catch (error) {
      // do nothing
    }
  }, []);

  useEffect(() => {
    fetchNotifications();
    // console.log(">>>>>>>>>>>>>>>>>>>>>>>>> Notifications");
  }, [fetchNotifications]);

  const renderMenuItem = (icon, onClick, value, text, extra) => (
    <MenuItem
      onClick={onClick}
      className={classes.dropdownItem}
      style={{ paddingLeft: 8, paddingRight: 8 }}
    >
      <Box display="flex">
        <Box style={{ paddingRight: 5 }}>
          <FontAwesomeIcon icon={icon} color={primaryColor[0]} />
        </Box>
        <Box display="flex" justifyContent="space-between" width="100%">
          <Box style={{ paddingRight: 5 }}>{text}</Box>
          {extra}
          <Box>
            {value && value > 0 ? <span className={classes.notifications}>{value}</span> : <span />}
          </Box>
        </Box>
      </Box>
    </MenuItem>
  );

  const renderNotifications = () => {
    const { totalCalendar, totalExpenses, totalGlobals } = notifications;

    const nCalendar = renderMenuItem(
      faCalendarAlt,
      () => {
        handleCloseNotification();
        history.push("/app/calendar");
      },
      totalCalendar,
      "Agenda"
    );

    const nExpense = renderMenuItem(
      faArrowTrendDown,
      () => {
        handleCloseNotification();
        history.push("/app/expenses");
      },
      totalExpenses,
      "Despesas"
    );

    const nSystem = renderMenuItem(
      faBell,
      () => {
        handleCloseNotification();
        setOpenSystemNotification(true);
      },
      totalGlobals,
      "Sistema"
    );

    // const nGlobals = globals.map((item) =>
    //   renderMenuItem(faBell, handleCloseNotification, null, item.text)
    // );

    return [nCalendar, nExpense, nSystem];
  };

  const renderHotkeys = () => {
    const priceSearch = renderMenuItem(
      faDollarSign,
      () => {
        handleCloseHotkeys();
        setOpenPriceSearch(true);
      },
      null,
      "Pesquisa de preços",
      <code
        style={{ color: primaryColor[0], backgroundColor: "#f1f1f1", borderRadius: 4, padding: 0 }}
      >
        shift+p
      </code>
    );
    return [priceSearch];
  };

  const onCloseZoomSupportModal = useCallback(() => {
    SupportService.saveZoomNotification();
    setOpenZoomSupportModal(false);
  }, [setOpenZoomSupportModal]);

  const onCloseNotificationModal = useCallback(() => {
    const { total, totalGlobals, globals } = notifications;

    const ids = globals.map((item) => item.id);

    setNotificationsRead(ids);
    setNotifications({
      ...notifications,
      total: total - totalGlobals,
      totalGlobals: 0,
    });
    setOpenSystemNotification(false);
  }, [notifications, setNotificationsRead]);

  const renderNotificationMenu = () => (
    <div className={classes.manager}>
      <Button
        // color={window.innerWidth > 959 ? "transparent" : "white"}
        justIcon={window.innerWidth > 959}
        simple={!(window.innerWidth > 959)}
        aria-owns={openNotification ? "notification-menu-list-grow" : null}
        aria-haspopup="true"
        onClick={handleClickNotification}
        style={{ marginRight: 15, minWidth: 30 }}
      >
        <FontAwesomeIcon icon={faBell} color="#686868" fontSize={16} />
        {notifications.total > 0 && (
          <div
            className={classes.notifications}
            style={{ top: -9, position: "relative", right: 8 }}
          >
            {notifications.total}
          </div>
        )}
      </Button>

      <Poppers
        open={Boolean(openNotification)}
        anchorEl={openNotification}
        transition
        disablePortal
        className={`${classNames({ [classes.popperClose]: !openNotification })} ${
          classes.popperNav
        }`}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            id="notification-menu-list-grow"
            style={{
              transformOrigin: placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleCloseNotification}>
                <MenuList role="menu">{renderNotifications()}</MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Poppers>

      <NotificationModal
        open={openSystemNotification}
        notifications={notifications.globals}
        onClose={onCloseNotificationModal}
      />
      <ZoomSupportModal open={openZoomSupportModal} onClose={onCloseZoomSupportModal} />
    </div>
  );
  const renderHotkeysMenu = () => (
    <div className={classes.manager}>
      <Button
        justIcon={window.innerWidth > 959}
        simple={!(window.innerWidth > 959)}
        aria-owns={openHotKeys ? "hotkeys-menu-list-grow" : null}
        aria-haspopup="true"
        onClick={handleClickSupport}
        style={{ minWidth: 30 }}
        className={classes.buttonLink}
      >
        <FontAwesomeIcon icon={faQuestionCircle} color="#ffa726" fontSize={18} />
      </Button>
      <Button
        // color={window.innerWidth > 959 ? "transparent" : "white"}
        justIcon={window.innerWidth > 959}
        simple={!(window.innerWidth > 959)}
        aria-owns={openHotKeys ? "hotkeys-menu-list-grow" : null}
        aria-haspopup="true"
        onClick={handleClickHotkeys}
        style={{ minWidth: 30 }}
        className={classes.buttonLink}
      >
        <FontAwesomeIcon icon={faKeyboard} color="#686868" fontSize={16} />
      </Button>
      <Poppers
        open={Boolean(openHotKeys)}
        anchorEl={openHotKeys}
        transition
        disablePortal
        className={`${classNames({ [classes.popperClose]: !openHotKeys })} ${classes.popperNav}`}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            id="hotkeys-menu-list-grow"
            style={{
              transformOrigin: placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleCloseHotkeys}>
                <MenuList role="menu">{renderHotkeys()}</MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Poppers>
    </div>
  );

  const renderPriceSearch = () => (
    <Modal
      style={{ overflow: "auto" }}
      // disableEscapeKeyDown
      onClose={() => setOpenPriceSearch(false)}
      open={openPriceSearch}
    >
      <PriceSearch modalOpen={openPriceSearch} onClose={() => setOpenPriceSearch(false)} />
    </Modal>
  );

  return [renderHotkeysMenu(), renderNotificationMenu(), renderPriceSearch()];
};

export default React.memo(Notification);
