import Keycloak from "keycloak-js";

const KEYCLOAK_URL = process.env.REACT_APP_KEYCLOAK_URL;

// console.log("REACT_APP_KEYCLOAK_URL", KEYCLOAK_URL);
// eslint-disable-next-line import/no-mutable-exports


const keycloak = new Keycloak({
  url: KEYCLOAK_URL,
  realm: "sales",
  clientId: "sales-core",
});


export default keycloak;
