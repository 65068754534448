import axios from "axios";
import { APIS } from "../../../config/constants";
import BaseService from "../../../core/BaseService";

const { register: registerAPI } = APIS;

class StripeService extends BaseService {
  constructor() {
    super(registerAPI.stripe);
  }

  createPortalSession = () => axios.post(`${this.endpoint}/create-portal-session`);

  createCheckoutSession = (planId, paymentRecurrenceType) =>
    axios.post(`${this.endpoint}/create-checkout-session`, { planId, paymentRecurrenceType });
}

export default new StripeService();
