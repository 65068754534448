/* eslint-disable react/prop-types */
import React, { useCallback } from "react";
import CurrencyTextInput from "components/textInput/CurrencyTextInput";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

const PartilhaEstadoItem = ({ data, handleChange, errors = {} }) => {
  const handleChangeStates = useCallback(
    (field, value) => {
      const newData = {
        ...data,
        [field]: value,
      };
      handleChange(newData);
    },
    [data, handleChange]
  );

  const renderAliquotaIcmsInterestadual = (aliquotaIcmsInterestadual) => (
    <CurrencyTextInput
      currencySymbol="%"
      id="aliquotaIcmsInterestadual"
      value={aliquotaIcmsInterestadual || "0"}
      onChange={handleChangeStates}
      errors={errors}
    />
  );

  const renderAliquotaIcmsInternaEstadoDestino = (aliquotaIcmsInternaEstadoDestino) => (
    <CurrencyTextInput
      currencySymbol="%"
      id="aliquotaIcmsInternaEstadoDestino"
      value={aliquotaIcmsInternaEstadoDestino || "0"}
      onChange={handleChangeStates}
      errors={errors}
    />
  );

  const renderAliquotaFcp = (aliquotaFcp) => (
    <CurrencyTextInput
      currencySymbol="%"
      id="aliquotaFcp"
      value={aliquotaFcp || "0"}
      onChange={handleChangeStates}
      errors={errors}
    />
  );

  const renderBaseCalculo = (baseCalculo) => (
    <CurrencyTextInput
      decimalPlaces={4}
      currencySymbol="%"
      id="baseCalculo"
      value={baseCalculo || "0"}
      onChange={handleChangeStates}
      errors={errors}
    />
  );

  return (
    <TableRow>
      <TableCell>{data.estado}</TableCell>
      <TableCell>{renderAliquotaIcmsInterestadual(data.aliquotaIcmsInterestadual)}</TableCell>
      <TableCell>
        {renderAliquotaIcmsInternaEstadoDestino(data.aliquotaIcmsInternaEstadoDestino)}
      </TableCell>
      <TableCell>{renderAliquotaFcp(data.aliquotaFcp)}</TableCell>
      <TableCell>{renderBaseCalculo(data.baseCalculo)}</TableCell>
    </TableRow>
  );
};

export default React.memo(PartilhaEstadoItem);
