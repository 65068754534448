/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import CustomComponentsStyle from "assets/jss/material-dashboard-react/components/customComponentsStyle";
import {
  Box,
  Divider,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import GridItem from "components/Grid/GridItem";

import CurrencyTextInput from "components/textInput/CurrencyTextInput";
import MESSAGES from "config/messages";
import GridContainer from "components/Grid/GridContainer";

import Autocomplete from "components/autocomplete/Autocomplete";
import CfopService from "views/cfop/service/CfopService";
import { dangerColor } from "assets/jss/material-dashboard-react";
import AddressService from "components/address/service/AddressService";

const { naturezaOperacaoMessages } = MESSAGES;

const NotaFiscalRetencaoIcmsTransporte = ({
  data = {},
  handleChange,
  errors = {},
  states,
  disabled = false,
}) => {
  const useCustomComponentsStyle = makeStyles(CustomComponentsStyle);
  const classesCustomComponentsStyle = useCustomComponentsStyle();

  const [cities, setCities] = useState([]);

  const fetchCitiesByState = useCallback(async (stateId) => {
    const result = await AddressService.fetchCitiesByState(stateId);
    const { data: dataResponse } = result;
    return dataResponse;
  }, []);

  useEffect(() => {
    const asyncFetch = async () => {
      if (data.state) {
        const citiesResult = await fetchCitiesByState(data.state);
        setCities(citiesResult);
      }
    };
    asyncFetch();
  }, [data.state, fetchCitiesByState]);

  const onChange = useCallback(
    (field, value) => {
      handleChange({
        ...data,
        [field]: value,
      });
    },
    [data, handleChange]
  );

  const renderState = () => (
    <GridItem xs={12} sm={12} md={4}>
      <FormControl
        disabled={disabled}
        fullWidth
        className={classesCustomComponentsStyle.formControlSelect}
        size="small"
        variant="outlined"
        required
      >
        <InputLabel>Estado</InputLabel>
        <Select
          label="Estado"
          id="state"
          autoWidth
          value={data.state == null ? "" : data.state}
          onChange={(value) => {
            onChange("state", value.target.value);
          }}
          error={errors.state}
        >
          {states.map((item) => (
            <MenuItem value={item.id}>{item.name}</MenuItem>
          ))}
        </Select>
        <FormHelperText style={{ color: dangerColor[0] }}>{errors.state}</FormHelperText>
      </FormControl>
    </GridItem>
  );

  const renderMunicipioIcms = () => (
    <GridItem xs={12} sm={12} md={4}>
      <FormControl
        disabled={disabled}
        fullWidth
        className={classesCustomComponentsStyle.formControlSelect}
        size="small"
        variant="outlined"
        required
      >
        <InputLabel>Cidade</InputLabel>
        <Select
          label="Cidade"
          id="municipioIcmsRetencaoIcmsTransporte"
          autoWidth
          value={
            data.municipioIcmsRetencaoIcmsTransporte == null
              ? ""
              : data.municipioIcmsRetencaoIcmsTransporte
          }
          onChange={(value) => {
            onChange("municipioIcmsRetencaoIcmsTransporte", value.target.value);
          }}
          disabled={cities.length === 0}
          error={errors.municipioIcmsRetencaoIcmsTransporte}
        >
          {cities.map((item) => (
            <MenuItem value={item.id}>{item.name}</MenuItem>
          ))}
        </Select>
        <FormHelperText style={{ color: dangerColor[0] }}>
          {errors.municipioIcmsRetencaoIcmsTransporte}
        </FormHelperText>
      </FormControl>
    </GridItem>
  );

  const renderCfop = () => (
    <GridItem xs={12} sm={12} md={4}>
      <Box display="flex" flexGrow={1}>
        <Autocomplete
          disabled={disabled}
          maxLength={5}
          freeSolo
          autoSelect
          width="100%"
          variant="outlined"
          fieldsToShowAfterSelectItem={["shortDescription"]}
          label={naturezaOperacaoMessages.simples.cfopSimples}
          placeholder={naturezaOperacaoMessages.simples.autocompleteCfopSimples}
          service={CfopService}
          value={data.cfopRetencaoIcmsTransporte}
          onChange={(value) => {
            if (typeof value === "string" || value == null) {
              onChange("cfopRetencaoIcmsTransporte", value);
            } else {
              onChange("cfopRetencaoIcmsTransporte", value.cfop);
            }
          }}
        />
      </Box>
    </GridItem>
  );

  const renderValorServico = () => (
    <GridItem xs={12} sm={12} md={3}>
      <CurrencyTextInput
        decimalPlaces={3}
        id="valorServicoRetencaoIcmsTransporte"
        label="Valor do serviço"
        value={data.valorServicoRetencaoIcmsTransporte || "0"}
        onChange={onChange}
        errors={errors}
        disabled={disabled}
      />
    </GridItem>
  );

  const renderBcRetencaoIcmsTransporte = () => (
    <GridItem xs={12} sm={12} md={3}>
      <CurrencyTextInput
        decimalPlaces={3}
        id="bcRetencaoIcmsTransporte"
        label="BC retenção ICMS"
        value={data.bcRetencaoIcmsTransporte || "0"}
        onChange={onChange}
        errors={errors}
        disabled={disabled}
      />
    </GridItem>
  );

  const renderAliquotaRetencaoIcmsTransporte = () => (
    <GridItem xs={12} sm={12} md={3}>
      <CurrencyTextInput
        decimalPlaces={3}
        id="aliquotaRetencaoIcmsTransporte"
        label="Alíq. da retenção"
        value={data.aliquotaRetencaoIcmsTransporte || "0"}
        onChange={onChange}
        errors={errors}
        disabled={disabled}
      />
    </GridItem>
  );

  const renderValorIcmsRetidoRetencaoIcmsTransporte = () => (
    <GridItem xs={12} sm={12} md={3}>
      <CurrencyTextInput
        decimalPlaces={3}
        id="valorIcmsRetidoRetencaoIcmsTransporte"
        label="Valor ICMS retido"
        value={data.valorIcmsRetidoRetencaoIcmsTransporte || "0"}
        onChange={onChange}
        errors={errors}
        disabled={disabled}
      />
    </GridItem>
  );

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12} style={{ marginTop: 30 }}>
        <Divider style={{ width: "100%", marginBottom: 4 }} />
        <Typography style={{ fontSize: 18, fontWeight: 500 }}>Retenção ICMS transporte</Typography>
      </GridItem>
      {renderValorServico()}
      {renderBcRetencaoIcmsTransporte()}
      {renderAliquotaRetencaoIcmsTransporte()}
      {renderValorIcmsRetidoRetencaoIcmsTransporte()}
      {renderCfop()}
      {renderState()}
      {renderMunicipioIcms()}
    </GridContainer>
  );
};

export default React.memo(NotaFiscalRetencaoIcmsTransporte);
