/* eslint-disable react/prop-types */
/* eslint-disable react/button-has-type */
import React, { useState, useCallback, useContext, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faTimes,
  faCoins,
  faExclamationCircle,
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";
import GridContainer from "components/Grid/GridContainer";

import {
  ToggleButton,
  ToggleButtonGroup,
  Box,
  Grid,
  Typography,
  Divider,
  Button,
  Modal,
} from "@mui/material";
import { primaryColor } from "assets/jss/material-dashboard-react";
import { useHistory } from "react-router-dom";
import { currencyFormatter } from "../../../config/util";
import PlansStyle from "./PlansStyle";
import StripeService from "../service/StripeService";
import { REDIRECT_HTML } from "../../../config/constants";
import CustomModal from "../../../components/CustomModal/CustomModal";
import LoginService from "../../login/service/LoginService";

// https://codepen.io/devzstudio/pen/OwJyZX
const useStyle = makeStyles(PlansStyle);

const RECURRENCE = {
  ANNUAL: {
    code: "ANNUAL",
    field: "valueAnnual",
  },
  QUARTERLY: {
    code: "QUARTERLY",
    field: "valueQuarterly",
  },
  MONTHLY: {
    code: "MONTHLY",
    field: "valueMonthly",
  },
};

const Plans = ({ plans = [], userMe = {} }) => {
  const classes = useStyle();
  const history = useHistory();
  const [recurrence, setRecurrence] = useState(RECURRENCE.MONTHLY);
  const [me, setMe] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const goToCompleteCompanyData = () => history.push("/app/company");
  const closeModal = () => setModalOpen(false);

  const getIcon = (active) => (
    <FontAwesomeIcon
      icon={active ? faCheck : faTimes}
      fontSize={active ? 18 : 22}
      color="#686868"
    />
  );

  const renderDialog = () => (
    <CustomModal
      open={modalOpen}
      onClose={closeModal}
      title=""
      actionButtonText="Completar dados da empresa"
      actionButtonFunction={goToCompleteCompanyData}
    >
      <FontAwesomeIcon
        icon={faExclamationTriangle}
        size="4x"
        style={{ color: "#f9ab32", marginBottom: "10px", width: "100%" }}
      />
      <Box width="100%" />
      <Typography style={{ fontWeight: "bold", textAlign: "center" }}>
        Parece que algumas informações importantes da sua empresa estão incompletas!
      </Typography>
      <Typography style={{ textAlign: "center" }}>
        Para aproveitar ao máximo nossos serviços e assinar um plano, por favor, complete os dados
        necessários.
      </Typography>
    </CustomModal>
  );

  const getPlanIcon = (color) => <FontAwesomeIcon icon={faCoins} fontSize={30} color={color} />;

  const fetchMe = useCallback(async () => {
    if (!userMe?.companyFullRegistration) {
      const meReq = await LoginService.me();
      setMe(meReq?.data);
    } else {
      setMe(userMe);
    }
  }, []);

  useEffect(() => {
    fetchMe();
  }, [fetchMe]);

  const handleChangeRecurrence = useCallback((ev) => {
    setRecurrence(RECURRENCE[ev.currentTarget.value]);
  }, []);

  const sign = useCallback(
    async (planId) => {
      if (me?.companyFullRegistration) {
        const page = window.open("", "_blank");
        page.document.write(REDIRECT_HTML);
        page.document.close();
        const result = await StripeService.createCheckoutSession(planId, recurrence.code);
        const { url } = result.data;
        page.location.href = url;
      } else {
        setModalOpen(true);
      }
    },
    [recurrence.code, me]
  );

  const renderCard = (id, text, price, iconColor, items, star = false) => (
    <Grid item xs={12} sm={12} md={4} style={{ marginTop: star ? 0 : 24 }}>
      <Box className={classes.card}>
        {star && (
          <Box
            style={{
              backgroundColor: "#00b3ff",
              color: "#ffff",
              fontSize: 13,
              position: "relative",
              zIndex: 10,
              borderTopLeftRadius: 8,
              borderTopRightRadius: 8,
              padding: 2,
              textAlign: "center",
            }}
          >
            <Typography style={{ fontWeight: "bold" }}>Mais Vendido</Typography>
          </Box>
        )}

        <Box
          display="flex"
          flexDirection="row"
          flexGrow={1}
          justifyContent="center"
          style={{ margin: 2 }}
        >
          <Box>
            <Box display="flex" justifyContent="center" paddingTop={2}>
              {getPlanIcon(iconColor)}
            </Box>
            <Box display="flex" justifyContent="center">
              <Typography inline style={{ fontWeight: 500, fontSize: 28, color: "#5a5959" }}>
                {text}
              </Typography>
            </Box>
            <Box display="flex" justifyContent="center">
              <Typography
                inline
                style={{
                  fontSize: 35,
                  color: "#5a5959",
                }}
              >
                {currencyFormatter(price)}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Divider style={{ width: "100%" }} />
        <Box>
          <ul style={{ listStyleType: "none", paddingLeft: 20 }}>
            {items.map((item) => {
              const active = item.active === "true";
              return (
                <li>
                  <Box style={{ padding: 3 }} display="flex" alignItems="center">
                    <span
                      style={{
                        display: "flex",
                        marginRight: 4,
                        verticalAlign: "center",
                        width: 20,
                      }}
                    >
                      {getIcon(active)}
                    </span>
                    <span style={{ textDecoration: active ? "none" : "line-through" }}>
                      {item.description}
                    </span>
                  </Box>
                </li>
              );
            })}
          </ul>
        </Box>
        <Divider style={{ width: "100%" }} />
        <Box display="flex" justifyContent="center">
          <Button
            variant="outlined"
            size="large"
            style={{ color: primaryColor[0], width: "100%", margin: 4 }}
            onClick={() => sign(id)}
          >
            Assinar
          </Button>
        </Box>
      </Box>
    </Grid>
  );

  return (
    <>
      <GridContainer style={{ justifyContent: "center" }}>
        <Box display="flex" flexDirection="column" flexGrow={1}>
          <Box>
            <ToggleButtonGroup
              value={recurrence.code}
              size="small"
              exclusive
              onChange={handleChangeRecurrence}
            >
              <ToggleButton
                value={RECURRENCE.ANNUAL.code}
                classes={{
                  selected: classes.selected,
                }}
              >
                Anual
              </ToggleButton>
              <ToggleButton
                value={RECURRENCE.QUARTERLY.code}
                classes={{
                  selected: classes.selected,
                }}
              >
                Trimestral
              </ToggleButton>
              <ToggleButton
                value={RECURRENCE.MONTHLY.code}
                classes={{
                  selected: classes.selected,
                }}
              >
                Mensal
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>
          <Box display="flex" style={{ paddingTop: 10 }}>
            {plans.map((plan) =>
              renderCard(
                plan.id,
                plan.name,
                plan[recurrence.field],
                plan.color,
                plan.text,
                plan.star
              )
            )}
          </Box>
        </Box>
      </GridContainer>
      {renderDialog()}
    </>
  );
};

export default React.memo(Plans);
