/* eslint-disable react/prop-types */
import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from "@mui/material";
import { getBrowserZoomLevel } from "config/util";
import { primaryColor } from "../../assets/jss/material-dashboard-react";

const getCodeText = (text) => (
  <code style={{ color: primaryColor[0], backgroundColor: "#f1f1f1", borderRadius: 4, padding: 0 }}>
    {text}
  </code>
);

const InitialSupportModal = ({ open, onClose }) => (
  <>
    <Dialog disableEscapeKeyDown open={open} fullWidth maxWidth="md">
      <DialogTitle> Kairum Sistemas</DialogTitle>
      <DialogContent>
        <Typography inline align="left" style={{ fontSize: 16 }}>
          Olá, identificamos que o seu navegador está com o zoom em{" "}
          {getCodeText(`${getBrowserZoomLevel()}%`)}, o que pode impactar o funcionamento ideal do
          sistema. Para ajustar o zoom, siga uma das opções abaixo:
          <ul>
            <li>
              Pressione as teclas {getCodeText("Ctrl")} e {getCodeText("0")} (zero) ao mesmo tempo.
            </li>
            <li>
              Pressione as teclas {getCodeText("Ctrl")} e {getCodeText("+")} ou{" "}
              {getCodeText("Ctrl")} e {getCodeText("-")}.
            </li>
            {/* <li>Pressione {getCodeText("Ctrl")} e gire a rodinha do mouse.</li> */}
            <li>Utilize a lupa do navegador.</li>
          </ul>
        </Typography>
      </DialogContent>
      <DialogActions>
        <div style={{ flexGrow: 1, display: "flex", justifyContent: "space-between" }}>
          <Button style={{ color: primaryColor[0] }} onClick={onClose}>
            Fechar
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  </>
);

export default React.memo(InitialSupportModal);
