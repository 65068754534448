import { blackColor, whiteColor, hexToRgb } from "assets/jss/material-dashboard-react.js";

const dataGridStyle = {
  muiDataGridRowInactive: {
    // backgroundColor: "#dcdcdc",
    color: "#8f8f8f",
    fontStyle: "italic",
  },
};

export default dataGridStyle;
