/* eslint-disable react/prop-types */
import React from "react";
import { makeStyles } from "@mui/styles";
import CustomComponentsStyle from "assets/jss/material-dashboard-react/components/customComponentsStyle";
import { Box, Divider, FormControl, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import GridItem from "components/Grid/GridItem";
import TextInput from "components/textInput/TextInput";
import CurrencyTextInput from "components/textInput/CurrencyTextInput";
import MESSAGES from "config/messages";
import GridContainer from "components/Grid/GridContainer";
import { SituacaoTributariaPis, TipoCalculoNFeType } from "../model/NaturezaOperacao";
import SelectTipoCalculo from "./SelectTipoCalculo";

const { naturezaOperacaoMessages } = MESSAGES;
const CHARACTER_LIMIT = 4000;

const NOPisTab = ({ data, handleChange, errors = {} }) => {
  const useCustomComponentsStyle = makeStyles(CustomComponentsStyle);
  const classesCustomComponentsStyle = useCustomComponentsStyle();

  const {
    situacaoTributariaPis,
    aliquotaPis,
    baseCalculoPis,
    observacaoPis,
    valorPis,
    tipoCalculoPis,
  } = data;

  const renderTipoCalculo = () => (
    <GridItem xs={8} sm={8} md={3}>
      <SelectTipoCalculo
        field="tipoCalculoPis"
        valueSelected={tipoCalculoPis}
        handleChange={handleChange}
      />
    </GridItem>
  );

  const renderBaseCalculoPis = () => (
    <GridItem xs={8} sm={8} md={3}>
      <CurrencyTextInput
        decimalPlaces={4}
        currencySymbol="%"
        id="baseCalculoPis"
        label={naturezaOperacaoMessages.pis.baseCalculoPis}
        value={baseCalculoPis || "0"}
        onChange={handleChange}
        errors={errors}
      />
    </GridItem>
  );

  const renderAliquotaPis = () => (
    <GridItem xs={8} sm={8} md={3}>
      <CurrencyTextInput
        currencySymbol="%"
        id="aliquotaPis"
        label={naturezaOperacaoMessages.pis.aliquotaPis}
        value={aliquotaPis || "0"}
        onChange={handleChange}
        errors={errors}
      />
    </GridItem>
  );

  const renderValorPis = () => (
    <GridItem xs={8} sm={8} md={3}>
      <CurrencyTextInput
        currencySymbol="R$"
        id="valorPis"
        label={naturezaOperacaoMessages.pis.valorPis}
        value={valorPis || "0"}
        onChange={handleChange}
        errors={errors}
      />
    </GridItem>
  );

  const renderObservacaoPis = () => (
    <GridItem xs={12} sm={12} md={12}>
      <TextInput
        id="observacaoPis"
        label={naturezaOperacaoMessages.pis.observacaoPis}
        value={observacaoPis}
        maxLength={CHARACTER_LIMIT}
        onChange={handleChange}
        isCounter
        multiline
        rows={4}
      />
    </GridItem>
  );

  const renderSituacaoTributariaPis = () => (
    <GridItem xs={12} sm={12} md={12}>
      <FormControl
        fullWidth
        className={classesCustomComponentsStyle.formControlSelect}
        size="small"
        variant="outlined"
      >
        <InputLabel>{naturezaOperacaoMessages.pis.situacaoTributariaPis}</InputLabel>
        <Select
          label={naturezaOperacaoMessages.pis.situacaoTributariaPis}
          id="situacaoTributariaPis"
          autoWidth
          value={situacaoTributariaPis == null ? "" : situacaoTributariaPis}
          onChange={(event) => {
            const {
              target: { value },
            } = event;
            return handleChange("situacaoTributariaPis", value);
          }}
          error={errors.situacaoTributariaPis}
          helperText={naturezaOperacaoMessages.pis.situacaoTributariaPis}
        >
          {Object.keys(SituacaoTributariaPis).map((key) => {
            const { code, name, value } = SituacaoTributariaPis[key];
            return (
              <MenuItem key={code} value={value}>
                {code} - {name}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </GridItem>
  );

  const renderPisST = () => (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12} style={{ marginTop: 12 }}>
        {/* <Divider style={{ width: "100%", marginBottom: 4 }} /> */}
        <Typography style={{ fontSize: 18, fontWeight: 500 }}>Substituição tributária</Typography>
      </GridItem>
    </GridContainer>
  );

  // const renderPis = () => (
  //   <GridContainer>
  //     <GridItem xs={12} sm={12} md={12} style={{ marginTop: 12 }}>
  //       <Divider style={{ width: "100%", marginBottom: 4 }} />
  //       <Typography style={{ fontSize: 18, fontWeight: 500 }}>PIS</Typography>
  //     </GridItem>
  //   </GridContainer>
  // );

  const renderAliquotaOrValorByTipoCalculo = () => {
    if (tipoCalculoPis === TipoCalculoNFeType.VALOR.code) {
      return renderValorPis();
    }
    return [renderAliquotaPis(), renderBaseCalculoPis()];
  };

  const renderBySituacaoTributariaPis = () => {
    const code = parseInt(SituacaoTributariaPis[situacaoTributariaPis].code, 10);

    if (code === 1 || code === 2) {
      return [/* renderPis() */ renderAliquotaPis(), renderBaseCalculoPis(), renderObservacaoPis()];
    }

    if (code === 3) {
      return [/* renderPis() */ renderValorPis(), renderObservacaoPis()];
    }

    if (code === 5) {
      return [
        <GridItem xs={12} sm={12} md={12}>
          {renderPisST()}
        </GridItem>,
        renderTipoCalculo(),
        renderAliquotaOrValorByTipoCalculo(),
        renderObservacaoPis(),
      ];
    }

    if (code >= 49 && code <= 99) {
      return [renderTipoCalculo(), renderAliquotaOrValorByTipoCalculo(), renderObservacaoPis()];
    }
    return <Box />;
  };

  return (
    <GridContainer>
      {renderSituacaoTributariaPis()}
      {renderBySituacaoTributariaPis()}
    </GridContainer>
  );
};

export default React.memo(NOPisTab);
