import axios from "axios";
import { APIS } from "../../../config/constants";
import BaseService from "../../../core/BaseService";

const { register: registerAPI } = APIS;

class UserService extends BaseService {
  constructor() {
    super(registerAPI.user);
  }

  fetchStates = () => axios.get(`${registerAPI.state}?size=50`);

  fetchCitiesByState = (stateId) => axios.get(`${registerAPI.city}/state/${stateId}`);

  updatePassword = (request) => axios.put(`${this.endpoint}/update-password`, request);
}

export default new UserService();
