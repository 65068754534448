import axios from "axios";
import { APIS } from "../../../config/constants";
import BaseService from "../../../core/BaseService";

const { register: registerAPI } = APIS;

class ExpenseService extends BaseService {
  constructor() {
    super(registerAPI.expense);
  }

  featch = ({ params, pagination, sorting }) => {
    const { page, pageSize } = pagination;
    const { field = "createdAt", sort = "desc" } = sorting || {};
    return axios.get(
      `${registerAPI.expense}/search-expenses?size=${pageSize}&page=${page}&sort=${field},${sort}&${params}`
    );
  };

  featchValues = (params) => axios.get(`${registerAPI.expense}/search-expenses-values?${params}`);
}

export default new ExpenseService();
