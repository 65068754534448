const ID = "support-initial-presentation-kairum";

const ZOOM_ID = "support-zoom-initial-kairum";

class SupporService {
  save = () => localStorage.setItem(ID, true);

  isShow = () => !(localStorage.getItem(ID) === "true");

  saveZoomNotification = () => localStorage.setItem(ZOOM_ID, true);

  isZoomNotificationShow = () => !(localStorage.getItem(ZOOM_ID) === "true");
}

export default new SupporService();
