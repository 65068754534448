import logoK from "../assets/img/logo_k.png";
import loadingGif from "../assets/img/loading_redirect.gif";

const GATEWAY = process.env.REACT_APP_URL_GATEWAY;

// console.log("REACT_APP_URL_GATEWAY", GATEWAY);

const APIS = {
  register: {
    auth: `${GATEWAY}/auth/realms/sales/protocol/openid-connect/token`,
    person: `${GATEWAY}/register/v1/persons`,
    user: `${GATEWAY}/register/v1/users`,
    company: `${GATEWAY}/register/v1/companies`,
    state: `${GATEWAY}/register/v1/states`,
    city: `${GATEWAY}/register/v1/cities`,
    product: `${GATEWAY}/register/v1/products`,
    productPriceChangeHistory: `${GATEWAY}/register/v1/product-price-change-history`,
    order: `${GATEWAY}/register/v1/orders`,
    serviceOrder: `${GATEWAY}/register/v1/service-orders`,
    calendar: `${GATEWAY}/register/v1/calendars`,
    expense: `${GATEWAY}/register/v1/expenses`,
    receive: `${GATEWAY}/register/v1/receives`,
    report: `${GATEWAY}/register/v1/reports`,
    departament: `${GATEWAY}/register/v1/departaments`,
    fieldsConfig: `${GATEWAY}/register/v1/fields-config`,
    paymentMethod: `${GATEWAY}/register/v1/payment-methods`,
    commissionConfig: `${GATEWAY}/register/v1/commission-configurations`,
    commission: `${GATEWAY}/register/v1/commissions`,
    inventoryMovements: `${GATEWAY}/register/v1/inventory-movements`,
    inventory: `${GATEWAY}/register/v1/inventories`,
    notification: `${GATEWAY}/register/v1/notifications`,
    plans: `${GATEWAY}/register/v1/plans`,
    stripe: `${GATEWAY}/register/v1/stripe`,
    dashboard: `${GATEWAY}/register/v1/dashboards`,
    unitMeasurement: `${GATEWAY}/register/v1/units-measurement`,
    unitConversion: `${GATEWAY}/register/v1/units-conversion`,
    digitalCertificates: `${GATEWAY}/register/v1/digital-certificates`,
    naturezaOperacao: `${GATEWAY}/register/v1/natureza-operacao`,
    cfop: `${GATEWAY}/register/v1/cfop`,
    codigoEnquadramentoIpi: `${GATEWAY}/register/v1/codigo-enquadramento-ipi`,
    ncm: `${GATEWAY}/register/v1/ncm`,
    partilhaIcms: `${GATEWAY}/register/v1/partilha-icms-interestadual`,
    cest: `${GATEWAY}/register/v1/cest`,
    zipCode: `${GATEWAY}/register/v1/cep`,
    notaFiscal: `${GATEWAY}/register/v1/nota-fiscal`,
    nfeInutilizacao: `${GATEWAY}/register/v1/nota-fiscal-inutilizacao`,
    notaFiscalImportacao: `${GATEWAY}/register/v1/nota-fiscal-importacao`,
    notaFiscalConfig: `${GATEWAY}/register/v1/configuracao-nfe`,
    notaFiscalPacoteDownload: `${GATEWAY}/register/v1/nota-fiscal-pacote-download`,
    adminCompanies: `${GATEWAY}/register/v1/admin-companies`,
    financialAccounts: `${GATEWAY}/register/v1/financial-accounts`,
  },
};

const SEARCH = {
  MINIMUM_CHARACTERS: 3,
  WAIT_INTERVAL: 800,
};

const ITENS_COMMISSIONS = [
  {
    code: "NONE",
    name: "Não aplicar",
  },
  {
    code: "PRODUCTS_SERVICES",
    name: "Produtos e serviços",
  },
  {
    code: "PRODUCTS",
    name: "Apenas produtos",
  },
  {
    code: "SERVICES",
    name: "Apenas serviços",
  },
];

const AUTOCOMPLETE = {
  QUICK_ADD: "QUICK_ADD"
}

const DATA_GRID_PAGE_DEFAULT_MODEL = { page: 0, pageSize: [25] };

const MODULES = {
  "/app/dashboards": "DASHBOARD",
  "/app/service-orders": "SERVICE-ORDER",
  "/app/service-order": "SERVICE-ORDER",
  "/app/orders": "ORDER",
  "/app/order": "ORDER",
  "/app/inventories": "INVENTORY", // Url esta sendo usada em outros locais. Se mudar, ajustar validar onde precisa ser alterado.
  "INVENTORY-SALE": "INVENTORY-SALE", // Url esta sendo usada em outros locais. Se mudar, ajustar validar onde precisa ser alterado.
  "/app/products": "REGISTRATION-PRODUCT",
  "/app/product": "REGISTRATION-PRODUCT",
  "/app/prodservices": "REGISTRATION-SERVICE",
  "/app/prodservice": "REGISTRATION-SERVICE",
  "/app/person-list": "REGISTRATION-PERSON",
  "/app/person": "REGISTRATION-PERSON",
  "/app/expenses": "FINANCIAL-EXPENSE",
  "/app/receives": "FINANCIAL-RECEIVE",
  "/app/commissions": "FINANCIAL-COMMISSIONS",
  "/app/reports": "REPORT",
  "/app/support": "SUPPORT",
  "REPORT-SERVICE-ORDER": "REPORT-SERVICE-ORDER",
  "REPORT-ORDER": "REPORT-ORDER",
  "REPORT-INVENTORY": "REPORT-INVENTORY",
  "REPORT-COMMISSIONS": "REPORT-COMMISSIONS",
  "/app/payment-methods": "SETTING-PAYMENT-METHOD",
  "/app/payment-method": "SETTING-PAYMENT-METHOD",
  "/app/commission-configurations": "SETTING-COMMISSIONS",
  "/app/commission-configuration": "SETTING-COMMISSIONS",
  "/app/users": "SETTING-USERS",
  "/app/user": "SETTING-USERS",
  "/app/company": "COMPANY",
  "/app/my-plan": "MY-PLAN",
  "/app/stripe": "MY-PLAN",
  "/app/calendar": "CALENDAR",
  "/app/price-search": "PRICE-SEARCH",
  "/app/units-measurement": "REGISTRATION-UNIT-MEASUREMENT",
  "/app/unit-measurement": "REGISTRATION-UNIT-MEASUREMENT",
  "/app/units-conversion": "REGISTRATION-UNIT-CONVERSION",
  "/app/unit-conversion": "REGISTRATION-UNIT-CONVERSION",
  "/app/digital-certificate": "DIGITAL-CERTIFICATE",
  "/app/operations-type": "NATUREZA-OPERACAO",
  "/app/operation-type": "NATUREZA-OPERACAO",
  "/app/partilha-icms": "PARTILHA-ICMS",
  "/app/nfe-emissao": "EMISSAO-NFE",
  "/app/nfe-emissoes": "EMISSAO-NFE",
  "/app/nfce-emissao": "EMISSAO-NFCE",
  "/app/nfce-emissoes": "EMISSAO-NFCE",
  "/app/nfe-entrada": "NFE-ENTRADA",
  "/app/nfe-entradas": "NFE-ENTRADA",
  "/app/nfe-entrada-lancar-estoque": "IMPORTACAO-NFE",
  "/app/nfe-inutilizacao": "NFE-INUTILIZACAO",
  "/app/nfe-inutilizacoes": "NFE-INUTILIZACAO",
  "/app/configuracao-nfe": "NFE-CONFIGURACAO",
  "/app/nfe-pacote-download": "PACKAGE-XML-NF",
  "/app/nfe-pacote-downloads": "PACKAGE-XML-NF",
  "/app/admin": "ADMIN_COMPANIES",
  "/app/admin-companies": "ADMIN_COMPANIES",
  "/app/financial-accounts": "SETTING-FINANCIAL-ACCOUNT",
  "/app/financial-account": "SETTING-FINANCIAL-ACCOUNT",
};

const REDIRECT_HTML = `<html><head><style>body {font-family: Arial; color: #747c87;}.split { height: 100%; width: 50%; position: fixed; z-index: 1; top: 0; overflow-x: hidden; padding-top: 20px; }.left { left: 0; background-color: #9c27b0; }.right { right: 0; background-color: #fff; }.centered { position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); text-align: center; }.centered img { width: 150px; }.imgGif { width: 80px !important; } </style> </head><div class='split left'> <div class='centered'><img src=${logoK} width='100px' /> </div> </div><div class='split right'> <div class='centered'><img src=${loadingGif} class='imgGif' /> <h2>Aguarde, estamos preparando tudo para você...</h2> </div> </div></body></html>`;
export {
  GATEWAY,
  APIS,
  SEARCH,
  ITENS_COMMISSIONS,
  MODULES,
  REDIRECT_HTML,
  DATA_GRID_PAGE_DEFAULT_MODEL,
  AUTOCOMPLETE
};
