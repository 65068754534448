/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useCallback } from "react";
import CustomComponentsStyle from "assets/jss/material-dashboard-react/components/customComponentsStyle";
import { makeStyles } from "@mui/styles";
import { FormControl, InputLabel, Select, MenuItem, FormHelperText } from "@mui/material";
import { dangerColor } from "assets/jss/material-dashboard-react";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

const SelectEnum = ({
  label,
  field,
  Enum,
  valueSelected,
  handleChange,
  errors,
  required = false,
  useValueField = false,
  disabled,
  style,
}) => {
  const useCustomComponentsStyle = makeStyles(CustomComponentsStyle);
  const classesCustomComponentsStyle = useCustomComponentsStyle();

  return (
    <FormControl
      fullWidth
      className={classesCustomComponentsStyle.formControlSelect}
      size="small"
      variant="outlined"
      required={required}
      style={style}
    >
      <InputLabel error={errors[field]}>{label}</InputLabel>
      <Select
        error={errors[field]}
        disabled={disabled}
        label={label}
        id={field}
        autoWidth
        value={valueSelected == null ? "" : valueSelected}
        onChange={(event) => {
          const {
            target: { value },
          } = event;
          return handleChange(field, value);
        }}
      >
        {useValueField
          ? Object.keys(Enum).map((key) => {
              const { code, name, value } = Enum[key];
              return (
                <MenuItem key={code} value={value}>
                  {code} - {name}
                </MenuItem>
              );
            })
          : Object.keys(Enum).map((key) => {
              const { code, name } = Enum[key];
              return (
                <MenuItem key={code} value={code}>
                  {name}
                </MenuItem>
              );
            })}
      </Select>
      <FormHelperText style={{ color: dangerColor[0] }}>{errors[field]}</FormHelperText>
    </FormControl>
  );
};

export default SelectEnum;
