const ExpenseRepeatType = {
  N: {
    code: "N",
    name: "Não repetir",
  },
  D: {
    code: "D",
    name: "Diariamente",
  },
  W: {
    code: "W",
    name: "Semanalmente",
  },
  M: {
    code: "M",
    name: "Mensalmente",
  },
};

const ExpenseOriginType = {
  MANUALLY: {
    code: "MANUALLY",
    name: "Manual",
  },
  ORDER: {
    code: "ORDER",
    name: "Venda",
  },
  SERVICE_ORDER: {
    code: "SERVICE_ORDER",
    name: "Ordem de serviço",
  },
  INSTALLMENT: {
    code: "INSTALLMENT",
    name: "Parcela",
  },
  COMMISSION: {
    code: "COMMISSION",
    name: "Comissão",
  },
  NFE: {
    code: "NFE",
    name: "Nota Fiscal",
  },
};

const PaymentStatusType = {
  PAID: {
    code: "PAID",
    name: "Pago",
  },
  PARTIALLY_PAID: {
    code: "PARTIALLY_PAID",
    name: "Parcial. pago",
  },
  PENDING: {
    code: "PENDING",
    name: "Pendente",
  },
};

const ExpensePaymentType = {
  MANUALLY: {
    code: "MANUALLY",
  },
  AUTOMATIC: {
    code: "AUTOMATIC",
  },
};

class Expense {
  constructor(source = {}) {
    this.id = source.id;
    this.name = source.name;
    this.startDate = source.startDate;
    this.endDate = source.endDate;
    this.value = source.value;
    this.user = source.user;
    this.financialAccount = source.financialAccount;
    this.repeatType = source.repeatType;
    this.expenseOriginType = source.expenseOriginType;
    this.parentId = source.parentId;
    this.payments = source.payments;
    this.version = source.version;
    this.valuePaid = source.valuePaid;
    this.paymentStatusType = source.paymentStatusType;
  }
}

export { Expense, ExpenseRepeatType, ExpenseOriginType, PaymentStatusType, ExpensePaymentType };
