/* eslint-disable no-param-reassign */
/* eslint-disable no-console */
import axios from "axios";
import { APIS } from "../../../config/constants";

const { register: registerAPI } = APIS;

class LoginService {
  me = () => axios.get(`${registerAPI.user}/me`);
}

export default new LoginService();
