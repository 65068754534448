/* eslint-disable react/prop-types */
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileImport } from "@fortawesome/free-solid-svg-icons";
import { makeStyles } from "@mui/styles";
import styles from "./iconStyle";

const useStyles = makeStyles(styles);

const ManifestacaoIcon = ({ onClick, style }) => {
  const classes = useStyles();
  return (
    <FontAwesomeIcon
      icon={faFileImport}
      onClick={onClick}
      className={classes.custom}
      title="Manifestação"
      color="#686868"
      style={style}
    />
  );
};

export default React.memo(ManifestacaoIcon);
