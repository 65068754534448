import moment from "moment";
import { ServiceOrder } from "../model/ServiceOrder";

const toRequest = (order, userLogged) => {
  const {
    id,
    expirationDate,
    customer,
    user,
    serviceProvider,
    paymentMethod,
    orderType,
    additionalInformation,
    version,
    orderNumber,
    discount,
    total,
    installments = {},
    items = [],
    numberInstallments,
    extraFields,
    received,
    difference,
    defect,
    expectedDelivery,
    notaFiscal,
    financialAccount
  } = order;

  let installmentsConverted = Object.values(installments);

  installmentsConverted =
    installmentsConverted?.map((install) => {
      const { dueDate } = install;
      const dateConverted = moment(dueDate).format();
      return {
        ...install,
        dueDate: dateConverted,
      };
    }) || [];

  const customerId = customer?.id;
  const customerName = customer?.name;
  const userId = user?.id;
  const userName = user?.name;
  const userLoggedId = userLogged?.id;
  const userLoggedName = userLogged?.name;
  const serviceProviderId = serviceProvider?.id;
  const serviceProviderName = serviceProvider?.name;

  return {
    id,
    expirationDate,
    customerId,
    customerName,
    userId,
    userName,
    userLoggedId,
    userLoggedName,
    serviceProviderId,
    serviceProviderName,
    paymentMethod,
    orderType,
    additionalInformation,
    orderNumber,
    discount,
    total,
    installments: installmentsConverted,
    items: items.map((item) => ({
      ...item,
      inventory: null,
      id: null,
    })),
    numberInstallments,
    extraFields,
    received: received <= total ? total : received,
    difference,
    defect,
    expectedDelivery,
    notaFiscal,
    financialAccount,
    version,
  };
};

const fromResponse = (response) => {
  if (response === null) return new ServiceOrder();

  const {
    id,
    expirationDate,
    customerId,
    customerName,
    userId,
    userName,
    userLoggedId,
    userLoggedName,
    serviceProviderId,
    serviceProviderName,
    paymentMethod,
    orderType,
    additionalInformation,
    items,
    version,
    createdAt,
    updatedAt,
    finalizationDate,
    orderNumber,
    discount,
    total,
    extraFields = {},
    cancelled,
    installments = [],
    numberInstallments,
    received = 0,
    difference = 0,
    defect,
    expectedDelivery,
    notaFiscal,
    financialAccount
  } = response;

  const installmentsConverted = {};
  installments.forEach((item) => {
    installmentsConverted[item.installmentNumber] = item;
  });

  const customer = {
    id: customerId,
    name: customerName,
  };

  const user = {
    id: userId,
    name: userName,
  };

  const userLogged = {
    id: userLoggedId,
    name: userLoggedName,
  };

  const serviceProvider = {
    id: serviceProviderId,
    name: serviceProviderName,
  };

  return new ServiceOrder({
    id,
    expirationDate,
    customer,
    user,
    paymentMethod,
    orderType,
    additionalInformation,
    items,
    version,
    createdAt,
    updatedAt,
    finalizationDate,
    orderNumber,
    discount,
    total,
    userLogged,
    serviceProvider,
    cancelled,
    installments: installmentsConverted,
    numberInstallments,
    extraFields,
    received,
    difference,
    defect,
    expectedDelivery,
    notaFiscal,
    financialAccount,
  });
};

export { toRequest, fromResponse };
