class CommissionConfig {
  constructor(source = {}) {
    this.id = source.id;
    this.startDate = source.startDate;
    this.endDate = source.endDate;
    this.serviceCommission = source.serviceCommission;
    this.productCommission = source.productCommission;
    this.receiptInInstallments = source.receiptInInstallments;
    this.onAllSales = source.onAllSales;
    this.removeOnCancelOrder = source.removeOnCancelOrder;
    this.targetCalculationMethod = source.targetCalculationMethod;
    this.goal = source.goal;
    this.goalAutomaticClosing = source.goalAutomaticClosing;
    this.goalAutomaticClosingDay = source.goalAutomaticClosingDay;
    this.goals = source.goals;
    this.user = source.user;
    this.active = source.active;
    this.version = source.version;
  }
}

export default CommissionConfig;
