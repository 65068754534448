import moment from "moment";
import Company from "../model/Company";

const toRequest = (company, inscricaoEstadualSubstitutoTributarioList) => {
  const {
    id,
    tradingName,
    companyName,
    cnpj,
    cpf,
    documentType,
    foundationDate,
    city,
    street,
    number,
    complement,
    zipCode,
    neighborhood,
    phone,
    mobile,
    email,
    version,
    addressId,
    addressVersion,
    profileBusiness,
    inscricaoEstadual,
  } = company;

  let inscricaoEstadualSubstitutoTributario = null;
  if (Object.keys(inscricaoEstadualSubstitutoTributarioList?.[0]).length > 1) {
    inscricaoEstadualSubstitutoTributario = inscricaoEstadualSubstitutoTributarioList.reduce(
      (a, v) => ({ ...a, [v.state]: v.ie }),
      {}
    );
  }

  const address = {
    id: addressId,
    version: addressVersion,
    city:
      city != null
        ? {
            id: city,
          }
        : null,
    street,
    number,
    complement,
    zipCode,
    neighborhood,
  };

  const cnpjWM = documentType === "CNPJ" ? cnpj?.replace(/[^0-9]+/g, "") || null : null;
  const cpfWM = documentType === "CPF" ? cpf?.replace(/[^0-9]+/g, "") || null : null;
  const mobileWM = mobile?.replace(/[^0-9]+/g, "") || null;
  const phoneWM = phone?.replace(/[^0-9]+/g, "") || null;

  return {
    id,
    tradingName,
    companyName,
    foundationDate: foundationDate ? moment(foundationDate).format("YYYY-MM-DD") : null,
    address,
    cnpj: cnpjWM,
    cpf: cpfWM,
    documentType,
    phone: phoneWM,
    mobile: mobileWM,
    email,
    profileBusiness,
    inscricaoEstadualSubstitutoTributario,
    inscricaoEstadual,
    version,
  };
};

const fromResponse = (response) => {
  if (response === null) return new Company();

  const {
    id,
    tradingName,
    companyName,
    cnpj,
    cpf,
    documentType,
    foundationDate,
    phone,
    mobile,
    email,
    version,
    address,
    profileBusiness,
    inscricaoEstadual,
    inscricaoEstadualSubstitutoTributario,
  } = response;

  const {
    city,
    street,
    number,
    complement,
    zipCode,
    neighborhood,
    id: addressId,
    version: addressVersion,
  } = address || {};
  let cityId = null;
  let stateId = null;
  if (city != null) {
    cityId = city.id;
    stateId = city.state.id;
  }

  let inscricaoEstadualSubstitutoTributarioList = [];
  if (inscricaoEstadualSubstitutoTributario != null) {
    inscricaoEstadualSubstitutoTributarioList = Object.keys(
      inscricaoEstadualSubstitutoTributario
    ).map((key, index) => {
      const ie = inscricaoEstadualSubstitutoTributario[key];
      return { state: key, ie, index };
    });
  }

  return new Company({
    id,
    tradingName,
    companyName,
    cnpj,
    cpf,
    documentType,
    foundationDate,
    state: stateId,
    city: cityId,
    street,
    number,
    complement,
    zipCode,
    neighborhood,
    phone,
    mobile,
    email,
    version,
    addressId,
    addressVersion,
    profileBusiness,
    inscricaoEstadualSubstitutoTributario: inscricaoEstadualSubstitutoTributarioList,
    inscricaoEstadual,
  });
};

export { toRequest, fromResponse };
