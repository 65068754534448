import axios from "axios";
import { APIS } from "../../../config/constants";
import BaseService from "../../../core/BaseService";

const { register: registerAPI } = APIS;

class FinancialAccountsService extends BaseService {
  constructor() {
    super(registerAPI.financialAccounts);
  }

  fetchActives = () => axios.get(`${this.endpoint}/all-actives`);
}

export default new FinancialAccountsService();
