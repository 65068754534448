/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useCallback, useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faShoppingCart,
  faTools,
  faArrowTrendDown,
  faArrowTrendUp,
} from "@fortawesome/free-solid-svg-icons";
import { useSnackbar } from "notistack";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import Danger from "components/Typography/Danger";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardIcon from "components/Card/CardIcon";
import CardBody from "components/Card/CardBody";
import CardFooter from "components/Card/CardFooter";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { Link, useHistory } from "react-router-dom";
// eslint-disable-next-line import/extensions
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import {
  Box,
  CircularProgress,
  LinearProgress,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { primaryColor } from "assets/jss/material-dashboard-react";
import { currencyFormatter } from "../../config/util";
import MESSAGES from "../../config/messages";
import DashboardService from "./service/DashboardService";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const useStyles = makeStyles(styles);

const options = {
  responsive: true,
  lineTension: 0.5,
  plugins: {
    legend: {
      position: "top",
    },
    // title: {
    //   display: true,
    //   text: "Chart.js Line Chart",
    // },
  },
};

const RECURRENCE = {
  YEARLY: {
    code: "YEARLY",
    field: "valueAnnual",
  },
  MONTHLY: {
    code: "MONTHLY",
    field: "valueMonthly",
  },
  WEEKLY: {
    code: "WEEKLY",
    field: "valueWeekly",
  },
};

const { generalMessages } = MESSAGES;

export default function Dashboard() {
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const [counters, setCounter] = useState({
    sumOrdersOfTheDay: 0,
    sumServiceOrdersOfTheDay: 0,
    sumExpensesOfTheDay: 0,
    sumReceiveOfTheDay: 0,
  });

  const [osOrder, setOsOrder] = useState({
    labels: [],
    datasets: [],
  });

  const [recurrence, setRecurrence] = useState(RECURRENCE.WEEKLY);

  const [loadingCounters, setLoadingCounters] = useState(false);
  const [loadingOsOrderChart, setLoadingOsOrderChart] = useState(false);

  const handleChangeRecurrence = useCallback((ev) => {
    setRecurrence(RECURRENCE[ev.currentTarget.value]);
  }, []);

  const getCounters = useCallback(async () => {
    setLoadingCounters(true);
    try {
      const result = await DashboardService.counterDay();
      const { data: dt } = result?.data || {};
      setCounter(dt);
    } catch (error) {
      enqueueSnackbar(generalMessages.error, {
        variant: "error",
      });
    } finally {
      setLoadingCounters(false);
    }
  }, [enqueueSnackbar]);

  const getOSOrdersChart = useCallback(async () => {
    setLoadingOsOrderChart(true);
    try {
      const result = await DashboardService.orderOSFromPeriod(recurrence.code);
      const { data: dt } = result?.data || {};
      setOsOrder(dt);
    } catch (error) {
      enqueueSnackbar(generalMessages.error, {
        variant: "error",
      });
    } finally {
      setLoadingOsOrderChart(false);
    }
  }, [enqueueSnackbar, recurrence.code]);

  useEffect(() => {
    getCounters();
  }, [getCounters]);

  useEffect(() => {
    getOSOrdersChart();
  }, [getOSOrdersChart]);

  return (
    <div>
      <Box
        display="flex"
        justifyContent="center"
        style={{ width: "100%", paddingTop: 10, paddingBottom: 10 }}
      >
        {loadingCounters ||
          (loadingOsOrderChart && (
            <Box sx={{ width: "90%" }}>
              <LinearProgress
                classes={{
                  colorPrimary: classes.linearProgressColorPrimary,
                  barColorPrimary: classes.linearProgressCarColorPrimary,
                }}
              />
            </Box>
          ))}
      </Box>
      <GridContainer>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="warning" stats icon>
              <Box display="flex" flexDirection="column">
                <Box>
                  <CardIcon color="warning" style={{ padding: "5px" }}>
                    <FontAwesomeIcon
                      icon={faTools}
                      color="#ffff"
                      style={{ width: "24px", height: "24px" }}
                    />
                  </CardIcon>
                </Box>
                <Box style={{ position: "relative", top: "-10px" }}>
                  <p className={classes.cardCategory}>OS hoje</p>
                  <h3
                    className={classes.cardTitle}
                    style={{ fontSize: "20px", display: "inline-block" }}
                  >
                    {currencyFormatter(counters.sumServiceOrdersOfTheDay)}
                  </h3>
                </Box>
              </Box>
            </CardHeader>
            <CardFooter stats style={{ marginTop: "5px" }}>
              <Link to="/app/service-orders">Ver OS</Link>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="success" stats icon>
              <Box display="flex" flexDirection="column">
                <Box>
                  <CardIcon color="success" style={{ padding: "5px" }}>
                    <FontAwesomeIcon
                      icon={faShoppingCart}
                      color="#ffff"
                      style={{ width: "24px", height: "24px" }}
                    />
                  </CardIcon>
                </Box>
                <Box style={{ position: "relative", top: "-10px" }}>
                  <p className={classes.cardCategory}>Vendas hoje</p>
                  <h3
                    className={classes.cardTitle}
                    style={{ fontSize: "20px", display: "inline-block" }}
                  >
                    {currencyFormatter(counters.sumOrdersOfTheDay)}
                  </h3>
                </Box>
              </Box>
            </CardHeader>
            <CardFooter stats style={{ marginTop: "5px" }}>
              <Link to="/app/orders"> Ver vendas</Link>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="danger" stats icon>
              <Box display="flex" flexDirection="column">
                <Box>
                  <CardIcon color="danger" style={{ padding: "5px" }}>
                    <FontAwesomeIcon
                      icon={faArrowTrendDown}
                      color="#ffff"
                      style={{ width: "24px", height: "24px" }}
                    />
                  </CardIcon>
                </Box>
                <Box style={{ position: "relative", top: "-10px" }}>
                  <p className={classes.cardCategory}>Despesas hoje</p>
                  <h3
                    className={classes.cardTitle}
                    style={{ fontSize: "20px", display: "inline-block" }}
                  >
                    {currencyFormatter(counters.sumExpensesOfTheDay)}
                  </h3>
                </Box>
              </Box>
            </CardHeader>
            <CardFooter stats style={{ marginTop: "5px" }}>
              <Link to="/app/expenses">Ver despesas</Link>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="info" stats icon>
              <Box display="flex" flexDirection="column">
                <Box>
                  <CardIcon color="info" style={{ padding: "5px" }}>
                    <FontAwesomeIcon
                      icon={faArrowTrendUp}
                      color="#ffff"
                      style={{ width: "24px", height: "24px" }}
                    />
                  </CardIcon>
                </Box>
                <Box style={{ position: "relative", top: "-10px" }}>
                  <p className={classes.cardCategory}>Recebimentos hoje</p>
                  <h3
                    className={classes.cardTitle}
                    style={{ fontSize: "20px", display: "inline-block" }}
                  >
                    {currencyFormatter(counters.sumReceiveOfTheDay)}
                  </h3>
                </Box>
              </Box>
            </CardHeader>
            <CardFooter stats style={{ marginTop: "5px" }}>
              <Link to="/app/receives">Ver recebimentos</Link>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>

      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card chart>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>Vendas/OS do período</h4>
              <p className={classes.cardCategoryWhite}>
                Gráfico para acompanhamento de Vendas e Ordens de serviço.
              </p>
            </CardHeader>
            <CardBody>
              <Box>
                <Box display="flex" justifyContent="flex-end">
                  <ToggleButtonGroup
                    value={recurrence.code}
                    size="small"
                    exclusive
                    onChange={handleChangeRecurrence}
                  >
                    <ToggleButton
                      value={RECURRENCE.WEEKLY.code}
                      classes={{
                        selected: classes.recurrenceSelected,
                      }}
                    >
                      Semana
                    </ToggleButton>
                    <ToggleButton
                      value={RECURRENCE.MONTHLY.code}
                      classes={{
                        selected: classes.recurrenceSelected,
                      }}
                    >
                      Mês
                    </ToggleButton>
                    <ToggleButton
                      value={RECURRENCE.YEARLY.code}
                      classes={{
                        selected: classes.recurrenceSelected,
                      }}
                    >
                      Ano
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Box>
                <Line options={options} data={osOrder} height="70vh" />
              </Box>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
