import UnitMeasurement from "../model/UnitMeasurement";

const toRequest = (unitMeasurement) => {
  const { id, acronym, name, version, active } = unitMeasurement;

  return {
    id,
    acronym,
    name,
    version,
    active,
  };
};

const fromResponse = (response) => {
  if (response === null) return new UnitMeasurement();

  const { id, acronym, name, version, active } = response;

  return new UnitMeasurement({
    id,
    acronym,
    name,
    version,
    active,
  });
};

export { toRequest, fromResponse };
