/* eslint-disable react/prop-types */
import React from "react";
import { makeStyles } from "@mui/styles";
import CustomComponentsStyle from "assets/jss/material-dashboard-react/components/customComponentsStyle";
import {
  Box,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  Typography,
} from "@mui/material";
import GridItem from "components/Grid/GridItem";
import Autocomplete from "components/autocomplete/Autocomplete";
import TextInput from "components/textInput/TextInput";
import CurrencyTextInput from "components/textInput/CurrencyTextInput";
import MESSAGES from "config/messages";
import GridContainer from "components/Grid/GridContainer";
import CfopService from "views/cfop/service/CfopService";
import { SituacaoOperacionalSimplesNacional, YesNo } from "../model/NaturezaOperacao";
import SelectNFNotaInfoItemModalidadeBCICMSST from "./SelectNFNotaInfoItemModalidadeBCICMSST";
import SelectNFNotaInfoItemModalidadeBCICMS from "./SelectNFNotaInfoItemModalidadeBCICMS";

const { naturezaOperacaoMessages } = MESSAGES;
const CHARACTER_LIMIT = 4000;

const NOSimplesTab = ({ data, handleChange, errors = {} }) => {
  const useCustomComponentsStyle = makeStyles(CustomComponentsStyle);
  const classesCustomComponentsStyle = useCustomComponentsStyle();

  const {
    situacaoOperacaoSnSimples,
    partilhaIcmsInterestadualSimples,
    cfopSimples,
    aliquotaCalculoCreditoSimples,
    observacaoSimples,
    aliquotaIcmsSimples,
    baseIcmsSimples,
    aliquotaIcmsStSimples,
    baseCalculoIcmsStSimples,
    margemAdicionalIcmsStSimples,
    aliquotaFcpIcmsStSimples,
    obterIcmsStRetidoAnteriormenteNfCompraSimples,
    modalidadeBcIcmsStSimples,
    modalidadeBcIcmsSimples,
    percentualReducaoBcSimples,
    percentualReducaoBcIcmsStSimples,
    percentualReducaoBcEfetivaSimples,
    aliquotaIcmsEfetivaSimples,
    baseIcmsEfetivaSimples,
  } = data;

  const renderCfopSimles = () => (
    <GridItem xs={12} sm={12} md={4}>
      <Box display="flex" flexGrow={1}>
        <Autocomplete
          freeSolo
          autoSelect
          width="100%"
          variant="outlined"
          fieldsToShowAfterSelectItem={["shortDescription"]}
          label={naturezaOperacaoMessages.simples.cfopSimples}
          placeholder={naturezaOperacaoMessages.simples.autocompleteCfopSimples}
          service={CfopService}
          value={cfopSimples}
          onChange={(value) => {
            if (typeof value === "string" || value == null) {
              handleChange("cfopSimples", value);
            } else {
              handleChange("cfopSimples", value.cfop);
            }
          }}
        />
      </Box>
    </GridItem>
  );

  const renderAliquotaIcmsSimples = () => (
    <GridItem xs={8} sm={8} md={3}>
      <CurrencyTextInput
        decimalPlaces={4}
        currencySymbol="%"
        id="aliquotaIcmsSimples"
        label={naturezaOperacaoMessages.simples.aliquotaIcmsSimples}
        value={aliquotaIcmsSimples || "0"}
        onChange={handleChange}
        errors={errors}
      />
    </GridItem>
  );

  const renderBaseIcmsSimples = () => (
    <GridItem xs={8} sm={8} md={3}>
      <CurrencyTextInput
        decimalPlaces={4}
        currencySymbol="%"
        id="baseIcmsSimples"
        label={naturezaOperacaoMessages.simples.baseIcmsSimples}
        value={baseIcmsSimples || "0"}
        onChange={handleChange}
        errors={errors}
      />
    </GridItem>
  );

  const renderPartilhaIcmsInterestadualSimples = () => (
    <GridItem xs={12} sm={12} md={3}>
      <FormControl
        fullWidth
        className={classesCustomComponentsStyle.formControlSelect}
        size="small"
        variant="outlined"
      >
        <InputLabel>{naturezaOperacaoMessages.simples.partilhaIcmsInterestadualSimples}</InputLabel>
        <Select
          label={naturezaOperacaoMessages.simples.partilhaIcmsInterestadualSimples}
          id="partilhaIcmsInterestadualSimples"
          autoWidth
          value={partilhaIcmsInterestadualSimples === true ? YesNo.YES.code : YesNo.No.code}
          onChange={(event) => {
            const {
              target: { value },
            } = event;
            return handleChange("partilhaIcmsInterestadualSimples", value);
          }}
          error={errors.partilhaIcmsInterestadualSimples}
          helperText={naturezaOperacaoMessages.simples.partilhaIcmsInterestadualSimples}
        >
          {Object.keys(YesNo).map((key) => {
            const { code, name } = YesNo[key];
            return (
              <MenuItem key={code} value={code}>
                {name}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </GridItem>
  );

  const renderAliquotaCalculoCreditoSimples = () => (
    <GridItem xs={8} sm={8} md={3}>
      <CurrencyTextInput
        currencySymbol="%"
        id="aliquotaCalculoCreditoSimples"
        label={naturezaOperacaoMessages.simples.aliquotaCalculoCreditoSimples}
        value={aliquotaCalculoCreditoSimples || "0"}
        onChange={handleChange}
        errors={errors}
      />
    </GridItem>
  );

  const renderObservacaoSimples = () => (
    <GridItem xs={12} sm={12} md={12}>
      <TextInput
        id="observacaoSimples"
        label={naturezaOperacaoMessages.simples.observacaoSimples}
        value={observacaoSimples}
        maxLength={CHARACTER_LIMIT}
        onChange={handleChange}
        isCounter
        multiline
        rows={4}
      />
    </GridItem>
  );

  const renderObterIcmsSTRetidoAnteriormenteAPartirNFCompra = (
    value = false,
    field,
    label,
    disabled = false
  ) => (
    <GridItem xs={8} sm={8} md={12}>
      <FormGroup style={{ paddingTop: "8px" }}>
        <FormControlLabel
          control={
            <Switch
              checked={value}
              onChange={(event) => handleChange(field, event.target.checked)}
              color="secondary"
            />
          }
          label={label}
          disabled={disabled}
        />
      </FormGroup>
    </GridItem>
  );

  const renderModalidadeBcIcmsStSimples = () => (
    <GridItem xs={8} sm={8} md={6}>
      <SelectNFNotaInfoItemModalidadeBCICMSST
        field="modalidadeBcIcmsStSimples"
        valueSelected={modalidadeBcIcmsStSimples}
        handleChange={handleChange}
      />
    </GridItem>
  );

  const renderPercentualReducaoBcIcmsStSimples = () => (
    <GridItem xs={8} sm={8} md={6}>
      <CurrencyTextInput
        currencySymbol="%"
        decimalPlaces={4}
        id="percentualReducaoBcIcmsStSimples"
        label="% Redução da BC ICMS ST"
        value={percentualReducaoBcIcmsStSimples || "0"}
        onChange={handleChange}
        errors={errors}
      />
    </GridItem>
  );

  const renderModalidadeBcIcmsSimples = () => (
    <GridItem xs={8} sm={8} md={4}>
      <SelectNFNotaInfoItemModalidadeBCICMS
        field="modalidadeBcIcmsSimples"
        valueSelected={modalidadeBcIcmsSimples}
        handleChange={handleChange}
      />
    </GridItem>
  );

  const renderPercentualReducaoBcSimples = () => (
    <GridItem xs={8} sm={8} md={4}>
      <CurrencyTextInput
        decimalPlaces={4}
        currencySymbol="%"
        id="percentualReducaoBcSimples"
        label="% redução da BC ICMS"
        value={percentualReducaoBcSimples || "0"}
        onChange={handleChange}
        errors={errors}
      />
    </GridItem>
  );

  const renderPercentualReducaoBcEfetivaSimples = () => (
    <GridItem xs={8} sm={8} md={4}>
      <CurrencyTextInput
        decimalPlaces={4}
        currencySymbol="%"
        id="percentualReducaoBcEfetivaSimples"
        label="% Redução da BC Efetivo"
        value={percentualReducaoBcEfetivaSimples || "0"}
        onChange={handleChange}
        errors={errors}
      />
    </GridItem>
  );

  const renderSubstituicaoTributaria = () => (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12} style={{ marginTop: 12 }}>
        <Divider style={{ width: "100%", marginBottom: 4 }} />
        <Typography style={{ fontSize: 18, fontWeight: 500 }}>Substituição tributária</Typography>
      </GridItem>
      {[renderModalidadeBcIcmsStSimples(), renderPercentualReducaoBcIcmsStSimples()]}

      <GridItem xs={8} sm={8} md={6}>
        <CurrencyTextInput
          currencySymbol="%"
          id="aliquotaIcmsStSimples"
          label={naturezaOperacaoMessages.simples.aliquotaIcmsStSimples}
          value={aliquotaIcmsStSimples || "0"}
          onChange={handleChange}
          errors={errors}
        />
      </GridItem>
      <GridItem xs={8} sm={8} md={6}>
        <CurrencyTextInput
          currencySymbol="%"
          id="baseCalculoIcmsStSimples"
          label={naturezaOperacaoMessages.simples.baseCalculoIcmsStSimples}
          value={baseCalculoIcmsStSimples || "0"}
          onChange={handleChange}
          errors={errors}
        />
      </GridItem>
      <GridItem xs={8} sm={8} md={6}>
        <CurrencyTextInput
          decimalPlaces={4}
          currencySymbol="%"
          id="margemAdicionalIcmsStSimples"
          label={naturezaOperacaoMessages.simples.margemAdicionalIcmsStSimples}
          value={margemAdicionalIcmsStSimples || "0"}
          onChange={handleChange}
          errors={errors}
        />
      </GridItem>
      <GridItem xs={8} sm={8} md={6}>
        <CurrencyTextInput
          currencySymbol="%"
          id="aliquotaFcpIcmsStSimples"
          label={naturezaOperacaoMessages.simples.aliquotaFcpIcmsStSimples}
          value={aliquotaFcpIcmsStSimples || "0"}
          onChange={handleChange}
          errors={errors}
        />
      </GridItem>
    </GridContainer>
  );

  const renderSubstituicaoTributariaRetidaAnteriormente = () => (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12} style={{ marginTop: 12 }}>
        <Divider style={{ width: "100%", marginBottom: 4 }} />
        <Typography style={{ fontSize: 18, fontWeight: 500 }}>
          Substituição tributária retida anteriormente
        </Typography>
      </GridItem>

      <GridItem xs={8} sm={8} md={6}>
        <CurrencyTextInput
          currencySymbol="%"
          id="aliquotaIcmsStSimples"
          label={naturezaOperacaoMessages.simples.aliquotaIcmsRetidoStSimples}
          value={aliquotaIcmsStSimples || "0"}
          onChange={handleChange}
          errors={errors}
        />
      </GridItem>

      <GridItem xs={8} sm={8} md={6}>
        <CurrencyTextInput
          currencySymbol="%"
          id="baseCalculoIcmsStSimples"
          label={naturezaOperacaoMessages.simples.baseCalculoIcmsRetidoStSimples}
          value={baseCalculoIcmsStSimples || "0"}
          onChange={handleChange}
          errors={errors}
        />
      </GridItem>

      <GridItem xs={8} sm={8} md={6}>
        <CurrencyTextInput
          currencySymbol="%"
          id="aliquotaFcpIcmsStSimples"
          label={naturezaOperacaoMessages.simples.aliquotaFcpIcmsStSimples}
          value={aliquotaFcpIcmsStSimples || "0"}
          onChange={handleChange}
          errors={errors}
        />
      </GridItem>
    </GridContainer>
  );

  const renderAliquotaIcmsEfetivoStSimples = () => (
    <GridItem xs={8} sm={8} md={6}>
      <CurrencyTextInput
        currencySymbol="%"
        decimalPlaces={4}
        id="aliquotaIcmsEfetivaSimples"
        label={naturezaOperacaoMessages.simples.aliquotaIcmsEfetivoStSimples}
        value={aliquotaIcmsEfetivaSimples || "0"}
        onChange={handleChange}
        errors={errors}
      />
    </GridItem>
  );

  const renderBaseIcmsEfetivoStSimples = () => (
    <GridItem xs={8} sm={8} md={6}>
      <CurrencyTextInput
        decimalPlaces={4}
        currencySymbol="%"
        id="baseIcmsEfetivaSimples"
        label={naturezaOperacaoMessages.simples.baseIcmsEfetivoStSimples}
        value={baseIcmsEfetivaSimples || "0"}
        onChange={handleChange}
        errors={errors}
      />
    </GridItem>
  );

  const renderBySituacaoOperacaoSNSimples = () => {
    const code = parseInt(SituacaoOperacionalSimplesNacional[situacaoOperacaoSnSimples].code, 10);

    if (code === 101) {
      return [
        renderCfopSimles(),
        renderPartilhaIcmsInterestadualSimples(),
        renderAliquotaCalculoCreditoSimples(),
        renderObservacaoSimples(),
      ];
    }

    if (code === 102 || code === 103) {
      return [
        renderCfopSimles(),
        renderPartilhaIcmsInterestadualSimples(),
        renderObservacaoSimples(),
      ];
    }

    if (code === 201) {
      return [
        renderCfopSimles(),
        renderAliquotaIcmsSimples(),
        renderBaseIcmsSimples(),
        renderPartilhaIcmsInterestadualSimples(),
        renderAliquotaCalculoCreditoSimples(),
        renderObservacaoSimples(),
        renderSubstituicaoTributaria(),
        // renderModalidadeBcIcmsStSimples(),
      ];
    }

    if (code === 202 || code === 203) {
      return [
        renderCfopSimles(),
        renderAliquotaIcmsSimples(),
        renderBaseIcmsSimples(),
        renderPartilhaIcmsInterestadualSimples(),
        renderObservacaoSimples(),
        renderSubstituicaoTributaria(),
      ];
    }

    if (code === 300 || code === 400) {
      return [
        renderCfopSimles(),
        renderPartilhaIcmsInterestadualSimples(),
        renderObservacaoSimples(),
      ];
    }

    if (code === 500) {
      return [
        renderCfopSimles(),
        renderPercentualReducaoBcEfetivaSimples(),
        renderAliquotaIcmsEfetivoStSimples(),
        renderBaseIcmsEfetivoStSimples(),
        renderPartilhaIcmsInterestadualSimples(),
        renderObservacaoSimples(),

        renderObterIcmsSTRetidoAnteriormenteAPartirNFCompra(
          obterIcmsStRetidoAnteriormenteNfCompraSimples,
          "obterIcmsStRetidoAnteriormenteNfCompraSimples",
          "Obter ICMS-ST retido anteriormente a partir de nota fiscal de compra"
        ),
        !obterIcmsStRetidoAnteriormenteNfCompraSimples &&
          renderSubstituicaoTributariaRetidaAnteriormente(),
      ];
    }

    if (code === 900) {
      return [
        renderCfopSimles(),
        renderAliquotaIcmsSimples(),
        renderBaseIcmsSimples(),
        renderPartilhaIcmsInterestadualSimples(),
        renderModalidadeBcIcmsSimples(),
        renderPercentualReducaoBcSimples(),
        renderAliquotaCalculoCreditoSimples(),
        renderObservacaoSimples(),
        renderSubstituicaoTributaria(),
      ];
    }

    return <Box />;
  };

  // const renderTipoCalculo = () => (
  //   <GridItem xs={8} sm={8} md={3} style={{ marginTop: -6 }}>
  //     <SelectTipoCalculo
  //       field="tipoCalculoSimples"
  //       valueSelected={tipoCalculoSimples}
  //       handleChange={handleChange}
  //     />
  //   </GridItem>
  // );

  const renderSituacaoOperacaoSnSimples = () => (
    <GridItem xs={12} sm={12} md={8}>
      <FormControl
        fullWidth
        className={classesCustomComponentsStyle.formControlSelect}
        size="small"
        variant="outlined"
      >
        <InputLabel>{naturezaOperacaoMessages.simples.situacaoOperacaoSnSimples}</InputLabel>
        <Select
          label={naturezaOperacaoMessages.simples.situacaoOperacaoSnSimples}
          id="situacaoOperacaoSnSimples"
          autoWidth
          value={situacaoOperacaoSnSimples == null ? "" : situacaoOperacaoSnSimples}
          onChange={(event) => {
            const {
              target: { value },
            } = event;
            return handleChange("situacaoOperacaoSnSimples", value);
          }}
          error={errors.situacaoOperacaoSnSimples}
          helperText={naturezaOperacaoMessages.simples.situacaoOperacaoSnSimples}
        >
          {Object.keys(SituacaoOperacionalSimplesNacional).map((key) => {
            const { code, name, value } = SituacaoOperacionalSimplesNacional[key];
            return (
              <MenuItem key={code} value={value}>
                {code} - {name}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </GridItem>
  );

  return (
    <GridContainer>
      {renderSituacaoOperacaoSnSimples()}
      {renderBySituacaoOperacaoSNSimples()}
    </GridContainer>
  );
};

export default React.memo(NOSimplesTab);
