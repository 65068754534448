const PaymentMethod = {
  CASH: {
    code: "CASH",
    name: "À vista",
  },
  CARD: {
    code: "CARD",
    name: "Cartão",
  },
  INSTALLMENT: {
    code: "INSTALLMENT",
    name: "Crediário",
  },
};

const OrderType = {
  BUDGET: {
    code: "BUDGET",
    name: "Orçamento",
  },
  ORDER: {
    code: "ORDER",
    name: "Venda",
  },
  NFE: {
    code: "NFE",
    name: "NFe",
  },
  NFCE: {
    code: "NFCE",
    name: "NFCe",
  },
  SERVICE_ORDER: {
    code: "SERVICE_ORDER",
    name: "Ordem de serviço",
  },
};

const OrderTypeFilter = {
  ALL: {
    code: "ALL",
    name: "Todos",
  },
  BUDGET: {
    code: "BUDGET",
    name: "Orçamentos",
  },
  ORDER: {
    code: "ORDER",
    name: "Vendas",
  },
  NFE: {
    code: "NFE",
    name: "Notas fiscais",
  },
};

class Order {
  constructor(source = {}) {
    if (source == null) {
      return this;
    }

    this.id = source.id;
    this.expirationDate = source.expirationDate;
    this.customer = source.customer;
    this.user = source.user;
    this.userLogged = source.userLogged;
    this.paymentMethod = source.paymentMethod;
    this.orderType = source.orderType;
    this.additionalInformation = source.additionalInformation;
    this.items = source.items;
    this.version = source.version;
    this.createdAt = source.createdAt;
    this.updatedAt = source.updatedAt;
    this.finalizationDate = source.finalizationDate;
    this.orderNumber = source.orderNumber;
    this.discount = source.discount;
    this.total = source.total;
    this.cancelled = source.cancelled;
    this.installments = source.installments;
    this.numberInstallments = source.numberInstallments;
    // this.commissionTypeDefinition = source.commissionTypeDefinition;
    this.received = source.received;
    this.notaFiscal = source.notaFiscal;
    this.difference = source.difference; // Troco
    this.financialAccount = source.financialAccount;
  }
}

export { Order, PaymentMethod, OrderType, OrderTypeFilter };
