import React, { useState, useCallback, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import CardStyle from "assets/jss/material-dashboard-react/components/cardStyle";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import Button from "components/CustomButtons/Button";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import CardFooter from "components/Card/CardFooter";
import { useSnackbar } from "notistack";
import {
  CardContent,
  Divider,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  Switch,
  Typography,
} from "@mui/material";
import { primaryColor } from "assets/jss/material-dashboard-react";
import moment from "moment";
import { handlingResponseErrors } from "../../config/util";
import MESSAGES from "../../config/messages";
import Loading from "../../components/loading/Loading";
import NotaFiscalConfigService from "./service/NotaFiscalConfigService";
import TextInput from "../../components/textInput/TextInput";

const { generalMessages } = MESSAGES;

const useCardStyle = makeStyles(CardStyle);

// eslint-disable-next-line react/prop-types
export default function NotaFiscalConfigForm() {
  const classesCardStyle = useCardStyle();

  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [entity, setEntity] = useState({});
  const [errors, setErrors] = useState({});

  const handleChange = useCallback(
    (field, value) => {
      setEntity({
        ...entity,
        [field]: value,
      });
    },
    [entity]
  );

  const onSave = useCallback(async () => {
    try {
      setLoading(true);
      const { id } = entity;
      if (id != null) {
        await NotaFiscalConfigService.update(id, entity);
      } else {
        await NotaFiscalConfigService.save(entity);
      }
      enqueueSnackbar(generalMessages.saveSuccess, {
        variant: "success",
        autoHideDuration: 3000,
      });
    } catch (error) {
      const { message, validationErrors } = handlingResponseErrors(error);
      enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
      if (validationErrors != null) {
        setErrors(validationErrors);
      }
    } finally {
      setLoading(false);
    }
  }, [entity, enqueueSnackbar]);

  const fetch = useCallback(async () => {
    setLoading(true);
    try {
      const result = await NotaFiscalConfigService.fetch();
      const { data: dataResult } = result;
      const { data } = dataResult;

      setEntity(data);
    } catch (error) {
      enqueueSnackbar(generalMessages.error, {
        variant: "error",
      });
    } finally {
      setLoading(false);
    }
  }, [enqueueSnackbar]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  const renderCard = ({ option, title, text, field }) => (
    <Card
      style={{
        cursor: "pointer",
        border: `solid 1px ${entity[field] === option ? primaryColor[0] : "#ffffff"}`,
      }}
      onClick={() => handleChange(field, option)}
    >
      <CardContent>
        <Typography variant="h5" component="div">
          <Radio checked={entity[field] === option} />
          <FormLabel>{title}</FormLabel>
        </Typography>
        <Typography sx={{ mb: 1.5 }} color="text.secondary">
          {text}
        </Typography>
      </CardContent>
    </Card>
  );

  const getDataUltimaImportacao = useCallback(() => {
    const dateFormat = "DD/MM/yyyy HH:mm";

    return entity.dataUltimaImportacao
      ? moment(entity.dataUltimaImportacao).format(dateFormat)
      : "Nenhuma importação registrada";
  }, [entity.dataUltimaImportacao]);

  return (
    <>
      <GridContainer style={{ justifyContent: "center" }}>
        <GridItem xs={12} sm={12} md={10}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classesCardStyle.cardTitle}>Configuração de Nota Fiscal</h4>
              <p className={classesCardStyle.cardCategory}>
                Configuração da nota fiscal eletrônica
              </p>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <Typography
                    style={{ fontSize: 16, fontWeight: 500, marginBottom: -20, marginTop: 20 }}
                  >
                    Ambiente
                  </Typography>
                </GridItem>

                <GridItem xs={12} sm={12} md={6}>
                  {renderCard({
                    option: "PRODUCAO",
                    title: "Produção",
                    text: "Emissão de notas fiscais com validade jurídica",
                    field: "env",
                  })}
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  {renderCard({
                    option: "HOMOLOGACAO",
                    title: "Homologação",
                    text: "Emissão de notas fiscais no ambiente de testes",
                    field: "env",
                  })}
                </GridItem>

                <Divider style={{ width: "100%", marginBottom: 20 }} />

                <GridItem xs={12} sm={12} md={12}>
                  <Typography style={{ fontSize: 16, fontWeight: 500, marginBottom: -20 }}>
                    Importação automática
                    <div style={{ display: "inline-flex" }}>
                      <Typography style={{ fontSize: 12, fontWeight: 500 }}>
                        &nbsp;&nbsp;&nbsp;(Data última importação: &nbsp;
                      </Typography>
                      <Typography style={{ fontSize: 12 }}>{getDataUltimaImportacao()})</Typography>
                    </div>
                  </Typography>
                </GridItem>

                <GridItem xs={12} sm={12} md={6}>
                  {renderCard({
                    option: true,
                    title: "Sim",
                    text: "Realizar a importação das notas automaticamente",
                    field: "importacaoAutomatica",
                  })}
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  {renderCard({
                    option: false,
                    title: "Não",
                    text: "Desabilita a importação das notas automaticamente",
                    field: "importacaoAutomatica",
                  })}
                </GridItem>

                <Divider style={{ width: "100%", marginBottom: 20 }} />

                <GridItem xs={12} sm={12} md={12}>
                  <Typography style={{ fontSize: 16, fontWeight: 500 }}>
                    Configuirações da NFCe
                  </Typography>
                </GridItem>

                <GridItem xs={12} sm={12} md={5}>
                  <TextInput
                    maxLength={36}
                    autoFocus
                    id="tokenNfce"
                    label="Token NFCe"
                    value={entity.tokenNfce}
                    onChange={handleChange}
                    required
                    errors={errors}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  <TextInput
                    maxLength={6}
                    id="idTokenNfce"
                    label="ID Token NFCe"
                    value={entity.idTokenNfce}
                    onChange={handleChange}
                    required
                    errors={errors}
                  />
                </GridItem>

                <Divider style={{ width: "100%", marginBottom: 20, marginTop: 20 }} />

                <GridItem xs={12} sm={12} md={12}>
                  <Typography style={{ fontSize: 16, fontWeight: 500 }}>
                    Configuração Série
                  </Typography>
                </GridItem>

                <GridContainer xs={12} sm={12} md={12}>
                  <GridItem
                    style={{ alignItems: "center", display: "flex", marginTop: 6, width: "80px" }}
                  >
                    <Typography style={{ fontSize: 13 }}>NFe:</Typography>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    <TextInput
                      autoFocus
                      id="serieNfe"
                      label="Série NFe"
                      value={entity.serieNfe}
                      onChange={handleChange}
                      required
                      errors={errors}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    <TextInput
                      id="numeroInicialNfe"
                      label="Número da primeira NFe"
                      value={entity.numeroInicialNfe}
                      onChange={handleChange}
                      required
                      errors={errors}
                    />
                  </GridItem>
                </GridContainer>

                <GridContainer xs={12} sm={12} md={12}>
                  <GridItem
                    style={{ alignItems: "center", display: "flex", marginTop: 6, width: "80px" }}
                  >
                    <Typography style={{ fontSize: 13 }}>NFCe:</Typography>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    <TextInput
                      autoFocus
                      id="serieNfce"
                      label="Série NFce"
                      value={entity.serieNfce}
                      onChange={handleChange}
                      required
                      errors={errors}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    <TextInput
                      id="numeroInicialNfce"
                      label="Número da primeira NFce"
                      value={entity.numeroInicialNfce}
                      onChange={handleChange}
                      required
                      errors={errors}
                    />
                  </GridItem>
                </GridContainer>
              </GridContainer>
            </CardBody>
            <CardFooter>
              <Button color="primary" onClick={onSave}>
                {generalMessages.save}
              </Button>
            </CardFooter>
          </Card>
        </GridItem>
        <Loading loading={loading} />
      </GridContainer>
    </>
  );
}
