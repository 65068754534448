import React from "react";
import { makeStyles } from "@mui/styles";
import { Box, Divider, Typography } from "@mui/material";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import CardStyle from "assets/jss/material-dashboard-react/components/cardStyle";
import YoutubeEmbed from "components/youtubeEmbed/YoutubeEmbed";

const useCardStyle = makeStyles(CardStyle);

export default function Support() {
  const classesuseCardStyle = useCardStyle();

  const renderVideo = (title, embedId) => (
    <GridItem xs={12} sm={12} md={6} style={{ marginBottom: 20 }}>
      <Box flexDirection="column">
        <Box display="flex" justifyContent="center">
          <Typography inline align="left" style={{ fontSize: 18, fontWeight: 500 }}>
            {title}
          </Typography>
        </Box>
        <Box display="flex" justifyContent="center">
          <YoutubeEmbed embedId={embedId} />
        </Box>
      </Box>
    </GridItem>
  );
  return (
    <GridContainer style={{ justifyContent: "center" }}>
      <GridItem xs={12} sm={12} md={11}>
        <Card>
          <CardHeader color="primary">
            <h4 className={classesuseCardStyle.cardTitleList}>Treinamentos Kairum Sistemas</h4>
            <p className={classesuseCardStyle.cardCategoryList}>Materiais de suporte</p>
          </CardHeader>

          <CardBody>
            <GridContainer justifyContent="space-between">
              {[
                renderVideo("Sejam bem vindos a Kairum Sistemas", "NNXhPe6cdyU"),
                renderVideo("Apresentação do sistema", "rpr8Lpsdhw4"),
                renderVideo("Como realizar login em sua conta Kairum", "Utqq956ZnnU"),
                renderVideo("Cadastro de dados de sua empresa", "RofyU8R83l4"),
                renderVideo("Explicação dos Dashboards", "_8FgEyUVpIc"),
                renderVideo("Pesquisa de preço de produto", "CfLGxQV3zdE"),
                renderVideo("Cadastros de clientes, fornecedores e transportadora", "jh6_uQ7oIqs"),
                renderVideo("Cadastro de produtos", "HyUYnOth8t4"),
                renderVideo("Cadastro de pagamento de despesas", "zWb-8DEFBEY"),
                renderVideo("Cadastro de serviços", "amwbiEBtvS8"),
                renderVideo("Cadastro de forma de pagamento", "DfPdqiyR0nM"),
                renderVideo("Agenda", "haBb19M1yPs"),
                renderVideo("Realizando uma venda", "AhTUVGf1c5Y"),
                renderVideo("Realizando uma ordem de serviço", "O96d3Avjl_A"),
                renderVideo("Cancelamento de venda", "XNKdUGNxQ58"),
                renderVideo("Cadastro de usuários e contador", "dRaHnG6-wbE"),
              ]}
            </GridContainer>
            <Divider style={{ width: "100%", marginBottom: 4 }} />
            <Box display="flex" justifyContent="center">
              <Typography inline align="left" style={{ fontSize: 18, fontWeight: 500 }}>
                Novos treinamentos em breve!
              </Typography>
            </Box>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
