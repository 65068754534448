import React, { useCallback } from "react";
import { Box, Typography, Link as LinkMUI } from "@mui/material";
import { Link } from "react-router-dom";

export default function PlanTopMessage({ plan }) {
  const { freePlanEndDays, freePlan, expiredPlan, expiredDaysAgo } = plan;

  const renderContainer = useCallback(
    (children) => (
      <Box>
        <Box
          display="flex"
          justifyContent="center"
          style={{
            width: "100%",
            background: "linear-gradient(60deg, rgb(210 221 41 / 50%), rgb(251 237 32 / 50%))",
            boxShadow: "0 4px 20px 0 rgb(0 0 0 / 14%), 0 7px 10px -5px rgb(183 187 45 / 40%)",
            borderRadius: 5,
          }}
        >
          {children}
        </Box>
      </Box>
    ),
    []
  );

  const renderFreePlan = useCallback(() => {
    let text = "";
    if (freePlanEndDays === 0) {
      text = (
        <span>
          Seu tempo para experimentar a Kairum termina <b>hoje</b>.
        </span>
      );
    } else {
      text = (
        <span>
          Você tem <b>{freePlanEndDays} dia(s)</b> para experimentar a Kairum.
        </span>
      );
    }

    return (
      <Typography style={{ fontSize: 14, color: "#000", padding: 10 }}>
        {text} <Link to="/app/my-plan">Assine Já!</Link>
      </Typography>
    );
  }, [freePlanEndDays]);

  const planRegularize = useCallback(() => {
    const { urlMyPlanStripe } = plan;
    const { url } = urlMyPlanStripe;
    window.open(url, "_blank");
  }, [plan]);

  const renderExpiredPlan = useCallback(() => {
    let text = "";
    if (expiredDaysAgo === 7) {
      text = (
        <span>
          <b>Hoje</b> é o último dia para regularizar seu plano e evitar o bloqueio do sistema.
        </span>
      );
    } else {
      text = (
        <span>
          Seu plano está expirado há <b>{expiredDaysAgo} dia(s)</b>.
        </span>
      );
    }

    return (
      <Typography style={{ fontSize: 14, color: "#000", padding: 10 }}>
        {text}{" "}
        <LinkMUI
          size="small"
          onClick={planRegularize}
          style={{ cursor: "pointer", color: "#9c27b0", textDecoration: "none" }}
        >
          Regularize Já!
        </LinkMUI>
      </Typography>
    );
  }, [expiredDaysAgo, planRegularize]);

  const renderContent = useCallback(() => {
    if (freePlan) {
      return renderContainer(renderFreePlan());
    }
    if (expiredPlan && expiredDaysAgo >= 1) {
      return renderContainer(renderExpiredPlan());
    }
    return null;
  }, [expiredDaysAgo, expiredPlan, freePlan, renderContainer, renderExpiredPlan, renderFreePlan]);

  return renderContent();
}
