/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useCallback } from "react";
import { makeStyles } from "@mui/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import EditIcon from "components/icons/EditIcon";
import DeleteIcon from "components/icons/DeleteIcon";
import { Divider, Typography } from "@mui/material";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import {
  NFOrigem,
  SituacaoOperacionalSimplesNacional,
  TipoExcecao,
  SituacaoTributariaIpi,
  SituacaoTributariaIssqn,
  SituacaoTributariaPis,
  SituacaoTributariaCofins,
  SituacaoTributariaIcms,
} from "../model/NaturezaOperacao";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

const NOExceptionTable = ({ exceptions = [], onEdit, onDelete, tab }) => {
  const classes = useStyles();

  const getSituacaoTributaria = useCallback(
    (item) => {
      const {
        situacaoOperacaoSnSimples,
        situacaoTributariaPis,
        situacaoTributariaIssqn,
        situacaoTributariaIpi,
        situacaoTributariaIcms,
        situacaoTributariaCofins,
      } = item;
      switch (tab) {
        case TipoExcecao.SIMPLES: {
          return situacaoOperacaoSnSimples
            ? SituacaoOperacionalSimplesNacional[item.situacaoOperacaoSnSimples].name
            : "";
        }
        case TipoExcecao.IPI:
          return situacaoTributariaIpi
            ? SituacaoTributariaIpi[item.situacaoTributariaIpi].name
            : "";
        case TipoExcecao.ISSQN:
          return situacaoTributariaIssqn
            ? SituacaoTributariaIssqn[item.situacaoTributariaIssqn].name
            : "";
        case TipoExcecao.PIS:
          return situacaoTributariaPis
            ? SituacaoTributariaPis[item.situacaoTributariaPis].name
            : "";
        case TipoExcecao.COFINS:
          return situacaoTributariaCofins
            ? SituacaoTributariaCofins[item.situacaoTributariaCofins].name
            : "";
        case TipoExcecao.ICMS:
          return situacaoTributariaIcms
            ? SituacaoTributariaIcms[item.situacaoTributariaIcms].name
            : "";
        default:
          return "";
      }
    },
    [tab]
  );

  return (
    <GridContainer>
      <Typography style={{ fontSize: 18, fontWeight: 500 }}>
        Exceções ({exceptions.length})
      </Typography>

      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Destino(s)</TableCell>
              <TableCell style={{ width: "50%" }}>Produto(s)</TableCell>
              {tab === TipoExcecao.SIMPLES && <TableCell>CFOP</TableCell>}
              <TableCell>Situação tributária</TableCell>
              <TableCell align="right">Ações</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {exceptions.map((item) => {
              const ufEstados = item?.estados.map((e) => e.uf).join(", ");
              const origens = item?.origens
                .map((o) => `${NFOrigem[o].code} - ${NFOrigem[o].name}`)
                .join(", ");
              const produtos = item?.produtos.map((p) => p.name).join(", ");

              const situacaoTrib = getSituacaoTributaria(item);
              return (
                <TableRow>
                  <TableCell component="th" scope="row">
                    {ufEstados}
                  </TableCell>
                  <TableCell style={{ width: "50%" }} align="left">
                    {origens.length > 0 && (
                      <span>
                        <b>Origens: </b>
                        {origens}
                      </span>
                    )}
                    {produtos.length > 0 && (
                      <span>
                        {origens.length > 0 && (
                          <span>
                            <br />
                          </span>
                        )}

                        <b>Produtos: </b>
                        {produtos}
                      </span>
                    )}
                  </TableCell>
                  {tab === TipoExcecao.SIMPLES && (
                    <TableCell align="left">{item?.cfopSimples || ""}</TableCell>
                  )}
                  <TableCell align="left">{situacaoTrib}</TableCell>
                  <TableCell align="right">
                    <div
                      style={{
                        cursor: "pointer",
                        justifyContent: "flex-end",
                        padding: "5px",
                        display: "flex",
                        flex: 1,
                      }}
                    >
                      <EditIcon onClick={() => onEdit(item.index)} style={{ paddingRight: 10 }} />
                      <DeleteIcon onClick={() => onDelete(item.index)} />
                    </div>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </GridContainer>
  );
};

export default NOExceptionTable;
