/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useContext, useEffect, useState } from "react";
import { Switch, Route, Redirect, useHistory, useLocation } from "react-router-dom";

import "perfect-scrollbar/css/perfect-scrollbar.css";

import { makeStyles } from "@mui/styles";
// core components
import Navbar from "components/Navbars/Navbar";
import Sidebar from "components/Sidebar/Sidebar";
import routes from "routes.js";
import styles from "assets/jss/material-dashboard-react/layouts/adminStyle";
import bgImage from "assets/img/sidebar-2.jpg";
import logo from "assets/img/reactlogo.png";
import { isMobile } from "../config/util";
import UserContext from "../core/UserContext";
import LoginService from "../views/login/service/LoginService";
import { MODULES } from "../config/constants";

const useStyles = makeStyles(styles);

const switchRoutes = (
  <Switch>
    {routes.map((prop, key) => {
      if (prop.layout === "/app") {
        if (prop.menus) {
          return prop.menus.map((propMenu, keyMenu) => (
            <Route
              path={propMenu.layout + propMenu.path}
              component={propMenu.component}
              key={keyMenu}
            />
          ));
        }
        return <Route path={prop.layout + prop.path} component={prop.component} key={key} />;
      }
      return null;
    })}
    <Redirect from="/app" to="/app/orders" />
  </Switch>
);

export default function App({ ...rest }) {
  const { userLogged, setUserLogged } = useContext(UserContext);
  const [plansRoutes, setPlansRoutes] = useState([]);
  const history = useHistory();
  const location = useLocation();

  // ref to help us initialize PerfectScrollbar on windows devices
  const mainPanel = React.createRef();
  // states and functions
  const [image] = React.useState(bgImage);
  const [color] = React.useState("purple");
  // If mobile the menu is started close
  const [openMenu, setOpenMenu] = React.useState(!isMobile());
  const classes = useStyles({ openMenu });

  const handleDrawerToggle = () => {
    setOpenMenu(!openMenu);
  };

  const closeMenuOnClickMobile = () => {
    if (isMobile()) {
      setTimeout(() => {
        setOpenMenu(false);
        // It's waiting for the menu to close to avoid bug on the menu animation
      }, 800);
    }
  };

  const getNameCompany = () => (userLogged == null ? "" : userLogged.companyName);

  const checkNavigationPermission = useCallback(
    (modules, routeLocation) => {
      // console.log(">>>>>>>>>>>>> checkNavigationPermission");
      let url = routeLocation.pathname;
      // Remove UUID. Ex: '/app/user/8e38fec1-0326-4e81-af05-3cf6510c36ab'
      url = url.replace(/\/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/, "");
      const module = MODULES[url];
      const locked = !modules.includes(module);

      if (url !== "/app/forbidden" && locked) {
        history.push("/app/forbidden");
      }
    },
    [history]
  );

  useEffect(() => {
    if (userLogged != null) {
      const { modules } = userLogged;
      checkNavigationPermission(modules, location);

      return history.listen((routeLocation) => {
        checkNavigationPermission(modules, routeLocation);
        // console.log("Location:", routeLocation.pathname);

        // Close menu on mobile after clicked
        closeMenuOnClickMobile();
      });
    }
    return null;
  }, [checkNavigationPermission, history, location, location.pathname, userLogged]);

  const buildMenuPermission = (route, modules) => {
    // console.log(">>>>>>>>>>>>> buildMenuPermission");
    const { layout, path } = route;
    const url = `${layout}${path}`;
    const module = MODULES[url];
    const locked = !modules.includes(module);
    return {
      ...route,
      locked,
    };
  };

  const prepareRoutes = useCallback(async () => {
    // console.log(">>>>>>>>>>>>> prepareRoutes");
    if (userLogged == null) {
      const me = await LoginService.me();
      const userMe = me?.data || {};
      const isValidPlan = userMe.plan?.validPlan || false;
      if (!isValidPlan && history.location.pathname !== "/app/company") {
        history.push({
          pathname: "/check-in",
          // state: { userMe },
        });
      } else {
        setUserLogged(userMe);
      }
    } else {
      const { modules } = userLogged;

      let filteredRoutes = routes.filter(({ hideOnMenu }) => !hideOnMenu);

      filteredRoutes = filteredRoutes.map((route) => buildMenuPermission(route, modules));

      filteredRoutes = filteredRoutes.map((route) => {
        let { menus } = route;
        if (menus) {
          menus = menus.filter(({ hideOnMenu }) => !hideOnMenu);

          menus = menus.map((routeMenu) => buildMenuPermission(routeMenu, modules));
        }

        return { ...route, menus };
      });

      setPlansRoutes(filteredRoutes);
    }
  }, [userLogged, history, setUserLogged]);

  useEffect(() => {
    prepareRoutes();
  }, [userLogged, prepareRoutes]);

  return (
    <div className={classes.wrapper}>
      <Sidebar
        routes={plansRoutes}
        logoText={getNameCompany()}
        logo={logo}
        image={image}
        handleDrawerToggle={handleDrawerToggle}
        open={openMenu}
        color={color}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...rest}
      />
      <div className={classes.mainPanel} ref={mainPanel}>
        <Navbar handleDrawerToggle={handleDrawerToggle} {...rest} />
        <div className={classes.content}>
          <div className={classes.container}>{switchRoutes}</div>
        </div>
      </div>
    </div>
  );
}
