const RegistrationType = {
  CUSTOMER: {
    code: "CUSTOMER",
    name: "Cliente",
  },
  SUPPLIER: {
    code: "SUPPLIER",
    name: "Fornecedor",
  },
  CARRIER: {
    code: "CARRIER",
    name: "Transportadora",
  },
};

const PersonType = {
  PF: {
    code: "PF",
    name: "Pessoa Física",
  },
  PJ: {
    code: "PJ",
    name: "Pessoa Jurídica",
  },
};

const GenderType = {
  M: {
    // Man
    code: "M",
    name: "Masculino",
  },
  W: {
    // Woman
    code: "W",
    name: "Feminino",
  },
  U: {
    // Undefined
    code: "U",
    name: "Prefiro não informar",
  },
};

class Person {
  constructor(source = {}) {
    if (source == null) return this;

    this.id = source.id;
    this.code = source.code;
    // PF fields
    this.cpf = source.cpf;
    this.rg = source.rg;
    this.genderType = source.genderType;
    this.birthday = source.birthday;

    // PJ fields
    this.tradingName = source.tradingName;
    this.cnpj = source.cnpj;
    this.stateInscription = source.stateInscription;
    this.foundationDate = source.foundationDate;

    // PF and PJ fields
    this.name = source.name;
    this.personType = source.personType;
    this.phone = source.phone;
    this.mobile = source.mobile;
    this.email = source.email;
    this.state = source.state;
    this.city = source.city;
    this.street = source.street;
    this.number = source.number;
    this.complement = source.complement;
    this.zipCode = source.zipCode;
    this.neighborhood = source.neighborhood;
    this.addressId = source.addressId;
    this.addressVersion = source.addressVersion;
    this.inscricaoEstadualSubstitutoTributario = source.inscricaoEstadualSubstitutoTributario;
    this.inscricaoEstadual = source.inscricaoEstadual;
    this.registrationType = source.registrationType;
    this.version = source.version;
    this.standard = source.standard;
    this.active = source.active;
    this.taxpayerType = source.taxpayerType;
  }
}

export { Person, PersonType, GenderType, RegistrationType };
