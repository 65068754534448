const Style = {
  row: {
    color: "#000000",
    cursor: "pointer",
    borderBottom: "1px solid #ccc",
    // padding: "5px 3px",
  },

  focusedRow: {
    color: "white",
    backgroundColor: "#8f26ab",
    borderRadius: 5,
  },
};

export default Style;
