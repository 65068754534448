const MESSAGES = {
  paymentMethodMessages: {
    payment: "Forma de pagamento",
    payments: "Formas de pagamento",
    paymentsTable: "Lista de formas de pagamento cadastrados",
    paymentForm: "Cadastro de novas formas de pagamento",
    name: "Nome",
    creditCard: "Cartão de crédito",
    anticipatedReceipt: "Antecipa parcelas",
    anticipatedDays: "Antecipa dias",
    amountDays: "Quantidade de dias",
    allowsInstallments: "Usa parcelamento",
    maximumAmountInstallments: "Máximo de parcelas",
    standard: "Padrão",
    active: "Ativo",
    searchbar: "Digite para pesquisar por Nome ...",
  },
  installmentsMessages: {
    date: "Data",
    value: "Valor",
    maximumAmountInstallments: (num) => `Essa condição permite no máximo ${num} parcelas!`,
    total: (type) => `Total ${type}`,
    totalInstallments: "Total das parcelas",
    divideInstallmentCorrectly: "Dividir parcelas uniformemente",
    wrongDivision: (value, type) =>
      `A soma das parcelas ${value}  está divergente do valor total da ${type}`,
  },
  generalMessages: {
    saveSuccess: "Feito! Seu registro foi salvo com sucesso!",
    error: "Não foi possível realizar a operação. Tente novamente em alguns instantes!",
    validations: "Campos obrigatórios não preenchidos!",
    fieldValidations: "Campo obrigatório",
    deleteSuccess: "Feito! Seu registro foi removido com sucesso!",
    deleteConfirm: "Tem certeza que deseja remover o registro?",
    new: "Novo",
    save: "Salvar",
    cancel: "Cancelar",
    welcome: "Olá, vamos começar...",
    firstUser: "Insira os dados do responsável pela empresa:",
    firstCompany: "Agora, vamos cadastrar sua empresa:",
    startSystem: "Criar conta",
    emailOrPassword: "E-mail ou senha não estão válidos!",
    userEmployee: "Funcionário sem acesso. Entre em contato com o administrador do sistema.",
    actions: "Ações",
    search: "Pesquisar",
    code: "Código",
    error_keycloak: "E-mail já em uso no sistema.",
    add: "Adicionar",
    autoSave: "Pressione ENTER para salvar",
    barCodeText: "Código de barras",
    productNoAdded: "Produto/Serviço não encontrado.",
    paymentMethod: "Forma de pagamento",
    amountInstallments: "Quantidade de parcelas",
    barCodePlaceholder: "Utilize o leitor para pesquisar",
    PERIOD_OPTIONS: [
      {
        id: "DAY",
        momentCode: "days",
        value: "Dia",
      },
      {
        id: "WEEK",
        momentCode: "W",
        value: "Semana",
      },
      {
        id: "MONTH",
        momentCode: "M",
        value: "Mês",
      },
      {
        id: "YEAR",
        momentCode: "Y",
        value: "Ano",
      },
    ],
  },
  userMessages: {
    code: "Código",
    user: "Usuário",
    users: "Usuário",
    usersTable: "Lista de usuários cadastrados",
    userForm: "Cadastro de novos usuários",
    name: "Nome",
    email: "E-mail",
    password: "Senha",
    passwordRepeat: "Confirmar senha",
    roles: "Perfil",
    searchbar: "Digite para pesquisar por Nome ou E-mail ...",
    productCommission: "Comissão nos Produtos",
    serviceCommission: "Comissão nos Serviços",
  },
  personMessages: {
    customer: "Pessoa",
    customers: "Pessoas",
    customersTable: "Lista de clientes, transportadoras e fornecedores cadastrados",
    customerForm: "Cadastro de novos clientes, transportadoras e fornecedores",
    name: "Nome",
    personType: "Tipo de pessoa",
    cpf: "CPF",
    rg: "RG",
    gender: "Gênero",
    phone: "Contato 1",
    mobile: "Contato 2",
    email: "E-mail",
    zipCode: "CEP",
    state: "Estado",
    city: "Cidade",
    street: "Rua",
    number: "Número",
    neighborhood: "Bairro",
    complement: "Complemento",
    discount: "Desconto (%)",
    foundationDate: "Data de fundação",
    companyName: "Razão social",
    tradingName: "Nome fantasia",
    cnpj: "CNPJ",
    birthday: "Data de nascimento",
    searchbar: "Digite para pesquisar por Nome, Nome fantasia, CPF ou CNPJ ...",
    pfsTab: "Físicas",
    pjsTab: "Jurídicas",
  },
  customerMessages: {
    customer: "Cliente",
    customers: "Clientes",
    customersTable: "Lista de clientes cadastrados",
    customerForm: "Cadastro de novos clientes",
    name: "Nome",
    personType: "Tipo de pessoa",
    cpf: "CPF",
    rg: "RG",
    gender: "Gênero",
    phone: "Contato 1",
    mobile: "Contato 2",
    email: "E-mail",
    zipCode: "CEP",
    state: "Estado",
    city: "Cidade",
    street: "Rua",
    number: "Número",
    neighborhood: "Bairro",
    complement: "Complemento",
    discount: "Desconto (%)",
    foundationDate: "Data de fundação",
    companyName: "Razão social",
    tradingName: "Nome fantasia",
    cnpj: "CNPJ",
    birthday: "Data de nascimento",
    searchbar: "Digite para pesquisar por Nome, Nome fantasia, CPF ou CNPJ ...",
    pfsTab: "Físicas",
    pjsTab: "Jurídicas",
  },
  naturezaOperacaoMessages: {
    naturezaOperacao: "Natureza de operação",
    naturezasOperacao: "Naturezas de operação",
    naturezaOperacaoTable: "Lista de naturezas de operação cadastradas",
    naturezaOperacaoForm: "Cadastro de novas naturezas de operação",
    descricao: "Descrição",
    series: "Série Nota Fiscal",
    autocompleteSeries: "Digite para pesquisar as séries ...",
    searchbar: "Digite para pesquisar por Descrição ...",
    finalidade: "Finalidade",
    observacaoNaturezaOperacao: "Observações para notas desta operação",
    simples: {
      simples: "Simples",
      situacaoOperacaoSnSimples: "Código de situação da operação no Simples nacional (CSOSN)",
      partilhaIcmsInterestadualSimples: "Partilha ICMS Interestadual",
      aliquotaCalculoCreditoSimples: "Alíquota Apl. de Cál. do Crédito",
      observacaoSimples: "Observação do Simples",
      aliquotaIcmsSimples: "Alíquota ICMS",
      baseIcmsSimples: "Base ICMS",
      cfopSimples: "Código fiscal da operação cfop",
      autocompleteCfopSimples: "Digite para pesquisar o CFOPs ...",
      aliquotaIcmsEfetivoStSimples: "Alíquota ICMS Efetivo",
      aliquotaIcmsRetidoStSimples: "Alíquota ICMS Retido",
      aliquotaIcmsStSimples: "Alíquota ICMS",
      baseCalculoIcmsStSimples: "Base de Cálculo ICMS",
      baseCalculoIcmsRetidoStSimples: "Base de Cálculo ICMS Retido",
      baseIcmsEfetivoStSimples: "Base ICMS Efetivo",
      margemAdicionalIcmsStSimples: "Margem Adic.ICMS",
      aliquotaFcpIcmsStSimples: "Alíquota FCP ICMS-ST",
    },
    ipi: {
      aliquotaIpi: "Alíquota",
      codigoEnquadramentoIpi: "Código de enquadramento",
      autocompleteCodigoEnquadramentoIpi: "Digite para pesquisar os códigos do enquadramento ...",
      observacaoIpi: "Observações do IPI",
      situacaoTributariaIpi: "Situação tributária (CST)",
      ipi: "IPI",
      valorIpi: "Valor por unidade",
    },
    issqn: {
      issqn: "ISSQN",
      situacaoTributariaIssqn: "Situação tributária (CST)",
      aliquotaIssqn: "Alíquota",
      baseCalculoIssqn: "Base",
      descontarIssIssqn: "Descontar ISS",
      observacaoIssqn: "Observações ISSQN",
    },
    pis: {
      pis: "PIS",
      situacaoTributariaPis: "Situação tributária (CST)",
      aliquotaPis: "Alíquota",
      baseCalculoPis: "Base",
      valorPis: "Valor unitário",
      observacaoPis: "Observações PIS",
    },
    cofins: {
      cofins: "COFINS",
      situacaoTributariaCofins: "Situação tributária (CST)",
      aliquotaCofins: "Alíquota",
      valorCofins: "Valor unitário",
      baseCalculoCofins: "Base",
      observacaoCofins: "Observações COFINS",
    },
    icms: {
      icms: "ICMS",
      situacaoTributariaIcms: "Situação tributária (CST)",
      cfopIcms: "Código fiscal da operação cfop",
      autocompleteCfopIcms: "Digite para pesquisar o CFOPs ...",
      aliquotaIcms: "Alíquota",
      partilhaIcmsInterestadualIcms: "Partilha ICMS Interestadual",
      aliquotaFcpIcms: "Alíquota FCP",
      observacaoIcms: "Observações do ICMS",
      aliquotaIcmsStIcms: "Alíquota ICMS-ST",
      baseCalculoIcmsStIcms: "Base de Cálculo ICMS-ST",
      mvaIvaIcms: "MVA / IVA",
      aliquotaFcpIcmsStIcms: "Alíquota FCP ICMS-ST",
      aliquotaPisIcms: "Alíquota PIS",
      aliquotaCofinsIcms: "Alíquota COFINS",
      baseIcms: "Base",
      baseDiferimentoIcms: "Base Diferimento",
      presumidoIcms: "Presumido",
      aliquotaIcmsEfetivoIcms: "Alíquota ICMS efetivo",
      baseIcmsEfetivoIcms: "Base ICMS efetivo",
      obterIcmsStRetidoAnteriormenteNfCompraIcms:
        "Obter ICMS-ST retido anteriormente a partir de nota fiscal de compra",
    },
    exceptions: {
      product: "Produto",
      autocompleteProduct: "Digite para pesquisar os produtos ...",
      ncm: "NCM",
      estados: "Estados",
      origens: "Origens",
      autocompleteNcm: "Digite para pesquisar os NCMs ...",
      confirm: "Confirmar",
      cancel: "Cancelar",
      delete: "Tem certeza que deseja remover a exceção?",
      productDelete: "Tem certeza que deseja remover o produto?",
    },
    advanced: {
      regimeTributario: "Código de regime tributário",
      operacaoConsumidorFinal: "Consumidor final",
      considerarComoReceitaNoDre: "Considerar como receita no DRE",
      considerarCvmNoDre: "Considerar CMV no DRE",
      compraDeProdutorRural: "Compra de produtor rural",
      permiteLancamentoDeEstoque: "Permite lançamento e reserva de estoque ",
      aliquotaFunrural: "Alíquota funrural",
      descontarFunruralDoTotalFaturado: "Descontar funrural do total faturado",
      vendaParaZonaFrancaDeManaus: "Venda para Zona Franca de Manaus",
      reducaoDaBaseDeCalculoDoIcmsSt: "Redução da Base de Cálculo do ICMS-ST",
      descontarDesoneracaoDoTotalDaNotaFiscal: "Descontar desoneração do total da nota fiscal",
      itemCompoemValorTotalNfe: "Itens compõem valor total da NF-e",
    },
  },

  supplierMessages: {
    supplier: "Fornecedor",
    suppliers: "Fornecedores",
    suppliersTable: "Lista de fornecedores cadastrados",
    supplierForm: "Cadastro de novos fornecedores",
    name: "Empresa/Nome",
    phone: "Contato 1",
    mobile: "Contato 2",
    email: "E-mail",
    zipCode: "CEP",
    state: "Estado",
    city: "Cidade",
    street: "Rua",
    number: "Número",
    neighborhood: "Bairro",
    complement: "Complemento",
    representative: "Representante",
    cnpj: "CNPJ",
    searchbar: "Digite para pesquisar por Nome, Representante ou CNPJ ...",
  },
  unitMeasurementMessages: {
    unitMeasurement: "Unidade de medida",
    unitsMeasurement: "Unidades de medida",
    unitsMeasurementTable: "Lista de unidades de medida cadastradas",
    unitMeasurementForm: "Cadastro de novas unidades de medida",
    name: "Descrição",
    acronym: "Sigla",
    searchbar: "Digite para pesquisar por Sigla ou Descrição ...",
  },
  digitalCertificate: {
    digitalCertificate: "Certificado digital",
    digitalCertificateForm: "Cadastro de certificado digital",
  },
  companyMessages: {
    companyName: "Razão social",
    companyName2: "Nome da empresa",
    tradingName: "Nome fantasia",
    cnpj: "CNPJ",
    foundationDate: "Data de fundação",
    name: "Nome",
    company: "Empresa",
    companyForm: "Empresa cadastrada",
    contact: "Contato",
    phone: "Contato 1",
    mobile: "Contato 2",
    email: "E-mail",
  },
  commissionMessages: {
    name: "Nome",
    commissionConfig: "Configuração de comissão",
    commissionsConfig: "Configuração de comissões",
    commissionsConfigsTable: "Lista de configurações de comissões cadastradas",
    commissionConfigForm: "Cadastro de novas configurações de comissões",
    startDate: "Data inicial",
    endDate: "Data final",
    serviceCommission: "Comissão nos Serviços",
    productCommission: "Comissão nos Produtos",
    receiptInInstallments: "Receber parcelado",
    onAllSales: "Receber em todas as vendas",
    user: "Profissional",
    active: "Ativo",
    autocompleteUser: "Digite para pesquisar os profissionais ...",
    searchbar: "Digite para pesquisar por profissional ...",
    orderCodeSearch: "Nº da Venda/OS",
    titleCard: "Comissões",
    commissionsTable: "Lista de comissoẽs cadastradas",
    title: "Comissão",
  },
  productMessages: {
    name: "Nome",
    supplier: "Fornecedor",
    industryCode: "Código da industria",
    barCode: "GTIN/EAN (Código de barras)",
    cost: "Custo",
    margin: "Margem de lucro",
    saleValue: "Valor de venda",
    profit: "Lucro bruto",
    inventory: "Estoque",
    priceMinimum: "Valor mínimo de venda",
    saleService: "Valor do serviço",
    additionalInformation: "Informações adicionais",
    product: "Produto",
    products: "Produtos",
    productsAndServices: "Produtos",
    productsTable: "Lista de produtos cadastrados",
    productForm: "Cadastro de novos produtos",
    searchbar: "Digite para pesquisar por Código, Nome ...",
    autocompleteSupplier: "Digite para pesquisar os fornecedores ...",
    localization: "Localização",
    service: "Serviço",
    servicesTab: "Serviços",
    productsTab: "Produtos",
    brand: "Marca",
  },
  orderMessages: {
    budgetNumber: "Número do Orçamento/ Venda",
    close: "Fechar",
    orderCancelled: "Cancelado",
    ordersBudget: "Venda",
    ordersBudgetTable: "Lista de Vendas e Orçamentos",
    customer: "Cliente",
    expirationDate: "Data de validade",
    orderNumber: "Número",
    orderDate: "Data",
    type: "Tipo",
    industryCode: "Código da industria",
    barCode: "Código de barras",
    cost: "Custo",
    saleValue: "Valor de venda",
    profit: "Lucro",
    inventory: "Estoque",
    additionalInformation: "Informações adicionais",
    product: "Produto",
    products: "Produtos",
    productsTable: "Lista de produtos cadastrados",
    order: "Venda",
    budget: "Orçamento",
    ordersTab: "Vendas",
    budgetsTab: "Orçamentos",
    orderBudget: "Venda e Orçamento",
    orderBudgetForm: "Cadastro de novos orçamentos/vendas",
    searchbar: "Digite para pesquisar por Código, Nome ...",
    confirmOrderCancel: "Tem certeza que deseja cancelar a Venda/ Orçamento?",
    confirmOrderFinish: "Tem certeza que deseja finalizar a Venda?",
    date: "Data",
    steps: {
      general: "Dados gerais",
      products: "Itens",
      installments: "Parcelas",
      financial: "Financeiro",
      review: "Revisão",
    },
    buttons: {
      next: "Próximo",
      back: "Voltar",
      finish: "Finalizar",
      saveFinish: "Finalizar venda",
      save: "Salvar orçamento",
      cancel: "Cancelar",
      print: "Imprimir",
    },
    productStep: {
      item: "Item",
      brand: "Marca",
      name: "Name",
      product: "Produto/Serviço",
      autocomplete: "Digite para pesquisar os produtos/serviços ...",
      saleValue: "Valor",
      saleValueAddition: "Valor com acréscimo",
      addition: "Acréscimo (%)",
      discount: "Desconto (%)",
      saleValueDiscount: "Valor com desconto",
      quantity: "Quantidade",
      finalValue: "Valor final",
      updateDate: "Data de atualização",
      confirm: "Confirmar",
      cancel: "Cancelar",
      orders: "Orçamentos/ Vendas",
      ordersTable: "Lista de Orçamentos/ Vendas cadastrados",
      searchbar: "Digite para pesquisar ...",
      deleteProduct: "Tem certeza que deseja remover o produto do carrinho?",
      productAddedOnCart: "Esse produto já está adicionado no carrinho!",
      emptyCart: "Adicione ao menos 1 item no carrinho!",
      total: "Total",
      generalDiscount: "Desconto geral",
      saleValueLMinimum: "Não é permitido aplicar um preço de venda menor que o preço mínimo",
      minimumInventory: "A quantidade informada é maior do que a disponível em estoque",
    },
    generalStep: {
      expirationDate: "Data de validade",
      customer: "Cliente",
      saller: "Profissional",
      paymentMethod: "Forma de pagamento",
      additionalInformation: "Informações adicionais",
      autocompleteCustomer: "Digite para pesquisar os clientes ...",
      autocompleteSaller: "Digite para pesquisar os vendedores ...",
      commissionTypeByUser: "Itens de comissão do profissional",
    },
    overviewStep: {
      total: "Total",
      generalDiscount: "Desconto geral",
      totalWithGeneralDiscount: "Total com desconto",
    },
  },
  serviceOrderMessages: {
    budgetNumber: "Número do Orçamento/ OS",
    close: "Fechar",
    orderCancelled: "Cancelado",
    ordersBudget: "Ordem de serviço",
    ordersBudgetTable: "Lista de Ordens de serviço e Orçamentos",
    customer: "Cliente",
    expirationDate: "Data de validade",
    orderNumber: "Número",
    orderDate: "Data",
    type: "Tipo",
    industryCode: "Código da industria",
    barCode: "Código de barras",
    cost: "Custo",
    saleValue: "Valor de venda",
    profit: "Lucro",
    inventory: "Estoque",
    additionalInformation: "Informações adicionais",
    product: "Produto",
    products: "Produtos",
    productsTable: "Lista de produtos cadastrados",
    order: "Venda",
    budget: "Orçamento",
    ordersTab: "Ordens de serviço",
    budgetsTab: "Orçamentos",
    orderBudget: "Ordem de serviço e Orçamento",
    orderBudgetForm: "Cadastro de novos orçamentos/vendas",
    searchbar: "Digite para pesquisar por Código, Nome ...",
    confirmOrderCancel: "Tem certeza que deseja cancelar a Ordem de serviço/ Orçamento?",
    confirmOrderFinish: "Tem certeza que deseja finalizar a Ordem de serviço?",
    date: "Data",
    steps: {
      general: "Dados gerais",
      products: "Itens",
      installments: "Parcelas",
      financial: "Financeiro",
      review: "Revisão",
    },
    buttons: {
      next: "Próximo",
      back: "Voltar",
      finish: "Finalizar",
      saveFinish: "Finalizar ordem de serviço",
      save: "Salvar orçamento",
      cancel: "Cancelar",
      print: "Imprimir",
    },
    productStep: {
      item: "Item",
      brand: "Marca",
      name: "Name",
      product: "Produto/Serviço",
      autocomplete: "Digite para pesquisar os produtos/serviços ...",
      saleValue: "Valor",
      saleValueAddition: "Valor com acréscimo",
      addition: "Acréscimo (%)",
      discount: "Desconto (%)",
      saleValueDiscount: "Valor com desconto",
      quantity: "Quantidade",
      finalValue: "Valor final",
      updateDate: "Data de atualização",
      confirm: "Confirmar",
      cancel: "Cancelar",
      orders: "Orçamentos/ Vendas",
      ordersTable: "Lista de Orçamentos/ Vendas cadastrados",
      searchbar: "Digite para pesquisar ...",
      deleteProduct: "Tem certeza que deseja remover o produto do carrinho?",
      productAddedOnCart: "Esse produto já está adicionado no carrinho!",
      emptyCart: "Adicione ao menos 1 item no carrinho!",
      total: "Total",
      generalDiscount: "Desconto geral",
      saleValueLMinimum: "Não é permitido aplicar um preço de venda menor que o preço mínimo",
      minimumInventory: "A quantidade informada é maior do que a disponível em estoque",
    },
    generalStep: {
      expirationDate: "Data de validade",
      customer: "Cliente",
      saller: "Profissional",
      paymentMethod: "Forma de pagamento",
      additionalInformation: "Informações adicionais",
      autocompleteCustomer: "Digite para pesquisar os clientes ...",
      autocompleteSaller: "Digite para pesquisar os vendedores ...",
      commissionTypeByUser: "Itens de comissão do profissional",
    },
    overviewStep: {
      total: "Total",
      generalDiscount: "Desconto geral",
      totalWithGeneralDiscount: "Total com desconto",
    },
  },
  calendarMessages: {
    title: "Evento",
    confirm: "Confirmar",
    cancel: "Cancelar",
    delete: "Remover",
    name: "Nome",
    startDate: "Data início",
    endDate: "Data fim",
  },
  expenseMessages: {
    titleCard: "Despesas",
    expensesTable: "Lista de despesas cadastradas",
    title: "Despesa",
    name: "Nome/Descrição",
    save: "Salvar",
    cancel: "Cancelar",
    delete: "Remover",
    repeat: "Repetir",
    value: "Valor",
    date: "Data",
    startDate: "Data início",
    endDate: "Data fim",
    origin: "Origem",
    paymentStatusType: "Condição",
  },
  receiveMessages: {
    titleCard: "Recebimentos",
    receivesTable: "Lista de recebimentos cadastradas",
    title: "Recebimento",
    customer: "Cliente",
    name: "Descrição",
    save: "Salvar",
    cancel: "Cancelar",
    delete: "Remover",
    repeat: "Repetir",
    value: "Valor",
    date: "Data",
    startDate: "Data início",
    endDate: "Data fim",
    autocompleteCustomer: "Digite para pesquisar os clientes ...",
    customerSearch: "Cliente",
    orderCodeSearch: "Nº da Venda/OS",
    origin: "Origem",
    paymentStatusType: "Condição",
    receivementsPaymentMethod: "Forma de pagamento",
    interest: "Juros",
    receivementsDate: "Data de recebimento",
    receivementsValue: "Valor",
    receivementBtn: "Receber",
    receivementsBtnSave: "Salvar",
    receivementsBtnCancel: "Cancelar",
    receivementsBtnBack: "Voltar",
    receivementsInstall: "Parcelas",
  },
  reportMessages: {
    orderPeriod: "Vendas",
    osPeriod: "Ordens de serviço",
    products: "Estoque",
    commission: "Comissão",
    cancel: "Cancelar",
    confirm: "Gerar relatório",
    startDate: "Data início",
    endDate: "Data fim",
    user: "Profissional",
    autocompleteUser: "Digite para pesquisar os profissionais ...",
    customer: "Cliente",
    autocompleteCustomer: "Digite para pesquisar os clientes ...",
    itensShowSale: "Itens do relatório",
  },
  departamentMessages: {
    titleCard: "Departamentos",
    table: "Lista de departamentos cadastradas",
    name: "Nome",
  },
  autocompleteMessages: {
    emptyText: "Não encontrou o item que procurava? Use uma das opções para adicionar.",
  },
};

export default MESSAGES;
