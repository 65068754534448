class Product {
  constructor(source = {}) {
    if (source == null) return this;

    this.id = source.id;
    this.name = source.name;
    this.suppliers = source.suppliers;
    this.industryCode = source.industryCode;
    this.barCode = source.barCode;
    this.cost = source.cost;
    this.margin = source.margin;
    this.saleValue = source.saleValue;
    this.priceMinimum = source.priceMinimum;
    this.profit = source.profit;
    this.inventoryQuantity = source.inventoryQuantity;
    this.lastCost = source.lastCost;
    this.localization = source.localization;
    this.commissionable = source.commissionable;
    this.additionalInformation = source.additionalInformation;
    this.width = source.width;
    this.height = source.height;
    this.length = source.length;
    this.diameter = source.diameter;
    this.grossWeight = source.grossWeight;
    this.netWeight = source.netWeight;
    this.classificationCodeIpi = source.classificationCodeIpi;
    this.taxableUnit = source.taxableUnit;
    this.eanTaxable = source.eanTaxable;
    this.conversionFactor = source.conversionFactor;
    this.valueIpi = source.valueIpi;
    this.originIcms = source.originIcms;
    this.ncm = source.ncm;
    this.cest = source.cest;
    this.unitMeasurement = source.unitMeasurement;
    this.ipiCnpjProducer = source.ipiCnpjProducer;
    this.ipiControlSealCode = source.ipiControlSealCode;
    this.ipiQuantityControlStamp = source.ipiQuantityControlStamp;
    this.codigoBeneficiario = source.codigoBeneficiario;
    this.version = source.version;
    this.brand = source.brand;
    this.active = source.active;
    this.controlInventory = source.controlInventory;
    this.saleLowInventory = source.saleLowInventory;
    this.enableForNf = source.enableForNf;
  }
}

export default Product;
