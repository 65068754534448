import React, { useState, useCallback, useEffect } from "react";
import moment from "moment";
import { makeStyles } from "@mui/styles";
import CustomComponentsStyle from "assets/jss/material-dashboard-react/components/customComponentsStyle";
import CardStyle from "assets/jss/material-dashboard-react/components/cardStyle";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import Button from "components/CustomButtons/Button";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import CardFooter from "components/Card/CardFooter";
import AddressForm from "components/address/AddressForm";
import { useHistory, useParams } from "react-router-dom";
import { DatePicker } from "@mui/x-date-pickers";
import InputMask from "react-input-mask";
import {
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
  FormControlLabel,
  Typography,
  TextField,
  Switch,
  FormGroup,
  Checkbox,
  Box,
} from "@mui/material";
import { useSnackbar } from "notistack";
import AddressService from "components/address/service/AddressService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { Person, PersonType, GenderType, RegistrationType } from "./model/Person";
import { toRequest, fromResponse } from "./converter/PersonConverter";
import PersonService from "./service/PersonService";
import { handlingResponseErrors } from "../../config/util";
import MESSAGES from "../../config/messages";
import Loading from "../../components/loading/Loading";
import TextInput from "../../components/textInput/TextInput";
import CnpjSearch from "./components/CnpjSearch";
import SelectEnum from "../../components/selectEnum/SelectEnum";

const ISENTO = "ISENTO";

const { generalMessages, personMessages } = MESSAGES;

const useCustomComponentsStyle = makeStyles(CustomComponentsStyle);
const useCardStyle = makeStyles(CardStyle);

// eslint-disable-next-line react/prop-types
export default function PersonForm({ onCancel, afterSave, name, registrationTypeParam }) {
  const [loading, setLoading] = useState(false);
  const classesCustomComponentsStyle = useCustomComponentsStyle();
  const classesCardStyle = useCardStyle();
  const history = useHistory();
  const routerParams = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [errors, setErrors] = useState({});
  const [openCnpjModal, setOpenCnpjModal] = useState(false);
  const [states, setStates] = useState([]);

  // const now = formatDate(new Date(), "yyyy-MM-dd");

  const getRegistrationTypeInitial = () => {
    if (registrationTypeParam === RegistrationType.SUPPLIER.code) {
      return {
        [RegistrationType.CUSTOMER.code]: false,
        [RegistrationType.SUPPLIER.code]: true,
        [RegistrationType.CARRIER.code]: false,
      };
    }

    // if (registrationTypeParam === RegistrationType.CUSTOMER.code) {
    //   return {
    //     [RegistrationType.CUSTOMER.code]: true,
    //     [RegistrationType.SUPPLIER.code]: false,
    //     [RegistrationType.CARRIER.code]: false,
    //   };
    // }
    // Default
    return {
      [RegistrationType.CUSTOMER.code]: true,
      [RegistrationType.SUPPLIER.code]: false,
      [RegistrationType.CARRIER.code]: false,
    };
  };

  const [person, setPerson] = useState(
    new Person({
      name,
      genderType: GenderType.M.code,
      personType: PersonType.PF.code,
      birthday: null,
      registrationType: getRegistrationTypeInitial(),
      active: true,
    })
  );

  const fetchStates = useCallback(async () => {
    try {
      setLoading(true);
      const result = await AddressService.fetchStates();
      const { data } = result;
      const { content } = data;
      setStates(content);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchStates();
  }, [fetchStates]);

  const fetchById = useCallback(async () => {
    try {
      setLoading(true);
      const { id } = routerParams || {};
      if (id != null) {
        const result = await PersonService.fetchById(id);
        const customerResponse = fromResponse(result?.data?.data);
        const { inscricaoEstadual } = customerResponse;
        setPerson({
          ...customerResponse,
          isento: inscricaoEstadual === ISENTO,
        });
      }
    } finally {
      setLoading(false);
    }
  }, [routerParams]);

  useEffect(() => {
    fetchById();
  }, [fetchById]);

  const handleChangeCnpjSearch = useCallback(
    (personSearched) => {
      const newPerson = {
        ...person,
        ...personSearched,
      };
      setPerson(newPerson);
    },
    [person]
  );

  const handleChange = useCallback(
    (field, value) => {
      let newPerson = null;
      if (field === "personType") {
        // Reset the customer object
        if (value === PersonType.PF.code) {
          newPerson = new Person({
            genderType: GenderType.M.code,
            personType: PersonType.PF.code,
            registrationType: {
              [RegistrationType.CUSTOMER.code]: true,
              [RegistrationType.SUPPLIER.code]: false,
              [RegistrationType.CARRIER.code]: false,
            },
            birthday: null,
            active: true,
          });
        } else {
          newPerson = new Person({
            personType: PersonType.PJ.code,
            registrationType: {
              [RegistrationType.CUSTOMER.code]: true,
              [RegistrationType.SUPPLIER.code]: false,
              [RegistrationType.CARRIER.code]: false,
            },
            foundationDate: null,
            active: true,
          });
        }
      } else {
        newPerson = new Person({ ...person });
      }
      newPerson[field] = value;
      setPerson(newPerson);
    },
    [person]
  );

  const handleChangeAddress = useCallback(
    (field, value, automaticFilling) => {
      if (automaticFilling) {
        // From via cep
        setPerson({
          ...person,
          ...value,
        });
      } else {
        setPerson({
          ...person,
          [field]: value,
        });
      }
    },
    [person]
  );

  const handleChangeIsento = useCallback(
    (isento) => {
      setPerson({
        ...person,
        isento,
        inscricaoEstadual: isento ? ISENTO : "",
      });
    },
    [person]
  );

  const handleChangeRegistrationType = useCallback(
    (code, checked) => {
      const { registrationType } = person;

      const registrationTypeList = [];
      Object.keys(registrationType).forEach((property) => {
        const value = registrationType[property];
        if (value) {
          registrationTypeList.push(property);
        }
      });

      if (!checked && Object.keys(registrationTypeList).length === 1) {
        enqueueSnackbar("É necessário pelo menos um tipo de cadastro!", {
          variant: "info",
          autoHideDuration: 3000,
        });
        return;
      }

      registrationType[code] = checked;

      setPerson({
        ...person,
        registrationType,
      });
    },
    [enqueueSnackbar, person]
  );

  const goBack = useCallback(() => {
    if (onCancel) {
      onCancel();
    } else {
      history.push("/app/person-list");
    }
  }, [history, onCancel]);

  const onSave = useCallback(async () => {
    try {
      setLoading(true);
      const { id } = person;
      if (id != null) {
        await PersonService.update(id, toRequest(person));
      } else {
        const response = await PersonService.save(toRequest(person));
        if (afterSave) {
          afterSave(response?.data?.data);
        }
      }
      enqueueSnackbar(generalMessages.saveSuccess, {
        variant: "success",
        autoHideDuration: 3000,
      });
      goBack();
    } catch (error) {
      const { message, validationErrors } = handlingResponseErrors(error);
      enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
      if (validationErrors != null) {
        setErrors(validationErrors);
      }
    } finally {
      setLoading(false);
    }
  }, [person, goBack, enqueueSnackbar, afterSave]);

  const renderIEIsentoSwitch = ({
    value = false,
    label,
    disabled = false,
    style,
    labelPlacement = "end",
  }) => (
    <GridItem xs={12} sm={12} md={2}>
      <Box style={{ marginTop: 12 }}>
        <FormGroup style={style}>
          <FormControlLabel
            control={
              <Switch
                color="secondary"
                checked={value}
                onChange={(event) => handleChangeIsento(event.target.checked)}
              />
            }
            label={label}
            labelPlacement={labelPlacement}
            disabled={disabled}
          />
        </FormGroup>
      </Box>
    </GridItem>
  );

  const renderInscricaoEstadual = () => (
    <GridItem xs={12} sm={12} md={4}>
      <TextInput
        id="inscricaoEstadual"
        label="Inscrição estadual"
        value={person.inscricaoEstadual}
        onChange={handleChange}
        errors={errors}
        disabled={person.isento}
      />
    </GridItem>
  );

  const renderPhones = () => (
    <>
      <GridItem xs={12} sm={12} md={3}>
        <InputMask
          mask="(99) 99999-9999"
          disabled={false}
          value={person.phone == null ? "" : person.phone}
          onChange={(value) => handleChange("phone", value.currentTarget.value)}
        >
          <TextField
            size="small"
            variant="outlined"
            id="phone"
            label={personMessages.phone}
            fullWidth
            InputLabelProps={{
              className: classesCustomComponentsStyle.labelTextField,
            }}
            className={classesCustomComponentsStyle.textField}
          />
        </InputMask>
      </GridItem>
      <GridItem xs={12} sm={12} md={3}>
        <InputMask
          mask="(99) 99999-9999"
          disabled={false}
          value={person.mobile == null ? "" : person.mobile}
          onChange={(value) => handleChange("mobile", value.currentTarget.value)}
        >
          <TextField
            size="small"
            variant="outlined"
            id="mobile"
            label={personMessages.mobile}
            fullWidth
            InputLabelProps={{
              className: classesCustomComponentsStyle.labelTextField,
            }}
            className={classesCustomComponentsStyle.textField}
          />
        </InputMask>
      </GridItem>
    </>
  );

  const renderEmail = () => (
    <>
      <GridItem xs={12} sm={12} md={6}>
        <TextInput
          id="email"
          label={personMessages.email}
          value={person.email}
          onChange={handleChange}
        />
      </GridItem>
    </>
  );

  const renderSexType = () => (
    <>
      <GridItem xs={12} sm={12} md={12} style={{ height: "70px" }}>
        <FormControl
          component="fieldset"
          className={classesCustomComponentsStyle.formControlRadio}
          size="small"
        >
          <FormLabel className={classesCustomComponentsStyle.formLabel}>
            {personMessages.gender}
          </FormLabel>
          <RadioGroup
            row
            name="genderType"
            value={person.genderType}
            onChange={(value) => handleChange("genderType", value.currentTarget.value)}
          >
            <FormControlLabel
              value={GenderType.M.code}
              control={<Radio color="secondary" />}
              label={
                <Typography className={classesCustomComponentsStyle.formControlLabel}>
                  {GenderType.M.name}
                </Typography>
              }
            />
            <FormControlLabel
              value={GenderType.W.code}
              control={<Radio color="secondary" />}
              label={
                <Typography className={classesCustomComponentsStyle.formControlLabel}>
                  {GenderType.W.name}
                </Typography>
              }
            />
            <FormControlLabel
              value={GenderType.U.code}
              control={<Radio color="secondary" />}
              label={
                <Typography className={classesCustomComponentsStyle.formControlLabel}>
                  {GenderType.U.name}
                </Typography>
              }
            />
          </RadioGroup>
        </FormControl>
      </GridItem>
    </>
  );

  const renderSwitch = (value = false, field, label, disabled = false) => (
    <GridItem xs={12} sm={12} md={12}>
      <FormGroup style={{ paddingTop: "8px" }}>
        <FormControlLabel
          control={
            <Switch
              color="secondary"
              checked={value}
              onChange={(event) => handleChange(field, event.target.checked)}
            />
          }
          label={label}
          disabled={disabled}
        />
      </FormGroup>
    </GridItem>
  );

  const renderName = () => (
    <GridItem xs={12} sm={12} md={6}>
      <TextInput
        autoFocus
        id="name"
        label={personMessages.name}
        value={person.name}
        onChange={handleChange}
        required
        errors={errors}
      />
    </GridItem>
  );
  const renderCpf = () => (
    <GridItem xs={12} sm={12} md={3}>
      <InputMask
        mask="999.999.999-99"
        disabled={false}
        value={person.cpf == null ? "" : person.cpf}
        onChange={(value) => handleChange("cpf", value.currentTarget.value)}
      >
        <TextField
          size="small"
          variant="outlined"
          id="cpf"
          label={personMessages.cpf}
          fullWidth
          InputLabelProps={{
            className: classesCustomComponentsStyle.labelTextField,
          }}
          className={classesCustomComponentsStyle.textField}
          error={errors.cpf}
          helperText={errors.cpf}
        />
      </InputMask>
    </GridItem>
  );
  const renderRG = () => (
    <GridItem xs={12} sm={12} md={3}>
      <TextInput
        id="rg"
        maxLength={11}
        label={personMessages.rg}
        value={person.rg}
        onChange={handleChange}
      />
    </GridItem>
  );
  const renderBirthday = () => (
    <GridItem xs={12} sm={12} md={4} style={{ marginTop: "12px" }}>
      <DatePicker
        id="birthday"
        label={personMessages.birthday}
        format="dd/MM/yyyy"
        slotProps={{ textField: { size: "small" } }}
        value={person?.birthday ? moment(person.birthday).valueOf() : null}
        onChange={(date) => handleChange("birthday", date)}
      />
    </GridItem>
  );
  const renderTaxpayerType = () => (
    <GridItem xs={12} sm={12} md={4}>
      <SelectEnum
        label="Tipo de contribuinte"
        field="taxpayerType"
        id="taxpayerType"
        Enum={{
          SELECIONE: {
            code: null,
            name: "--Selecione--",
          },
          CONTRIBUINTE_ICMS: {
            code: "CONTRIBUINTE_ICMS",
            name: "Contribuinte ICMS",
          },
          CONTRIBUINTE_ISENTO_INSCRICAO_CONTRIBUINTES_ICMS: {
            code: "CONTRIBUINTE_ISENTO_INSCRICAO_CONTRIBUINTES_ICMS",
            name: "Contribuinte isento inscrição contribuintes ICMS",
          },
          NAO_CONTRIBUINTE: {
            code: "NAO_CONTRIBUINTE",
            name: "Não contribuinte",
          },
        }}
        valueSelected={person.taxpayerType}
        handleChange={handleChange}
        errors={errors}
      />
    </GridItem>
  );

  const renderTradingName = () => (
    <GridItem xs={12} sm={12} md={6}>
      <TextInput
        id="tradingName"
        label={personMessages.tradingName}
        value={person.tradingName}
        onChange={handleChange}
        errors={errors}
      />
    </GridItem>
  );

  const renderSearchCnpjIcon = () => (
    <GridItem xs={12} sm={12} md={1}>
      <FontAwesomeIcon
        icon={faMagnifyingGlass}
        onClick={() => setOpenCnpjModal(true)}
        fontSize={20}
        title="Pesquisar CNPJ"
        color="#686868"
        // style={style}
      />
    </GridItem>
  );

  const renderCnpj = () => (
    <GridItem xs={12} sm={12} md={6}>
      <Box display="flex">
        <Box display="flex" flexGrow={1}>
          <InputMask
            mask="99.999.999/9999-99"
            disabled={false}
            value={person.cnpj == null ? "" : person.cnpj}
            onChange={(value) => handleChange("cnpj", value.currentTarget.value)}
          >
            <TextField
              size="small"
              variant="outlined"
              id="cnpj"
              label={personMessages.cnpj}
              fullWidth
              InputLabelProps={{
                className: classesCustomComponentsStyle.labelTextField,
              }}
              className={classesCustomComponentsStyle.textField}
              error={errors.cnpj}
              helperText={errors.cnpj}
            />
          </InputMask>
        </Box>
        <Box style={{ alignItems: "center", display: "flex", marginTop: "12px" }}>
          {renderSearchCnpjIcon()}
        </Box>
      </Box>
    </GridItem>
  );

  const renderFoundationDate = () => (
    <GridItem xs={12} sm={12} md={4} style={{ marginTop: "12px" }}>
      <DatePicker
        id="foundationDate"
        label={personMessages.foundationDate}
        format="dd/MM/yyyy"
        slotProps={{ textField: { size: "small" } }}
        value={person?.foundationDate ? moment(person.foundationDate).valueOf() : null}
        onChange={(date) => handleChange("foundationDate", date)}
      />
    </GridItem>
  );

  const renderRegistrationType = () => (
    <GridItem xs={12} sm={12} md={12}>
      <FormLabel className={classesCustomComponentsStyle.formLabel}>Tipo de cadastro</FormLabel>
      <FormGroup row>
        <FormControlLabel
          control={
            <Checkbox
              color="secondary"
              checked={person.registrationType[RegistrationType.CUSTOMER.code]}
              onChange={() =>
                handleChangeRegistrationType(
                  RegistrationType.CUSTOMER.code,
                  !person.registrationType[RegistrationType.CUSTOMER.code]
                )
              }
            />
          }
          label={RegistrationType.CUSTOMER.name}
        />
        <FormControlLabel
          control={
            <Checkbox
              color="secondary"
              checked={person.registrationType[RegistrationType.SUPPLIER.code]}
              onChange={() =>
                handleChangeRegistrationType(
                  RegistrationType.SUPPLIER.code,
                  !person.registrationType[RegistrationType.SUPPLIER.code]
                )
              }
            />
          }
          label={RegistrationType.SUPPLIER.name}
        />
        <FormControlLabel
          control={
            <Checkbox
              color="secondary"
              checked={person.registrationType[RegistrationType.CARRIER.code]}
              onChange={() =>
                handleChangeRegistrationType(
                  RegistrationType.CARRIER.code,
                  !person.registrationType[RegistrationType.CARRIER.code]
                )
              }
            />
          }
          label={RegistrationType.CARRIER.name}
        />
      </FormGroup>
    </GridItem>
  );

  const renderPersonType = () => (
    <GridItem xs={12} sm={12} md={12} style={{ height: "70px" }}>
      <FormControl
        size="small"
        component="fieldset"
        className={classesCustomComponentsStyle.formControlRadio}
        required
      >
        <FormLabel className={classesCustomComponentsStyle.formLabel}>
          {personMessages.personType}
        </FormLabel>
        <RadioGroup
          row
          name="personType"
          value={person.personType}
          onChange={(value) => handleChange("personType", value.currentTarget.value)}
        >
          <FormControlLabel
            value={PersonType.PF.code}
            control={<Radio color="secondary" />}
            label={
              <Typography className={classesCustomComponentsStyle.formControlLabel}>
                {PersonType.PF.name}
              </Typography>
            }
          />
          <FormControlLabel
            value={PersonType.PJ.code}
            control={<Radio color="secondary" />}
            label={
              <Typography className={classesCustomComponentsStyle.formControlLabel}>
                {PersonType.PJ.name}
              </Typography>
            }
          />
        </RadioGroup>
      </FormControl>
    </GridItem>
  );

  const renderCode = () => (
    <GridItem xs={12} sm={12} md={12}>
      <div
        style={{
          fontSize: 35,
          fontWeight: "bold",
          flexGrow: 1,
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        {person.code}
      </div>
    </GridItem>
  );

  const renderPJ = () => (
    <>
      <GridItem xs={12} sm={12} md={8} />
      {renderCnpj()}
      <GridItem xs={12} sm={12} md={6} />
      {renderName()}
      {renderTradingName()}

      {renderInscricaoEstadual()}
      {renderIEIsentoSwitch({
        value: person.isento,
        label: "IE Isento",
      })}

      {renderPhones()}
      {renderEmail()}
      <GridItem xs={12} sm={12} md={6} />
      {renderTaxpayerType()}
      {renderFoundationDate()}

      <AddressForm
        errorFields={{
          zipCode: "address.zipCode",
          state: "address.state",
          city: "address.city",
          street: "address.street",
          neighborhood: "address.neighborhood",
          number: "address.number",
          complement: "address.complement",
        }}
        address={person}
        states={states}
        handleChange={handleChangeAddress}
        errors={errors}
        required={false}
      />
    </>
  );

  const renderPF = () => (
    <>
      {renderName()}
      {renderCpf()}
      {renderRG()}

      {renderSexType()}
      {renderPhones()}
      {renderEmail()}
      {renderTaxpayerType()}
      {renderBirthday()}
      <AddressForm
        errorFields={{
          zipCode: "address.zipCode",
          state: "address.state",
          city: "address.city",
          street: "address.street",
          neighborhood: "address.neighborhood",
          number: "address.number",
          complement: "address.complement",
        }}
        address={person}
        states={states}
        handleChange={handleChangeAddress}
        errors={errors}
        required={false}
      />
    </>
  );

  return (
    <>
      <GridContainer style={{ justifyContent: "center" }}>
        <GridItem xs={12} sm={12} md={10}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classesCardStyle.cardTitle}>{personMessages.customer}</h4>
              <p className={classesCardStyle.cardCategory}>{personMessages.customerForm}</p>
            </CardHeader>
            <CardBody>
              <GridContainer>
                {renderCode()}
                {renderRegistrationType()}
                {renderPersonType()}
                {person.personType === PersonType.PF.code ? renderPF() : renderPJ()}
                {renderSwitch(person.active, "active", "Ativo")}
              </GridContainer>
            </CardBody>
            <CardFooter>
              <Button color="primary" onClick={goBack}>
                {generalMessages.cancel}
              </Button>
              {person.standard !== true && (
                <Button color="primary" onClick={onSave}>
                  {generalMessages.save}
                </Button>
              )}
            </CardFooter>
          </Card>
        </GridItem>
        <CnpjSearch
          open={openCnpjModal}
          onClose={setOpenCnpjModal}
          handleChange={handleChangeCnpjSearch}
          states={states}
          cnpjTyped={person?.cnpj}
        />
        <Loading loading={loading} />
      </GridContainer>
    </>
  );
}
