import axios from "axios";
import { APIS } from "../../../config/constants";
import BaseService from "../../../core/BaseService";

const { register: registerAPI } = APIS;

class ServiceOrderService extends BaseService {
  constructor() {
    super(registerAPI.serviceOrder);
  }

  validateGeneralStep = (request) => axios.post(`${this.endpoint}/validate-general-step`, request);

  cancel = (id) => axios.patch(`${this.endpoint}/${id}/cancel`);

  fetchAll = ({ params, pagination, sorting }) => {
    const { page, pageSize } = pagination;
    const { field = "createdAt", sort = "desc" } = sorting || {};
    return axios.get(
      `${this.endpoint}/search-with-params?${params}&size=${pageSize}&page=${page}&sort=${field},${sort}`
    );
  };

  printOrder = (id) => axios.get(`${this.endpoint}/${id}/print`, { responseType: "blob" });
}

export default new ServiceOrderService();
