/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useState } from "react";
import GridContainer from "components/Grid/GridContainer";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  TextField,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import CustomComponentsStyle from "assets/jss/material-dashboard-react/components/customComponentsStyle";
import { primaryColor } from "assets/jss/material-dashboard-react";
import GridItem from "components/Grid/GridItem";
import { useSnackbar } from "notistack";
import InputMask from "react-input-mask";
import Loading from "components/loading/Loading";
import PersonService from "../service/PersonService";
import { handlingResponseErrors } from "../../../config/util";

const useCustomComponentsStyle = makeStyles(CustomComponentsStyle);
const EMPTY = "EMPTY";

const CnpjSearch = ({ open, handleChange, onClose, states, cnpjTyped }) => {
  const { enqueueSnackbar } = useSnackbar();
  // const { userLogged } = useContext(UserContext);
  const classesCustomComponentsStyle = useCustomComponentsStyle();

  const [loading, setLoading] = useState(false);

  const [state, setState] = useState(EMPTY);
  const [cnpj, setCnpj] = useState(cnpjTyped);

  // useEffect(() => {
  //   if (states?.length > 0 && state == null) {
  //     const pr = states.find((st) => st.uf === "PR");
  //     setState(pr);
  //   }
  // }, [states, state]);

  useEffect(() => {
    setCnpj(cnpjTyped);
  }, [cnpjTyped]);

  const handleState = useCallback((value) => {
    setState(value);
  }, []);

  const handleCnpj = useCallback((value) => {
    setCnpj(value);
  }, []);

  const handleClose = useCallback(() => {
    onClose(false);
  }, [onClose]);

  const fromResponse = useCallback((response) => {
    const {
      name,
      tradingName,
      cnpj: cnpjObg,
      foundationDate,
      phone,
      mobile,
      email,
      address,
      inscricaoEstadual,
    } = response;

    const { id: addressId, city, street, number, complement, zipCode, neighborhood } =
      address || {};

    let cityId = null;
    let stateId = null;
    if (city != null) {
      cityId = city.id;
      stateId = city.state.id;
    }
    return {
      name,
      tradingName,
      cnpj: cnpjObg,
      foundationDate,
      phone,
      mobile,
      email,
      state: stateId,
      city: cityId,
      addressId,
      street,
      number,
      complement,
      zipCode,
      neighborhood,
      inscricaoEstadual,
    };
  }, []);

  const onSearch = useCallback(async () => {
    try {
      setLoading(true);
      if (state === EMPTY) {
        enqueueSnackbar("Selecione o estado que o CNPJ pertence!", {
          variant: "info",
          autoHideDuration: 5000,
        });
        return;
      }
      const cnpjWM = cnpj?.replace(/[^0-9]+/g, "") || null;
      const { data: response } = await PersonService.fetchCnpj(cnpjWM, state?.uf);
      const person = fromResponse(response?.data);
      if (person) {
        handleChange(person);
        handleClose();
      }
    } catch (error) {
      const show500msg = true;
      const { message } = handlingResponseErrors(error, show500msg);
      enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    } finally {
      setLoading(false);
    }
  }, [state, cnpj, fromResponse, enqueueSnackbar, handleChange, handleClose]);

  return (
    <>
      <Dialog disableEscapeKeyDown open={open} fullWidth maxWidth="sm">
        <DialogTitle>Busca de CNPJ</DialogTitle>
        <DialogContent>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <FormControl
                fullWidth
                className={classesCustomComponentsStyle.formControlSelect}
                size="small"
                variant="outlined"
                required
              >
                <InputLabel>Estado</InputLabel>
                <Select
                  label="Estado"
                  autoWidth
                  value={state == null ? "" : state}
                  onChange={(value) => {
                    handleState(value.target.value);
                  }}
                >
                  <MenuItem value={EMPTY}>--Selecione--</MenuItem>
                  {states.map((item) => (
                    <MenuItem value={item}>{item.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <InputMask
                mask="99.999.999/9999-99"
                disabled={false}
                value={cnpj == null ? "" : cnpj}
                onChange={(value) => handleCnpj(value.currentTarget.value)}
              >
                <TextField
                  size="small"
                  variant="outlined"
                  id="cnpj"
                  label="CNPJ"
                  fullWidth
                  InputLabelProps={{
                    className: classesCustomComponentsStyle.labelTextField,
                  }}
                  className={classesCustomComponentsStyle.textField}
                />
              </InputMask>
            </GridItem>
          </GridContainer>
        </DialogContent>
        <DialogActions>
          <div style={{ flexGrow: 1, display: "flex", justifyContent: "space-between" }}>
            <Button style={{ color: primaryColor[0] }} onClick={handleClose}>
              Voltar
            </Button>
            <Button style={{ color: primaryColor[0] }} onClick={onSearch}>
              Pesquisar
            </Button>
          </div>
        </DialogActions>
      </Dialog>
      <Loading loading={loading} />
    </>
  );
};

export default React.memo(CnpjSearch);
