/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable prettier/prettier */
/* eslint-disable react/prop-types */
import React, { useState, useCallback, useContext } from "react";
import { v4 as uuidv4 } from "uuid";
import { makeStyles } from "@mui/styles";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import Button from "components/CustomButtons/Button";
import Autocomplete from "components/autocomplete/Autocomplete";
import AutocompleteStyle from "components/autocomplete/AutocompleteStyle";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid as GridMUI,
  Box,
  Button as ButtonUI,
  Modal,
} from "@mui/material";
import useConfirmDialog from "components/confirm/ConfirmDialog";
import { useSnackbar } from "notistack";
import { primaryColor } from "assets/jss/material-dashboard-react";
import Loading from "../../../components/loading/Loading";
import ProductService from "../../product/service/ProductService";
import ServiceProductForm from "../../service/ServiceProductForm";
import ProductForm from "../../product/ProductForm";
import MESSAGES from "../../../config/messages";
import { currencyFormatter, percentageFormatter } from "../../../config/util";
import TextInput from "../../../components/textInput/TextInput";
import CurrencyTextInput from "../../../components/textInput/CurrencyTextInput";
import ProductIcon from "../../../components/icons/ProductIcon";
import EditIcon from "../../../components/icons/EditIcon";
import DeleteIcon from "../../../components/icons/DeleteIcon";
import DataGridCustom from "../../../components/dataGrid/DataGridCustom";
import UserContext from "../../../core/UserContext";
import { MODULES, AUTOCOMPLETE } from "../../../config/constants";

const { serviceOrderMessages, generalMessages, autocompleteMessages } = MESSAGES;
const useAutocompleteStyle = makeStyles(AutocompleteStyle);

const ServiceOrderProductStep = ({
  onChange,
  onChangeChangeGeneralValues,
  cartItems,
  order,
  getOrderTotal,
}) => {
  const { userLogged } = useContext(UserContext);
  const { enqueueSnackbar } = useSnackbar();
  const classesAutocompleteStyle = useAutocompleteStyle();
  const [openModal, setOpenModal] = useState(false);
  const [barCodeField, setBarCodeField] = useState("");
  const [onTypedChange, setOnTypedChange] = useState(null);
  const [errors] = useState({});
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [loading, setLoading] = useState(false);
  const [quickAddProductModalOpen, setQuickAddProductModalOpen] = useState(false);
  const [quickAddServiceModalOpen, setQuickAddServiceModalOpen] = useState(false);
  const { showConfirmDialog, closeConfirmDialog, ConfirmDialog } = useConfirmDialog();

  const hasInventoryPermission = useCallback(() => {
    if (userLogged != null) {
      const { modules } = userLogged;

      const module = MODULES["INVENTORY-SALE"];
      const hasPermission = modules.includes(module);
      return hasPermission;
    }
    return false;
  }, [userLogged]);

  const onDeleteProduct = useCallback(
    ({ id }) => {
      showConfirmDialog({
        title: "Serviço",
        text: serviceOrderMessages.productStep.deleteProduct,
        onClickConfirm: () => {
          const newCartItems = [...cartItems];
          const index = newCartItems.findIndex((item) => item.id === id);
          newCartItems.splice(index, 1);
          // const installments = calculateInstallments(numberInstallments, total);
          const total = getOrderTotal(newCartItems);
          const discount = 0;
          onChangeChangeGeneralValues(total, discount);
          onChange([...newCartItems]);
          closeConfirmDialog();
        },
      });
    },
    [
      cartItems,
      closeConfirmDialog,
      getOrderTotal,
      onChange,
      onChangeChangeGeneralValues,
      showConfirmDialog,
    ]
  );

  const onEditProduct = useCallback(
    ({ id }) => {
      const productSelected = cartItems.find((item) => item.id === id);
      setSelectedProduct(productSelected);
      setOpenModal(true);
    },
    [setOpenModal, cartItems]
  );

  const columns = [
    {
      field: "service_product",
      headerName: " ",
      sortable: false,
      width: 40,
      disableClickEventBubbling: true,
      renderCell: (params) => <ProductIcon isService={params.row.service} />,
    },
    {
      field: "productName",
      headerName: serviceOrderMessages.productStep.name,
      flex: 4,
      minWidth: 250,
    },
    {
      field: "brand",
      headerName: serviceOrderMessages.productStep.brand,
      flex: 2,
      minWidth: 150,
    },
    {
      field: "saleValue",
      headerName: serviceOrderMessages.productStep.saleValue,
      flex: 2,
      minWidth: 120,
      valueGetter: ({ value }) => currencyFormatter(value),
    },
    {
      field: "quantity",
      headerName: serviceOrderMessages.productStep.quantity,
      flex: 2,
      minWidth: 120,
    },
    {
      field: "discount",
      headerName: serviceOrderMessages.productStep.discount,
      flex: 2,
      minWidth: 120,
      valueGetter: ({ value }) => percentageFormatter(value),
    },
    {
      field: "finalValue",
      headerName: serviceOrderMessages.productStep.finalValue,
      flex: 2,
      minWidth: 120,
      valueGetter: ({ value }) => currencyFormatter(value),
    },
    {
      field: "actions",
      headerName: generalMessages.actions,
      sortable: false,
      width: 100,
      disableClickEventBubbling: true,
      renderCell: ({ row }) => (
        <div
          style={{
            cursor: "pointer",
            justifyContent: "space-between",
            padding: "10px",
            display: "flex",
            flex: 1,
          }}
        >
          <EditIcon onClick={() => onEditProduct(row)} />
          <DeleteIcon onClick={() => onDeleteProduct(row)} />
        </div>
      ),
    },
  ];

  const handleChangeSelectedProduct = useCallback(
    (field, value) => {
      const { discount = 0, saleValue = 0, quantity = 1 } = selectedProduct;
      let { saleValueDiscount, finalValue } = selectedProduct;

      if (field === "saleValue" || field === "discount" || field === "quantity") {
        const valueChecked = value == null || value === "" ? "0" : value;
        const valueParsed = parseFloat(valueChecked);

        if (field === "saleValue") {
          const discountValue = discount / 100;
          saleValueDiscount = valueParsed - valueParsed * discountValue;
          finalValue = saleValueDiscount * quantity;
        }
        if (field === "discount") {
          const discountValue = valueParsed / 100;
          saleValueDiscount = saleValue - saleValue * discountValue;
          finalValue = saleValueDiscount * quantity;
        }
        if (field === "quantity") {
          const discountValue = discount / 100;
          saleValueDiscount = saleValue - saleValue * discountValue;
          finalValue = saleValueDiscount * valueParsed;
        }

        finalValue = parseFloat(finalValue.toFixed(2));
      }

      setSelectedProduct({
        ...selectedProduct,
        discount,
        saleValue,
        quantity,
        saleValueDiscount,
        finalValue,
        [field]: value,
      });
    },
    [selectedProduct]
  );

  const isMinimumInventoryException = (product) => {
    const { quantity, saleLowInventory, inventory, service, controlInventory } = product;

    if (service || controlInventory === false || saleLowInventory) {
      return false;
    }

    const inventoryQuantity = inventory?.quantity || 0;
    const newQtde = inventoryQuantity - quantity;

    return newQtde < 0;
  };

  const onConfirmProduct = useCallback(
    (selected) => {
      const { saleValue } = selected;

      // eslint-disable-next-line no-param-reassign
      selected.saleValue = parseFloat(saleValue || 0);
      const newItems = [...cartItems];
      const index = newItems.findIndex((item) => item.id === selected.id);
      if (index >= 0) {
        newItems[index] = selected;
      } else {
        newItems.push(selected);
      }
      newItems.sort(({ service: serviceA }, { service: serviceB }) =>
        // eslint-disable-next-line no-nested-ternary
        serviceA === serviceB ? 0 : serviceA ? 1 : -1
      );

      onChange([...newItems]);
      const total = getOrderTotal(newItems);
      const discount = 0;
      onChangeChangeGeneralValues(total, discount);

      setOpenModal(false);
      setSelectedProduct(null);
    },
    [cartItems, onChange, getOrderTotal, onChangeChangeGeneralValues]
  );

  const beforeOnConfirmProduct = useCallback(
    (selected) => {
      const { priceMinimum, finalValue } = selected;
      const { allowSellingBelowMinimumPrice } = userLogged;

      if (hasInventoryPermission() && isMinimumInventoryException(selected)) {
        enqueueSnackbar(`${serviceOrderMessages.productStep.minimumInventory}`, {
          variant: "info",
          autoHideDuration: 5000,
        });
        return;
      }

      const isPriceBelowMinimum = finalValue < priceMinimum;

      if (isPriceBelowMinimum && allowSellingBelowMinimumPrice) {
        showConfirmDialog({
          title: "Preço mínimo",
          text: `Produto sendo vendido abaixo do preço mínimo: ${currencyFormatter(
            priceMinimum
          )}. Deseja continuar?`,
          onClickConfirm: () => {
            closeConfirmDialog();
            onConfirmProduct(selected);
          },
        });
      } else if (isPriceBelowMinimum && !allowSellingBelowMinimumPrice) {
        enqueueSnackbar(
          `${serviceOrderMessages.productStep.saleValueLMinimum}: ${currencyFormatter(
            priceMinimum
          )}`,
          {
            variant: "info",
            autoHideDuration: 5000,
          }
        );
      } else {
        onConfirmProduct(selected);
      }
    },
    [
      closeConfirmDialog,
      enqueueSnackbar,
      hasInventoryPermission,
      onConfirmProduct,
      showConfirmDialog,
      userLogged,
    ]
  );

  const renderModal = useCallback(() => {
    if (selectedProduct == null) return <></>;
    const {
      discount = 0,
      saleValue = 0,
      quantity = 1,
      saleValueDiscount,
      finalValue,
      service,
      inventory,
      controlInventory,
    } = selectedProduct;

    return (
      <Dialog open={openModal}>
        <DialogTitle onClose={() => setOpenModal(false)}>
          {serviceOrderMessages.productStep.product}
        </DialogTitle>
        <DialogContent dividers>
          <GridContainer spacing={8} style={{ margin: "0 -15px" }}>
            <GridItem xs={8} sm={8} md={12}>
              <div style={{ fontSize: 20, fontWeight: "bold" }}>{selectedProduct.productName}</div>
            </GridItem>
            <GridItem xs={8} sm={8} md={4}>
              <CurrencyTextInput
                id="saleValue"
                onFocus={(e) => {
                  // Se for o primeiro campo da tela
                  setTimeout(() => {
                    e.target.setSelectionRange(0, 0);
                  }, 200);
                }}
                autoFocus
                label={serviceOrderMessages.productStep.saleValue}
                value={saleValue}
                onChange={handleChangeSelectedProduct}
                required
                errors={errors}
              />
            </GridItem>

            <GridItem xs={8} sm={8} md={4}>
              <CurrencyTextInput
                id="discount"
                currencySymbol="%"
                label={serviceOrderMessages.productStep.discount}
                value={discount}
                onChange={handleChangeSelectedProduct}
                required
                errors={errors}
              />
            </GridItem>
            <GridItem xs={8} sm={8} md={4}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: 0,
                  textAlign: "center",
                }}
              >
                <div>{serviceOrderMessages.productStep.saleValueDiscount}</div>
                <div style={{ fontSize: 20, fontWeight: "bold" }}>
                  {currencyFormatter(saleValueDiscount)}
                </div>
              </div>
            </GridItem>
            {/* <Box width="100%" /> */}
            <GridItem xs={8} sm={8} md={4}>
              <TextInput
                id="quantity"
                label={serviceOrderMessages.productStep.quantity}
                value={quantity}
                onChange={handleChangeSelectedProduct}
                type="number"
                required
                errors={errors}
              />
            </GridItem>
            <GridItem xs={8} sm={8} md={4}>
              {hasInventoryPermission() && !service && controlInventory && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginTop: 0,
                    textAlign: "center",
                  }}
                >
                  <div>Estoque</div>
                  <div style={{ fontSize: 20, fontWeight: "bold" }}>
                    {inventory?.quantity || "0"}
                  </div>
                </div>
              )}
            </GridItem>
            <GridItem xs={8} sm={8} md={4}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: 0,
                  textAlign: "center",
                }}
              >
                <div>{serviceOrderMessages.productStep.finalValue}</div>
                <div style={{ fontSize: 20, fontWeight: "bold" }}>
                  {currencyFormatter(finalValue)}
                </div>
              </div>
            </GridItem>
          </GridContainer>
        </DialogContent>
        <DialogActions>
          <div style={{ display: "flex", flex: 1, justifyContent: "space-between" }}>
            <Button
              color="primary"
              onClick={() => {
                setOpenModal(false);
                setSelectedProduct(null);
              }}
            >
              {serviceOrderMessages.productStep.cancel}
            </Button>
            <Button color="primary" onClick={() => beforeOnConfirmProduct(selectedProduct)}>
              {serviceOrderMessages.productStep.confirm}
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    );
  }, [
    selectedProduct,
    openModal,
    handleChangeSelectedProduct,
    errors,
    hasInventoryPermission,
    beforeOnConfirmProduct,
  ]);

  const calculeValues = useCallback((item) => {
    const { discount = 0, saleValue = 0, quantity = 1 } = item;
    const discountValue = discount / 100;
    const saleValueDiscount = parseFloat((saleValue - saleValue * discountValue).toFixed(2));
    const finalValue = parseFloat((saleValueDiscount * quantity).toFixed(2));
    return { ...item, saleValueDiscount, finalValue };
  }, []);

  const handleChangeGeneralDiscount = useCallback(
    (discount) => {
      let total = getOrderTotal(cartItems);
      const discountValue = discount / 100;
      total -= total * discountValue;
      // const installments = calculateInstallments(order.numberInstallments, total);
      // onChangeOrder({
      //   ...order,
      //   discount,
      //   total,
      //   installments,
      // });
      onChangeChangeGeneralValues(total, discount);
    },
    [onChangeChangeGeneralValues, getOrderTotal, cartItems]
  );

  const handleChangeTotal = useCallback(
    (newTotal) => {
      /*
      Formula calcular desconto com base na diferenca de valores:
      Ex: total = 100,00   novo_total = 90,00
          
          valor_desconto = total - novo_total
          valor_desconto = 100,00 - 90,00
          valor_desconto = 10,00

          %_desconto = valor_desconto / total * 100
          %_desconto = 10,00 / 100,00 * 100
          %_desconto = 10% 
      */
      const total = getOrderTotal(cartItems);
      const discountValue = total - newTotal;
      const discount = (discountValue / total) * 100;
      // const installments = calculateInstallments(order.numberInstallments, newTotal);
      onChangeChangeGeneralValues(newTotal, discount);
    },
    [onChangeChangeGeneralValues, cartItems, getOrderTotal]
  );

  const renderQuickAddText = () => (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      className={classesAutocompleteStyle.emptyContainer}
    >
      <Box>{autocompleteMessages.emptyText}</Box>
      <Box>
        <ButtonUI
          style={{ color: primaryColor[0] }}
          onMouseDown={() => setQuickAddProductModalOpen(true)}
        >
          Adicionar produto
        </ButtonUI>
        <ButtonUI
          style={{ color: primaryColor[0] }}
          onMouseDown={() => setQuickAddServiceModalOpen(true)}
        >
          Adicionar serviço
        </ButtonUI>
      </Box>
    </Box>
  );

  const renderOptionProductSearch = (props, item) => {
    const { id, industryCode, name, brand } = item;

    if (id === AUTOCOMPLETE.QUICK_ADD) {
      return renderQuickAddText();
    }

    const option = [];
    if (industryCode !== null && industryCode !== "") {
      option.push(industryCode);
    }
    if (name !== null && name !== "") {
      option.push(name);
    }
    if (brand !== null && brand !== "") {
      option.push(brand);
    }
    const text = option.join(" - ");

    return (
      <Box
        {...props}
        display="flex"
        flexDirection="row"
        style={{ paddingTop: 6, paddingBottom: 6 }}
      >
        <Box style={{ paddingRight: 6 }}>
          <ProductIcon isService={item.service} />
        </Box>
        <Box>{text}</Box>
      </Box>
    );
  };

  const checkProductAlreadyAdded = useCallback(
    (value) => {
      if (cartItems.findIndex((item) => item.productId === value.id) !== -1) {
        enqueueSnackbar(serviceOrderMessages.productStep.productAddedOnCart, {
          variant: "info",
          autoHideDuration: 4000,
        });
        return true;
      }
      return false;
    },
    [cartItems, enqueueSnackbar]
  );

  const beforeAutocompleteSelectProduct = useCallback(
    (value) => {
      if (value == null) return;

      if (checkProductAlreadyAdded(value)) return;

      const productCalculate = calculeValues(value);
      setSelectedProduct({
        ...productCalculate,
        id: uuidv4(),
        productName: value.name,
        productId: value.id,
        discount: 0,
        quantity: 1,
      });

      setOpenModal(true);
    },
    [calculeValues, checkProductAlreadyAdded]
  );

  const onEnterSelectProduct = useCallback(
    async (value) => {
      try {
        setLoading(true);

        const result = await ProductService.fetchByBarCode(value);
        const content = result?.data?.content ?? [];
        if (content.length === 0) {
          // eslint-disable-next-line no-alert
          alert("Item não encontrado.");
        } else {
          const productBarCode = content[0];

          let productToAddOnCart = null;
          const index = cartItems.findIndex((item) => item.productId === productBarCode.id);
          const exist = index !== -1;
          if (exist) {
            productToAddOnCart = cartItems[index];
            productToAddOnCart.quantity += 1;
          } else {
            productToAddOnCart = {
              ...productBarCode,
              id: uuidv4(),
              quantity: 1,
              discount: 0,
              productId: productBarCode.id,
              productName: productBarCode.name,
              saleValue: productBarCode.saleValue,
            };
          }
          const { discount, saleValue, quantity } = productToAddOnCart;
          const discountValue = discount / 100;
          const saleValueDiscount = saleValue - saleValue * discountValue;
          productToAddOnCart.finalValue = saleValueDiscount * quantity;

          beforeOnConfirmProduct(productToAddOnCart);
        }
        setBarCodeField("");
      } finally {
        setLoading(false);
      }
    },
    [cartItems, beforeOnConfirmProduct]
  );

  const afterSaveProduct = useCallback(
    async (product) => {
      setQuickAddProductModalOpen(false);
      const result = await ProductService.fetchByAutoComplete(null, { id: product.id });
      const { data } = result;
      const { content } = data;
      beforeAutocompleteSelectProduct(content[0]);
    },
    [beforeAutocompleteSelectProduct]
  );

  const renderQuickAddProduct = () => (
    <Modal style={{ overflow: "auto" }} disableEscapeKeyDown open={quickAddProductModalOpen}>
      <ProductForm
        name={onTypedChange}
        onCancel={() => setQuickAddProductModalOpen(false)}
        afterSave={(id) => afterSaveProduct(id)}
      />
    </Modal>
  );

  const afterSaveService = useCallback(
    async (service) => {
      setQuickAddServiceModalOpen(false);
      beforeAutocompleteSelectProduct(service);
    },
    [beforeAutocompleteSelectProduct]
  );

  const renderQuickAddService = () => (
    <Modal
      style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      disableEscapeKeyDown
      open={quickAddServiceModalOpen}
    >
      <ServiceProductForm
        name={onTypedChange}
        onCancel={() => setQuickAddServiceModalOpen(false)}
        afterSave={(id) => afterSaveService(id)}
      />
    </Modal>
  );

  return (
    <>
      <GridItem xs={12} sm={12} md={12}>
        <GridContainer>
          <GridMUI item xs={12} sm={12} md={8}>
            <Autocomplete
              autoFocus
              variant="outlined"
              fieldsToShowAfterSelectItem={[]}
              label={serviceOrderMessages.productStep.product}
              placeholder={serviceOrderMessages.productStep.autocomplete}
              service={ProductService}
              onChange={(value) => beforeAutocompleteSelectProduct(value)}
              onTypedChange={(value) => setOnTypedChange(value)}
              renderOption={renderOptionProductSearch}
              quickAdd
            />
          </GridMUI>
          <GridMUI item xs={12} sm={12} md={4}>
            <TextInput
              style={{ marginLeft: 4 }}
              onKeyPress={(e) => {
                if (e.which === 13) {
                  onEnterSelectProduct(e.target.value);
                }
              }}
              id="barCode"
              label={generalMessages.barCodeText}
              value={barCodeField}
              onChange={(ev, value) => setBarCodeField(value)}
            />
          </GridMUI>
        </GridContainer>
        <div style={{ height: "calc(100vh - 400px)", marginTop: 10, minHeight: "295px" }}>
          <DataGridCustom onRowDoubleClick={onEditProduct} rows={cartItems} columns={columns} />
        </div>
      </GridItem>
      <GridContainer>
        <GridItem xs={0} sm={0} md={4} />
        <GridItem xs={0} sm={0} md={4} />
        <GridItem xs={12} sm={12} md={2}>
          <CurrencyTextInput
            id="discount"
            currencySymbol="%"
            label={serviceOrderMessages.productStep.generalDiscount}
            value={order.discount || 0}
            onChange={(id, value) => handleChangeGeneralDiscount(value)}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={2}>
          <CurrencyTextInput
            id="total"
            label={serviceOrderMessages.productStep.total}
            value={order.total || 0}
            onChange={(id, value) => handleChangeTotal(value)}
          />
        </GridItem>
      </GridContainer>
      {renderModal()}
      {renderQuickAddProduct()}
      {renderQuickAddService()}
      <Loading loading={loading} />
      <ConfirmDialog />
    </>
  );
};

export default React.memo(ServiceOrderProductStep);
