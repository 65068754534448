/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useCallback } from "react";
import CustomComponentsStyle from "assets/jss/material-dashboard-react/components/customComponentsStyle";
import { makeStyles } from "@mui/styles";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { NFNotaInfoItemModalidadeBCICMS } from "../model/NaturezaOperacao";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

const SelectNFNotaInfoItemModalidadeBCICMS = ({ field, valueSelected, handleChange }) => {
  const useCustomComponentsStyle = makeStyles(CustomComponentsStyle);
  const classesCustomComponentsStyle = useCustomComponentsStyle();

  return (
    <FormControl
      fullWidth
      className={classesCustomComponentsStyle.formControlSelect}
      size="small"
      variant="outlined"
    >
      <InputLabel>Modalid de determ. da BC ICMS</InputLabel>
      <Select
        label="Modalid de determ. da BC ICMS"
        id={field}
        autoWidth
        value={valueSelected == null ? "" : valueSelected}
        onChange={(event) => {
          const {
            target: { value },
          } = event;
          return handleChange(field, value);
        }}
      >
        {Object.keys(NFNotaInfoItemModalidadeBCICMS).map((key) => {
          const { code, name } = NFNotaInfoItemModalidadeBCICMS[key];
          return (
            <MenuItem key={code} value={code}>
              {name}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default SelectNFNotaInfoItemModalidadeBCICMS;
