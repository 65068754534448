/* eslint-disable react/prop-types */
import React, { useCallback } from "react";
import { makeStyles } from "@mui/styles";
import CustomComponentsStyle from "assets/jss/material-dashboard-react/components/customComponentsStyle";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import PartilhaEstadoItem from "./PartilhaEstadoItem";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

const PartilhaEstado = ({ partilhaIcmsInterestadualValores, handleChange, errors = {} }) => {
  const classes = useStyles();
  const useCustomComponentsStyle = makeStyles(CustomComponentsStyle);
  const classesCustomComponentsStyle = useCustomComponentsStyle();

  const handleChangeStates = useCallback(
    (data) => {
      const index = partilhaIcmsInterestadualValores.findIndex((item) => item.id === data.id);
      const newPartilhaIcmsInterestadualValores = [...partilhaIcmsInterestadualValores];
      if (index >= 0) {
        newPartilhaIcmsInterestadualValores[index] = data;
      }
      handleChange(newPartilhaIcmsInterestadualValores);
    },
    [handleChange, partilhaIcmsInterestadualValores]
  );

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Estado</TableCell>
                <TableCell>Alíquota interestadual para produtos não importados</TableCell>

                <TableCell> Alíquota de ICMS interna do estado de destino (sem FCP)</TableCell>
                <TableCell>Alíquota de ICMS do Fundo Combate à Pobreza</TableCell>
                <TableCell>Percentual da base de cálculo</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {partilhaIcmsInterestadualValores.map((item) => (
                <PartilhaEstadoItem data={item} handleChange={handleChangeStates} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </GridItem>
    </GridContainer>
  );
};

export default React.memo(PartilhaEstado);
