const style = (theme) => (
    {
        custom: {
            fontSize: 20,
            [theme.breakpoints.down('custom')]: {
                fontSize: 16
            },
        },
        custom2: {
            fontSize: 25,
            [theme.breakpoints.down('custom')]: {
                fontSize: 18
            },
        }

    }

)

export default style;
