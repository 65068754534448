/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useCallback, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import CardStyle from "assets/jss/material-dashboard-react/components/cardStyle";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import Button from "components/CustomButtons/Button";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import CardFooter from "components/Card/CardFooter";
import { FormControlLabel, FormGroup, Switch } from "@mui/material";
import { useSnackbar } from "notistack";
import PartilhaIcmsService from "./service/PartilhaIcmsService";
import PartilhaIcms from "./model/PartilhaIcms";
import toRequest from "./converter/PartilhaIcmsConverter";
import { handlingResponseErrors } from "../../config/util";
import MESSAGES from "../../config/messages";
import Loading from "../../components/loading/Loading";
import PartilhaEstado from "./components/PartilhaEstado";

const { generalMessages } = MESSAGES;

const initial = {
  possuiPartilhaIcmsInterestadual: true,
  partilhaIcmsInterestadualValores: [],
};

const useCardStyle = makeStyles(CardStyle);

// eslint-disable-next-line react/prop-types
export default function PartilhaIcmsForm() {
  const classesCardStyle = useCardStyle();
  const { enqueueSnackbar } = useSnackbar();
  const [errors, setErrors] = useState({});

  const [loading, setLoading] = useState(false);

  const [partilha, setPartilha] = useState(new PartilhaIcms(initial));

  const handleChangePossuiPartilhaIcmsInterestadual = useCallback(
    (possuiPartilhaIcmsInterestadual) => {
      setPartilha({
        ...partilha,
        possuiPartilhaIcmsInterestadual,
      });
    },
    [partilha]
  );

  const handleChangeStates = useCallback(
    (partilhaIcmsInterestadualValores) => {
      setPartilha({
        ...partilha,
        partilhaIcmsInterestadualValores,
      });
    },
    [partilha]
  );

  const fetchAll = useCallback(async () => {
    setLoading(true);
    try {
      const result = await PartilhaIcmsService.customFetchAll();
      const { data } = result;
      const { content } = data;

      setPartilha(content?.[0] || initial);
    } catch (error) {
      enqueueSnackbar(generalMessages.error, {
        variant: "error",
      });
    } finally {
      setLoading(false);
    }
  }, [enqueueSnackbar]);

  useEffect(() => {
    fetchAll();
  }, [fetchAll]);

  const onSave = useCallback(async () => {
    try {
      setLoading(true);
      const { id } = partilha;
      await PartilhaIcmsService.update(id, toRequest(partilha));

      enqueueSnackbar(generalMessages.saveSuccess, {
        variant: "success",
        autoHideDuration: 3000,
      });
    } catch (error) {
      const { message, validationErrors } = handlingResponseErrors(error);
      enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
      if (validationErrors != null) {
        setErrors(validationErrors);
      }
    } finally {
      fetchAll();
      setLoading(false);
    }
  }, [partilha, enqueueSnackbar, fetchAll]);

  const renderSwitch = (value = false, label, disabled = false) => (
    <FormGroup style={{ paddingTop: "8px" }}>
      <FormControlLabel
        control={
          <Switch
            color="secondary"
            checked={value}
            onChange={(event) => handleChangePossuiPartilhaIcmsInterestadual(event.target.checked)}
          />
        }
        label={label}
        disabled={disabled}
      />
    </FormGroup>
  );

  return (
    <>
      <GridContainer style={{ justifyContent: "center" }}>
        <GridItem xs={12} sm={12} md={10}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classesCardStyle.cardTitle}>Partilha de ICMS</h4>
              <p className={classesCardStyle.cardCategory}>
                Partilha de ICMS em operações interestaduais (EC 87/2015)
              </p>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  {renderSwitch(
                    partilha.possuiPartilhaIcmsInterestadual,
                    "Possui partilha ICMS em operações interestaduais (EC 87/2015)"
                  )}
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  {partilha.possuiPartilhaIcmsInterestadual && (
                    <PartilhaEstado
                      handleChange={handleChangeStates}
                      partilhaIcmsInterestadualValores={partilha.partilhaIcmsInterestadualValores}
                    />
                  )}
                </GridItem>
              </GridContainer>
            </CardBody>
            <CardFooter>
              <Button color="primary" onClick={onSave}>
                {generalMessages.save}
              </Button>
            </CardFooter>
          </Card>
        </GridItem>
        <Loading loading={loading} />
      </GridContainer>
    </>
  );
}
