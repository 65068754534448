import React, { useState, useEffect, useCallback } from "react";
import { useDebounce } from "use-debounce";
import { makeStyles } from "@mui/styles";
import { faKey } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { primaryColor } from "assets/jss/material-dashboard-react";
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
} from "@mui/material";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import CardStyle from "assets/jss/material-dashboard-react/components/cardStyle";
import { handlingResponseErrors, isPasswordsEquals } from "../../config/util";
import Searchbar from "../../components/Searchbar/Searchbar";
import { Roles } from "./model/User";
import UserService from "./service/UserService";
import MESSAGES from "../../config/messages";
import { SEARCH } from "../../config/constants";
import Loading from "../../components/loading/Loading";
import AddButton from "../../components/addButton/AddButton";
import EditIcon from "../../components/icons/EditIcon";
import DataGridCustom from "../../components/dataGrid/DataGridCustom";
import TextPassword from "../../components/textInput/TextPassword";

const { generalMessages, userMessages } = MESSAGES;

const useCardStyle = makeStyles(CardStyle);

export default function UserList() {
  const classesuseCardStyle = useCardStyle();
  const history = useHistory();
  const [list, setList] = useState([]);
  const [openUpdatePassword, setOpenUpdatePassword] = useState(false);
  const [userSelected, setUserSelected] = useState({ changePasswordFirstAccess: false });
  const [searchTerm, setSearchTerm] = useState("");
  const [searchTermDebounce] = useDebounce(searchTerm, SEARCH.WAIT_INTERVAL);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const { enqueueSnackbar } = useSnackbar();

  const fetchAll = useCallback(async () => {
    if (searchTermDebounce !== "" && searchTermDebounce.length < SEARCH.MINIMUM_CHARACTERS) return;

    setLoading(true);
    try {
      const result = await UserService.fetchAll(searchTermDebounce);
      const { data } = result;
      const { content } = data;
      setList(content);
    } catch (error) {
      enqueueSnackbar(generalMessages.error, {
        variant: "error",
      });
    } finally {
      setLoading(false);
    }
  }, [enqueueSnackbar, searchTermDebounce]);

  useEffect(() => {
    fetchAll();
  }, [fetchAll]);

  const goToForm = (params) =>
    params?.id ? history.push(`/app/user/${params?.id}`) : history.push("/app/user");

  const formatterRoles = (value) => {
    if (value != null && value !== undefined) {
      const hasAdmin = value.some((item) => item.name === Roles.ADMIN.code);
      const hasUser = value.some((item) => item.name === Roles.USER.code);
      const hasEmployee = value.some((item) => item.name === Roles.EMPLOYEE.code);
      const hasAccountant = value.some((item) => item.name === Roles.ACCOUNTANT.code);
      if (hasAdmin) {
        return Roles.ADMIN.name;
      }
      if (hasUser) {
        return Roles.USER.name;
      }
      if (hasEmployee) {
        return Roles.EMPLOYEE.name;
      }
      if (hasAccountant) {
        return Roles.ACCOUNTANT.name;
      }
    }
    return "";
  };

  const updatePassword = useCallback((user) => {
    setUserSelected(user);
    setOpenUpdatePassword(true);
  }, []);

  const hasRole = useCallback(
    (user, role) => user.roles?.map((item) => item.name).indexOf(role) !== -1,
    []
  );

  const isEmployee = useCallback((user) => hasRole(user, Roles.EMPLOYEE.code), [hasRole]);

  const columns = [
    {
      field: "code",
      headerName: userMessages.code,
      flex: 1,
      minWidth: 100,
    },
    {
      field: "name",
      headerName: userMessages.name,
      flex: 1,
      minWidth: 200,
    },
    {
      field: "email",
      headerName: userMessages.email,
      flex: 1,
      minWidth: 250,
    },
    {
      field: "roles",
      headerName: userMessages.roles,
      flex: 1,
      minWidth: 150,
      valueGetter: ({ value }) => formatterRoles(value),
    },
    {
      field: "active",
      headerName: "Ativo",
      flex: 2,
      minWidth: 120,
      valueGetter: ({ value }) => (value ? "Sim" : "Não"),
    },
    {
      field: "actions",
      headerName: generalMessages.actions,
      sortable: false,
      width: 100,
      disableClickEventBubbling: true,
      renderCell: (params) => (
        <div
          style={{
            cursor: "pointer",
            justifyContent: "space-between",
            padding: "10px",
            display: "flex",
            flex: 1,
          }}
        >
          <EditIcon onClick={() => goToForm({ id: params.row.id })} />
          {!isEmployee(params.row) && (
            <FontAwesomeIcon
              icon={faKey}
              onClick={() => updatePassword(params.row)}
              fontSize={20}
              title="Alterar senha"
              color="#686868"
            />
          )}
        </div>
      ),
    },
  ];

  const handleChange = useCallback(
    (field, value) => {
      setUserSelected({
        ...userSelected,
        [field]: value,
      });
    },
    [userSelected]
  );

  const onUpdatePassword = useCallback(async () => {
    try {
      setLoading(true);
      if (!isPasswordsEquals(userSelected.password, userSelected.passwordRepeat)) {
        setErrors({
          ...errors,
          passwordRepeat: "As senhas devem ser iguais",
        });
      } else {
        const request = {
          userId: userSelected.id,
          password: userSelected.password,
          passwordRepeat: userSelected.passwordRepeat,
          changePasswordFirstAccess: userSelected.changePasswordFirstAccess,
        };

        await UserService.updatePassword(request);

        enqueueSnackbar(generalMessages.saveSuccess, {
          variant: "success",
          autoHideDuration: 3000,
        });
        setOpenUpdatePassword(false);
      }
    } catch (error) {
      const { message, validationErrors } = handlingResponseErrors(error);
      enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
      if (validationErrors != null) {
        setErrors(validationErrors);
      }
    } finally {
      setLoading(false);
    }
  }, [userSelected, setOpenUpdatePassword, enqueueSnackbar, errors]);

  const renderPassword = (disabled = false) => (
    <>
      <GridItem xs={12} sm={12} md={12}>
        <TextPassword
          id="password"
          label={userMessages.password}
          value={userSelected.password}
          onChange={handleChange}
          required
          disabled={disabled}
          errors={errors}
        />
      </GridItem>
      <GridItem xs={12} sm={12} md={12}>
        <TextPassword
          id="passwordRepeat"
          label={userMessages.passwordRepeat}
          value={userSelected.passwordRepeat}
          onChange={handleChange}
          disabled={disabled}
          required
          errors={errors}
        />
      </GridItem>
      <GridItem xs={12} sm={12} md={12} style={{ display: "flex", marginTop: 10 }}>
        <FormControlLabel
          control={
            <Checkbox
              color="secondary"
              checked={userSelected.changePasswordFirstAccess}
              onChange={(value) =>
                handleChange("changePasswordFirstAccess", value.currentTarget.checked)
              }
            />
          }
          label="Alterar senha no primeiro acesso"
        />
      </GridItem>
    </>
  );

  const renderModalUpdatePassword = () => (
    <Dialog disableEscapeKeyDown open={openUpdatePassword}>
      <DialogTitle>Alteração de senha do usuário {userSelected?.name || ""}</DialogTitle>
      <DialogContent>
        <GridContainer>{renderPassword()}</GridContainer>
      </DialogContent>
      <DialogActions>
        <div style={{ flexGrow: 1, display: "flex", justifyContent: "space-between" }}>
          <Button
            style={{ color: primaryColor[0] }}
            onClick={() => setOpenUpdatePassword(false)}
            autoFocus
          >
            Cancelar
          </Button>

          <Button style={{ color: primaryColor[0] }} onClick={onUpdatePassword}>
            Salvar
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card className="gridLayoutCard">
          <div className="gridLayoutContainer">
            <CardHeader color="primary">
              <h4 className={classesuseCardStyle.cardTitleList}>{userMessages.users}</h4>
              <p className={classesuseCardStyle.cardCategoryList}>{userMessages.usersTable}</p>
            </CardHeader>

            <div className="gridLayoutFilters">
              <Searchbar
                onChange={setSearchTerm}
                searchTerm={searchTerm}
                placeholder={userMessages.searchbar}
              />
            </div>

            <div className="gridLayoutGrid">
              <DataGridCustom onRowDoubleClick={goToForm} rows={list} columns={columns} />
            </div>

            <div className="gridLayoutBottom">
              <AddButton onClick={() => goToForm(null)} />
            </div>
          </div>
        </Card>
      </GridItem>
      {renderModalUpdatePassword()}
      <Loading loading={loading} />
    </GridContainer>
  );
}
