/* eslint-disable prefer-destructuring */
import {
  UpdatePriceType,
  UpdatePriceMinimumType,
  UpdateCostType,
} from "views/inventory/model/InventoryProduct";
import { NFOrigem } from "views/naturezaOperacao/model/NaturezaOperacao";

const toRequest = (list) => {
  const newList = list.map((item) => {
    const {
      id,
      notaFiscal,
      importarProduto,
      controlaEstoque,
      custo,
      custoMedio,
      preco,
      precoMinimo,
      updateCostType,
      updatePriceType,
      updatePriceMinimumType,
      numeroItem,
    } = item;

    const {
      codigo,
      codigoDeBarrasGtin,
      ncm,
      codigoEspecificadorSituacaoTributaria,
      unidadeComercial,
      codigoDeBarrasGtinTributavel,
      unidadeTributavel,
    } = notaFiscal;

    let quantidadeComercial = notaFiscal.quantidadeComercial;
    const unidadeImportada = item.unidadeImportada;
    const quantidadeImportada = item.quantidadeImportada;
    let descricao = notaFiscal.descricao;
    let origemIcms = null;
    let extras = {};

    const { produto } = item;

    if (produto != null && Object.keys(produto).length > 0) {
      extras = {
        origemIcms: notaFiscal.origemIcms,
        updateCostType,
        updatePriceType,
        updatePriceMinimumType,
        produtoSelecionadoId: produto.id,
      };
    } else {
      origemIcms = item.origemIcms;
      descricao = item.descricao;
      quantidadeComercial = item.quantidadeComercial;
    }

    return {
      notaFiscalProdutoId: id,
      codigo,
      codigoDeBarrasGtin,
      descricao,
      ncm,
      codigoEspecificadorSituacaoTributaria,
      unidadeComercial,
      quantidadeComercial,
      unidadeImportada,
      quantidadeImportada,
      codigoDeBarrasGtinTributavel,
      unidadeTributavel,
      origemIcms,
      importarProduto,
      controlaEstoque,
      custo,
      custoMedio,
      preco,
      precoMinimo,
      numeroItem,
      ...extras,
    };
  });
  return newList;
};

const fromResponse = (list) => {
  const newList = list.map((item, index) => {
    const {
      id,
      codigo,
      codigoDeBarrasGtin,
      descricao,
      ncm,
      codigoEspecificadorSituacaoTributaria,
      unidadeComercial,
      quantidadeComercial,
      unidadeImportada,
      quantidadeImportada,
      codigoDeBarrasGtinTributavel,
      unidadeTributavel,
      numeroItem,
      valorUnitario,
      inventoryMovement,
    } = item;

    let { origemIcms, produtosEncontrados } = item;
    const countProdutosEncontrados = produtosEncontrados.length;
    const produtoExistente = countProdutosEncontrados > 0;
    const produtoJaImportado = inventoryMovement != null;

    const notaFiscal = {
      codigo,
      codigoDeBarrasGtin,
      descricao,
      ncm,
      codigoEspecificadorSituacaoTributaria,
      unidadeComercial,
      quantidadeComercial,
      unidadeImportada,
      quantidadeImportada,
      codigoDeBarrasGtinTributavel,
      unidadeTributavel,
      origemIcms,
      valorUnitario,
    };

    let extras = {};

    // let produtoExistente = true;

    // let { produto } = item;
    let produto = {};
    if (produtoExistente) {
      produtosEncontrados = produtosEncontrados.map((itemProd) => {
        const {
          cost,
          priceMinimum,
          saleValue,
          inventoryQuantity,
          industryCode,
          brand,
          ...rest
        } = itemProd;

        return {
          ...rest,
          custo: cost,
          codigoFabricante: industryCode,
          marca: brand,
          precoMinimo: priceMinimum,
          preco: saleValue,
          quantidade: inventoryQuantity,
        };
      });
      if (countProdutosEncontrados === 1) {
        produto = produtosEncontrados[0];
        const { custo, precoMinimo, preco, quantidade, ...rest } = produto;

        produto = {
          ...rest,
          custo,
          precoMinimo,
          preco,
          quantidade,
        };
      } else {
        // Quando tem mais de 1 produto, o combo vem vazio, para selecionar.
        produto = {
          id: null,
          custo: 0,
          precoMinimo: 0,
          preco: 0,
          quantidade: 0,
        };
      }

      extras = {
        custo: valorUnitario,
        custoMedio: 0, // valor sera atualizado na tela
        updateCostType: UpdateCostType.ENTRY.code,
        updatePriceType: UpdatePriceMinimumType.MARGIN.code,
        updatePriceMinimumType: UpdatePriceType.MARGIN.code,
      };
    } else {
      if (origemIcms == null) {
        origemIcms = NFOrigem.NACIONAL.value;
      }
      extras = {
        descricao,
        quantidadeComercial,
        unidadeImportada,
        quantidadeImportada,
        unidadeComercial,
        // custoMedio: item.valorUnitario,
        custo: item.valorUnitario,
        precoMinimo: item.valorUnitario,
        preco: item.valorUnitario * 2, // 100% de margem
        origemIcms,
      };
    }

    const counter = index + 1;

    const expandido = !produtoExistente;
    const controlaEstoque = true;
    const importarProduto = true;

    return {
      id,
      counter,
      produtoExistente,
      produto,
      notaFiscal,
      importarProduto,
      controlaEstoque,
      expandido,
      numeroItem,
      produtoJaImportado,
      produtosEncontrados,
      ...extras,
    };
  });
  return newList;
};

export { toRequest, fromResponse };
