/* eslint-disable react/jsx-props-no-spreading */
import React, {useCallback, useEffect, useReducer, useState} from "react";
import { makeStyles } from "@mui/styles";
import CardStyle from "assets/jss/material-dashboard-react/components/cardStyle";
import Autocomplete from "components/autocomplete/Autocomplete";
import { DatePicker } from "@mui/x-date-pickers";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import Button from "components/CustomButtons/Button";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import CardFooter from "components/Card/CardFooter";
import { useHistory, useParams } from "react-router-dom";
import {Box, Divider, FormControlLabel, FormGroup, Switch, Typography} from "@mui/material";
import { useSnackbar } from "notistack";
import moment from "moment";
import CommissionConfig from "./model/CommissionConfig";
import { toRequest, fromResponse } from "./converter/CommissionConfigConverter";
import Service from "./service/CommissionConfigService";
import { handlingResponseErrors } from "../../config/util";
import MESSAGES from "../../config/messages";
import Loading from "../../components/loading/Loading";
import reducer from "./reducer/CommissionConfigReducer";
import CurrencyTextInput from "../../components/textInput/CurrencyTextInput";
import UserService from "../user/service/UserService";
import TooltipCustom from "../../components/tooltipCustom/TooltipCustom";
import TextInput from "../../components/textInput/TextInput";
import SelectEnum from "../../components/selectEnum/SelectEnum";
import ProductSuppliers from "../product/components/ProductSuppliers";
import CommissionGoal from "./components/CommissionGoal";

const { generalMessages, commissionMessages } = MESSAGES;

const useCardStyle = makeStyles(CardStyle);

export default function SupplierForm() {
  const classesCardStyle = useCardStyle();
  const history = useHistory();
  const routerParams = useParams();
  const { enqueueSnackbar } = useSnackbar();

  const initialState = { item: new CommissionConfig({ active: true }), errors: {}, loading: false };
  const [state, dispatch] = useReducer(reducer, initialState);
  const [goals, setGoals] = useState([]);

  const fetchById = useCallback(async () => {
    try {
      dispatch({ type: "setLoading", value: true });
      const { id } = routerParams || {};
      if (id != null) {
        const result = await Service.fetchById(id);
        const response = fromResponse(result?.data?.data);
        const { goals: goalsResponse, ...rest } = response;
        dispatch({ type: "setItem", value: response });
        setGoals(goalsResponse);
      }
    } finally {
      dispatch({ type: "setLoading", value: false });
    }
  }, [routerParams]);

  useEffect(() => {
    fetchById();
  }, [fetchById]);

  const goBack = useCallback(() => {
    history.push("/app/commission-configurations");
  }, [history]);

  const onSave = useCallback(async () => {
    try {
      const { item } = state;
      dispatch({ type: "setLoading", value: true });
      const { id } = item;
      if (id != null) {
        await Service.update(id, toRequest(item, goals));
      } else {
        await Service.save(toRequest(item, goals));
      }
      enqueueSnackbar(generalMessages.saveSuccess, {
        variant: "success",
        autoHideDuration: 3000,
      });
      goBack();
    } catch (error) {
      const { message, validationErrors } = handlingResponseErrors(error);
      enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
      if (validationErrors != null) {
        dispatch({ type: "setErrors", value: validationErrors });
      }
    } finally {
      dispatch({ type: "setLoading", value: false });
    }
  }, [state, goBack, enqueueSnackbar, goals]);

  const renderSwitch = (actionType, value = false, field, label, disabled = false) => (
    <FormGroup style={{ paddingTop: "8px" }}>
      <FormControlLabel
        control={
          <Switch
            color="secondary"
            checked={value}
            onChange={(event) =>
              dispatch({
                type: actionType,
                field,
                value: event.target.checked,
              })
            }
          />
        }
        label={label}
        disabled={disabled}
      />
    </FormGroup>
  );

  const { item, errors, loading } = state;

  const handleGoals = useCallback((value) => setGoals(value), []);

  const renderGoals = () => (
      item.goal && (
        <>
          <GridItem xs={12} sm={12} md={4}>
            <SelectEnum
              label="Fechamento Automático"
              field="goalAutomaticClosing"
              id="goalAutomaticClosing"
              valueSelected={item.goalAutomaticClosing}
              errors={errors}
              handleChange={(field, value) =>
                dispatch({ type: "handleChange", field: "goalAutomaticClosing", value })
              }
              Enum={{
                NAO: {
                  code: false,
                  name: "Não, fechar de forma manual quando necessário",
                },
                SIM: {
                  code: true,
                  name: "Sim, fechar automaticamente em um dia específico",
                },
              }}
            />
          </GridItem>
          {item.goalAutomaticClosing && (
            <GridItem xs={8} sm={8} md={3}>
              <TextInput
                id="goalAutomaticClosingDay"
                maxLength={20}
                label="Dia do fechamento"
                value={item.goalAutomaticClosingDay}
                onChange={(field, value) =>
                  dispatch({ type: "handleChange", field: "goalAutomaticClosingDay", value })
                }
                errors={errors}
              />
            </GridItem>
          )}
          <GridItem xs={12} sm={12} md={7}>
            <SelectEnum
              label="Base para Cálculo da Meta"
              field="targetCalculationMethod"
              id="targetCalculationMethod"
              valueSelected={item.targetCalculationMethod}
              errors={errors}
              handleChange={(field, value) =>
                dispatch({ type: "handleChange", field: "targetCalculationMethod", value })
              }
              Enum={{
                TOTAL_VALUE: {
                  code: 'TOTAL_VALUE',
                  name: "Calcula a meta com base no valor combinado de produtos e serviços (Produtos + Serviços)",
                },
                SEPARATE_VALUES: {
                  code: 'SEPARATE_VALUES',
                  name: "Calcula a meta separadamente para produtos e serviços (Produtos e Serviços)",
                },
              }}
            />
          </GridItem>
          <CommissionGoal items={goals} handleChange={handleGoals} errors={errors} />
        </>
      )
  );

  return (
    <>
      <GridContainer style={{ justifyContent: "center" }}>
        <GridItem xs={12} sm={12} md={10}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classesCardStyle.cardTitle}>{commissionMessages.commissionConfig}</h4>
              <p className={classesCardStyle.cardCategory}>
                {commissionMessages.commissionConfigForm}
              </p>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <span
                    style={{
                      fontWeight: "normal",
                      fontSize: "0.9em",
                      color: "#666",
                      display: "block",
                    }}
                  >
                    As datas não são obrigatórias. Caso não sejam preenchidas, o sistema continuará operando sem restrições por período.
                  </span>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <span
                    style={{
                      fontWeight: "normal",
                      fontSize: "0.9em",
                      color: "#666",
                      display: "block",
                      marginBottom: "10px",
                    }}
                  >
                    O profissional não é obrigatório. Caso não seja preenchido, o sistema irá calcular a mesma comissão para todos os profissionais.
                  </span>
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  <DatePicker
                    id="start"
                    label={commissionMessages.startDate}
                    format="dd/MM/yyyy"
                    slotProps={{ textField: { size: "small" } }}
                    value={item.startDate ? moment(item.startDate).valueOf() : null}
                    helperText={errors.startDate}
                    onChange={(date) =>
                      dispatch({ type: "handleChange", field: "startDate", value: date })
                    }
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  <DatePicker
                    id="end"
                    label={commissionMessages.endDate}
                    format="dd/MM/yyyy"
                    slotProps={{ textField: { size: "small" } }}
                    onChange={(date) =>
                      dispatch({ type: "handleChange", field: "endDate", value: date })
                    }
                    value={item.endDate ? moment(item.endDate).valueOf() : null}
                    helperText={errors.endDate}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={12}/>
                <GridItem xs={12} sm={12} md={4}>
                  <Autocomplete
                    variant="outlined"
                    fieldsToShowAfterSelectItem={["name"]}
                    label={commissionMessages.user}
                    placeholder={commissionMessages.autocompleteUser}
                    service={UserService}
                    value={item.user}
                    onChange={(value) => dispatch({ type: "handleChange", field: "user", value })}
                    error={errors.user}
                  />
                </GridItem>

                <GridItem xs={12} sm={12} md={4}>
                  <>
                    <SelectEnum
                      label={commissionMessages.receiptInInstallments}
                      field="receiptInInstallments"
                      id="receiptInInstallments"
                      valueSelected={item.receiptInInstallments}
                      errors={errors}
                      handleChange={(field, value) =>
                        dispatch({ type: "handleChange", field: "receiptInInstallments", value })
                      }
                      Enum={{
                        NAO: {
                          code: false,
                          name:
                            "Não, receber comissão de uma vez, independentemente do parcelamento",
                        },
                        SIM: {
                          code: true,
                          name: "Sim, receber comissão parcelada conforme a venda",
                        },
                      }}
                    />
                  </>
                </GridItem>

                <GridItem xs={12} sm={12} md={4}>
                  <>
                    <SelectEnum
                      label={commissionMessages.onAllSales}
                      field="onAllSales"
                      id="onAllSales"
                      valueSelected={item.onAllSales}
                      errors={errors}
                      handleChange={(field, value) =>
                        dispatch({ type: "handleChange", field: "onAllSales", value })
                      }
                      Enum={{
                        NAO: {
                          code: false,
                          name: "Não, receber comissão apenas sobre suas próprias vendas",
                        },
                        SIM: {
                          code: true,
                          name:
                            "Sim, receber comissão sobre todas as suas vendas e as de outros profissionais",
                        },
                      }}
                    />
                  </>
                </GridItem>

                <GridItem xs={12} sm={12} md={3}>
                  <SelectEnum
                    label="Remover comissão ao cancelar venda/ordem de serviço"
                    field="removeOnCancelOrder"
                    id="removeOnCancelOrder"
                    valueSelected={item.removeOnCancelOrder}
                    errors={errors}
                    handleChange={(field, value) =>
                      dispatch({ type: "handleChange", field: "removeOnCancelOrder", value })
                    }
                    Enum={{
                      SIM: {
                        code: true,
                        name: "Sim",
                      },
                      NAO: {
                        code: false,
                        name: "Não",
                      },
                    }}
                  />
                </GridItem>

                <GridItem xs={12} sm={12} md={3}>
                  <SelectEnum
                    label="Ativo"
                    field="active"
                    id="active"
                    valueSelected={item.active}
                    errors={errors}
                    handleChange={(field, value) =>
                      dispatch({ type: "handleChange", field: "active", value })
                    }
                    Enum={{
                      SIM: {
                        code: true,
                        name: "Sim",
                      },
                      NAO: {
                        code: false,
                        name: "Não",
                      },
                    }}
                  />
                </GridItem>

                <GridItem xs={12} sm={12} md={12} style={{ marginTop: 30 }}>
                  <Divider style={{ width: "100%", marginBottom: 4 }} />
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  <SelectEnum
                    label="Utilizar metas"
                    field="goal"
                    id="goal"
                    valueSelected={item.goal}
                    errors={errors}
                    handleChange={(field, value) =>
                      dispatch({ type: "handleChange", field: "goal", value })
                    }
                    Enum={{
                      SIM: {
                        code: true,
                        name: "Sim",
                      },
                      NAO: {
                        code: false,
                        name: "Não",
                      },
                    }}
                  />
                </GridItem>

                <>{renderGoals()}</>

                {!item.goal && (
                  <>
                    <GridItem xs={12} sm={12} md={3}>
                      <CurrencyTextInput
                        id="serviceCommission"
                        currencySymbol="%"
                        label={commissionMessages.serviceCommission}
                        value={item.serviceCommission || "0"}
                        onChange={(field, value) =>
                          dispatch({ type: "handleChange", field: "serviceCommission", value })
                        }
                        errors={errors}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={3}>
                      <CurrencyTextInput
                        id="productCommission"
                        currencySymbol="%"
                        label={commissionMessages.productCommission}
                        value={item.productCommission || "0"}
                        onChange={(field, value) =>
                          dispatch({ type: "handleChange", field: "productCommission", value })
                        }
                        errors={errors}
                      />
                    </GridItem>
                  </>
                )}
              </GridContainer>
            </CardBody>
            <CardFooter>
              <Button color="primary" onClick={goBack}>
                {generalMessages.cancel}
              </Button>
              <Button color="primary" onClick={onSave}>
                {generalMessages.save}
              </Button>
            </CardFooter>
          </Card>
        </GridItem>
        <Loading loading={loading} />
      </GridContainer>
    </>
  );
}
