import React, {
  useState,
  useCallback,
  useEffect,
  useReducer,
  useContext,
  useRef,
  useMemo,
} from "react";
import CustomComponentsStyle from "assets/jss/material-dashboard-react/components/customComponentsStyle";
import CardStyle from "assets/jss/material-dashboard-react/components/cardStyle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDollarSign, faListAlt } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import { useSnackbar } from "notistack";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import ButtonCustom from "components/CustomButtons/Button";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  IconButton,
  Box,
  TextField,
  Chip,
  Collapse,
  FormGroup,
  FormControlLabel,
  Switch,
} from "@mui/material";
import useConfirmDialog from "components/confirm/ConfirmDialog";
import Autocomplete from "components/autocomplete/Autocomplete";
import { makeStyles } from "@mui/styles";
import { DatePicker } from "@mui/x-date-pickers";
import PersonService from "views/person/service/PersonService";
import { RegistrationType } from "views/person/model/Person";
import FinancialAccountsService from "views/financialAccounts/service/FinancialAccountsService";
import AccordionFilters from "components/Accordion/AccordionFilters";
import CurrencyTextInput from "../../components/textInput/CurrencyTextInput";
import TextInput from "../../components/textInput/TextInput";
import Loading from "../../components/loading/Loading";
import { primaryColor } from "../../assets/jss/material-dashboard-react";
import MESSAGES from "../../config/messages";
import Service from "./service/ReceiveService";
import AddButton from "../../components/addButton/AddButton";
import { ReceiveRepeatType, Receive, ReceiveOrigin, PaymentStatusType } from "./model/Receive";
import { handlingResponseErrors, currencyFormatter, isEmptyString } from "../../config/util";
import { toRequest, fromResponse } from "./converter/ReceiveConverter";
import ReceivementsModal from "./components/ReceivementsModal";
import InstallmentsModal from "./components/InstallmentsModal";
import NextIcon from "../../components/icons/NextIcon";
import BackIcon from "../../components/icons/BackIcon";
import EditIcon from "../../components/icons/EditIcon";
import DeleteIcon from "../../components/icons/DeleteIcon";
import DataGridCustom from "../../components/dataGrid/DataGridCustom";
import UserContext from "../../core/UserContext";

const useCustomComponentsStyle = makeStyles(CustomComponentsStyle);
const useCardStyle = makeStyles(CardStyle);

const { receiveMessages, generalMessages } = MESSAGES;

const formatDate = (date) => date.format("YYYY-MM-DD");
const formatDatePT = (date) => date.format("DD/MM/YYYY");

const defaultPeriod = generalMessages.PERIOD_OPTIONS[2];

export default function ReceiveComponent() {
  const customGridRef = useRef();
  const [rowCount, setRowCount] = React.useState(0);
  const { userLogged } = useContext(UserContext);
  const classesuseCardStyle = useCardStyle();
  const classesCustomComponentsStyle = useCustomComponentsStyle();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const [openReceivementsModal, setOpenReceivementsModal] = useState(false);
  const [openInstallmentsModal, setOpenInstallmentsModal] = useState(false);
  const [values, setValues] = useState({ received: 0, total: 0 });
  const { showConfirmDialog, closeConfirmDialog, ConfirmDialog } = useConfirmDialog();
  const [receive, setReceive] = useState(new Receive());
  const [list, setList] = useState([]);
  const [financialAccounts, setFinancialAccounts] = useState([]);
  const [loading, setLoading] = useState(false);

  const [errors, setErrors] = useState({});

  const SearchAll = useMemo(
    () => ({
      id: "ALL",
      name: "Todas",
    }),
    []
  );

  const getDates = (momentCode, start, end) => {
    let startDate = null;
    let endDate = null;
    if (momentCode === "Y") {
      startDate = start.startOf("year");
      endDate = end.endOf("year");
    } else if (momentCode === "M") {
      startDate = start.startOf("month");
      endDate = end.endOf("month");
    } else if (momentCode === "W") {
      startDate = start.startOf("week");
      endDate = end.endOf("week");
    } else if (momentCode === "days") {
      startDate = start;
      endDate = end;
    }
    return { startDate, endDate };
  };

  const getInitialSearchState = useCallback(() => {
    const { startDate, endDate } = getDates(defaultPeriod.momentCode, moment(), moment());
    return {
      now: moment(),
      startDate,
      endDate,
      selectPeriod: defaultPeriod,
      customer: "",
      orderCode: "",
      lateOrdersOnly: false,
      financialAccount: SearchAll,
    };
  }, [SearchAll]);

  const searchReducer = (state, action) => {
    switch (action.type) {
      case "setSelectPeriod": {
        const newSelectPeriod = action.value;
        const { startDate, endDate } = getDates(newSelectPeriod.momentCode, moment(), moment());
        return {
          ...state,
          startDate,
          endDate,
          selectPeriod: newSelectPeriod,
        };
      }
      case "setDates": {
        const { startDate, endDate } = action.value;
        return {
          ...state,
          startDate,
          endDate,
        };
      }
      case "setCustomer": {
        return {
          ...state,
          customer: action.value,
        };
      }
      case "setOrderCode": {
        return {
          ...state,
          orderCode: action.value,
        };
      }
      case "setLateOrdersOnly": {
        return {
          ...state,
          lateOrdersOnly: action.value,
        };
      }
      case "setFinancialAccount": {
        return {
          ...state,
          financialAccount: action.value,
        };
      }
      case "reset": {
        return {
          ...getInitialSearchState(),
        };
      }
      default:
        throw new Error("Action not defined");
    }
  };

  const [searchState, dispatchSearch] = useReducer(searchReducer, getInitialSearchState());

  const handleChange = useCallback(
    (field, value) => {
      setReceive({
        ...receive,
        [field]: value,
      });
    },
    [receive]
  );

  const showCarousel = useCallback((params) => {
    const { customer, orderCode, lateOrdersOnly } = params;
    return isEmptyString(customer) && isEmptyString(orderCode) && !lateOrdersOnly;
  }, []);

  const fetchValues = useCallback(
    async (queryParams) => {
      const { startDate, endDate, financialAccount } = queryParams;
      const params = [];

      if (startDate != null) {
        params.push(`startDate=${formatDate(startDate)}`);
      }
      if (endDate != null) {
        params.push(`endDate=${formatDate(endDate)}`);
      }

      if (financialAccount != null && financialAccount.id !== SearchAll.id) {
        params.push(`financialAccountId=${financialAccount.id}`);
      }

      const result = await Service.featchReceivesValues(params.join("&"));
      const { data } = result;
      setValues(data);
    },
    [SearchAll.id]
  );

  const fetchSearch = useCallback(
    async ({ queryParams, paginationModelParams, sorting }) => {
      setLoading(true);
      try {
        const {
          customer,
          orderCode,
          startDate,
          endDate,
          lateOrdersOnly,
          financialAccount,
        } = queryParams;
        const params = [];

        const pagination =
          paginationModelParams || customGridRef.current.getDefaultPaginationModel();

        if (!isEmptyString(customer)) {
          params.push(`customer=${customer}`);
        }
        if (!isEmptyString(orderCode)) {
          params.push(`orderCode=${orderCode}`);
        }
        if (startDate != null) {
          params.push(`startDate=${formatDate(startDate)}`);
        }
        if (endDate != null) {
          params.push(`endDate=${formatDate(endDate)}`);
        }
        if (lateOrdersOnly != null) {
          params.push(`lateOrdersOnly=${lateOrdersOnly}`);
        }

        if (financialAccount != null && financialAccount.id !== SearchAll.id) {
          params.push(`financialAccountId=${financialAccount.id}`);
        }

        const result = await Service.featchReceives({
          params: params.join("&"),
          pagination,
          sorting,
        });
        const { data } = result;
        const { totalElements } = data;
        let { content } = data;
        content = content.map((item) => fromResponse(item));
        setList(content);
        setRowCount(totalElements);
        fetchValues(queryParams);
      } catch (error) {
        enqueueSnackbar(generalMessages.error, {
          variant: "error",
        });
      } finally {
        setLoading(false);
      }
    },
    [SearchAll.id, enqueueSnackbar, fetchValues]
  );

  const onDelete = useCallback(
    async ({ id }) => {
      showConfirmDialog({
        title: "Recebimento",
        text: generalMessages.deleteConfirm,
        onClickConfirm: async () => {
          try {
            await Service.delete(id);
            enqueueSnackbar(generalMessages.deleteSuccess, {
              variant: "success",
            });
            customGridRef.current.resetPaginationModel();
            fetchSearch({ queryParams: searchState });
          } catch (error) {
            enqueueSnackbar(generalMessages.error, {
              variant: "error",
            });
          }
          closeConfirmDialog();
        },
      });
    },
    [showConfirmDialog, closeConfirmDialog, enqueueSnackbar, fetchSearch, searchState]
  );

  const onEdit = useCallback(
    ({ id }) => {
      const itemSelected = list.find((item) => item.id === id);
      if (itemSelected.receiveOriginType === ReceiveOrigin.MANUALLY.code) {
        setReceive(new Receive(itemSelected));
        setOpen(true);
      }
    },
    [list]
  );

  const onAddReceipts = useCallback(
    (id) => {
      const itemSelected = list.find((item) => item.id === id);
      setReceive(new Receive(itemSelected));
      setOpenReceivementsModal(true);
    },
    [list]
  );

  const onShowInstallments = useCallback(
    (id) => {
      const itemSelected = list.find((item) => item.id === id);
      setReceive(new Receive(itemSelected));
      setOpenInstallmentsModal(true);
    },
    [list]
  );

  const onClose = useCallback(() => {
    setOpen(false);
    setReceive(new Receive({ repeatType: ReceiveRepeatType.N.code, startDate: new Date() }));
  }, []);

  const onSave = useCallback(async () => {
    try {
      setLoading(true);
      const { id } = receive;
      const user = {
        id: userLogged.userId,
        name: userLogged.name,
      };
      if (id != null) {
        await Service.update(id, toRequest(receive, user));
      } else {
        await Service.save(toRequest(receive, user));
      }
      setOpen(false);
      setErrors({});

      enqueueSnackbar(generalMessages.saveSuccess, {
        variant: "success",
        autoHideDuration: 3000,
      });

      setOpenReceivementsModal(false);
      customGridRef.current.resetPaginationModel();
      fetchSearch({ queryParams: searchState });
    } catch (error) {
      const { message, validationErrors } = handlingResponseErrors(error);
      enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
      if (validationErrors != null) {
        setErrors(validationErrors);
      }
    } finally {
      setLoading(false);
    }
  }, [receive, userLogged?.userId, userLogged?.name, enqueueSnackbar, fetchSearch, searchState]);

  const fetchFinancialAccounts = useCallback(async () => {
    const response = await FinancialAccountsService.fetchActives();
    setFinancialAccounts(response?.data || []);
  }, []);

  useEffect(() => {
    fetchSearch({ queryParams: getInitialSearchState() });
    fetchFinancialAccounts();
  }, [fetchSearch, getInitialSearchState, fetchFinancialAccounts]);

  const handleOpen = useCallback(() => {
    const defaultFinancialAccount = financialAccounts.find((item) => item.standard);
    setReceive(
      new Receive({
        repeatType: ReceiveRepeatType.N.code,
        receiveOriginType: ReceiveOrigin.MANUALLY.code,
        startDate: new Date(),
        financialAccount: defaultFinancialAccount,
      })
    );
    setOpen(true);
  }, [financialAccounts]);

  const formatterReceiveOriginType = (item) => {
    const { receiveOriginType } = item;
    if (receiveOriginType != null && receiveOriginType !== undefined) {
      return ReceiveOrigin[receiveOriginType].name;
    }
    return "";
  };

  const formatterPaymentStatusType = (item) => {
    const { paymentStatusType = "" } = item;

    switch (paymentStatusType) {
      case PaymentStatusType.PAID.code:
        return (
          <Chip
            size="small"
            label={PaymentStatusType[paymentStatusType].name}
            style={{ backgroundColor: "#0e850b", color: "#ffff" }}
          />
        );

      case PaymentStatusType.PARTIALLY_PAID.code:
        return (
          <Chip
            size="small"
            label={PaymentStatusType[paymentStatusType].name}
            style={{ backgroundColor: "#035afc", color: "#ffff" }}
          />
        );

      default:
        return (
          <Chip
            size="small"
            label={PaymentStatusType.PENDING.name}
            style={{ backgroundColor: "#AA810D", color: "#ffff" }}
          />
        );
    }
  };

  const columns = [];

  columns.push({
    field: "financialAccount",
    headerName: "Conta financeira destino",
    flex: 1,
    minWidth: 220,
    valueGetter: ({ value }) => value?.name || "",
  });

  columns.push({
    field: "name",
    headerName: receiveMessages.name,
    minWidth: 250,
    flex: 2,
  });
  columns.push({
    field: "customer",
    headerName: receiveMessages.customer,
    minWidth: 250,
    flex: 2,
    valueGetter: ({ value }) => value?.name || "",
  });
  columns.push({
    field: "value",
    headerName: receiveMessages.value,
    minWidth: 120,
    flex: 1,
    valueGetter: ({ value }) => currencyFormatter(value || 0),
  });

  columns.push({
    field: "startDate",
    headerName: receiveMessages.date,
    minWidth: 150,
    flex: 1,
    valueGetter: ({ value }) => moment(value).format("DD/MM/YYYY"),
  });

  columns.push(
    {
      field: "receiveOriginType",
      headerName: receiveMessages.origin,
      flex: 1,
      minWidth: 180,
      renderCell: ({ row: item }) => formatterReceiveOriginType(item),
    },
    {
      field: "paymentStatusType",
      headerName: receiveMessages.paymentStatusType,
      flex: 1,
      minWidth: 180,
      renderCell: ({ row: item }) => formatterPaymentStatusType(item),
    }
  );

  const shouldShowReceivements = ({ paymentMethod, receiveOriginType }) => {
    const allowsInstallments = paymentMethod?.allowsInstallments || false;
    const creditCard = paymentMethod?.creditCard || false;
    const isManually = receiveOriginType === ReceiveOrigin.MANUALLY.code;

    if ((allowsInstallments && !creditCard) || isManually) {
      return true;
    }
    return false;
  };

  const shouldShowInstallments = ({ paymentMethod, receiveOriginType }) => {
    const isManually = receiveOriginType === ReceiveOrigin.MANUALLY.code;

    return !isManually;
  };

  columns.push({
    field: "actions",
    headerName: generalMessages.actions,
    sortable: false,
    width: 140,
    disableClickEventBubbling: true,
    renderCell: ({ row }) => (
      <div
        style={{
          cursor: "pointer",
          justifyContent: "flex-end",
          display: "flex",
          flex: 1,
        }}
      >
        {row.receiveOriginType === ReceiveOrigin.MANUALLY.code && (
          <>
            <EditIcon onClick={() => onEdit(row)} style={{ padding: 4 }} />
            <DeleteIcon onClick={() => onDelete(row)} style={{ padding: 4 }} />
          </>
        )}
        {shouldShowReceivements(row) && (
          <FontAwesomeIcon
            icon={faDollarSign}
            onClick={() => onAddReceipts(row.id)}
            fontSize={20}
            title="Recebimentos"
            color="#686868"
            style={{ padding: 4 }}
          />
        )}
        {shouldShowInstallments(row) && (
          <FontAwesomeIcon
            icon={faListAlt}
            onClick={() => onShowInstallments(row.id)}
            fontSize={20}
            title="Parcelas"
            color="#686868"
            style={{ padding: 4 }}
          />
        )}
      </div>
    ),
  });

  const renderSwitchPaid = (value = false, field, label, disabled = false) => (
    <FormGroup style={{ paddingTop: "8px" }}>
      <FormControlLabel
        control={
          <Switch
            color="secondary"
            checked={value === PaymentStatusType.PAID.code}
            onChange={(event) =>
              handleChange(
                field,
                event.target.checked ? PaymentStatusType.PAID.code : PaymentStatusType.PENDING.code
              )
            }
          />
        }
        label={label}
        disabled={disabled}
      />
    </FormGroup>
  );

  const showPaidSwitch = () => {
    const { id, valueReceived } = receive;
    const show = id == null || valueReceived === null || valueReceived === 0;
    return show;
  };

  const focusInputField = useCallback((input) => {
    if (input) {
      setTimeout(() => {
        input.focus();
      }, 100);
    }
  }, []);

  const renderModal = () => (
    <Dialog disableEscapeKeyDown open={open} fullWidth maxWidth="sm">
      <DialogTitle>{receiveMessages.title}</DialogTitle>
      <DialogContent>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <TextInput
              inputRef={focusInputField}
              id="name"
              label={receiveMessages.name}
              value={receive.name}
              onChange={handleChange}
              required
              errors={errors}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <Autocomplete
              // autoFocus
              variant="outlined"
              fieldsToShowAfterSelectItem={["name"]}
              label={receiveMessages.customer}
              placeholder={receiveMessages.autocompleteCustomer}
              service={PersonService}
              extraSearch={{ registrationType: RegistrationType.CUSTOMER.code }}
              value={receive.customer}
              onChange={(value) => handleChange("customer", value)}
              // renderOption={(item) => `${item.name}`}
              error={errors.customer}
              helperText={errors.customer}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <FormControl
              fullWidth
              className={classesCustomComponentsStyle.formControlSelect}
              size="small"
              required
            >
              <InputLabel>Conta financeira destino</InputLabel>
              <Select
                label="Conta financeira destino"
                id="financialAccount"
                autoWidth
                value={receive.financialAccount == null ? "" : receive.financialAccount.id}
                // eslint-disable-next-line arrow-body-style
                onChange={(event, child) => {
                  const { objectValue } = child.props;
                  return handleChange("financialAccount", objectValue);
                }}
                required
                error={errors.paymentMethod}
                helperText={errors.paymentMethod}
              >
                {financialAccounts.map((item) => (
                  <MenuItem key={item.id} value={item.id} objectValue={item}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </GridItem>
          <GridItem xs={8} sm={8} md={6}>
            <CurrencyTextInput
              id="value"
              label={receiveMessages.value}
              value={receive.value}
              onChange={handleChange}
              required
              errors={errors}
              disabled={receive.valueReceived != null && receive.valueReceived !== 0}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={6}>
            <FormControl
              fullWidth
              className={classesCustomComponentsStyle.formControlSelect}
              size="small"
            >
              <InputLabel>{receiveMessages.repeat}</InputLabel>
              <Select
                label={receiveMessages.repeat}
                id="repeatType"
                autoWidth
                value={receive.repeatType == null ? "" : receive.repeatType}
                onChange={(value) => handleChange("repeatType", value.target.value)}
                required
                error={errors.repeatType}
                helperText={errors.repeatType}
              >
                {Object.entries(ReceiveRepeatType).map((item) => {
                  const repeat = item[1];
                  return (
                    <MenuItem key={repeat.code} value={repeat.code}>
                      {repeat.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </GridItem>

          {receive.repeatType === ReceiveRepeatType.N.code ? (
            <GridItem xs={12} sm={12} md={6} style={{ marginTop: "12px" }}>
              <DatePicker
                id="startDate"
                label={receiveMessages.date}
                format="dd/MM/yyyy"
                slotProps={{ textField: { size: "small" } }}
                value={receive?.startDate ? moment(receive.startDate).valueOf() : null}
                helperText={errors.startDate}
                onChange={(date) => handleChange("startDate", date)}
              />
            </GridItem>
          ) : (
            <>
              <GridItem xs={12} sm={12} md={6} style={{ marginTop: "12px" }}>
                <DatePicker
                  id="startDate"
                  label={receiveMessages.date}
                  format="dd/MM/yyyy"
                  slotProps={{ textField: { size: "small" } }}
                  value={receive?.startDate ? moment(receive.startDate).valueOf() : null}
                  helperText={errors.startDate}
                  onChange={(date) => handleChange("startDate", date)}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={6} style={{ marginTop: "12px" }}>
                <DatePicker
                  id="endDate"
                  label={receiveMessages.endDate}
                  format="dd/MM/yyyy"
                  slotProps={{ textField: { size: "small" } }}
                  value={receive?.endDate ? moment(receive.endDate).valueOf() : null}
                  helperText={errors.endDate}
                  onChange={(date) => handleChange("endDate", date)}
                />
              </GridItem>
            </>
          )}

          {showPaidSwitch() && (
            <GridItem xs={12} sm={12} md={12}>
              {renderSwitchPaid(receive.paymentStatusType, "paymentStatusType", "Pago")}
            </GridItem>
          )}
        </GridContainer>
      </DialogContent>
      <DialogActions>
        <div style={{ flexGrow: 1, display: "flex", justifyContent: "space-between" }}>
          <Button style={{ color: primaryColor[0] }} onClick={onClose}>
            {receiveMessages.cancel}
          </Button>
          <Button style={{ color: primaryColor[0] }} onClick={onSave}>
            {receiveMessages.save}
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );

  const renderTotalCard = () => (
    <Box display="flex" justifyContent="flex-end">
      <Box display="flex" justifyContent="flex-end">
        <Box
          display="flex"
          style={{
            borderRadius: 4,
            padding: 15,
            marginTop: -20,
            color: "#ffff",
            background: "linear-gradient(60deg, #66bb6a, #43a047)",
          }}
        >
          <Box display="flex" flexDirection="column">
            <Typography style={{ alignSelf: "center", fontSize: 16 }}>À receber</Typography>
            <Typography style={{ alignSelf: "center", fontSize: 22 }}>
              {currencyFormatter((values.total || 0) - (values.received || 0))}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box display="flex" justifyContent="flex-end">
        <Box
          display="flex"
          style={{
            borderRadius: 4,
            marginLeft: 10,
            padding: 15,
            marginTop: -20,
            color: "#ffff",
            background: "linear-gradient(60deg, #66bb6a, #43a047)",
          }}
        >
          <Box display="flex" flexDirection="column">
            <Typography style={{ alignSelf: "center", fontSize: 16 }}>Recebido + juros</Typography>
            <Typography style={{ alignSelf: "center", fontSize: 22 }}>
              {currencyFormatter((values.received || 0) + (values.interest || 0))}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box display="flex" justifyContent="flex-end">
        <Box
          display="flex"
          style={{
            borderRadius: 4,
            marginLeft: 10,
            padding: 15,
            marginTop: -20,
            color: "#ffff",
            background: "linear-gradient(60deg, #66bb6a, #43a047)",
          }}
        >
          <Box display="flex" flexDirection="column">
            <Typography style={{ alignSelf: "center", fontSize: 16 }}>Total</Typography>
            <Typography style={{ alignSelf: "center", fontSize: 22 }}>
              {currencyFormatter(values.total || 0)}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );

  const onKeyPressSearch = useCallback(
    (ev) => {
      if (ev.which === 13) {
        customGridRef.current.resetPaginationModel();
        fetchSearch({ queryParams: searchState });
      }
    },
    [fetchSearch, searchState]
  );

  const renderSelectPeriodSearch = () => (
    <FormControl variant="outlined" size="small" fullWidth>
      <Select
        autoWidth
        value={searchState.selectPeriod.id}
        onChange={(event, child) => {
          const { objectValue } = child.props;
          dispatchSearch({ type: "setSelectPeriod", value: objectValue });
          const { startDate, endDate } = getDates(objectValue.momentCode, moment(), moment());
          customGridRef.current.resetPaginationModel();
          fetchSearch({
            queryParams: {
              ...searchState,
              startDate,
              endDate,
            },
          });
        }}
        required
      >
        {generalMessages.PERIOD_OPTIONS.map((item) => (
          <MenuItem key={item.id} value={item.id} objectValue={item}>
            {item.value}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );

  const renderFinancialAccountSearch = () => (
    <FormControl variant="outlined" size="small" fullWidth style={{ marginTop: 12 }}>
      <InputLabel>Conta financeira destino</InputLabel>
      <Select
        label="Conta financeira destino"
        id="financialAccount"
        autoWidth
        value={searchState?.financialAccount?.id}
        onChange={(event, child) => {
          const { objectValue } = child.props;
          dispatchSearch({ type: "setFinancialAccount", value: objectValue });
          customGridRef.current.resetPaginationModel();
          fetchSearch({
            queryParams: {
              ...searchState,
              financialAccount: objectValue,
            },
          });
        }}
      >
        <MenuItem key={SearchAll.id} value={SearchAll.id} objectValue={SearchAll}>
          {SearchAll.name}
        </MenuItem>
        {financialAccounts.map((item) => (
          <MenuItem key={item.id} value={item.id} objectValue={item}>
            {item.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );

  const renderTextCarousel = () => {
    const { startDate, selectPeriod } = searchState;
    const sd = startDate.clone();
    const { momentCode } = selectPeriod;
    const texts = [];

    if (momentCode === "Y") {
      texts.push(moment(sd).year());
    } else if (momentCode === "M") {
      texts.push(moment(sd).format(" MMMM YYYY"));
    } else if (momentCode === "W") {
      texts.push(formatDatePT(sd.startOf("week")));
      texts.push(" - ");
      texts.push(formatDatePT(sd.endOf("week")));
    } else if (momentCode === "days") {
      texts.push(formatDatePT(sd));
    }
    return texts.join(" ");
  };

  const onSearch = useCallback(() => {
    customGridRef.current.resetPaginationModel();
    fetchSearch({ queryParams: searchState });
  }, [fetchSearch, searchState]);

  const onClear = useCallback(() => {
    dispatchSearch({ type: "reset" });
    customGridRef.current.resetPaginationModel();
    fetchSearch({ queryParams: getInitialSearchState() });
  }, [fetchSearch, getInitialSearchState]);

  const renderCarousel = () => {
    const { startDate, endDate, selectPeriod } = searchState;
    const { momentCode } = selectPeriod;
    const startMoment = moment(startDate);
    const endMoment = moment(endDate);
    return (
      <>
        <GridContainer style={{ paddingTop: "12px" }}>
          <GridItem xs={12} sm={12} md={9}>
            <Box display="flex" flexDirection="row" justifyContent="space-between" flexGrow={1}>
              <IconButton
                style={{ backgroundColor: "transparent" }}
                onClick={() => {
                  const start = startMoment.subtract(1, momentCode);
                  const end = endMoment.subtract(1, momentCode);
                  const { startDate: newStartDate, endDate: newEndDate } = getDates(
                    momentCode,
                    start,
                    end
                  );
                  dispatchSearch({
                    type: "setDates",
                    value: { startDate: newStartDate, endDate: newEndDate },
                  });
                  customGridRef.current.resetPaginationModel();
                  fetchSearch({
                    queryParams: {
                      ...searchState,
                      startDate: newStartDate,
                      endDate: newEndDate,
                    },
                  });
                }}
              >
                <BackIcon />
              </IconButton>
              <Typography
                style={{
                  alignSelf: "center",
                  fontSize: 18,
                  fontWeight: 500,
                  textTransform: "uppercase",
                }}
              >
                {renderTextCarousel()}
              </Typography>
              <IconButton
                style={{ backgroundColor: "transparent" }}
                onClick={() => {
                  const start = startMoment.add(1, momentCode);
                  const end = endMoment.add(1, momentCode);
                  const { startDate: newStartDate, endDate: newEndDate } = getDates(
                    momentCode,
                    start,
                    end
                  );
                  dispatchSearch({
                    type: "setDates",
                    value: { startDate: newStartDate, endDate: newEndDate },
                  });
                  customGridRef.current.resetPaginationModel();
                  fetchSearch({
                    queryParams: {
                      ...searchState,
                      startDate: newStartDate,
                      endDate: newEndDate,
                    },
                  });
                }}
              >
                <NextIcon />
              </IconButton>
            </Box>
          </GridItem>
          <GridItem xs={12} sm={12} md={1}>
            {renderSelectPeriodSearch()}
          </GridItem>
        </GridContainer>
      </>
    );
  };

  const renderSearch = () => (
    <GridContainer>
      <GridItem xs={12} sm={12} md={2}>
        <TextField
          size="small"
          onKeyPress={onKeyPressSearch}
          variant="outlined"
          label={receiveMessages.orderCodeSearch}
          fullWidth
          InputLabelProps={{
            className: classesCustomComponentsStyle.labelTextField,
          }}
          className={classesCustomComponentsStyle.textField}
          value={searchState.orderCode == null ? "" : searchState.orderCode}
          onChange={(value) =>
            dispatchSearch({ type: "setOrderCode", value: value.currentTarget.value })
          }
        />
      </GridItem>
      <GridItem xs={12} sm={12} md={3}>
        <TextField
          size="small"
          onKeyPress={onKeyPressSearch}
          variant="outlined"
          label={receiveMessages.customerSearch}
          fullWidth
          InputLabelProps={{
            className: classesCustomComponentsStyle.labelTextField,
          }}
          className={classesCustomComponentsStyle.textField}
          value={searchState.customer == null ? "" : searchState.customer}
          onChange={(value) =>
            dispatchSearch({ type: "setCustomer", value: value.currentTarget.value })
          }
        />
      </GridItem>
      <GridItem xs={12} sm={12} md={2}>
        {renderFinancialAccountSearch()}
      </GridItem>
      <GridItem xs={12} sm={12} md={2}>
        <FormGroup style={{ paddingTop: 12 }}>
          <FormControlLabel
            control={
              <Switch
                color="secondary"
                checked={searchState.lateOrdersOnly}
                onChange={(event) => {
                  const lateOrdersOnly = event.target.checked;
                  customGridRef.current.resetPaginationModel();
                  fetchSearch({
                    queryParams: { ...searchState, lateOrdersOnly },
                  });
                  dispatchSearch({ type: "setLateOrdersOnly", value: lateOrdersOnly });
                }}
              />
            }
            label="Apenas atrasados"
          />
        </FormGroup>
      </GridItem>
      <GridItem
        xs={12}
        sm={12}
        md={2}
        style={{
          marginTop: "5px",
          alignItems: "center",
          display: "flex",
        }}
      >
        <ButtonCustom color="primary" onClick={onSearch}>
          {generalMessages.search}
        </ButtonCustom>
        <ButtonCustom color="primary" onClick={onClear}>
          Limpar
        </ButtonCustom>
      </GridItem>
    </GridContainer>
  );

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card className="gridLayoutCard">
          <div className="gridLayoutContainer">
            <CardHeader color="primary">
              <Box display="flex" flexDirection="row" justifyContent="space-between">
                <Box display="flex" flexDirection="column">
                  <h4 className={classesuseCardStyle.cardTitleList}>{receiveMessages.titleCard}</h4>
                  <p className={classesuseCardStyle.cardCategoryList}>
                    {receiveMessages.receivesTable}
                  </p>
                </Box>
                {renderTotalCard()}
              </Box>
            </CardHeader>

            <div className="gridLayoutFilters">
              <AccordionFilters screen="receive_list">
                <Collapse in={showCarousel(searchState)} timeout="auto" unmountOnExit>
                  {renderCarousel()}
                </Collapse>
                {renderSearch()}
              </AccordionFilters>
            </div>

            <div className="gridLayoutGrid">
              <DataGridCustom
                ref={customGridRef}
                onRowDoubleClick={onEdit}
                rows={list}
                columns={columns}
                usePagination
                rowCount={rowCount}
                fetchAction={(params) =>
                  fetchSearch({
                    ...params,
                    queryParams: searchState,
                  })
                }
              />
            </div>

            <div className="gridLayoutBottom">
              <AddButton onClick={handleOpen} />
            </div>

            {open && renderModal()}
            {openReceivementsModal && (
              <ReceivementsModal
                open={openReceivementsModal}
                payments={receive?.payments ? [...receive.payments] : []}
                handleChangePayments={handleChange}
                total={receive.value}
                onSave={onSave}
                onClose={() => setOpenReceivementsModal(false)}
              />
            )}

            {openInstallmentsModal && (
              <InstallmentsModal
                open={openInstallmentsModal}
                total={receive.value}
                receiveId={receive.id}
                onClose={() => setOpenInstallmentsModal(false)}
              />
            )}
          </div>
        </Card>
      </GridItem>

      <Loading loading={loading} />
      <ConfirmDialog />
    </GridContainer>
  );
}
