/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useState } from "react";
import CustomComponentsStyle from "assets/jss/material-dashboard-react/components/customComponentsStyle";
import { makeStyles } from "@mui/styles";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  Button as ButtonUI,
  Grid,
  Divider,
  Typography,
  FormHelperText,
} from "@mui/material";
import { useSnackbar } from "notistack";
import TextInput from "components/textInput/TextInput";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { primaryColor, dangerColor } from "assets/jss/material-dashboard-react";
import DeleteIcon from "components/icons/DeleteIcon";
import CurrencyTextInput from "components/textInput/CurrencyTextInput";
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import PaymentMethodService from "../../paymentMethod/service/PaymentMethodService";
import {NFMeioPagamento} from "../../paymentMethod/model/PaymentMethod";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

const NotaFiscalFormaPagamento = ({ items = [], handleChange, errors = {}, disabled = false }) => {
  const useCustomComponentsStyle = makeStyles(CustomComponentsStyle);
  const classesCustomComponentsStyle = useCustomComponentsStyle();
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [somaValorFormaPagamento, setSomaValorFormaPagamento] = useState(0);

  const fetchPaymentMethods = useCallback(async () => {
    const payments = await PaymentMethodService.fetchActives();
    setPaymentMethods(payments?.data || []);
  }, []);

  useEffect(() => {
    fetchPaymentMethods();
  }, [fetchPaymentMethods]);

  useEffect(() => {
    setSomaValorFormaPagamento(
      items.reduce((acumulador, fp) => parseFloat(acumulador) + parseFloat(fp.valor), 0)
    );
  }, [items]);

  const add = useCallback(() => {
    const newItem = { index: items.length };
    const newItems = [...items, newItem];
    handleChange(newItems);
  }, [handleChange, items]);

  const remove = useCallback(
    (i) => {
      let newItems = [...items];
      const index = newItems.findIndex((item) => item.index === i);
      newItems.splice(index, 1);
      // Changing the indexes
      newItems = newItems.map((item, idx) => ({ ...item, index: idx }));
      handleChange(newItems);
    },
    [handleChange, items]
  );

  const onChange = useCallback(
    (field, value, index) => {
      const newItems = [...items];

      const item = newItems[index];

      newItems[index] = {
        ...item,
        [field]: value,
      };

      handleChange(newItems);
    },
    [handleChange, items]
  );

  const renderAddButton = () => (
    <GridItem xs={12} sm={12} md={3}>
      <Box display="flex" style={{ marginTop: 12 }}>
        <ButtonUI style={{ color: primaryColor[0] }} onClick={add}>
          Adicionar
        </ButtonUI>
      </Box>
    </GridItem>
  );

  const renderPaymentMethods = (item) => (
    <GridItem xs={12} sm={12} md={4}>
      <FormControl
        disabled={disabled}
        fullWidth
        className={classesCustomComponentsStyle.formControlSelect}
        size="small"
        variant="outlined"
        required
        error={errors[`notaFiscalFormaPagamentos[${item.index}].formaPagamento`]}
      >
        <InputLabel>Forma de pagamento</InputLabel>
        <Select
          label="Forma de pagamento"
          id="formaPagamento"
          autoWidth
          value={item.formaPagamento == null ? "" : item.formaPagamento.id}
          // eslint-disable-next-line arrow-body-style
          onChange={(event, child) => {
            const { objectValue } = child.props;
            onChange("formaPagamento", objectValue, item.index);
          }}
          required
        >
          {paymentMethods.map((pm) => (
            <MenuItem key={pm.id} value={pm.id} objectValue={pm}>
              {pm.name}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText style={{ color: dangerColor[0] }}>
          {errors[`notaFiscalFormaPagamentos[${item.index}].formaPagamento`]}
        </FormHelperText>
      </FormControl>
    </GridItem>
  );

  const renderValor = (item) => (
    <GridItem xs={12} sm={12} md={2}>
      <CurrencyTextInput
        decimalPlaces={3}
        id={`notaFiscalFormaPagamentos[${item.index}].valor`}
        label="Valor"
        value={item.valor || "0"}
        onChange={(field, value) => onChange("valor", value, item.index)}
        required
        errors={errors}
        disabled={disabled}
      />
    </GridItem>
  );

  const renderValorFinal = () => (
    <GridItem xs={12} sm={12} md={2}>
      <CurrencyTextInput
        decimalPlaces={3}
        id="valorFinal"
        label="Valor Total"
        value={somaValorFormaPagamento || "0"}
        required
        errors={errors}
        disabled
      />
    </GridItem>
  );

  const renderVencimento = (item) => (
    <GridItem xs={12} sm={12} md={3} style={{ marginTop: "12px" }}>
      <DatePicker
        id="vencimento"
        label="Data vencimento"
        format="dd/MM/yyyy"
        slotProps={{ textField: { size: "small" } }}
        value={item?.vencimento ? moment(item.vencimento).valueOf() : null}
        helperText={errors[`notaFiscalFormaPagamentos[${item.index}].vencimento`]}
        onChange={(date) => onChange("vencimento", date, item.index)}
        disabled={disabled}
      />
    </GridItem>
  );

  // const renderNumero = (item) => (
  //   <GridItem xs={12} sm={12} md={2}>
  //     <TextInput
  //       id="numero"
  //       label="Número"
  //       value={item.numero}
  //       onChange={(field, value) => onChange(field, value, item.index)}
  //     />
  //   </GridItem>
  // );

  const renderItem = (item) => (
    <GridContainer>
      {/* {renderNumero(item)} */}
      {renderPaymentMethods(item)}
      {item?.formaPagamento?.nfPaymentMethod !== NFMeioPagamento.SEM_PAGAMENTO.code && renderVencimento(item)}
      {item?.formaPagamento?.nfPaymentMethod !== NFMeioPagamento.SEM_PAGAMENTO.code && renderValor(item)}
      <GridItem xs={12} sm={12} md={1}>
        <Box style={{ marginTop: 20 }}>
          {!disabled && <DeleteIcon onClick={() => remove(item.index)} />}
        </Box>
      </GridItem>
    </GridContainer>
  );

  const renderItems = () => {
    if (items.length === 0) {
      return add();
    }
    return items.map((item) => renderItem(item));
  };

  return (
    <Grid item xs={12} sm={12} md={12}>
      <GridItem xs={12} sm={12} md={12} style={{ marginTop: 30 }}>
        <Divider style={{ width: "100%", marginBottom: 4 }} />
        <Typography style={{ fontSize: 18, fontWeight: 500 }}>Forma de pagamento</Typography>
      </GridItem>
      {renderItems()}
      <GridContainer>
        {!disabled && renderAddButton()}
        <GridItem xs={12} sm={12} md={!disabled ? 4 : 7} />
        {renderValorFinal()}
      </GridContainer>
    </Grid>
  );
};

export default React.memo(NotaFiscalFormaPagamento);
