/* eslint-disable react/prop-types */
import React, { useState, useCallback, useEffect } from "react";
import { useSnackbar } from "notistack";
import GridContainer from "components/Grid/GridContainer";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  ListItem,
  ListItemText,
} from "@mui/material";

import moment from "moment";
import { primaryColor } from "../../assets/jss/material-dashboard-react";
import Loading from "../../components/loading/Loading";
import MESSAGES from "../../config/messages";
import ProductPriceChangeHistoryService from "./service/ProductPriceChangeHistoryService";
import {
  UpdateCostType,
  UpdatePriceMinimumType,
  UpdatePriceType,
} from "./model/ProductPriceChangeHistory";
import { currencyFormatter } from "../../config/util";
import GridItem from "../../components/Grid/GridItem";

const { generalMessages } = MESSAGES;

const InventoryMovementPriceChangeModal = ({ open, onClose, movement }) => {
  const [item, setItem] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);

  const fetch = useCallback(async () => {
    setLoading(true);
    try {
      const result = await ProductPriceChangeHistoryService.findMovementPriceChange(movement.id);
      const { data } = result;
      setItem(data || {});
    } catch (error) {
      enqueueSnackbar(error.response.status === 428 ? error.response.data : generalMessages.error, {
        variant: "error",
      });
    } finally {
      setLoading(false);
    }
  }, [enqueueSnackbar, movement]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  return (
    <>
      <Dialog disableEscapeKeyDown open={open} fullWidth maxWidth="md">
        <DialogTitle>Alterações de preço na movimentação</DialogTitle>
        <DialogContent>
          <GridContainer>
            <GridItem xs={12} sm={6} md={6}>
              <ListItem>
                <ListItemText
                  primary="Data:"
                  secondary={moment(item.createdAt).format("DD/MM/yyyy HH:mm")}
                />
              </ListItem>
            </GridItem>
            <GridItem xs={12} sm={6} md={6}>
              <ListItem>
                <ListItemText primary="Alterado por:" secondary={item?.user?.name} />
              </ListItem>
            </GridItem>
            <GridItem xs={12} sm={6} md={6}>
              <ListItem>
                <ListItemText
                  primary="Atualização do custo:"
                  secondary={
                    <>
                      <b>{UpdateCostType[item?.updateCostType]?.name}</b> de{" "}
                      <b>{currencyFormatter(item?.cost)}</b> para{" "}
                      <b>{currencyFormatter(item?.newCost)}</b>
                    </>
                  }
                />
              </ListItem>
            </GridItem>
            <GridItem xs={12} sm={6} md={6}>
              <ListItem>
                <ListItemText
                  primary="Atualização do preço mínimo:"
                  secondary={
                    <>
                      <b>{UpdatePriceMinimumType[item?.updatePriceMinimumType]?.name}</b> de{" "}
                      <b>{currencyFormatter(item?.priceMinimum)}</b> para{" "}
                      <b>{currencyFormatter(item?.newPriceMinimum)}</b>
                    </>
                  }
                />
              </ListItem>
            </GridItem>
            <GridItem xs={12} sm={6} md={6}>
              <ListItem>
                <ListItemText
                  primary="Atualização do preço:"
                  secondary={
                    <>
                      <b>{UpdatePriceType[item?.updatePriceType]?.name}</b> de{" "}
                      <b>{currencyFormatter(item?.price)}</b> para{" "}
                      <b>{currencyFormatter(item?.newPrice)}</b>
                    </>
                  }
                />
              </ListItem>
            </GridItem>
            {item?.inventoryMovement?.supplier?.name && (
              <GridItem xs={12} sm={6} md={6}>
                <ListItem>
                  <ListItemText
                    primary="Fornecedor:"
                    secondary={item?.inventoryMovement?.supplier?.name}
                  />
                </ListItem>
              </GridItem>
            )}
          </GridContainer>
        </DialogContent>
        <DialogActions>
          <div style={{ flexGrow: 1, display: "flex", justifyContent: "space-between" }}>
            <Button style={{ color: primaryColor[0] }} onClick={onClose}>
              Voltar
            </Button>
          </div>
        </DialogActions>
      </Dialog>
      <Loading loading={loading} />
    </>
  );
};

export default React.memo(InventoryMovementPriceChangeModal);
