import axios from "axios";
import { APIS } from "../../../config/constants";
import BaseService from "../../../core/BaseService";

const { register: registerAPI } = APIS;

class UnitConversionService extends BaseService {
  constructor() {
    super(registerAPI.unitConversion);
  }

  fetchAll = ({ searchTermDebounce, pagination, sorting }) => {
    const { page, pageSize } = pagination;
    const { field = "createdAt", sort = "desc" } = sorting || {};
    return axios.get(
      `${this.endpoint}/search?size=${pageSize}&page=${page}&sort=${field},${sort}&term=${searchTermDebounce}`
    );
  };

  fatorConversao = (sourceUnit, targetUnit) => axios.get(`${this.endpoint}/source/${sourceUnit}/target/${targetUnit}`);

  all = () => axios.get(`${this.endpoint}/all`);
}

export default new UnitConversionService();
