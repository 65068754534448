/* eslint-disable no-alert */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React, { useCallback } from "react";
import { useSnackbar } from "notistack";
import { Box } from "@mui/material";
import { useDropzone } from "react-dropzone";
import DeleteIcon from "components/icons/DeleteIcon";
import useConfirmDialog from "components/confirm/ConfirmDialog";

const thumb = {
  display: "inline-flex",
  borderRadius: 2,
  border: "1px solid #eaeaea",
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: "border-box",
};

const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
};

const img = {
  display: "block",
  width: "auto",
  height: "100%",
};

const MAX_SIZE = 1000000;

const KairumImageDropzone = ({
  textDropzone,
  handleChange,
  onDelete,
  item,
  error,
  fileTypes,
  loadLogo,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { showConfirmDialog, closeConfirmDialog, ConfirmDialog } = useConfirmDialog();
  const onDrop = useCallback(
    (acceptedFiles, rejectedFiles) => {
      handleChange(acceptedFiles?.[0] || null);
    },
    [handleChange]
  );

  // If return the code and message, it's possible get on the rejectedFiles.
  const validator = useCallback(
    (file) => {
      if (file.size > MAX_SIZE) {
        enqueueSnackbar(
          `O tamanho da imagem selecionada é de ${Number(file.size / 1000000).toFixed(
            2
          )}MB. Tamanho permitido:${MAX_SIZE / 1000000}MB`,
          {
            variant: "error",
            autoHideDuration: 8000,
          }
        );
      }
    },
    [enqueueSnackbar]
  );

  const handleDelete = useCallback(() => {
    showConfirmDialog({
      title: "Logo",
      text: "Tem certeza de que deseja remover o arquivo? Esta ação é irreversível!",
      onClickConfirm: () => {
        onDelete();
        closeConfirmDialog();
      },
    });
  }, [closeConfirmDialog, onDelete, showConfirmDialog]);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    // onDropRejected,
    validator,
    accept: fileTypes,
    maxSize: MAX_SIZE,
  });
  return (
    <Box>
      <Box>
        <div
          {...getRootProps({ className: "dropzone" })}
          style={{ borderColor: error ? "#f44336" : "#eeeeee" }}
        >
          <input {...getInputProps()} />
          <b>{textDropzone}</b>
        </div>
      </Box>

      {item != null && (
        <Box display="flex">
          <Box>
            <div style={thumb} key={item?.name}>
              <div style={thumbInner}>
                <img src={`data:image/jpeg;base64,${item}`} style={img} />
              </div>
            </div>
          </Box>
          <Box display="flex" alignItems="center">
            <DeleteIcon onClick={handleDelete} style={{ cursor: "pointer" }} />
          </Box>
        </Box>
      )}
      <ConfirmDialog />
    </Box>
  );
};

export default KairumImageDropzone;
