import { NFeInutilizacao } from "../model/NFeInutilizacao";

const toRequest = (entity) => {
  const { id, serie, modelo, justificativa, numeroInicial, numeroFinal, version, active } = entity;

  return {
    id,
    serie,
    modelo,
    justificativa,
    numeroInicial,
    numeroFinal,
    active,
    version,
  };
};

const fromResponse = (response) => {
  if (response === null) return new NFeInutilizacao();

  const {
    id,
    serie,
    modelo,
    justificativa,
    numeroInicial,
    numeroFinal,
    version,
    active,
    status,
  } = response;

  return new NFeInutilizacao({
    id,
    serie,
    modelo,
    justificativa,
    numeroInicial,
    numeroFinal,
    version,
    active,
    status,
  });
};

export { toRequest, fromResponse };
