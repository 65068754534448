/* eslint-disable react/prop-types */
import React from "react";
import { makeStyles } from "@mui/styles";
import CustomComponentsStyle from "assets/jss/material-dashboard-react/components/customComponentsStyle";
import { Box, Divider, FormControl, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import GridItem from "components/Grid/GridItem";
import TextInput from "components/textInput/TextInput";
import CurrencyTextInput from "components/textInput/CurrencyTextInput";
import MESSAGES from "config/messages";
import GridContainer from "components/Grid/GridContainer";
import { SituacaoTributariaCofins, TipoCalculoNFeType } from "../model/NaturezaOperacao";
import SelectTipoCalculo from "./SelectTipoCalculo";

const { naturezaOperacaoMessages } = MESSAGES;
const CHARACTER_LIMIT = 4000;

const NOCofinsTab = ({ data, handleChange, errors = {} }) => {
  const useCustomComponentsStyle = makeStyles(CustomComponentsStyle);
  const classesCustomComponentsStyle = useCustomComponentsStyle();

  const {
    situacaoTributariaCofins,
    aliquotaCofins,
    valorCofins,
    baseCalculoCofins,
    observacaoCofins,
    tipoCalculoCofins,
  } = data;

  const renderTipoCalculo = () => (
    <GridItem xs={8} sm={8} md={3}>
      <SelectTipoCalculo
        field="tipoCalculoCofins"
        valueSelected={tipoCalculoCofins}
        handleChange={handleChange}
      />
    </GridItem>
  );

  const renderBaseCalculoCofins = () => (
    <GridItem xs={8} sm={8} md={3}>
      <CurrencyTextInput
        decimalPlaces={4}
        currencySymbol="%"
        id="baseCalculoCofins"
        label={naturezaOperacaoMessages.cofins.baseCalculoCofins}
        value={baseCalculoCofins || "0"}
        onChange={handleChange}
        errors={errors}
      />
    </GridItem>
  );

  const renderAliquotaCofins = () => (
    <GridItem xs={8} sm={8} md={3}>
      <CurrencyTextInput
        currencySymbol="%"
        id="aliquotaCofins"
        label={naturezaOperacaoMessages.cofins.aliquotaCofins}
        value={aliquotaCofins || "0"}
        onChange={handleChange}
        errors={errors}
      />
    </GridItem>
  );

  const renderValorCofins = () => (
    <GridItem xs={8} sm={8} md={3}>
      <CurrencyTextInput
        currencySymbol="R$"
        id="valorCofins"
        label={naturezaOperacaoMessages.cofins.valorCofins}
        value={valorCofins || "0"}
        onChange={handleChange}
        errors={errors}
      />
    </GridItem>
  );

  const renderObservacaoCofins = () => (
    <GridItem xs={12} sm={12} md={12}>
      <TextInput
        id="observacaoCofins"
        label={naturezaOperacaoMessages.cofins.observacaoCofins}
        value={observacaoCofins}
        maxLength={CHARACTER_LIMIT}
        onChange={handleChange}
        isCounter
        multiline
        rows={4}
      />
    </GridItem>
  );

  const renderSituacaoTributariaCofins = () => (
    <GridItem xs={12} sm={12} md={12}>
      <FormControl
        fullWidth
        className={classesCustomComponentsStyle.formControlSelect}
        size="small"
        variant="outlined"
      >
        <InputLabel>{naturezaOperacaoMessages.cofins.situacaoTributariaCofins}</InputLabel>
        <Select
          label={naturezaOperacaoMessages.cofins.situacaoTributariaCofins}
          id="situacaoTributariaCofins"
          autoWidth
          value={situacaoTributariaCofins == null ? "" : situacaoTributariaCofins}
          onChange={(event) => {
            const {
              target: { value },
            } = event;
            return handleChange("situacaoTributariaCofins", value);
          }}
          // value={
          //   situacaoTributariaCofins == null
          //     ? SituacaoTributariaCofins.SELECIONE.value
          //     : situacaoTributariaCofins
          // }
          // onChange={(event) => {
          //   const {
          //     target: { value },
          //   } = event;
          //   return handleChange(
          //     "situacaoTributariaCofins",
          //     value === SituacaoTributariaCofins.SELECIONE.value ? null : value
          //   );
          // }}
          error={errors.situacaoTributariaCofins}
          helperText={naturezaOperacaoMessages.cofins.situacaoTributariaCofins}
        >
          {Object.keys(SituacaoTributariaCofins).map((key) => {
            const { code, name, value } = SituacaoTributariaCofins[key];
            return (
              <MenuItem key={code} value={value}>
                {code} - {name}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </GridItem>
  );

  const renderCofinsST = () => (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12} style={{ marginTop: 12 }}>
        {/* <Divider style={{ width: "100%", marginBottom: 4 }} /> */}
        <Typography style={{ fontSize: 18, fontWeight: 500 }}>Substituição tributária</Typography>
      </GridItem>
    </GridContainer>
  );

  // const renderCofins = () => (
  //   <GridContainer>
  //     <GridItem xs={12} sm={12} md={12} style={{ marginTop: 12 }}>
  //       <Divider style={{ width: "100%", marginBottom: 4 }} />
  //       <Typography style={{ fontSize: 18, fontWeight: 500 }}>COFINS</Typography>
  //     </GridItem>
  //   </GridContainer>
  // );

  const renderAliquotaOrValorByTipoCalculo = () => {
    if (tipoCalculoCofins === TipoCalculoNFeType.VALOR.code) {
      return renderValorCofins();
    }
    return [renderAliquotaCofins(), renderBaseCalculoCofins()];
  };

  const renderBySituacaoTributariaCofins = () => {
    const code = parseInt(SituacaoTributariaCofins[situacaoTributariaCofins].code, 10);

    if (code === 1 || code === 2) {
      return [
        /* renderCofins() */ renderAliquotaCofins(),
        renderBaseCalculoCofins(),
        renderObservacaoCofins(),
      ];
    }

    if (code === 3) {
      return [/* renderCofins() */ renderValorCofins(), renderObservacaoCofins()];
    }

    if (code === 5) {
      return [
        <GridItem xs={12} sm={12} md={12}>
          {renderCofinsST()}
        </GridItem>,
        renderTipoCalculo(),
        renderAliquotaOrValorByTipoCalculo(),
        renderObservacaoCofins(),
      ];
    }

    if (code >= 49 && code <= 99) {
      return [renderTipoCalculo(), renderAliquotaOrValorByTipoCalculo(), renderObservacaoCofins()];
    }
    return <Box />;
  };

  return (
    <GridContainer>
      {renderSituacaoTributariaCofins()}
      {renderBySituacaoTributariaCofins()}
    </GridContainer>
  );
};

export default React.memo(NOCofinsTab);
