class FinancialAccounts {
  constructor(source = {}) {
    this.id = source.id;
    this.name = source.name;
    this.standard = source.standard;
    this.active = source.active;
    this.version = source.version;
  }
}

export default FinancialAccounts;
