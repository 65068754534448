import ServiceProduct from "../model/ServiceProduct";

const toRequest = (item) => {
  const { id, name, saleValue, commissionable, additionalInformation, version, active, industryCode } = item;

  const inventoryQuantity = 0;
  const cost = 0;
  const supplier = null;
  const barCode = null;
  const profit = 0;
  const priceMinimum = 0;
  const localization = null;
  const service = true;

  return {
    id,
    name,
    supplier,
    industryCode,
    barCode,
    cost,
    saleValue,
    commissionable,
    profit,
    inventoryQuantity,
    additionalInformation,
    localization,
    priceMinimum,
    version,
    active,
    service,
  };
};

const fromResponse = (response) => {
  if (response === null) return new ServiceProduct();

  const {
    id,
    industryCode,
    name,
    saleValue,
    commissionable,
    additionalInformation,
    version,
    active,
    service,
  } = response;

  return new ServiceProduct({
    id,
    name,
    industryCode,
    saleValue,
    commissionable,
    additionalInformation,
    version,
    active,
    service,
  });
};

export { toRequest, fromResponse };
