const Style = {
  tooltip: {
    color: "rgba(0, 0, 0, 0.38)",
    transition: "0.2s",
    "&:hover": {
      color: "rgba(147, 42, 173, 0.8)",
    },
  },
};

export default Style;
