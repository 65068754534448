import moment from "moment";
import { Receive, ReceiveRepeatType } from "../model/Receive";

const toRequest = (receive, user) => {
  const {
    id,
    name,
    value,
    repeatType,
    parentId,
    version,
    customer,
    paymentStatusType,
    receiveOriginType,
    valueReceived,
    payments,
    receiveOriginId,
    paymentMethod,
    financialAccount
  } = receive;
  let { startDate, endDate } = receive;

  if (repeatType === ReceiveRepeatType.N.code) {
    endDate = startDate;
  }
  startDate = startDate != null ? moment(startDate).startOf("day").format() : null;
  endDate = endDate != null ? moment(endDate).endOf("day").format() : null;

  const newPayments =
    payments?.map((payment) => {
      const { dateReceipt } = payment;
      const dateReceiptFormatted = moment(dateReceipt).format();
      return {
        ...payment,
        dateReceipt: dateReceiptFormatted,
      };
    }) || [];

  return {
    id,
    name,
    startDate,
    endDate,
    value,
    user,
    repeatType,
    parentId,
    customer,
    paymentStatusType,
    receiveOriginType, // : ReceiveOrigin.MANUALLY.code,
    valueReceived,
    payments: newPayments,
    receiveOriginId,
    paymentMethod,
    financialAccount,
    version,
  };
};

const fromResponse = (response) => {
  if (response === null) return new Receive();

  const {
    id,
    name,
    startDate,
    endDate,
    user: userResponse,
    value,
    repeatType,
    customer,
    parentId,
    receiveOrigin,
    paymentStatusType,
    receiveOriginType,
    valueReceived,
    payments,
    receiveOriginId,
    paymentMethod,
    financialAccount,
    version,
  } = response;

  return new Receive({
    id,
    name,
    startDate,
    endDate,
    user: userResponse,
    value,
    repeatType,
    parentId,
    receiveOrigin,
    customer,
    paymentStatusType,
    receiveOriginType,
    valueReceived,
    payments,
    receiveOriginId,
    paymentMethod,
    financialAccount,
    version,
  });
};

export { toRequest, fromResponse };
