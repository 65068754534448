/* eslint-disable react/prop-types */
import React, { useState, useCallback, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import CustomComponentsStyle from "assets/jss/material-dashboard-react/components/customComponentsStyle";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  Checkbox,
  ListItemText,
  Divider,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import MultiAutoComplete from "components/autocomplete/MultiAutoComplete";
import ProductService from "views/product/service/ProductService";
import NcmService from "views/ncm/service/NcmService";
import { primaryColor } from "../../../assets/jss/material-dashboard-react";
import MESSAGES from "../../../config/messages";
import {
  NFOrigem,
  SituacaoOperacionalSimplesNacional,
  TipoExcecao,
  SituacaoTributariaIpi,
  SituacaoTributariaIssqn,
  SituacaoTributariaPis,
  SituacaoTributariaCofins,
  SituacaoTributariaIcms,
} from "../model/NaturezaOperacao";
import NaturezaOperacaoService from "../service/NaturezaOperacaoService";
import NOSimplesTab from "./NOSimplesTab";
import NOIpiTab from "./NOIpiTab";
import NOIssqnTab from "./NOIssqnTab";
import NOPisTab from "./NOPisTab";
import NOCofinsTab from "./NOCofinsTab";
import NOIcmsTab from "./NOIcmsTab";

const { naturezaOperacaoMessages } = MESSAGES;
const getInitial = (currentTab) => {
  const initial = {
    estados: [],
    produtos: [],
    origens: [],
    ncms: [],
  };

  switch (currentTab) {
    case TipoExcecao.SIMPLES:
      return {
        ...initial,
        tipoExcecao: TipoExcecao.SIMPLES,
        situacaoOperacaoSnSimples:
          SituacaoOperacionalSimplesNacional.TRIBUTADA_SEM_PERMISSAO_CREDITO.value,
        partilhaIcmsInterestadualSimples: true,
        obterIcmsStRetidoAnteriormenteNfCompra: false,
      };
    case TipoExcecao.ICMS:
      return {
        ...initial,
        tipoExcecao: TipoExcecao.ICMS,
        situacaoTributariaIcms: SituacaoTributariaIcms.TRIBUTACAO_INTEGRALMENTE.value,
        partilhaIcmsInterestadualIcms: true,
        obterIcmsStRetidoAnteriormenteNfCompraIcms: false,
      };

    case TipoExcecao.IPI:
      return {
        ...initial,
        tipoExcecao: TipoExcecao.IPI,
        situacaoTributariaIpi: SituacaoTributariaIpi.SELECIONE.value,
      };

    case TipoExcecao.ISSQN:
      return {
        ...initial,
        tipoExcecao: TipoExcecao.ISSQN,
        descontarIssIssqn: true,
        situacaoTributariaIssqn: SituacaoTributariaIssqn.ISENTA.value,
      };

    case TipoExcecao.PIS:
      return {
        ...initial,
        tipoExcecao: TipoExcecao.PIS,
        situacaoTributariaPis: SituacaoTributariaPis.OPERACAO_SEM_INCIDENCIA_CONTRIBUICAO.value,
      };

    case TipoExcecao.COFINS:
      return {
        ...initial,
        tipoExcecao: TipoExcecao.COFINS,
        situacaoTributariaCofins:
          SituacaoTributariaCofins.OPERACAO_SEM_INCIDENCIA_CONTRIBUICAO.value,
      };

    default:
      return initial;
  }
};

const NOExceptionModal = ({
  open,
  currentTab,
  onConfirm,
  onClose,
  exceptionItem,
  exceptions = [],
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const useCustomComponentsStyle = makeStyles(CustomComponentsStyle);
  const classesCustomComponentsStyle = useCustomComponentsStyle();
  const [errors, setErrors] = useState({});
  const [states, setStates] = useState([]);
  const [exception, setException] = useState(getInitial(currentTab));

  useEffect(() => {
    if (!exceptionItem || exceptionItem == null) {
      setException(getInitial(currentTab));
    } else {
      setException(exceptionItem);
    }
  }, [currentTab, exceptionItem]);

  const close = useCallback(() => {
    onClose();
    setException(getInitial(currentTab));
  }, [currentTab, onClose]);

  const confirm = useCallback(() => {
    const { index, estados } = exception;

    if (estados && estados.length === 0) {
      enqueueSnackbar("Nenhum estado foi adicionado!", {
        variant: "error",
        autoHideDuration: 3000,
      });
      setErrors({ estados: "Campo obrigatório" });
      return;
    }

    let newIndex = index;

    if (newIndex == null) {
      newIndex = exceptions.length;
    }

    onConfirm({
      ...exception,
      index: newIndex,
    });
    setErrors({});
    onClose();
    setException(getInitial(currentTab));
  }, [currentTab, enqueueSnackbar, exception, exceptions.length, onClose, onConfirm]);

  const handleChange = useCallback(
    (field, value) => {
      setException({
        ...exception,
        [field]: value,
      });
    },
    [exception]
  );

  const fetchStates = useCallback(async () => {
    try {
      // setLoading(true);
      const result = await NaturezaOperacaoService.fetchStates();
      const { data } = result;
      const { content } = data;
      setStates(content);
    } finally {
      // setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchStates();
  }, [fetchStates]);

  const renderConfigs = () => (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12} style={{ marginTop: 12 }}>
        <Typography style={{ fontSize: 18, fontWeight: 500 }}>Estado(s) / Produto(s)</Typography>
        <Divider style={{ width: "100%", marginBottom: 4 }} />
      </GridItem>
      <GridItem xs={12} sm={12} md={12}>
        <FormControl
          fullWidth
          className={classesCustomComponentsStyle.formControlSelect}
          size="small"
          variant="outlined"
        >
          <InputLabel>{naturezaOperacaoMessages.exceptions.estados}</InputLabel>
          <Select
            label={naturezaOperacaoMessages.exceptions.estados}
            multiple
            id="estados"
            autoWidth
            value={exception.estados}
            onChange={(event) => {
              const { value } = event.target;
              return handleChange("estados", value);
            }}
            renderValue={(selected) => selected.map((item) => item.name).join(", ")}
            required
            error={errors.estados}
            helperText={naturezaOperacaoMessages.exceptions.estados}
          >
            {states.map((item) => {
              const { id, name } = item;
              return (
                <MenuItem key={id} value={item}>
                  <Checkbox
                    color="secondary"
                    checked={exception.estados.findIndex((state) => state.id === id) !== -1}
                  />
                  <ListItemText primary={name} />
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </GridItem>

      <GridItem xs={12} sm={12} md={12}>
        <MultiAutoComplete
          fieldsToShowAfterSelectItem={["code", "name"]}
          chipText="name"
          label={naturezaOperacaoMessages.exceptions.product}
          placeholder={naturezaOperacaoMessages.exceptions.autocompleteProduct}
          service={ProductService}
          items={exception.produtos}
          handleChange={(items) => handleChange("produtos", items)}
        />
      </GridItem>
      <GridItem xs={12} sm={12} md={12} style={{ marginTop: 12 }}>
        <Typography style={{ fontSize: 18, fontWeight: 500 }}>Geral</Typography>
        <Divider style={{ width: "100%", marginBottom: 4 }} />
      </GridItem>
      <GridItem xs={12} sm={12} md={12}>
        <FormControl
          fullWidth
          className={classesCustomComponentsStyle.formControlSelect}
          size="small"
          variant="outlined"
        >
          <InputLabel>{naturezaOperacaoMessages.exceptions.origens}</InputLabel>
          <Select
            multiple
            label={naturezaOperacaoMessages.exceptions.origens}
            id="origens"
            autoWidth
            value={exception.origens}
            onChange={(event) => {
              const { value } = event.target;
              return handleChange("origens", value);
            }}
            renderValue={(selected) => selected.map((item) => NFOrigem[item].name).join(", ")}
          >
            {Object.keys(NFOrigem).map((key) => {
              const item = NFOrigem[key];
              const { code, name, value } = item;
              return (
                <MenuItem key={code} value={value}>
                  <Checkbox
                    color="secondary"
                    checked={exception.origens.findIndex((origem) => origem === value) !== -1}
                  />
                  <ListItemText primary={name} />
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </GridItem>

      <GridItem xs={12} sm={12} md={12}>
        <MultiAutoComplete
          freeSolo
          autoSelect
          addOnEnter
          fieldsToShowAfterSelectItem={["ncm", "description"]}
          chipText="ncm"
          label={naturezaOperacaoMessages.exceptions.ncm}
          placeholder={naturezaOperacaoMessages.exceptions.autocompleteNcm}
          service={NcmService}
          items={exception.ncms}
          handleChange={(items) => handleChange("ncms", items)}
        />
      </GridItem>
    </GridContainer>
  );

  const renderTabs = () => {
    switch (currentTab) {
      case TipoExcecao.SIMPLES:
        return <NOSimplesTab data={exception} handleChange={handleChange} errors={{}} />;

      case TipoExcecao.IPI:
        return <NOIpiTab data={exception} handleChange={handleChange} errors={{}} />;

      case TipoExcecao.ISSQN:
        return <NOIssqnTab data={exception} handleChange={handleChange} errors={{}} />;

      case TipoExcecao.PIS:
        return <NOPisTab data={exception} handleChange={handleChange} errors={{}} />;

      case TipoExcecao.COFINS:
        return <NOCofinsTab data={exception} handleChange={handleChange} errors={{}} />;

      case TipoExcecao.ICMS:
        return <NOIcmsTab data={exception} handleChange={handleChange} errors={{}} />;

      default:
        return <div />;
    }
  };

  return (
    <Dialog disableEscapeKeyDown open={open} fullWidth maxWidth="md">
      <DialogTitle>Exceção - {currentTab}</DialogTitle>
      <DialogContent>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            {renderConfigs()}

            {renderTabs()}
          </GridItem>
        </GridContainer>
      </DialogContent>
      <DialogActions>
        <div style={{ flexGrow: 1, display: "flex", justifyContent: "space-between" }}>
          <Button style={{ color: primaryColor[0] }} onClick={close}>
            {naturezaOperacaoMessages.exceptions.cancel}
          </Button>
          <Button style={{ color: primaryColor[0] }} onClick={confirm}>
            {naturezaOperacaoMessages.exceptions.confirm}
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default React.memo(NOExceptionModal);
