/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useCallback, useState } from "react";
import CustomComponentsStyle from "assets/jss/material-dashboard-react/components/customComponentsStyle";
import { makeStyles } from "@mui/styles";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  Button as ButtonUI,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TextInput from "components/textInput/TextInput";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { primaryColor } from "assets/jss/material-dashboard-react";
import DeleteIcon from "components/icons/DeleteIcon";
import Autocomplete from "components/autocomplete/Autocomplete";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

const MultiAutoComplete = ({
  label,
  chipText,
  placeholder,
  fieldsToShowAfterSelectItem,
  service,
  handleChange,
  items,
  maxChip = 5,
  freeSolo,
  autoSelect,
  addOnEnter = false,
}) => {
  const useCustomComponentsStyle = makeStyles(CustomComponentsStyle);
  const classesCustomComponentsStyle = useCustomComponentsStyle();
  const theme = useTheme();
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("custom"));
  const [onTypedChange, setOnTypedChange] = useState(null);
  const [open, setOpen] = useState(false);

  const onTypedChangeProduct = useCallback((value) => {
    setOnTypedChange(value);
  }, []);

  const remove = useCallback(
    (value) => {
      const newItems = [...items];
      let index = 0;
      if (typeof value === "string" || value == null) {
        index = newItems.findIndex((item) => item === value);
      } else {
        index = newItems.findIndex((item) => item.id === value.id);
      }
      newItems.splice(index, 1);
      handleChange(newItems);
    },
    [handleChange, items]
  );

  const add = useCallback(
    (value) => {
      if (value == null || value === "") return;
      let index = 0;
      if (typeof value === "string" || value == null) {
        index = items.findIndex((item) => item === value);
      } else {
        index = items.findIndex((item) => item.id === value.id);
      }

      if (index === -1) {
        items.push(value);
        handleChange(items);
      }
      setOnTypedChange(null);
    },
    [handleChange, items]
  );

  const getChips = useCallback(() => items.slice(0, maxChip), [items, maxChip]);

  const renderButtonAll = () => (
    <ButtonUI
      size="small"
      style={{ color: primaryColor[0], cursor: "pointer", marginTop: 4 }}
      onClick={() => setOpen(true)}
    >
      Ver todos
    </ButtonUI>
  );

  const renderModalSeeAll = () => (
    <Dialog disableEscapeKeyDown open={open} fullWidth maxWidth="md">
      <DialogTitle>Lista</DialogTitle>
      <DialogContent>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <TableContainer>
              <Table sx={{ minWidth: 650 }} aria-label="caption table">
                {/* <TableHead>
                  <TableRow>
                    <TableCell>Item</TableCell>
                  </TableRow>
                </TableHead> */}
                <TableBody>
                  {items?.map((item) => (
                    <TableRow>
                      <TableCell>{typeof item === "string" ? item : item[chipText]}</TableCell>
                      <TableCell align="right" width="10%">
                        <DeleteIcon onClick={() => remove(item)} style={{ cursor: "pointer" }} />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </GridItem>
        </GridContainer>
      </DialogContent>
      <DialogActions>
        <div style={{ flexGrow: 1, display: "flex", justifyContent: "space-between" }}>
          <Button style={{ color: primaryColor[0] }} onClick={() => setOpen(false)}>
            Fechar
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );

  const renderAutoComplete = () => (
    <GridContainer style={{ marginBottom: 8 }}>
      <Grid item xs={12} sm={12} md={12}>
        <Box display="flex" flexGrow={1}>
          <Autocomplete
            freeSolo={freeSolo}
            autoSelect={autoSelect}
            width="100%"
            variant="outlined"
            fieldsToShowAfterSelectItem={fieldsToShowAfterSelectItem}
            label={label}
            placeholder={placeholder}
            service={service}
            value={onTypedChange}
            onTypedChange={(value) => onTypedChangeProduct(value)}
            onChange={(value) => add(value)}
            onBlur={(ev) => (addOnEnter ? add(ev.target.value) : null)}
            onKeyDown={(ev) => {
              if (addOnEnter) {
                if (ev.which === 13 && ev.target.value) {
                  add(ev.target.value);
                }
              }
            }}
          />
        </Box>
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        {getChips().map((value) => (
          <Chip
            label={typeof value === "string" ? value : value[chipText]}
            variant="outlined"
            size="small"
            style={{ marginRight: 4, marginTop: 4 }}
            onDelete={() => remove(value)}
          />
        ))}
        {items.length > maxChip && renderButtonAll()}
      </Grid>
    </GridContainer>
  );

  return [renderAutoComplete(), renderModalSeeAll()];
};

export default MultiAutoComplete;
