import React, { useCallback, useContext } from "react";
import classNames from "classnames";
import { makeStyles } from "@mui/styles";
import { Link, Redirect, useHistory } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Hidden from "@mui/material/Hidden";
import Poppers from "@mui/material/Popper";
import Divider from "@mui/material/Divider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faLock, faFileContract } from "@fortawesome/free-solid-svg-icons";
import Button from "components/CustomButtons/Button.js";
import styles from "assets/jss/material-dashboard-react/components/headerLinksStyle.js";
import Notification from "../notification/Notification";
import UserContext from "../../core/UserContext";
import StripeService from "../../views/myPlan/service/StripeService";
import { REDIRECT_HTML, MODULES } from "../../config/constants";

const useStyles = makeStyles(styles);

export default function AdminNavbarLinks() {
  const { userLogged } = useContext(UserContext);
  const classes = useStyles();
  const history = useHistory();
  const { keycloak } = useKeycloak();

  const [openProfile, setOpenProfile] = React.useState(null);

  const handleClickProfile = (event) => {
    if (openProfile && openProfile.contains(event.target)) {
      setOpenProfile(null);
    } else {
      setOpenProfile(event.currentTarget);
    }
  };
  const handleCloseProfile = () => {
    setOpenProfile(null);
  };

  const isMenuLocked = useCallback(
    (url) => {
      if (userLogged != null) {
        const { modules } = userLogged;
        const module = MODULES[url];
        const locked = !modules.includes(module);
        return locked;
      }
      return true;
    },
    [userLogged]
  );

  const isLockedCompany = isMenuLocked("/app/company");
  const isLockedPlan = isMenuLocked("/app/my-plan");

  const goToCompany = () => {
    history.push("/app/company");
    setOpenProfile(null);
  };

  const goToDigitalCertificate = () => {
    history.push("/app/digital-certificate");
    setOpenProfile(null);
  };

  const goToConfiguracaoNFe = () => {
    history.push("/app/configuracao-nfe");
    setOpenProfile(null);
  };

  const goToNaturezaOperacao = () => {
    history.push("/app/operations-type");
    setOpenProfile(null);
  };

  const goToPartilhaICMS = () => {
    history.push("/app/partilha-icms");
    setOpenProfile(null);
  };

  const goToNFeEmissao = () => {
    history.push("/app/nfe-emissoes");
    setOpenProfile(null);
  };

  const goToNFeEntrada = () => {
    history.push("/app/nfe-entradas");
    setOpenProfile(null);
  };

  const goToNFeInutilizacao = () => {
    history.push("/app/nfe-inutilizacoes");
    setOpenProfile(null);
  };

  const goToMyPlan = async () => {
    if (isLockedPlan) {
      return history.push("/app/forbidden");
    }
    if (userLogged.plan.freePlan) {
      history.push("/app/my-plan");
    } else {
      const page = window.open("", "_blank");
      page.document.write(REDIRECT_HTML);
      page.document.close();
      const result = await StripeService.createPortalSession();
      const { url } = result.data;
      page.location.href = url;
    }
    setOpenProfile(null);
  };

  const handleLogout = () => {
    keycloak.logout({ redirectUri: window.location.origin });
  };

  const getUserName = () => {
    if (userLogged != null) {
      return userLogged.name || "";
    }
  };

  return (
    <div>
      <Hidden smDown>
        <Notification />
      </Hidden>
      <div className={classes.manager}>
        <Hidden smDown>
          Olá, {getUserName()}
        </Hidden>
        <Button
          color={"transparent"}
          justIcon={true}
          simple={false}
          aria-owns={openProfile ? "profile-menu-list-grow" : null}
          aria-haspopup="true"
          onClick={handleClickProfile}
          style={{ marginTop: 0, marginRight: 20 }}
          className={classes.buttonLink}
        >
          <FontAwesomeIcon icon={faUser} color="#686868" />
          {/* <Hidden mdUp implementation="css">
            <p className={classes.linkText}>Profile</p>
          </Hidden> */}
        </Button>
        <Poppers
          open={Boolean(openProfile)}
          anchorEl={openProfile}
          transition
          disablePortal
          className={classNames({ [classes.popperClose]: !openProfile }) + " " + classes.popperNav}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              id="profile-menu-list-grow"
              style={{
                transformOrigin: placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleCloseProfile}>
                  <MenuList role="menu">
                    <>
                      <MenuItem onClick={goToCompany} className={classes.dropdownItem}>
                        {isLockedCompany && (
                          <FontAwesomeIcon
                            icon={faLock}
                            color="#686868"
                            style={{ marginRight: 4 }}
                          />
                        )}
                        Empresa
                      </MenuItem>
                      <MenuItem onClick={goToMyPlan} className={classes.dropdownItem}>
                        {isLockedPlan && (
                          <FontAwesomeIcon
                            icon={faLock}
                            color="#686868"
                            style={{ marginRight: 4 }}
                          />
                        )}
                        Meu plano
                      </MenuItem>
                      {/* <MenuItem onClick={goToDepartament} className={classes.dropdownItem}>
                          Departamento
                        </MenuItem> */}
                    </>

                    {/* <MenuItem onClick={handleCloseProfile} className={classes.dropdownItem}>
                      Settings
                    </MenuItem> */}
                    <Divider light />
                    <MenuItem onClick={handleLogout} className={classes.dropdownItem}>
                      Sair
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Poppers>
      </div>
    </div>
  );
}
