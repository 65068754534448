import React, { useState, useEffect, useCallback } from "react";
import { useDebounce } from "use-debounce";
import { makeStyles } from "@mui/styles";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import CardStyle from "assets/jss/material-dashboard-react/components/cardStyle";
import NaturezaOperacaoService from "./service/NaturezaOperacaoService";
import MESSAGES from "../../config/messages";
import { SEARCH } from "../../config/constants";
import Loading from "../../components/loading/Loading";
import AddButton from "../../components/addButton/AddButton";
import EditIcon from "../../components/icons/EditIcon";
import DataGridCustom from "../../components/dataGrid/DataGridCustom";
import { NFRegimeTributario, NaturezaOperacaoPadraoType } from "./model/NaturezaOperacao";

const { generalMessages, naturezaOperacaoMessages } = MESSAGES;

const useCardStyle = makeStyles(CardStyle);

export default function NaturezaOperacaoList() {
  const classesuseCardStyle = useCardStyle();
  const history = useHistory();
  const [list, setList] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchTermDebounce] = useDebounce(searchTerm, SEARCH.WAIT_INTERVAL);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const fetchAll = useCallback(async () => {
    if (searchTermDebounce !== "" && searchTermDebounce.length < SEARCH.MINIMUM_CHARACTERS) return;

    setLoading(true);
    try {
      const result = await NaturezaOperacaoService.fetchAll(searchTermDebounce);
      const { data } = result;
      const { content } = data;
      setList(content);
    } catch (error) {
      enqueueSnackbar(generalMessages.error, {
        variant: "error",
      });
    } finally {
      setLoading(false);
    }
  }, [enqueueSnackbar, searchTermDebounce]);

  useEffect(() => {
    fetchAll();
  }, [fetchAll]);

  const goToForm = (params) =>
    params?.id
      ? history.push(`/app/operation-type/${params?.id}`)
      : history.push("/app/operation-type");

  const formatterCfop = (item) => {
    const { regimeTributario, cfopSimples, cfopIcms } = item;
    if (regimeTributario != null) {
      if (regimeTributario === NFRegimeTributario.SIMPLES_NACIONAL.code) {
        return cfopSimples;
      }
      return cfopIcms;
    }
    return "";
  };

  const columns = [
    {
      field: "descricao",
      headerName: naturezaOperacaoMessages.descricao,
      flex: 3,
      minWidth: 250,
    },
    {
      field: "regimeTributario",
      headerName: "Regime tributário",
      flex: 3,
      minWidth: 250,
      renderCell: ({ row: item }) =>
        item.regimeTributario ? NFRegimeTributario[item.regimeTributario].name : "",
    },

    {
      field: "CFOP",
      headerName: "CFOP",
      minWidth: 120,
      flex: 1,
      renderCell: ({ row: item }) => formatterCfop(item),
    },
    {
      field: "padrão",
      headerName: "Padrão",
      minWidth: 150,
      flex: 2,
      renderCell: ({ row: item }) =>
        item.padrao ? NaturezaOperacaoPadraoType[item.padrao].name : "",
    },
    {
      field: "active",
      headerName: "Ativo",
      minWidth: 120,
      flex: 1,
      valueGetter: ({ value }) => (value ? "Sim" : "Não"),
    },
    {
      field: "actions",
      headerName: generalMessages.actions,
      sortable: false,
      width: 100,
      disableClickEventBubbling: true,
      renderCell: (params) => (
        <div
          style={{
            cursor: "pointer",
            justifyContent: "center",
            padding: "10px",
            display: "flex",
            flex: 1,
          }}
        >
          <EditIcon onClick={() => goToForm({ id: params.row.id })} />
        </div>
      ),
    },
  ];

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card className="gridLayoutCard">
          <div className="gridLayoutContainer">
            <CardHeader color="primary">
              <h4 className={classesuseCardStyle.cardTitleList}>
                {naturezaOperacaoMessages.naturezasOperacao}
              </h4>
              <p className={classesuseCardStyle.cardCategoryList}>
                {naturezaOperacaoMessages.naturezaOperacaoTable}
              </p>
            </CardHeader>

            <div className="gridLayoutGrid">
              <DataGridCustom onRowDoubleClick={goToForm} rows={list} columns={columns} />
            </div>

            <div className="gridLayoutBottom">
              <AddButton onClick={() => goToForm(null)} />
            </div>
          </div>
        </Card>
      </GridItem>
      <Loading loading={loading} />
    </GridContainer>
  );
}
