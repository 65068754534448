/* eslint-disable no-param-reassign */
import axios from "axios";
import keycloak from "./keycloak";

const isLoginRequest = (url) => url.includes("openid-connect/token");

axios.interceptors.request.use(
  async (config) => {
    let token = null;
    if (keycloak && keycloak.token) {
      token = keycloak.token;
      // console.log("##### token:", token);
      const isTokenExpired = await keycloak.isTokenExpired(20);
      // console.log("##### isTokenExpired: ", isTokenExpired);
      if (isTokenExpired) {
        await keycloak.updateToken(-1);
        token = keycloak.token;
      }
      // console.log("##### token:", token);
    }

    if (!isLoginRequest(config?.url) && token != null) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error?.response?.status === 401 && !isLoginRequest(error?.config?.url)) {
      if (keycloak && keycloak.token) {
        keycloak.clearToken();
      }
      // window.location.href = `${window.location.origin}/login`;
      return Promise.reject(error);
    }

    return Promise.reject(error);
  }
);
