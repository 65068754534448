import { blackColor, whiteColor, hexToRgb } from "assets/jss/material-dashboard-react.js";

const cardStyle = (theme) => (
  {
    card: {
      border: "0",
      marginBottom: "30px",
      marginTop: "30px",
      borderRadius: "6px",
      color: "rgba(" + hexToRgb(blackColor) + ", 0.87)",
      background: whiteColor,
      width: "100%",
      boxShadow: "0 1px 4px 0 rgba(" + hexToRgb(blackColor) + ", 0.14)",
      position: "relative",
      display: "flex",
      flexDirection: "column",
      minWidth: "0",
      wordWrap: "break-word",
      fontSize: ".875rem",
    },
    cardPlain: {
      background: "transparent",
      boxShadow: "none",
    },
    cardProfile: {
      marginTop: "30px",
      textAlign: "center",
    },
    cardChart: {
      "& p": {
        marginTop: "0px",
        paddingTop: "0px",
      },
    },
    cardCategory: {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
      [theme.breakpoints.down('custom')]: {
        display: 'none'
      },
    },
    cardTitle: {
      color: "#FFFFFF",
      marginTop: "0px",
      minHeight: "auto",
      fontWeight: "300",
      fontFamily: "'Nunito', 'Roboto', 'Helvetica', 'Arial', sans-serif",
      marginBottom: "3px",
      textDecoration: "none",
      [theme.breakpoints.down('custom')]: {
        fontSize: '1em'
      },
    },
    cardCategoryList: {
      "&,& a,& a:hover,& a:focus": {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0",

      },
      "& a,& a:hover,& a:focus": {
        color: "#FFFFFF",
      },
      [theme.breakpoints.down('custom')]: {
        display: 'none'
      },
    },
    cardTitleList: {
      color: "#FFFFFF",
      marginTop: "0px",
      minHeight: "auto",
      fontWeight: "300",
      fontFamily: "'Nunito', 'Roboto', 'Helvetica', 'Arial', sans-serif",
      marginBottom: "3px",
      textDecoration: "none",
      "& small": {
        color: "#777",
        fontSize: "65%",
        fontWeight: "400",
        lineHeight: "1",
      },
      [theme.breakpoints.down('custom')]: {
        fontSize: '1em'
      },
    },
  }
)

export default cardStyle;
