/* eslint-disable react/prop-types */
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBoxOpen, faWrench } from "@fortawesome/free-solid-svg-icons";
import { makeStyles } from "@mui/styles";
import styles from "./iconStyle";

const useStyles = makeStyles(styles);

const ProductIcon = ({ isService }) => {
  const classes = useStyles();
  return isService ? (
    <FontAwesomeIcon icon={faWrench} className={classes.custom} title="Serviço" color="#686868" />
  ) : (
    <FontAwesomeIcon icon={faBoxOpen} className={classes.custom} title="Produto" color="#686868" />
  );
};

export default React.memo(ProductIcon);
