import axios from "axios";
import { APIS } from "../../../config/constants";
import BaseService from "../../../core/BaseService";

const { register: registerAPI } = APIS;

class NotaFiscalPacoteDownloadService extends BaseService {
  constructor() {
    super(registerAPI.notaFiscalPacoteDownload);
  }

  fetchAll = ({ pagination, sorting }) => {
    const { page, pageSize } = pagination;
    const { field = "createdAt", sort = "desc" } = sorting || {};
    return axios.get(`${this.endpoint}/search?size=${pageSize}&page=${page}&sort=${field},${sort}`);
  };

  download = (id) =>
    axios.get(`${this.endpoint}/${id}/zip`, {
      responseType: "blob",
    });
}

export default new NotaFiscalPacoteDownloadService();
