/* eslint-disable react/prop-types */
import React, { useCallback, useState } from "react";
import GridContainer from "components/Grid/GridContainer";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  Typography,
  FormGroup,
  FormControlLabel,
  Switch,
} from "@mui/material";
import { useHistory } from "react-router-dom";
import { primaryColor } from "assets/jss/material-dashboard-react";
import GridItem from "components/Grid/GridItem";
import { useSnackbar } from "notistack";
import KairumDropzone from "components/dropZone/KairumDropzone";
import Loading from "components/loading/Loading";
import { handlingResponseErrors } from "../../../../config/util";
import MESSAGES from "../../../../config/messages";
import NFeImportacaoService from "../../service/NFeImportacaoService";

const { generalMessages } = MESSAGES;

const NFeEntradaImportarXML = ({ open, onClose }) => {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [xml, setXml] = useState(null);
  const [encontrarProdutoSomentePelaDescricao, setEncontrarProdutoSomentePelaDescricao] = useState(
    true
  );

  const handleDelete = useCallback(() => {
    setXml(null);
  }, []);

  const handleClose = useCallback(() => {
    handleDelete();
    onClose(false);
  }, [handleDelete, onClose]);

  const onImport = useCallback(async () => {
    try {
      setLoading(true);

      const formData = new FormData();

      formData.append("file", xml);
      formData.append("encontrarProdutoSomentePelaDescricao", encontrarProdutoSomentePelaDescricao);

      const { data: response } = await NFeImportacaoService.import(formData);
      const id = response?.data?.id;
      history.push(`/app/nfe-entrada/${id}`);

      setErrors({});
      enqueueSnackbar(generalMessages.saveSuccess, {
        variant: "success",
        autoHideDuration: 3000,
      });
    } catch (error) {
      const { message, validationErrors } = handlingResponseErrors(error);
      enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
      if (validationErrors != null) {
        setErrors(validationErrors);
      }
    } finally {
      setLoading(false);
    }
  }, [xml, encontrarProdutoSomentePelaDescricao, history, enqueueSnackbar]);

  return (
    <>
      <Dialog disableEscapeKeyDown open={open} fullWidth maxWidth="md">
        <DialogTitle>Histórico nota fiscal</DialogTitle>
        <DialogContent>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <KairumDropzone
                title="XML da Nota Fiscal de Entrada"
                textDropzone="Arraste e solte para importar o XML da Nota Fiscal de Entrada"
                handleChange={setXml}
                onDelete={handleDelete}
                item={xml}
                errors={errors}
                fileTypes={{ "text/xml": [] }}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <Box>
                <Typography
                  style={{ fontSize: 18, fontWeight: 500, marginTop: 15, marginBottom: 6 }}
                >
                  Regras para o relacionamento dos produtos
                </Typography>
                <Typography style={{ fontSize: 14 }}>
                  As regras aplicadas para o relacionamento dos produtos da nota fiscal de compra
                  com os produtos existentes serão as listadas abaixo:
                  <p>1. Fornecedor + código no fabricante + descrição do produto</p>
                  <p>2. Fornecedor + código no fabricante</p>
                  <p>3. Código no fabricante + descrição do produto</p>
                  <p>4. EAN do produto</p>
                </Typography>
              </Box>
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      checked={encontrarProdutoSomentePelaDescricao}
                      onChange={(event) =>
                        setEncontrarProdutoSomentePelaDescricao(event.target.checked)
                      }
                    />
                  }
                  label="Se não encontrar pelas regras acima, relacionar apenas pela descrição"
                />
              </FormGroup>
            </GridItem>
          </GridContainer>
        </DialogContent>
        <DialogActions>
          <div style={{ flexGrow: 1, display: "flex", justifyContent: "space-between" }}>
            <Button style={{ color: primaryColor[0] }} onClick={handleClose}>
              Voltar
            </Button>
            <Button style={{ color: primaryColor[0] }} onClick={onImport}>
              Importar XML
            </Button>
          </div>
        </DialogActions>
      </Dialog>
      <Loading loading={loading} />
    </>
  );
};

export default React.memo(NFeEntradaImportarXML);
