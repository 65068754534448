import axios from "axios";
import { APIS } from "../../../config/constants";
import BaseService from "../../../core/BaseService";

const { register: registerAPI } = APIS;

class ProductPriceChangeHistoryService extends BaseService {
  constructor() {
    super(registerAPI.productPriceChangeHistory);
  }

  findProductPriceChangeHistory = (product, term) =>
    axios.get(`${this.endpoint}/product/${product}?size=1000&&sort=createdAt,DESC&term=${term}`);

  findMovementPriceChange = (inventoryMovementId) =>
    axios.get(`${this.endpoint}/inventory-movement/${inventoryMovementId}`);
}

export default new ProductPriceChangeHistoryService();
