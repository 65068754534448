/* eslint-disable no-new */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useCallback } from "react";
import moment from "moment";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  Link,
  Typography,
} from "@mui/material";
import Autocomplete from "components/autocomplete/Autocomplete";
import { useSnackbar } from "notistack";
import { DatePicker } from "@mui/x-date-pickers";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPercentage, faLock } from "@fortawesome/free-solid-svg-icons";
import { primaryColor } from "../../../assets/jss/material-dashboard-react";
import ReportService from "../service/ReportService";
import UserService from "../../user/service/UserService";
import Loading from "../../../components/loading/Loading";

import MESSAGES from "../../../config/messages";

const { reportMessages, generalMessages } = MESSAGES;

// eslint-disable-next-line react/prop-types
export default function OSReport({ locked }) {
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const [item, setItem] = useState({});

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const handleChange = useCallback(
    (field, value) => {
      setItem({
        ...item,
        [field]: value,
      });
    },
    [item]
  );

  const onClose = useCallback(() => {
    setItem({});
    setErrors({});
    setOpen(false);
  }, []);

  const goReport = useCallback(async () => {
    try {
      setLoading(true);
      const { startDate, endDate, user } = item;
      const params = [];

      if (startDate != null) {
        params.push(`startDate=${moment(startDate).format("YYYY-MM-DD")}`);
      }
      if (endDate != null) {
        params.push(`endDate=${moment(endDate).format("YYYY-MM-DD")}`);
      }
      if (user != null) {
        params.push(`userId=${user.id}`);
      }

      const response = await ReportService.printCommissionPeriod(params.join("&"));

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `Relatório de comissão.pdf`);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      if (
        error.request.responseType === "blob" &&
        error.response.data instanceof Blob &&
        error.response.data.type &&
        error.response.data.type.toLowerCase().indexOf("json") !== -1
      ) {
        new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = () => {
            const message = error.response.status === 428 ? reader?.result : generalMessages.error;
            enqueueSnackbar(message, {
              variant: "error",
              autoHideDuration: 3000,
            });
          };

          reader.onerror = () => {
            reject(error);
          };

          reader.readAsText(error.response.data);
        });
      }
    } finally {
      setLoading(false);
    }
  }, [item, enqueueSnackbar]);

  const focusInputField = useCallback((input) => {
    if (input) {
      setTimeout(() => {
        input.focus();
      }, 100);
    }
  }, []);

  const renderModal = () => (
    <Dialog disableEscapeKeyDown open={open} fullWidth maxWidth="sm">
      <DialogTitle>{reportMessages.commission}</DialogTitle>
      <DialogContent>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Autocomplete
              inputRef={focusInputField}
              variant="outlined"
              fieldsToShowAfterSelectItem={["name"]}
              label={reportMessages.user}
              placeholder={reportMessages.autocompleteUser}
              service={UserService}
              value={item.user}
              onChange={(value) => handleChange("user", value)}
              // renderOption={(_item) => `${_item.name}`}
              error={errors.user}
              required
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={6} style={{ marginTop: "12px" }}>
            <DatePicker
              id="startDate"
              label={reportMessages.startDate}
              format="dd/MM/yyyy"
              slotProps={{ textField: { size: "small" } }}
              value={item?.startDate ? moment(item?.startDate).valueOf() : null}
              helperText={errors.startDate}
              onChange={(date) => handleChange("startDate", date)}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={6} style={{ marginTop: "12px" }}>
            <DatePicker
              id="endDate"
              label={reportMessages.endDate}
              format="dd/MM/yyyy"
              slotProps={{ textField: { size: "small" } }}
              value={item?.endDate ? moment(item?.endDate).valueOf() : null}
              helperText={errors.endDate}
              onChange={(date) => handleChange("endDate", date)}
            />
          </GridItem>
        </GridContainer>
      </DialogContent>
      <DialogActions>
        <div style={{ flexGrow: 1, display: "flex", justifyContent: "space-between" }}>
          <Button style={{ color: primaryColor[0] }} onClick={onClose} autoFocus>
            {reportMessages.cancel}
          </Button>
          <Button style={{ color: primaryColor[0] }} onClick={goReport}>
            {reportMessages.confirm}
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );

  return (
    <Box display="flex" alignItems="center" padding="12px" width="100%">
      <Box style={{ width: 50 }}>
        <FontAwesomeIcon
          icon={faPercentage}
          color={primaryColor[0]}
          fontSize={35}
          style={{ paddingRight: 20 }}
        />
      </Box>
      <Box display="flex" justifyContent="space-between" width="100%" alignItems="center">
        <Box>
          <Typography style={{ fontSize: 16 }}> {reportMessages.commission}</Typography>
        </Box>
        <Box>
          {locked ? (
            <FontAwesomeIcon
              icon={faLock}
              fontSize={30}
              color={primaryColor[0]}
              style={{ marginRight: 15 }}
            />
          ) : (
            <Button style={{ color: primaryColor[0] }} onClick={() => setOpen(true)}>
              Gerar
            </Button>
          )}
        </Box>
      </Box>
      {renderModal()}
      <Loading loading={loading} />
    </Box>
  );
}
