/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import CustomComponentsStyle from "assets/jss/material-dashboard-react/components/customComponentsStyle";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import GridItem from "components/Grid/GridItem";
import TextInput from "components/textInput/TextInput";
import MESSAGES from "config/messages";
import GridContainer from "components/Grid/GridContainer";
import {
  Finalidade,
  NFRegimeTributario,
  NFTipo,
  YesNo,
  NaturezaOperacaoPadraoType,
} from "../model/NaturezaOperacao";

const { naturezaOperacaoMessages } = MESSAGES;
const CHARACTER_LIMIT = 4000;

const NaturezaOperacaoGeneral = ({ natureza, handleChange, errors = {} }) => {
  const useCustomComponentsStyle = makeStyles(CustomComponentsStyle);
  const classesCustomComponentsStyle = useCustomComponentsStyle();

  const {
    descricao,
    observacaoNaturezaOperacao,
    finalidade,
    regimeTributario,
    operacaoConsumidorFinal,
    tipo,
    padrao,
  } = natureza;

  const renderYesNoSelect = (field, val) => (
    <GridItem xs={12} sm={12} md={2}>
      <FormControl
        fullWidth
        className={classesCustomComponentsStyle.formControlSelect}
        size="small"
        variant="outlined"
      >
        <InputLabel>{naturezaOperacaoMessages.advanced[field]}</InputLabel>
        <Select
          label={naturezaOperacaoMessages.advanced[field]}
          id={field}
          autoWidth
          value={val === true ? YesNo.YES.code : YesNo.No.code}
          onChange={(event) => {
            const {
              target: { value },
            } = event;
            return handleChange(field, value);
          }}
          error={errors[field]}
          helperText={naturezaOperacaoMessages.advanced[field]}
        >
          {Object.keys(YesNo).map((key) => {
            const { code, name } = YesNo[key];
            return (
              <MenuItem key={code} value={code}>
                {name}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </GridItem>
  );

  const renderNFRegimeTributario = () => (
    <GridItem xs={12} sm={12} md={4} style={{ marginTop: 0 }}>
      <FormControl
        fullWidth
        className={classesCustomComponentsStyle.formControlSelect}
        variant="outlined"
        size="small"
      >
        <InputLabel>{naturezaOperacaoMessages.advanced.regimeTributario}</InputLabel>
        <Select
          label={naturezaOperacaoMessages.advanced.regimeTributario}
          id="regimeTributario"
          autoWidth
          value={regimeTributario == null ? "" : regimeTributario}
          onChange={(event) => {
            const {
              target: { value },
            } = event;
            return handleChange("regimeTributario", value);
          }}
          error={errors.regimeTributario}
          helperText={naturezaOperacaoMessages.advanced.regimeTributario}
        >
          {Object.keys(NFRegimeTributario).map((key) => {
            const { code, name } = NFRegimeTributario[key];
            return (
              <MenuItem key={code} value={code}>
                {name}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </GridItem>
  );

  const renderNFTipo = () => (
    <GridItem xs={12} sm={12} md={3} style={{ marginTop: 0 }}>
      <FormControl
        fullWidth
        className={classesCustomComponentsStyle.formControlSelect}
        variant="outlined"
        size="small"
      >
        <InputLabel>Tipo</InputLabel>
        <Select
          label="Tipo"
          id="tipo"
          autoWidth
          value={tipo == null ? "" : tipo}
          onChange={(event) => {
            const {
              target: { value },
            } = event;
            return handleChange("tipo", value);
          }}
          error={errors.tipo}
          helperText="Tipo"
        >
          {Object.keys(NFTipo).map((key) => {
            const { code, name } = NFTipo[key];
            return (
              <MenuItem key={code} value={code}>
                {name}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </GridItem>
  );

  const renderNaturezaOperacaoPadraoType = () => (
    <GridItem xs={12} sm={12} md={3}>
      <FormControl
        fullWidth
        className={classesCustomComponentsStyle.formControlSelect}
        size="small"
        variant="outlined"
      >
        <InputLabel>Padrão</InputLabel>
        <Select
          id="padrao"
          label="Padrão"
          autoWidth
          value={padrao == null ? "" : padrao}
          // eslint-disable-next-line arrow-body-style
          onChange={(event) => {
            const {
              target: { value },
            } = event;
            return handleChange("padrao", value);
          }}
          required
          error={errors.padrao}
          helperText="Padrão"
        >
          {Object.keys(NaturezaOperacaoPadraoType).map((key) => {
            const { code, name } = NaturezaOperacaoPadraoType[key];
            return (
              <MenuItem key={code} value={code}>
                {name}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </GridItem>
  );
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={6}>
        <TextInput
          autoFocus
          id="descricao"
          label={naturezaOperacaoMessages.descricao}
          value={descricao}
          onChange={handleChange}
          required
          errors={errors}
        />
      </GridItem>
      <GridItem xs={12} sm={12} md={3}>
        <FormControl
          fullWidth
          className={classesCustomComponentsStyle.formControlSelect}
          size="small"
          variant="outlined"
        >
          <InputLabel>{naturezaOperacaoMessages.finalidade}</InputLabel>
          <Select
            id="finalidade"
            label={naturezaOperacaoMessages.finalidade}
            autoWidth
            value={finalidade == null ? "" : finalidade}
            // eslint-disable-next-line arrow-body-style
            onChange={(event) => {
              const {
                target: { value },
              } = event;
              return handleChange("finalidade", value);
            }}
            required
            error={errors.finalidade}
            helperText={naturezaOperacaoMessages.finalidade}
          >
            {Object.keys(Finalidade).map((key) => {
              const { code, name } = Finalidade[key];
              return (
                <MenuItem key={code} value={code}>
                  {name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </GridItem>

      {renderNFRegimeTributario()}
      {renderYesNoSelect("operacaoConsumidorFinal", operacaoConsumidorFinal)}
      {renderNFTipo()}
      {renderNaturezaOperacaoPadraoType()}
      <GridItem xs={12} sm={12} md={12}>
        <TextInput
          id="observacaoNaturezaOperacao"
          label={naturezaOperacaoMessages.obsPadrao}
          value={observacaoNaturezaOperacao}
          maxLength={CHARACTER_LIMIT}
          onChange={handleChange}
          isCounter
          multiline
          rows={4}
        />
      </GridItem>
    </GridContainer>
  );
};

export default React.memo(NaturezaOperacaoGeneral);
