/* eslint-disable react/prop-types */
import React from "react";
import { makeStyles } from "@mui/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import Style from "./TooltipCustomStyle";

const useStyle = makeStyles(Style);

const TooltipCustom = ({ title }) => {
  const classes = useStyle();
  return (
    <FontAwesomeIcon icon={faInfoCircle} fontSize={20} title={title} className={classes.tooltip} />
  );
};

export default React.memo(TooltipCustom);
