class PaymentMethod {
  constructor(source = {}) {
    this.id = source.id;
    this.name = source.name;
    this.creditCard = source.creditCard;
    this.anticipatedReceipt = source.anticipatedReceipt;
    this.anticipatedDays = source.anticipatedDays;
    this.allowsInstallments = source.allowsInstallments;
    this.maximumAmountInstallments = source.maximumAmountInstallments;
    this.standard = source.standard;
    this.active = source.active;
    this.version = source.version;
    this.allowsDifference = source.allowsDifference;
    this.nfFormOfPayment = source.nfFormOfPayment;
    this.nfPaymentMethod = source.nfPaymentMethod;
  }
}

const NFIndicadorFormaPagamento = {
  A_VISTA: {
    code: "A_VISTA",
    name: "Pagamento À vista",
  },
  A_PRAZO: {
    code: "A_PRAZO",
    name: "Pagamento a prazo",
  },
};

const NFMeioPagamento = {
  DINHEIRO: { code: "DINHEIRO", name: "Dinheiro" },
  CHEQUE: { code: "CHEQUE", name: "Cheque" },
  CARTAO_CREDITO: { code: "CARTAO_CREDITO", name: "Cartão de crédito" },
  CARTAO_DEBITO: { code: "CARTAO_DEBITO", name: "Cartão de débito" },
  CARTAO_LOJA: { code: "CARTAO_LOJA", name: "Cartão da loja" },
  VALE_ALIMENTACAO: { code: "VALE_ALIMENTACAO", name: "Vale alimentação" },
  VALE_REFEICAO: { code: "VALE_REFEICAO", name: "Vale refeição" },
  VALE_PRESENTE: { code: "VALE_PRESENTE", name: "Vale presente" },
  VALE_COMBUSTIVEL: { code: "VALE_COMBUSTIVEL", name: "Vale combustível" },
  DUPLICATA_MERCANTIL: { code: "DUPLICATA_MERCANTIL", name: "Duplicata mercantil" },
  BOLETO_BANCARIO: { code: "BOLETO_BANCARIO", name: "Boleto bancario" },
  DEPOSITO_BANCARIO: { code: "DEPOSITO_BANCARIO", name: "Deposito bancario" },
  PIX_ESTATICO: { code: "PIX_ESTATICO", name: "Pagamento Instantaneo (PIX)" },
  TRANSFERENCIA_BANCARIA: { code: "TRANSFERENCIA_BANCARIA", name: "Transferencia bancaria" },
  FIDELIDADE_CASHBACK: { code: "FIDELIDADE_CASHBACK", name: "Programa de fidelidade (Cashback)" },
  SEM_PAGAMENTO: { code: "SEM_PAGAMENTO", name: "Sem pagamento" },
  OUTRO: { code: "OUTRO", name: "Outro" },
};

export { PaymentMethod, NFIndicadorFormaPagamento, NFMeioPagamento };
