/* eslint-disable react/prop-types */
import React from "react";
import { Button } from "@mui/material";
import { primaryColor } from "../../assets/jss/material-dashboard-react";

const TextIcon = ({ onClick, text }) => (
  <Button style={{ color: primaryColor[0], fontSize: 12 }} onClick={onClick}>
    {text}
  </Button>
);

export default React.memo(TextIcon);
