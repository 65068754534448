import axios from "axios";
import { isEmptyString } from "config/util";

class BaseService {
  constructor(endpoint) {
    if (endpoint == null) {
      throw new Error("It's necessary call super constructor passing the enpoint BaseService.js");
    }
    this.endpoint = endpoint;
  }

  save = (request) => axios.post(this.endpoint, request);

  update = (id, request) => axios.put(`${this.endpoint}/${id}`, request);

  delete = (id) => axios.delete(`${this.endpoint}/${id}`);

  fetchAll = (term, sort = "name") =>
    axios.get(`${this.endpoint}/search?size=1000&sort=active,DESC&sort=${sort}&term=${term}`);

  fetchAllWithoutTerm = () =>
    axios.get(`${this.endpoint}/search?size=1000&sort=active,DESC&sort=name&`);

  fetchByAutoComplete = (term, extras) => {
    const params = [];

    if (!isEmptyString(term)) {
      params.push(`term=${term}`);
    }

    if (!isEmptyString(extras?.id)) {
      params.push(`id=${extras?.id}`);
    }
    return axios.get(
      `${this.endpoint}/autocomplete-search?size=30&sort=name,ASC&${params.join("&")}`
    );
  };

  fetchById = (id) => axios.get(`${this.endpoint}/${id}`);
}

export default BaseService;
