/* eslint-disable react/prop-types */
/* eslint-disable react/button-has-type */
import React, { useState, useCallback, useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import CardStyle from "assets/jss/material-dashboard-react/components/cardStyle";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import GridItem from "components/Grid/GridItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle, faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import GridContainer from "components/Grid/GridContainer";
import { Box, Typography } from "@mui/material";
import LoginService from "../login/service/LoginService";
import UserContext from "../../core/UserContext";

const useCardStyle = makeStyles(CardStyle);

const StripeResult = () => {
  const classesCardStyle = useCardStyle();
  const { userLogged, setUserLogged } = useContext(UserContext);
  const { search } = useLocation();
  const [success, setSuccess] = useState(null);

  useEffect(() => {
    const params = new URLSearchParams(search);
    const querySuccess = params.get("success");
    // const sessionId = params.get("session_id");
    setSuccess(querySuccess === "true");
  }, [search]);

  const fetchMe = useCallback(async () => {
    const me = await LoginService.me();
    setUserLogged(me?.data);
  }, [setUserLogged]);

  useEffect(() => {
    if (success) {
      fetchMe();
    }
  }, [success, fetchMe]);

  const getIcon = () => (
    <Box display="flex" justifyContent="center">
      <FontAwesomeIcon
        icon={success ? faCheckCircle : faExclamationCircle}
        fontSize={60}
        color="#686868"
      />
    </Box>
  );

  return (
    <GridContainer style={{ justifyContent: "center" }}>
      <GridItem xs={12} sm={12} md={10}>
        <Card>
          <CardHeader color="primary">
            <h4 className={classesCardStyle.cardTitle}>Meu plano</h4>
            <p className={classesCardStyle.cardCategory}>Informações sobre assinatura de planos</p>
          </CardHeader>
          <CardBody>
            <GridContainer>
              <Box display="flex" flexDirection="column" flexGrow={1}>
                <Box>
                  {success && (
                    <Box>
                      <Box>{getIcon()}</Box>
                      <Typography
                        inline
                        style={{
                          fontSize: 20,
                          color: "#5a5959",
                          textAlign: "center",
                          marginTop: 20,
                        }}
                      >
                        Parabéns, {userLogged?.name}. Sua assinatura foi concluída com sucesso!
                      </Typography>
                    </Box>
                  )}
                  {success === false && (
                    <Box>
                      <Box>{getIcon()}</Box>
                      <Typography
                        inline
                        style={{
                          fontSize: 20,
                          color: "#5a5959",
                          textAlign: "center",
                          marginTop: 20,
                        }}
                      >
                        Ops! Tivemos algum problema com sua assinatura. Entre em contato com o
                        suporte técnico.
                      </Typography>
                    </Box>
                  )}
                </Box>
              </Box>
            </GridContainer>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default React.memo(StripeResult);
