/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { makeStyles } from "@mui/styles";
import CustomComponentsStyle from "assets/jss/material-dashboard-react/components/customComponentsStyle";
import { CircularProgress, TextField, useMediaQuery, useTheme } from "@mui/material";
import AutocompleteMUI from "@mui/lab/Autocomplete";
import { useSnackbar } from "notistack";
import { primaryColor } from "assets/jss/material-dashboard-react";
import MESSAGES from "../../config/messages";
import { AUTOCOMPLETE } from "../../config/constants";

const { generalMessages } = MESSAGES;
const useCustomComponentsStyle = makeStyles(CustomComponentsStyle);
const WAIT_INTERVAL = 1000;
let timerID;
const Autocomplete = ({
  onChange, // When selected the value
  onTypedChange, // While is typing
  value,
  fieldsToShowAfterSelectItem,
  label,
  service,
  placeholder,
  renderOption,
  variant = "standard",
  error,
  required = false,
  autoFocus = false,
  inputRef,
  width,
  quickAdd = false,
  ref,
  freeSolo,
  autoSelect = false,
  onBlur,
  onKeyDown,
  extraSearch,
  fetchFunction,
  maxLength,
  openOnFocus = false,
  disabled = false,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [openOnFocusState, setOpenOnFocusState] = useState(openOnFocus);
  const [options, setOptions] = useState([]);
  const [query, setQuery] = useState(""); // Controla para o componente nao abrir no click e exibir a mensagem "No options..."
  const classesCustomComponentsStyle = useCustomComponentsStyle();

  const theme = useTheme();
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("custom"));

  const onSearch = useCallback(
    async (searchTerm) => {
      // eslint-disable-next-line no-console
      // console.log("Autocomplete search starting...");

      try {
        if (searchTerm !== "") {
          let result = [];
          if (fetchFunction && typeof fetchFunction === "function") {
            result = await fetchFunction(searchTerm);
          } else {
            result = await service.fetchByAutoComplete(searchTerm, extraSearch);
          }

          const { data } = result;
          const { content } = data;

          if (quickAdd) {
            content.push({ id: AUTOCOMPLETE.QUICK_ADD });
          }
          setOptions(content);
        }
      } catch (error1) {
        console.error(error1);
        enqueueSnackbar(generalMessages.error, {
          variant: "error",
        });
      } finally {
        setLoading(false);
      }
    },
    [fetchFunction, quickAdd, service, extraSearch, enqueueSnackbar]
  );

  useEffect(() => {
    if (openOnFocusState) onSearch(" "); // used with openOnFocusState
  }, [onSearch, openOnFocusState]);

  const renderOptionLabel = useCallback(
    (option) => {
      if (typeof option === "string") {
        return option;
      }
      const labelOption = [];
      fieldsToShowAfterSelectItem?.forEach((fieldToShow) => {
        const valueField = option[fieldToShow];
        if (valueField != null && valueField !== "") {
          labelOption.push(valueField);
        }
      });
      return labelOption.length > 0 ? labelOption.join(" - ") : "";
    },
    [fieldsToShowAfterSelectItem]
  );

  const handleSearch = useCallback(
    (queryText) => {
      setLoading(true);
      setQuery(queryText);
      if (onTypedChange) onTypedChange(queryText);
      clearTimeout(timerID);
      timerID = setTimeout(() => {
        onSearch(queryText);
      }, WAIT_INTERVAL);
    },
    [onSearch, onTypedChange]
  );

  const shouldOpen = useMemo(() => openOnFocusState || query?.length > 0, [
    openOnFocusState,
    query?.length,
  ]);

  return (
    <>
      <AutocompleteMUI
        disabled={disabled}
        openOnFocus={openOnFocusState}
        freeSolo={freeSolo}
        ref={ref}
        style={{ width }}
        popupIcon={null}
        forcePopupIcon={false}
        open={shouldOpen}
        onBlur={(ev) => (onBlur ? onBlur(ev) : setQuery(""))}
        autoSelect={autoSelect}
        getOptionLabel={(option) => renderOptionLabel(option)}
        renderOption={renderOption}
        options={options}
        loading={loading}
        filterOptions={(x) => x}
        onChange={(ev, itemValue) => {
          onChange(itemValue);
          setQuery("");
          setOpenOnFocusState(false);
        }}
        value={value == null ? "" : value}
        renderInput={(params) => (
          <TextField
            {...params}
            onKeyDown={(ev) => (onKeyDown ? onKeyDown(ev) : null)}
            size="small"
            autoFocus={autoFocus}
            // onMouseDownCapture={(e) => e.stopPropagation()}
            variant={variant}
            style={{ width: "100%" }}
            label={label}
            placeholder={placeholder}
            className={classesCustomComponentsStyle.textField}
            onChange={(ev) => handleSearch(ev.target.value)}
            required={required}
            error={error}
            helperText={error}
            InputProps={{
              ...params.InputProps,
              inputProps: {
                ...params.inputProps,
                maxLength,
                style: {
                  padding: isMediumScreen ? "2px 2px" : null,
                },
              },
              inputRef,

              endAdornment: (
                <>
                  {loading ? (
                    <CircularProgress style={{ color: primaryColor[0] }} size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
        noOptionsText="Nenhum item encontrado!"
      />
    </>
  );
};

export default React.memo(Autocomplete);
