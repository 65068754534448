const Roles = {
  ADMIN: {
    code: "ADMIN",
    name: "Administrador",
  },
  USER: {
    code: "USER",
    name: "Usuário",
  },
  EMPLOYEE: {
    code: "EMPLOYEE",
    name: "Funcionário (sem acesso)",
  },
  ACCOUNTANT: {
    code: "ACCOUNTANT",
    name: "Contador",
  },
};

class User {
  constructor(source = {}) {
    if (source == null) return this;

    this.id = source.id;
    this.code = source.code;
    this.name = source.name;
    this.email = source.email;
    this.password = source.password;
    this.passwordRepeat = source.passwordRepeat;
    this.roles = source.roles;
    this.version = source.version;
    this.active = source.active;
    this.allowSellingBelowMinimumPrice = source.allowSellingBelowMinimumPrice;
    this.genderType = source.genderType;
    this.cpf = source.cpf;
    this.rg = source.rg;
    this.phone = source.phone;
    this.mobile = source.mobile;
    this.state = source.state;
    this.city = source.city;
    this.street = source.street;
    this.number = source.number;
    this.complement = source.complement;
    this.zipCode = source.zipCode;
    this.neighborhood = source.neighborhood;
    this.function = source.function;
    this.admissionDate = source.admissionDate;
    this.resignationDate = source.resignationDate;
    this.observation = source.observation;
    this.timeZone = source.timeZone;
    this.changePasswordFirstAccess = source.changePasswordFirstAccess;
    this.addressId = source.addressId;
    this.addressVersion = source.addressVersion;
  }
}

export { User, Roles };
