import React, { useState, useCallback, useEffect, useContext } from "react";
import moment from "moment";
import timezone from "moment-timezone";
import { DatePicker } from "@mui/x-date-pickers";
import { makeStyles } from "@mui/styles";
import CustomComponentsStyle from "assets/jss/material-dashboard-react/components/customComponentsStyle";
import CardStyle from "assets/jss/material-dashboard-react/components/cardStyle";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import Button from "components/CustomButtons/Button";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import CardFooter from "components/Card/CardFooter";
import { useHistory, useParams } from "react-router-dom";
import InputMask from "react-input-mask";
import {
  FormLabel,
  FormControlLabel,
  Checkbox,
  FormGroup,
  Switch,
  TextField,
  FormControl,
  RadioGroup,
  Radio,
  Typography,
  Divider,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import AddressForm from "components/address/AddressForm";
import AddressService from "components/address/service/AddressService";
import { useSnackbar } from "notistack";
import UserContext from "core/UserContext";
import LoginService from "views/login/service/LoginService";
import { User, Roles } from "./model/User";
import { toRequest, fromResponse } from "./converter/UserConverter";
import UserService from "./service/UserService";
import { handlingResponseErrors, isPasswordsEquals } from "../../config/util";
import MESSAGES from "../../config/messages";
import Loading from "../../components/loading/Loading";
import TextInput from "../../components/textInput/TextInput";
import TextPassword from "../../components/textInput/TextPassword";
import TextEmail from "../../components/textInput/TextEmail";
import { GenderType } from "../person/model/Person";

const { generalMessages, userMessages, customerMessages } = MESSAGES;

const useCustomComponentsStyle = makeStyles(CustomComponentsStyle);
const useCardStyle = makeStyles(CardStyle);

export default function UserForm() {
  const { setUserLogged } = useContext(UserContext);
  const classesCustomComponentsStyle = useCustomComponentsStyle();
  const classesCardStyle = useCardStyle();
  const history = useHistory();
  const routerParams = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [states, setStates] = useState([]);
  const [currentRoles, setCurrentRoles] = useState([]);

  const [user, setUser] = useState(
    new User({
      roles: [Roles.USER.code],
      active: true,
      allowSellingBelowMinimumPrice: false,
      genderType: GenderType.M.code,
      changePasswordFirstAccess: false,
      timeZone: timezone.tz.guess(),
    })
  );

  const fetchStates = useCallback(async () => {
    try {
      setLoading(true);
      const result = await AddressService.fetchStates();
      const { data } = result;
      const { content } = data;
      setStates(content);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchStates();
  }, [fetchStates]);

  const fetchById = useCallback(async () => {
    try {
      setLoading(true);
      const { id } = routerParams || {};
      if (id != null) {
        const result = await UserService.fetchById(id);
        const response = fromResponse(result?.data?.data);
        setUser(response);
        setCurrentRoles([...response.roles]);
      }
    } finally {
      setLoading(false);
    }
  }, [routerParams]);

  useEffect(() => {
    fetchById();
  }, [fetchById]);

  const handleChange = useCallback(
    (field, value) => {
      setUser({
        ...user,
        [field]: value,
      });
    },
    [user]
  );

  const handleChangeAddress = useCallback(
    (field, value, automaticFilling) => {
      if (automaticFilling) {
        // From via cep
        setUser({
          ...user,
          ...value,
        });
      } else {
        setUser({
          ...user,
          [field]: value,
        });
      }
    },
    [user]
  );

  const handleChangeRoles = useCallback(
    (field, checked) => {
      let { roles, password, passwordRepeat } = user;

      if (checked) {
        if (field === Roles.EMPLOYEE.code) {
          roles = [];
          password = "";
          passwordRepeat = "";
        } else {
          const index = roles.indexOf(Roles.EMPLOYEE.code);
          if (index !== -1) {
            roles.splice(index, 1);
          }
        }
        roles.push(field);
      } else {
        const index = roles.indexOf(field);
        roles.splice(index, 1);
        if (field === Roles.EMPLOYEE.code && roles.length === 0) {
          roles.push(Roles.USER.code);
        }
        // if it will stay empty, set user
        if (roles.length === 0) {
          roles.push(Roles.USER.code);
        }
      }
      setUser({
        ...user,
        password,
        passwordRepeat,
        roles,
      });
      setErrors({});
    },
    [user]
  );

  const goBack = useCallback(() => {
    history.push("/app/users");
  }, [history]);

  const onSave = useCallback(async () => {
    try {
      setLoading(true);
      if (!isPasswordsEquals(user.password, user.passwordRepeat)) {
        setErrors({
          ...errors,
          passwordRepeat: "As senhas devem ser iguais",
        });
      } else {
        const { id } = user;
        if (id != null) {
          await UserService.update(id, toRequest(user));
        } else {
          await UserService.save(toRequest(user));
        }
        const me = await LoginService.me();
        setUserLogged(me?.data);
        enqueueSnackbar(generalMessages.saveSuccess, {
          variant: "success",
          autoHideDuration: 3000,
        });
        goBack();
        // setTimeout(() => {
        //   window.location.reload();
        // }, 500);
      }
    } catch (error) {
      const { message, validationErrors } = handlingResponseErrors(error);
      enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
      if (validationErrors != null) {
        setErrors(validationErrors);
      }
    } finally {
      setLoading(false);
    }
  }, [user, errors, setUserLogged, enqueueSnackbar, goBack]);

  const hasRole = useCallback((roles, role) => roles.indexOf(role) !== -1, []);

  const renderPhones = () => (
    <>
      <GridItem xs={12} sm={12} md={6}>
        <InputMask
          mask="(99) 99999-9999"
          disabled={false}
          value={user.phone == null ? "" : user.phone}
          onChange={(value) => handleChange("phone", value.currentTarget.value)}
        >
          <TextField
            size="small"
            variant="outlined"
            id="phone"
            label={customerMessages.phone}
            fullWidth
            InputLabelProps={{
              className: classesCustomComponentsStyle.labelTextField,
            }}
            className={classesCustomComponentsStyle.textField}
          />
        </InputMask>
      </GridItem>
      <GridItem xs={12} sm={12} md={6}>
        <InputMask
          mask="(99) 99999-9999"
          disabled={false}
          value={user.mobile == null ? "" : user.mobile}
          onChange={(value) => handleChange("mobile", value.currentTarget.value)}
        >
          <TextField
            size="small"
            variant="outlined"
            id="mobile"
            label={customerMessages.mobile}
            fullWidth
            InputLabelProps={{
              className: classesCustomComponentsStyle.labelTextField,
            }}
            className={classesCustomComponentsStyle.textField}
          />
        </InputMask>
      </GridItem>
    </>
  );

  const renderSexType = () => (
    <>
      <GridItem xs={12} sm={12} md={12} style={{ height: "70px" }}>
        <FormControl
          component="fieldset"
          className={classesCustomComponentsStyle.formControlRadio}
          size="small"
        >
          <FormLabel className={classesCustomComponentsStyle.formLabel}>
            {customerMessages.gender}
          </FormLabel>
          <RadioGroup
            row
            name="genderType"
            value={user.genderType}
            onChange={(value) => handleChange("genderType", value.currentTarget.value)}
          >
            <FormControlLabel
              value={GenderType.M.code}
              control={<Radio color="secondary" />}
              label={
                <Typography className={classesCustomComponentsStyle.formControlLabel}>
                  {GenderType.M.name}
                </Typography>
              }
            />
            <FormControlLabel
              value={GenderType.W.code}
              control={<Radio color="secondary" />}
              label={
                <Typography className={classesCustomComponentsStyle.formControlLabel}>
                  {GenderType.W.name}
                </Typography>
              }
            />
            <FormControlLabel
              value={GenderType.U.code}
              control={<Radio color="secondary" />}
              label={
                <Typography className={classesCustomComponentsStyle.formControlLabel}>
                  {GenderType.U.name}
                </Typography>
              }
            />
          </RadioGroup>
        </FormControl>
      </GridItem>
    </>
  );

  const renderSwitch = (value = false, field, label, disabled = false) => (
    <FormGroup style={{ paddingTop: "8px" }}>
      <FormControlLabel
        control={
          <Switch
            color="secondary"
            checked={value}
            onChange={(event) => handleChange(field, event.target.checked)}
          />
        }
        label={label}
        disabled={disabled}
      />
    </FormGroup>
  );

  const renderAllowSellingBelowMinimumPrice = (value = false, field, label, disabled = false) => (
    <FormGroup style={{ paddingTop: "8px" }}>
      <FormControlLabel
        control={
          <Switch
            color="secondary"
            checked={value}
            onChange={(event) => handleChange(field, event.target.checked)}
          />
        }
        label={label}
        disabled={disabled}
      />
    </FormGroup>
  );

  const renderName = () => (
    <GridItem xs={12} sm={12} md={12}>
      <TextInput
        autoFocus
        id="name"
        label={userMessages.name}
        value={user.name}
        onChange={handleChange}
        required
        errors={errors}
      />
    </GridItem>
  );

  const renderRG = () => (
    <GridItem xs={12} sm={12} md={6}>
      <TextInput
        id="rg"
        maxLength={11}
        label={customerMessages.rg}
        value={user.rg}
        onChange={handleChange}
      />
    </GridItem>
  );

  const renderCPF = () => (
    <GridItem xs={12} sm={12} md={6}>
      <InputMask
        mask="999.999.999-99"
        disabled={false}
        value={user.cpf == null ? "" : user.cpf}
        onChange={(value) => handleChange("cpf", value.currentTarget.value)}
      >
        <TextField
          size="small"
          variant="outlined"
          id="cpf"
          label={customerMessages.cpf}
          fullWidth
          InputLabelProps={{
            className: classesCustomComponentsStyle.labelTextField,
          }}
          className={classesCustomComponentsStyle.textField}
          error={errors.cpf}
          helperText={errors.cpf}
        />
      </InputMask>
    </GridItem>
  );

  const renderObservation = () => (
    <GridItem xs={12} sm={8} md={8}>
      <TextInput
        id="observation"
        label="Observações"
        value={user.observation}
        onChange={handleChange}
        errors={errors}
      />
    </GridItem>
  );

  const renderTimeZone = () => (
    <GridItem xs={12} sm={4} md={4}>
      <FormControl
        fullWidth
        className={classesCustomComponentsStyle.formControlSelect}
        variant="outlined"
        size="small"
      >
        <InputLabel>Fuso horário</InputLabel>
        <Select
          label="Fuso horário"
          id="type"
          autoWidth
          value={user.timeZone == null ? timezone.tz.guess() : user.timeZone}
          onChange={(value) => handleChange("timeZone", value.target.value)}
          required
          error={errors.timeZone}
          helperText={errors.timeZone}
        >
          {timezone.tz.zonesForCountry("BR").map((item) => (
            <MenuItem key={item} value={item}>
              (GMT{timezone.tz(item).format("Z")}) {item}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </GridItem>
  );

  const renderFunction = () => (
    <GridItem xs={12} sm={12} md={6}>
      <TextInput
        id="function"
        label="Função"
        value={user.function}
        onChange={handleChange}
        errors={errors}
      />
    </GridItem>
  );

  const renderAdmissionDate = () => (
    <GridItem xs={12} sm={12} md={3} style={{ marginTop: "12px" }}>
      <DatePicker
        id="admissionDate"
        label="Data admissão"
        format="dd/MM/yyyy"
        slotProps={{ textField: { size: "small" } }}
        value={user?.admissionDate ? moment(user.admissionDate).valueOf() : null}
        onChange={(date) => handleChange("admissionDate", date)}
      />
    </GridItem>
  );

  const renderResignationDate = () => (
    <GridItem xs={12} sm={12} md={3} style={{ marginTop: "12px" }}>
      <DatePicker
        id="resignationDate"
        label="Data demissão"
        format="dd/MM/yyyy"
        slotProps={{ textField: { size: "small" } }}
        value={user?.resignationDate ? moment(user.resignationDate).valueOf() : null}
        onChange={(date) => handleChange("resignationDate", date)}
      />
    </GridItem>
  );

  const renderEmail = (required = true) => (
    <GridItem xs={12} sm={12} md={12}>
      <TextEmail
        id="email"
        label={userMessages.email}
        value={user.email}
        onChange={handleChange}
        required={required}
        errors={errors}
      />
    </GridItem>
  );

  const renderPassword = () => (
    <>
      <GridItem xs={12} sm={12} md={4}>
        <TextPassword
          id="password"
          label={userMessages.password}
          value={user.password}
          onChange={handleChange}
          required
          // disabled={disabled}
          errors={errors}
        />
      </GridItem>
      <GridItem xs={12} sm={12} md={4}>
        <TextPassword
          id="passwordRepeat"
          label={userMessages.passwordRepeat}
          value={user.passwordRepeat}
          onChange={handleChange}
          // disabled={disabled}
          required
          errors={errors}
        />
      </GridItem>
      <GridItem xs={12} sm={12} md={4} style={{ display: "flex", marginTop: 10 }}>
        <FormControlLabel
          control={
            <Checkbox
              color="secondary"
              checked={user.changePasswordFirstAccess}
              onChange={(value) =>
                handleChange("changePasswordFirstAccess", value.currentTarget.checked)
              }
            />
          }
          label="Alterar senha no primeiro acesso"
        />
      </GridItem>
    </>
  );

  const renderRoleAdmin = (disabled = false) => (
    <FormControlLabel
      control={
        <Checkbox
          color="secondary"
          disabled={disabled}
          checked={hasRole(user.roles, Roles.ADMIN.code)}
          onChange={(value) => handleChangeRoles(Roles.ADMIN.code, value.currentTarget.checked)}
        />
      }
      color="secondary"
      label={Roles.ADMIN.name}
    />
  );

  const renderRoleUser = (disabled = false) => (
    <FormControlLabel
      control={
        <Checkbox
          color="secondary"
          disabled={disabled}
          checked={hasRole(user.roles, Roles.USER.code)}
          onChange={(value) => handleChangeRoles(Roles.USER.code, value.currentTarget.checked)}
        />
      }
      label={Roles.USER.name}
    />
  );

  const renderRoleAccountant = (disabled = false) => (
    <FormControlLabel
      control={
        <Checkbox
          color="secondary"
          disabled={disabled}
          checked={hasRole(user.roles, Roles.ACCOUNTANT.code)}
          onChange={(value) =>
            handleChangeRoles(Roles.ACCOUNTANT.code, value.currentTarget.checked)
          }
        />
      }
      label={Roles.ACCOUNTANT.name}
    />
  );

  const renderRoleEmployee = () => (
    <FormControlLabel
      control={
        <Checkbox
          color="secondary"
          checked={hasRole(user.roles, Roles.EMPLOYEE.code)}
          onChange={(value) => handleChangeRoles(Roles.EMPLOYEE.code, value.currentTarget.checked)}
        />
      }
      label={Roles.EMPLOYEE.name}
    />
  );

  const renderRoles = () => (
    <GridItem xs={12} sm={12} md={12}>
      <FormGroup row>
        {renderRoleAdmin()}
        {renderRoleUser()}
        {renderRoleAccountant()}
        {renderRoleEmployee()}
      </FormGroup>
    </GridItem>
  );

  const isEmployee = useCallback(() => hasRole(user.roles, Roles.EMPLOYEE.code), [
    hasRole,
    user.roles,
  ]);

  const wasEmployee = useCallback(() => {
    const currentEmployeeRole = hasRole(currentRoles, Roles.EMPLOYEE.code);
    const isEmployeex = hasRole(user.roles, Roles.EMPLOYEE.code);
    return currentEmployeeRole && !isEmployeex;
  }, [currentRoles, hasRole, user.roles]);

  const isNew = useCallback(() => !user?.id, [user]);

  const isRenderPassword = useCallback(() => {
    const isNewx = isNew();
    const isEmployeex = isEmployee();
    const wasEmployeex = wasEmployee();

    if ((isNewx && !isEmployeex) || wasEmployeex) {
      return true;
    }
    return false;
  }, [isEmployee, isNew, wasEmployee]);

  return (
    <>
      <GridContainer style={{ justifyContent: "center" }}>
        <GridItem xs={12} sm={12} md={10}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classesCardStyle.cardTitle}>{userMessages.user}</h4>
              <p className={classesCardStyle.cardCategory}>{userMessages.userForm}</p>
            </CardHeader>
            <CardBody>
              <GridContainer>
                {renderRoles()}
                {renderName()}

                {renderEmail(!isEmployee())}
                {isRenderPassword() && renderPassword()}
                <GridItem xs={12} sm={12} md={12} style={{ marginTop: 12 }}>
                  <Divider style={{ width: "100%", marginBottom: 4 }} />
                  <Typography style={{ fontSize: 18, fontWeight: 500 }}>
                    Dados adicionais
                  </Typography>
                </GridItem>
                {renderSexType()}

                {renderCPF()}
                {renderRG()}

                {renderPhones()}
                <AddressForm
                  errorFields={{
                    zipCode: "address.zipCode",
                    state: "address.state",
                    city: "address.city",
                    street: "address.street",
                    neighborhood: "address.neighborhood",
                    number: "address.number",
                    complement: "address.complement",
                  }}
                  address={user}
                  states={states}
                  handleChange={handleChangeAddress}
                  errors={errors}
                  required={false}
                />
                {renderFunction()}
                {renderAdmissionDate()}
                {renderResignationDate()}
                {renderObservation()}
                {renderTimeZone()}
                <GridItem xs={12} sm={12} md={12}>
                  {renderSwitch(user.active, "active", "Ativo")}
                  {renderAllowSellingBelowMinimumPrice(
                    user.allowSellingBelowMinimumPrice,
                    "allowSellingBelowMinimumPrice",
                    "Permite vender abaixo do preço mínimo"
                  )}
                </GridItem>
              </GridContainer>
            </CardBody>
            <CardFooter>
              <Button color="primary" onClick={goBack}>
                {generalMessages.cancel}
              </Button>
              <Button color="primary" onClick={onSave}>
                {generalMessages.save}
              </Button>
            </CardFooter>
          </Card>
        </GridItem>
        <Loading loading={loading} />
      </GridContainer>
    </>
  );
}
