/* eslint-disable react/prop-types */
import React, { useCallback } from "react";
import { Divider, Typography } from "@mui/material";
import GridItem from "components/Grid/GridItem";
import TextInput from "components/textInput/TextInput";
import CurrencyTextInput from "components/textInput/CurrencyTextInput";
import GridContainer from "components/Grid/GridContainer";

const NotaFiscalVolumes = ({ data = {}, handleChange, errors = {}, disabled = false }) => {
  const onChange = useCallback(
    (field, value) => {
      handleChange({
        ...data,
        [field]: value,
      });
    },
    [data, handleChange]
  );

  const renderQuantidadeVolume = () => (
    <GridItem xs={12} sm={12} md={3}>
      <CurrencyTextInput
        decimalPlaces={3}
        currencySymbol=""
        id="quantidadeVolume"
        label="Quantidade"
        value={data.quantidadeVolume || "0"}
        onChange={onChange}
        errors={errors}
        disabled={disabled}
      />
    </GridItem>
  );

  const renderPesoLiquido = () => (
    <GridItem xs={12} sm={12} md={3}>
      <CurrencyTextInput
        decimalPlaces={3}
        currencySymbol=""
        id="pesoLiquidoVolume"
        label="Peso líquido"
        value={data.pesoLiquidoVolume || "0"}
        onChange={onChange}
        errors={errors}
        disabled={disabled}
      />
    </GridItem>
  );

  const renderPesoBruto = () => (
    <GridItem xs={12} sm={12} md={3}>
      <CurrencyTextInput
        decimalPlaces={3}
        currencySymbol=""
        id="pesoBrutoVolume"
        label="Peso bruto"
        value={data.pesoBrutoVolume || "0"}
        onChange={onChange}
        errors={errors}
        disabled={disabled}
      />
    </GridItem>
  );

  const renderEspecieVolume = () => (
    <GridItem xs={12} sm={12} md={4}>
      <TextInput
        id="especieVolume"
        label="Espécie"
        value={data.especieVolume}
        onChange={onChange}
        required
        errors={errors}
        maxLength={60}
        disabled={disabled}
      />
    </GridItem>
  );

  const renderMarcaVolume = () => (
    <GridItem xs={12} sm={12} md={4}>
      <TextInput
        id="marcaVolume"
        label="Marca"
        value={data.marcaVolume}
        onChange={onChange}
        required
        errors={errors}
        maxLength={60}
        disabled={disabled}
      />
    </GridItem>
  );

  const renderNumeracaoVolume = () => (
    <GridItem xs={12} sm={12} md={4}>
      <TextInput
        id="numeracaoVolume"
        label="Numeração"
        value={data.numeracaoVolume}
        onChange={onChange}
        required
        errors={errors}
        maxLength={60}
        disabled={disabled}
      />
    </GridItem>
  );

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12} style={{ marginTop: 30 }}>
        <Divider style={{ width: "100%", marginBottom: 4 }} />
        <Typography style={{ fontSize: 18, fontWeight: 500 }}>Volumes</Typography>
      </GridItem>
      {renderQuantidadeVolume()}
      {renderPesoLiquido()}
      {renderPesoBruto()}
      {renderEspecieVolume()}
      {renderMarcaVolume()}
      {renderNumeracaoVolume()}
    </GridContainer>
  );
};

export default React.memo(NotaFiscalVolumes);
