import React, { useState, useEffect, useCallback, useRef } from "react";
import { makeStyles } from "@mui/styles";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import CardStyle from "assets/jss/material-dashboard-react/components/cardStyle";
import ButtonCustom from "components/CustomButtons/Button";
import TextInput from "components/textInput/TextInput";
import moment from "moment";
import { DatePicker } from "@mui/x-date-pickers";
import { Box, Chip, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import customComponentsStyle from "assets/jss/material-dashboard-react/components/customComponentsStyle";
import HistoryIcon from "components/icons/HistoryIcon";
import SeeIcon from "components/icons/SeeIcon";
import NotaFiscalHistorico from "views/notaFiscal/components/dialog/NotaFiscalHistorico";
import { NFModelo, NotaFiscalStatusType } from "views/notaFiscal/model/NotaFiscal";
import { NFTipo } from "views/naturezaOperacao/model/NaturezaOperacao";
import useEmpresaNaoAptaAlert from "hooks/useEmpresaNaoAptaAlert";
import AccordionFilters from "components/Accordion/AccordionFilters";
import NotaFiscalService from "../notaFiscal/service/NotaFiscalService";
import MESSAGES from "../../config/messages";
import Loading from "../../components/loading/Loading";
import AddButton from "../../components/addButton/AddButton";
import { currencyFormatter } from "../../config/util";
import EditIcon from "../../components/icons/EditIcon";
import DataGridCustom from "../../components/dataGrid/DataGridCustom";

const { generalMessages } = MESSAGES;

const useCardStyle = makeStyles(CardStyle);

const SearchAll = {
  All: {
    code: "ALL",
    name: "Todos",
  },
};

const initialSearch = {
  numero: null,
  cliente: null,
  data: null,
  status: SearchAll.All.code,
};

export default function NFCeEmissaoList() {
  const customGridRef = useRef();
  const classesuseCardStyle = useCardStyle();
  const useCustomComponentsStyle = makeStyles(customComponentsStyle);
  const classesCustomComponentsStyle = useCustomComponentsStyle();
  const history = useHistory();
  const [list, setList] = useState([]);
  const [search, setSearch] = useState(initialSearch);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [openHistoricoNotaFiscalModal, setOpenHistoricoNotaFiscalModal] = useState(false);
  const [notaFiscalHistoricoList, setNotaFiscalHistoricoList] = useState([]);
  const { showEmpresaNaoAptaAlert } = useEmpresaNaoAptaAlert();
  const [rowCount, setRowCount] = React.useState(0);

  const handleChangeSearch = useCallback(
    (field, value) => {
      setSearch({
        ...search,
        [field]: value,
      });
    },
    [search]
  );

  const fetchAll = useCallback(
    async ({ searchParams, paginationModelParams, sorting }) => {
      setLoading(true);
      try {
        const params = [];
        const pagination =
          paginationModelParams || customGridRef.current.getDefaultPaginationModel();

        params.push(`queryField=tipo;${NFTipo.SAIDA.code}`);
        params.push(`queryField=modelo;${NFModelo.NFCE.code}`);

        if (searchParams.numero != null) {
          params.push(`queryField=numero;${searchParams.numero}`);
        }
        if (searchParams.cliente != null) {
          params.push(`queryField=cliente.name;${searchParams.cliente}`);
        }
        if (searchParams.data != null) {
          params.push(`queryField=dataHoraEmissao;${searchParams.data}`);
        }
        if (searchParams.status != null && searchParams.status !== SearchAll.All.code) {
          params.push(`queryField=status;${searchParams.status}`);
        }

        const result = await NotaFiscalService.searchWithParams({
          params: params.join("&"),
          pagination,
          sorting,
        });

        const { data } = result;
        const { content, totalElements } = data;

        setList(content);
        setRowCount(totalElements);
      } catch (error) {
        enqueueSnackbar(generalMessages.error, {
          variant: "error",
        });
      } finally {
        setLoading(false);
      }
    },
    [enqueueSnackbar]
  );

  useEffect(() => {
    fetchAll({ searchParams: initialSearch });
  }, [fetchAll]);

  const goToForm = async (params) => {
    const result = await NotaFiscalService.empresaAptaEmissao();
    if (result == null) {
      enqueueSnackbar("Ocorreu um erro!", {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
    const { errors, status } = result;
    if (status === 200) {
      return params?.id
        ? history.push(`/app/nfce-emissao/${params?.id}`)
        : history.push("/app/nfce-emissao");
    }
    if (status === 422) {
      return showEmpresaNaoAptaAlert(errors);
    }
    return null;
  };

  const formatterStatus = (item) => {
    const { status } = item;

    if (status != null && status !== undefined) {
      switch (status) {
        case NotaFiscalStatusType.CRIADA.code:
          return (
            <Chip
              size="small"
              label={NotaFiscalStatusType[status].name}
              style={{ backgroundColor: "#1186e3", color: "#ffff" }}
            />
          );

        case NotaFiscalStatusType.EMITIDA.code:
          return (
            <Chip
              size="small"
              label={NotaFiscalStatusType[status].name}
              style={{ backgroundColor: "#0e850b", color: "#ffff" }}
            />
          );

        case NotaFiscalStatusType.ERRO.code:
          return (
            <Chip
              size="small"
              label={NotaFiscalStatusType[status].name}
              style={{ backgroundColor: "#e53935", color: "#ffff" }}
            />
          );
        case NotaFiscalStatusType.CANCELADA.code:
          return (
            <Chip
              size="small"
              label={NotaFiscalStatusType[status].name}
              style={{ backgroundColor: "#aa810d", color: "#ffff" }}
            />
          );
        default:
          return "";
      }
    }
    return "";
  };

  const formatterOrigem = (item) => {
    if (item?.order?.id) {
      return (
        <Chip
          component="a"
          onClick={() => history.push(`/app/order/${item?.order?.id}`, { readOnly: true })}
          clickable
          size="small"
          label={`Venda ${item?.order?.orderNumber}`}
          style={{ backgroundColor: "#0e850b", color: "#ffff" }}
        />
      );
    }

    if (item?.serviceOrder?.id) {
      return (
        <Chip
          component="a"
          onClick={() =>
            history.push(`/app/service-order/${item?.serviceOrder?.id}`, { readOnly: true })
          }
          clickable
          size="small"
          label={`Ordem serviço ${item?.serviceOrder?.orderNumber}`}
          style={{ backgroundColor: "#0e850b", color: "#ffff" }}
        />
      );
    }

    return (
      <Chip size="small" label="Avulsa" style={{ backgroundColor: "#1186e3", color: "#ffff" }} />
    );
  };

  const readyOnly = (item) => {
    const { status } = item;
    return (
      status === NotaFiscalStatusType.EMITIDA.code || status === NotaFiscalStatusType.CANCELADA.code
    );
  };

  const handleOpenHistoricoNotaFiscalModal = useCallback(async (id) => {
    setOpenHistoricoNotaFiscalModal(true);
    try {
      setLoading(true);
      const { data } = await NotaFiscalService.fetchHistorico(id);
      const historicoList = data?.data || [];
      setNotaFiscalHistoricoList(historicoList);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, []);

  const handleCloseHistoricoNotaFiscalModal = useCallback(() => {
    setOpenHistoricoNotaFiscalModal(false);
    setNotaFiscalHistoricoList([]);
  }, []);

  const getColumns = () => {
    const columns = [
      {
        field: "numero",
        headerName: "Número",
        flex: 1,
        minWidth: 120,
        valueGetter: ({ value }) => (!value ? "--" : value),
      },
      {
        field: "nomeDestinatario",
        headerName: "Cliente",
        flex: 2,
        minWidth: 250,
        valueGetter: ({ value }) => (!value ? "Consumidor não identificado" : value),
      },

      {
        field: "totalNf",
        headerName: "Total",
        flex: 1,
        minWidth: 120,
        valueGetter: ({ value }) => currencyFormatter(value),
      },
      {
        field: "dataHoraEmissao",
        headerName: "Data/Hora emissão",
        minWidth: 200,
        flex: 1,
        valueGetter: ({ value }) => (!value ? "--" : moment(value).format("DD/MM/YYYY HH:mm")),
      },
      {
        field: "origem",
        headerName: "Origem",
        width: 150,
        renderCell: ({ row: item }) => (
          <div
            style={{
              justifyContent: "center",
              padding: "10px",
              display: "flex",

              flex: 1,
            }}
          >
            {formatterOrigem(item)}
          </div>
        ),
      },
      {
        field: "status",
        headerName: "Situação",
        width: 150,
        renderCell: ({ row: item }) => (
          <div
            style={{
              justifyContent: "center",
              padding: "10px",
              display: "flex",

              flex: 1,
            }}
          >
            {formatterStatus(item)}
          </div>
        ),
      },
    ];

    columns.push({
      field: "actions",
      headerName: generalMessages.actions,
      sortable: false,
      width: 100,
      disableClickEventBubbling: true,
      renderCell: (params) => (
        <div
          style={{
            cursor: "pointer",
            justifyContent: "flex-end",
            padding: "10px",
            display: "flex",
            flex: 1,
          }}
        >
          <>
            {readyOnly(params.row) ? (
              <SeeIcon onClick={() => goToForm({ id: params.row.id })} style={{ padding: 6 }} />
            ) : (
              <EditIcon onClick={() => goToForm({ id: params.row.id })} style={{ padding: 6 }} />
            )}

            <HistoryIcon
              onClick={() => handleOpenHistoricoNotaFiscalModal(params.row?.id || null)}
              style={{ padding: 6 }}
            />
          </>
        </div>
      ),
    });

    return columns;
  };

  const onSearch = useCallback(() => {
    fetchAll({ searchParams: search });
    customGridRef.current.resetPaginationModel();
  }, [fetchAll, search]);

  const onClear = useCallback(() => {
    fetchAll({ searchParams: initialSearch });
    customGridRef.current.resetPaginationModel();

    setSearch(initialSearch);
  }, [fetchAll]);

  const renderStatus = () => (
    <FormControl
      fullWidth
      className={classesCustomComponentsStyle.formControlSelect}
      size="small"
      variant="outlined"
    >
      <InputLabel>Situação</InputLabel>
      <Select
        label="Situação"
        autoWidth
        value={search.status == null ? "" : search.status}
        onChange={(event) => {
          const {
            target: { value },
          } = event;
          return handleChangeSearch("status", value);
        }}
      >
        <MenuItem key={SearchAll.All.code} value={SearchAll.All.code}>
          {SearchAll.All.name}
        </MenuItem>
        {Object.keys(NotaFiscalStatusType).map((key) => {
          const { code, name } = NotaFiscalStatusType[key];
          return (
            <MenuItem key={code} value={code}>
              {name}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );

  const renderNumero = () => (
    <TextInput
      autoFocus
      onKeyPress={(e) => {
        if (e.which === 13) {
          onSearch();
        }
      }}
      label="Número"
      value={search.numero}
      onChange={(ev, value) => {
        handleChangeSearch("numero", value);
      }}
    />
  );

  const renderCliente = () => (
    <TextInput
      onKeyPress={(e) => {
        if (e.which === 13) {
          onSearch();
        }
      }}
      label="Cliente"
      value={search.cliente}
      onChange={(ev, value) => {
        handleChangeSearch("cliente", value);
      }}
    />
  );

  const renderData = () => (
    <DatePicker
      id="dataHoraEmissao"
      label="Data emissão"
      format="dd/MM/yyyy"
      slotProps={{ textField: { size: "small" } }}
      value={search?.data ? moment(search?.data).valueOf() : null}
      onChange={(date) => handleChangeSearch("data", moment(date))}
    />
  );

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card className="gridLayoutCard">
          <div className="gridLayoutContainer">
            <CardHeader color="primary">
              <h4 className={classesuseCardStyle.cardTitleList}>
                Emissões notas fiscais do consumidor eletrônica
              </h4>
              <p className={classesuseCardStyle.cardCategoryList}>
                Lista de notas fiscais emitidas
              </p>
            </CardHeader>

            <div className="gridLayoutFilters">
              <AccordionFilters screen="nfce_emissao_list">
                <GridContainer>
                  <GridItem xs={12} sm={12} md={2}>
                    {renderNumero()}
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    {renderCliente()}
                  </GridItem>
                  <GridItem xs={12} sm={12} md={2} style={{ marginTop: "12px" }}>
                    {renderData()}
                  </GridItem>
                  <GridItem xs={12} sm={12} md={2}>
                    {renderStatus()}
                  </GridItem>

                  <GridItem xs={12} sm={12} md={4} style={{ marginTop: 6, flexBasis: 0 }}>
                    <Box display="flex">
                      <ButtonCustom color="primary" onClick={onSearch}>
                        {generalMessages.search}
                      </ButtonCustom>
                      <ButtonCustom color="primary" onClick={onClear}>
                        Limpar
                      </ButtonCustom>
                    </Box>
                  </GridItem>
                </GridContainer>
              </AccordionFilters>
            </div>

            <div className="gridLayoutGrid">
              <DataGridCustom
                ref={customGridRef}
                onRowDoubleClick={(params) => goToForm(params.row)}
                rows={list}
                columns={getColumns()}
                usePagination
                rowCount={rowCount}
                fetchAction={(params) =>
                  fetchAll({
                    ...params,
                    searchParams: search,
                  })
                }
              />
            </div>
            <div className="gridLayoutBottom">
              <AddButton onClick={() => goToForm({ id: null, standard: false })} />
            </div>
            <NotaFiscalHistorico
              open={openHistoricoNotaFiscalModal}
              onClose={handleCloseHistoricoNotaFiscalModal}
              items={notaFiscalHistoricoList}
            />
          </div>
        </Card>
        <Loading loading={loading} />
      </GridItem>
    </GridContainer>
  );
}
