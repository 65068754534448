/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useCallback, useEffect } from "react";
import { makeStyles } from "@mui/styles";

import CardStyle from "assets/jss/material-dashboard-react/components/cardStyle";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import Button from "components/CustomButtons/Button";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import CardFooter from "components/Card/CardFooter";
import { useHistory, useParams } from "react-router-dom";
import {
  Box,
  FormControlLabel,
  FormGroup,
  Switch,
  Tab,
  Button as ButtonUI,
  Divider,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { TabContext, TabList, TabPanel } from "@mui/lab";

import { primaryColor } from "assets/jss/material-dashboard-react";
import useConfirmDialog from "components/confirm/ConfirmDialog";
import NaturezaOperacaoService from "./service/NaturezaOperacaoService";
import {
  NaturezaOperacao,
  TipoExcecao,
  SituacaoOperacionalSimplesNacional,
  SituacaoTributariaIpi,
  SituacaoTributariaIssqn,
  SituacaoTributariaPis,
  SituacaoTributariaCofins,
  SituacaoTributariaIcms,
  ReducaoBaseCalculoIcmsStType,
  ItemCompoemValorTotalNfeType,
  NFRegimeTributario,
  VendaParaZonaFrancaDeManausType,
  TipoCalculoNFeType,
  NFNotaInfoItemModalidadeBCICMSST,
  NFNotaInfoItemModalidadeBCICMS,
  NFTipo,
  NaturezaOperacaoPadraoType,
} from "./model/NaturezaOperacao";
import { toRequest, fromResponse } from "./converter/NaturezaOperacaoConverter";
import { handlingResponseErrors } from "../../config/util";
import MESSAGES from "../../config/messages";
import Loading from "../../components/loading/Loading";
import NaturezaOperacaoGeneral from "./components/NaturezaOperacaoGeneral";
import NOSimplesTab from "./components/NOSimplesTab";
import NOIcmsTab from "./components/NOIcmsTab";
import NOIpiTab from "./components/NOIpiTab";
import NOPisTab from "./components/NOPisTab";
import NOCofinsTab from "./components/NOCofinsTab";
import NOExceptionModal from "./components/NOExceptionModal";
import NOExceptionsUtil from "./util/NOExceptionsUtil";
import NOExceptionTable from "./components/NOExceptionTable";
import NOAdvancedConfigs from "./components/NOAdvancedConfigs";

const { generalMessages, naturezaOperacaoMessages } = MESSAGES;

const useCardStyle = makeStyles(CardStyle);

// eslint-disable-next-line react/prop-types
export default function NaturezaOperacaoForm() {
  const routerParams = useParams();

  const classesCardStyle = useCardStyle();
  const history = useHistory();
  const { showConfirmDialog, closeConfirmDialog, ConfirmDialog } = useConfirmDialog();
  const { enqueueSnackbar } = useSnackbar();
  const [errors, setErrors] = useState({});
  const [exceptionEditing, setExceptionEditing] = useState(null);

  const [natureza, setNatureza] = useState(
    new NaturezaOperacao({
      active: true,
      regimeTributario: NFRegimeTributario.SIMPLES_NACIONAL.code,
      operacaoConsumidorFinal: true,
      tipo: NFTipo.ENTRADA.code,
      padrao: NaturezaOperacaoPadraoType.SEM_PADRAO.code,
    })
  );

  const [tab, setTab] = useState(TipoExcecao.SIMPLES);
  const [openModalExceptions, setOpenModalExceptions] = useState(false);

  useEffect(() => {
    setTab(
      natureza.regimeTributario === NFRegimeTributario.SIMPLES_NACIONAL.code
        ? TipoExcecao.SIMPLES
        : TipoExcecao.ICMS
    );
  }, [natureza.regimeTributario]);

  const addExceptions = () => {
    setOpenModalExceptions(true);
  };

  const closeExceptions = () => {
    setOpenModalExceptions(false);
  };

  const [loading, setLoading] = useState(false);

  const [simples, setSimples] = useState({
    situacaoOperacaoSnSimples:
      SituacaoOperacionalSimplesNacional.TRIBUTADA_SEM_PERMISSAO_CREDITO.value,
    obterIcmsStRetidoAnteriormenteNfCompra: false,
    partilhaIcmsInterestadualSimples: true,
    tipoCalculoSimples: TipoCalculoNFeType.VALOR.code,
    modalidadeBcIcmsStSimples: NFNotaInfoItemModalidadeBCICMSST.MARGEM_VALOR_AGREGADO.code,
    modalidadeBcIcmsSimples: NFNotaInfoItemModalidadeBCICMS.MVA.code,
  });

  const [icms, setIcms] = useState({
    situacaoTributariaIcms: SituacaoTributariaIcms.TRIBUTACAO_INTEGRALMENTE.value,
    obterIcmsStRetidoAnteriormenteNfCompraIcms: false,
    partilhaIcmsInterestadualIcms: true,
    tipoCalculoIcms: TipoCalculoNFeType.VALOR.code,
    modalidadeBcIcmsStIcms: NFNotaInfoItemModalidadeBCICMSST.MARGEM_VALOR_AGREGADO.code,
    modalidadeBcIcmsIcms: NFNotaInfoItemModalidadeBCICMS.MVA.code,
  });

  const [ipi, setIpi] = useState({
    situacaoTributariaIpi: SituacaoTributariaIpi.SELECIONE.value,
    tipoCalculoIpi: TipoCalculoNFeType.VALOR.code,
  });

  const [issqn, setIssqn] = useState({
    descontarIssIssqn: true,
    situacaoTributariaIssqn: SituacaoTributariaIssqn.ISENTA.code,
    tipoCalculoIssqn: TipoCalculoNFeType.VALOR.code,
  });

  const [pis, setPis] = useState({
    situacaoTributariaPis: SituacaoTributariaPis.OPERACAO_SEM_INCIDENCIA_CONTRIBUICAO.value,
    tipoCalculoPis: TipoCalculoNFeType.VALOR.code,
  });

  const [cofins, setCofins] = useState({
    situacaoTributariaCofins: SituacaoTributariaCofins.OPERACAO_SEM_INCIDENCIA_CONTRIBUICAO.value,
    tipoCalculoCofins: TipoCalculoNFeType.VALOR.code,
  });

  const [advancedConfigs, setAdvancedConfigs] = useState({
    considerarComoReceitaNoDre: true,
    considerarCvmNoDre: true,
    compraDeProdutorRural: false,
    permiteLancamentoDeEstoque: true,
    descontarFunruralDoTotalFaturado: true,
    vendaParaZonaFrancaDeManaus: VendaParaZonaFrancaDeManausType.NAO.code,
    reducaoDaBaseDeCalculoDoIcmsSt: ReducaoBaseCalculoIcmsStType.PADRAO.code,
    descontarDesoneracaoDoTotalDaNotaFiscal: true,
    itemCompoemValorTotalNfe: ItemCompoemValorTotalNfeType.COMPOEM_VALOR_TOTAL.code,
  });

  const [excecoesSimples, setExcecoesSimples] = useState([]);
  const [excecoesIpi, setExcecoesIpi] = useState([]);
  const [excecoesIssqn, setExcecoesIssqn] = useState([]);
  const [excecoesPis, setExcecoesPis] = useState([]);
  const [excecoesCofins, setExcecoesCofins] = useState([]);
  const [excecoesIcms, setExcecoesIcms] = useState([]);

  const handleChangeSimples = useCallback(
    (field, value) => {
      setSimples({
        ...simples,
        [field]: value,
      });
    },
    [simples]
  );

  const handleChangeIcms = useCallback(
    (field, value) => {
      setIcms({
        ...icms,
        [field]: value,
      });
    },
    [icms]
  );

  const handleChangeIpi = useCallback(
    (field, value) => {
      setIpi({
        ...ipi,
        [field]: value,
      });
    },
    [ipi]
  );

  // const handleChangeIssqn = useCallback(
  //   (field, value) => {
  //     setIssqn({
  //       ...issqn,
  //       [field]: value,
  //     });
  //   },
  //   [issqn]
  // );

  const handleChangePis = useCallback(
    (field, value) => {
      setPis({
        ...pis,
        [field]: value,
      });
    },
    [pis]
  );

  const handleChangeCofins = useCallback(
    (field, value) => {
      setCofins({
        ...cofins,
        [field]: value,
      });
    },
    [cofins]
  );

  const handleChangeAdvancedConfigs = useCallback(
    (field, value) => {
      setAdvancedConfigs({
        ...advancedConfigs,
        [field]: value,
      });
    },
    [advancedConfigs]
  );

  const handleChange = useCallback(
    (field, value) => {
      setNatureza({
        ...natureza,
        [field]: value,
      });
    },
    [natureza]
  );

  const onEditException = useCallback(
    (index) => {
      switch (tab) {
        case TipoExcecao.SIMPLES: {
          const exception = excecoesSimples.find((item) => item.index === index);
          setExceptionEditing(exception);
          setOpenModalExceptions(true);
          break;
        }

        case TipoExcecao.IPI: {
          const exception = excecoesIpi.find((item) => item.index === index);
          setExceptionEditing(exception);
          setOpenModalExceptions(true);
          break;
        }

        case TipoExcecao.ISSQN: {
          const exception = excecoesIssqn.find((item) => item.index === index);
          setExceptionEditing(exception);
          setOpenModalExceptions(true);
          break;
        }

        case TipoExcecao.PIS: {
          const exception = excecoesPis.find((item) => item.index === index);
          setExceptionEditing(exception);
          setOpenModalExceptions(true);
          break;
        }

        case TipoExcecao.COFINS: {
          const exception = excecoesCofins.find((item) => item.index === index);
          setExceptionEditing(exception);
          setOpenModalExceptions(true);
          break;
        }

        case TipoExcecao.ICMS: {
          const exception = excecoesIcms.find((item) => item.index === index);
          setExceptionEditing(exception);
          setOpenModalExceptions(true);
          break;
        }

        default:
          setExceptionEditing(null);
      }
    },
    [excecoesCofins, excecoesIcms, excecoesIpi, excecoesIssqn, excecoesPis, excecoesSimples, tab]
  );

  const getExceptions = useCallback(() => {
    switch (tab) {
      case TipoExcecao.SIMPLES:
        return excecoesSimples;
      case TipoExcecao.IPI:
        return excecoesIpi;
      case TipoExcecao.ISSQN:
        return excecoesIssqn;
      case TipoExcecao.PIS:
        return excecoesPis;
      case TipoExcecao.COFINS:
        return excecoesCofins;
      case TipoExcecao.ICMS:
        return excecoesIcms;
      default:
        return {};
    }
  }, [excecoesIcms, excecoesCofins, excecoesIpi, excecoesIssqn, excecoesPis, excecoesSimples, tab]);

  const confirmExceptions = useCallback(
    (exception) => {
      switch (tab) {
        case TipoExcecao.SIMPLES: {
          setExcecoesSimples(NOExceptionsUtil.updateExceptionList(exception, excecoesSimples));
          break;
        }
        case TipoExcecao.IPI:
          setExcecoesIpi(NOExceptionsUtil.updateExceptionList(exception, excecoesIpi));
          break;
        case TipoExcecao.ISSQN:
          setExcecoesIssqn(NOExceptionsUtil.updateExceptionList(exception, excecoesIssqn));
          break;
        case TipoExcecao.PIS:
          setExcecoesPis(NOExceptionsUtil.updateExceptionList(exception, excecoesPis));
          break;
        case TipoExcecao.COFINS:
          setExcecoesCofins(NOExceptionsUtil.updateExceptionList(exception, excecoesCofins));
          break;
        case TipoExcecao.ICMS:
          setExcecoesIcms(NOExceptionsUtil.updateExceptionList(exception, excecoesIcms));
          break;
        default:
          break;
      }
      setExceptionEditing(null);
    },
    [excecoesIcms, excecoesCofins, excecoesIpi, excecoesIssqn, excecoesPis, excecoesSimples, tab]
  );

  const onDeleteException = useCallback(
    (index) => {
      showConfirmDialog({
        title: "Exceções",
        text: naturezaOperacaoMessages.exceptions.delete,
        onClickConfirm: () => {
          switch (tab) {
            case TipoExcecao.SIMPLES: {
              setExcecoesSimples(NOExceptionsUtil.deleteException(index, excecoesSimples));
              break;
            }
            case TipoExcecao.IPI:
              setExcecoesIpi(NOExceptionsUtil.deleteException(index, excecoesIpi));
              break;
            case TipoExcecao.ISSQN:
              setExcecoesIssqn(NOExceptionsUtil.deleteException(index, excecoesIssqn));
              break;
            case TipoExcecao.PIS:
              setExcecoesPis(NOExceptionsUtil.deleteException(index, excecoesPis));
              break;
            case TipoExcecao.COFINS:
              setExcecoesCofins(NOExceptionsUtil.deleteException(index, excecoesCofins));
              break;
            case TipoExcecao.ICMS:
              setExcecoesIcms(NOExceptionsUtil.deleteException(index, excecoesIcms));
              break;
            default:
              break;
          }
          setExceptionEditing(null);
          closeConfirmDialog();
        },
      });
    },
    [
      closeConfirmDialog,
      excecoesCofins,
      excecoesIcms,
      excecoesIpi,
      excecoesIssqn,
      excecoesPis,
      excecoesSimples,
      showConfirmDialog,
      tab,
    ]
  );

  const fetchById = useCallback(async () => {
    try {
      setLoading(true);
      const { id } = routerParams || {};

      if (id != null) {
        const result = await NaturezaOperacaoService.fetchById(id);
        const naturezaResponse = fromResponse(result?.data?.data);

        const {
          simples: simplesResponse,
          ipi: ipiResponse,
          issqn: issqnResponse,
          pis: pisResponse,
          cofins: cofinsResponse,
          icms: icmsResponse,
          advancedConfigs: advancedConfigsResponse,
          excecoesSimples: excecoesSimplesResponse,
          excecoesIpi: excecoesIpiResponse,
          excecoesIssqn: excecoesIssqnResponse,
          excecoesPis: excecoesPisResponse,
          excecoesCofins: excecoesCofinsResponse,
          excecoesIcms: excecoesIcmsResponse,
          ...rest
        } = naturezaResponse;

        setNatureza(rest);
        setSimples(simplesResponse);
        setIpi(ipiResponse);
        setIssqn(issqnResponse);
        setPis(pisResponse);
        setCofins(cofinsResponse);
        setIcms(icmsResponse);

        setAdvancedConfigs(advancedConfigsResponse);

        setExcecoesSimples(excecoesSimplesResponse);
        setExcecoesIpi(excecoesIpiResponse);
        setExcecoesIssqn(excecoesIssqnResponse);
        setExcecoesPis(excecoesPisResponse);
        setExcecoesCofins(excecoesCofinsResponse);
        setExcecoesIcms(excecoesIcmsResponse);
      }
    } finally {
      setLoading(false);
    }
  }, [routerParams]);

  useEffect(() => {
    fetchById();
  }, [fetchById]);

  const goBack = useCallback(() => {
    history.push("/app/operations-type");
  }, [history]);

  const onSave = useCallback(async () => {
    try {
      setLoading(true);
      const { id } = natureza;
      if (id != null) {
        await NaturezaOperacaoService.update(
          id,
          toRequest({
            natureza,
            simples,
            ipi,
            issqn,
            pis,
            cofins,
            icms,
            advancedConfigs,
            excecoesSimples,
            excecoesIpi,
            excecoesIssqn,
            excecoesPis,
            excecoesCofins,
            excecoesIcms,
          })
        );
      } else {
        await NaturezaOperacaoService.save(
          toRequest({
            natureza,
            simples,
            ipi,
            issqn,
            pis,
            cofins,
            icms,
            advancedConfigs,
            excecoesSimples,
            excecoesIpi,
            excecoesIssqn,
            excecoesPis,
            excecoesCofins,
            excecoesIcms,
          })
        );
      }
      enqueueSnackbar(generalMessages.saveSuccess, {
        variant: "success",
        autoHideDuration: 3000,
      });
      goBack();
    } catch (error) {
      const { message, validationErrors } = handlingResponseErrors(error);
      enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
      if (validationErrors != null) {
        setErrors(validationErrors);
      }
    } finally {
      setLoading(false);
    }
  }, [
    natureza,
    enqueueSnackbar,
    goBack,
    simples,
    ipi,
    issqn,
    pis,
    cofins,
    icms,
    advancedConfigs,
    excecoesSimples,
    excecoesIpi,
    excecoesIssqn,
    excecoesPis,
    excecoesCofins,
    excecoesIcms,
  ]);

  const renderSwitch = (value = false, field, label, disabled = false) => (
    <FormGroup style={{ paddingTop: "8px" }}>
      <FormControlLabel
        control={
          <Switch
            color="secondary"
            checked={value}
            onChange={(event) => handleChange(field, event.target.checked)}
          />
        }
        label={label}
        disabled={disabled}
      />
    </FormGroup>
  );

  const renderTabs = () => (
    <Box sx={{ width: "100%", typography: "body1" }}>
      <TabContext value={tab}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={(ev, value) => setTab(value)}>
            {natureza.regimeTributario === NFRegimeTributario.SIMPLES_NACIONAL.code ? (
              <Tab label={naturezaOperacaoMessages.simples.simples} value={TipoExcecao.SIMPLES} />
            ) : (
              <Tab label={naturezaOperacaoMessages.icms.icms} value={TipoExcecao.ICMS} />
            )}

            <Tab label={naturezaOperacaoMessages.ipi.ipi} value={TipoExcecao.IPI} />
            {/* <Tab label={naturezaOperacaoMessages.issqn.issqn} value={TipoExcecao.ISSQN} /> */}
            <Tab label={naturezaOperacaoMessages.pis.pis} value={TipoExcecao.PIS} />
            <Tab label={naturezaOperacaoMessages.cofins.cofins} value={TipoExcecao.COFINS} />
          </TabList>
        </Box>
        {natureza.regimeTributario === NFRegimeTributario.SIMPLES_NACIONAL.code ? (
          <TabPanel value={TipoExcecao.SIMPLES}>
            <NOSimplesTab
              data={simples}
              handleChange={handleChangeSimples}
              errors={errors}
              exceptions={getExceptions()}
              onEditException={onEditException}
              onDeleteException={onDeleteException}
            />
          </TabPanel>
        ) : (
          <TabPanel value={TipoExcecao.ICMS}>
            <NOIcmsTab
              data={icms}
              handleChange={handleChangeIcms}
              errors={errors}
              exceptions={getExceptions()}
              onEditException={onEditException}
              onDeleteException={onDeleteException}
            />
          </TabPanel>
        )}

        <TabPanel value={TipoExcecao.IPI}>
          <NOIpiTab
            data={ipi}
            handleChange={handleChangeIpi}
            errors={errors}
            exceptions={getExceptions()}
            onEditException={onEditException}
            onDeleteException={onDeleteException}
          />
        </TabPanel>
        {/* <TabPanel value={TipoExcecao.ISSQN}>
          <NOIssqnTab
            data={issqn}
            handleChange={handleChangeIssqn}
            errors={errors}
            exceptions={getExceptions()}
            onEditException={onEditException}
            onDeleteException={onDeleteException}
          />
        </TabPanel> */}
        <TabPanel value={TipoExcecao.PIS}>
          <NOPisTab
            data={pis}
            handleChange={handleChangePis}
            errors={errors}
            exceptions={getExceptions()}
            onEditException={onEditException}
            onDeleteException={onDeleteException}
          />
        </TabPanel>
        <TabPanel value={TipoExcecao.COFINS}>
          <NOCofinsTab
            data={cofins}
            handleChange={handleChangeCofins}
            errors={errors}
            exceptions={getExceptions()}
            onEditException={onEditException}
            onDeleteException={onDeleteException}
          />
        </TabPanel>
      </TabContext>
    </Box>
  );

  return (
    <>
      <GridContainer style={{ justifyContent: "center" }}>
        <GridItem xs={12} sm={12} md={10}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classesCardStyle.cardTitle}>
                {naturezaOperacaoMessages.naturezaOperacao}
              </h4>
              <p className={classesCardStyle.cardCategory}>
                {naturezaOperacaoMessages.naturezaOperacaoForm}
              </p>
            </CardHeader>
            <CardBody>
              <GridItem xs={12} sm={12} md={12}>
                <NaturezaOperacaoGeneral
                  natureza={natureza}
                  handleChange={handleChange}
                  errors={errors}
                />
                <Divider style={{ width: "100%", marginTop: 6 }} />
                <GridItem xs={12} sm={12} md={12}>
                  {renderTabs()}
                </GridItem>

                {getExceptions().length > 0 && (
                  <GridItem xs={12} sm={12} md={12}>
                    <NOExceptionTable
                      tab={tab}
                      exceptions={getExceptions()}
                      onEdit={onEditException}
                      onDelete={onDeleteException}
                    />
                  </GridItem>
                )}
                {/*
                <Box display="flex" justifyContent="end" style={{ paddingBottom: 10 }}>
                  <ButtonUI style={{ color: primaryColor[0] }} onClick={addExceptions}>
                    Adicionar exceções
                  </ButtonUI>
                </Box>
                <NOAdvancedConfigs
                  data={advancedConfigs}
                  handleChange={handleChangeAdvancedConfigs}
                />
                */}

                {/* <Divider style={{ width: "100%", marginTop: 20 }} /> */}
                <NOExceptionModal
                  exceptionItem={exceptionEditing}
                  open={openModalExceptions}
                  onClose={closeExceptions}
                  currentTab={tab}
                  onConfirm={confirmExceptions}
                  exceptions={getExceptions()}
                />
                <GridItem xs={12} sm={12} md={12}>
                  {renderSwitch(natureza.active, "active", "Ativo")}
                </GridItem>
              </GridItem>
            </CardBody>
            <CardFooter>
              <Button color="primary" onClick={goBack}>
                {generalMessages.cancel}
              </Button>
              <Button color="primary" onClick={onSave}>
                {generalMessages.save}
              </Button>
            </CardFooter>
          </Card>
        </GridItem>
        <Loading loading={loading} />
        <ConfirmDialog />
      </GridContainer>
    </>
  );
}
