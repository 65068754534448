import React, { useState, useEffect, useCallback, useRef } from "react";
import { useDebounce } from "use-debounce";
import { makeStyles } from "@mui/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClockRotateLeft, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import { useSnackbar } from "notistack";
import CardStyle from "assets/jss/material-dashboard-react/components/cardStyle";
import TextInput from "components/textInput/TextInput";
import AccordionFilters from "components/Accordion/AccordionFilters";
import InventoryService from "./service/InventoryService";
import MESSAGES from "../../config/messages";
import { SEARCH } from "../../config/constants";
import Loading from "../../components/loading/Loading";

import InventoryMovementHistoryModal from "./components/InventoryMovementHistoryModal";
import InventoryMovementModal from "./components/InventoryMovementModal";
import DataGridCustom from "../../components/dataGrid/DataGridCustom";

const { generalMessages, productMessages } = MESSAGES;

const useCardStyle = makeStyles(CardStyle);

export default function InventoryProductList() {
  const classesuseCardStyle = useCardStyle();
  const [list, setList] = useState([]);

  const [searchTerm, setSearchTerm] = useState("");
  const [searchTermDebounce] = useDebounce(searchTerm, SEARCH.WAIT_INTERVAL);
  const [barCodeField, setBarCodeField] = useState("");
  const [loading, setLoading] = useState(false);
  const [productSelected, setProductSelected] = useState(null);
  const [openInventoryMovement, setOpenInventoryMovement] = useState(false);
  const [openAddMovement, setOpenAddMovement] = useState(false);
  // Does not cause re-renders
  const firstLoad = useRef(true);
  const { enqueueSnackbar } = useSnackbar();
  const customGridRef = useRef();
  const [rowCount, setRowCount] = React.useState(0);

  const fetchAll = useCallback(
    async ({ term, barcode = "", paginationModelParams, sorting }) => {
      setLoading(true);

      const pagination = paginationModelParams || customGridRef.current.getDefaultPaginationModel();

      try {
        const result = await InventoryService.fetchAll({
          term,
          barcode,
          pagination,
          sorting,
        });
        const { data } = result;
        const { content, totalElements } = data;

        setList(content);
        setRowCount(totalElements);
      } catch (error) {
        enqueueSnackbar(generalMessages.error, {
          variant: "error",
        });
      } finally {
        setLoading(false);
      }
    },
    [enqueueSnackbar]
  );

  useEffect(() => {
    if (firstLoad.current) {
      fetchAll({ term: "" });
      firstLoad.current = false;
    } else if (searchTermDebounce !== null) {
      customGridRef.current.resetPaginationModel();
      fetchAll({ term: searchTermDebounce });
    }
  }, [fetchAll, searchTermDebounce, firstLoad]);

  const goToInventoryMovement = useCallback((product) => {
    setProductSelected(product);
    setOpenInventoryMovement(true);
  }, []);

  const goToAddMovement = useCallback((product) => {
    setProductSelected(product);
    setOpenAddMovement(true);
  }, []);

  const closeInventoryMovement = useCallback(() => {
    setProductSelected(null);
    setOpenInventoryMovement(false);
    fetchAll({ term: searchTerm });
  }, [fetchAll, searchTerm]);

  const closeAddMovement = useCallback(() => {
    setOpenAddMovement(false);
    setProductSelected(null);
    fetchAll({ term: searchTerm });
  }, [fetchAll, searchTerm]);

  const columns = [];

  columns.push({
    field: "industryCode",
    headerName: "Código",
    // eslint-disable-next-line arrow-body-style
    valueGetter: ({ row }) => {
      return row.industryCode;
    },
    minWidth: 120,
    flex: 2,
  });

  columns.push({
    field: "product",
    headerName: productMessages.name,
    // eslint-disable-next-line arrow-body-style
    valueGetter: ({ row }) => {
      return row.name;
    },
    minWidth: 250,
    flex: 2,
  });

  columns.push({
    field: "inventoryQuantity",
    headerName: productMessages.inventory,
    // eslint-disable-next-line arrow-body-style
    valueGetter: ({ row }) => {
      return row.inventory.quantity;
    },
    minWidth: 100,
    flex: 2,
  });

  columns.push({
    field: "actions",
    headerName: generalMessages.actions,
    sortable: false,
    width: 100,
    disableClickEventBubbling: true,
    renderCell: (params) => (
      <div
        style={{
          cursor: "pointer",
          justifyContent: "flex-end",
          padding: "10px",
          display: "flex",
          flex: 1,
        }}
      >
        <FontAwesomeIcon
          icon={faPlusCircle}
          onClick={() => goToAddMovement(params.row)}
          fontSize={20}
          style={{ padding: 6 }}
          color="#686868"
          title="Movimentar estoque"
        />
        <FontAwesomeIcon
          icon={faClockRotateLeft}
          onClick={() => goToInventoryMovement(params.row)}
          fontSize={20}
          color="#686868"
          style={{ padding: 6 }}
          title="Histórico de movimentações"
        />
      </div>
    ),
  });

  const onEnterSelectProduct = useCallback(async (value) => {
    const pagination = customGridRef.current.getDefaultPaginationModel();
    const result = await InventoryService.fetchAll({ term: "", barcode: value, pagination });
    const content = result?.data?.content ?? [];
    if (content.length === 0) {
      // eslint-disable-next-line no-alert
      alert("Item não encontrado.");
    } else {
      const productBarCode = content[0];

      setProductSelected(productBarCode);
      setOpenAddMovement(true);
    }
    setBarCodeField("");
  }, []);

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card className="gridLayoutCard">
          <div className="gridLayoutContainer">
            <CardHeader color="primary">
              <h4 className={classesuseCardStyle.cardTitleList}>Estoque</h4>
              <p className={classesuseCardStyle.cardCategoryList}>Lista de produtos</p>
            </CardHeader>

            <div className="gridLayoutFilters">
              <AccordionFilters screen="inventory_list">
                <GridContainer style={{ marginBottom: 8 }}>
                  <GridItem xs={12} sm={12} md={8}>
                    <TextInput
                      value={searchTerm}
                      onChange={(ev, value) => setSearchTerm(value)}
                      placeholder={productMessages.searchbar}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <TextInput
                      onKeyPress={(e) => {
                        if (e.which === 13) {
                          onEnterSelectProduct(e.target.value);
                        }
                      }}
                      id="barcode"
                      label={generalMessages.barCodeText}
                      value={barCodeField}
                      onChange={(ev, value) => setBarCodeField(value)}
                    />
                  </GridItem>
                </GridContainer>
              </AccordionFilters>
            </div>

            <div className="gridLayoutGrid">
              <DataGridCustom
                ref={customGridRef}
                getRowId={(row) => row.id}
                rows={list}
                columns={columns}
                usePagination
                rowCount={rowCount}
                fetchAction={(params) =>
                  fetchAll({
                    ...params,
                    term: searchTerm,
                  })
                }
              />
            </div>
          </div>
        </Card>
      </GridItem>
      {openInventoryMovement && (
        <InventoryMovementHistoryModal
          open={openInventoryMovement}
          item={{ product: productSelected }}
          onClose={closeInventoryMovement}
        />
      )}
      {openAddMovement && (
        <InventoryMovementModal
          open={openAddMovement}
          onClose={closeAddMovement}
          productId={productSelected?.id}
        />
      )}
      <Loading loading={loading} />
    </GridContainer>
  );
}
