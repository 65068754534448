import axios from "axios";
import { APIS } from "../../../config/constants";
import BaseService from "../../../core/BaseService";

const { register: registerAPI } = APIS;

class DigitalCertificateService extends BaseService {
  constructor() {
    super(registerAPI.digitalCertificates);
  }

  // upload = (file, password) =>
  //   axios.post(`${this.endpoint}/upload?password=${password}`, file, {
  //     headers: {
  //       "Content-Type": "multipart/form-data",
  //     },
  //   });

  fetchAll = () => axios.get(`${this.endpoint}?size=10`);

  upload = (file) =>
    axios.post(`${this.endpoint}/upload`, file, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
}

export default new DigitalCertificateService();
