import React, { useState, useCallback, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { Box, FormControlLabel, FormGroup, Switch } from "@mui/material";
import CardStyle from "assets/jss/material-dashboard-react/components/cardStyle";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import Button from "components/CustomButtons/Button";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import CardFooter from "components/Card/CardFooter";
import { useHistory, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import ServiceProduct from "./model/ServiceProduct";
import { toRequest, fromResponse } from "./converter/ServiceProductConverter";
import Service from "./service/ServiceProductService";
import { handlingResponseErrors } from "../../config/util";
import MESSAGES from "../../config/messages";
import Loading from "../../components/loading/Loading";
import TextInput from "../../components/textInput/TextInput";
import CurrencyTextInput from "../../components/textInput/CurrencyTextInput";
import TooltipCustom from "../../components/tooltipCustom/TooltipCustom";
import SelectEnum from "../../components/selectEnum/SelectEnum";

const { generalMessages, productMessages } = MESSAGES;

const useCardStyle = makeStyles(CardStyle);

// eslint-disable-next-line react/prop-types
export default function ProductForm({ onCancel, afterSave, name }) {
  const classesCardStyle = useCardStyle();
  const history = useHistory();
  const routerParams = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [errors, setErrors] = useState({});
  const [service, setService] = useState(new ServiceProduct({ active: true, name, commissionable: true }));
  const [loading, setLoading] = useState(false);

  const handleChange = useCallback(
    (field, value) => {
      setService({
        ...service,
        [field]: value,
      });
    },
    [service]
  );

  const goBack = useCallback(() => {
    if (onCancel) {
      onCancel();
    } else {
      history.push("/app/prodservices");
    }
  }, [history, onCancel]);

  const onSave = useCallback(async () => {
    try {
      setLoading(true);
      const { id } = service;
      if (id != null) {
        await Service.update(id, toRequest(service));
      } else {
        const response = await Service.save(toRequest(service));
        if (afterSave) {
          afterSave(response?.data?.data);
        }
      }
      enqueueSnackbar(generalMessages.saveSuccess, {
        variant: "success",
        autoHideDuration: 3000,
      });
      goBack();
    } catch (error) {
      const { message, validationErrors } = handlingResponseErrors(error);
      enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
      if (validationErrors != null) {
        setErrors(validationErrors);
      }
    } finally {
      setLoading(false);
    }
  }, [service, enqueueSnackbar, goBack, afterSave]);

  const fetchById = useCallback(async () => {
    try {
      setLoading(true);
      const { id } = routerParams || {};
      if (id != null) {
        const result = await Service.fetchById(id);
        const response = fromResponse(result?.data?.data);
        setService(response);
      }
    } finally {
      setLoading(false);
    }
  }, [routerParams]);

  useEffect(() => {
    fetchById();
  }, [fetchById]);

  const renderSwitch = (value = false, field, label, disabled = false) => (
    <FormGroup style={{ paddingTop: "8px" }}>
      <FormControlLabel
        control={
          <Switch
            color="secondary"
            checked={value}
            onChange={(event) => handleChange(field, event.target.checked)}
          />
        }
        label={label}
        disabled={disabled}
      />
    </FormGroup>
  );

  const renderService = () => (
    <>
      <GridItem xs={12} sm={12} md={4}>
        <TextInput
          autoFocus
          id="industryCode"
          label="Código"
          value={service.industryCode}
          onChange={handleChange}
          errors={errors}
        />
      </GridItem>

      <GridItem xs={12} sm={12} md={8}>
        <Box style={{ marginTop: "19px" }}>
          <TooltipCustom title="Se o código não for preenchido, será gerado automaticamente." />
        </Box>
      </GridItem>
      <GridItem xs={12} sm={12} md={9}>
        <TextInput
          id="name"
          label={productMessages.name}
          value={service.name}
          onChange={handleChange}
          required
          errors={errors}
        />
      </GridItem>

      <GridItem xs={12} sm={12} md={3}>
        <CurrencyTextInput
          id="saleValue"
          label={productMessages.saleService}
          value={service.saleValue}
          onChange={handleChange}
          required
          errors={errors}
        />
      </GridItem>
      <GridItem xs={12} sm={12} md={3}>
        <SelectEnum
          label="Elegível para Comissão"
          field="commissionable"
          id="commissionable"
          valueSelected={service.commissionable}
          errors={errors}
          handleChange={(field, value) =>
            handleChange(field, value)
          }
          Enum={{
            SIM: {
              code: true,
              name: "Sim",
            },
            NAO: {
              code: false,
              name: "Não",
            },
          }}
        />
      </GridItem>
      <GridItem xs={12} sm={12} md={9}>
        <TextInput
          id="additionalInformation"
          label={productMessages.additionalInformation}
          value={service.additionalInformation}
          onChange={handleChange}
          errors={errors}
        />
      </GridItem>
      <GridItem xs={12} sm={12} md={12}>
        {renderSwitch(service.active, "active", "Ativo")}
      </GridItem>
    </>
  );

  return (
    <>
      <GridContainer style={{ justifyContent: "center" }}>
        <GridItem xs={12} sm={12} md={10}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classesCardStyle.cardTitle}>Serviços</h4>
              <p className={classesCardStyle.cardCategory}>Cadastro de serviços</p>
            </CardHeader>
            <CardBody>
              <GridContainer>{renderService()}</GridContainer>
            </CardBody>
            <CardFooter>
              <Button color="primary" onClick={goBack}>
                {generalMessages.cancel}
              </Button>
              <Button color="primary" onClick={onSave}>
                {generalMessages.save}
              </Button>
            </CardFooter>
          </Card>
        </GridItem>
        <Loading loading={loading} />
      </GridContainer>
    </>
  );
}
