import React, { useState, useCallback, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import CardStyle from "assets/jss/material-dashboard-react/components/cardStyle";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import { useSnackbar } from "notistack";
import MESSAGES from "../../config/messages";
import Loading from "../../components/loading/Loading";

import Plans from "./components/Plans";
import MyPlanService from "./service/MyPlanService";

const { generalMessages } = MESSAGES;
const useCardStyle = makeStyles(CardStyle);

const MyPlan = (userMe = {}) => {
  // export default function MyPlan(props) {
  const classesCardStyle = useCardStyle();
  const { enqueueSnackbar } = useSnackbar();
  const [plans, setPlans] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetch = useCallback(async () => {
    setLoading(true);
    try {
      const result = await MyPlanService.fetch();
      const { data } = result;
      const plansData = data.map((item) => ({ ...item, text: JSON.parse(item.text) }));
      setPlans(plansData);
    } catch (error) {
      enqueueSnackbar(generalMessages.error, {
        variant: "error",
      });
    } finally {
      setLoading(false);
    }
  }, [enqueueSnackbar]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  return (
    <>
      <GridContainer style={{ justifyContent: "center" }}>
        <GridItem xs={12} sm={12} md={10}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classesCardStyle.cardTitle}>Meu plano</h4>
              <p className={classesCardStyle.cardCategory}>
                Escolha seu plano e continue utilizando a Kairum
              </p>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <Plans plans={plans} userMe={userMe.userMe} />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
        <Loading loading={loading} />
      </GridContainer>
    </>
  );
};
export default React.memo(MyPlan);
