/* eslint-disable react/prop-types */
import React from "react";
import { makeStyles } from "@mui/styles";
import CustomComponentsStyle from "assets/jss/material-dashboard-react/components/customComponentsStyle";
import { TextField } from "@mui/material";

const useCustomComponentsStyle = makeStyles(CustomComponentsStyle);

const TextEmail = ({ id, autoFocus, label, value, required, onChange, errors = {} }) => {
  const classesCustomComponentsStyle = useCustomComponentsStyle();

  return (
    <TextField
      size="small"
      variant="outlined"
      autoComplete="off"
      autoFocus={autoFocus}
      id={id}
      label={label}
      fullWidth
      InputLabelProps={{
        className: classesCustomComponentsStyle.labelTextField,
      }}
      className={classesCustomComponentsStyle.textField}
      value={value == null ? "" : value}
      onChange={(val) => onChange(id, val.currentTarget.value?.toLowerCase() ?? null)}
      required={required}
      error={errors[id]}
      helperText={errors[id]}
      inputProps={{ style: { textTransform: "lowercase" } }}
    />
  );
};

export default React.memo(TextEmail);
