import moment from "moment";
import { Expense, ExpenseRepeatType } from "../model/Expense";

const toRequest = (expense, user) => {
  const {
    id,
    name,
    value,
    repeatType,
    parentId,
    version,
    expenseOriginType,
    payments,
    paymentStatusType,
    valuePaid,
    financialAccount
  } = expense;
  let { startDate, endDate } = expense;

  if (repeatType === ExpenseRepeatType.N.code) {
    endDate = startDate;
  }
  startDate = startDate != null ? moment(startDate).startOf("day").format() : null;
  endDate = endDate != null ? moment(endDate).endOf("day").format() : null;

  const newPayments =
    payments?.map((payment) => {
      const { datePayment } = payment;
      const datePaymentFormatted = moment(datePayment).format();
      return {
        ...payment,
        datePayment: datePaymentFormatted,
      };
    }) || [];


  return {
    id,
    name,
    startDate,
    endDate,
    value,
    user,
    repeatType,
    parentId,
    version,
    payments: newPayments,
    paymentStatusType,
    expenseOriginType,
    valuePaid,
    financialAccount
  };
};

const fromResponse = (response) => {
  if (response === null) return new Expense();

  const {
    id,
    name,
    startDate,
    endDate,
    user: userResponse,
    financialAccount,
    value,
    repeatType,
    parentId,
    expenseOriginType,
    payments,
    version,
    paymentStatusType,
    valuePaid,
  } = response;

  return new Expense({
    id,
    name,
    startDate,
    endDate,
    user: userResponse,
    financialAccount,
    value,
    repeatType,
    parentId,
    expenseOriginType,
    payments,
    paymentStatusType,
    version,
    valuePaid,
  });
};

export { toRequest, fromResponse };
