/* eslint-disable eqeqeq */
/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useRef, useState } from "react";
import InputMask from "react-input-mask";
import { makeStyles } from "@mui/styles";
import CustomComponentsStyle from "assets/jss/material-dashboard-react/components/customComponentsStyle";
import GridItem from "components/Grid/GridItem";
import Loading from "components/loading/Loading";
import axios from "axios";
import {
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  TextField,
  Typography,
  Divider,
  FormHelperText,
} from "@mui/material";
import { dangerColor } from "assets/jss/material-dashboard-react";
import MESSAGES from "../../config/messages";
import { APIS } from "../../config/constants";
import TextInput from "../textInput/TextInput";
import AddressService from "./service/AddressService";

const { customerMessages } = MESSAGES;
const maxLengthStreet = 60;

const useCustomComponentsStyle = makeStyles(CustomComponentsStyle);

const AddressForm = ({
  address,
  states,
  handleChange,
  errors,
  fields = {
    zipCode: "zipCode",
    state: "state",
    city: "city",
    street: "street",
    neighborhood: "neighborhood",
    number: "number",
    complement: "complement",
  },
  errorFields,
  title = "Endereço",
  showTitle = true,
  disabled,
  required = true,
}) => {
  const classesCustomComponentsStyle = useCustomComponentsStyle();
  const [loading, setLoading] = useState(false);
  const viaCep = useRef(false);
  const [cities, setCities] = useState([]);

  const fetchCitiesByState = useCallback(async (stateId) => {
    const result = await AddressService.fetchCitiesByState(stateId);
    const { data } = result;
    return data;
  }, []);

  useEffect(() => {
    const asyncFetch = async () => {
      if (address.state && !viaCep.current) {
        const citiesResult = await fetchCitiesByState(address.state);
        setCities(citiesResult);
      }
    };
    asyncFetch();
  }, [address.state, fetchCitiesByState]);

  const getZipCode = useCallback(
    async (zipCode) => {
      const automaticFilling = true;
      const code = zipCode.replace(/[^a-zA-Z0-9 ]/g, "");
      try {
        setLoading(true);
        const response = await axios.get(`${APIS.register.zipCode}/${code}`);
        const addss = response?.data?.data;
        if (addss) {
          const { uf, ibge, bairro, complemento } = addss;
          let { logradouro } = addss;
          logradouro = logradouro?.substr(0, maxLengthStreet);

          if (uf) {
            const stateResult = states.find((item) => item.uf === uf);
            const stateId = stateResult.id;
            const citiesResult = await fetchCitiesByState(stateId);

            const cityResult = citiesResult.find((item) => item.ibge == ibge);
            setCities(citiesResult);
            handleChange(
              null,
              {
                [fields.state]: stateId,
                [fields.city]: cityResult?.id,
                [fields.street]: logradouro,
                [fields.neighborhood]: bairro,
                [fields.complement]: complemento,
              },
              automaticFilling
            );
          }
        }
      } catch (error) {
        if (error.response) {
          handleChange(
            null,
            {
              [fields.state]: null,
              [fields.city]: null,
              [fields.street]: null,
              [fields.neighborhood]: null,
              [fields.complement]: null,
              [fields.number]: null,
            },
            automaticFilling
          );
          // console.log("Zipcode: ", error.response.status);
        }
      } finally {
        setLoading(false);
      }
    },
    [
      fetchCitiesByState,
      fields.city,
      fields.complement,
      fields.neighborhood,
      fields.number,
      fields.state,
      fields.street,
      handleChange,
      states,
    ]
  );

  return (
    <>
      {showTitle && (
        <GridItem xs={12} sm={12} md={12} style={{ marginTop: 12 }}>
          <Divider style={{ width: "100%", marginBottom: 4 }} />
          <Typography style={{ fontSize: 18, fontWeight: 500 }}>{title}</Typography>
        </GridItem>
      )}
      <GridItem xs={12} sm={12} md={4}>
        <InputMask
          mask="99999-999"
          disabled={disabled}
          value={address[fields.zipCode] == null ? "" : address[fields.zipCode]}
          onBlur={(ev) => {
            viaCep.current = true;
            getZipCode(ev.target.value);
          }}
          onChange={(value) => handleChange(fields.zipCode, value.currentTarget.value)}
        >
          <TextField
            size="small"
            variant="outlined"
            id={fields.zipCode}
            label={customerMessages.zipCode}
            fullWidth
            InputLabelProps={{
              className: classesCustomComponentsStyle.labelTextField,
            }}
            className={classesCustomComponentsStyle.textField}
            required={required}
            error={errors[errorFields?.neighborhood || fields.zipCode]}
            helperText={errors[errorFields?.neighborhood || fields.zipCode]}
          />
        </InputMask>
      </GridItem>
      <GridItem xs={12} sm={12} md={4}>
        <FormControl
          disabled={disabled}
          fullWidth
          className={classesCustomComponentsStyle.formControlSelect}
          size="small"
          variant="outlined"
          required={required}
          error={errors[errorFields?.state || fields.state]}
        >
          <InputLabel>{customerMessages.state}</InputLabel>
          <Select
            label={customerMessages.state}
            id={fields.state}
            autoWidth
            value={address[fields.state] == null ? "" : address[fields.state]}
            onChange={(value) => {
              viaCep.current = false;
              handleChange(fields.state, value.target.value);
            }}
          >
            {states.map((item) => (
              <MenuItem value={item.id}>{item.name}</MenuItem>
            ))}
          </Select>
          <FormHelperText style={{ color: dangerColor[0] }}>
            {errors[errorFields?.state || fields.state]}
          </FormHelperText>
        </FormControl>
      </GridItem>
      <GridItem xs={12} sm={12} md={4}>
        <FormControl
          fullWidth
          className={classesCustomComponentsStyle.formControlSelect}
          size="small"
          variant="outlined"
          required={required}
          error={errors[errorFields?.city || fields.city]}
        >
          <InputLabel>{customerMessages.city}</InputLabel>
          <Select
            id={fields.city}
            autoWidth
            label={customerMessages.city}
            value={address[fields.city] == null ? "" : address[fields.city]}
            onChange={(value) => handleChange(fields.city, value.target.value)}
            disabled={disabled || cities.length === 0}
          >
            {cities.map((item) => (
              <MenuItem value={item.id}>{item.name}</MenuItem>
            ))}
          </Select>
          <FormHelperText style={{ color: dangerColor[0] }}>
            {errors[errorFields?.city || fields.city]}
          </FormHelperText>
        </FormControl>
      </GridItem>
      <GridItem xs={12} sm={12} md={6}>
        <TextInput
          disabled={disabled}
          id={fields.street}
          fieldError={errorFields?.street}
          label={customerMessages.street}
          value={address[fields.street]}
          onChange={handleChange}
          required={required}
          errors={errors}
          maxLength={maxLengthStreet}
        />
      </GridItem>
      <GridItem xs={12} sm={12} md={4}>
        <TextInput
          disabled={disabled}
          id={fields.neighborhood}
          fieldError={errorFields?.neighborhood}
          label={customerMessages.neighborhood}
          value={address[fields.neighborhood]}
          onChange={handleChange}
          required={required}
          errors={errors}
          maxLength={60}
        />
      </GridItem>
      <GridItem xs={12} sm={12} md={2}>
        <TextInput
          disabled={disabled}
          id={fields.number}
          fieldError={errorFields?.number}
          label={customerMessages.number}
          value={address[fields.number]}
          onChange={handleChange}
          required={required}
          errors={errors}
          maxLength={60}
        />
      </GridItem>
      <GridItem xs={12} sm={12} md={12}>
        <TextInput
          disabled={disabled}
          id={fields.complement}
          fieldError={errorFields?.complement}
          label={customerMessages.complement}
          value={address[fields.complement]}
          onChange={handleChange}
          maxLength={60}
          errors={errors}
        />
      </GridItem>
      <Loading loading={loading} />
    </>
  );
};

export default React.memo(AddressForm);
