/* eslint-disable react/prop-types */
import React, { useCallback, useContext } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import MESSAGES from "../../../config/messages";
import {
  percentageFormatter,
  formatDate,
  formatDateWithoutTime,
  currencyFormatter,
} from "../../../config/util";
import { OrderType } from "../../order/model/Order";
import ProductIcon from "../../../components/icons/ProductIcon";
import UserContext from "../../../core/UserContext";
import ProfileBusiness from "../../company/model/ProfileBusiness";

const { serviceOrderMessages } = MESSAGES;

const ServiceOrderReviewStep = ({ order, items, getOrderTotalWithoutProductDiscounts }) => {
  const { userLogged } = useContext(UserContext);

  const { total = 0 } = order;
  const services = items.filter((item) => item.service);
  const products = items.filter((item) => !item.service);

  const getTotalByItems = useCallback((_items) => {
    let totalItems = 0;
    _items.forEach((item) => {
      totalItems += item.finalValue;
    });
    return totalItems;
  }, []);

  const totalWithoutDiscount = getOrderTotalWithoutProductDiscounts(items);
  const totalDiscount = totalWithoutDiscount - total;

  const getMotorcycleMechanic = () => (
    <>
      <Grid container justifyContent="space-between">
        <Typography inline align="left" style={{ fontSize: 18 }}>
          Veiculo
        </Typography>
        <Typography inline align="right" style={{ fontWeight: 400, fontSize: 18 }}>
          {order?.extraFields?.veiculo}
        </Typography>
      </Grid>
      <Grid container justifyContent="space-between">
        <Typography inline align="left" style={{ fontSize: 18 }}>
          Placa
        </Typography>
        <Typography inline align="right" style={{ fontWeight: 400, fontSize: 18 }}>
          {order?.extraFields?.placa}
        </Typography>
      </Grid>
      <Grid container justifyContent="space-between">
        <Typography inline align="left" style={{ fontSize: 18 }}>
          Ano
        </Typography>
        <Typography inline align="right" style={{ fontWeight: 400, fontSize: 18 }}>
          {order?.extraFields?.ano}
        </Typography>
      </Grid>
      <Grid container justifyContent="space-between">
        <Typography inline align="left" style={{ fontSize: 18 }}>
          KM
        </Typography>
        <Typography inline align="right" style={{ fontWeight: 400, fontSize: 18 }}>
          {order?.extraFields?.km}
        </Typography>
      </Grid>
    </>
  );

  const renderByProfileBusiness = () => {
    const profile = userLogged?.profileBusiness;
    switch (profile) {
      case ProfileBusiness.MOTORCYCLE_MECHANIC.code:
        return getMotorcycleMechanic();
      case ProfileBusiness.CAR_MECHANIC.code:
        return getMotorcycleMechanic();
      default:
        return <></>;
    }
  };

  const renderItems = () => (
    <div>
      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="caption table">
          <TableHead>
            <TableRow>
              <TableCell width={40} />
              <TableCell>{serviceOrderMessages.productStep.item}</TableCell>
              <TableCell align="right">{serviceOrderMessages.productStep.brand}</TableCell>
              <TableCell align="right">{serviceOrderMessages.productStep.saleValue}</TableCell>
              <TableCell align="right">{serviceOrderMessages.productStep.quantity}</TableCell>
              <TableCell align="right">{serviceOrderMessages.productStep.discount}</TableCell>
              <TableCell align="right">{serviceOrderMessages.productStep.finalValue}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <Typography
              inline
              align="left"
              style={{ fontSize: 18, fontWeight: 500, marginTop: 20 }}
            >
              Produtos
            </Typography>

            {products.map((item) => (
              <TableRow key={item.productId}>
                <TableCell>
                  <ProductIcon isService={item.service} />
                </TableCell>
                <TableCell component="th" scope="row">
                  {item.productName}
                </TableCell>
                <TableCell align="right">{item.brand}</TableCell>
                <TableCell align="right">{currencyFormatter(item.saleValue)}</TableCell>
                <TableCell align="right">{item.quantity}</TableCell>
                <TableCell align="right">{percentageFormatter(item.discount)}</TableCell>
                <TableCell align="right">{currencyFormatter(item.finalValue)}</TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell width={40} />
              <TableCell component="th" scope="row" />
              <TableCell align="right" />
              <TableCell align="right" />
              <TableCell align="right" />
              <TableCell align="right" />
              <TableCell align="right">
                <Typography inline style={{ fontWeight: 500, fontSize: 16 }}>
                  {currencyFormatter(getTotalByItems(products))}
                </Typography>
              </TableCell>
            </TableRow>

            <Typography
              inline
              align="left"
              style={{ fontSize: 18, fontWeight: 500, marginTop: 30 }}
            >
              Serviços
            </Typography>
            {services.map((item) => (
              <TableRow key={item.productId}>
                <TableCell>
                  <ProductIcon isService={item.service} />
                </TableCell>
                <TableCell component="th" scope="row">
                  {item.productName}
                </TableCell>
                <TableCell align="right">{item.brand}</TableCell>
                <TableCell align="right">{currencyFormatter(item.saleValue)}</TableCell>
                <TableCell align="right">{item.quantity}</TableCell>
                <TableCell align="right">{percentageFormatter(item.discount)}</TableCell>
                <TableCell align="right">{currencyFormatter(item.finalValue)}</TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell width={40} />
              <TableCell component="th" scope="row" />
              <TableCell align="right" />
              <TableCell align="right" />
              <TableCell align="right" />
              <TableCell align="right" />
              <TableCell align="right">
                <Typography inline style={{ fontWeight: 500, fontSize: 16 }}>
                  {currencyFormatter(getTotalByItems(services))}
                </Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );

  const getPaymentMethod = useCallback(() => {
    const { numberInstallments, paymentMethod } = order;
    if (numberInstallments && numberInstallments != null && numberInstallments !== 0) {
      return `${paymentMethod?.name} ${numberInstallments}x`;
    }
    return paymentMethod?.name;
  }, [order]);

  const renderOverview = () => (
    <GridContainer>
      <Grid
        container
        justifyContent="space-between"
        style={{ paddingLeft: 27, paddingRight: 27, marginBottom: 10, marginTop: 30 }}
      >
        <Typography inline align="left" style={{ fontSize: 20 }}>
          {OrderType[order.orderType || OrderType.BUDGET.code]?.name}
        </Typography>
        <Typography inline align="right" style={{ fontWeight: 400, fontSize: 25 }}>
          {order.orderNumber}
          {/* {pad(order.orderNumber, 18)} */}
        </Typography>
      </Grid>

      <Divider style={{ width: "100%" }} />

      <Grid item xs={12} sm={12} md={12} style={{ padding: 30 }}>
        <Grid container justifyContent="space-between">
          <Typography inline align="left" style={{ fontSize: 18 }}>
            {serviceOrderMessages.generalStep.customer}
          </Typography>
          <Typography inline align="right" style={{ fontWeight: 400, fontSize: 18 }}>
            {order.customer?.name}
          </Typography>
        </Grid>
        {/* <Divider style={{ width: "100%" }} /> */}
        <Grid container justifyContent="space-between">
          <Typography inline align="left" style={{ fontSize: 18 }}>
            {serviceOrderMessages.generalStep.paymentMethod}
          </Typography>
          <Typography inline align="right" style={{ fontWeight: 400, fontSize: 18 }}>
            {getPaymentMethod()}
          </Typography>
        </Grid>
        {/* <Divider style={{ width: "100%" }} /> */}
        <Grid container justifyContent="space-between">
          <Typography inline align="left" style={{ fontSize: 18 }}>
            {serviceOrderMessages.date}
          </Typography>
          <Typography inline align="right" style={{ fontWeight: 400, fontSize: 18 }}>
            {formatDate(
              order.orderType === "SERVICE_ORDER"
                ? order.finalizationDate
                : order.createdAt || new Date()
            )}
          </Typography>
        </Grid>
        {/* <Divider style={{ width: "100%" }} /> */}
        <Grid container justifyContent="space-between">
          <Typography inline align="left" style={{ fontSize: 18 }}>
            {serviceOrderMessages.generalStep.saller}
          </Typography>
          <Typography inline align="right" style={{ fontWeight: 400, fontSize: 18 }}>
            {order.user?.name}
          </Typography>
        </Grid>
        <Grid container justifyContent="space-between">
          <Typography inline align="left" style={{ fontSize: 18 }}>
            Previsão de entrega
          </Typography>
          <Typography inline align="right" style={{ fontWeight: 400, fontSize: 18 }}>
            {formatDateWithoutTime(order?.expectedDelivery)}
          </Typography>
        </Grid>
        {renderByProfileBusiness()}
      </Grid>
    </GridContainer>
  );

  return (
    <Grid container spacing={2}>
      <GridItem xs={12} sm={12} md={12}>
        {renderOverview()}
        <Divider style={{ width: "100%" }} />
        {renderItems()}
      </GridItem>

      <GridItem xs={12} sm={12} md={6} />
      <GridItem xs={12} sm={12} md={6}>
        <Box style={{ padding: 20 }}>
          <Grid container justifyContent="space-between">
            <Typography inline align="left" style={{ fontWeight: 600, fontSize: 18 }}>
              {serviceOrderMessages.overviewStep.total}
            </Typography>
            <Typography inline align="right" style={{ fontWeight: 600, fontSize: 18 }}>
              {currencyFormatter(totalWithoutDiscount)}
            </Typography>
          </Grid>
          <Grid container justifyContent="space-between">
            <Typography inline align="left" style={{ fontWeight: 400, fontSize: 18 }}>
              {serviceOrderMessages.overviewStep.generalDiscount}
            </Typography>
            <Typography inline align="right" style={{ fontWeight: 500, fontSize: 18 }}>
              {currencyFormatter(totalDiscount)}
            </Typography>
          </Grid>
          <Grid container justifyContent="space-between">
            <Typography inline align="left" style={{ fontWeight: 400, fontSize: 18 }}>
              {serviceOrderMessages.overviewStep.totalWithGeneralDiscount}
            </Typography>
            <Typography inline align="right" style={{ fontWeight: 500, fontSize: 18 }}>
              {currencyFormatter(order.total)}
            </Typography>
          </Grid>
          {order?.paymentMethod?.allowsDifference && (
            <>
              <Grid container justifyContent="space-between">
                <Typography inline align="left" style={{ fontWeight: 400, fontSize: 18 }}>
                  Valor recebido
                </Typography>
                <Typography inline align="right" style={{ fontWeight: 500, fontSize: 18 }}>
                  {currencyFormatter(order.received)}
                </Typography>
              </Grid>
              <Grid container justifyContent="space-between">
                <Typography inline align="left" style={{ fontWeight: 400, fontSize: 18 }}>
                  Troco
                </Typography>
                <Typography inline align="right" style={{ fontWeight: 500, fontSize: 18 }}>
                  {currencyFormatter(order.difference)}
                </Typography>
              </Grid>
            </>
          )}
        </Box>
      </GridItem>
    </Grid>
  );
};

export default React.memo(ServiceOrderReviewStep);
