/* eslint-disable react/prop-types */
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import GridContainer from "components/Grid/GridContainer";
import BigNumber from "bignumber.js";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  ListItem,
  Divider,
  Grid,
  Typography,
  Box,
  FormHelperText,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import CustomComponentsStyle from "assets/jss/material-dashboard-react/components/customComponentsStyle";
import { primaryColor, dangerColor } from "assets/jss/material-dashboard-react";
import GridItem from "components/Grid/GridItem";
import { useSnackbar } from "notistack";
import Loading from "components/loading/Loading";
import FinancialAccountsService from "views/financialAccounts/service/FinancialAccountsService";
import PaymentMethodService from "views/paymentMethod/service/PaymentMethodService";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDollarSign, faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import { DatePicker } from "@mui/x-date-pickers";
import CurrencyTextInput from "components/textInput/CurrencyTextInput";
import DeleteIcon from "components/icons/DeleteIcon";
import NFeImportacaoService from "views/nfeEntrada/service/NFeImportacaoService";
import UserContext from "core/UserContext";
import { currencyFormatter, handlingResponseErrors } from "../../../../config/util";
import MESSAGES from "../../../../config/messages";

const { generalMessages, installmentsMessages } = MESSAGES;
const useCustomComponentsStyle = makeStyles(CustomComponentsStyle);
const NFeEntradaLancarDespesas = ({
  open,
  onClose,
  idNfe,
  dadosFormaPagamentoList = [],
  setShowButtonsImport,
}) => {
  const classesCustomComponentsStyle = useCustomComponentsStyle();
  const { userLogged } = useContext(UserContext);
  const { enqueueSnackbar } = useSnackbar();
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [financialAccounts, setFinancialAccounts] = useState([]);
  const [financialAccount, setFinancialAccount] = useState({});

  const [paymentMethods, setPaymentMethods] = useState([]);

  const [items, setItems] = useState([]);

  useEffect(() => {
    if (open) setItems(dadosFormaPagamentoList);
  }, [dadosFormaPagamentoList, open]);

  const fetchFinancialAccounts = useCallback(async () => {
    const response = await FinancialAccountsService.fetchActives();
    return response?.data;
  }, []);

  const fetchPaymentMethods = useCallback(async () => {
    const payments = await PaymentMethodService.fetchActives();
    return payments?.data;
  }, []);

  const total = useMemo(
    () =>
      dadosFormaPagamentoList.reduce(
        (acumulador, fp) => parseFloat(acumulador) + parseFloat(fp.valor),
        0
      ),
    [dadosFormaPagamentoList]
  );

  const totalExpenses = useMemo(
    () => items.reduce((acumulador, fp) => parseFloat(acumulador) + parseFloat(fp.valor || 0), 0),
    [items]
  );

  const showWarning = useMemo(() => new BigNumber(total).comparedTo(totalExpenses) !== 0, [
    total,
    totalExpenses,
  ]);

  const init = useCallback(async () => {
    const resultFinancialAccounts = await fetchFinancialAccounts();
    setFinancialAccounts(resultFinancialAccounts);
    const defaultFinancialAccount = resultFinancialAccounts.find((item) => item.standard);
    setFinancialAccount(defaultFinancialAccount);

    const resultPaymentMethods = await fetchPaymentMethods();
    setPaymentMethods(resultPaymentMethods);
  }, [fetchFinancialAccounts, fetchPaymentMethods]);

  useEffect(() => {
    init();
  }, [init]);

  const onImport = useCallback(async () => {
    try {
      setLoading(true);

      const dtoItems = [];
      items.forEach((it) => {
        const { formaPagamento, vencimento, valor } = it;
        const item = {
          formaPagamento: formaPagamento.name,
          valor,
          vencimento,
          user: {
            id: userLogged.userId,
          },
          financialAccount,
        };
        dtoItems.push(item);
      });

      const dataToSend = {
        items: dtoItems,
      };

      await NFeImportacaoService.importarDespesas(idNfe, dataToSend);

      setErrors({});
      onClose();
      setShowButtonsImport((prevState) => ({
        ...prevState,
        despesas: false,
      }));
      enqueueSnackbar(generalMessages.saveSuccess, {
        variant: "success",
        autoHideDuration: 3000,
      });
    } catch (error) {
      const { message, validationErrors } = handlingResponseErrors(error);
      enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
      if (validationErrors != null) {
        setErrors(validationErrors);
      }
    } finally {
      setLoading(false);
    }
  }, [
    items,
    idNfe,
    onClose,
    setShowButtonsImport,
    enqueueSnackbar,
    userLogged?.userId,
    financialAccount,
  ]);

  const handleChangeItem = useCallback(
    (field, value, index) => {
      const newItems = [...items];

      const item = newItems[index];

      newItems[index] = {
        ...item,
        [field]: value,
      };

      setItems(newItems);
    },
    [items]
  );

  const add = useCallback(() => {
    const newItem = {
      index: items.length,
      valor: 0,
      formaPagamento: paymentMethods?.[0],
      vencimento: moment(),
    };
    const newItems = [...items, newItem];
    setItems(newItems);
  }, [items, paymentMethods]);

  const remove = useCallback(
    (i) => {
      let newItems = [...items];
      const index = newItems.findIndex((item) => item.index === i);
      newItems.splice(index, 1);
      // Changing the indexes
      newItems = newItems.map((item, idx) => ({ ...item, index: idx }));
      setItems(newItems);
    },
    [setItems, items]
  );

  const renderAddButton = () => (
    <Box display="flex" style={{ marginTop: 12, paddingLeft: 10 }}>
      <Button style={{ color: primaryColor[0] }} onClick={add}>
        Adicionar
      </Button>
    </Box>
  );

  const renderItems = useCallback(
    () =>
      items.map((item, index) => (
        <>
          <ListItem style={{ display: "flex", alignItems: "stretch" }}>
            <GridItem xs={12} sm={12} md={1}>
              <FontAwesomeIcon icon={faDollarSign} fontSize={25} color={primaryColor[0]} />
            </GridItem>

            <>
              <GridItem xs={12} sm={12} md={5}>
                <FormControl
                  fullWidth
                  className={classesCustomComponentsStyle.formControlSelect}
                  size="small"
                  error={errors[`items[${index}].formaPagamento`]}
                >
                  <InputLabel>{generalMessages.paymentMethod}</InputLabel>
                  <Select
                    label={generalMessages.paymentMethod}
                    id="formaPagamento"
                    autoWidth
                    value={item.formaPagamento == null ? "" : item.formaPagamento.id}
                    // eslint-disable-next-line arrow-body-style
                    onChange={(event, child) => {
                      const { objectValue } = child.props;
                      return handleChangeItem("formaPagamento", objectValue, index);
                    }}
                    required
                  >
                    {paymentMethods.map((pm) => (
                      <MenuItem key={pm.id} value={pm.id} objectValue={pm}>
                        {pm.name}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText style={{ color: dangerColor[0] }}>
                    {errors[`items[${index}].formaPagamento`]}
                  </FormHelperText>
                </FormControl>
              </GridItem>
              <GridItem xs={12} sm={12} md={3} style={{ marginTop: "12px" }}>
                <DatePicker
                  id="vencimento"
                  label={installmentsMessages.date}
                  format="dd/MM/yyyy"
                  slotProps={{
                    textField: {
                      helperText: errors[`items[${index}].vencimento`],
                      size: "small",
                      error: errors[`items[${index}].vencimento`],
                    },
                  }}
                  value={item.vencimento ? moment(item.vencimento).valueOf() : null}
                  onChange={(date) => handleChangeItem("vencimento", date, index)}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={3}>
                <CurrencyTextInput
                  id={`items[${index}].valor`}
                  currencySymbol="R$"
                  label={installmentsMessages.value}
                  value={item.valor}
                  onChange={(field, value) => handleChangeItem("valor", value, index)}
                  errors={errors}
                  required
                />
              </GridItem>

              <GridItem xs={12} sm={12} md={1}>
                <Box style={{ marginTop: 16 }}>
                  <DeleteIcon onClick={() => remove(item.index)} />
                </Box>
              </GridItem>
            </>
          </ListItem>
        </>
      )),
    [
      classesCustomComponentsStyle.formControlSelect,
      errors,
      handleChangeItem,
      items,
      paymentMethods,
      remove,
    ]
  );

  const renderFormaPagamentoDespesas = useCallback(
    () => (
      <GridContainer>
        <GridItem xs={12} sm={12} md={6}>
          <FormControl
            fullWidth
            className={classesCustomComponentsStyle.formControlSelect}
            size="small"
            required
          >
            <InputLabel>Conta financeira destino</InputLabel>
            <Select
              label="Conta financeira destino"
              id="financialAccount"
              autoWidth
              value={financialAccount == null ? "" : financialAccount.id}
              // eslint-disable-next-line arrow-body-style
              onChange={(event, child) => {
                const { objectValue } = child.props;
                return setFinancialAccount(objectValue);
              }}
              required
            >
              {financialAccounts.map((item) => (
                <MenuItem key={item.id} value={item.id} objectValue={item}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <Divider style={{ paddingTop: 20, marginBottom: 8 }} />
          {renderItems()}
        </GridItem>
      </GridContainer>
    ),
    [
      classesCustomComponentsStyle.formControlSelect,
      financialAccount,
      financialAccounts,
      renderItems,
    ]
  );

  const renderTotals = useCallback(
    () => (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Divider style={{ paddingTop: 20, marginBottom: 8 }} />
        </GridItem>
        <GridItem xs={12} sm={12} md={6} />
        <GridItem xs={12} sm={12} md={6}>
          <Grid container justifyContent="space-between">
            <Typography inline align="left" style={{ fontWeight: 400, fontSize: 18 }}>
              {installmentsMessages.total("NFe importada")}
            </Typography>
            <Typography inline align="right" style={{ fontWeight: 500, fontSize: 18 }}>
              {currencyFormatter(total)}
            </Typography>
          </Grid>
        </GridItem>

        <GridItem xs={12} sm={12} md={6} />
        <GridItem xs={12} sm={12} md={6}>
          <Grid container justifyContent="space-between">
            <Typography inline align="left" style={{ fontWeight: 400, fontSize: 18 }}>
              {installmentsMessages.total("para lançamento em despesas")}
            </Typography>
            <Typography
              inline
              align="right"
              style={{ fontWeight: 600, fontSize: 18, color: "#f44336" }}
            >
              {currencyFormatter(totalExpenses)}
            </Typography>
          </Grid>
        </GridItem>
      </GridContainer>
    ),
    [total, totalExpenses]
  );

  const resetButton = useCallback(
    () => (
      <Button
        style={{ color: primaryColor[0], borderColor: primaryColor[0] }}
        variant="outlined"
        onClick={() => setItems(dadosFormaPagamentoList)}
      >
        Redefinir
      </Button>
    ),
    [dadosFormaPagamentoList]
  );

  const renderWarning = useCallback(
    () => (
      <GridContainer>
        <GridItem xs={12} sm={12} md={1} display="flex" alignItems="center">
          <FontAwesomeIcon
            icon={faTriangleExclamation}
            fontSize={25}
            color="#eb9114"
            style={{ marginLeft: 4 }}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={9}>
          <Typography
            inline
            align="left"
            style={{ fontWeight: 400, fontSize: 16, color: "#eb9114" }}
          >
            O total que será lançado em despesas está diferente do total da NF-e importada. Este é
            apenas um aviso. É possível continuar com a operação, mas certifique-se de que tudo está
            correto ou pressione o botão REDEFINIR para voltar ao original.
          </Typography>
        </GridItem>
        <GridItem xs={12} sm={12} md={2} display="flex" alignItems="center">
          {resetButton()}
        </GridItem>
      </GridContainer>
    ),
    [resetButton]
  );

  return (
    <>
      <Dialog disableEscapeKeyDown open={open} fullWidth maxWidth="md">
        <DialogTitle>Lançamento de despesas</DialogTitle>
        <DialogContent>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              {renderFormaPagamentoDespesas()}
            </GridItem>
            <GridContainer flexGrow={1}>
              <GridItem xs={12} sm={12} md={1} />
              <GridItem xs={12} sm={12} md={11}>
                {renderAddButton()}
              </GridItem>
            </GridContainer>
            {showWarning && (
              <GridItem xs={12} sm={12} md={12}>
                <Divider style={{ paddingTop: 20, marginBottom: 8 }} />
                {renderWarning()}
              </GridItem>
            )}

            <GridItem xs={12} sm={12} md={12}>
              {renderTotals()}
              <Divider style={{ paddingTop: 8 }} />
            </GridItem>
          </GridContainer>
        </DialogContent>
        <DialogActions>
          <div style={{ flexGrow: 1, display: "flex", justifyContent: "space-between" }}>
            <Button style={{ color: primaryColor[0] }} onClick={onClose}>
              Voltar
            </Button>
            <Button style={{ color: primaryColor[0] }} onClick={onImport}>
              Lançar despesas
            </Button>
          </div>
        </DialogActions>
      </Dialog>
      <Loading loading={loading} />
    </>
  );
};

export default React.memo(NFeEntradaLancarDespesas);
