/* eslint-disable react/prop-types */
import React from "react";
import { Divider, Typography } from "@mui/material";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import TextInput from "components/textInput/TextInput";

const CHARACTER_LIMIT = 4000;

const NotaFiscalInfoAdicionais = ({ informacoesComplementares, handleChange, disabled }) => {
  const renderAdditionalInformation = () => (
    <GridItem xs={12} sm={12} md={12}>
      <TextInput
        disabled={disabled}
        id="informacoesComplementares"
        label="Informações adicionais"
        value={informacoesComplementares}
        maxLength={CHARACTER_LIMIT}
        onChange={handleChange}
        isCounter
        multiline
        rows={4}
      />
    </GridItem>
  );

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12} style={{ marginTop: 30 }}>
        <Divider style={{ width: "100%", marginBottom: 4 }} />
        <Typography style={{ fontSize: 18, fontWeight: 500 }}>
          Informações complementares
        </Typography>
      </GridItem>
      {renderAdditionalInformation()}
    </GridContainer>
  );
};

export default React.memo(NotaFiscalInfoAdicionais);
