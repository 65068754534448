import axios from "axios";
import BaseService from "../../../core/BaseService";
import {APIS} from "../../../config/constants";

const { register: registerAPI } = APIS;

class AdminCompaniesService extends BaseService {

  constructor() {
    super(registerAPI.adminCompanies);
  }

  fetchAll = ({ term, pagination, sorting }) => {
    const { page, pageSize } = pagination;
    const { field = "createdAt", sort = "desc" } = sorting || {};
    return axios.get(
      `${this.endpoint}/search?size=${pageSize}&page=${page}&sort=${field},${sort}&term=${term}`
    );
  };
}

export default new AdminCompaniesService();
