/* eslint-disable react/prop-types */
import React, { useState, useCallback, useEffect } from "react";
import BigNumber from "bignumber.js";
import { useSnackbar } from "notistack";
import moment from "moment";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  Grid,
  List,
  ListItem,
  Divider,
} from "@mui/material";
import Service from "../service/ReceiveService";
import { primaryColor } from "../../../assets/jss/material-dashboard-react";
import Loading from "../../../components/loading/Loading";
import MESSAGES from "../../../config/messages";

import { currencyFormatter } from "../../../config/util";

const { receiveMessages, generalMessages } = MESSAGES;

const InstallmentsModal = ({ open, onClose, receiveId }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetch = useCallback(async () => {
    setLoading(true);
    try {
      const result = await Service.featchInstallments(receiveId);
      const { data } = result;
      setList(data);
    } catch (error) {
      enqueueSnackbar(generalMessages.error, {
        variant: "error",
      });
    } finally {
      setLoading(false);
    }
  }, [enqueueSnackbar, receiveId]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  const getTotals = useCallback(() => {
    let totalValue = new BigNumber(0);
    let totalValuePaid = new BigNumber(0);
    list.forEach((item) => {
      totalValue = totalValue.plus(new BigNumber(item.value || "0"));
      totalValuePaid = totalValuePaid.plus(new BigNumber(item.valuePaid || "0"));
    });
    return {
      totalValue: totalValue.toNumber(),
      totalValuePaid: totalValuePaid.toNumber(),
    };
  }, [list]);

  const { totalValue, totalValuePaid } = getTotals();
  return (
    <>
      <Dialog disableEscapeKeyDown open={open} fullWidth maxWidth="md">
        <DialogTitle>Parcelas</DialogTitle>
        <DialogContent>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <List>
                <ListItem disablePadding>
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    width="100%"
                  >
                    <Box width="14%">Data</Box>
                    <Box width="36%">Descrição</Box>
                    <Box width="15%">Valor</Box>
                    <Box width="15%">Valor pago</Box>
                    <Box width="20%">Situação</Box>
                  </Box>
                </ListItem>
                {list.map(
                  ({ expirationDate, value, valuePaid, description, paymentStatusType }) => (
                    <>
                      <ListItem disablePadding>
                        <Box
                          display="flex"
                          flexDirection="row"
                          justifyContent="space-between"
                          width="100%"
                        >
                          <Box width="14%">{moment(expirationDate).format("DD/MM/YYYY")}</Box>
                          <Box width="36%">{description}</Box>
                          <Box width="15%">{currencyFormatter(value || 0)}</Box>
                          <Box width="15%">{currencyFormatter(valuePaid || 0)}</Box>
                          <Box width="20%">{paymentStatusType}</Box>
                        </Box>
                      </ListItem>
                      <Divider />
                    </>
                  )
                )}
              </List>
            </GridItem>
            <Grid container justifyContent="space-between">
              <Typography inline align="left" style={{ fontWeight: 400, fontSize: 18 }}>
                Total parcelas:
              </Typography>
              <Typography inline align="right" style={{ fontWeight: 500, fontSize: 18 }}>
                {currencyFormatter(totalValue || 0)}
              </Typography>
            </Grid>
            <Grid container justifyContent="space-between">
              <Typography inline align="left" style={{ fontWeight: 400, fontSize: 18 }}>
                Total pago:
              </Typography>
              <Typography inline align="right" style={{ fontWeight: 500, fontSize: 18 }}>
                {currencyFormatter(totalValuePaid || 0)}
              </Typography>
            </Grid>
          </GridContainer>
        </DialogContent>
        <DialogActions>
          <div style={{ flexGrow: 1, display: "flex", justifyContent: "space-between" }}>
            <Button style={{ color: primaryColor[0] }} onClick={onClose}>
              {receiveMessages.receivementsBtnBack}
            </Button>
          </div>
        </DialogActions>
      </Dialog>
      <Loading loading={loading} />
    </>
  );
};

export default React.memo(InstallmentsModal);
