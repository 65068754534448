import axios from "axios";
import { APIS } from "../../../config/constants";
import BaseService from "../../../core/BaseService";

const { register: registerAPI } = APIS;

class DashboardService extends BaseService {
  constructor() {
    super(registerAPI.dashboard);
  }

  counterDay = () => axios.get(`${this.endpoint}/of-the-day`);

  orderOSFromPeriod = (type) =>
    axios.get(`${this.endpoint}/oos-from-period?dashboardPeriodType=${type}`);
}

export default new DashboardService();
