import {
  SituacaoTributariaCofins,
  SituacaoTributariaPis,
  SituacaoTributariaIpi,
  SituacaoTributariaIcms,
  SituacaoOperacionalSimplesNacional,
  TipoCalculoNFeType,
} from "../model/NaturezaOperacao";

class NaturezaOperacaoConverterUtil {
  toRequestIcms = (icms) => {
    const {
      situacaoTributariaIcms,
      cfopIcms,
      aliquotaIcms,
      partilhaIcmsInterestadualIcms,
      aliquotaFcpIcms,
      observacaoIcms,
      aliquotaIcmsStIcms,
      baseCalculoIcmsStIcms,
      mvaIvaIcms,
      aliquotaFcpIcmsStIcms,
      aliquotaPisIcms,
      aliquotaCofinsIcms,
      baseIcms,
      baseDiferimentoIcms,
      presumidoIcms,
      aliquotaIcmsEfetivoIcms,
      baseIcmsEfetivoIcms,
      obterIcmsStRetidoAnteriormenteNfCompraIcms,
      tipoCalculoIcms,
      modalidadeBcIcmsStIcms,
      percentualReducaoBcIcmsStIcms,
      modalidadeBcIcmsIcms,
      percentualReducaoBcIcms,
      percentualReducaoBcEfetivaIcms,
    } = icms;

    const code = parseInt(SituacaoTributariaIcms[situacaoTributariaIcms].code, 10);

    if (code === 0) {
      return {
        situacaoTributariaIcms,
        cfopIcms,
        modalidadeBcIcmsIcms,
        aliquotaIcms,
        partilhaIcmsInterestadualIcms,
        aliquotaFcpIcms,
        observacaoIcms,
      };
    }

    if (code === 10) {
      return {
        situacaoTributariaIcms,
        cfopIcms,
        modalidadeBcIcmsIcms,
        aliquotaIcms,
        partilhaIcmsInterestadualIcms,
        aliquotaFcpIcms,
        observacaoIcms,
        // ST
        modalidadeBcIcmsStIcms,
        percentualReducaoBcIcmsStIcms,
        aliquotaIcmsStIcms,
        baseCalculoIcmsStIcms,
        mvaIvaIcms,
        aliquotaFcpIcmsStIcms,
        aliquotaPisIcms,
        aliquotaCofinsIcms,
      };
    }

    if (code === 20) {
      return {
        situacaoTributariaIcms,
        cfopIcms,
        modalidadeBcIcmsIcms,
        percentualReducaoBcIcms,

        aliquotaIcms,
        baseIcms,

        partilhaIcmsInterestadualIcms,

        aliquotaFcpIcms,
        observacaoIcms,
      };
    }

    if (code === 30) {
      return {
        situacaoTributariaIcms,
        cfopIcms,

        aliquotaIcms,

        partilhaIcmsInterestadualIcms,
        // ST
        modalidadeBcIcmsStIcms,
        percentualReducaoBcIcmsStIcms,
        aliquotaIcmsStIcms,
        baseCalculoIcmsStIcms,
        mvaIvaIcms,
        aliquotaFcpIcmsStIcms,
        aliquotaPisIcms,
        aliquotaCofinsIcms,
      };
    }

    if (code === 40 || code === 41 || code === 50) {
      return {
        situacaoTributariaIcms,
        cfopIcms,
        aliquotaIcms,
        partilhaIcmsInterestadualIcms,
        observacaoIcms,
      };
    }

    if (code === 51) {
      return {
        situacaoTributariaIcms,
        cfopIcms,
        modalidadeBcIcmsIcms,
        percentualReducaoBcIcms,
        aliquotaIcms,
        baseIcms,
        partilhaIcmsInterestadualIcms,
        baseDiferimentoIcms,
        aliquotaFcpIcms,
        observacaoIcms,
      };
    }

    if (code === 60) {
      const newObj = {
        situacaoTributariaIcms,
        cfopIcms,
        percentualReducaoBcEfetivaIcms,
        aliquotaIcmsEfetivoIcms,
        baseIcmsEfetivoIcms,
        partilhaIcmsInterestadualIcms,
        observacaoIcms,
        aliquotaIcmsStIcms,

        obterIcmsStRetidoAnteriormenteNfCompraIcms,
      };
      if (!obterIcmsStRetidoAnteriormenteNfCompraIcms) {
        newObj.aliquotaIcms = aliquotaIcms;
        newObj.baseIcms = baseIcms;
        newObj.baseCalculoIcmsStIcms = baseCalculoIcmsStIcms;
        newObj.mvaIvaIcms = mvaIvaIcms;
        newObj.aliquotaFcpIcmsStIcms = aliquotaFcpIcmsStIcms;
        newObj.aliquotaPisIcms = aliquotaPisIcms;
        newObj.aliquotaCofinsIcms = aliquotaCofinsIcms;
      }
      return newObj;
    }

    if (code === 70) {
      return {
        situacaoTributariaIcms,
        cfopIcms,
        modalidadeBcIcmsIcms,
        percentualReducaoBcIcms,
        aliquotaIcms,
        baseIcms,
        partilhaIcmsInterestadualIcms,
        aliquotaFcpIcms,
        observacaoIcms,
        // ST
        modalidadeBcIcmsStIcms,
        percentualReducaoBcIcmsStIcms,
        aliquotaIcmsStIcms,
        baseCalculoIcmsStIcms,
        mvaIvaIcms,
        aliquotaFcpIcmsStIcms,
        aliquotaPisIcms,
        aliquotaCofinsIcms,
      };
    }

    if (code === 90) {
      return {
        situacaoTributariaIcms,
        cfopIcms,
        modalidadeBcIcmsIcms,
        percentualReducaoBcIcms,
        aliquotaIcms,
        baseIcms,
        partilhaIcmsInterestadualIcms,
        baseDiferimentoIcms,
        presumidoIcms,
        aliquotaFcpIcms,
        observacaoIcms,
        // ST
        modalidadeBcIcmsStIcms,
        percentualReducaoBcIcmsStIcms,
        aliquotaIcmsStIcms,
        baseCalculoIcmsStIcms,
        mvaIvaIcms,
        aliquotaFcpIcmsStIcms,
        aliquotaPisIcms,
        aliquotaCofinsIcms,
      };
    }

    return {
      situacaoTributariaIcms,
    };
  };

  toRequestSimples = (simples) => {
    const {
      situacaoOperacaoSnSimples,
      cfopSimples,
      aliquotaCalculoCreditoSimples,
      observacaoSimples,
      aliquotaIcmsSimples,
      baseIcmsSimples,
      aliquotaIcmsStSimples,
      baseCalculoIcmsStSimples,
      margemAdicionalIcmsStSimples,
      aliquotaFcpIcmsStSimples,
      obterIcmsStRetidoAnteriormenteNfCompraSimples = false,
      modalidadeBcIcmsStSimples,
      modalidadeBcIcmsSimples,
      percentualReducaoBcSimples,
      percentualReducaoBcIcmsStSimples,
      percentualReducaoBcEfetivaSimples,
      aliquotaIcmsEfetivaSimples,
      baseIcmsEfetivaSimples,
    } = simples;

    const { partilhaIcmsInterestadualSimples } = simples;
    // partilhaIcmsInterestadualSimples = partilhaIcmsInterestadualSimples === "S";

    const code = parseInt(SituacaoOperacionalSimplesNacional[situacaoOperacaoSnSimples].code, 10);

    if (code === 101) {
      return {
        situacaoOperacaoSnSimples,
        cfopSimples,
        partilhaIcmsInterestadualSimples,
        aliquotaCalculoCreditoSimples,
        observacaoSimples,
      };
    }

    if (code === 102 || code === 103) {
      return {
        situacaoOperacaoSnSimples,
        cfopSimples,
        partilhaIcmsInterestadualSimples,
        observacaoSimples,
      };
    }

    if (code === 201) {
      return {
        situacaoOperacaoSnSimples,
        cfopSimples,
        aliquotaIcmsSimples,
        baseIcmsSimples,
        partilhaIcmsInterestadualSimples,
        aliquotaCalculoCreditoSimples,
        observacaoSimples,
        // ST
        aliquotaIcmsStSimples,
        baseCalculoIcmsStSimples,
        margemAdicionalIcmsStSimples,
        aliquotaFcpIcmsStSimples,
        modalidadeBcIcmsStSimples,
        percentualReducaoBcIcmsStSimples,
      };
    }

    if (code === 202 || code === 203) {
      return {
        situacaoOperacaoSnSimples,
        cfopSimples,
        aliquotaIcmsSimples,
        baseIcmsSimples,
        partilhaIcmsInterestadualSimples,
        observacaoSimples,
        // ST
        aliquotaIcmsStSimples,
        baseCalculoIcmsStSimples,
        margemAdicionalIcmsStSimples,
        aliquotaFcpIcmsStSimples,
        modalidadeBcIcmsStSimples,
        percentualReducaoBcIcmsStSimples,
      };
    }

    if (code === 300 || code === 400) {
      return {
        situacaoOperacaoSnSimples,
        cfopSimples,

        partilhaIcmsInterestadualSimples,
        observacaoSimples,
      };
    }

    if (code === 500) {
      const newObj = {
        situacaoOperacaoSnSimples,
        cfopSimples,
        percentualReducaoBcEfetivaSimples,
        aliquotaIcmsEfetivaSimples,
        baseIcmsEfetivaSimples,
        partilhaIcmsInterestadualSimples,
        observacaoSimples,

        obterIcmsStRetidoAnteriormenteNfCompraSimples,
      };
      if (!obterIcmsStRetidoAnteriormenteNfCompraSimples) {
        newObj.aliquotaIcmsStSimples = aliquotaIcmsStSimples;
        newObj.baseCalculoIcmsStSimples = baseCalculoIcmsStSimples;
        newObj.aliquotaFcpIcmsStSimples = aliquotaFcpIcmsStSimples;
      }
      return newObj;
    }

    if (code === 900) {
      return {
        situacaoOperacaoSnSimples,
        cfopSimples,
        aliquotaIcmsSimples,
        baseIcmsSimples,
        partilhaIcmsInterestadualSimples,
        modalidadeBcIcmsSimples,
        percentualReducaoBcSimples,
        aliquotaCalculoCreditoSimples,
        observacaoSimples,
        // ST
        aliquotaIcmsStSimples,
        baseCalculoIcmsStSimples,
        margemAdicionalIcmsStSimples,
        aliquotaFcpIcmsStSimples,
        modalidadeBcIcmsStSimples,
        percentualReducaoBcIcmsStSimples,
      };
    }

    return {
      situacaoOperacaoSnSimples,
    };
  };

  toRequestPis = (pis) => {
    const {
      situacaoTributariaPis,
      aliquotaPis,
      baseCalculoPis,
      observacaoPis,
      valorPis,
      tipoCalculoPis,
    } = pis;

    const code = parseInt(SituacaoTributariaPis[situacaoTributariaPis].code, 10);

    if (code === 1 || code === 2) {
      return {
        situacaoTributariaPis,
        aliquotaPis,
        baseCalculoPis,
        observacaoPis,
      };
    }

    if (code === 3) {
      return {
        situacaoTributariaPis,
        valorPis,
        observacaoPis,
      };
    }

    if (code === 5 || (code >= 49 && code <= 99)) {
      const newObj = {
        situacaoTributariaPis,
        tipoCalculoPis,

        observacaoPis,
      };

      if (tipoCalculoPis === TipoCalculoNFeType.VALOR.code) {
        newObj.valorPis = valorPis;
      } else {
        newObj.aliquotaPis = aliquotaPis;
        newObj.baseCalculoPis = baseCalculoPis;
      }

      return newObj;
    }

    return {
      situacaoTributariaPis,
    };
  };

  toRequestCofins = (cofins) => {
    const {
      situacaoTributariaCofins,
      aliquotaCofins,
      valorCofins,
      baseCalculoCofins,
      observacaoCofins,
      tipoCalculoCofins,
    } = cofins;

    const code = parseInt(SituacaoTributariaCofins[situacaoTributariaCofins].code, 10);

    if (code === 1 || code === 2) {
      return {
        situacaoTributariaCofins,
        aliquotaCofins,
        baseCalculoCofins,
        observacaoCofins,
      };
    }

    if (code === 3) {
      return {
        situacaoTributariaCofins,
        valorCofins,
        observacaoCofins,
      };
    }

    if (code === 5 || (code >= 49 && code <= 99)) {
      const newObj = {
        situacaoTributariaCofins,
        tipoCalculoCofins,

        observacaoCofins,
      };

      if (tipoCalculoCofins === TipoCalculoNFeType.VALOR.code) {
        newObj.valorCofins = valorCofins;
      } else {
        newObj.aliquotaCofins = aliquotaCofins;
        newObj.baseCalculoCofins = baseCalculoCofins;
      }

      return newObj;
    }

    return {
      situacaoTributariaCofins,
    };
  };

  toRequestIpi = (ipi) => {
    let { situacaoTributariaIpi } = ipi;
    const { aliquotaIpi, codigoEnquadramentoIpi, observacaoIpi, tipoCalculoIpi, valorIpi } = ipi;

    situacaoTributariaIpi =
      situacaoTributariaIpi === SituacaoTributariaIpi.SELECIONE.value
        ? null
        : situacaoTributariaIpi;

    const code = parseInt(SituacaoTributariaIpi[situacaoTributariaIpi]?.code || -1, 10);

    if (code === 0 || code === 49 || code === 50 || code === 99) {
      const newObj = {
        situacaoTributariaIpi,
        codigoEnquadramentoIpi,
        tipoCalculoIpi,
        observacaoIpi,
      };

      if (tipoCalculoIpi === TipoCalculoNFeType.VALOR.code) {
        newObj.valorIpi = valorIpi;
      } else {
        newObj.aliquotaIpi = aliquotaIpi;
      }

      return newObj;
    }

    if ((code >= 1 && code <= 5) || (code >= 51 && code <= 55)) {
      return {
        situacaoTributariaIpi,
        codigoEnquadramentoIpi,
      };
    }

    return {
      situacaoTributariaIpi,
    };
  };
}

export default new NaturezaOperacaoConverterUtil();
