// eslint-disable-next-line import/extensions
import { primaryColor } from "assets/jss/material-dashboard-react";
// eslint-disable-next-line import/extensions
import { blackColor, whiteColor, hexToRgb } from "assets/jss/material-dashboard-react.js";

// eslint-disable-next-line prefer-template
const Style = {
  card: {
    border: "0",
    margin: 4,
    // marginBottom: "30px",
    // marginTop: "30px",
    borderRadius: "6px",
    color: `rgba(${hexToRgb(blackColor)}, 0.87)`,
    background: whiteColor,
    // width: "100%",
    boxShadow: `0 1px 4px 0 rgba(${hexToRgb(blackColor)}, 0.14)`,
    position: "relative",
    display: "flex",
    flexDirection: "column",
    minWidth: "0",
    wordWrap: "break-word",
    fontSize: ".875rem",
    // height: "100%",
  },
  selected: {
    color: "#ffff !important",
    backgroundColor: `${primaryColor[1]}!important`,
  },
};

export default Style;
