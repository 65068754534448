/* eslint-disable react/prop-types */
import React, { useCallback } from "react";
import { Divider, Typography } from "@mui/material";
import GridItem from "components/Grid/GridItem";
import CurrencyTextInput from "components/textInput/CurrencyTextInput";
import GridContainer from "components/Grid/GridContainer";

const NotaFiscalTotais = ({ data = {}, handleChange, errors = {} }) => {
  const onChange = useCallback(
    (field, value) => {
      handleChange({
        ...data,
        [field]: value,
      });
    },
    [data, handleChange]
  );

  const renderTotais = ({ label, field }) => (
    <GridItem xs={12} sm={12} md={3}>
      <CurrencyTextInput
        decimalPlaces={3}
        currencySymbol=""
        id={field}
        label={label}
        value={data[field] || "0"}
        onChange={onChange}
        errors={errors}
        disabled
      />
    </GridItem>
  );

  const renderTotalNfe = () => (
    <GridItem xs={12} sm={12} md={3}>
      <CurrencyTextInput
        decimalPlaces={3}
        currencySymbol=""
        id="totalNf"
        label="Total NFe"
        value={data.totalNf || "0"}
        disabled
        onChange={onChange}
        errors={errors}
      />
    </GridItem>
  );

  const renderDescontoNfe = () => (
    <GridItem xs={12} sm={12} md={3}>
      <CurrencyTextInput
        decimalPlaces={3}
        currencySymbol=""
        id="totalDesconto"
        label="Total de desconto"
        value={data.totalDesconto || "0"}
        disabled
        errors={errors}
      />
    </GridItem>
  );

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12} style={{ marginTop: 30 }}>
        <Divider style={{ width: "100%", marginBottom: 4 }} />
        <Typography style={{ fontSize: 18, fontWeight: 500 }}>Totais da nota</Typography>
      </GridItem>
      {renderTotais({
        label: "Total BC ICMS",
        field: "totalBcIcms",
      })}
      {renderTotais({
        label: "Total ICMS",
        field: "totalIcms",
      })}
      {renderTotais({
        label: "Total FCP",
        field: "totalFcp",
      })}
      {renderTotais({
        label: "Total BC ICMS ST",
        field: "totalBcIcmsSt",
      })}
      {renderTotais({
        label: "Total ICMS ST",
        field: "totalIcmsSt",
      })}
      {renderTotais({
        label: "Total do FCP ST",
        field: "totalFcpSt",
      })}
      {renderTotais({
        label: "Total de frete",
        field: "totalFrete",
      })}
      {renderTotais({
        label: "Total de seguro",
        field: "totalSeguro",
      })}
      {renderTotais({
        label: "Total outras despesas",
        field: "totalOutrasDespesas",
      })}
      {renderTotais({
        label: "Total de II",
        field: "totalIi",
      })}
      {renderTotais({
        label: "Total de IPI",
        field: "totalIpi",
      })}
      {renderTotais({
        label: "Total de IPI devolvido",
        field: "totalIpiDevolvido",
      })}
      {renderTotais({
        label: "Total de PIS",
        field: "totalPis",
      })}
      {renderTotais({
        label: "Total de COFINS",
        field: "totalCofins",
      })}
      {renderTotais({
        label: "Total ICMS deson.",
        field: "totalIcmsDesonerado",
      })}
      {renderTotais({
        label: "Total do FCP Retido",
        field: "totalFcpRetido",
      })}
      {renderTotais({
        label: "Total do FCP Interestadual",
        field: "totalFcpInterestadual",
      })}
      {renderTotais({
        label: "ICMS UF remet",
        field: "icmsUfRemet",
      })}
      {renderTotais({
        label: "ICMS UF destino",
        field: "icmsUfDestino",
      })}
      {renderTotais({
        label: "Total bruto produtos",
        field: "totalBrutoProdutos",
      })}
      {renderDescontoNfe()}
      {renderTotalNfe()}
    </GridContainer>
  );
};

export default React.memo(NotaFiscalTotais);
