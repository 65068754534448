/* eslint-disable react/prop-types */
import React, { useState, useCallback } from "react";
import BigNumber from "bignumber.js";
import moment from "moment";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Grid,
} from "@mui/material";
import useConfirmDialog from "components/confirm/ConfirmDialog";
import { DatePicker } from "@mui/x-date-pickers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { v4 as uuidv4 } from "uuid";
import { faArrowDown } from "@fortawesome/free-solid-svg-icons";
import CurrencyTextInput from "../../../components/textInput/CurrencyTextInput";
import TextInput from "../../../components/textInput/TextInput";
import { primaryColor } from "../../../assets/jss/material-dashboard-react";
import MESSAGES from "../../../config/messages";
import { ExpensePaymentType } from "../model/Expense";
import { currencyFormatter, isEmptyString } from "../../../config/util";
import DataGridCustom from "../../../components/dataGrid/DataGridCustom";
import TextIcon from "../../../components/icons/TextIcon";

const { generalMessages } = MESSAGES;

const validate = (item) => {
  const errors = {};
  const { paymentMethod, value } = item;
  if (isEmptyString(paymentMethod)) {
    errors.paymentMethod = generalMessages.fieldValidations;
    return errors;
  }
  if (value === null || value === undefined || value === "0") {
    errors.value = generalMessages.fieldValidations;
    return errors;
  }
  return errors;
};

const initialItem = {
  paymentMethod: "",
  value: "0",
  interest: "0",
  datePayment: new Date(),
  expensePaymentType: ExpensePaymentType.MANUALLY.code,
};
const PaymentsModal = ({ open, onClose, payments, onSave, handleChangePayments, total }) => {
  const { showConfirmDialog, closeConfirmDialog, ConfirmDialog } = useConfirmDialog();
  const [item, setItem] = useState(initialItem);
  const [errors, setErrors] = useState({});
  const [paymentsOriginals, setPaymentsOriginals] = useState([...payments]);

  const handleChange = useCallback(
    (field, value) => {
      setItem({
        ...item,
        [field]: value,
      });
    },
    [item]
  );

  const getOrderTotal = useCallback(() => {
    let totalPayments = new BigNumber(0);
    payments.forEach((payment) => {
      totalPayments = totalPayments.plus(new BigNumber(payment.value));
    });
    return totalPayments.toNumber();
  }, [payments]);

  const addReceive = useCallback(() => {
    const validationErrors = validate(item);
    const valueParsed = parseFloat(item.value);

    if (getOrderTotal() + valueParsed > total) {
      // eslint-disable-next-line no-alert
      alert("O valor inserido é maior que o valor do pagamento");
      return;
    }

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      const paymentsClone = [...payments];
      paymentsClone.push({ id: uuidv4(), ...item });
      handleChangePayments("payments", paymentsClone);
      setItem(initialItem);
      setErrors({});
    }
  }, [item, getOrderTotal, total, payments, handleChangePayments]);

  const onCloseInternal = useCallback(() => {
    if (JSON.stringify(payments) === JSON.stringify(paymentsOriginals)) {
      onClose();
    } else {
      showConfirmDialog({
        title: "Pagamentos",
        text:
          "Notamos que você alterou itens nessa tela e não salvou. Tem certeza que deseja sair sem salvar?",
        onClickConfirm: () => {
          closeConfirmDialog();
          onClose();
        },
      });
    }
  }, [closeConfirmDialog, onClose, payments, paymentsOriginals, showConfirmDialog]);

  const removeReceive = useCallback(
    (id) => {
      const newPayments = [...payments];
      const index = newPayments.findIndex((pay) => pay.id === id);
      newPayments.splice(index, 1);
      handleChangePayments("payments", newPayments);
    },
    [payments, handleChangePayments]
  );

  const focusInputField = useCallback((input) => {
    if (input) {
      setTimeout(() => {
        input.focus();
      }, 100);
    }
  }, []);

  const columns = [];
  columns.push({
    field: "datePayment",
    headerName: "Data de pagamento",
    minWidth: 200,
    flex: 2,
    valueGetter: ({ value }) => moment(value).format("DD/MM/YYYY"),
  });

  columns.push({
    field: "paymentMethod",
    headerName: "Forma de pagamento",
    minWidth: 250,
    flex: 2,
  });

  columns.push({
    field: "value",
    headerName: "Valor",
    minWidth: 120,
    flex: 1,
    valueGetter: ({ value }) => currencyFormatter(value || 0),
  });

  columns.push({
    field: "interest",
    headerName: "Juros",
    minWidth: 120,
    flex: 1,
    valueGetter: ({ value }) => currencyFormatter(value || 0),
  });

  columns.push({
    field: "actions",
    headerName: generalMessages.actions,
    sortable: false,
    width: 100,
    disableClickEventBubbling: true,
    renderCell: ({ row }) => (
      <div
        style={{
          cursor: "pointer",
          justifyContent: "space-between",
          display: "flex",
          flex: 1,
          padding: "0px",
        }}
      >
        <>
          <TextIcon onClick={() => removeReceive(row.id)} text="Estornar" />
        </>
      </div>
    ),
  });

  return (
    <>
      <Dialog disableEscapeKeyDown open={open} fullWidth maxWidth="md">
        <DialogTitle>Pagamento</DialogTitle>
        <DialogContent>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <TextInput
                inputRef={focusInputField}
                id="paymentMethod"
                placeholder="Dinheiro, Pix, Cartão..."
                label="Forma de pagamento"
                value={item.paymentMethod}
                onChange={handleChange}
                required
                errors={errors}
              />
            </GridItem>

            <GridItem xs={12} sm={12} md={6} style={{ marginTop: "12px" }}>
              <DatePicker
                id="datePayment"
                label="Data de pagamento"
                format="dd/MM/yyyy"
                slotProps={{ textField: { size: "small" } }}
                value={item?.datePayment ? moment(item.datePayment).valueOf() : null}
                helperText={errors.datePayment}
                onChange={(date) => handleChange("datePayment", date)}
              />
            </GridItem>

            <GridItem xs={12} sm={12} md={6}>
              <CurrencyTextInput
                id="value"
                label="Valor"
                value={item.value}
                onChange={handleChange}
                required
                errors={errors}
              />
            </GridItem>

            <GridItem xs={12} sm={12} md={6}>
              <CurrencyTextInput
                id="interest"
                label="Juros"
                value={item.interest}
                onChange={handleChange}
                required
                errors={errors}
              />
            </GridItem>

            <GridItem xs={12} sm={12} md={12}>
              <div style={{ flexGrow: 1, display: "flex", justifyContent: "right" }}>
                <div style={{ marginTop: 12 }}>
                  <Button style={{ color: primaryColor[0] }} onClick={addReceive}>
                    Adicionar Pagamento
                    <FontAwesomeIcon icon={faArrowDown} style={{ marginLeft: 8 }} />
                  </Button>
                </div>
              </div>
            </GridItem>

            <div style={{ height: "30vh", width: "100%", marginTop: "8px" }}>
              <DataGridCustom rows={payments} columns={columns} />
            </div>

            <Grid container style={{ justifyContent: "right", marginTop: "8px" }}>
              <Typography inline align="left" style={{ fontWeight: 400, fontSize: 18 }}>
                Valor:
              </Typography>
              <Typography
                inline
                align="right"
                style={{ fontWeight: 500, fontSize: 18, minWidth: "100px" }}
              >
                {currencyFormatter(total || 0)}
              </Typography>
            </Grid>
            <Grid container style={{ justifyContent: "right" }}>
              <Typography inline align="left" style={{ fontWeight: 400, fontSize: 18 }}>
                Valor devido:
              </Typography>
              <Typography
                inline
                align="right"
                style={{ fontWeight: 500, fontSize: 18, minWidth: "100px" }}
              >
                {currencyFormatter((total || 0) - getOrderTotal())}
              </Typography>
            </Grid>
          </GridContainer>
        </DialogContent>

        <DialogActions>
          <div style={{ flexGrow: 1, display: "flex", justifyContent: "space-between" }}>
            <Button style={{ color: primaryColor[0] }} onClick={onCloseInternal}>
              Cancelar
            </Button>
            <Button style={{ color: primaryColor[0] }} onClick={onSave}>
              Salvar
            </Button>
          </div>
        </DialogActions>
      </Dialog>
      <ConfirmDialog />
    </>
  );
};

export default React.memo(PaymentsModal);
