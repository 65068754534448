const MovementType = {
  ENTRY: {
    code: "ENTRY",
    name: "Entrada",
  },
  OUT: {
    code: "OUT",
    name: "Saída",
  },
  AVERAGE_COST: {
    code: "AVERAGE_COST",
    name: "Custo médio",
  },
};

const UpdateCostType = {
  ENTRY: {
    code: "ENTRY",
    name: "Valor de entrada",
  },
  MEDIUM: {
    code: "MEDIUM",
    name: "Custo médio",
  },
  AVERAGE_COST: {
    code: "AVERAGE_COST",
    name: "Ajuste de custo médio",
  },
  NOT_UPDATE: {
    code: "NOT_UPDATE",
    name: "Não atualizar",
  },
};

const UpdatePriceType = {
  MARGIN: {
    code: "MARGIN",
    name: "Utilizar margem",
  },
  CUSTOM: {
    code: "CUSTOM",
    name: "Personalizado",
  },
  AVERAGE_COST: {
    code: "AVERAGE_COST",
    name: "Ajuste de custo médio",
  },
  NOT_UPDATE: {
    code: "NOT_UPDATE",
    name: "Não atualizar",
  },
};

const UpdatePriceMinimumType = {
  MARGIN: {
    code: "MARGIN",
    name: "Percentual de aumento",
  },
  CUSTOM: {
    code: "CUSTOM",
    name: "Personalizado",
  },
  AVERAGE_COST: {
    code: "AVERAGE_COST",
    name: "Ajuste de custo médio",
  },
  NOT_UPDATE: {
    code: "NOT_UPDATE",
    name: "Não atualizar",
  },
};

const OriginType = {
  MANUALLY: {
    code: "MANUALLY",
    name: "Manual",
  },
  PRODUCT: {
    code: "PRODUCT",
    name: "Produto",
  },
  ORDER: {
    code: "ORDER",
    name: "Venda",
  },
  ENTRY_NOTE: {
    code: "ENTRY_NOTE",
    name: "Nota de compra",
  },
  ORDER_CANCELED: {
    code: "ORDER_CANCELED",
    name: "Venda cancelada",
  },
  SERVICE_ORDER: {
    code: "SERVICE_ORDER",
    name: "Ordem de serviço",
  },
  SERVICE_ORDER_CANCELED: {
    code: "SERVICE_ORDER_CANCELED",
    name: "Ordem de serviço cancelada",
  },
};
class InventoryProduct {
  constructor(source = {}) {
    if (source == null) return this;

    this.id = source.id;
    this.name = source.name;
    this.supplier = source.supplier;
    this.industryCode = source.industryCode;
    this.barCode = source.barCode;
    this.cost = source.cost;
    this.saleValue = source.saleValue;
    this.priceMinimum = source.priceMinimum;
    this.profit = source.profit;
    this.inventory = source.inventory;
    this.localization = source.localization;
    this.additionalInformation = source.additionalInformation;
    this.version = source.version;
    this.service = source.service;
    this.brand = source.brand;
  }
}

export {
  InventoryProduct,
  MovementType,
  OriginType,
  UpdateCostType,
  UpdatePriceType,
  UpdatePriceMinimumType,
};
