/* eslint-disable react/prop-types */
import React, { useCallback } from "react";
import GridContainer from "components/Grid/GridContainer";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@mui/material";
import { primaryColor } from "assets/jss/material-dashboard-react";
import TextInput from "components/textInput/TextInput";
import GridItem from "components/Grid/GridItem";

const NotaFiscalCancelamentoModal = ({ open, onClose, onCancel, value, handleChange, errors }) => {
  const handleClose = useCallback(() => {
    handleChange("");
    onClose();
  }, [handleChange, onClose]);

  return (
    <>
      <Dialog disableEscapeKeyDown open={open} fullWidth maxWidth="sm">
        <DialogTitle>Cancelar nota fiscal</DialogTitle>
        <DialogContent>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <TextInput
                autoFocus
                id="motivoCancelamento"
                label="Motivo do cancelamento"
                value={value}
                onChange={handleChange}
                maxLength={256}
                minLength={15}
                errors={errors}
                required
              />
            </GridItem>
          </GridContainer>
        </DialogContent>
        <DialogActions>
          <div style={{ flexGrow: 1, display: "flex", justifyContent: "space-between" }}>
            <Button style={{ color: primaryColor[0] }} onClick={handleClose}>
              Voltar
            </Button>
            <Button style={{ color: primaryColor[0] }} onClick={onCancel}>
              Cancelar nota fiscal
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default React.memo(NotaFiscalCancelamentoModal);
