import React, { useState, useCallback, useEffect, useContext } from "react";
import CustomComponentsStyle from "assets/jss/material-dashboard-react/components/customComponentsStyle";
import { makeStyles } from "@mui/styles";
import {
  FormControlLabel,
  FormGroup,
  Box,
  Switch,
  Divider,
  Typography,
  Button as ButtonUI,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  FormHelperText,
} from "@mui/material";
import InputMask from "react-input-mask";
import CardStyle from "assets/jss/material-dashboard-react/components/cardStyle";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import Button from "components/CustomButtons/Button";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import CardFooter from "components/Card/CardFooter";
import Autocomplete from "components/autocomplete/Autocomplete";
import { useHistory, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
// import ProductStyle from "./ProductStyle";
import { primaryColor, dangerColor } from "assets/jss/material-dashboard-react";
import InventoryMovementHistoryModal from "views/inventory/components/InventoryMovementHistoryModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClockRotateLeft } from "@fortawesome/free-solid-svg-icons";
import { NFOrigem } from "views/naturezaOperacao/model/NaturezaOperacao";
import PersonForm from "views/person/PersonForm";
import { RegistrationType } from "views/person/model/Person";
import PersonService from "views/person/service/PersonService";
import Product from "./model/Product";
import { toRequest, fromResponse } from "./converter/ProductConverter";
import ProductService from "./service/ProductService";
import { handlingResponseErrors, currencyFormatter } from "../../config/util";
import MESSAGES from "../../config/messages";
import Loading from "../../components/loading/Loading";
import TextInput from "../../components/textInput/TextInput";
import CurrencyTextInput from "../../components/textInput/CurrencyTextInput";
import TooltipCustom from "../../components/tooltipCustom/TooltipCustom";
import UserContext from "../../core/UserContext";
import { MODULES } from "../../config/constants";
import ProductPriceChangeHistoryModal from "../productPriceChangeHistoryService/ProductPriceChangeHistoryModal";
import NcmService from "../ncm/service/NcmService";
import CestService from "../cest/service/CestService";
import UnitMeasurementService from "../unitMeasurement/service/UnitMeasurementService";
import ProductSuppliers from "./components/ProductSuppliers";
import SelectEnum from "../../components/selectEnum/SelectEnum";

const { generalMessages, productMessages } = MESSAGES;

const useCardStyle = makeStyles(CardStyle);
// const useProductStyle = makeStyles(ProductStyle);

// eslint-disable-next-line react/prop-types
export default function ProductForm({ onCancel, afterSave, name }) {
  const useCustomComponentsStyle = makeStyles(CustomComponentsStyle);
  const classesCustomComponentsStyle = useCustomComponentsStyle();
  const { userLogged } = useContext(UserContext);
  const classesCardStyle = useCardStyle();
  // const classesProductStyle = useProductStyle();
  const history = useHistory();
  const routerParams = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [errors, setErrors] = useState({});
  const [fieldsChanged, setFieldsChanged] = useState(false);
  const [product, setProduct] = useState(
    new Product({ active: true, controlInventory: true, saleLowInventory: false, name, commissionable: true })
  );
  const [loading, setLoading] = useState(false);
  const [openInventoryMovement, setOpenInventoryMovement] = useState(false);
  const [openProductPriceChangeHistory, setOpenProductPriceChangeHistory] = useState(false);
  const [openConfirmSave, setOpenConfirmSave] = useState(false);
  const isNew = useCallback(() => !product?.id, [product]);
  const [suppliers, setSuppliers] = useState([]);

  const hasInventoryPermission = useCallback(() => {
    if (userLogged != null) {
      const { modules } = userLogged;
      const inventoryUrl = "/app/inventories";

      const module = MODULES[inventoryUrl];
      const hasPermission = modules.includes(module);
      return hasPermission;
    }
    return false;
  }, [userLogged]);

  const calcMargin = useCallback(
    (field, value) => {
      if (field === "cost") {
        if (Number(value) !== 0) {
          product.margin = ((Number(product.saleValue) - Number(value)) / Number(value)) * 100;
        } else {
          product.margin = 0;
        }
      } else if (field === "saleValue") {
        if (Number(product.cost) !== 0) {
          product.margin = ((Number(value) - Number(product.cost)) / Number(product.cost)) * 100;
        } else {
          product.margin = 0;
        }
      } else if (field === "margin") {
        if (Number(product.cost) !== 0) {
          product.saleValue = Number(product.cost) + Number(product.cost) * (Number(value) / 100);
        }
      }

      setProduct({
        ...product,
        [field]: value,
      });
    },
    [product]
  );

  const handleSuppliers = useCallback((value) => setSuppliers(value), []);

  const handleChange = useCallback(
    (field, value) => {
      if (field === "controlInventory") {
        if (!value) {
          product.saleLowInventory = false;
        }
      }

      calcMargin(field, value);

      setProduct({
        ...product,
        [field]: value,
      });

      setFieldsChanged(true);
    },
    [calcMargin, product]
  );

  const afterSaveGoToInventoryMovement = () => {
    setFieldsChanged(false);
    setOpenConfirmSave(false);
    setOpenInventoryMovement(true);
  };

  const goBack = useCallback(() => {
    if (onCancel) {
      onCancel();
    } else {
      history.push("/app/products");
    }
  }, [history, onCancel]);

  const onSave = useCallback(
    async (afterSaveCallback) => {
      try {
        setLoading(true);
        const { id } = product;

        if (id != null) {
          await ProductService.update(id, toRequest(product, suppliers));
        } else {
          const response = await ProductService.save(toRequest(product, suppliers));
          if (afterSave) {
            afterSave(response?.data?.data);
          }
        }
        enqueueSnackbar(generalMessages.saveSuccess, {
          variant: "success",
          autoHideDuration: 3000,
        });
        afterSaveCallback();
      } catch (error) {
        const { message, validationErrors } = handlingResponseErrors(error);
        enqueueSnackbar(message, {
          variant: "error",
          autoHideDuration: 3000,
        });
        if (validationErrors != null) {
          setErrors(validationErrors);
        }
      } finally {
        setLoading(false);
      }
    },
    [product, enqueueSnackbar, suppliers, afterSave]
  );

  const fetchById = useCallback(async () => {
    try {
      setLoading(true);
      const { id } = routerParams || {};
      if (id != null) {
        const result = await ProductService.fetchById(id);
        const response = fromResponse(result?.data?.data);
        const { suppliers: suppliersResponse, ...rest } = response;
        setProduct(rest);
        setSuppliers(suppliersResponse);
      }
    } finally {
      setLoading(false);
    }
  }, [routerParams]);

  useEffect(() => {
    fetchById();
  }, [fetchById]);

  const closeInventoryMovement = useCallback(async () => {
    fetchById();
    setOpenInventoryMovement(false);
  }, [fetchById]);

  const closeProductPriceChangeHistoryModal = useCallback(async () => {
    setOpenProductPriceChangeHistory(false);
  }, []);

  const goToInventoryMovement = () => {
    if (fieldsChanged) {
      setOpenConfirmSave(true);
    } else {
      setOpenInventoryMovement(true);
    }
  };

  const renderConfirmSave = () => (
    <Dialog open={openConfirmSave}>
      <DialogTitle>Salvar produto</DialogTitle>
      <DialogContent>
        <DialogContentText>
          É necessário salvar o produto antes de prosseguir para a movimentação de estoque!
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <div style={{ flexGrow: 1, display: "flex", justifyContent: "space-between" }}>
          <ButtonUI
            style={{ color: primaryColor[0] }}
            onClick={() => setOpenConfirmSave(false)}
            autoFocus
          >
            Cancelar
          </ButtonUI>
          <ButtonUI
            style={{ color: primaryColor[0] }}
            onClick={() => onSave(afterSaveGoToInventoryMovement)}
          >
            Salvar
          </ButtonUI>
        </div>
      </DialogActions>
    </Dialog>
  );

  const renderSwitch = ({
    value = false,
    field,
    label,
    disabled = false,
    style,
    labelPlacement = "end",
  }) => (
    <FormGroup style={style}>
      <FormControlLabel
        control={
          <Switch
            color="secondary"
            checked={value}
            onChange={(event) => handleChange(field, event.target.checked)}
          />
        }
        label={label}
        labelPlacement={labelPlacement}
        disabled={disabled}
      />
    </FormGroup>
  );

  const renderOriginIcms = () => (
    <GridItem xs={12} sm={12} md={9}>
      <FormControl
        fullWidth
        className={classesCustomComponentsStyle.formControlSelect}
        size="small"
        variant="outlined"
        required
      >
        <InputLabel>Origem</InputLabel>
        <Select
          label="Origem"
          id="originIcms"
          autoWidth
          value={product.originIcms || ""}
          onChange={(event) => {
            const {
              target: { value },
            } = event;
            return handleChange("originIcms", value);
          }}
          error={errors.originIcms}
        >
          {Object.keys(NFOrigem).map((key) => {
            const item = NFOrigem[key];
            const { code, name: itemName, value } = item;
            return (
              <MenuItem key={code} value={value}>
                {code} - {itemName}
              </MenuItem>
            );
          })}
        </Select>
        <FormHelperText style={{ color: dangerColor[0] }}>{errors.originIcms}</FormHelperText>
      </FormControl>
    </GridItem>
  );

  const renderNcm = () => (
    <GridItem xs={12} sm={12} md={3}>
      <Box display="flex" flexGrow={1}>
        <Autocomplete
          required
          freeSolo
          autoSelect
          width="100%"
          variant="outlined"
          fieldsToShowAfterSelectItem={["ncm", "description"]}
          label="NCM"
          placeholder="Digite para pesquisar os NCMs ..."
          service={NcmService}
          value={product.ncm}
          error={errors.ncm}
          helperText={errors.ncm}
          onChange={(value) => {
            if (typeof value === "string" || value == null) {
              handleChange("ncm", value);
            } else {
              handleChange("ncm", value.ncm);
            }
          }}
        />
      </Box>
    </GridItem>
  );

  const renderCest = () => (
    <GridItem xs={12} sm={12} md={3}>
      <Box display="flex" flexGrow={1}>
        <Autocomplete
          freeSolo
          autoSelect
          width="100%"
          variant="outlined"
          fieldsToShowAfterSelectItem={["cest", "description"]}
          label="CEST"
          placeholder="Digite para pesquisar os CESTs ..."
          service={CestService}
          value={product.cest}
          onChange={(value) => {
            if (typeof value === "string" || value == null) {
              handleChange("cest", value);
            } else {
              handleChange("cest", value.cest);
            }
          }}
        />
      </Box>
    </GridItem>
  );

  const renderUnitMeasurement = () => (
    <GridItem xs={12} sm={12} md={5}>
      <Box display="flex" flexGrow={1}>
        <Autocomplete
          width="100%"
          variant="outlined"
          fieldsToShowAfterSelectItem={["acronym", "name"]}
          label="Unidade de medida"
          placeholder="Digite para pesquisar as unidades ..."
          service={UnitMeasurementService}
          value={product.unitMeasurement}
          onChange={(value) => {
            handleChange("unitMeasurement", value);
          }}
          required
          error={errors.unitMeasurement}
        />
      </Box>
    </GridItem>
  );

  const renderCnpjProducer = () => (
    <GridItem xs={8} sm={8} md={3}>
      <InputMask
        mask="99.999.999/9999-99"
        disabled={false}
        value={product.ipiCnpjProducer == null ? "" : product.ipiCnpjProducer}
        onChange={(value) => handleChange("ipiCnpjProducer", value.currentTarget.value)}
      >
        <TextField
          size="small"
          variant="outlined"
          id="ipiCnpjProducer"
          label="CNPJ Produtor"
          fullWidth
          InputLabelProps={{
            className: classesCustomComponentsStyle.labelTextField,
          }}
          className={classesCustomComponentsStyle.textField}
          error={errors.ipiCnpjProducer}
          helperText={errors.ipiCnpjProducer}
        />
      </InputMask>
    </GridItem>
  );

  const renderControlSealCode = () => (
    <GridItem xs={8} sm={8} md={3}>
      <TextInput
        id="ipiControlSealCode"
        maxLength={20}
        label="Código do selo de controle"
        value={product.ipiControlSealCode}
        onChange={handleChange}
        errors={errors}
      />
    </GridItem>
  );

  const renderQuantityControlStamp = () => (
    <GridItem xs={8} sm={8} md={3}>
      <CurrencyTextInput
        currencySymbol=""
        decimalPlaces={0}
        id="ipiQuantityControlStamp"
        label="Quantidade do selo de controle"
        value={product.ipiQuantityControlStamp}
        onChange={handleChange}
        errors={errors}
      />
    </GridItem>
  );

  const renderCodigoBeneficiario = () => (
    <GridItem xs={8} sm={8} md={3}>
      <TextInput
        id="codigoBeneficiario"
        label="Código beneficiário"
        value={product.codigoBeneficiario}
        onChange={handleChange}
        errors={errors}
      />
    </GridItem>
  );

  const renderIndustryCode = () => (
    <GridItem xs={6} sm={6} md={4}>
      <TextInput
        autoFocus
        id="industryCode"
        label={productMessages.industryCode}
        value={product.industryCode}
        onChange={handleChange}
        errors={errors}
      />
    </GridItem>
  );
  const renderName = () => (
    <GridItem xs={12} sm={12} md={12}>
      <TextInput
        id="name"
        label={productMessages.name}
        value={product.name}
        onChange={handleChange}
        required
        errors={errors}
      />
    </GridItem>
  );

  const renderBarcode = () => (
    <GridItem xs={12} sm={12} md={3}>
      <TextInput
        id="barCode"
        label={productMessages.barCode}
        value={product.barCode}
        onChange={handleChange}
        errors={errors}
      />
    </GridItem>
  );
  const renderCost = () => (
    <GridItem xs={12} sm={12} md={3}>
      <CurrencyTextInput
        id="cost"
        label={productMessages.cost}
        value={product.cost}
        onChange={handleChange}
        required
        errors={errors}
      />
    </GridItem>
  );

  const renderBrand = () => (
    <GridItem xs={12} sm={12} md={4}>
      <TextInput
        id="brand"
        label={productMessages.brand}
        value={product.brand}
        onChange={handleChange}
        errors={errors}
      />
    </GridItem>
  );
  const renderSaleValue = () => (
    <GridItem xs={12} sm={12} md={3}>
      <CurrencyTextInput
        id="saleValue"
        label={productMessages.saleValue}
        value={product.saleValue}
        onChange={handleChange}
        required
        errors={errors}
      />
    </GridItem>
  );
  const renderMargin = () => (
    <GridItem xs={12} sm={12} md={3}>
      <CurrencyTextInput
        id="margin"
        currencySymbol="%"
        label={productMessages.margin}
        value={product.margin || "0"}
        onChange={handleChange}
        errors={errors}
      />
    </GridItem>
  );
  const renderLocalization = () => (
    <GridItem xs={12} sm={12} md={6}>
      <TextInput
        id="localization"
        label={productMessages.localization}
        value={product.localization}
        onChange={handleChange}
        errors={errors}
      />
    </GridItem>
  );
  const renderCommissionable = () => (
    <GridItem xs={12} sm={12} md={3}>
      <SelectEnum
        label="Elegível para Comissão"
        field="commissionable"
        id="commissionable"
        valueSelected={product.commissionable}
        errors={errors}
        handleChange={(field, value) =>
          handleChange(field, value)
        }
        Enum={{
          SIM: {
            code: true,
            name: "Sim",
          },
          NAO: {
            code: false,
            name: "Não",
          },
        }}
      />
    </GridItem>
  );
  const renderAdditionalInformation = () => (
    <GridItem xs={12} sm={12} md={12}>
      <TextInput
        id="additionalInformation"
        label={productMessages.additionalInformation}
        value={product.additionalInformation}
        onChange={handleChange}
        errors={errors}
      />
    </GridItem>
  );
  const renderPriceMinimum = () => (
    <GridItem xs={12} sm={12} md={3}>
      <CurrencyTextInput
        id="priceMinimum"
        label={productMessages.priceMinimum}
        value={product.priceMinimum}
        onChange={handleChange}
        errors={errors}
      />
    </GridItem>
  );

  const renderProfit = () => (
    <GridItem xs={6} sm={6} md={2}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginTop: 0,
          textAlign: "center",
        }}
      >
        <div>{productMessages.profit}</div>
        <div style={{ fontSize: 20, fontWeight: "bold" }}>
          {currencyFormatter((product.saleValue || 0) - (product.cost || 0))}
        </div>
      </div>
    </GridItem>
  );

  const renderInventoryConfiguration = () =>
    hasInventoryPermission() && (
      <>
        <GridItem xs={12} sm={12} md={12} style={{ marginTop: 40 }}>
          <Divider style={{ width: "100%", marginBottom: 4 }} />
          <Box display="flex" flexDirection="row" justifyContent="space-between">
            <Box>
              <Typography style={{ fontSize: 18, fontWeight: 500 }}>
                Configurações de estoque
              </Typography>
            </Box>
            <Box>
              {renderSwitch({
                value: product.controlInventory,
                field: "controlInventory",
                label: "Controla estoque",
                labelPlacement: "start",
              })}
            </Box>
          </Box>
        </GridItem>

        {product.controlInventory && (
          <>
            <GridItem xs={12} sm={12} md={2} style={{ marginTop: -2 }}>
              {isNew() ? (
                <TextInput
                  id="inventoryQuantity"
                  label="Estoque inicial"
                  value={product?.inventoryQuantity}
                  onChange={handleChange}
                  errors={errors}
                  disabled={!product.controlInventory || product.id != null}
                  type="number"
                  required
                />
              ) : (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginTop: 0,
                    textAlign: "center",
                  }}
                >
                  <div>{productMessages.inventory}</div>
                  <div style={{ fontSize: 20, fontWeight: "bold" }}>
                    {product?.inventoryQuantity}
                  </div>
                </div>
              )}
            </GridItem>
            <GridItem xs={12} sm={12} md={3}>
              {renderSwitch({
                value: product.saleLowInventory,
                field: "saleLowInventory",
                label: "Permite vender sem estoque",

                disabled: !product.controlInventory,
              })}
            </GridItem>

            {!isNew() && (
              <>
                <GridItem xs={12} sm={12} md={4}>
                  <Box display="flex" style={{ marginTop: 10 }}>
                    <ButtonUI style={{ color: primaryColor[0] }} onClick={goToInventoryMovement}>
                      Movimentação de estoque
                    </ButtonUI>
                  </Box>
                  {openInventoryMovement && (
                    <InventoryMovementHistoryModal
                      open={openInventoryMovement}
                      item={{ product }}
                      onClose={closeInventoryMovement}
                    />
                  )}
                </GridItem>
                {renderConfirmSave()}
              </>
            )}
          </>
        )}
        {!isNew() && openProductPriceChangeHistory && (
          <ProductPriceChangeHistoryModal
            open={openProductPriceChangeHistory}
            product={product}
            onClose={closeProductPriceChangeHistoryModal}
          />
        )}
      </>
    );

  const renderProduct = () => (
    <>
      {renderIndustryCode()}

      <GridItem xs={6} sm={6} md={8}>
        <Box style={{ marginTop: "19px", paddingLeft: "-10px" }}>
          <TooltipCustom title="Se o código não for preenchido, será gerado automaticamente." />
        </Box>
      </GridItem>
      {renderName()}
      {renderBrand()}
      {renderBarcode()}
      {renderUnitMeasurement()}
      {/* <GridItem xs={12} sm={12} md={6} /> */}
      {renderCost()}
      {renderPriceMinimum()}
      {renderSaleValue()}
      {renderProfit()}
      {!isNew() && (
        <GridItem xs={6} sm={6} md={1}>
          <IconButton
            style={{
              borderRadius: "10%",
              width: "45px",
              height: "45px",
              marginTop: "7px",
            }}
            onClick={() => setOpenProductPriceChangeHistory(true)}
            aria-label="Price history"
          >
            <FontAwesomeIcon icon={faClockRotateLeft} fontSize={20} />
          </IconButton>
        </GridItem>
      )}

      {renderMargin()}
      {renderLocalization()}
      {renderCommissionable()}
      {renderAdditionalInformation()}

      <GridItem xs={12} sm={12} md={12}>
        {renderSwitch({
          value: product.active,
          field: "active",
          label: "Ativo",
        })}
      </GridItem>

      {renderInventoryConfiguration()}
    </>
  );

  const renderGrossWeight = () => (
    <GridItem xs={8} sm={8} md={3}>
      <CurrencyTextInput
        decimalPlaces={3}
        currencySymbol=""
        id="grossWeight"
        label="Peso bruto"
        value={product.grossWeight || "0"}
        onChange={handleChange}
        errors={errors}
      />
    </GridItem>
  );

  const renderNetWeight = () => (
    <GridItem xs={8} sm={8} md={3}>
      <CurrencyTextInput
        decimalPlaces={3}
        currencySymbol=""
        id="netWeight"
        label="Peso líquido"
        value={product.netWeight || "0"}
        onChange={handleChange}
        errors={errors}
      />
    </GridItem>
  );

  const renderConversionFactor = () => (
    <GridItem xs={8} sm={8} md={3}>
      <CurrencyTextInput
        decimalPlaces={4}
        currencySymbol="%"
        id="conversionFactor"
        label="Fator de conversão"
        value={product.conversionFactor || "0"}
        onChange={handleChange}
        errors={errors}
      />
    </GridItem>
  );

  const renderValueIpi = () => (
    <GridItem xs={8} sm={8} md={3}>
      <CurrencyTextInput
        decimalPlaces={4}
        id="valueIpi"
        label="Valor do IPI"
        value={product.valueIpi || "0"}
        onChange={handleChange}
        errors={errors}
      />
    </GridItem>
  );

  const renderEanTaxable = () => (
    <GridItem xs={8} sm={8} md={3}>
      <TextInput
        id="eanTaxable"
        maxLength={20}
        label="GTIN/EAN tributável"
        value={product.eanTaxable}
        onChange={handleChange}
        errors={errors}
      />
    </GridItem>
  );

  const renderTaxableUnit = () => (
    <GridItem xs={8} sm={8} md={3}>
      <TextInput
        id="taxableUnit"
        maxLength={6}
        label="Unidade tributável"
        value={product.taxableUnit}
        onChange={handleChange}
        errors={errors}
      />
    </GridItem>
  );

  const renderClassificationCodeIpi = () => (
    <GridItem xs={8} sm={8} md={3}>
      <TextInput
        id="classificationCodeIpi"
        maxLength={3}
        label="Cód. classificação IPI"
        value={product.classificationCodeIpi}
        onChange={handleChange}
        errors={errors}
      />
    </GridItem>
  );

  const renderAdditionalTaxInformation = () => (
    <>
      {product.enableForNf && [
        renderOriginIcms(),
        <GridItem xs={12} sm={12} md={3} />,
        renderNcm(),
        renderCest(),
        renderEanTaxable(),
        renderCodigoBeneficiario(),

        renderTaxableUnit(),
        renderConversionFactor(),
        renderClassificationCodeIpi(),
        renderValueIpi(),

        renderCnpjProducer(),
        renderControlSealCode(),

        renderNetWeight(),
        renderGrossWeight(),
      ]}
    </>
  );

  return (
    <>
      <GridContainer style={{ justifyContent: "center" }}>
        <GridItem xs={12} sm={12} md={10}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classesCardStyle.cardTitle}>{productMessages.product}</h4>
              <p className={classesCardStyle.cardCategory}>{productMessages.productForm}</p>
            </CardHeader>
            <CardBody>
              <GridItem xs={12} sm={12} md={12} style={{ marginTop: 12 }}>
                <Typography style={{ fontSize: 18, fontWeight: 500 }}>Dados gerais</Typography>
              </GridItem>
              <GridContainer>{renderProduct()}</GridContainer>

              <GridItem xs={12} sm={12} md={12} style={{ marginTop: 40 }}>
                <Divider style={{ width: "100%", marginBottom: 4 }} />
                <Box display="flex" flexDirection="row" justifyContent="space-between">
                  <Box>
                    <Typography style={{ fontSize: 18, fontWeight: 500 }}>
                      Informações tributárias adicionais
                    </Typography>
                  </Box>
                  <Box>
                    {renderSwitch({
                      value: product.enableForNf,
                      field: "enableForNf",
                      label: "Habilitar Nota Fiscal",
                      labelPlacement: "start",
                    })}
                  </Box>
                </Box>
              </GridItem>
              <GridContainer>{renderAdditionalTaxInformation()}</GridContainer>
              <ProductSuppliers items={suppliers} handleChange={handleSuppliers} errors={errors} />
            </CardBody>

            <CardFooter>
              <Button color="primary" onClick={goBack}>
                {generalMessages.cancel}
              </Button>
              <Button color="primary" onClick={() => onSave(goBack)}>
                {generalMessages.save}
              </Button>
            </CardFooter>
          </Card>
        </GridItem>
        <Loading loading={loading} />
      </GridContainer>
    </>
  );
}
