/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useCallback, useEffect, useContext } from "react";
import moment from "moment";
import { Buffer } from "buffer";
import InputMask from "react-input-mask";
import { makeStyles } from "@mui/styles";
import { DatePicker } from "@mui/x-date-pickers";
import AddressForm from "components/address/AddressForm";
import AddressService from "components/address/service/AddressService";
import CustomComponentsStyle from "assets/jss/material-dashboard-react/components/customComponentsStyle";
import CardStyle from "assets/jss/material-dashboard-react/components/cardStyle";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import Button from "components/CustomButtons/Button";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import CardFooter from "components/Card/CardFooter";
import {
  TextField,
  Box,
  Grid,
  FormGroup,
  FormControlLabel,
  Switch,
  Typography,
  Divider,
} from "@mui/material";
import { useSnackbar } from "notistack";
import KairumImageDropzone from "components/dropZone/KairumImageDropzone";
import Company from "./model/Company";
import { toRequest, fromResponse } from "./converter/CompanyConverter";
import CompanyService from "./service/CompanyService";
import LoginService from "../login/service/LoginService";
import { handlingResponseErrors } from "../../config/util";
import MESSAGES from "../../config/messages";
import Loading from "../../components/loading/Loading";
import TextInput from "../../components/textInput/TextInput";
import UserContext from "../../core/UserContext";
import IESubstitutosTributarios from "./components/IESubstitutosTributarios";
import SelectEnum from "../../components/selectEnum/SelectEnum";
import { DocumentType } from "../notaFiscal/model/NotaFiscal";

const { generalMessages, companyMessages } = MESSAGES;

const ISENTO = "ISENTO";

const useCustomComponentsStyle = makeStyles(CustomComponentsStyle);
const useCardStyle = makeStyles(CardStyle);

export default function CompanyForm() {
  const { setUserLogged } = useContext(UserContext);
  const classesCustomComponentsStyle = useCustomComponentsStyle();
  const classesCardStyle = useCardStyle();
  const { enqueueSnackbar } = useSnackbar();
  const [errors, setErrors] = useState({});
  const [company, setCompany] = useState(new Company());
  const [loading, setLoading] = useState(false);
  const [states, setStates] = useState([]);
  const [logo, setLogo] = useState(null);
  const [containsCpfOrCnpj, setContainsCpfOrCnpj] = useState(false);

  const [
    inscricaoEstadualSubstitutoTributarioList,
    setInscricaoEstadualSubstitutoTributarioList,
  ] = useState([]);

  const fetchStates = useCallback(async () => {
    try {
      setLoading(true);
      const result = await AddressService.fetchStates();
      const { data } = result;
      const { content } = data;
      setStates(content);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchStates();
  }, [fetchStates]);

  const fetch = useCallback(async () => {
    try {
      setLoading(true);
      const result = await CompanyService.fetch();
      const { data } = result?.data || {};
      const responseCompany = fromResponse(data);
      const {
        inscricaoEstadualSubstitutoTributario,
        inscricaoEstadual,
        cpf,
        cnpj,
      } = responseCompany;

      setContainsCpfOrCnpj(cpf || cnpj);

      setCompany({
        ...responseCompany,
        isento: inscricaoEstadual === ISENTO,
      });
      setInscricaoEstadualSubstitutoTributarioList(inscricaoEstadualSubstitutoTributario);
    } finally {
      setLoading(false);
    }
  }, []);

  const fetchLogo = useCallback(async () => {
    try {
      const result = await CompanyService.loadLogo();
      if (result?.data) {
        const buffer = Buffer.from(result.data, "binary").toString("base64");
        setLogo(buffer);
      }
    } finally {
      setLoading(false);
    }
  }, []);

  const fetchAll = useCallback(() => {
    fetch();
    fetchLogo();
  }, [fetch, fetchLogo]);

  useEffect(() => {
    fetchAll();
  }, [fetchAll]);

  const handleChange = useCallback(
    (field, value) => {
      setCompany({
        ...company,
        [field]: value,
      });
    },
    [company]
  );

  const handleChangeAddress = useCallback(
    (field, value, automaticFilling) => {
      if (automaticFilling) {
        // From via cep
        setCompany({
          ...company,
          ...value,
        });
      } else {
        setCompany({
          ...company,
          [field]: value,
        });
      }
    },
    [company]
  );

  const refresh = useCallback(async () => {
    try {
      setLoading(true);
      const me = await LoginService.me();
      setUserLogged(me?.data);
      window.location.reload();
    } finally {
      setLoading(false);
    }
  }, [setUserLogged]);

  const onSave = useCallback(async () => {
    try {
      setLoading(true);
      const { id } = company;
      if (id != null) {
        await CompanyService.update(
          id,
          toRequest(company, inscricaoEstadualSubstitutoTributarioList)
        );
      } else {
        await CompanyService.save(toRequest(company, inscricaoEstadualSubstitutoTributarioList));
      }

      enqueueSnackbar(generalMessages.saveSuccess, {
        variant: "success",
        autoHideDuration: 3000,
      });
      refresh();
    } catch (error) {
      const { message, validationErrors } = handlingResponseErrors(error);
      enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
      if (validationErrors != null) {
        setErrors(validationErrors);
      }
    } finally {
      setLoading(false);
    }
  }, [company, enqueueSnackbar, inscricaoEstadualSubstitutoTributarioList, refresh]);

  const renderPhones = () => (
    <>
      <GridItem xs={12} sm={12} md={3}>
        <InputMask
          mask="(99) 99999-9999"
          disabled={false}
          value={company.phone == null ? "" : company.phone}
          onChange={(value) => handleChange("phone", value.currentTarget.value)}
        >
          <TextField
            size="small"
            variant="outlined"
            id="phone"
            label={companyMessages.phone}
            fullWidth
            InputLabelProps={{
              className: classesCustomComponentsStyle.labelTextField,
            }}
            className={classesCustomComponentsStyle.textField}
          />
        </InputMask>
      </GridItem>
      <GridItem xs={12} sm={12} md={3}>
        <InputMask
          mask="(99) 99999-9999"
          disabled={false}
          value={company.mobile == null ? "" : company.mobile}
          onChange={(value) => handleChange("mobile", value.currentTarget.value)}
        >
          <TextField
            size="small"
            variant="outlined"
            id="mobile"
            label={companyMessages.mobile}
            fullWidth
            InputLabelProps={{
              className: classesCustomComponentsStyle.labelTextField,
            }}
            className={classesCustomComponentsStyle.textField}
          />
        </InputMask>
      </GridItem>
    </>
  );

  const renderEmail = () => (
    <>
      <GridItem xs={12} sm={12} md={6}>
        <TextInput
          id="email"
          label={companyMessages.email}
          value={company.email}
          onChange={handleChange}
          errors={errors}
          required
        />
      </GridItem>
      {/* <GridItem xs={12} sm={12} md={4} /> */}
    </>
  );

  const renderIESubstitutosTributarios = () => (
    <>
      <Box>
        <GridItem xs={12} sm={12} md={12} style={{ marginTop: 40 }}>
          <Divider style={{ width: "100%", marginBottom: 4 }} />
          <Typography style={{ fontSize: 18, fontWeight: 500 }}>
            Inscrições Estaduais dos Substitutos Tributários
          </Typography>
        </GridItem>
      </Box>
      <IESubstitutosTributarios
        states={states}
        items={inscricaoEstadualSubstitutoTributarioList}
        handleChange={setInscricaoEstadualSubstitutoTributarioList}
      />
    </>
  );

  const handleChangeIsento = useCallback(
    (isento) => {
      setCompany({
        ...company,
        isento,
        inscricaoEstadual: isento ? ISENTO : "",
      });
    },
    [company]
  );

  const renderIEIsentoSwitch = ({
    value = false,
    label,
    disabled = false,
    style,
    labelPlacement = "end",
  }) => (
    <FormGroup style={style}>
      <FormControlLabel
        control={
          <Switch
            color="secondary"
            checked={value}
            onChange={(event) => handleChangeIsento(event.target.checked)}
          />
        }
        label={label}
        labelPlacement={labelPlacement}
        disabled={disabled}
      />
    </FormGroup>
  );

  const addLogo = useCallback(
    async (file) => {
      try {
        setLoading(true);
        const formData = new FormData();
        formData.append("file", file);
        await CompanyService.uploadLogo(formData);
        const result = await CompanyService.fetch();
        const { data } = result?.data || {};
        const { version } = data;
        setCompany((prev) => {
          const current = prev;
          current.version = version;
          return current;
        });
        fetchLogo();
      } finally {
        setLoading(false);
      }
    },
    [fetchLogo]
  );

  const deleteLogo = useCallback(async () => {
    try {
      setLoading(true);

      await CompanyService.deleteLogo();
      setLogo(null);

      enqueueSnackbar(generalMessages.deleteSuccess, {
        variant: "success",
        autoHideDuration: 3000,
      });
    } catch (error) {
      enqueueSnackbar(generalMessages.error, {
        variant: "error",
      });
    } finally {
      setLoading(false);
    }
  }, [enqueueSnackbar]);

  return (
    <>
      <GridContainer style={{ justifyContent: "center" }}>
        <GridItem xs={12} sm={12} md={10}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classesCardStyle.cardTitle}>{companyMessages.company}</h4>
              <p className={classesCardStyle.cardCategory}>{companyMessages.companyForm}</p>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <TextInput
                    id="companyName"
                    label={companyMessages.name}
                    value={company.companyName}
                    onChange={handleChange}
                    required
                    errors={errors}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <TextInput
                    id="tradingName"
                    label={companyMessages.tradingName}
                    value={company.tradingName}
                    onChange={handleChange}
                    required
                    errors={errors}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={2}>
                  <SelectEnum
                    label="Documento"
                    field="documentType"
                    Enum={DocumentType}
                    valueSelected={company.documentType}
                    handleChange={handleChange}
                    errors={errors}
                    required
                    disabled={containsCpfOrCnpj}
                  />
                </GridItem>
                {DocumentType.CNPJ.code === company.documentType && (
                  <GridItem xs={12} sm={12} md={3}>
                    <InputMask
                      mask="99.999.999/9999-99"
                      disabled={containsCpfOrCnpj}
                      value={company.cnpj == null ? "" : company.cnpj}
                      onChange={(value) => handleChange("cnpj", value.currentTarget.value)}
                    >
                      <TextField
                        size="small"
                        variant="outlined"
                        id="cnpj"
                        label={companyMessages.cnpj}
                        fullWidth
                        InputLabelProps={{
                          className: classesCustomComponentsStyle.labelTextField,
                        }}
                        className={classesCustomComponentsStyle.textField}
                        error={errors.cnpj}
                        helperText={errors.cnpj}
                      />
                    </InputMask>
                  </GridItem>
                )}
                {DocumentType.CPF.code === company.documentType && (
                  <GridItem xs={12} sm={12} md={3}>
                    <InputMask
                      mask="999.999.999-99"
                      value={company.cpf == null ? "" : company.cpf}
                      onChange={(value) => handleChange("cpf", value.currentTarget.value)}
                      disabled={containsCpfOrCnpj}
                    >
                      <TextField
                        size="small"
                        variant="outlined"
                        id="cpf"
                        label="CPF"
                        fullWidth
                        InputLabelProps={{
                          className: classesCustomComponentsStyle.labelTextField,
                        }}
                        className={classesCustomComponentsStyle.textField}
                        error={errors.cpf}
                        helperText={errors.cpf}
                      />
                    </InputMask>
                  </GridItem>
                )}
                <GridItem xs={12} sm={12} md={4}>
                  <TextInput
                    id="inscricaoEstadual"
                    label="Inscrição estadual"
                    value={company.inscricaoEstadual}
                    onChange={handleChange}
                    errors={errors}
                    disabled={company.isento}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  <Box style={{ marginTop: 12 }}>
                    {renderIEIsentoSwitch({
                      value: company.isento,
                      label: "IE Isento",
                    })}
                  </Box>
                </GridItem>

                {renderPhones()}
                {renderEmail()}
                <AddressForm
                  errorFields={{
                    zipCode: "address.zipCode",
                    state: "address.state",
                    city: "address.city",
                    street: "address.street",
                    neighborhood: "address.neighborhood",
                    number: "address.number",
                    complement: "address.complement",
                  }}
                  address={company}
                  states={states}
                  handleChange={handleChangeAddress}
                  errors={errors}
                />
                <Box width="100%" />
                <GridItem xs={12} sm={12} md={3} style={{ marginTop: "12px" }}>
                  <DatePicker
                    id="start"
                    label={companyMessages.foundationDate}
                    format="dd/MM/yyyy"
                    slotProps={{ textField: { size: "small" } }}
                    value={
                      company?.foundationDate ? moment(company.foundationDate).valueOf() : null
                    }
                    onChange={(date) => handleChange("foundationDate", date)}
                  />
                </GridItem>
                <Grid item xs={12} sm={12} md={12}>
                  {renderIESubstitutosTributarios()}
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <Box>
                    <GridItem xs={12} sm={12} md={12} style={{ marginTop: 40 }}>
                      <Divider style={{ width: "100%", marginBottom: 4 }} />
                      <Typography style={{ fontSize: 18, fontWeight: 500 }}>
                        Logo da empresa
                      </Typography>
                      <Typography style={{ fontSize: 14, fontWeight: 100 }}>
                        Seu logo será exibido nas suas Notas Fiscais, pedidos, propostas, etc.
                      </Typography>
                    </GridItem>
                  </Box>
                  <KairumImageDropzone
                    textDropzone="Formatos aceitos JPEG, JPG, PNG com tamanho máximo de 1MB"
                    handleChange={addLogo}
                    onDelete={deleteLogo}
                    item={logo}
                    fileTypes={{
                      "image/png": [".png"],
                      "image/jpg": [".jpg", ".jpeg"],
                    }}
                  />
                </Grid>
              </GridContainer>
            </CardBody>
            <CardFooter>
              <Button color="primary" onClick={onSave}>
                {generalMessages.save}
              </Button>
            </CardFooter>
          </Card>
        </GridItem>
        <Loading loading={loading} />
      </GridContainer>
    </>
  );
}
