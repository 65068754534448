const NotaFiscalHistoricoSituacaoEventoType = {
  CRIADA: {
    code: "CRIADA",
    name: "Criada",
  },
  SUCESSO: {
    code: "SUCESSO",
    name: "Sucesso",
  },
  ERRO: {
    code: "ERRO",
    name: "Erro",
  },
};

const NotaFiscalHistoricoTipoEventoType = {
  EMISSAO: {
    code: "EMISSAO",
    name: "Emissão",
  },
  CANCELAMENTO: {
    code: "CANCELAMENTO",
    name: "Cancelamento",
  },
  MANIFESTACAO: {
    code: "MANIFESTACAO",
    name: "Manifestação",
  },
};

const NotaFiscalStatusType = {
  CRIADA: {
    code: "CRIADA",
    name: "Criada",
  },
  EMITIDA: {
    code: "EMITIDA",
    name: "Emitida",
  },
  ERRO: {
    code: "ERRO",
    name: "Erro",
  },
  CANCELADA: {
    code: "CANCELADA",
    name: "Cancelada",
  },
};

const NotaFiscalImportacaoOrigemType = {
  UPLOAD_XML: {
    code: "UPLOAD_XML",
    name: "Upload Manual",
  },
  AUTOMATICA: {
    code: "AUTOMATICA",
    name: "Automática",
  },
  NOTA_ENTRADA: {
    code: "NOTA_ENTRADA",
    name: "Emissão Própria",
  },
};

const NFEventoManifestacaoDestinatarioType = {
  PENDENTE_DE_MANIFESTACAO: {
    code: "PENDENTE_DE_MANIFESTACAO",
    name: "Pendente de Manifestação",
  },
  CONFIRMACAO_DA_OPERACAO: {
    code: "CONFIRMACAO_DA_OPERACAO",
    name: "Confirmação da Operação",
  },
  CIENCIA_DA_EMISSAO: {
    code: "CIENCIA_DA_EMISSAO",
    name: "Ciência da Operação",
  },
  DESCONHECIMENTO_DA_OPERACAO: {
    code: "DESCONHECIMENTO_DA_OPERACAO",
    name: "Desconhecimento da Operação",
  },
  OPERACAO_NAO_REALIZADA: {
    code: "OPERACAO_NAO_REALIZADA",
    name: "Operação nao Realizada",
  },
};

const NFFinalidade = {
  NORMAL: {
    code: "NORMAL",
    name: "Normal",
  },
  COMPLEMENTAR: {
    code: "COMPLEMENTAR",
    name: "Complementar",
  },
  AJUSTE: {
    code: "AJUSTE",
    name: "Ajuste",
  },
  DEVOLUCAO_OU_RETORNO: {
    code: "DEVOLUCAO_OU_RETORNO",
    name: "Devolução ou retorno",
  },
};

const NFTipo = {
  ENTRADA: {
    code: "ENTRADA",
    name: "Entrada",
  },
  SAIDA: {
    code: "SAIDA",
    name: "Saída",
  },
};

const NFModelo = {
  NFE: {
    code: "NFE",
    name: "NFe (55)",
  },
  NFCE: {
    code: "NFCE",
    name: "NFCe (65)",
  },
};

const NFOperacaoConsumidorFinal = {
  NAO: {
    code: "NAO",
    name: "Não",
  },
  SIM: {
    code: "SIM",
    name: "Sim",
  },
};

const NFTipoEmissao = {
  EMISSAO_NORMAL: {
    code: "EMISSAO_NORMAL",
    name: "Normal",
  },
  CONTINGENCIA_FS_IA: {
    code: "CONTINGENCIA_FS_IA",
    name: "Contingência FS",
  },
  REGIME_ESPECIAL_NFF: {
    code: "REGIME_ESPECIAL_NFF",
    name: "Regime Especial NFF (NT 2021.002)",
  },
  CONTINGENCIA_EPEC: {
    code: "CONTINGENCIA_EPEC",
    name: "Contingência EPEC",
  },
  CONTINGENCIA_FSDA: {
    code: "CONTINGENCIA_FSDA",
    name: "Contingência FSDA",
  },
  CONTINGENCIA_SVCAN: {
    code: "CONTINGENCIA_SVCAN",
    name: "Contingência SVCAN",
  },
  CONTINGENCIA_SVCRS: {
    code: "CONTINGENCIA_SVCRS",
    name: "Contingência SVCRS",
  },
  CONTIGENCIA_OFFLINE: {
    code: "CONTIGENCIA_OFFLINE",
    name: "Contigência offline NFC-e",
  },
};

const NFIdentificadorLocalDestinoOperacao = {
  OPERACAO_INTERNA: {
    code: "OPERACAO_INTERNA",
    name: "Operação interna",
  },
  OPERACAO_INTERESTADUAL: {
    code: "OPERACAO_INTERESTADUAL",
    name: "Operação interestadual",
  },
  OPERACAO_COM_EXTERIOR: {
    code: "OPERACAO_COM_EXTERIOR",
    name: "Operação com o exterior",
  },
};

const NFIndicadorPresencaComprador = {
  NAO_APLICA: {
    code: "NAO_APLICA",
    name: "Não se aplica",
  },
  OPERACAO_PRESENCIAL: {
    code: "OPERACAO_PRESENCIAL",
    name: "Operação presencial",
  },
  OPERACAO_NAO_PRESENCIAL_INTERNET: {
    code: "OPERACAO_NAO_PRESENCIAL_INTERNET",
    name: "Operação não presencial - Internet",
  },
  OPERACAO_NAO_PRESENCIAL_TELEATENDIMENTO: {
    code: "OPERACAO_NAO_PRESENCIAL_TELEATENDIMENTO",
    name: "Operação não presencial - Teleatendimento",
  },
  OPERACAO_PRESENCIAL_FORA_ESTABELECIMENTO: {
    code: "OPERACAO_PRESENCIAL_FORA_ESTABELECIMENTO",
    name: "Operação presencial, fora do estabelecimento",
  },
  OPERACAO_NAO_PRESENCIAL_OUTROS: {
    code: "OPERACAO_NAO_PRESENCIAL_OUTROS",
    name: "Operação não presencial - Outros",
  },
};

const NFCeIndicadorPresencaComprador = {
  OPERACAO_PRESENCIAL: {
    code: "OPERACAO_PRESENCIAL",
    name: "Operação presencial",
  },
  NFCE_EM_OPERACAO_COM_ENTREGA_DOMICILIO: {
    code: "NFCE_EM_OPERACAO_COM_ENTREGA_DOMICILIO",
    name: "NFC-e em operação com entrega a domicílio",
  },
};

const DocumentType = {
  CPF: {
    code: "CPF",
    name: "CPF",
  },
  CNPJ: {
    code: "CNPJ",
    name: "CNPJ",
  },
  // CESTRANGEIROPF: {
  //   code: "ESTRANGEIRO",
  //   name: "ESTRANGEIRO",
  // },
};

const NFIndicadorIEDestinatario = {
  CONTRIBUINTE_ICMS: {
    code: "CONTRIBUINTE_ICMS",
    name: "Contribuinte ICMS",
  },
  CONTRIBUINTE_ISENTO_INSCRICAO_CONTRIBUINTES_ICMS: {
    code: "CONTRIBUINTE_ISENTO_INSCRICAO_CONTRIBUINTES_ICMS",
    name: "Contribuinte isento inscrição contribuintes ICMS",
  },
  NAO_CONTRIBUINTE: {
    code: "NAO_CONTRIBUINTE",
    name: "Não contribuinte",
  },
};

const NFModalidadeFrete = {
  CONTRATACAO_POR_CONTA_DO_REMETENTE: {
    code: "CONTRATACAO_POR_CONTA_DO_REMETENTE",
    name: "Contratação do frete por conta do remetente (CIF)",
  },
  CONTRATACAO_POR_CONTA_DO_DESTINATARIO: {
    code: "CONTRATACAO_POR_CONTA_DO_DESTINATARIO",
    name: "Contratação do frete por conta do destinatário (FOB)",
  },
  CONTRATACAO_POR_CONTA_DE_TERCEIROS: {
    code: "CONTRATACAO_POR_CONTA_DE_TERCEIROS",
    name: "Contratação do frete por conta de terceiros",
  },
  PROPRIO_POR_CONTA_DO_REMETENTE: {
    code: "PROPRIO_POR_CONTA_DO_REMETENTE",
    name: "Contratação do frete por conta de terceiros",
  },
  PROPRIO_POR_CONTA_DO_DESTINATARIO: {
    code: "PROPRIO_POR_CONTA_DO_DESTINATARIO",
    name: "Transporte próprio por conta do destinatário",
  },
  SEM_OCORRENCIA_TRANSPORTE: {
    code: "SEM_OCORRENCIA_TRANSPORTE",
    name: "Sem ocorrência de transporte",
  },
};

const DFUnidadeFederativa = {
  AC: { code: "AC", name: "Acre" },
  AL: { code: "AL", name: "Alagoas" },
  AP: { code: "AP", name: "Amapá" },
  AM: { code: "AM", name: "Amazonas" },
  BA: { code: "BA", name: "Bahia" },
  CE: { code: "CE", name: "Ceará" },
  DF: { code: "DF", name: "Distrito Federal" },
  GO: { code: "GO", name: "Goiás" },
  ES: { code: "ES", name: "Espírito Santo" },
  MA: { code: "MA", name: "Maranhão" },
  MT: { code: "MT", name: "Mato Grosso" },
  MS: { code: "MS", name: "Mato Grosso do Sul" },
  MG: { code: "MG", name: "Minas Gerais" },
  PA: { code: "PA", name: "Pará" },
  PB: { code: "PB", name: "Paraiba" },
  PR: { code: "PR", name: "Paraná" },
  PE: { code: "PE", name: "Pernambuco" },
  PI: { code: "PI", name: "Piauí" },
  RJ: { code: "RJ", name: "Rio de Janeiro" },
  RN: { code: "RN", name: "Rio Grande do Norte" },
  RS: { code: "RS", name: "Rio Grande do Sul" },
  RO: { code: "RO", name: "Rondônia" },
  RR: { code: "RR", name: "Roraima" },
  SP: { code: "SP", name: "São Paulo" },
  SC: { code: "SC", name: "Santa Catarina" },
  SE: { code: "SE", name: "Sergipe" },
  TO: { code: "TO", name: "Tocantins" },
  NACIONAL: { code: "NACIONAL", name: "Nacional" },
  RFB: { code: "RFB", name: "RFB" },
  EX: { code: "EX", name: "Exterior" },
};
class NotaFiscal {
  constructor(source = {}) {
    if (source == null) return this;

    this.id = source.id;
    this.dadosGerais = source.dadosGerais;
    this.dadosDestinatario = source.dadosDestinatario;
    this.dadosEmitente = source.dadosEmitente;
    this.dadosEnderecoEntrega = source.dadosEnderecoEntrega;
    this.dadosTransporte = source.dadosTransporte;
    this.notaFiscalFormaPagamentos = source.notaFiscalFormaPagamentos;
    this.dadosVolume = source.dadosVolume;
    this.dadosTransporte = source.dadosTransporte;
    this.dadosRetencaoIcmsTransporte = source.dadosRetencaoIcmsTransporte;
    this.notaFiscalProdutos = source.notaFiscalProdutos;
    this.notaFiscalHistoricoList = source.notaFiscalHistoricoList;
    this.notaFiscalImportada = source.notaFiscalImportada;
    this.version = source.version;
    this.status = source.status;
    this.active = source.active;
    this.fornecedor = source.fornecedor;
    this.despesaImportada = source.despesaImportada;
    this.informacoesComplementares = source.informacoesComplementares;
  }
}

export {
  NotaFiscal,
  NotaFiscalStatusType,
  NotaFiscalImportacaoOrigemType,
  NFEventoManifestacaoDestinatarioType,
  NFFinalidade,
  NFTipo,
  NFModelo,
  NFOperacaoConsumidorFinal,
  NFTipoEmissao,
  NFIdentificadorLocalDestinoOperacao,
  NFIndicadorPresencaComprador,
  NFCeIndicadorPresencaComprador,
  DocumentType,
  NFIndicadorIEDestinatario,
  NFModalidadeFrete,
  DFUnidadeFederativa,
  NotaFiscalHistoricoSituacaoEventoType,
  NotaFiscalHistoricoTipoEventoType,
};
