import React, { useState, useCallback, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import CardStyle from "assets/jss/material-dashboard-react/components/cardStyle";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import Button from "components/CustomButtons/Button";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import CardFooter from "components/Card/CardFooter";
import { useSnackbar } from "notistack";
import KairumDropzone from "components/dropZone/KairumDropzone";
import { handlingResponseErrors } from "../../config/util";
import MESSAGES from "../../config/messages";
import Loading from "../../components/loading/Loading";

import TextPassword from "../../components/textInput/TextPassword";
import DigitalCertificateService from "./service/DigitalCertificateService";

const { generalMessages, digitalCertificate, userMessages } = MESSAGES;

const useCardStyle = makeStyles(CardStyle);
const initial = { password: "", passwordRepeat: "", file: null, id: null };
// eslint-disable-next-line react/prop-types
export default function DigitalCertificateForm() {
  const classesCardStyle = useCardStyle();

  const { enqueueSnackbar } = useSnackbar();
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [certificate, setCertificate] = useState(initial);

  const handleChange = useCallback(
    (field, value) => {
      setCertificate({
        ...certificate,
        [field]: value,
      });
    },
    [certificate]
  );

  const handleChangeFile = useCallback(
    (value) => {
      setCertificate({
        ...certificate,
        file: value,
      });
    },
    [certificate]
  );

  const onDelete = useCallback(async () => {
    try {
      setLoading(true);
      const { id } = certificate;
      if (id) {
        await DigitalCertificateService.delete(certificate.id);
      }

      setCertificate(initial);
      setErrors({});
      enqueueSnackbar(generalMessages.deleteSuccess, {
        variant: "success",
        autoHideDuration: 3000,
      });
    } catch (error) {
      enqueueSnackbar(generalMessages.error, {
        variant: "error",
      });
    } finally {
      setLoading(false);
    }
  }, [certificate, enqueueSnackbar]);

  const onSave = useCallback(async () => {
    try {
      setLoading(true);

      const formData = new FormData();
      const { file } = certificate;
      const { id, password, passwordRepeat } = certificate;
      formData.append("file", file);
      if (id) {
        formData.append("id", id);
      }
      formData.append("password", password === null ? "" : password);
      formData.append("passwordRepeat", passwordRepeat === null ? "" : passwordRepeat);

      const { data: response } = await DigitalCertificateService.upload(formData);

      setCertificate({
        ...certificate,
        id: response?.data || null,
      });
      setErrors({});
      enqueueSnackbar(generalMessages.saveSuccess, {
        variant: "success",
        autoHideDuration: 3000,
      });
    } catch (error) {
      const { message, validationErrors } = handlingResponseErrors(error);
      enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
      if (validationErrors != null) {
        setErrors(validationErrors);
      }
    } finally {
      setLoading(false);
    }
  }, [certificate, enqueueSnackbar]);

  const fetchAll = useCallback(async () => {
    setLoading(true);
    try {
      const result = await DigitalCertificateService.fetchAll();
      const { data } = result;
      const { content } = data;

      if (content && content.length > 0) {
        const { id, name } = content[0];
        setCertificate({ id, file: { name }, password: "", passwordRepeat: "" });
      } else {
        setCertificate(initial);
      }
    } catch (error) {
      enqueueSnackbar(generalMessages.error, {
        variant: "error",
      });
    } finally {
      setLoading(false);
    }
  }, [enqueueSnackbar]);

  useEffect(() => {
    fetchAll();
  }, [fetchAll]);

  return (
    <>
      <GridContainer style={{ justifyContent: "center" }}>
        <GridItem xs={12} sm={12} md={10}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classesCardStyle.cardTitle}>
                {`${digitalCertificate.digitalCertificate} A1`}
              </h4>
              <p className={classesCardStyle.cardCategory}>
                {digitalCertificate.digitalCertificateForm}
              </p>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <KairumDropzone
                    title="O certificado digital A1 é utilizado para assinar eletrônicamente as notas fiscais emitidas ou como acesso para a busca das notas fiscais."
                    textDropzone="Arraste e solte para importar o certificado digital A1 da sua empresa"
                    handleChange={handleChangeFile}
                    onDelete={onDelete}
                    item={certificate?.file}
                    errors={errors?.file}
                    fileTypes={{
                      "application/x-pkcs12": [".pfx"],
                      "application/pkcs12": [".pfx"],
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <TextPassword
                    id="password"
                    label={userMessages.password}
                    value={certificate.password}
                    onChange={handleChange}
                    required
                    errors={errors}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <TextPassword
                    id="passwordRepeat"
                    label={userMessages.passwordRepeat}
                    value={certificate.passwordRepeat}
                    onChange={handleChange}
                    required
                    errors={errors}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
            <CardFooter>
              <Button color="primary" onClick={onSave}>
                {generalMessages.save}
              </Button>
            </CardFooter>
          </Card>
        </GridItem>
        <Loading loading={loading} />
      </GridContainer>
    </>
  );
}
