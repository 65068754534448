import moment from "moment";

const toRequest = (calendar) => {
  const { id, name, startDate, endDate, version, userId } = calendar;
  return {
    id,
    name,
    userId,
    startDate: moment(startDate).format("YYYY-MM-DDTHH:mm:ssZ"),
    endDate: moment(endDate).format("YYYY-MM-DDTHH:mm:ssZ"),
    version,
  };
};

const toReactCalendar = (calendar) => {
  const { id, name, startDate, endDate, version } = calendar;

  return {
    id,
    title: name,
    start: new Date(startDate),
    end: new Date(endDate),
    version,
  };
};

const toCalendar = (calendar) => {
  const { id, title, start, end, version } = calendar;

  return {
    id,
    name: title,
    startDate: new Date(start),
    endDate: new Date(end),
    version,
  };
};

export { toRequest, toReactCalendar, toCalendar };
