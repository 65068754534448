import axios from "axios";
import { APIS } from "../../../config/constants";
import BaseService from "../../../core/BaseService";

const { register: registerAPI } = APIS;

class ReportService extends BaseService {
  constructor() {
    super(registerAPI.report);
  }

  printOrderPeriod = (params) =>
    axios.get(`${this.endpoint}/sales?${params}`, {
      responseType: "blob",
    });

  printOSPeriod = (params) =>
    axios.get(`${this.endpoint}/service-orders?${params}`, {
      responseType: "blob",
    });

  printCommissionPeriod = (params) =>
    axios.get(`${this.endpoint}/commissions?${params}`, {
      responseType: "blob",
    });

  printProducts = () =>
    axios.get(`${this.endpoint}/products`, {
      responseType: "blob",
    });
}

export default new ReportService();
