import React, {useState, useEffect, useCallback, useRef} from "react";
import { useDebounce } from "use-debounce";
import { makeStyles } from "@mui/styles";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import CardStyle from "assets/jss/material-dashboard-react/components/cardStyle";
import Searchbar from "../../components/Searchbar/Searchbar";
import UnitConversionService from "./service/UnitConversionService";
import { SEARCH } from "../../config/constants";
import Loading from "../../components/loading/Loading";
import AddButton from "../../components/addButton/AddButton";
import EditIcon from "../../components/icons/EditIcon";
import DataGridCustom from "../../components/dataGrid/DataGridCustom";
import MESSAGES from "../../config/messages";

const { generalMessages} = MESSAGES;

const useCardStyle = makeStyles(CardStyle);

export default function UnitConversionList() {
  const classesuseCardStyle = useCardStyle();
  const history = useHistory();
  const [list, setList] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchTermDebounce] = useDebounce(searchTerm, SEARCH.WAIT_INTERVAL);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const customGridRef = useRef();
  const [rowCount, setRowCount] = React.useState(0);

  const fetchAll = useCallback(
    async ({ term, paginationModelParams, sorting }) => {
      setLoading(true);

      try {
        if (searchTermDebounce !== "" && searchTermDebounce.length < SEARCH.MINIMUM_CHARACTERS) return;
        const pagination = paginationModelParams || customGridRef.current.getDefaultPaginationModel();

        const result = await UnitConversionService.fetchAll({ searchTermDebounce, pagination, sorting });
        const { data } = result;
        const { content, totalElements } = data;

        setList(content);
        setRowCount(totalElements);
      } catch (error) {
        enqueueSnackbar(generalMessages.error, {
          variant: "error",
        });
      } finally {
        setLoading(false);
      }
    },
    [enqueueSnackbar, searchTermDebounce]
  );

  useEffect(() => {
      fetchAll({ term: "" });
  }, [fetchAll]);

  const goToForm = (params) =>
    params?.id
      ? history.push(`/app/unit-conversion/${params?.id}`)
      : history.push("/app/unit-conversion");

  const columns = [
    {
      field: "sourceUnit",
      headerName: "Conversão de",
      flex: 2,
      minWidth: 200,
      valueGetter: ({ value }) => value?.acronym || "",
    },
    {
      field: "targetUnit",
      headerName: "Para",
      flex: 2,
      minWidth: 200,
      valueGetter: ({ value }) => value?.acronym || "",
    },
    {
      field: "conversionFactor",
      headerName: "Fator de conversão",
      flex: 2,
      minWidth: 200,
    },
    {
      field: "active",
      headerName: "Ativo",
      flex: 1,
      minWidth: 120,
      valueGetter: ({ value }) => (value ? "Sim" : "Não"),
    },
    {
      field: "actions",
      headerName: generalMessages.actions,
      sortable: false,
      width: 100,
      disableClickEventBubbling: true,
      renderCell: (params) => (
        <div
          style={{
            cursor: "pointer",
            justifyContent: "center",
            padding: "10px",
            display: "flex",
            flex: 1,
          }}
        >
          {params.row.company && <EditIcon onClick={() => goToForm({ id: params.row.id })} />}
        </div>
      ),
    },
  ];

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card className="gridLayoutCard">
          <div className="gridLayoutContainer">
            <CardHeader color="primary">
              <h4 className={classesuseCardStyle.cardTitleList}>
                Unidades de Conversão
              </h4>
              <p className={classesuseCardStyle.cardCategoryList}>
                Lista de unidades de conversões cadastradas
              </p>
            </CardHeader>

            <div className="gridLayoutFilters">
              <Searchbar
                onChange={setSearchTerm}
                searchTerm={searchTerm}
                placeholder="Digite para pesquisar por unidade de medida ..."
              />
            </div>

            <div className="gridLayoutGrid">
              <DataGridCustom
                ref={customGridRef}
                onRowDoubleClick={goToForm}
                getRowId={(row) => row.id}
                rows={list}
                columns={columns}
                usePagination
                rowCount={rowCount}
                fetchAction={(params) =>
                  fetchAll({
                    ...params,
                    term: searchTerm,
                  })
                }
              />
            </div>

            <div className="gridLayoutBottom">
              <AddButton onClick={() => goToForm(null)} />
            </div>
          </div>
        </Card>
      </GridItem>
      <Loading loading={loading} />
    </GridContainer>
  );
}
