import { APIS } from "../../../config/constants";
import BaseService from "../../../core/BaseService";

const { register: registerAPI } = APIS;

class UnitMeasurementService extends BaseService {
  constructor() {
    super(registerAPI.unitMeasurement);
  }
}

export default new UnitMeasurementService();
