/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useEffect, useReducer } from "react";
import { makeStyles } from "@mui/styles";
import CardStyle from "assets/jss/material-dashboard-react/components/cardStyle";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import Button from "components/CustomButtons/Button";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import CardFooter from "components/Card/CardFooter";
import { useHistory, useParams } from "react-router-dom";
import { FormControlLabel, FormGroup, Switch } from "@mui/material";
import { useSnackbar } from "notistack";
import FinancialAccounts from "./model/FinancialAccounts";
import { toRequest, fromResponse } from "./converter/FinancialAccountsConverter";
import FinancialAccountsService from "./service/FinancialAccountsService";
import { handlingResponseErrors } from "../../config/util";
import MESSAGES from "../../config/messages";
import Loading from "../../components/loading/Loading";
import TextInput from "../../components/textInput/TextInput";
import reducer from "./reducer/reducer";

const { generalMessages, paymentMethodMessages } = MESSAGES;

const useCardStyle = makeStyles(CardStyle);

export default function FinancialAccountsForm() {
  const classesCardStyle = useCardStyle();
  const history = useHistory();
  const routerParams = useParams();
  const { enqueueSnackbar } = useSnackbar();

  const initialState = {
    item: new FinancialAccounts({ active: true }),
    errors: {},
    loading: false,
  };
  const [state, dispatch] = useReducer(reducer, initialState);
  const { item, errors, loading } = state;

  const fetchById = useCallback(async () => {
    try {
      dispatch({ type: "setLoading", value: true });
      const { id } = routerParams || {};
      if (id != null) {
        const result = await FinancialAccountsService.fetchById(id);
        const response = fromResponse(result?.data?.data);
        dispatch({ type: "setItem", value: response });
      }
    } finally {
      dispatch({ type: "setLoading", value: false });
    }
  }, [routerParams]);

  useEffect(() => {
    fetchById();
  }, [fetchById]);

  const goBack = useCallback(() => {
    history.push("/app/financial-accounts");
  }, [history]);

  const onSave = useCallback(async () => {
    try {
      const { item: itemState } = state;
      dispatch({ type: "setLoading", value: true });
      const { id } = itemState;
      if (id != null) {
        await FinancialAccountsService.update(id, toRequest(itemState));
      } else {
        await FinancialAccountsService.save(toRequest(itemState));
      }
      enqueueSnackbar(generalMessages.saveSuccess, {
        variant: "success",
        autoHideDuration: 3000,
      });
      goBack();
    } catch (error) {
      const { message, validationErrors } = handlingResponseErrors(error);
      enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
      if (validationErrors != null) {
        dispatch({ type: "setErrors", value: validationErrors });
      }
    } finally {
      dispatch({ type: "setLoading", value: false });
    }
  }, [state, goBack, enqueueSnackbar]);

  const renderSwitch = (actionType, value = false, field, label, disabled = false) => (
    <FormGroup style={{ paddingTop: "8px" }}>
      <FormControlLabel
        control={
          <Switch
            color="secondary"
            checked={value}
            onChange={(event) =>
              dispatch({
                type: actionType,
                field,
                value: event.target.checked,
              })
            }
          />
        }
        label={label}
        disabled={disabled}
      />
    </FormGroup>
  );

  return (
    <>
      <GridContainer style={{ justifyContent: "center" }}>
        <GridItem xs={12} sm={12} md={10}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classesCardStyle.cardTitle}>Conta financeira</h4>
              <p className={classesCardStyle.cardCategory}>Cadastro de novas contas financeiras</p>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <TextInput
                    autoFocus
                    id="name"
                    label={paymentMethodMessages.name}
                    value={item.name}
                    onChange={(field, value) => dispatch({ type: "handleChange", field, value })}
                    required
                    errors={errors}
                  />
                </GridItem>

                <GridItem xs={12} sm={12} md={12}>
                  {renderSwitch(
                    "handleChange",
                    item.standard,
                    "standard",
                    paymentMethodMessages.standard
                  )}
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  {renderSwitch(
                    "handleChange",
                    item.active,
                    "active",
                    paymentMethodMessages.active
                  )}
                </GridItem>
              </GridContainer>
            </CardBody>
            <CardFooter>
              <Button color="primary" onClick={goBack}>
                {generalMessages.cancel}
              </Button>
              <Button color="primary" onClick={onSave}>
                {generalMessages.save}
              </Button>
            </CardFooter>
          </Card>
        </GridItem>
        <Loading loading={loading} />
      </GridContainer>
    </>
  );
}
