import React, { useContext, useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Divider } from "@mui/material";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import CardStyle from "assets/jss/material-dashboard-react/components/cardStyle";
import OSReport from "./component/OSReport";
import OrderReport from "./component/OrderReport";
import StockReport from "./component/StockReport";
import CommissionReport from "./component/CommissionReport";
import { MODULES } from "../../config/constants";
import UserContext from "../../core/UserContext";

const useCardStyle = makeStyles(CardStyle);

export default function Reports() {
  const { userLogged } = useContext(UserContext);
  const [permission, setPermission] = useState({
    serviceOrder: true,
    order: true,
    stock: true,
    commission: true,
  });
  const classesuseCardStyle = useCardStyle();

  useEffect(() => {
    if (userLogged != null) {
      const { modules } = userLogged;
      const serviceOrder = modules.includes(MODULES["REPORT-SERVICE-ORDER"]);
      const order = modules.includes(MODULES["REPORT-ORDER"]);
      const stock = modules.includes(MODULES["REPORT-INVENTORY"]);
      const commission = modules.includes(MODULES["REPORT-COMMISSIONS"]);
      setPermission({
        serviceOrder,
        order,
        stock,
        commission,
      });
    }

    return null;
  }, [userLogged]);

  return (
    <GridContainer style={{ justifyContent: "center" }}>
      <GridItem xs={12} sm={12} md={10}>
        <Card>
          <CardHeader color="primary">
            <h4 className={classesuseCardStyle.cardTitleList}>Relatórios</h4>
            <p className={classesuseCardStyle.cardCategoryList}>Lista de relatórios</p>
          </CardHeader>

          <CardBody>
            <OSReport locked={!permission.serviceOrder} />
            <Divider style={{ width: "100%", marginTop: 8 }} />
            <OrderReport locked={!permission.order} />
            <Divider style={{ width: "100%", marginTop: 8 }} />
            <StockReport locked={!permission.stock} />
            <Divider style={{ width: "100%", marginTop: 8 }} />
            <CommissionReport locked={!permission.commission} />
            <Divider style={{ width: "100%", marginTop: 8 }} />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
