import moment from "moment";
import { Roles, User } from "../model/User";

const toRequest = (user) => {
  const {
    id,
    code,
    name,
    email,
    roles,
    version,
    active,
    allowSellingBelowMinimumPrice,
    genderType,
    cpf,
    rg,
    phone,
    mobile,
    changePasswordFirstAccess,
    city,
    street,
    number,
    complement,
    zipCode,
    neighborhood,
    function: userFunction,
    admissionDate,
    resignationDate,
    observation,
    timeZone,
    addressId,
    addressVersion,
  } = user;
  let { password, passwordRepeat } = user;

  const hasRole = (role) => roles.indexOf(role) !== -1;
  if (hasRole(Roles.EMPLOYEE.code)) {
    password = Roles.EMPLOYEE.code;
    passwordRepeat = Roles.EMPLOYEE.code;
  }

  const address = {
    id: addressId,
    version: addressVersion,
    city:
      city != null
        ? {
            id: city,
          }
        : null,
    street,
    number,
    complement,
    zipCode,
    neighborhood,
  };

  const cpfWM = cpf?.replace(/[^0-9]+/g, "") || null;
  const mobileWM = mobile?.replace(/[^0-9]+/g, "") || null;
  const phoneWM = phone?.replace(/[^0-9]+/g, "") || null;

  return {
    id,
    code,
    name,
    email,
    password,
    passwordRepeat,
    roles,
    version,
    active,
    allowSellingBelowMinimumPrice,
    genderType,
    cpf: cpfWM,
    rg,
    phone: phoneWM,
    mobile: mobileWM,
    address,
    function: userFunction,
    admissionDate: admissionDate ? moment(admissionDate).format("YYYY-MM-DD") : null,
    resignationDate: resignationDate ? moment(resignationDate).format("YYYY-MM-DD") : null,
    observation,
    timeZone,
    changePasswordFirstAccess,
  };
};

const fromResponse = (response) => {
  if (response === null) return new User();

  const {
    id,
    code,
    name,
    email,
    password,
    passwordRepeat,
    roles,
    version,
    active,
    allowSellingBelowMinimumPrice,
    genderType,
    cpf,
    rg,
    phone,
    mobile,
    address,
    function: userFunction,
    admissionDate,
    resignationDate,
    observation,
    timeZone,
    changePasswordFirstAccess,
  } = response;

  const {
    city,
    street,
    number,
    complement,
    zipCode,
    neighborhood,
    id: addressId,
    version: addressVersion,
  } = address || {};
  let cityId = null;
  let stateId = null;
  if (city != null) {
    cityId = city.id;
    stateId = city.state.id;
  }

  return new User({
    id,
    code,
    name,
    email,
    password,
    passwordRepeat,
    version,
    active,
    allowSellingBelowMinimumPrice,
    genderType,
    cpf,
    rg,
    phone,
    mobile,
    state: stateId,
    city: cityId,
    street,
    number,
    complement,
    zipCode,
    neighborhood,
    function: userFunction,
    admissionDate,
    resignationDate,
    observation,
    timeZone,
    changePasswordFirstAccess,
    roles: roles.map((item) => Roles[item.roleName]?.code),
    addressId,
    addressVersion,
  });
};

export { toRequest, fromResponse };
