const OrderTypeFilter = {
  ALL: {
    code: "ALL",
    name: "Todos",
  },
  BUDGET: {
    code: "BUDGET",
    name: "Orçamentos",
  },
  ORDER: {
    code: "SERVICE_ORDER",
    name: "Ordens de serviço",
  },
};
class ServiceOrder {
  constructor(source = {}) {
    if (source == null) {
      return this;
    }

    this.id = source.id;
    this.expirationDate = source.expirationDate;
    this.customer = source.customer;
    this.user = source.user;
    this.userLogged = source.userLogged;
    this.paymentMethod = source.paymentMethod;
    this.orderType = source.orderType;
    this.additionalInformation = source.additionalInformation;
    this.items = source.items;
    this.version = source.version;
    this.createdAt = source.createdAt;
    this.updatedAt = source.updatedAt;
    this.finalizationDate = source.finalizationDate;
    this.orderNumber = source.orderNumber;
    this.discount = source.discount;
    this.total = source.total;
    this.extraFields = source.extraFields;
    this.cancelled = source.cancelled;
    this.installments = source.installments;
    this.numberInstallments = source.numberInstallments;
    this.veiculo = source.veiculo;
    this.ano = source.ano;
    this.placa = source.placa;
    this.km = source.km;
    this.received = source.received;
    this.difference = source.difference; // Troco
    this.defect = source.defect;
    this.expectedDelivery = source.expectedDelivery;
    this.notaFiscal = source.notaFiscal;
    this.financialAccount = source.financialAccount;
  }
}

export { ServiceOrder, OrderTypeFilter };
