import React, { useState, useCallback, useEffect, useContext } from "react";
import { makeStyles } from "@mui/styles";
import { addDays } from "date-fns";
import CardStyle from "assets/jss/material-dashboard-react/components/cardStyle";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import Button from "components/CustomButtons/Button";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import CardFooter from "components/Card/CardFooter";
import {
  Step,
  StepLabel,
  Stepper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Typography,
} from "@mui/material";
import useConfirmDialog from "components/confirm/ConfirmDialog";
import { useHistory, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { RegistrationType } from "views/person/model/Person";
import PersonService from "views/person/service/PersonService";
import FinancialAccountsService from "views/financialAccounts/service/FinancialAccountsService";
import { handlingResponseErrors, formatDate } from "../../config/util";
import MESSAGES from "../../config/messages";
import ServiceOrderProductStep from "./steps/ServiceOrderProductStep";
import ServiceOrderGeneralStep from "./steps/ServiceOrderGeneralStep";
import ServiceOrderReviewStep from "./steps/ServiceOrderReviewStep";
import OrderService from "./service/ServiceOrderService";
import { OrderType } from "../order/model/Order";
import { toRequest, fromResponse } from "./converter/ServiceOrderConverter";
import PaymentMethodService from "../paymentMethod/service/PaymentMethodService";
import Loading from "../../components/loading/Loading";
import Installments from "../installments/Installments";
import UserContext from "../../core/UserContext";
import NotaFiscalService from "../notaFiscal/service/NotaFiscalService";
import { NFModelo } from "../notaFiscal/model/NotaFiscal";

const { generalMessages, serviceOrderMessages } = MESSAGES;

const useCardStyle = makeStyles(CardStyle);

const steps = [
  serviceOrderMessages.steps.general,
  serviceOrderMessages.steps.products,
  serviceOrderMessages.steps.financial,
  serviceOrderMessages.steps.review,
];
const generalTab = 0;
const productsTab = 1;
const financialTab = 2;
const reviewTab = 3;

const expirationDate = formatDate(addDays(new Date(), 7), "yyyy-MM-dd");

export default function OrderForm() {
  const { userLogged } = useContext(UserContext);
  const classesCardStyle = useCardStyle();
  const history = useHistory();
  const {
    location: { state = {} },
  } = history;
  const { readOnly = false } = state;
  const routerParams = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [errors, setErrors] = useState({});
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [cartItems, setCartItems] = useState([]);
  const [installments, setInstallments] = useState([]);
  const [financialAccounts, setFinancialAccounts] = useState([]);
  const [defaultCustomer, setDefaultCustomer] = useState({});
  const [order, setOrder] = useState({});
  const [activeStep, setActiveStep] = useState(0);
  const [orderNumberModalOpen, setOrderNumberModalOpen] = useState(false);
  const [installmentsValidation, setInstallmentsValidation] = useState({
    isValid: true,
    message: null,
  });
  const [isRecalculateInstallments, setIsRecalculateInstallments] = useState(false);
  const [loading, setLoading] = useState(false);
  const { showConfirmDialog, closeConfirmDialog, ConfirmDialog } = useConfirmDialog();

  useEffect(() => {
    const { id } = routerParams || {};
    setIsRecalculateInstallments(id === null);
  }, [routerParams]);

  const goBack = useCallback(() => {
    history.push("/app/service-orders");
  }, [history]);

  const onPrint = useCallback(
    async (needUpdate) => {
      try {
        setLoading(true);
        const { id, orderNumber, orderType } = order;
        if (needUpdate) {
          if (id != null) {
            const orderToSave = {
              ...order,
              orderType,
              items: cartItems,
              installments,
            };
            const userLoggedRequest = {
              id: userLogged.userId,
              name: userLogged.name,
            };

            await OrderService.update(id, toRequest(orderToSave, userLoggedRequest));
            const { data } = await OrderService.fetchById(id);
            const { data: orderSaved } = data;
            setOrder(fromResponse(orderSaved));
          }
        }
        const response = await OrderService.printOrder(id);
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        const reportName = orderType === OrderType.BUDGET.code ? "Orçamento" : "OS";
        link.setAttribute("download", `${reportName} - ${orderNumber}.pdf`);
        document.body.appendChild(link);
        link.click();
      } catch (e) {
        enqueueSnackbar(generalMessages.error, {
          variant: "error",
          autoHideDuration: 3000,
        });
      } finally {
        setLoading(false);
      }
    },
    [order, cartItems, installments, userLogged?.userId, userLogged?.name, enqueueSnackbar]
  );

  const osToNF = useCallback(
    async (modelo) => {
      try {
        setLoading(true);
        const { id } = order;

        const response = await NotaFiscalService.osToNF(id, modelo.code);
        const idNfe = response?.data?.data?.id;

        if (modelo.code === NFModelo.NFCE.code) {
          try {
            await NotaFiscalService.emitir(idNfe);
            enqueueSnackbar("Nota fiscal emitida com sucesso!", {
              variant: "success",
              autoHideDuration: 5000,
            });
            goBack();
          } catch (error) {
            enqueueSnackbar(
              "Nota fiscal não emitida automaticamente, revise os dados e tente novamente!",
              {
                variant: "warning",
                autoHideDuration: 5000,
              }
            );
            history.push(`/app/${modelo?.code?.toLowerCase()}-emissao/${idNfe}`);
          }
        } else {
          enqueueSnackbar(generalMessages.saveSuccess, {
            variant: "success",
            autoHideDuration: 3000,
          });
          history.push(`/app/${modelo?.code?.toLowerCase()}-emissao/${idNfe}`);
        }
      } catch (error) {
        const { message, validationErrors } = handlingResponseErrors(error);
        enqueueSnackbar(message, {
          variant: "error",
          autoHideDuration: 3000,
        });
        if (validationErrors != null) {
          setErrors(validationErrors);
        }
      } finally {
        setLoading(false);
      }
      return false;
    },
    [enqueueSnackbar, goBack, history, order]
  );

  const beforeVendaToOS = useCallback(
    (modelo) => {
      showConfirmDialog({
        title: "OS para NFe",
        text: `Tem certeza que deseja converter a ordem de serviço para ${modelo?.name}? <br> Apenas os produtos serão incluídos na nota fiscal. Serviços não serão listados.`,
        onClickConfirm: () => {
          osToNF(modelo);
          closeConfirmDialog();
        },
      });
    },
    [closeConfirmDialog, osToNF, showConfirmDialog]
  );

  const renderConverterNotaFiscalButton = useCallback(
    (modelo) => (
      <Button color="primary" onClick={() => beforeVendaToOS(modelo)}>
        Emitir {modelo?.name}
      </Button>
    ),
    [beforeVendaToOS]
  );

  const renderOrderNumberAlert = () => (
    <Dialog disableEscapeKeyDown open={orderNumberModalOpen}>
      <DialogTitle>{serviceOrderMessages.budgetNumber}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Typography inline align="center" style={{ fontWeight: 400, fontSize: 40 }}>
            {order.orderNumber}
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <div
          style={{
            flexGrow: 1,
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column",
          }}
        >
          <Button color="primary" onClick={() => onPrint(false)}>
            {serviceOrderMessages.buttons.print}
          </Button>
          {order.orderType === OrderType.SERVICE_ORDER.code &&
            !order.cancelled && [
              renderConverterNotaFiscalButton(NFModelo.NFE),
              renderConverterNotaFiscalButton(NFModelo.NFCE),
            ]}
          <Button
            color="primary"
            onClick={() => {
              setOrderNumberModalOpen(false);
              goBack();
            }}
            autoFocus
          >
            {serviceOrderMessages.close}
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );

  const onSave = useCallback(
    async (orderType) => {
      try {
        setLoading(true);
        const { id } = order;
        const orderToSave = {
          ...order,
          orderType,
          items: cartItems,
          installments,
          // extraFields,
        };
        const userLoggedRequest = {
          id: userLogged.userId,
          name: userLogged.name,
        };
        if (id != null) {
          await OrderService.update(id, toRequest(orderToSave, userLoggedRequest));
          goBack();
        } else {
          const { data } = await OrderService.save(toRequest(orderToSave, userLoggedRequest));
          const { data: orderSaved } = data;
          setOrder(orderSaved);
          setOrderNumberModalOpen(true);
        }
        enqueueSnackbar(generalMessages.saveSuccess, {
          variant: "success",
          autoHideDuration: 3000,
        });
      } catch (error) {
        const { message, validationErrors } = handlingResponseErrors(error);
        enqueueSnackbar(message, {
          variant: "error",
          autoHideDuration: 3000,
        });
        if (validationErrors != null) {
          setErrors(validationErrors);
        }
      } finally {
        setLoading(false);
      }
      return false;
    },

    [order, userLogged, installments, cartItems, goBack, enqueueSnackbar]
  );

  const beforeSave = useCallback(
    (orderType, isFinish) => {
      if (isFinish) {
        showConfirmDialog({
          title: "Finalização da OS",
          text: serviceOrderMessages.confirmOrderFinish,
          onClickConfirm: () => {
            onSave(orderType);
            closeConfirmDialog();
          },
        });
      } else {
        onSave(orderType);
      }
    },
    [closeConfirmDialog, onSave, showConfirmDialog]
  );

  const onCancel = useCallback(async () => {
    showConfirmDialog({
      title: "OS",
      text: serviceOrderMessages.confirmOrderCancel,
      onClickConfirm: async () => {
        closeConfirmDialog();
        try {
          const { id } = order;

          setLoading(true);
          await OrderService.cancel(id);
          enqueueSnackbar(generalMessages.saveSuccess, {
            variant: "success",
            autoHideDuration: 3000,
          });
          goBack();
        } catch (error) {
          const { message, validationErrors } = handlingResponseErrors(error);
          enqueueSnackbar(message, {
            variant: "error",
            autoHideDuration: 3000,
          });
          if (validationErrors != null) {
            setErrors(validationErrors);
          }
        } finally {
          setLoading(false);
        }
      },
    });
  }, [showConfirmDialog, closeConfirmDialog, order, enqueueSnackbar, goBack]);

  const validateGeneralTab = useCallback(async () => {
    try {
      setLoading(true);
      const userLoggedRequest = {
        id: userLogged.userId,
        name: userLogged.name,
      };
      await OrderService.validateGeneralStep(toRequest(order, userLoggedRequest));
      setActiveStep(productsTab);
    } catch (error) {
      const { message, validationErrors } = handlingResponseErrors(error);
      enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
      if (validationErrors != null) {
        setErrors(validationErrors);
      }
    } finally {
      setLoading(false);
    }
  }, [order, userLogged, enqueueSnackbar]);

  const validateProductTab = useCallback(async () => {
    if (cartItems.length === 0) {
      enqueueSnackbar(serviceOrderMessages.productStep.emptyCart, {
        variant: "info",
        autoHideDuration: 3000,
      });
    } else {
      setActiveStep(financialTab);
    }
  }, [cartItems, enqueueSnackbar]);

  const getOrderTotal = useCallback((items) => {
    let total = 0;
    items.forEach((item) => {
      total += item.finalValue;
    });
    return total;
  }, []);

  const validateFinancialTab = useCallback(() => {
    const { isValid, message } = installmentsValidation;
    if (isValid) {
      setActiveStep(reviewTab);
    } else {
      enqueueSnackbar(message, {
        variant: "info",
        autoHideDuration: 3000,
      });
    }
  }, [installmentsValidation, enqueueSnackbar]);

  const getOrderTotalWithoutProductDiscounts = useCallback((items) => {
    let total = 0;
    items.forEach((item) => {
      total += item.saleValue * item.quantity;
    });
    return total;
  }, []);

  const handleChangeOrder = useCallback((field, value) => {
    setOrder((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  }, []);

  const handleChangeExtras = useCallback((field, value) => {
    setOrder((prevState) => ({
      ...prevState,
      extraFields: {
        ...prevState.extraFields,
        [field]: value,
      },
    }));
  }, []);

  const handleChangeGeneralValues = useCallback(
    (total, discount) => {
      const received = total;
      let difference = received - total;
      difference = difference < 0 ? 0 : difference;

      setOrder((prevState) => ({
        ...prevState,
        total,
        difference,
        received,
        discount,
      }));
      setIsRecalculateInstallments(true);
    },
    [setIsRecalculateInstallments]
  );

  const handleChangeReceivedValue = useCallback(
    (received) => {
      const { total } = order;
      const difference = (received || 0) - total;
      setOrder((prevState) => ({
        ...prevState,
        received,
        difference: difference < 0 ? 0 : difference,
      }));
    },
    [order]
  );

  const handleChangePaymentMethod = useCallback(
    (paymentMethod) => {
      // const { allowsDifference } = paymentMethod;

      setOrder((prevState) => ({
        ...prevState,
        // received: allowsDifference ? prevState.received : 0,
        // difference: allowsDifference ? prevState.difference : 0,
        paymentMethod,
        installments: [],
        numberInstallments: paymentMethod.allowsInstallments ? 1 : null,
      }));
      setIsRecalculateInstallments(true);
    },
    [setIsRecalculateInstallments]
  );

  const handleChangeNumberInstallments = useCallback((numberInstallments) => {
    setOrder((prevState) => ({
      ...prevState,
      numberInstallments,
    }));
  }, []);

  const handleChangeFinancialAccounts = useCallback((financialAccount) => {
    setOrder((prevState) => ({
      ...prevState,
      financialAccount,
    }));
  }, []);

  const fetchFinancialAccounts = useCallback(async () => {
    const response = await FinancialAccountsService.fetchActives();
    return response?.data;
  }, []);

  const fetchPaymentMethods = useCallback(async () => {
    const payments = await PaymentMethodService.fetchActives();
    return payments?.data;
  }, []);

  const fetchById = useCallback(async () => {
    try {
      setLoading(true);
      const { id } = routerParams || {};
      const resultPaymentMethods = await fetchPaymentMethods();
      setPaymentMethods(resultPaymentMethods);

      const resultFinancialAccounts = await fetchFinancialAccounts();
      setFinancialAccounts(resultFinancialAccounts);

      if (id != null) {
        const result = await OrderService.fetchById(id);

        const resultResponse = fromResponse(result?.data?.data);
        const { items, installments: installs } = resultResponse;

        setOrder({
          ...resultResponse,
        });

        setCartItems(items);
        setInstallments(installs);
      } else {
        const user = {
          id: userLogged?.userId,
          name: userLogged?.name,
        };
        let defaultPMethod = resultPaymentMethods.find((item) => item.standard);
        if (defaultPMethod === undefined) {
          defaultPMethod = resultPaymentMethods.find((item) => item.name === "Dinheiro");
        }
        if (defaultPMethod === undefined) {
          [defaultPMethod] = resultPaymentMethods;
        }

        const defaultFinancialAccount = resultFinancialAccounts.find((item) => item.standard);

        setOrder((prevState) => ({
          ...prevState,
          user,
          expirationDate,
          paymentMethod: defaultPMethod,
          financialAccount: defaultFinancialAccount,
          extraFields: {},
        }));
      }
    } finally {
      setLoading(false);
    }
  }, [
    routerParams,
    fetchPaymentMethods,
    fetchFinancialAccounts,
    userLogged?.userId,
    userLogged?.name,
  ]);

  const fetchDefaultCustomer = useCallback(async () => {
    const result = await PersonService.fetchStandard(RegistrationType.CUSTOMER.code);
    setDefaultCustomer(result.data);
  }, []);

  useEffect(() => {
    if (defaultCustomer) {
      setOrder((prevState) => ({
        ...prevState,
        customer: defaultCustomer,
      }));
    }
  }, [defaultCustomer]);

  useEffect(() => {
    fetchById();
    fetchDefaultCustomer();
  }, [fetchById, fetchDefaultCustomer]);

  const handleNextStep = useCallback(() => {
    if (activeStep === generalTab) {
      validateGeneralTab();
    } else if (activeStep === productsTab) {
      validateProductTab();
    } else if (activeStep === financialTab) {
      validateFinancialTab();
    } else {
      setActiveStep(activeStep + 1);
    }
  }, [activeStep, validateGeneralTab, validateProductTab, validateFinancialTab]);

  const handleBackStep = useCallback(() => {
    setActiveStep(activeStep - 1);
  }, [activeStep]);

  const handleChangeCartItems = useCallback(
    (items) => {
      setCartItems(items);
      setIsRecalculateInstallments(true);
    },
    [setCartItems, setIsRecalculateInstallments]
  );

  const renderStep = useCallback(() => {
    switch (activeStep) {
      case generalTab:
        return (
          <ServiceOrderGeneralStep
            onChange={handleChangeOrder}
            onChangeExtras={handleChangeExtras}
            onChangePaymentMethod={handleChangePaymentMethod}
            order={order}
            paymentMethods={paymentMethods}
            errors={errors}
          />
        );
      case productsTab:
        return (
          <ServiceOrderProductStep
            onChange={handleChangeCartItems}
            onChangeChangeGeneralValues={handleChangeGeneralValues}
            getOrderTotal={getOrderTotal}
            cartItems={cartItems}
            order={order}
          />
        );
      case financialTab:
        return (
          <Installments
            errors={errors}
            isRecalculate={isRecalculateInstallments}
            orderTotal={order.total}
            received={order.received}
            difference={order.difference}
            installments={installments}
            paymentMethod={order.paymentMethod}
            numberInstallments={order.numberInstallments}
            paymentMethods={paymentMethods}
            readOnly={false}
            typeOrigin="OS"
            financialAccounts={financialAccounts}
            financialAccount={order.financialAccount}
            onChange={setInstallments}
            setValidation={setInstallmentsValidation}
            onChangePaymentMethod={handleChangePaymentMethod}
            onChangeNumberInstallments={handleChangeNumberInstallments}
            onChangeReceivedValue={handleChangeReceivedValue}
            onChangeFinancialAccount={handleChangeFinancialAccounts}
          />
        );
      default:
        return (
          <ServiceOrderReviewStep
            order={order}
            items={cartItems}
            getOrderTotalWithoutProductDiscounts={getOrderTotalWithoutProductDiscounts}
          />
        );
    }
  }, [
    activeStep,
    handleChangeOrder,
    handleChangeExtras,
    handleChangePaymentMethod,
    order,
    paymentMethods,
    errors,
    handleChangeCartItems,
    handleChangeGeneralValues,
    getOrderTotal,
    cartItems,
    isRecalculateInstallments,
    installments,
    financialAccounts,
    handleChangeNumberInstallments,
    handleChangeReceivedValue,
    handleChangeFinancialAccounts,
    getOrderTotalWithoutProductDiscounts,
  ]);

  const renderButtons = useCallback(() => {
    if (
      readOnly &&
      order.orderType !== OrderType.NFE.code &&
      order.orderType !== OrderType.NFCE.code
    ) {
      return (
        <>
          <div style={{ width: "100%" }} />
          {!order.cancelled && (
            <Button color="primary" onClick={onCancel}>
              {serviceOrderMessages.buttons.cancel}
            </Button>
          )}
          {order.orderType === OrderType.BUDGET.code && !order.cancelled && (
            <Button color="primary" onClick={() => beforeSave(OrderType.SERVICE_ORDER.code, true)}>
              {serviceOrderMessages.buttons.finish}
            </Button>
          )}
          {order.orderType === OrderType.SERVICE_ORDER.code && !order.cancelled && (
            <>
              {renderConverterNotaFiscalButton(NFModelo.NFE)}
              {renderConverterNotaFiscalButton(NFModelo.NFCE)}
            </>
          )}
          <Button color="primary" onClick={() => onPrint(false)}>
            {serviceOrderMessages.buttons.print}
          </Button>
          <Button color="primary" onClick={goBack}>
            {serviceOrderMessages.buttons.back}
          </Button>
        </>
      );
    }
    if (
      readOnly &&
      (order.orderType === OrderType.NFE.code || order.orderType === OrderType.NFCE.code)
    ) {
      return (
        <>
          <div style={{ width: "100%" }} />
          <Button
            color="primary"
            onClick={() =>
              history.push(
                `/app/${order?.orderType?.toLowerCase()}-emissao/${order?.notaFiscal?.id}`
              )
            }
          >
            Ver nota Fiscal
          </Button>
          <Button color="primary" onClick={goBack}>
            {serviceOrderMessages.buttons.back}
          </Button>
        </>
      );
    }
    if (activeStep === generalTab) {
      return (
        <>
          <div style={{ width: "100%" }} />
          <Button color="primary" onClick={handleNextStep}>
            {serviceOrderMessages.buttons.next}
          </Button>
        </>
      );
    }
    if (activeStep === reviewTab) {
      return (
        <>
          <div>
            <Button color="primary" onClick={handleBackStep}>
              {serviceOrderMessages.buttons.back}
            </Button>
          </div>
          <div style={{ display: "flex" }}>
            {!order.cancelled && (
              <Button color="primary" onClick={onCancel}>
                {serviceOrderMessages.buttons.cancel}
              </Button>
            )}
            {order.id != null && (
              <Button color="primary" onClick={() => onPrint(true)}>
                {serviceOrderMessages.buttons.print}
              </Button>
            )}
            <Button
              color="primary"
              onClick={() => beforeSave(order?.orderType || OrderType.BUDGET.code)}
            >
              {serviceOrderMessages.buttons.save}
            </Button>
            {/* {order.orderType === OrderType.BUDGET.code && ( */}
            <Button color="primary" onClick={() => beforeSave(OrderType.SERVICE_ORDER.code, true)}>
              {serviceOrderMessages.buttons.saveFinish}
            </Button>
            {/* )} */}
          </div>
        </>
      );
    }
    return (
      <>
        <Button color="primary" onClick={handleBackStep}>
          {serviceOrderMessages.buttons.back}
        </Button>
        <Button color="primary" onClick={handleNextStep}>
          {serviceOrderMessages.buttons.next}
        </Button>
      </>
    );
  }, [
    readOnly,
    order.orderType,
    order.cancelled,
    order?.notaFiscal?.id,
    order.id,
    activeStep,
    handleBackStep,
    handleNextStep,
    onCancel,
    renderConverterNotaFiscalButton,
    goBack,
    beforeSave,
    onPrint,
    history,
  ]);

  return (
    <>
      <GridContainer style={{ justifyContent: "center" }}>
        <GridItem xs={12} sm={12} md={12}>
          <Card className="gridLayoutCard">
            <CardHeader color="primary">
              <h4 className={classesCardStyle.cardTitle}>{serviceOrderMessages.orderBudget}</h4>
              <p className={classesCardStyle.cardCategory}>
                Cadastro de novos orçamentos/ordem de serviço
              </p>
            </CardHeader>
            <CardBody>
              <GridContainer>
                {readOnly ? (
                  <GridItem xs={12} sm={12} md={12}>
                    <ServiceOrderReviewStep
                      order={order}
                      items={cartItems}
                      getOrderTotalWithoutProductDiscounts={getOrderTotalWithoutProductDiscounts}
                    />
                  </GridItem>
                ) : (
                  <GridItem xs={12} sm={12} md={12}>
                    <Stepper nonLinear activeStep={activeStep}>
                      {steps.map((label) => (
                        <Step key={label}>
                          <StepLabel>{label}</StepLabel>
                        </Step>
                      ))}
                    </Stepper>
                    {renderStep()}
                  </GridItem>
                )}
              </GridContainer>
            </CardBody>
            <CardFooter>{renderButtons()}</CardFooter>
          </Card>
        </GridItem>
        {renderOrderNumberAlert()}
        <Loading loading={loading} />
        <ConfirmDialog />
      </GridContainer>
    </>
  );
}
