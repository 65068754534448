class NOExceptionsUtil {
  updateExceptionList = (exception, exceptions) => {
    const newExcecoes = [...exceptions];
    const index = newExcecoes.findIndex((item) => item.index === exception.index);
    if (index >= 0) {
      newExcecoes[index] = exception;
    } else {
      newExcecoes.push(exception);
    }
    return newExcecoes;
  };

  deleteException = (index, exceptions) => {
    const newExcecoes = [...exceptions];
    const i = newExcecoes.findIndex((item) => item.index === index);
    newExcecoes.splice(i, 1);
    return newExcecoes;
  };
}

export default new NOExceptionsUtil();
