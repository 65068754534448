/* eslint-disable react/prop-types */
import React, { useCallback } from "react";
import moment from "moment";
import GridContainer from "components/Grid/GridContainer";
import { faBell } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  List,
  ListItem,
  Box,
  Divider,
  Typography,
} from "@mui/material";
import { primaryColor } from "assets/jss/material-dashboard-react";
import TextInput from "components/textInput/TextInput";
import GridItem from "components/Grid/GridItem";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from "@mui/lab";
import {
  NFeInutilizacaoStatusType,
  NotaFiscalHistoricoTipoEventoType,
} from "views/nfeInutilizacao/model/NFeInutilizacao";

const NFeInutilizacaoHistorico = ({ open, onClose, items = [] }) => {
  const getTimelineDot = (situacao) => {
    switch (situacao) {
      case NFeInutilizacaoStatusType.CRIADA.code:
        return <TimelineDot style={{ backgroundColor: "#1186e3" }} />;
      case NFeInutilizacaoStatusType.TRANSMITIDA.code:
        return <TimelineDot style={{ backgroundColor: "#0e850b" }} />;
      case NFeInutilizacaoStatusType.ERRO.code:
        return <TimelineDot style={{ backgroundColor: "#e53935" }} />;
      default:
        return <TimelineDot />;
    }
  };

  const renderTimeline = (item) => (
    <Timeline>
      <TimelineItem>
        <TimelineOppositeContent color="textSecondary">
          {moment(item.dataHora).format("DD/MM/yyyy HH:mm")}
        </TimelineOppositeContent>
        <TimelineSeparator>
          {getTimelineDot(item.situacao)}
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          <Typography style={{ fontSize: 18, fontWeight: 500 }}>
            {NotaFiscalHistoricoTipoEventoType[item.evento].name}
          </Typography>
          <Typography>{item.mensagem}</Typography>
        </TimelineContent>
      </TimelineItem>
    </Timeline>
  );

  return (
    <>
      <Dialog disableEscapeKeyDown open={open} fullWidth maxWidth="sm">
        <DialogTitle>Histórico de inutilização</DialogTitle>
        <DialogContent>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              {items.map((item) => renderTimeline(item))}
            </GridItem>
          </GridContainer>
        </DialogContent>
        <DialogActions>
          <div style={{ flexGrow: 1, display: "flex", justifyContent: "space-between" }}>
            <Button style={{ color: primaryColor[0] }} onClick={onClose}>
              Voltar
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default React.memo(NFeInutilizacaoHistorico);
