import React, { useState, useCallback, useEffect, useContext } from "react";
// https://jquense.github.io/react-big-calendar/examples/index.html
import { Calendar as CalendarUI, momentLocalizer } from "react-big-calendar";
import style from "react-big-calendar/lib/css/react-big-calendar.css";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import "moment/locale/pt-br";
import { useSnackbar } from "notistack";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@mui/material";
import CardStyle from "assets/jss/material-dashboard-react/components/cardStyle";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import { DateTimePicker } from "@mui/x-date-pickers";
import useConfirmDialog from "components/confirm/ConfirmDialog";
import TextInput from "../../components/textInput/TextInput";
import Loading from "../../components/loading/Loading";
import { primaryColor } from "../../assets/jss/material-dashboard-react";
import MESSAGES from "../../config/messages";
import Calendar from "./model/Calendar";
import CalendarService from "./service/CalendarService";
import AddButton from "../../components/addButton/AddButton";
import DeleteIcon from "../../components/icons/DeleteIcon";
import UserContext from "../../core/UserContext";

import { handlingResponseErrors } from "../../config/util";

import { toRequest, toCalendar, toReactCalendar } from "./converter/CalendarConverter";

const useCardStyle = makeStyles(CardStyle);
const { calendarMessages, generalMessages } = MESSAGES;

const localizer = momentLocalizer(moment);

const messages = {
  allDay: "Dia Inteiro",
  previous: "Anterior",
  next: "Próximo",
  today: "Hoje",
  month: "Mês",
  week: "Semana",
  day: "Dia",
  agenda: "Agenda",
  date: "Data",
  time: "Hora",
  event: "Evento",
  showMore: (total) => `+ (${total}) Eventos`,
};

const ColoredDateCellWrapper = ({ children }) =>
  React.cloneElement(React.Children.only(children), {
    style: {
      backgroundColor: "lightblue",
    },
  });

export default function CalendarComponent() {
  const { userLogged } = useContext(UserContext);
  const { showConfirmDialog, closeConfirmDialog, ConfirmDialog } = useConfirmDialog();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const [calendar, setCalendar] = useState(new Calendar());
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const classesuseCardStyle = useCardStyle();
  const [errors, setErrors] = useState({});

  const handleOpenDialog = (slot) => {
    const now = moment();
    const slotStartDate = moment(slot.start);
    const slotEndDate = moment(slot.start);
    slotStartDate.set({
      hour: now.hours(),
      minute: now.minutes(),
      second: 0,
      millisecond: 0,
    });

    slotEndDate.set({
      hour: now.hours(),
      minute: now.minutes(),
      second: 0,
      millisecond: 0,
    });

    slotEndDate.add(1, "hours");

    const newEvent = new Calendar({
      startDate: slotStartDate,
      endDate: slotEndDate,
    });

    setCalendar(newEvent);
    setErrors({});
    setOpen(true);
  };

  const handleEvent = (event) => {
    setCalendar(toCalendar(event));
    setErrors({});
    setOpen(true);
  };

  const handleChange = useCallback(
    (field, value) => {
      setCalendar({
        ...calendar,
        [field]: value,
      });
    },
    [calendar]
  );

  const fetchAll = useCallback(async () => {
    setLoading(true);
    try {
      const result = await CalendarService.fetchAll();
      const { data } = result;
      let { content } = data;
      content = content.map((item) => toReactCalendar(item));
      setList(content);
    } catch (error) {
      enqueueSnackbar(generalMessages.error, {
        variant: "error",
      });
    } finally {
      setLoading(false);
    }
  }, [enqueueSnackbar]);

  const onDelete = useCallback(async () => {
    showConfirmDialog({
      title: "Evento",
      text: generalMessages.deleteConfirm,
      onClickConfirm: async () => {
        try {
          await CalendarService.delete(calendar.id);
          enqueueSnackbar(generalMessages.deleteSuccess, {
            variant: "success",
          });
          setOpen(false);
          fetchAll();
        } catch (error) {
          enqueueSnackbar(generalMessages.error, {
            variant: "error",
          });
        }
        closeConfirmDialog();
      },
    });
  }, [showConfirmDialog, closeConfirmDialog, calendar.id, enqueueSnackbar, fetchAll]);

  const onSave = useCallback(async () => {
    try {
      setLoading(true);
      const { id } = calendar;
      const request = {
        ...calendar,
        userId: userLogged.userId,
      };
      if (id != null) {
        await CalendarService.update(id, toRequest(request));
      } else {
        await CalendarService.save(toRequest(request));
      }

      setOpen(false);

      enqueueSnackbar(generalMessages.saveSuccess, {
        variant: "success",
        autoHideDuration: 3000,
      });
    } catch (error) {
      const { message, validationErrors } = handlingResponseErrors(error);
      enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
      if (validationErrors != null) {
        setErrors(validationErrors);
      }
    } finally {
      setLoading(false);
      fetchAll();
    }
  }, [calendar, enqueueSnackbar, fetchAll, userLogged]);

  useEffect(() => {
    fetchAll();
  }, [fetchAll]);

  const focusInputField = useCallback((input) => {
    if (input) {
      setTimeout(() => {
        input.focus();
      }, 100);
    }
  }, []);

  const renderModal = () => (
    <Dialog disableEscapeKeyDown open={open}>
      <DialogTitle>
        <div style={{ display: "flex", flexGrow: 1, justifyContent: "space-between" }}>
          {calendarMessages.title}
          {calendar.id && <DeleteIcon onClick={() => onDelete()} />}
        </div>
      </DialogTitle>
      <DialogContent>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <TextInput
              inputRef={focusInputField}
              // autoFocus
              id="name"
              label={calendarMessages.name}
              value={calendar.name}
              onChange={handleChange}
              required
              errors={errors}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={6} style={{ marginTop: "12px" }}>
            <DateTimePicker
              id="startDate"
              label={calendarMessages.startDate}
              format="dd/MM/yyyy HH:mm"
              slotProps={{ textField: { size: "small" } }}
              value={calendar?.startDate ? moment(calendar?.startDate).valueOf() : null}
              helperText={errors.commissionDate}
              onChange={(date) => handleChange("startDate", moment(date))}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={6} style={{ marginTop: "12px" }}>
            <DateTimePicker
              id="startDate"
              label={calendarMessages.endDate}
              format="dd/MM/yyyy HH:mm"
              slotProps={{ textField: { size: "small" } }}
              value={calendar?.endDate ? moment(calendar?.endDate).valueOf() : null}
              helperText={errors.commissionDate}
              onChange={(date) => handleChange("endDate", moment(date))}
            />
          </GridItem>
        </GridContainer>
      </DialogContent>
      <DialogActions>
        <div style={{ flexGrow: 1, display: "flex", justifyContent: "space-between" }}>
          <Button style={{ color: primaryColor[0] }} onClick={() => setOpen(false)} autoFocus>
            {calendarMessages.cancel}
          </Button>
          {/* {calendar.id && (
            <Button style={{ color: primaryColor[0] }} onClick={onDelete}>
              {calendarMessages.delete}
            </Button>
          )} */}
          <Button style={{ color: primaryColor[0] }} onClick={onSave}>
            {calendarMessages.confirm}
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );

  return (
    <GridContainer style={{ justifyContent: "center" }}>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary">
            <h4 className={classesuseCardStyle.cardTitleList}>Agenda</h4>
            <p className={classesuseCardStyle.cardCategoryList}>Lista de agendamentos</p>
          </CardHeader>

          <CardBody>
            <div style={{ height: 600, padding: 10 }}>
              <CalendarUI
                style={{ ...style }}
                messages={messages}
                events={list}
                views={["day", "month"]}
                // views={["month"]}
                timeslots={8}
                step={60}
                showMultiDayTimes
                defaultDate={new Date()}
                components={{
                  timeSlotWrapper: ColoredDateCellWrapper,
                }}
                localizer={localizer}
                selectable
                onSelectSlot={handleOpenDialog}
                onSelectEvent={handleEvent}
                eventPropGetter={(event) => ({
                  style: {
                    backgroundColor: primaryColor[0],
                    borderRadius: "8px",
                    minHeight: "10px",
                  },
                })}
              />
              {renderModal()}
            </div>
            <AddButton onClick={handleOpenDialog} />
            <Loading loading={loading} />
          </CardBody>
        </Card>
      </GridItem>
      <ConfirmDialog />
    </GridContainer>
  );
}
