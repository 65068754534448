/* eslint-disable no-alert */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileContract as faFile } from "@fortawesome/free-solid-svg-icons";
import { Box } from "@mui/material";
import { useDropzone } from "react-dropzone";
import DeleteIcon from "components/icons/DeleteIcon";
import useConfirmDialog from "components/confirm/ConfirmDialog";

const KairumDropzone = ({
  title,
  textDropzone,
  handleChange,
  onDelete,
  item,
  error,
  fileTypes,
}) => {
  const { showConfirmDialog, closeConfirmDialog, ConfirmDialog } = useConfirmDialog();
  const onDrop = useCallback(
    (acceptedFiles) => {
      handleChange(acceptedFiles?.[0] || null);
    },
    [handleChange]
  );

  const { getRootProps, getInputProps } = useDropzone({ onDrop, accept: fileTypes });

  const handleDelete = useCallback(() => {
    showConfirmDialog({
      title: "Certificado",
      text: "Tem certeza de que deseja remover o arquivo? Esta ação é irreversível!",
      onClickConfirm: () => {
        onDelete();
        closeConfirmDialog();
      },
    });
  }, [closeConfirmDialog, onDelete, showConfirmDialog]);

  return (
    <Box>
      <p>{title}</p>
      {item?.id == null && (
        <Box>
          <div
            {...getRootProps({ className: "dropzone" })}
            style={{ borderColor: error ? "#f44336" : "#eeeeee" }}
          >
            <input {...getInputProps()} />
            <b>{textDropzone}</b>
          </div>
        </Box>
      )}

      {item != null && (
        <Box
          display="flex"
          justifyContent="space-between"
          style={{ padding: 10, backgroundColor: "#edf4ff", borderRadius: 4 }}
        >
          <Box>
            <FontAwesomeIcon
              icon={faFile}
              fontSize={20}
              color="#686868"
              style={{ marginRight: 4 }}
            />
            {item?.name}
          </Box>
          <DeleteIcon onClick={handleDelete} style={{ cursor: "pointer" }} />
        </Box>
      )}
      <ConfirmDialog />
    </Box>
  );
};

export default KairumDropzone;
