/* eslint-disable react/prop-types */
import React from "react";
import { makeStyles } from "@mui/styles";
import CustomComponentsStyle from "assets/jss/material-dashboard-react/components/customComponentsStyle";
import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import GridItem from "components/Grid/GridItem";
import Autocomplete from "components/autocomplete/Autocomplete";
import TextInput from "components/textInput/TextInput";
import CurrencyTextInput from "components/textInput/CurrencyTextInput";
import MESSAGES from "config/messages";
import GridContainer from "components/Grid/GridContainer";
import CodigoEnquadramentoIpiService from "views/codigoEnquadramentoIpi/service/CodigoEnquadramentoIpiService";
import { SituacaoTributariaIpi, TipoCalculoNFeType } from "../model/NaturezaOperacao";
import SelectTipoCalculo from "./SelectTipoCalculo";

const { naturezaOperacaoMessages } = MESSAGES;
const CHARACTER_LIMIT = 4000;

const NOIpiTab = ({ data, handleChange, errors = {} }) => {
  const useCustomComponentsStyle = makeStyles(CustomComponentsStyle);
  const classesCustomComponentsStyle = useCustomComponentsStyle();

  const {
    situacaoTributariaIpi,
    aliquotaIpi,
    codigoEnquadramentoIpi,
    observacaoIpi,
    tipoCalculoIpi,
    valorIpi,
  } = data;

  const renderSituacaoTributariaIpi = () => (
    <GridItem xs={12} sm={12} md={6}>
      <FormControl
        fullWidth
        className={classesCustomComponentsStyle.formControlSelect}
        size="small"
        variant="outlined"
      >
        <InputLabel>{naturezaOperacaoMessages.ipi.situacaoTributariaIpi}</InputLabel>
        <Select
          label={naturezaOperacaoMessages.ipi.situacaoTributariaIpi}
          id="situacaoTributariaIpi"
          autoWidth
          value={
            situacaoTributariaIpi == null
              ? SituacaoTributariaIpi.SELECIONE.value
              : situacaoTributariaIpi
          }
          onChange={(event) => {
            const {
              target: { value },
            } = event;
            return handleChange(
              "situacaoTributariaIpi",
              value === SituacaoTributariaIpi.SELECIONE.value ? null : value
            );
          }}
          error={errors.situacaoTributariaIpi}
          helperText={naturezaOperacaoMessages.ipi.situacaoTributariaIpi}
        >
          {Object.keys(SituacaoTributariaIpi).map((key) => {
            const { code, name, value } = SituacaoTributariaIpi[key];
            return (
              <MenuItem key={code} value={value}>
                {code} - {name}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </GridItem>
  );

  const renderAliquotaIpi = () => (
    <GridItem xs={8} sm={8} md={3}>
      <CurrencyTextInput
        currencySymbol="%"
        id="aliquotaIpi"
        label={naturezaOperacaoMessages.ipi.aliquotaIpi}
        value={aliquotaIpi || "0"}
        onChange={handleChange}
        errors={errors}
        required
      />
    </GridItem>
  );

  const renderValorIpi = () => (
    <GridItem xs={8} sm={8} md={3}>
      <CurrencyTextInput
        currencySymbol="%"
        id="valorIpi"
        label={naturezaOperacaoMessages.ipi.valorIpi}
        value={valorIpi || "0"}
        onChange={handleChange}
        errors={errors}
        required
      />
    </GridItem>
  );

  const renderCodigoEnquadramentoIpi = () => (
    <GridItem xs={12} sm={12} md={6}>
      <Box display="flex" flexGrow={1}>
        <Autocomplete
          freeSolo
          autoSelect
          width="100%"
          variant="outlined"
          fieldsToShowAfterSelectItem={["codigo", "descricao"]}
          label={naturezaOperacaoMessages.ipi.codigoEnquadramentoIpi}
          placeholder={naturezaOperacaoMessages.ipi.autocompleteCodigoEnquadramentoIpi}
          service={CodigoEnquadramentoIpiService}
          value={codigoEnquadramentoIpi}
          onChange={(value) => {
            if (typeof value === "string" || value == null) {
              handleChange("codigoEnquadramentoIpi", value);
            } else {
              handleChange("codigoEnquadramentoIpi", value.codigo);
            }
          }}
        />
      </Box>
    </GridItem>
  );

  const renderObservacaoIpi = () => (
    <GridItem xs={12} sm={12} md={12}>
      <TextInput
        id="observacaoIpi"
        label={naturezaOperacaoMessages.ipi.observacaoIpi}
        value={observacaoIpi}
        maxLength={CHARACTER_LIMIT}
        onChange={handleChange}
        isCounter
        multiline
        rows={4}
      />
    </GridItem>
  );

  const renderTipoCalculo = () => (
    <GridItem xs={8} sm={8} md={3}>
      <SelectTipoCalculo
        field="tipoCalculoIpi"
        valueSelected={tipoCalculoIpi}
        handleChange={handleChange}
      />
    </GridItem>
  );

  const renderBySituacaoTributariaIpi = () => {
    const code = parseInt(SituacaoTributariaIpi[situacaoTributariaIpi]?.code || -1, 10);

    if (code === 0 || code === 49 || code === 50 || code === 99) {
      return [
        renderCodigoEnquadramentoIpi(),
        renderTipoCalculo(),
        tipoCalculoIpi === TipoCalculoNFeType.VALOR.code ? renderValorIpi() : renderAliquotaIpi(),
        renderObservacaoIpi(),
      ];
    }

    if ((code >= 1 && code <= 5) || (code >= 51 && code <= 55)) {
      return [renderCodigoEnquadramentoIpi()];
    }

    return <Box />;
  };

  return (
    <GridContainer>
      {renderSituacaoTributariaIpi()}
      {renderBySituacaoTributariaIpi()}
    </GridContainer>
  );
};

export default React.memo(NOIpiTab);
