import React, { useState, useEffect, useCallback, useRef } from "react";
import CustomComponentsStyle from "assets/jss/material-dashboard-react/components/customComponentsStyle";
import { makeStyles } from "@mui/styles";
import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import CardStyle from "assets/jss/material-dashboard-react/components/cardStyle";
import ButtonCustom from "components/CustomButtons/Button";
import TextInput from "components/textInput/TextInput";
import AccordionFilters from "components/Accordion/AccordionFilters";
import { PersonType, RegistrationType } from "./model/Person";
import PersonService from "./service/PersonService";
import MESSAGES from "../../config/messages";
import Loading from "../../components/loading/Loading";
import AddButton from "../../components/addButton/AddButton";
import { applyCnpjMask, applyCpfMask } from "../../config/util";
import EditIcon from "../../components/icons/EditIcon";
import DataGridCustom from "../../components/dataGrid/DataGridCustom";

const { generalMessages, personMessages } = MESSAGES;

const SearchAll = {
  All: {
    code: "ALL",
    name: "Todos",
  },
};

const useCardStyle = makeStyles(CardStyle);

const initialSearch = {
  registrationType: SearchAll.All.code,
  personType: SearchAll.All.code,
  term: "",
};

export default function PersonList() {
  const customGridRef = useRef();
  const classesuseCardStyle = useCardStyle();
  const useCustomComponentsStyle = makeStyles(CustomComponentsStyle);
  const classesCustomComponentsStyle = useCustomComponentsStyle();
  const history = useHistory();
  const [list, setList] = useState([]);
  const [query, setQuery] = useState(initialSearch);
  const [loading, setLoading] = useState(false);
  const [rowCount, setRowCount] = React.useState(0);
  const { enqueueSnackbar } = useSnackbar();

  const fetchAll = useCallback(
    async ({ searchParams, paginationModelParams, sorting }) => {
      setLoading(true);
      try {
        const pagination =
          paginationModelParams || customGridRef.current.getDefaultPaginationModel();

        const params = [];

        if (searchParams.term != null) {
          params.push(`term=${searchParams.term}`);
        }
        if (searchParams.registrationType !== SearchAll.All.code) {
          params.push(`registrationType=${searchParams.registrationType}`);
        }
        if (searchParams.personType !== SearchAll.All.code) {
          params.push(`personType=${searchParams.personType}`);
        }
        const result = await PersonService.fetchAll({
          params: params.join("&"),
          pagination,
          sorting,
        });
        const { data } = result;
        const { content, totalElements } = data;

        setRowCount(totalElements);
        setList(content);
      } catch (error) {
        enqueueSnackbar(generalMessages.error, {
          variant: "error",
        });
      } finally {
        setLoading(false);
      }
    },
    [enqueueSnackbar]
  );

  useEffect(() => {
    fetchAll({ searchParams: initialSearch });
  }, [fetchAll]);

  const handleChange = useCallback(
    (field, value) => {
      setQuery({
        ...query,
        [field]: value,
      });
    },
    [query]
  );

  const goToForm = (params) => {
    const { id, standard } = params;
    if (id !== null) {
      if (!standard) {
        return params?.id ? history.push(`/app/person/${params?.id}`) : history.push("/app/person");
      }
      // eslint-disable-next-line no-alert
      alert("Não é possível editar o cliente Consumidor!");
    } else {
      return history.push("/app/person");
    }
    return false;
  };

  const formatterDoc = (item) => {
    const { personType: pt, cpf, cnpj } = item;

    if (pt === PersonType.PF.code) {
      return applyCpfMask(cpf);
    }
    if (pt === PersonType.PJ.code) {
      return applyCnpjMask(cnpj);
    }

    return "";
  };

  const formatterRegistrationType = (item) => {
    const { registrationType: rt } = item;
    return rt.map((r) => RegistrationType[r].name).join(", ");
  };

  const getColumns = () => {
    const columns = [
      {
        field: "code",
        headerName: generalMessages.code,
        flex: 1,
        minWidth: 120,
      },
    ];

    columns.push({
      field: "name",
      headerName: personMessages.name,
      minWidth: 250,
      flex: 2,
    });

    columns.push({
      field: "document",
      headerName: "Documento",
      minWidth: 150,
      flex: 2,
      renderCell: ({ row: item }) => formatterDoc(item),
    });

    columns.push({
      field: "personType",
      headerName: "Tipo de pessoa",
      minWidth: 150,
      flex: 2,
      valueGetter: ({ value }) => PersonType[value].name,
    });

    columns.push({
      field: "registrationType",
      headerName: "Tipo de cadastro",
      minWidth: 250,
      flex: 3,
      renderCell: ({ row: item }) => formatterRegistrationType(item),
    });

    columns.push({
      field: "active",
      headerName: "Ativo",
      minWidth: 120,
      flex: 2,
      valueGetter: ({ value }) => (value ? "Sim" : "Não"),
    });

    columns.push({
      field: "actions",
      headerName: generalMessages.actions,
      sortable: false,
      width: 100,
      disableClickEventBubbling: true,
      renderCell: (params) => (
        <div
          style={{
            cursor: "pointer",
            justifyContent: "center",
            padding: "10px",
            display: "flex",
            flex: 1,
          }}
        >
          {params.row.standard === true ? (
            <></>
          ) : (
            <>
              <EditIcon onClick={() => goToForm({ id: params.row.id, standard: false })} />
            </>
          )}
        </div>
      ),
    });

    return columns;
  };

  const renderRegistrationType = () => (
    <FormControl
      fullWidth
      className={classesCustomComponentsStyle.formControlSelect}
      size="small"
      variant="outlined"
    >
      <InputLabel>Tipo de cadastro</InputLabel>
      <Select
        label="Tipo de cadastro"
        autoWidth
        value={query.registrationType}
        onChange={(event) => {
          const {
            target: { value },
          } = event;
          return handleChange("registrationType", value);
        }}
      >
        <MenuItem key={SearchAll.All.code} value={SearchAll.All.code}>
          {SearchAll.All.name}
        </MenuItem>
        {Object.keys(RegistrationType).map((key) => {
          const { code, name } = RegistrationType[key];
          return (
            <MenuItem key={code} value={code}>
              {name}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );

  const renderPersonType = () => (
    <FormControl
      fullWidth
      className={classesCustomComponentsStyle.formControlSelect}
      size="small"
      variant="outlined"
    >
      <InputLabel>Tipo de pessoa</InputLabel>
      <Select
        label="Tipo de pessoa"
        autoWidth
        value={query.personType}
        onChange={(event) => {
          const {
            target: { value },
          } = event;
          return handleChange("personType", value);
        }}
      >
        <MenuItem key={SearchAll.All.code} value={SearchAll.All.code}>
          {SearchAll.All.name}
        </MenuItem>
        {Object.keys(PersonType).map((key) => {
          const { code, name } = PersonType[key];
          return (
            <MenuItem key={code} value={code}>
              {name}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );

  const onSearch = useCallback(() => {
    fetchAll({ searchParams: query });
    customGridRef.current.resetPaginationModel();
  }, [fetchAll, query]);

  const onClear = useCallback(() => {
    fetchAll({ searchParams: initialSearch });
    setQuery(initialSearch);
    customGridRef.current.resetPaginationModel();
  }, [fetchAll]);

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card className="gridLayoutCard">
          <div className="gridLayoutContainer">
            <CardHeader color="primary">
              <h4 className={classesuseCardStyle.cardTitleList}>{personMessages.customers}</h4>
              <p className={classesuseCardStyle.cardCategoryList}>
                {personMessages.customersTable}
              </p>
            </CardHeader>

            <div className="gridLayoutFilters">
              <AccordionFilters screen="person_list">
                <GridContainer>
                  <GridItem xs={12} sm={12} md={5}>
                    <TextInput
                      autoFocus
                      onKeyPress={(e) => {
                        if (e.which === 13) {
                          onSearch();
                        }
                      }}
                      label="Pesquisar pessoas"
                      placeholder="Digite para pesquisar..."
                      value={query.term}
                      onChange={(ev, value) => handleChange("term", value)}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={2}>
                    {renderRegistrationType()}
                  </GridItem>
                  <GridItem xs={12} sm={12} md={2}>
                    {renderPersonType()}
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4} style={{ marginTop: 6, flexBasis: 0 }}>
                    <Box display="flex">
                      <ButtonCustom color="primary" onClick={onSearch}>
                        {generalMessages.search}
                      </ButtonCustom>
                      <ButtonCustom color="primary" onClick={onClear}>
                        Limpar
                      </ButtonCustom>
                    </Box>
                  </GridItem>
                </GridContainer>
              </AccordionFilters>
            </div>

            <div className="gridLayoutGrid">
              <DataGridCustom
                ref={customGridRef}
                onRowDoubleClick={({ row }) => goToForm(row)}
                rows={list}
                columns={getColumns()}
                usePagination
                rowCount={rowCount}
                fetchAction={(params) =>
                  fetchAll({
                    ...params,
                    searchParams: query,
                  })
                }
              />
            </div>
            <div className="gridLayoutBottom">
              <AddButton onClick={() => goToForm({ id: null, standard: false })} />
            </div>
          </div>
        </Card>
      </GridItem>
      <Loading loading={loading} />
    </GridContainer>
  );
}
