/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React from "react";
import { MenuItem, Button, Menu } from "@mui/material";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";

import ArrowDown from "components/icons/ArrowDown";

const DropDownButton = ({ label = "Opções", options = [] }) => (
  <PopupState variant="popover" popupId="dropdown-popup-menu">
    {(popupState) => (
      <>
        <Button
          style={{ textTransform: "none" }}
          variant="text"
          {...bindTrigger(popupState)}
          endIcon={<ArrowDown style={{ fontSize: 12, marginBottom: 3 }} color="#000000de" />}
        >
          {label}
        </Button>
        <Menu {...bindMenu(popupState)}>
          {options.map((option) => (
            <MenuItem
              onClick={() => {
                option.action();
                popupState.close();
              }}
            >
              {option.label}
            </MenuItem>
          ))}
        </Menu>
      </>
    )}
  </PopupState>
);

export default DropDownButton;
