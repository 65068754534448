/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import GridContainer from "components/Grid/GridContainer";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@mui/material";
import { primaryColor } from "assets/jss/material-dashboard-react";
import TextInput from "components/textInput/TextInput";
import GridItem from "components/Grid/GridItem";
import Loading from "../../../../components/loading/Loading";
import Service from "../../service/NotaFiscalService";
import { handlingResponseErrors } from "../../../../config/util";

const NotaFiscalEnvioEmailModal = ({ open, onClose, emailInit, notaFiscalId }) => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setEmail(emailInit);
  }, [emailInit]);

  const handleEmail = useCallback(
    (field, value) => {
      setEmail(value);
    },
    [setEmail]
  );

  const handleClose = useCallback(() => {
    // handleEmail("");
    onClose();
  }, [onClose]);

  const send = useCallback(async () => {
    try {
      setLoading(true);

      await Service.sendEmail(email, notaFiscalId);

      enqueueSnackbar("E-mail enviado com sucesso", {
        variant: "success",
        autoHideDuration: 3000,
      });
    } catch (error) {
      const { message } = handlingResponseErrors(error);
      enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    } finally {
      setLoading(false);
    }
  }, [email, enqueueSnackbar, notaFiscalId]);

  return (
    <>
      <Dialog disableEscapeKeyDown open={open} fullWidth maxWidth="sm">
        <DialogTitle>Envio da NFe para o E-mail</DialogTitle>
        <DialogContent>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <TextInput
                autoFocus
                id="email"
                label="E-mail"
                value={email}
                onChange={handleEmail}
                maxLength={256}
                minLength={15}
                required
              />
            </GridItem>
          </GridContainer>
        </DialogContent>
        <DialogActions>
          <div style={{ flexGrow: 1, display: "flex", justifyContent: "space-between" }}>
            <Button style={{ color: primaryColor[0] }} onClick={handleClose}>
              Voltar
            </Button>
            <Button style={{ color: primaryColor[0] }} onClick={send}>
              Enviar
            </Button>
          </div>
        </DialogActions>
      </Dialog>
      <Loading loading={loading} />
    </>
  );
};

export default React.memo(NotaFiscalEnvioEmailModal);
