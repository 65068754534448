import moment from "moment";
import CommissionConfig from "../model/CommissionConfig";

const toRequest = (request, goals) => {
  const {
    id,
    startDate,
    endDate,
    serviceCommission,
    productCommission,
    receiptInInstallments,
    onAllSales,
    removeOnCancelOrder,
    targetCalculationMethod,
    goal,
    goalAutomaticClosing,
    goalAutomaticClosingDay,
    user,
    active,
    version,
  } = request;

  return {
    id,
    startDate: startDate ? moment(startDate).format() : null,
    endDate: endDate ? moment(endDate).format() : null,
    serviceCommission,
    productCommission,
    receiptInInstallments,
    onAllSales,
    removeOnCancelOrder,
    targetCalculationMethod,
    goal,
    goalAutomaticClosing,
    goalAutomaticClosingDay,
    goals: goals.map((item) => ({
      startTrack: item?.startTrack,
      endTrack: item?.endTrack,
      serviceCommission: item?.serviceCommission,
      productCommission: item?.productCommission
    })),
    user,
    active,
    version,
  };
};

const fromResponse = (response) => {
  if (response === null) return new CommissionConfig();

  const {
    id,
    startDate,
    endDate,
    serviceCommission,
    productCommission,
    receiptInInstallments,
    onAllSales,
    removeOnCancelOrder,
    targetCalculationMethod,
    goal,
    goalAutomaticClosing,
    goalAutomaticClosingDay,
    goals,
    user,
    active,
    version,
  } = response;

  const goalsList = goals.map((item, index) => ({
    ...item,
    index,
  }));

  return new CommissionConfig({
    id,
    startDate,
    endDate,
    serviceCommission,
    productCommission,
    receiptInInstallments,
    onAllSales,
    removeOnCancelOrder,
    targetCalculationMethod,
    goal,
    goalAutomaticClosing,
    goalAutomaticClosingDay,
    goals: goalsList,
    user,
    active,
    version,
  });
};

export { toRequest, fromResponse };
