import axios from "axios";
import { APIS } from "../../../config/constants";
import BaseService from "../../../core/BaseService";

const { register: registerAPI } = APIS;

class ReceiveService extends BaseService {
  constructor() {
    super(registerAPI.receive);
  }

  featchReceives = ({ params, pagination, sorting }) => {
    const { page, pageSize } = pagination;
    const { field = "createdAt", sort = "desc" } = sorting || {};
    return axios.get(
      `${registerAPI.receive}/search-receives?size=${pageSize}&page=${page}&sort=${field},${sort}&${params}`
    );
  };

  featchReceivesValues = (params) =>
    axios.get(`${registerAPI.receive}/search-receives-values?${params}`);

  featchInstallments = (receiveId) =>
    axios.get(`${registerAPI.receive}/search-installments/${receiveId}`);
}

export default new ReceiveService();
