/* eslint-disable react/prop-types */
import React, { useState, useCallback, useEffect } from "react";
import { useSnackbar } from "notistack";
import moment from "moment";
import GridContainer from "components/Grid/GridContainer";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Box } from "@mui/material";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClockRotateLeft } from "@fortawesome/free-solid-svg-icons";
import InventoryMovementService from "../service/InventoryMovementService";
import { primaryColor } from "../../../assets/jss/material-dashboard-react";
import Loading from "../../../components/loading/Loading";
import { MovementType, OriginType } from "../model/InventoryProduct";
import InventoryMovementModal from "./InventoryMovementModal";
import MESSAGES from "../../../config/messages";
import DataGridCustom from "../../../components/dataGrid/DataGridCustom";
import InventoryMovementPriceChangeModal from "../../productPriceChangeHistoryService/InventoryMovementPriceChangeModal";

const { generalMessages } = MESSAGES;

const InventoryMovementHistoryModal = ({ open, onClose, item }) => {
  const { product } = item || {};
  const [list, setList] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [openNew, setOpenNew] = useState(false);
  const [openPriceChange, setOpenPriceChange] = useState(false);
  const [movementSelected, setMovementSelected] = useState({});

  const fetch = useCallback(async () => {
    setLoading(true);
    try {
      const result = await InventoryMovementService.movementByProduct(product.id);
      const { data } = result;
      setList(data?.content || []);
    } catch (error) {
      enqueueSnackbar(generalMessages.error, {
        variant: "error",
      });
    } finally {
      setLoading(false);
    }
    // }
  }, [enqueueSnackbar, product]);

  useEffect(() => {
    console.log(">>>>>>>>>>> fetch");
    fetch();
  }, [fetch]);

  const goToPriceHistory = useCallback((movement) => {
    setMovementSelected(movement);
    // setOpenPriceChange(true);
  }, []);

  const add = useCallback(() => {
    setOpenNew(true);
  }, []);

  const onCloseAddMovement = useCallback(() => {
    fetch();
    setOpenNew(false);
  }, [fetch]);

  const getColumns = () => {
    const columns = [];

    columns.push({
      field: "date",
      headerName: "Data",
      flex: 1,
      minWidth: 120,
      valueGetter: ({ value }) => moment(value).format("DD/MM/YYYY"),
    });
    columns.push({
      field: "quantity",
      headerName: "Quantidade",
      minWidth: 150,
      flex: 1,
    });

    columns.push({
      field: "type",
      headerName: "Tipo",
      minWidth: 120,
      flex: 1,
      valueGetter: ({ value }) => (value ? MovementType[value].name : ""),
    });

    columns.push({
      field: "originType",
      headerName: "Origem",
      minWidth: 120,
      flex: 1,
      valueGetter: ({ value }) => (value ? OriginType[value].name : ""),
    });

    columns.push({
      field: "actions",
      headerName: generalMessages.actions,
      sortable: false,
      width: 100,
      disableClickEventBubbling: true,
      renderCell: ({ row }) => (
        <div
          style={{
            cursor: "pointer",
            justifyContent: "flex-end",
            padding: "10px",
            display: "flex",
            flex: 1,
          }}
        >
          {row?.type !== MovementType.OUT.code && (
            <FontAwesomeIcon
              icon={faClockRotateLeft}
              onClick={() => {
                setMovementSelected(row);
                setOpenPriceChange(true);
              }}
              fontSize={20}
              color="#686868"
              style={{ padding: 6 }}
              title="Alteração de preço"
            />
          )}
        </div>
      ),
    });

    return columns;
  };

  const renderGrid = () => (
    <div
      style={{
        height: "40vh",
        width: "100%",
        marginTop: 15,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <DataGridCustom rows={list} columns={getColumns()} />
    </div>
  );

  return (
    <>
      <Dialog disableEscapeKeyDown open={open} fullWidth maxWidth="md">
        <DialogTitle>Histórico de movimentações - {product?.name || ""}</DialogTitle>
        <DialogContent>
          <Box display="flex" justifyContent="right">
            <Button style={{ color: primaryColor[0] }} onClick={add}>
              Nova movimentação
            </Button>
          </Box>
          <GridContainer>{renderGrid()}</GridContainer>
        </DialogContent>
        <DialogActions>
          <div style={{ flexGrow: 1, display: "flex", justifyContent: "space-between" }}>
            <Button style={{ color: primaryColor[0] }} onClick={onClose}>
              Voltar
            </Button>
          </div>
        </DialogActions>
      </Dialog>
      {openNew && (
        <InventoryMovementModal
          open={openNew}
          onClose={onCloseAddMovement}
          productId={product?.id}
        />
      )}
      {openPriceChange && (
        <InventoryMovementPriceChangeModal
          open={openPriceChange}
          movement={movementSelected}
          onClose={() => setOpenPriceChange(false)}
        />
      )}
      <Loading loading={loading} />
    </>
  );
};

export default React.memo(InventoryMovementHistoryModal);
