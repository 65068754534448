import axios from "axios";
import { APIS } from "../../../config/constants";
import BaseService from "../../../core/BaseService";

const { register: registerAPI } = APIS;

class InventoryMovementService extends BaseService {
  constructor() {
    super(registerAPI.inventoryMovements);
  }

  movementByProduct = (product) => axios.get(`${this.endpoint}/product/${product}?sort=date,DESC`);

  add = (request) => axios.post(`${this.endpoint}/movement`, request);
}

export default new InventoryMovementService();
