const toRequest = (document) => {
  const {
    id,
    possuiPartilhaIcmsInterestadual,
    partilhaIcmsInterestadualValores,
    version,
  } = document;

  return {
    id,
    possuiPartilhaIcmsInterestadual,
    partilhaIcmsInterestadualValores,
    version,
  };
};

export default toRequest;
