/* eslint-disable react/prop-types */
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { Fab } from "@mui/material";
import { primaryColor } from "../../assets/jss/material-dashboard-react";

const AddButton = ({ onClick, icon = faPlus, tooltip = "Novo" }) => (
  <div style={{ display: "flex", justifyContent: "flex-end", marginTop: 4 }}>
    <Fab
      style={{ backgroundColor: primaryColor[0], color: "#ffff" /* width: 48, height: 48 */ }}
      aria-label="add"
      size="small"
      onClick={onClick}
    >
      <FontAwesomeIcon icon={icon} fontSize={18} title={tooltip} />
    </Fab>
  </div>
);

export default React.memo(AddButton);
