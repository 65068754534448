/* eslint-disable react/prop-types */
import React, { useState, useEffect, useCallback, useContext, useRef } from "react";
import { useDebounce } from "use-debounce";
import { makeStyles } from "@mui/styles";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import Card from "components/Card/Card";
import Button from "components/CustomButtons/Button";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import { useSnackbar } from "notistack";
import CardStyle from "assets/jss/material-dashboard-react/components/cardStyle";
import { Divider, Grid, Typography } from "@mui/material";
import TextInput from "components/textInput/TextInput";
import UserContext from "core/UserContext";
import CardFooter from "components/Card/CardFooter";
import VirtualizedList from "components/virtualized/VirtualizedList";
import ProductService from "../product/service/ProductService";
import MESSAGES from "../../config/messages";
import { MODULES, SEARCH } from "../../config/constants";
import Loading from "../../components/loading/Loading";
import { currencyFormatter } from "../../config/util";

const useCardStyle = makeStyles(CardStyle);

const { generalMessages, productMessages } = MESSAGES;

const EMPTY = "";
const EMPTY_TEXT = `Digite ao menos ${SEARCH.MINIMUM_CHARACTERS} caracteres para pesquisar`;

// const getListRandom = () => {
//   const list = [];
//   for (let i = 0; i < 1000; i += 1) {
//     list.push({
//       name: `AAAAA${i}`,
//     });
//   }
//   return list;
// };

export default function ProductList({ modalOpen, onClose }) {
  const vlRef = useRef(null);
  const classesuseCardStyle = useCardStyle();
  const { userLogged } = useContext(UserContext);
  const [list, setList] = useState([]);
  const [item, setItem] = useState(null);
  const [searchTerm, setSearchTerm] = useState(null);
  const [searchTermDebounce] = useDebounce(searchTerm, SEARCH.WAIT_INTERVAL);

  const [barCode, setBarCode] = useState("");
  const [emptyText, setEmptyText] = useState(EMPTY_TEXT);

  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);

  const hasInventoryPermission = useCallback(() => {
    if (userLogged != null) {
      const { modules } = userLogged;
      const inventoryUrl = "/app/inventories";

      const module = MODULES[inventoryUrl];
      const hasPermission = modules.includes(module);
      return hasPermission;
    }
    return false;
  }, [userLogged]);

  useEffect(() => {
    if (list && list.length > 0) {
      const firstItem = list[0];
      setItem(firstItem);
    }
  }, [list]);

  const fetchAll = useCallback(
    async (query, isBarCode) => {
      if (query === EMPTY) {
        setItem(null);
        setList([]);
        setEmptyText(EMPTY_TEXT);
        return;
      }
      console.log("");
      if (query.length < SEARCH.MINIMUM_CHARACTERS) {
        return;
      }

      setLoading(true);
      try {
        // console.log(">>>>>>>>>>>>>>>> Price Search is executing ...");
        if (isBarCode) {
          const result = await ProductService.fetchByBarCode(query);
          const content = result?.data?.content ?? [];
          if (content.length === 0) {
            // eslint-disable-next-line no-alert
            alert("Item não encontrado.");
            setItem(null);
            setList([]);
          } else {
            // const productBarCode = content[0];
            setList(content);
            if (content?.length === 0) {
              setEmptyText(null);
            }
          }
        } else {
          const result = await ProductService.fetchAllPriceSearch(query);
          const { data } = result;
          const { content } = data;

          setList(content);
          // setList([...getListRandom()]);

          if (content?.length === 0) {
            setEmptyText(null);
          }
        }
      } catch (error) {
        enqueueSnackbar(generalMessages.error, {
          variant: "error",
        });
      } finally {
        setLoading(false);
      }
    },
    [enqueueSnackbar]
  );

  useEffect(() => {
    if (searchTermDebounce !== null) fetchAll(searchTermDebounce);
  }, [fetchAll, searchTermDebounce, modalOpen]);

  const renderInfItem = (label, value) => (
    <Grid container justifyContent="space-between" style={{ paddingBottom: 1 }}>
      <Typography inline align="left" style={{ fontSize: 16 }}>
        {label}
      </Typography>
      <Typography inline align="right" style={{ fontWeight: 500, fontSize: 16 }}>
        {value}
      </Typography>
    </Grid>
  );

  const renderInfs = () => (
    <>
      <GridContainer style={{ padding: 6 }}>
        <Divider style={{ width: "100%", marginBottom: 4 }} />
        <Grid item xs={12} sm={12} md={6} style={{ paddingRight: 12 }}>
          {renderInfItem(productMessages.industryCode, item?.industryCode || "")}
          {renderInfItem(productMessages.name, item?.name || "")}
          {renderInfItem(productMessages.supplier, item?.supplier?.name || "")}
          {renderInfItem(productMessages.brand, item?.brand || "")}

          {renderInfItem(productMessages.localization, item?.localization || "")}
          {renderInfItem(productMessages.additionalInformation, item?.additionalInformation || "")}
        </Grid>

        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          style={{ paddingLeft: 12, borderLeft: "solid 1px", borderColor: "#c2c2c2" }}
        >
          {renderInfItem(productMessages.priceMinimum, currencyFormatter(item?.priceMinimum || 0))}
          {renderInfItem(productMessages.saleValue, currencyFormatter(item?.saleValue || 0))}
          {hasInventoryPermission() && renderInfItem("Estoque", item?.inventory?.quantity || "")}
        </Grid>
      </GridContainer>
    </>
  );

  const getColumns = () => {
    const columns = [];

    columns.push({
      dataKey: "industryCode",
      label: "Código",
      width: 50,
    });

    columns.push({
      dataKey: "name",
      label: productMessages.name,
      width: 300,
    });

    columns.push({
      dataKey: "localization",
      label: productMessages.localization,
      width: 150,
    });

    columns.push({
      dataKey: "brand",
      label: productMessages.brand,
      width: 150,
    });
    return columns;
  };

  const renderGrid = () => (
    <div
      style={{
        marginTop: 10,
        width: "100%",
        display: "inline-block",
      }}
    >
      <VirtualizedList
        fnFocus={(focus) => {
          vlRef.current = focus;
        }}
        emptyText={emptyText}
        list={list}
        columns={getColumns()}
        onSelectedItem={(sItem) => setItem(sItem)}
      />
    </div>
  );

  const onCloseModal = useCallback(() => {
    if (onClose) {
      onClose();
      setSearchTerm(null);
      setBarCode(null);
      setList([]);
      setItem(null);
      setEmptyText(EMPTY_TEXT);
    }
  }, [onClose]);

  return (
    <GridContainer style={{ justifyContent: "center", width: modalOpen ? "85%" : "100%" }}>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary">
            <h4 className={classesuseCardStyle.cardTitleList}>Pesquisa de preços</h4>
            <p className={classesuseCardStyle.cardCategoryList}>{productMessages.productsTable}</p>
          </CardHeader>

          <CardBody>
            <GridContainer>
              <Grid item xs={12} sm={12} md={8}>
                <TextInput
                  autoFocus
                  onKeyPress={(e) => {
                    if (e.which === 13) {
                      setBarCode("");
                    }
                  }}
                  onKeyDown={(e) => {
                    if (e.which === 40) {
                      if (vlRef?.current) vlRef.current();
                    }
                  }}
                  id="general"
                  label="Pesquisar produtos"
                  placeholder={productMessages.searchbar}
                  value={searchTerm}
                  onChange={(ev, value) => {
                    setSearchTerm(value);
                    setBarCode("");
                    setItem(null);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <TextInput
                  style={{ marginLeft: 4 }}
                  onKeyPress={(e) => {
                    if (e.which === 13) {
                      fetchAll(e.target.value, true);
                      setSearchTerm(null);
                      setItem(null);
                    }
                  }}
                  id="barCode"
                  label={generalMessages.barCodeText}
                  placeholder={generalMessages.barCodePlaceholder}
                  value={barCode}
                  onChange={(ev, value) => setBarCode(value)}
                />
              </Grid>
            </GridContainer>

            {renderGrid()}
            {renderInfs()}
          </CardBody>
          <CardFooter>
            {modalOpen && (
              <Button color="primary" onClick={onCloseModal}>
                Fechar
              </Button>
            )}
          </CardFooter>
        </Card>
      </GridItem>
      <Loading loading={loading} />
    </GridContainer>
  );
}
