/* eslint-disable react/prop-types */
import React from "react";
import { makeStyles } from "@mui/styles";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import CustomComponentsStyle from "assets/jss/material-dashboard-react/components/customComponentsStyle";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import GridItem from "components/Grid/GridItem";
import CurrencyTextInput from "components/textInput/CurrencyTextInput";
import MESSAGES from "config/messages";
import GridContainer from "components/Grid/GridContainer";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  YesNo,
  ItemCompoemValorTotalNfeType,
  ReducaoBaseCalculoIcmsStType,
  VendaParaZonaFrancaDeManausType,
} from "../model/NaturezaOperacao";

const { naturezaOperacaoMessages } = MESSAGES;

const NOAdvancedConfigs = ({ data, handleChange, errors = {} }) => {
  const useCustomComponentsStyle = makeStyles(CustomComponentsStyle);
  const classesCustomComponentsStyle = useCustomComponentsStyle();

  const {
    operacaoConsumidorFinal,
    considerarComoReceitaNoDre,
    considerarCvmNoDre,
    compraDeProdutorRural,
    permiteLancamentoDeEstoque,
    aliquotaFunrural,
    descontarFunruralDoTotalFaturado,
    vendaParaZonaFrancaDeManaus,
    reducaoDaBaseDeCalculoDoIcmsSt,
    descontarDesoneracaoDoTotalDaNotaFiscal,
    itemCompoemValorTotalNfe,
  } = data;

  const renderYesNoSelect = (field, val) => (
    <GridItem xs={12} sm={12} md={3}>
      <FormControl
        fullWidth
        className={classesCustomComponentsStyle.formControlSelect}
        size="small"
        variant="outlined"
      >
        <InputLabel>{naturezaOperacaoMessages.advanced[field]}</InputLabel>
        <Select
          label={naturezaOperacaoMessages.advanced[field]}
          id={field}
          autoWidth
          value={val === true ? YesNo.YES.code : YesNo.No.code}
          onChange={(event) => {
            const {
              target: { value },
            } = event;
            return handleChange(field, value);
          }}
          error={errors[field]}
          helperText={naturezaOperacaoMessages.advanced[field]}
        >
          {Object.keys(YesNo).map((key) => {
            const { code, name } = YesNo[key];
            return (
              <MenuItem key={code} value={code}>
                {name}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </GridItem>
  );

  const renderAliquotaFunrural = () => (
    <GridItem xs={8} sm={8} md={3}>
      <CurrencyTextInput
        currencySymbol="%"
        id="aliquotaFunrural"
        label={naturezaOperacaoMessages.advanced.aliquotaFunrural}
        value={aliquotaFunrural || "0"}
        onChange={handleChange}
        errors={errors}
      />
    </GridItem>
  );

  const renderReducaoBaseCalculoIcmsStType = () => (
    <GridItem xs={12} sm={12} md={6}>
      <FormControl
        fullWidth
        className={classesCustomComponentsStyle.formControlSelect}
        size="small"
        variant="outlined"
      >
        <InputLabel>{naturezaOperacaoMessages.advanced.reducaoDaBaseDeCalculoDoIcmsSt}</InputLabel>
        <Select
          label={naturezaOperacaoMessages.advanced.reducaoDaBaseDeCalculoDoIcmsSt}
          id="reducaoDaBaseDeCalculoDoIcmsSt"
          autoWidth
          value={reducaoDaBaseDeCalculoDoIcmsSt == null ? "" : reducaoDaBaseDeCalculoDoIcmsSt}
          onChange={(event) => {
            const {
              target: { value },
            } = event;
            return handleChange("reducaoDaBaseDeCalculoDoIcmsSt", value);
          }}
          error={errors.reducaoDaBaseDeCalculoDoIcmsSt}
          helperText={naturezaOperacaoMessages.advanced.reducaoDaBaseDeCalculoDoIcmsSt}
        >
          {Object.keys(ReducaoBaseCalculoIcmsStType).map((key) => {
            const { code, name } = ReducaoBaseCalculoIcmsStType[key];
            return (
              <MenuItem key={code} value={code}>
                {name}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </GridItem>
  );

  const renderItemCompoemValorTotalNfe = () => (
    <GridItem xs={12} sm={12} md={6}>
      <FormControl
        fullWidth
        className={classesCustomComponentsStyle.formControlSelect}
        size="small"
        variant="outlined"
      >
        <InputLabel>{naturezaOperacaoMessages.advanced.itemCompoemValorTotalNfe}</InputLabel>
        <Select
          label={naturezaOperacaoMessages.advanced.itemCompoemValorTotalNfe}
          id="itemCompoemValorTotalNfe"
          autoWidth
          value={itemCompoemValorTotalNfe == null ? "" : itemCompoemValorTotalNfe}
          onChange={(event) => {
            const {
              target: { value },
            } = event;
            return handleChange("itemCompoemValorTotalNfe", value);
          }}
          error={errors.itemCompoemValorTotalNfe}
          helperText={naturezaOperacaoMessages.advanced.itemCompoemValorTotalNfe}
        >
          {Object.keys(ItemCompoemValorTotalNfeType).map((key) => {
            const { code, name } = ItemCompoemValorTotalNfeType[key];
            return (
              <MenuItem key={code} value={code}>
                {name}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </GridItem>
  );

  const renderVendaParaZonaFrancaDeManaus = () => (
    <GridItem xs={12} sm={12} md={6} style={{ marginTop: -6 }}>
      <FormControl
        fullWidth
        className={classesCustomComponentsStyle.formControlSelect}
        size="small"
        variant="outlined"
      >
        <InputLabel>{naturezaOperacaoMessages.advanced.vendaParaZonaFrancaDeManaus}</InputLabel>
        <Select
          label={naturezaOperacaoMessages.advanced.vendaParaZonaFrancaDeManaus}
          id="vendaParaZonaFrancaDeManaus"
          autoWidth
          value={vendaParaZonaFrancaDeManaus == null ? "" : vendaParaZonaFrancaDeManaus}
          onChange={(event) => {
            const {
              target: { value },
            } = event;
            return handleChange("vendaParaZonaFrancaDeManaus", value);
          }}
          error={errors.vendaParaZonaFrancaDeManaus}
          helperText={naturezaOperacaoMessages.advanced.vendaParaZonaFrancaDeManaus}
        >
          {Object.keys(VendaParaZonaFrancaDeManausType).map((key) => {
            const { code, name } = VendaParaZonaFrancaDeManausType[key];
            return (
              <MenuItem key={code} value={code}>
                {name}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </GridItem>
  );

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<FontAwesomeIcon icon={faAngleDown} fontSize={20} />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography style={{ fontSize: 18, fontWeight: 500 }}>Configurações extras</Typography>
      </AccordionSummary>
      <AccordionDetails>
        {/* <GridContainer> */}
        <GridItem xs={12} sm={12} md={12}>
          <GridContainer>
            {[
              renderYesNoSelect("considerarComoReceitaNoDre", considerarComoReceitaNoDre),
              renderYesNoSelect("considerarCvmNoDre", considerarCvmNoDre),
              renderYesNoSelect("compraDeProdutorRural", compraDeProdutorRural),
              renderYesNoSelect("permiteLancamentoDeEstoque", permiteLancamentoDeEstoque),
              renderYesNoSelect(
                "descontarFunruralDoTotalFaturado",
                descontarFunruralDoTotalFaturado
              ),
              renderVendaParaZonaFrancaDeManaus(),
              renderAliquotaFunrural(),
              renderYesNoSelect(
                "descontarDesoneracaoDoTotalDaNotaFiscal",
                descontarDesoneracaoDoTotalDaNotaFiscal
              ),
              renderReducaoBaseCalculoIcmsStType(),
              renderItemCompoemValorTotalNfe(),
            ]}
          </GridContainer>
        </GridItem>
        {/* </GridContainer> */}
      </AccordionDetails>
    </Accordion>
  );
};

export default React.memo(NOAdvancedConfigs);
