import axios from "axios";
import { APIS } from "../../../config/constants";
import BaseService from "../../../core/BaseService";

const { register: registerAPI } = APIS;

class NaturezaOperacaoService extends BaseService {
  constructor() {
    super(registerAPI.naturezaOperacao);
  }

  fetchStates = () => axios.get(`${registerAPI.state}?size=50`);

  fetchAllWithoutTerm = (type) =>
    axios.get(
      `${this.endpoint}/search?size=1000&sort=active,DESC&sort=descricao&queryField=tipo;${type}`
    );

  fetchAll = (term) =>
    axios.get(`${this.endpoint}/search?size=1000&sort=active,DESC&sort=descricao&term=${term}`);

  fetchByAutoComplete = (term) =>
    axios.get(`${this.endpoint}/autocomplete-search?size=30&sort=descricao,ASC&term=${term}`);
}

export default new NaturezaOperacaoService();
