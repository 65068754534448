/* eslint-disable react/prop-types */
import React, { useCallback } from "react";
import { makeStyles } from "@mui/styles";
import CustomComponentsStyle from "assets/jss/material-dashboard-react/components/customComponentsStyle";
import { TextField, Typography } from "@mui/material";
import InputMask from "react-input-mask";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import SelectEnum from "components/selectEnum/SelectEnum";
import AddressForm from "components/address/AddressForm";
import { DocumentType } from "views/notaFiscal/model/NotaFiscal";

const NotaFiscalEnderecoEntrega = ({
  data = {},
  states,
  handleChange,
  errors = {},
  disabled = false,
}) => {
  const useCustomComponentsStyle = makeStyles(CustomComponentsStyle);
  const classesCustomComponentsStyle = useCustomComponentsStyle();

  // const {} = data;

  const onChange = useCallback(
    (field, value) => {
      handleChange({
        ...data,
        [field]: value,
      });
    },
    [data, handleChange]
  );

  const onChangeAddress = useCallback(
    (field, value, automaticFilling) => {
      if (automaticFilling) {
        // From via cep
        handleChange({
          ...data,
          ...value,
        });
      } else {
        handleChange({
          ...data,
          [field]: value,
        });
      }
    },
    [data, handleChange]
  );

  const renderTipoDocumento = () => (
    <GridItem xs={12} sm={12} md={3}>
      <SelectEnum
        label="Tipo de documento"
        field="tipoDocumentoEnderecoEntrega"
        Enum={DocumentType}
        valueSelected={data.tipoDocumentoEnderecoEntrega}
        handleChange={onChange}
        errors={errors}
        required
        disabled={disabled}
      />
    </GridItem>
  );

  const renderCpf = ({ field }) => (
    <GridItem xs={12} sm={12} md={3}>
      <InputMask
        mask="999.999.999-99"
        disabled={disabled}
        value={data[field] == null ? "" : data[field]}
        onChange={(value) => onChange(field, value.currentTarget.value)}
      >
        <TextField
          size="small"
          variant="outlined"
          id={field}
          label="CPF"
          fullWidth
          InputLabelProps={{
            className: classesCustomComponentsStyle.labelTextField,
          }}
          className={classesCustomComponentsStyle.textField}
          error={errors[field]}
          helperText={errors[field]}
        />
      </InputMask>
    </GridItem>
  );

  const renderCnpj = ({ field }) => (
    <GridItem xs={12} sm={12} md={3}>
      <InputMask
        mask="99.999.999/9999-99"
        disabled={disabled}
        value={data[field] == null ? "" : data[field]}
        onChange={(value) => onChange(field, value.currentTarget.value)}
      >
        <TextField
          size="small"
          variant="outlined"
          id={field}
          label="CNPJ"
          fullWidth
          InputLabelProps={{
            className: classesCustomComponentsStyle.labelTextField,
          }}
          className={classesCustomComponentsStyle.textField}
          error={errors[field]}
          helperText={errors[field]}
        />
      </InputMask>
    </GridItem>
  );

  const renderDocumento = () => {
    if (data.tipoDocumentoEnderecoEntrega === DocumentType.CPF.code) {
      return renderCpf({ field: "documentoEnderecoEntrega" });
    }
    if (data.tipoDocumentoEnderecoEntrega === DocumentType.CNPJ.code) {
      return renderCnpj({ field: "documentoEnderecoEntrega" });
    }
    return <></>;
  };

  const renderAddress = () => (
    <AddressForm
      disabled={disabled}
      fields={{
        zipCode: "cepEnderecoEntrega",
        state: "state",
        city: "cidadeEnderecoEntrega",
        street: "logradouroEnderecoEntrega",
        neighborhood: "bairroEnderecoEntrega",
        number: "numeroEnderecoEntrega",
        complement: "complementoEnderecoEntrega",
      }}
      address={data}
      states={states}
      handleChange={onChangeAddress}
      errors={errors}
      title="Endereço de entrega"
      showTitle={false}
    />
  );

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12} style={{ marginTop: 10 }}>
        {/* <Divider style={{ width: "100%", marginBottom: 4 }} /> */}
        <Typography style={{ fontSize: 16, fontWeight: 500 }}>Endereço de entrega</Typography>
      </GridItem>
      {renderTipoDocumento()}
      {renderDocumento()}
      <GridItem xs={12} sm={12} md={6} />
      {renderAddress()}
    </GridContainer>
  );
};

export default React.memo(NotaFiscalEnderecoEntrega);
