/* eslint-disable arrow-body-style */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React, { useCallback, useEffect } from "react";
import ReactGA from "react-ga4";
import "core/axiosInterceptors";
import { Route, Switch, Redirect, useLocation, useHistory } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";
// core components
import { REDIRECT_HTML } from "config/constants";
import App from "layouts/App";
import Welcome from "layouts/Welcome";
import "assets/css/material-dashboard-react.css?v=1.10.0";
import LoginService from "./views/login/service/LoginService";
import CheckIn from "./views/checkin/CheckIn";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const history = useHistory();
  const { keycloak, initialized } = useKeycloak();
  const isLoggedIn = keycloak.authenticated;

  const checkMeAccess = useCallback(async () => {
    const me = await LoginService.me();
    const userMe = me?.data || {};
    const isValidPlan = userMe.plan?.validPlan || false;
    if (!isValidPlan && history.location.pathname !== "/app/company") {
      history.push({
        pathname: "/check-in",
        // state: { userMe },
      });
    }
  }, [history]);

  useEffect(() => {
    if (initialized) {
      if (!isLoggedIn) {
        return keycloak.login();
      }
      return checkMeAccess();
    }
    return null;
  }, [initialized, checkMeAccess, isLoggedIn, keycloak]);

  if (!initialized) {
    // eslint-disable-next-line react/no-danger
    return <div dangerouslySetInnerHTML={{ __html: REDIRECT_HTML }} />;
  }

  if (isLoggedIn) {
    return <Component {...rest} />;
  }

  return <></>;
};

const isPROD = () => {
  // console.log(process.env.NODE_ENV);
  return process.env.NODE_ENV === "production";
};

const AppRouter = () => {
  const location = useLocation();
  const { keycloak, initialized } = useKeycloak();

  if(isPROD()) {
    ReactGA.initialize("G-RZMZ3QMMX6");
  }

  useEffect(() => {
    if (isPROD() && initialized) {
      keycloak.loadUserInfo().then((info) => {
        ReactGA.set({ userId: info.userId });
      });
    }
  }, [initialized, keycloak]);

  useEffect(() => {
    if (isPROD()) {
      ReactGA.send({
        hitType: "pageview",
        page: `${window.location.pathname}`,
        title: `${document.title}`,
      });
    }
  }, [location]);

  return (
    <Switch>
      <PrivateRoute path="/app" component={App} />
      <Route path="/welcome" component={Welcome} />
      <Route path="/check-in" component={CheckIn} />
      <Redirect from="/" to="/app/dashboards" />
    </Switch>
  );
};

export default AppRouter;
