import axios from "axios";
import { APIS } from "../../../config/constants";
import BaseService from "../../../core/BaseService";

const { register: registerAPI } = APIS;

class PartilhaIcmsService extends BaseService {
  constructor() {
    super(registerAPI.partilhaIcms);
  }

  customFetchAll = () => axios.get(`${this.endpoint}/search?size=1000`);
}

export default new PartilhaIcmsService();
