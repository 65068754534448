import React, { useState, useCallback } from "react";
import InputMask from "react-input-mask";
import { makeStyles } from "@mui/styles";
import logo from "assets/img/logo_login.png";
import backgroud from "assets/img/welcome_bg.jpg";
import CustomComponentsStyle from "assets/jss/material-dashboard-react/components/customComponentsStyle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router-dom";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import Button from "components/CustomButtons/Button";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardFooter from "components/Card/CardFooter";
import { useSnackbar } from "notistack";
import {
  TextField,
  Box,
  Typography,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button as MuiButton,
} from "@mui/material";
import timezone from "moment-timezone";
import ReactGA from "react-ga4";
import { primaryColor } from "assets/jss/material-dashboard-react";
import UserCompanyService from "./service/UserCompanyService";
import UserCompany from "./model/UserCompany";
import { handlingResponseErrors } from "../../config/util";
import MESSAGES from "../../config/messages";
import TextInput from "../../components/textInput/TextInput";

import TextEmail from "../../components/textInput/TextEmail";
import Loading from "../../components/loading/Loading";
import ProfileBusiness from "../company/model/ProfileBusiness";

const { generalMessages, companyMessages } = MESSAGES;

const useCustomComponentsStyle = makeStyles(CustomComponentsStyle);
const useStyles = makeStyles((theme) => ({
  background: {
    backgroundColor: "#9c27b0",
    height: "100vh",
    backgroundImage: `url(${backgroud})`,
    filter: "blur(2px) brightness(50%)",
    opacity: 0.41,
    backgroundPosition: "top",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    position: "absolute",
    width: "100%",
    zIndex: -1,
  },
  content: {
    display: "flex",
    justifyContent: "center",
    height: "100vh",
    overflowY: "auto", // Adicionando scroll apenas para o conteúdo
    zIndex: 1,
  },
  card: {
    marginTop: "50px",
    boxShadow: "rgba(100, 100, 111, 0.2) 0 7px 29px 0",
    backgroundColor: "#fff",
  },
  footerButton: {
    width: "100%",
  },
  hrContainer: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    margin: "10px 0",
  },
  hr: {
    flexGrow: 1,
    border: "none",
    height: "1px",
    backgroundColor: "#ccc",
  },
  hrText: {
    padding: "0 10px",
  },
}));

export default function WelcomeForm() {
  const [openConfirmation, setOpenConfirmation] = React.useState(false);
  const classesCustomComponentsStyle = useCustomComponentsStyle();

  const classes = useStyles();
  const history = useHistory();

  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [userCompany, setUserCompany] = useState(
    new UserCompany({ profileBusiness: ProfileBusiness.SALES_GENERAL.code })
  );
  const [errors, setErrors] = useState({});
  const [successfulRegister, setSuccessfulRegister] = useState(false);

  const handleChange = useCallback(
    (field, value) => {
      setUserCompany({
        ...userCompany,
        [field]: value,
      });
    },
    [userCompany]
  );

  const handleCloseConfirmation = useCallback(() => {
    setOpenConfirmation(false);
  }, []);

  const finishRegister = useCallback(async () => {
    try {
      setLoading(true);
      await UserCompanyService.save({
        ...userCompany,
        timeZone: timezone.tz.guess(),
      });
      enqueueSnackbar(generalMessages.saveSuccess, {
        variant: "success",
        autoHideDuration: 3000,
      });

      ReactGA.event("sign_up");

      setSuccessfulRegister(true);
    } catch (error) {
      const { message, validationErrors } = handlingResponseErrors(error);
      enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
      if (validationErrors != null) {
        setErrors(validationErrors);
      }
    } finally {
      handleCloseConfirmation();
      setLoading(false);
    }
  }, [userCompany, enqueueSnackbar, handleCloseConfirmation]);

  const onSave = useCallback(async () => {
    setOpenConfirmation(true);
  }, []);

  const confirmationDialog = useCallback(
    () => (
      <>
        <Dialog disableEscapeKeyDown open={openConfirmation} maxWidth="md">
          <DialogTitle>Criação de conta</DialogTitle>
          <DialogContent>
            <GridContainer>
              <div>
                <Typography variant="body1" gutterBottom>
                  Certifique-se de que está utilizando seu melhor <b>e-mail</b> e o{" "}
                  <b>número de celular</b> correto.
                </Typography>
                <Typography variant="body1">
                  E-mail: <b>{userCompany.email}</b>
                </Typography>
                <Typography variant="body1" gutterBottom>
                  Telefone: <b>{userCompany.mobile}</b>
                </Typography>
                <Typography variant="body1" gutterBottom>
                  Você receberá um e-mail com os dados do primeiro login e também uma mensagem pelo
                  WhatsApp.
                </Typography>
                <Typography variant="body1">
                  Caso o número informado esteja errado, outra pessoa poderá receber os dados de
                  acesso.
                </Typography>
              </div>
            </GridContainer>
          </DialogContent>
          <DialogActions>
            <div style={{ flexGrow: 1, display: "flex", justifyContent: "space-between" }}>
              <MuiButton style={{ color: primaryColor[0] }} onClick={handleCloseConfirmation}>
                Ajustar dados
              </MuiButton>

              <MuiButton style={{ color: primaryColor[0] }} onClick={finishRegister}>
                Finalizar cadastro
              </MuiButton>
            </div>
          </DialogActions>
        </Dialog>
      </>
    ),
    [
      openConfirmation,
      userCompany.email,
      userCompany.mobile,
      handleCloseConfirmation,
      finishRegister,
    ]
  );

  const renderProfileBusiness = () => (
    <FormControl
      variant="outlined"
      style={{ marginTop: 13, marginRight: 8, marginLeft: 8, width: "100%" }}
      size="small"
    >
      <InputLabel id="select-profile-business">Atividade da empresa</InputLabel>
      <Select
        label="Atividade da empresa"
        labelId="select-profile-business"
        autoWidth
        error={errors.profileBusiness}
        value={userCompany.profileBusiness || null}
        onChange={(event) => {
          const { value } = event.target;
          handleChange("profileBusiness", value);
        }}
      >
        {Object.keys(ProfileBusiness).map((key) => {
          const { code, name } = ProfileBusiness[key];
          return (
            <MenuItem key={code} value={code}>
              {name}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );

  return (
    <Box>
      <Box className={classes.background} />
      <Box className={classes.content}>
        <Box>
          <GridContainer style={{ justifyContent: "center" }}>
            {/* <img src={logoLogin} alt="" width={250} /> */}
            <GridItem xs={12} sm={12} md={10} style={{ maxWidth: "788px", margin: "0 auto" }}>
              {!successfulRegister ? (
                <Card
                  style={{ marginTop: "50px", boxShadow: "rgba(100,100,111,0.2) 0 7px 29px 0" }}
                >
                  <CardBody>
                    <Box
                      flexDirection="column"
                      style={{
                        verticalAlign: "middle",
                        justifyContent: "center",
                        width: "100%",
                        alignItems: "center",
                        flexGrow: 1,
                      }}
                    >
                      <Box style={{ textAlign: "center", marginTop: 8 }}>
                        <img src={logo} alt="" width="120rem" />
                      </Box>
                      <Box style={{ padding: 18, textAlign: "center" }}>
                        <Typography
                          style={{
                            fontSize: 21,
                            color: "#909FA7",
                            textAlign: "center",
                            fontWeight: 200,
                            fontFamily: "Nunito, sans-serif",
                          }}
                        >
                          Simplifique o controle do seu negócio.
                        </Typography>
                        <Typography
                          style={{
                            fontSize: 30,
                            color: "#3c3c3c",
                            fontWeight: 400,
                            fontFamily: "Nunito, sans-serif",
                          }}
                        >
                          Comece seu teste grátis agora!
                        </Typography>
                        <Box
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            paddingTop: 18,
                            paddingLeft: 18,
                            paddingRight: 18,
                          }}
                        >
                          <Box style={{ textAlign: "left" }}>
                            <Typography
                              style={{
                                fontSize: 18,
                                color: "#909FA7",
                                fontWeight: 200,
                                marginBottom: 8,
                                fontFamily: "Nunito, sans-serif",
                              }}
                            >
                              <FontAwesomeIcon icon={faCheck} style={{ marginRight: 8 }} /> Sem
                              necessidade de cartão de crédito
                            </Typography>
                            <Typography
                              style={{
                                fontSize: 18,
                                color: "#909FA7",
                                fontWeight: 200,
                                marginBottom: 8,
                                fontFamily: "Nunito, sans-serif",
                              }}
                            >
                              <FontAwesomeIcon icon={faCheck} style={{ marginRight: 8 }} /> Acesse
                              todas as funcionalidades
                            </Typography>
                            <Typography
                              style={{
                                fontSize: 18,
                                color: "#909FA7",
                                fontWeight: 200,
                                marginBottom: 8,
                                fontFamily: "Nunito, sans-serif",
                              }}
                            >
                              <FontAwesomeIcon icon={faCheck} style={{ marginRight: 8 }} /> Cancele
                              a qualquer momento
                            </Typography>
                            <Typography
                              style={{
                                fontSize: 18,
                                color: "#909FA7",
                                fontWeight: 200,
                                fontFamily: "Nunito, sans-serif",
                              }}
                            >
                              <FontAwesomeIcon icon={faCheck} style={{ marginRight: 8 }} /> Período
                              de teste sem cobrança
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Box>

                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                        <TextInput
                          autoFocus
                          id="name"
                          label="Qual é seu nome?"
                          value={userCompany.name}
                          onChange={handleChange}
                          errors={errors}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12}>
                        <TextEmail
                          id="email"
                          label="Qual é seu e-mail?"
                          value={userCompany.email}
                          onChange={handleChange}
                          errors={errors}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12}>
                        <InputMask
                          mask="(99) 99999-9999"
                          disabled={false}
                          value={userCompany.mobile == null ? "" : userCompany.mobile}
                          onChange={(value) => handleChange("mobile", value.currentTarget.value)}
                        >
                          <TextField
                            size="small"
                            variant="outlined"
                            id="mobile"
                            label="Telefone celular"
                            fullWidth
                            InputLabelProps={{
                              className: classesCustomComponentsStyle.labelTextField,
                            }}
                            className={classesCustomComponentsStyle.textField}
                            error={errors.mobile}
                            helperText={errors.mobile}
                          />
                        </InputMask>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12}>
                        <TextInput
                          id="companyName"
                          label={companyMessages.companyName2}
                          value={userCompany.companyName}
                          onChange={handleChange}
                          errors={errors}
                        />
                      </GridItem>
                      {renderProfileBusiness()}
                      <GridItem xs={12} sm={12} md={12}>
                        <Box display="flex" flexDirection="row">
                          <Box display="flex" alignItems="center">
                            <p style={{ color: "#909FA7", marginBottom: 0 }}>
                              Ao cadastrar, estou ciente e concordo com o tratamento dos meus dados
                              pessoais de acordo com os termos da&nbsp;
                              <a
                                target="_blank"
                                href="https://www.kairum.com.br/terms.html"
                                rel="noreferrer"
                              >
                                Politica de Privacidade
                              </a>
                            </p>
                          </Box>
                        </Box>
                      </GridItem>
                    </GridContainer>
                  </CardBody>
                  <CardFooter
                    style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
                  >
                    <Button color="primary" onClick={onSave} style={{ width: "100%" }}>
                      {generalMessages.startSystem}
                    </Button>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                        margin: "10px 0",
                      }}
                    >
                      <hr style={{ flexGrow: 1 }} />
                      <span style={{ padding: "0 10px" }}>ou</span>
                      <hr style={{ flexGrow: 1 }} />
                    </div>
                    <Button
                      color="success"
                      onClick={() => history.push(`/`)}
                      style={{ width: "100%" }}
                    >
                      Já possuo conta
                    </Button>
                  </CardFooter>
                </Card>
              ) : (
                <Card
                  style={{ marginTop: "50px", boxShadow: "rgba(100,100,111,0.2) 0 7px 29px 0" }}
                >
                  <CardBody>
                    <Box
                      flexDirection="column"
                      style={{
                        verticalAlign: "middle",
                        justifyContent: "center",
                        width: "100%",
                        alignItems: "center",
                        flexGrow: 1,
                      }}
                    >
                      <Box style={{ textAlign: "center", marginTop: 8 }}>
                        <img src={logo} alt="" width="120rem" />
                      </Box>
                      <Box style={{ padding: 18, textAlign: "center" }}>
                        <Typography
                          style={{
                            fontSize: 30,
                            color: "#909FA7",
                            textAlign: "center",
                            fontWeight: 200,
                            fontFamily: "Nunito, sans-serif",
                          }}
                        >
                          Seu cadastro foi realizado com sucesso!
                        </Typography>
                        <br />
                        <Typography
                          style={{
                            fontSize: 21,
                            color: "#657379",
                            textAlign: "center",
                            fontWeight: 200,
                            fontFamily: "Nunito, sans-serif",
                          }}
                        >
                          Você receberá um email com uma senha temporária.
                          <br />
                          Utilize essa senha para fazer seu primeiro login.
                          <br />
                          Após o login, você será solicitado a criar sua senha definitiva.
                        </Typography>
                      </Box>
                    </Box>
                  </CardBody>
                  <CardFooter
                    style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
                  >
                    <Button
                      color="primary"
                      onClick={() => history.push(`/`)}
                      style={{ width: "100%" }}
                    >
                      Ir ao login
                    </Button>
                  </CardFooter>
                </Card>
              )}
            </GridItem>
          </GridContainer>
        </Box>
      </Box>
      <Loading loading={loading} />
      {confirmationDialog()}
    </Box>
  );
}
