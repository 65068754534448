class UserCompany {
  constructor(source = {}) {
    this.name = source.name;
    this.email = source.email;
    this.password = source.password;
    this.passwordRepeat = source.passwordRepeat;
    this.companyName = source.companyName;
    this.tradingName = source.tradingName;
    this.foundationDate = source.foundationDate;
    this.name = source.name;
    this.mobile = source.mobile;
    this.companyEmail = source.companyEmail;
    this.profileBusiness = source.profileBusiness;
  }
}

export default UserCompany;
