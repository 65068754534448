/* eslint-disable react/prop-types */
import React from "react";
import { makeStyles } from "@mui/styles";
// import useMediaQuery from "@mui/material/useMediaQuery";
// import { useTheme } from "@mui/material/styles";
import CustomComponentsStyle from "assets/jss/material-dashboard-react/components/customComponentsStyle";
import { TextField } from "@mui/material";

const useCustomComponentsStyle = makeStyles(CustomComponentsStyle);

const TextInput = ({
  id,
  autoFocus,
  label,
  value,
  required,
  onChange,
  errors = {},
  type = "text",
  placeholder = "",
  onKeyPress,
  onKeyDown,
  onBlur,
  autoComplete = "off",
  style,
  disabled,
  maxLength,
  minLength,
  inputRef,
  multiline = false,
  rows = 1,
  isCounter,
  fieldError,
  InputProps,
}) => {
  const classesCustomComponentsStyle = useCustomComponentsStyle();
  // const theme = useTheme();
  // const isMediumScreen = useMediaQuery(theme.breakpoints.down("custom"));

  // const fontSize = isMediumScreen ? 14 : null;
  // const internalPadding = isMediumScreen ? "6px 7px" : null;

  return (
    <>
      <TextField
        inputRef={inputRef}
        disabled={disabled}
        style={style}
        size="small"
        variant="outlined"
        autoComplete={autoComplete}
        autoFocus={autoFocus}
        placeholder={placeholder}
        id={id}
        label={label}
        onKeyPress={onKeyPress}
        onKeyDown={onKeyDown}
        onBlur={onBlur}
        fullWidth
        InputLabelProps={{
          className: classesCustomComponentsStyle.labelTextField,
        }}
        className={classesCustomComponentsStyle.textField}
        value={value == null ? "" : value}
        onChange={(val) => (onChange ? onChange(id, val.currentTarget.value) : null)}
        required={required}
        error={errors[fieldError || id]}
        helperText={errors[fieldError || id]}
        type={type}
        inputProps={{
          maxLength,
          minLength,
          // style: {
          //   padding: internalPadding,
          // },
        }}
        // eslint-disable-next-line react/jsx-no-duplicate-props
        InputProps={{
          ...InputProps,
          // sx: {
          //   fontSize,
          // },
        }}
        multiline={multiline}
        rows={rows}
      />
      {isCounter && (
        <span
          style={{
            display: "flex",
            flexGrow: 1,
            justifyContent: "end",
          }}
        >
          {`${value?.length || 0} de ${maxLength}`}
        </span>
      )}
    </>
  );
};

export default React.memo(TextInput);
