const OriginType = {
  MANUALLY: {
    code: "MANUALLY",
    name: "Manual",
  },
  ORDER: {
    code: "ORDER",
    name: "Venda",
    initials: "VE",
  },
  SERVICE_ORDER: {
    code: "SERVICE_ORDER",
    name: "Ordem de serviço",
    initials: "OS",
  },
  PAYMENT: {
    code: "PAYMENT",
    name: "Pagamento",
  },
};

class Commission {
  constructor(source = {}) {
    this.id = source.id;
    this.name = source.name;
    this.totalCommission = source.totalCommission;
    this.totalService = source.totalService;
    this.totalProduct = source.totalProduct;
    this.percentageServiceCommission = source.percentageServiceCommission;
    this.percentageProductCommission = source.percentageProductCommission;
    this.originType = source.originType;
    this.originId = source.originId;
    this.user = source.user;
    this.commissionDate = source.commissionDate;
    this.canceled = source.canceled;
    this.version = source.version;
    this.goal = source.goal;
    this.calculated = source.calculated;
    this.orderValueTotal = source.orderValueTotal;
    this.orderValueTotalService = source.orderValueTotalService;
    this.orderValueTotalProduct = source.orderValueTotalProduct;
    this.commissionConfiguration = source.commissionConfiguration;
    this.goalServicePercentage = source.goalServicePercentage;
    this.goalProductPercentage = source.goalProductPercentage;
    this.goalStartTrackProduct = source.goalStartTrackProduct;
    this.goalStartTrackProduct = source.goalStartTrackProduct;
    this.goalTargetCalculationMethod = source.goalTargetCalculationMethod;
    this.goalEndTrackProduct = source.goalEndTrackProduct;
    this.goalEndTrackService = source.goalEndTrackService;
  }
}

export { Commission, OriginType };
