class NaturezaOperacao {
  constructor(source = {}) {
    if (source == null) return this;

    this.id = source.id;
    this.descricao = source.descricao;
    this.finalidade = source.finalidade;
    //
    this.regimeTributario = source.regimeTributario;
    this.operacaoConsumidorFinal = source.operacaoConsumidorFinal;
    this.tipo = source.tipo;
    this.padrao = source.padrao;
    //
    this.advancedConfigs = source.advancedConfigs;
    //
    this.excecoesSimples = source.excecoesSimples;
    this.excecoesIpi = source.excecoesIpi;
    this.excecoesIssqn = source.excecoesIssqn;
    this.excecoesPis = source.excecoesPis;
    this.excecoesCofins = source.excecoesCofins;
    this.excecoesIcms = source.excecoesIcms;
    //
    this.simples = source.simples;
    this.ipi = source.ipi;
    this.issqn = source.issqn;
    this.pis = source.pis;
    this.cofins = source.cofins;
    this.icms = source.icms;
    //
    this.active = source.active;
    this.version = source.version;
  }
}

const YesNo = {
  YES: {
    code: true,
    name: "Sim",
  },
  No: {
    code: false,
    name: "Não",
  },
};

const Finalidade = {
  NORMAL: {
    code: "NORMAL",
    name: "Normal",
  },
  COMPLEMENTAR: {
    code: "COMPLEMENTAR",
    name: "Complementar",
  },
  AJUSTE: {
    code: "AJUSTE",
    name: "Ajuste",
  },
  DEVOLUCAO_OU_RETORNO: {
    code: "DEVOLUCAO_OU_RETORNO",
    name: "Devolução ou retorno",
  },
};

const SELECIONE = {
  code: "",
  name: "-- Selecione --",
  value: "EMPTY",
};

const SituacaoOperacionalSimplesNacional = {
  // SELECIONE,
  TRIBUTADA_COM_PERMISSAO_CREDITO: {
    code: "101",
    name: "Tributada com permissão de crédito",
    value: "TRIBUTADA_COM_PERMISSAO_CREDITO",
  },
  TRIBUTADA_SEM_PERMISSAO_CREDITO: {
    code: "102",
    name: "Tributada sem permissão de crédito",
    value: "TRIBUTADA_SEM_PERMISSAO_CREDITO",
  },
  ISENCAO_ICMS_FAIXA_RECEITA_BRUTA: {
    code: "103",
    name: "Isenção ICMS faixa receita bruta",
    value: "ISENCAO_ICMS_FAIXA_RECEITA_BRUTA",
  },
  TRIBUTADA_SIMPLES_NACIONAL_COM_PERMISSAO_DE_CREDITO_E_COBRANCA_ICMS_SUBSTITUICAO_TRIBUTARIA: {
    code: "201",
    name: "Tributada Simples Nacional com permissão de crédito e cobrança ICMS ST",
    value:
      "TRIBUTADA_SIMPLES_NACIONAL_COM_PERMISSAO_DE_CREDITO_E_COBRANCA_ICMS_SUBSTITUICAO_TRIBUTARIA",
  },
  TRIBUTADA_SIMPLES_NACIONAL_SEM_PERMISSAO_DE_CREDITO_E_COBRANCA_ICMS_SUBSTITUICAO_TRIBUTARIA: {
    code: "202",
    name: "Tributada Simples Nacional sem permissão de crédito e cobrança ICMS ST",
    value:
      "TRIBUTADA_SIMPLES_NACIONAL_SEM_PERMISSAO_DE_CREDITO_E_COBRANCA_ICMS_SUBSTITUICAO_TRIBUTARIA",
  },
  TRIBUTADA_SIMPLES_NACIONAL_PARA_FAIXA_RECEITA_BRUTA_E_COBRANCA_ICMS_SUBSTITUICAO_TRIBUTARIA: {
    code: "203",
    name: "Tributada Simples Nacional para faixa receita bruta e cobrança ICMS ST",
    value:
      "TRIBUTADA_SIMPLES_NACIONAL_PARA_FAIXA_RECEITA_BRUTA_E_COBRANCA_ICMS_SUBSTITUICAO_TRIBUTARIA",
  },
  IMUNE: { code: "300", name: "Imune", value: "IMUNE" },
  NAO_TRIBUTADA: { code: "400", name: "Não tributada", value: "NAO_TRIBUTADA" },
  ICMS_COBRADO_ANTERIORMENTE_POR_SUBSTITUICAO_TRIBUTARIA_SUBSIDIO_OU_POR_ANTECIPACAO: {
    code: "500",
    name: "ICMS cobrado anteriormente por ST subsídio ou por antecipação",
    value: "ICMS_COBRADO_ANTERIORMENTE_POR_SUBSTITUICAO_TRIBUTARIA_SUBSIDIO_OU_POR_ANTECIPACAO",
  },
  OUTROS: { code: "900", name: "Outros", value: "OUTROS" },
};

const SituacaoTributariaIpi = {
  SELECIONE,
  ENTRADA_RECUPERACAO_CREDITO: {
    code: "00",
    name: "Entrada recuperação crédito",
    value: "ENTRADA_RECUPERACAO_CREDITO",
  },
  ENTRADA_TRIBUTADA_ALIQUOTA_ZERO: {
    code: "01",
    name: "Entrada tributada alíquota zero",
    value: "ENTRADA_TRIBUTADA_ALIQUOTA_ZERO",
  },
  ENTRADA_ISENTA: { code: "02", name: "Entrada isenta", value: "ENTRADA_ISENTA" },
  ENTRADA_NAO_TRIBUTADA: {
    code: "03",
    name: "Entrada não tributada",
    value: "ENTRADA_NAO_TRIBUTADA",
  },
  ENTRADA_IMUNE: { code: "04", name: "Entrada imune", value: "ENTRADA_IMUNE" },
  ENTRADA_COM_SUSPENSAO: {
    code: "05",
    name: "Entrada com suspensão",
    value: "ENTRADA_COM_SUSPENSAO",
  },
  OUTRAS_ENTRADAS: { code: "49", name: "Outras entradas", value: "OUTRAS_ENTRADAS" },
  SAIDA_TRIBUTADA: { code: "50", name: "Saída tributada", value: "SAIDA_TRIBUTADA" },
  SAIDA_TRIBUTADA_COM_ALIQUOTA_ZERO: {
    code: "51",
    name: "Saída tributada com alíquota zero",
    value: "SAIDA_TRIBUTADA_COM_ALIQUOTA_ZERO",
  },
  SAIDA_ISENTA: { code: "52", name: "Saída isenta", value: "SAIDA_ISENTA" },
  SAIDA_NAO_TRIBUTADA: { code: "53", name: "Saída não tributada", value: "SAIDA_NAO_TRIBUTADA" },
  SAIDA_IMUNE: { code: "54", name: "Saída imune", value: "SAIDA_IMUNE" },
  SAIDA_COM_SUSPENSAO: { code: "55", name: "Saída com suspensão", value: "SAIDA_COM_SUSPENSAO" },
  OUTRAS_SAIDAS: { code: "99", name: "Outras saídas", value: "OUTRAS_SAIDAS" },
};

const SituacaoTributariaIssqn = {
  SELECIONE: {
    code: "EMPTY",
    name: "-- Selecione --",
  },
  NORMAL: {
    code: "NORMAL",
    name: "Normal",
  },
  RETIDA: {
    code: "RETIDA",
    name: "Retida",
  },
  SUBSTITUTA: {
    code: "SUBSTITUTA",
    name: "Substituta",
  },
  ISENTA: {
    code: "ISENTA",
    name: "Isenta",
  },
};

const SituacaoTributariaPis = {
  // SELECIONE,
  OPERACAO_TRIBUTAVEL_CUMULATIVO_NAO_CUMULATIVO: {
    code: "01",
    name: "Operação tributável cumulativo/não cumulativo",
    value: "OPERACAO_TRIBUTAVEL_CUMULATIVO_NAO_CUMULATIVO",
  },
  OPERACAO_TRIBUTAVEL_ALIQUOTA_DIFERENCIADA: {
    code: "02",
    name: "Operação tributável alíquota diferenciada",
    value: "OPERACAO_TRIBUTAVEL_ALIQUOTA_DIFERENCIADA",
  },
  OPERACAO_TRIBUTAVEL_QUANTIDADE_VENDIDA_POR_ALIQUOTA_POR_UNIDADE_PRODUTO: {
    code: "03",
    name: "Operação tributável quantidade vendida por alíquota por unidade produto",
    value: "OPERACAO_TRIBUTAVEL_QUANTIDADE_VENDIDA_POR_ALIQUOTA_POR_UNIDADE_PRODUTO",
  },
  OPERACAO_TRIBUTAVEL_MONOFASICA_ALIQUOTA_ZERO: {
    code: "04",
    name: "Operação tributável monofásica alíquota zero",
    value: "OPERACAO_TRIBUTAVEL_MONOFASICA_ALIQUOTA_ZERO",
  },
  OPERACAO_TRIBUTAVEL_SUBSTITUICAO_TRIBUTARIA: {
    code: "05",
    name: "Operação tributável ST",
    value: "OPERACAO_TRIBUTAVEL_SUBSTITUICAO_TRIBUTARIA",
  },
  OPERACAO_TRIBUTAVEL_ALIQUOTA_ZERO: {
    code: "06",
    name: "Operação tributável alíquota zero",
    value: "OPERACAO_TRIBUTAVEL_ALIQUOTA_ZERO",
  },
  OPERACAO_ISENTA_CONTRIBUICAO: {
    code: "07",
    name: "Operação isenta de contribuição",
    value: "OPERACAO_ISENTA_CONTRIBUICAO",
  },
  OPERACAO_SEM_INCIDENCIA_CONTRIBUICAO: {
    code: "08",
    name: "Operação sem incidência de contribuição",
    value: "OPERACAO_SEM_INCIDENCIA_CONTRIBUICAO",
  },
  OPERACAO_COM_SUSPENSAO_CONTRIBUICAO: {
    code: "09",
    name: "Operação com suspensão de contribuição",
    value: "OPERACAO_COM_SUSPENSAO_CONTRIBUICAO",
  },
  OUTRAS_OPERACOES_SAIDA: {
    code: "49",
    name: "Outras operações de saída",
    value: "OUTRAS_OPERACOES_SAIDA",
  },
  OPERACAO_DIREITO_CREDITO_VINCULADA_EXCLUSIVAMENTE_RECEITA_TRIBUTADA_MERCADO_INTERNO: {
    code: "50",
    name: "Operação direito crédito vinculada exclusivamente receita tributada mercado interno",
    value: "OPERACAO_DIREITO_CREDITO_VINCULADA_EXCLUSIVAMENTE_RECEITA_TRIBUTADA_MERCADO_INTERNO",
  },
  OPERACAO_DIREITO_CREDITO_VINCULADA_EXCLUSIVAMENTE_RECEITA_NAO_TRIBUTADA_MERCADO_INTERNO: {
    code: "51",
    name: "Operação direito crédito vinculada exclusivamente receita não tributada mercado interno",
    value:
      "OPERACAO_DIREITO_CREDITO_VINCULADA_EXCLUSIVAMENTE_RECEITA_NAO_TRIBUTADA_MERCADO_INTERNO",
  },
  OPERACAO_DIREITO_CREDITO_VINCULADA_EXCLUSIVAMENTE_RECEITA_EXPORTACAO: {
    code: "52",
    name: "Operação direito crédito vinculada exclusivamente receita exportação",
    value: "OPERACAO_DIREITO_CREDITO_VINCULADA_EXCLUSIVAMENTE_RECEITA_EXPORTACAO",
  },
  OPERACAO_DIREITO_CREDITO_VINCULADA_RECEITAS_TRIBUTADA_E_NAO_TRIBUTADA_MERCADO_INTERNO: {
    code: "53",
    name: "Operação direito crédito vinculada receitas tributada e não tributada mercado interno",
    value: "OPERACAO_DIREITO_CREDITO_VINCULADA_RECEITAS_TRIBUTADA_E_NAO_TRIBUTADA_MERCADO_INTERNO",
  },
  OPERACAO_DIREITO_CREDITO_VINCULADA_RECEITAS_TRIBUTADAS_NO_MERCADO_INTERNO_EXPORTACAO: {
    code: "54",
    name: "Operação direito crédito vinculada receitas tributadas no mercado interno exportação",
    value: "OPERACAO_DIREITO_CREDITO_VINCULADA_RECEITAS_TRIBUTADAS_NO_MERCADO_INTERNO_EXPORTACAO",
  },
  OPERACAO_DIREITO_CREDITO_VINCULADA_RECEITAS_NAO_TRIBUTADA_NO_MERCADO_INTERNO_EXPORTACAO: {
    code: "55",
    name: "Operação direito crédito vinculada receitas não tributada no mercado interno exportação",
    value:
      "OPERACAO_DIREITO_CREDITO_VINCULADA_RECEITAS_NAO_TRIBUTADA_NO_MERCADO_INTERNO_EXPORTACAO",
  },
  OPERACAO_DIREITO_CREDITO_VINCULADA_RECEITAS_TRIBUTADAS_E_NAO_TRIBUTADAS_MERCADO_INTERNO_EXPORTACAO: {
    code: "56",
    name:
      "Operação direito crédito vinculada receitas tributadas e não tributadas mercado interno exportação",
    value:
      "OPERACAO_DIREITO_CREDITO_VINCULADA_RECEITAS_TRIBUTADAS_E_NAO_TRIBUTADAS_MERCADO_INTERNO_EXPORTACAO",
  },
  CREDITO_PRESUMIDO_OPERACAO_AQUISICAO_VINCULADA_EXCLUSIVAMENTE_RECEITA_TRIBUTADA_MERCADO_INTERNO: {
    code: "60",
    name:
      "Crédito presumido operação aquisição vinculada exclusivamente receita tributada mercado interno",
    value:
      "CREDITO_PRESUMIDO_OPERACAO_AQUISICAO_VINCULADA_EXCLUSIVAMENTE_RECEITA_TRIBUTADA_MERCADO_INTERNO",
  },
  CREDITO_PRESUMIDO_OPERACAO_AQUISICAO_VINCULADA_EXCLUSIVAMENTE_A_RECEITA_NAO_TRIBUTADA_MERCADO_INTERNO: {
    code: "61",
    name:
      "Crédito presumido operação aquisição vinculada exclusivamente à receita não tributada mercado interno",
    value:
      "CREDITO_PRESUMIDO_OPERACAO_AQUISICAO_VINCULADA_EXCLUSIVAMENTE_A_RECEITA_NAO_TRIBUTADA_MERCADO_INTERNO",
  },
  CREDITO_PRESUMIDO_OPERACAO_AQUISICAO_VINCULADA_EXCLUSIVAMENTE_RECEITA_EXPORTACAO: {
    code: "62",
    name: "Crédito presumido operação aquisição vinculada exclusivamente receita exportação",
    value: "CREDITO_PRESUMIDO_OPERACAO_AQUISICAO_VINCULADA_EXCLUSIVAMENTE_RECEITA_EXPORTACAO",
  },
  CREDITO_PRESUMIDO_OPERACAO_AQUISICAO_VINCULADA_RECEITAS_TRIBUTADAS_E_NAO_TRIBUTADAS_MERCADO_INTERNO: {
    code: "63",
    name: "Crédito presumido operação aquisição vinculada receitas tributadas mercado interno",
    value:
      "CREDITO_PRESUMIDO_OPERACAO_AQUISICAO_VINCULADA_RECEITAS_TRIBUTADAS_E_NAO_TRIBUTADAS_MERCADO_INTERNO",
  },
  CREDITO_PRESUMIDO_OPERACAO_AQUISICAO_VINCULADA_RECEITAS_TRIBUTADA_MERCADO_INTERNO_EXPORTACAO: {
    code: "64",
    name:
      "Crédito presumido operação aquisição vinculada receitas tributadas mercado interno exportação",
    value:
      "CREDITO_PRESUMIDO_OPERACAO_AQUISICAO_VINCULADA_RECEITAS_TRIBUTADA_MERCADO_INTERNO_EXPORTACAO",
  },
  CREDITO_PRESUMIDO_OPERACAO_AQUISICAO_VINCULADA_RECEITAS_NAO_TRIBUTADAS_MERCADO_INTERNO_EXPORTACAO: {
    code: "65",
    name:
      "Crédito presumido operação aquisição vinculada receitas não tributadas mercado interno exportação",
    value:
      "CREDITO_PRESUMIDO_OPERACAO_AQUISICAO_VINCULADA_RECEITAS_NAO_TRIBUTADAS_MERCADO_INTERNO_EXPORTACAO",
  },
  CREDITO_PRESUMIDO_OPERACAO_AQUISICAO_VINCULADA_RECEITAS_TRIBUTADAS_E_NAO_TRIBUTADAS_MERCADO_INTERNO_EXPORTACAO: {
    code: "66",
    name:
      "Crédito presumido operação aquisição vinculada receitas tributadas e não tributadas mercado interno exportação",
    value:
      "CREDITO_PRESUMIDO_OPERACAO_AQUISICAO_VINCULADA_RECEITAS_TRIBUTADAS_E_NAO_TRIBUTADAS_MERCADO_INTERNO_EXPORTACAO",
  },
  CREDITO_PRESUMIDO_OUTRAS_OPERACOES: {
    code: "67",
    name: "Crédito presumido outras operações",
    value: "CREDITO_PRESUMIDO_OUTRAS_OPERACOES",
  },
  OPERACAO_AQUISICAO_SEM_DIREITO_CREDITO: {
    code: "70",
    name: "Operação aquisição sem direito crédito",
    value: "OPERACAO_AQUISICAO_SEM_DIREITO_CREDITO",
  },
  OPERACAO_AQUISICAO_COM_ISENCAO: {
    code: "71",
    name: "Operação aquisição com isenção",
    value: "OPERACAO_AQUISICAO_COM_ISENCAO",
  },
  OPERACAO_AQUISICAO_COM_SUSPENSAO: {
    code: "72",
    name: "Operação aquisição com suspensão",
    value: "OPERACAO_AQUISICAO_COM_SUSPENSAO",
  },
  OPERACAO_AQUISICAO_ALIQUOTA_ZERO: {
    code: "73",
    name: "Operação aquisição alíquota zero",
    value: "OPERACAO_AQUISICAO_ALIQUOTA_ZERO",
  },
  OPERACAO_AQUISICAO_SEM_INCIDENCIA_CONTRIBUICAO: {
    code: "74",
    name: "Operação aquisição sem incidência de contribuição",
    value: "OPERACAO_AQUISICAO_SEM_INCIDENCIA_CONTRIBUICAO",
  },
  OPERACAO_AQUISICAO_POR_SUBSTITUICAO_TRIBUTARIA: {
    code: "75",
    name: "Operação aquisição por substituição tributária",
    value: "OPERACAO_AQUISICAO_POR_SUBSTITUICAO_TRIBUTARIA",
  },
  OUTRAS_OPERACOES_ENTRADA: {
    code: "98",
    name: "Outras operações de entrada",
    value: "OUTRAS_OPERACOES_ENTRADA",
  },
  OUTRAS_OPERACOES: { code: "99", name: "Outras operações", value: "OUTRAS_OPERACOES" },
};

const SituacaoTributariaCofins = {
  // SELECIONE,
  OPERACAO_TRIBUTAVEL_CUMULATIVO_NAO_CUMULATIVO: {
    code: "01",
    name: "Operação tributável cumulativo/não cumulativo",
    value: "OPERACAO_TRIBUTAVEL_CUMULATIVO_NAO_CUMULATIVO",
  },
  OPERACAO_TRIBUTAVEL_ALIQUOTA_DIFERENCIADA: {
    code: "02",
    name: "Operação tributável alíquota diferenciada",
    value: "OPERACAO_TRIBUTAVEL_ALIQUOTA_DIFERENCIADA",
  },
  OPERACAO_TRIBUTAVEL_QUANTIDADE_VENDIDA_POR_ALIQUOTA_POR_UNIDADE_PRODUTO: {
    code: "03",
    name: "Operação tributável quantidade vendida por alíquota por unidade produto",
    value: "OPERACAO_TRIBUTAVEL_QUANTIDADE_VENDIDA_POR_ALIQUOTA_POR_UNIDADE_PRODUTO",
  },
  OPERACAO_TRIBUTAVEL_MONOFASICA_ALIQUOTA_ZERO: {
    code: "04",
    name: "Operação tributável monofásica alíquota zero",
    value: "OPERACAO_TRIBUTAVEL_MONOFASICA_ALIQUOTA_ZERO",
  },
  OPERACAO_TRIBUTAVEL_SUBSTITUICAO_TRIBUTARIA: {
    code: "05",
    name: "Operação tributável ST",
    value: "OPERACAO_TRIBUTAVEL_SUBSTITUICAO_TRIBUTARIA",
  },
  OPERACAO_TRIBUTAVEL_ALIQUOTA_ZERO: {
    code: "06",
    name: "Operação tributável alíquota zero",
    value: "OPERACAO_TRIBUTAVEL_ALIQUOTA_ZERO",
  },
  OPERACAO_ISENTA_CONTRIBUICAO: {
    code: "07",
    name: "Operação isenta de contribuição",
    value: "OPERACAO_ISENTA_CONTRIBUICAO",
  },
  OPERACAO_SEM_INCIDENCIA_CONTRIBUICAO: {
    code: "08",
    name: "Operação sem incidência de contribuição",
    value: "OPERACAO_SEM_INCIDENCIA_CONTRIBUICAO",
  },
  OPERACAO_COM_SUSPENSAO_CONTRIBUICAO: {
    code: "09",
    name: "Operação com suspensão de contribuição",
    value: "OPERACAO_COM_SUSPENSAO_CONTRIBUICAO",
  },
  OUTRAS_OPERACOES_SAIDA: {
    code: "49",
    name: "Outras operações de saída",
    value: "OUTRAS_OPERACOES_SAIDA",
  },
  OPERACAO_DIREITO_CREDITO_VINCULADA_EXCLUSIVAMENTE_RECEITA_TRIBUTADA_MERCADO_INTERNO: {
    code: "50",
    name: "Operação direito crédito vinculada exclusivamente receita tributada mercado interno",
    value: "OPERACAO_DIREITO_CREDITO_VINCULADA_EXCLUSIVAMENTE_RECEITA_TRIBUTADA_MERCADO_INTERNO",
  },
  OPERACAO_DIREITO_CREDITO_VINCULADA_EXCLUSIVAMENTE_RECEITA_NAO_TRIBUTADA_MERCADO_INTERNO: {
    code: "51",
    name: "Operação direito crédito vinculada exclusivamente receita não tributada mercado interno",
    value:
      "OPERACAO_DIREITO_CREDITO_VINCULADA_EXCLUSIVAMENTE_RECEITA_NAO_TRIBUTADA_MERCADO_INTERNO",
  },
  OPERACAO_DIREITO_CREDITO_VINCULADA_EXCLUSIVAMENTE_RECEITA_EXPORTACAO: {
    code: "52",
    name: "Operação direito crédito vinculada exclusivamente receita exportação",
    value: "OPERACAO_DIREITO_CREDITO_VINCULADA_EXCLUSIVAMENTE_RECEITA_EXPORTACAO",
  },
  OPERACAO_DIREITO_CREDITO_VINCULADA_RECEITAS_TRIBUTADA_E_NAO_TRIBUTADA_MERCADO_INTERNO: {
    code: "53",
    name: "Operação direito crédito vinculada receitas tributada e não tributada mercado interno",
    value: "OPERACAO_DIREITO_CREDITO_VINCULADA_RECEITAS_TRIBUTADA_E_NAO_TRIBUTADA_MERCADO_INTERNO",
  },
  OPERACAO_DIREITO_CREDITO_VINCULADA_RECEITAS_TRIBUTADAS_NO_MERCADO_INTERNO_EXPORTACAO: {
    code: "54",
    name: "Operação direito crédito vinculada receitas tributadas no mercado interno exportação",
    value: "OPERACAO_DIREITO_CREDITO_VINCULADA_RECEITAS_TRIBUTADAS_NO_MERCADO_INTERNO_EXPORTACAO",
  },
  OPERACAO_DIREITO_CREDITO_VINCULADA_RECEITAS_NAO_TRIBUTADA_NO_MERCADO_INTERNO_EXPORTACAO: {
    code: "55",
    name: "Operação direito crédito vinculada receitas não tributada no mercado interno exportação",
    value:
      "OPERACAO_DIREITO_CREDITO_VINCULADA_RECEITAS_NAO_TRIBUTADA_NO_MERCADO_INTERNO_EXPORTACAO",
  },
  OPERACAO_DIREITO_CREDITO_VINCULADA_RECEITAS_TRIBUTADAS_E_NAO_TRIBUTADAS_MERCADO_INTERNO_EXPORTACAO: {
    code: "56",
    name:
      "Operação direito crédito vinculada receitas tributadas e não tributadas mercado interno exportação",
    value:
      "OPERACAO_DIREITO_CREDITO_VINCULADA_RECEITAS_TRIBUTADAS_E_NAO_TRIBUTADAS_MERCADO_INTERNO_EXPORTACAO",
  },
  CREDITO_PRESUMIDO_OPERACAO_AQUISICAO_VINCULADA_EXCLUSIVAMENTE_RECEITA_TRIBUTADA_MERCADO_INTERNO: {
    code: "60",
    name:
      "Crédito presumido operação aquisição vinculada exclusivamente receita tributada mercado interno",
    value:
      "CREDITO_PRESUMIDO_OPERACAO_AQUISICAO_VINCULADA_EXCLUSIVAMENTE_RECEITA_TRIBUTADA_MERCADO_INTERNO",
  },
  CREDITO_PRESUMIDO_OPERACAO_AQUISICAO_VINCULADA_EXCLUSIVAMENTE_A_RECEITA_NAO_TRIBUTADA_MERCADO_INTERNO: {
    code: "61",
    name:
      "Crédito presumido operação aquisição vinculada exclusivamente à receita não tributada mercado interno",
    value:
      "CREDITO_PRESUMIDO_OPERACAO_AQUISICAO_VINCULADA_EXCLUSIVAMENTE_A_RECEITA_NAO_TRIBUTADA_MERCADO_INTERNO",
  },
  CREDITO_PRESUMIDO_OPERACAO_AQUISICAO_VINCULADA_EXCLUSIVAMENTE_RECEITA_EXPORTACAO: {
    code: "62",
    name: "Crédito presumido operação aquisição vinculada exclusivamente receita exportação",
    value: "CREDITO_PRESUMIDO_OPERACAO_AQUISICAO_VINCULADA_EXCLUSIVAMENTE_RECEITA_EXPORTACAO",
  },
  CREDITO_PRESUMIDO_OPERACAO_AQUISICAO_VINCULADA_RECEITAS_TRIBUTADAS_E_NAO_TRIBUTADAS_MERCADO_INTERNO: {
    code: "63",
    name: "Crédito presumido operação aquisição vinculada receitas tributadas mercado interno",
    value:
      "CREDITO_PRESUMIDO_OPERACAO_AQUISICAO_VINCULADA_RECEITAS_TRIBUTADAS_E_NAO_TRIBUTADAS_MERCADO_INTERNO",
  },
  CREDITO_PRESUMIDO_OPERACAO_AQUISICAO_VINCULADA_RECEITAS_TRIBUTADA_MERCADO_INTERNO_EXPORTACAO: {
    code: "64",
    name:
      "Crédito presumido operação aquisição vinculada receitas tributadas mercado interno exportação",
    value:
      "CREDITO_PRESUMIDO_OPERACAO_AQUISICAO_VINCULADA_RECEITAS_TRIBUTADA_MERCADO_INTERNO_EXPORTACAO",
  },
  CREDITO_PRESUMIDO_OPERACAO_AQUISICAO_VINCULADA_RECEITAS_NAO_TRIBUTADAS_MERCADO_INTERNO_EXPORTACAO: {
    code: "65",
    name:
      "Crédito presumido operação aquisição vinculada receitas não tributadas mercado interno exportação",
    value:
      "CREDITO_PRESUMIDO_OPERACAO_AQUISICAO_VINCULADA_RECEITAS_NAO_TRIBUTADAS_MERCADO_INTERNO_EXPORTACAO",
  },
  CREDITO_PRESUMIDO_OPERACAO_AQUISICAO_VINCULADA_RECEITAS_TRIBUTADAS_E_NAO_TRIBUTADAS_MERCADO_INTERNO_EXPORTACAO: {
    code: "66",
    name:
      "Crédito presumido operação aquisição vinculada receitas tributadas e não tributadas mercado interno exportação",
    value:
      "CREDITO_PRESUMIDO_OPERACAO_AQUISICAO_VINCULADA_RECEITAS_TRIBUTADAS_E_NAO_TRIBUTADAS_MERCADO_INTERNO_EXPORTACAO",
  },
  CREDITO_PRESUMIDO_OUTRAS_OPERACOES: {
    code: "67",
    name: "Crédito presumido outras operações",
    value: "CREDITO_PRESUMIDO_OUTRAS_OPERACOES",
  },
  OPERACAO_AQUISICAO_SEM_DIREITO_CREDITO: {
    code: "70",
    name: "Operação aquisição sem direito crédito",
    value: "OPERACAO_AQUISICAO_SEM_DIREITO_CREDITO",
  },
  OPERACAO_AQUISICAO_COM_ISENCAO: {
    code: "71",
    name: "Operação aquisição com isenção",
    value: "OPERACAO_AQUISICAO_COM_ISENCAO",
  },
  OPERACAO_AQUISICAO_COM_SUSPENSAO: {
    code: "72",
    name: "Operação aquisição com suspensão",
    value: "OPERACAO_AQUISICAO_COM_SUSPENSAO",
  },
  OPERACAO_AQUISICAO_ALIQUOTA_ZERO: {
    code: "73",
    name: "Operação aquisição alíquota zero",
    value: "OPERACAO_AQUISICAO_ALIQUOTA_ZERO",
  },
  OPERACAO_AQUISICAO_SEM_INCIDENCIA_CONTRIBUICAO: {
    code: "74",
    name: "Operação aquisição sem incidência de contribuição",
    value: "OPERACAO_AQUISICAO_SEM_INCIDENCIA_CONTRIBUICAO",
  },
  OPERACAO_AQUISICAO_POR_SUBSTITUICAO_TRIBUTARIA: {
    code: "75",
    name: "Operação aquisição por substituição tributária",
    value: "OPERACAO_AQUISICAO_POR_SUBSTITUICAO_TRIBUTARIA",
  },
  OUTRAS_OPERACOES_ENTRADA: {
    code: "98",
    name: "Outras operações de entrada",
    value: "OUTRAS_OPERACOES_ENTRADA",
  },
  OUTRAS_OPERACOES: { code: "99", name: "Outras operações", value: "OUTRAS_OPERACOES" },
};

const SituacaoTributariaIcms = {
  // SELECIONE,
  TRIBUTACAO_INTEGRALMENTE: {
    code: "00",
    name: "Tributada integralmente",
    value: "TRIBUTACAO_INTEGRALMENTE",
  },
  TRIBUTADA_COM_COBRANCA_ICMS_POR_SUBSTITUICAO_TRIBUTARIA: {
    code: "10",
    name: "Tributada com cobrança de ICMS por ST",
    value: "TRIBUTADA_COM_COBRANCA_ICMS_POR_SUBSTITUICAO_TRIBUTARIA",
  },
  COM_REDUCAO_BASE_CALCULO: {
    code: "20",
    name: "Com redução da base de cálculo",
    value: "COM_REDUCAO_BASE_CALCULO",
  },
  ISENTA_OU_NAO_TRIBUTADA_COM_COBRANCA_ICMS_POR_SUBSTITUICAO_TRIBUTARIA: {
    code: "30",
    name: "Isenta ou não tributada com cobrança de ICMS por ST",
    value: "ISENTA_OU_NAO_TRIBUTADA_COM_COBRANCA_ICMS_POR_SUBSTITUICAO_TRIBUTARIA",
  },
  ISENTA: { code: "40", name: "Isenta", value: "ISENTA" },
  NAO_TRIBUTADO: { code: "41", name: "Não tributada", value: "NAO_TRIBUTADO" },
  SUSPENSAO: { code: "50", name: "Suspensão", value: "SUSPENSAO" },
  DIFERIMENTO: { code: "51", name: "Diferimento", value: "DIFERIMENTO" },
  ICMS_COBRADO_ANTERIORMENTE_POR_SUBSTITUICAO_TRIBUTARIA: {
    code: "60",
    name: "ICMS cobrado anteriormente por ST",
    value: "ICMS_COBRADO_ANTERIORMENTE_POR_SUBSTITUICAO_TRIBUTARIA",
  },
  COM_REDUCAO_BASE_CALCULO_COBRANCA_ICMS_POR_SUBSTITUICAO_TRIBUTARIA_ICMS_SUBSTITUICAO_TRIBUTARIA: {
    code: "70",
    name: "Com redução da base de cálculo/Cobrança ICMS por ST/ICMS ST",
    value:
      "COM_REDUCAO_BASE_CALCULO_COBRANCA_ICMS_POR_SUBSTITUICAO_TRIBUTARIA_ICMS_SUBSTITUICAO_TRIBUTARIA",
  },
  OUTROS: { code: "90", name: "Outros", value: "OUTROS" },
};
const NFOrigem = {
  NACIONAL: { value: "NACIONAL", code: "0", name: "Nacional" },
  ESTRANGEIRA_IMPORTACAO_DIRETA: {
    value: "ESTRANGEIRA_IMPORTACAO_DIRETA",
    code: "1",
    name: "Estrangeira importação direta",
  },
  ESTRANGEIRA_ADQUIRIDA_MERCADO_INTERNO: {
    value: "ESTRANGEIRA_ADQUIRIDA_MERCADO_INTERNO",
    code: "2",
    name: "Estrangeira adquirida mercado interno",
  },
  NACIONAL_MERCADORIA_OU_BEM_CONTEUDO_IMPORTACAO_SUPERIOR_40_P: {
    value: "NACIONAL_MERCADORIA_OU_BEM_CONTEUDO_IMPORTACAO_SUPERIOR_40_P",
    code: "3",
    name: "Nacional mercadoria ou bem conteúdo importação superior 40 P",
  },
  NACIONAL_PRODUCAO_EM_CONFORMIDADE_COM_PROCESSOS_PRODUTIVOS_BASICOS: {
    value: "NACIONAL_PRODUCAO_EM_CONFORMIDADE_COM_PROCESSOS_PRODUTIVOS_BASICOS",
    code: "4",
    name: "Nacional produção em conformidade com processos produtivos básicos",
  },
  NACIONAL_MERCADORIA_OU_BEM_CONTEUDO_IMPORTACAO_INFERIOR_40_P: {
    value: "NACIONAL_MERCADORIA_OU_BEM_CONTEUDO_IMPORTACAO_INFERIOR_40_P",
    code: "5",
    name: "Nacional mercadoria ou bem conteúdo importação inferior 40 P",
  },
  ESTRANGEIRA_IMPORTACAO_DIRETA_SEM_SIMILAR_NACIONAL_CONSTANTE_EM_LISTA_CAMEX: {
    value: "ESTRANGEIRA_IMPORTACAO_DIRETA_SEM_SIMILAR_NACIONAL_CONSTANTE_EM_LISTA_CAMEX",
    code: "6",
    name: "Estrangeira importação direta sem similar nacional constante em lista Camex",
  },
  ESTRANGEIRA_ADQUIRIDA_MERCADO_INTERNO_SEM_SIMILAR_NACIONAL_CONSTANTE_EM_LISTA_CAMEX: {
    value: "ESTRANGEIRA_ADQUIRIDA_MERCADO_INTERNO_SEM_SIMILAR_NACIONAL_CONSTANTE_EM_LISTA_CAMEX",
    code: "7",
    name: "Estrangeira adquirida mercado interno sem similar nacional constante em lista Camex",
  },
  NACIONAL_MERCADORIA_OU_BEM_COM_CONTEUDO_IMPORTACAO_SUPERIOR_70_P: {
    value: "NACIONAL_MERCADORIA_OU_BEM_COM_CONTEUDO_IMPORTACAO_SUPERIOR_70_P",
    code: "8",
    name: "Nacional mercadoria ou bem conteúdo importação superior 70 P",
  },
};

const ItemCompoemValorTotalNfeType = {
  COMPOEM_VALOR_TOTAL: { code: "COMPOEM_VALOR_TOTAL", name: "Compõem total da nota fiscal" },
  COMPOEM_VALOR_TOTAL_SEM_GERAR_FATURA: {
    code: "COMPOEM_VALOR_TOTAL_SEM_GERAR_FATURA",
    name: "Compõem total da nota fiscal, mas sem gerar faturas no XML da NFe",
  },
  NAO: { code: "NAO", name: "Não, Todos os totalizadores e valores da nota serão zerados" },
};

const ReducaoBaseCalculoIcmsStType = {
  PADRAO: { code: "PADRAO", name: "Padrão" },
  ANTES_DE_SOMAR_IPI: { code: "ANTES_DE_SOMAR_IPI", name: "Antes de somar o IPI" },
};

// eslint-disable-next-line import/prefer-default-export

const NFRegimeTributario = {
  SIMPLES_NACIONAL: { code: "SIMPLES_NACIONAL", name: "Simples nacional" },
  SIMPLES_NACIONAL_EXCESSO_RECEITA: {
    code: "SIMPLES_NACIONAL_EXCESSO_RECEITA",
    name: "Simples nacional com excesso de sublimite da receita bruta",
  },
  NORMAL: { code: "NORMAL", name: "Regime normal" },
};

const VendaParaZonaFrancaDeManausType = {
  NAO: { code: "NAO", name: "Não" },
  SIM_COM_DESONERACAO_TODOS_PRODUTOS: {
    code: "SIM_COM_DESONERACAO_TODOS_PRODUTOS",
    name: "Sim, com desoneração para todos os produtos",
  },
  SIM_COM_DESONERACAO_PARA_PRODUTOS_NACIONAIS: {
    code: "SIM_COM_DESONERACAO_PARA_PRODUTOS_NACIONAIS",
    name: "Sim, com desoneração apenas para produtos com origem nacional",
  },
};

const TipoExcecao = {
  SIMPLES: "SIMPLES",
  IPI: "IPI",
  // ISSQN: "ISSQN",
  PIS: "PIS",
  COFINS: "COFINS",
  ICMS: "ICMS",
};

const TipoCalculoNFeType = {
  PERCENTUAL: { code: "PERCENTUAL", name: "Percentual" },
  VALOR: { code: "VALOR", name: "Valor" },
};

const NFNotaInfoItemModalidadeBCICMSST = {
  PRECO_TABELADO: { code: "PRECO_TABELADO", name: "Preço Tabelado" },
  LISTA_NEGATIVA: { code: "LISTA_NEGATIVA", name: "Lista Negativa" },
  LISTA_POSITIVA: { code: "LISTA_POSITIVA", name: "Lista Positiva" },
  LISTA_NEUTRA: { code: "LISTA_NEUTRA", name: "Lista Neutra" },
  MARGEM_VALOR_AGREGADO: { code: "MARGEM_VALOR_AGREGADO", name: "Margem Valor Agregado" },
  PAUTA: { code: "PAUTA", name: "Pauta" },
  VALOR_OPERACAO: { code: "VALOR_OPERACAO", name: "Valor Operação" },
};

const NFNotaInfoItemModalidadeBCICMS = {
  MVA: { code: "MVA", name: "Margem de valor agregado" },
  PAUTA: { code: "PAUTA", name: "Pauta" },
  PRECO_TABELADO_MAXIMO: { code: "PRECO_TABELADO_MAXIMO", name: "Preço tabelado máximo" },
  VALOR_OPERACAO: { code: "VALOR_OPERACAO", name: "Valor da operação" },
};

const NFTipo = {
  ENTRADA: { code: "ENTRADA", name: "Entrada" },
  SAIDA: { code: "SAIDA", name: "Saída" },
};

const NaturezaOperacaoPadraoType = {
  SEM_PADRAO: { code: "SEM_PADRAO", name: "Nenhum" },
  COMPRA: { code: "COMPRA", name: "Compra" },
  VENDA: { code: "VENDA", name: "Venda" },
  CUPOM_FISCAL: { code: "CUPOM_FISCAL", name: "Cupom fiscal (NFC-e)" },
};

const getNaturezaOperacaoObservacao = (naturezaOperacao) => {
  if (naturezaOperacao) {
    const { regimeTributario, observacaoIcms, observacaoSimples } = naturezaOperacao;
    if (NFRegimeTributario.SIMPLES_NACIONAL.code === regimeTributario && observacaoSimples) {
      return observacaoSimples;
    }

    if (observacaoIcms) {
      return observacaoIcms;
    }
  }
  return "";
};

export {
  SituacaoTributariaCofins,
  SituacaoTributariaIssqn,
  SituacaoTributariaPis,
  SituacaoTributariaIpi,
  SituacaoTributariaIcms,
  Finalidade,
  SituacaoOperacionalSimplesNacional,
  YesNo,
  NFOrigem,
  TipoExcecao,
  ItemCompoemValorTotalNfeType,
  ReducaoBaseCalculoIcmsStType,
  NFRegimeTributario,
  VendaParaZonaFrancaDeManausType,
  TipoCalculoNFeType,
  NFNotaInfoItemModalidadeBCICMSST,
  NFNotaInfoItemModalidadeBCICMS,
  NFTipo,
  NaturezaOperacaoPadraoType,
  getNaturezaOperacaoObservacao,
  NaturezaOperacao,
};
