import axios from "axios";
import { isEmptyString } from "config/util";
import { APIS } from "../../../config/constants";
import BaseService from "../../../core/BaseService";

const { register: registerAPI } = APIS;

class CestService extends BaseService {
  constructor() {
    super(registerAPI.cest);
  }

  fetchByAutoComplete = (term, id) => {
    const params = [];

    if (!isEmptyString(term)) {
      params.push(`term=${term}`);
    }

    if (!isEmptyString(id)) {
      params.push(`id=${id}`);
    }
    return axios.get(
      `${this.endpoint}/autocomplete-search?size=30&sort=cest,ASC&${params.join("&")}`
    );
  };
}

export default new CestService();
