import React, { useState, useEffect, useCallback, useRef } from "react";
import { useDebounce } from "use-debounce";
import { makeStyles } from "@mui/styles";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import CardStyle from "assets/jss/material-dashboard-react/components/cardStyle";
import CustomComponentsStyle from "assets/jss/material-dashboard-react/components/customComponentsStyle";
import ButtonCustom from "components/CustomButtons/Button";
import { Box, TextField } from "@mui/material";
import AccordionFilters from "components/Accordion/AccordionFilters";
import Service from "./service/ServiceProductService";
import MESSAGES from "../../config/messages";
import Loading from "../../components/loading/Loading";
import AddButton from "../../components/addButton/AddButton";
import { currencyFormatter } from "../../config/util";
import EditIcon from "../../components/icons/EditIcon";
import DataGridCustom from "../../components/dataGrid/DataGridCustom";

const { generalMessages, productMessages } = MESSAGES;

const useCardStyle = makeStyles(CardStyle);
const useCustomComponentsStyle = makeStyles(CustomComponentsStyle);

export default function ServiceProductList() {
  const customGridRef = useRef();
  const classesuseCardStyle = useCardStyle();
  const classesCustomComponentsStyle = useCustomComponentsStyle();
  const history = useHistory();
  const [list, setList] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [rowCount, setRowCount] = React.useState(0);

  const fetchAll = useCallback(
    async ({ searchParams, paginationModelParams, sorting }) => {
      setLoading(true);
      const pagination = paginationModelParams || customGridRef.current.getDefaultPaginationModel();
      try {
        const result = await Service.fetchAll({ term: searchParams, pagination, sorting });
        const { data } = result;
        const { content, totalElements } = data;

        setRowCount(totalElements);
        setList(content);
      } catch (error) {
        enqueueSnackbar(generalMessages.error, {
          variant: "error",
        });
      } finally {
        setLoading(false);
      }
    },
    [enqueueSnackbar]
  );

  useEffect(() => {
    fetchAll({ searchParams: "" });
  }, [fetchAll]);

  const onKeyPress = useCallback(
    (ev) => {
      if (ev.which === 13) {
        fetchAll({ searchParams: searchTerm });
        customGridRef.current.resetPaginationModel();
      }
    },
    [fetchAll, searchTerm]
  );

  const goToForm = (params) =>
    params?.id ? history.push(`/app/prodservice/${params?.id}`) : history.push("/app/prodservice");

  const columns = [];

  columns.push({
    field: "industryCode",
    headerName: "Código",
    width: 130,
  });

  columns.push({
    field: "name",
    headerName: productMessages.name,
    minWidth: 250,
    flex: 2,
  });

  columns.push({
    field: "saleValue",
    headerName: "Valor",
    width: 150,
    valueGetter: ({ value }) => currencyFormatter(value || 0),
  });

  columns.push({
    field: "active",
    headerName: "Ativo",
    minWidth: 120,
    flex: 1,
    valueGetter: ({ value }) => (value ? "Sim" : "Não"),
  });

  columns.push({
    field: "actions",
    headerName: generalMessages.actions,
    sortable: false,
    width: 100,
    disableClickEventBubbling: true,
    renderCell: (params) => (
      <div
        style={{
          cursor: "pointer",
          justifyContent: "center",
          padding: "10px",
          display: "flex",
          flex: 1,
        }}
      >
        <EditIcon onClick={() => goToForm({ id: params.row.id })} />
      </div>
    ),
  });

  const onClear = useCallback(() => {
    fetchAll({ searchParams: "" });
    setSearchTerm("");
    customGridRef.current.resetPaginationModel();
  }, [fetchAll]);

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card className="gridLayoutCard">
          <div className="gridLayoutContainer">
            <CardHeader color="primary">
              <h4 className={classesuseCardStyle.cardTitleList}>Serviços</h4>
              <p className={classesuseCardStyle.cardCategoryList}>Lista de serviços cadastrados</p>
            </CardHeader>

            <div className="gridLayoutFilters">
              <AccordionFilters screen="service_product_list">
                <GridContainer>
                  <GridItem xs={12} sm={12} md={8}>
                    <TextField
                      size="small"
                      onKeyPress={onKeyPress}
                      variant="outlined"
                      id="number"
                      label="Digite para pesquisar por Código, Nome"
                      fullWidth
                      InputLabelProps={{
                        className: classesCustomComponentsStyle.labelTextField,
                      }}
                      className={classesCustomComponentsStyle.textField}
                      value={searchTerm == null ? "" : searchTerm}
                      onChange={(value) => setSearchTerm(value.currentTarget.value)}
                    />
                  </GridItem>

                  <GridItem xs={12} sm={12} md={4} style={{ marginTop: 6, flexBasis: 0 }}>
                    <Box display="flex">
                      <ButtonCustom
                        color="primary"
                        onClick={() => {
                          fetchAll({ searchParams: searchTerm });
                          customGridRef.current.resetPaginationModel();
                        }}
                      >
                        {generalMessages.search}
                      </ButtonCustom>
                      <ButtonCustom color="primary" onClick={onClear}>
                        Limpar
                      </ButtonCustom>
                    </Box>
                  </GridItem>
                </GridContainer>
              </AccordionFilters>
            </div>

            <div className="gridLayoutGrid">
              <DataGridCustom
                ref={customGridRef}
                onRowDoubleClick={goToForm}
                rows={list}
                columns={columns}
                usePagination
                rowCount={rowCount}
                fetchAction={(params) =>
                  fetchAll({
                    ...params,
                    searchParams: searchTerm,
                  })
                }
              />
            </div>
            <div className="gridLayoutBottom">
              <AddButton onClick={() => goToForm(null)} />
            </div>
          </div>
        </Card>
      </GridItem>
      <Loading loading={loading} />
    </GridContainer>
  );
}
