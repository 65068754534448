import React from "react";
import { Paper, InputBase, IconButton } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faTimes } from "@fortawesome/free-solid-svg-icons";

import PropTypes from "prop-types";

const Searchbar = (props) => {
  const { onChange, searchTerm, placeholder } = props;

  const keyPress = (e) => {
    if (e.keyCode === 13) {
      e.stopPropagation();
      return false;
    }
    return true;
  };

  return (
    <Paper
      component="form"
      elevation={2}
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      <div style={{ display: "flex", marginBottom: "10px" }}>
        <InputBase
          // sx={{ ml: 1, flex: 1 }}
          // size="small"
          style={{ padding: 6 }}
          placeholder={placeholder}
          fullWidth
          value={searchTerm}
          onChange={(value) => onChange(value.currentTarget.value)}
          onKeyDown={keyPress}
        />
        {searchTerm === "" ? (
          <IconButton sx={{ p: "10px" }} aria-label="search">
            <FontAwesomeIcon icon={faSearch} fontSize={20} />
          </IconButton>
        ) : (
          <IconButton onClick={() => onChange("")} sx={{ p: "10px" }} aria-label="search">
            <FontAwesomeIcon icon={faTimes} fontSize={20} />
          </IconButton>
        )}
      </div>
    </Paper>
  );
};

Searchbar.propTypes = {
  onChange: PropTypes.func.isRequired,
  searchTerm: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
};

export default React.memo(Searchbar);
