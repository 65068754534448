class ServiceProduct {
  constructor(source = {}) {
    if (source == null) return this;

    this.id = source.id;
    this.name = source.name;
    this.industryCode = source.industryCode;
    this.saleValue = source.saleValue;
    this.commissionable = source.commissionable;
    this.additionalInformation = source.additionalInformation;
    this.version = source.version;
    this.active = source.active;
  }
}

export default ServiceProduct;
