class Calendar {
  constructor(source = {}) {
    this.id = source.id;
    this.name = source.name;
    this.startDate = source.startDate;
    this.endDate = source.endDate;
    this.version = source.version;
  }
}

export default Calendar;
