import axios from "axios";
import { APIS } from "../../../config/constants";
import BaseService from "../../../core/BaseService";

const { register: registerAPI } = APIS;

class NotaFiscalService extends BaseService {
  constructor() {
    super(registerAPI.notaFiscal);
  }

  searchWithParams = ({ params, pagination, sorting }) => {
    const { page, pageSize } = pagination;
    const { field = "createdAt", sort = "desc" } = sorting || {};
    return axios.get(
      `${this.endpoint}/search?size=${pageSize}&page=${page}&sort=${field},${sort}&${params}`
    );
  };

  calculaImpostoTotal = (data) => axios.post(`${this.endpoint}/calcula-imposto-total`, data);

  emitir = (id) => axios.post(`${this.endpoint}/${id}/emitir`);

  vendaToNF = (orderId, documentType) =>
    axios.post(`${this.endpoint}/order/${orderId}/to/${documentType}`);

  osToNF = (serviceOrderId, documentType) =>
    axios.post(`${this.endpoint}/service-order/${serviceOrderId}/to/${documentType}`);

  cancelarNotaFiscal = (id, motivoCancelamento) =>
    axios.post(`${this.endpoint}/${id}/cancelar`, { motivoCancelamento });

  fetchHistorico = (id) => axios.get(`${this.endpoint}/${id}/historico`);

  ambiente = () => axios.get(`${this.endpoint}/ambiente`);

  sendEmail = (email, notaFiscalId) =>
    axios.post(`${this.endpoint}/${notaFiscalId}/email/${email}/send`);

  printDanfe = (id) =>
    axios.get(`${this.endpoint}/danfe/${id}`, {
      responseType: "blob",
    });

  xml = (id) =>
    axios.get(`${this.endpoint}/xml/${id}`, {
      responseType: "blob",
    });

  manifestar = (id, evento) => axios.post(`${this.endpoint}/${id}/manifestar`, { evento });

  empresaAptaEmissao = async () => {
    try {
      const response = await axios.get(`${this.endpoint}/empresa-apta-emissao`);
      const { status } = response;
      return {
        status,
        errors: null,
      };
    } catch (error) {
      const { response } = error;
      const {
        status,
        data: { errors },
      } = response;
      if (status === 422) {
        return {
          status,
          errors,
        };
      }
    }
    return null;
  };
}

export default new NotaFiscalService();
