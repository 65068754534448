const ProfileBusiness = {
  SALES_GENERAL: {
    code: "SALES_GENERAL",
    name: "Vendas em geral",
  },
  ASSISTANCE: {
    code: "ASSISTANCE",
    name: "Assistência",
  },
  WHOLESALE_RETAIL: {
    code: "WHOLESALE_RETAIL",
    name: "Atacado e varejo",
  },
  AUTO_PARTS: {
    code: "AUTO_PARTS",
    name: "Auto peças",
  },
  DRINKS: {
    code: "DRINKS",
    name: "Bebidas",
  },
  SHOE: {
    code: "SHOE",
    name: "Calçados",
  },
  COMPUTING: {
    code: "COMPUTING",
    name: "Informática",
  },
  CLEANING: {
    code: "CLEANING",
    name: "Limpeza",
  },
  MOTORCYCLE_MECHANIC: {
    code: "MOTORCYCLE_MECHANIC",
    name: "Oficina de motos",
  },
  CAR_MECHANIC: {
    code: "CAR_MECHANIC",
    name: "Oficina de carros",
  },
  UTILITIES: {
    code: "UTILITIES",
    name: "Utilidades",
  },
  CLOTHING: {
    code: "CLOTHING",
    name: "Vestuário",
  },
  OTHERS: {
    code: "OTHERS",
    name: "Outros",
  },
};

export default ProfileBusiness;
