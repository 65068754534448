import moment from "moment";
import { Commission } from "../model/Commission";

const toRequest = (item) => {
  const {
    id,
    name,
    totalCommission = 0,
    totalService = 0,
    totalProduct = 0,
    percentageServiceCommission = 0,
    percentageProductCommission = 0,
    originType,
    originId,
    user,
    commissionDate,
    canceled,
    version,
    goal,
    calculated,
    orderValueTotal,
    orderValueTotalService,
    orderValueTotalProduct,
  } = item;

  return {
    id,
    name,
    totalCommission,
    totalService,
    totalProduct,
    percentageServiceCommission,
    percentageProductCommission,
    originType,
    originId,
    user,
    commissionDate: moment(commissionDate).format(),
    canceled,
    version,
    goal,
    calculated,
    orderValueTotal,
    orderValueTotalService,
    orderValueTotalProduct,
  };
};

const fromResponse = (response) => {
  if (response === null) return new Commission();

  const {
    id,
    name,
    totalCommission,
    totalService,
    totalProduct,
    percentageServiceCommission,
    percentageProductCommission,
    originType,
    originId,
    user,
    commissionDate,
    canceled,
    version,
    goal,
    calculated,
    orderValueTotal,
    orderValueTotalService,
    orderValueTotalProduct,
    commissionConfiguration,
    goalServicePercentage,
    goalProductPercentage,
    goalStartTrackProduct,
    goalStartTrackService,
    goalTargetCalculationMethod,
    goalEndTrackProduct,
    goalEndTrackService,
  } = response;

  return new Commission({
    id,
    name,
    totalCommission,
    totalService,
    totalProduct,
    percentageServiceCommission,
    percentageProductCommission,
    originType,
    originId,
    user,
    commissionDate,
    canceled,
    version,
    goal,
    calculated,
    orderValueTotal,
    orderValueTotalService,
    orderValueTotalProduct,
    commissionConfiguration,
    goalServicePercentage,
    goalProductPercentage,
    goalStartTrackProduct,
    goalStartTrackService,
    goalTargetCalculationMethod,
    goalEndTrackProduct,
    goalEndTrackService,
  });
};

export { toRequest, fromResponse };
