/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useCallback, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import CustomComponentsStyle from "assets/jss/material-dashboard-react/components/customComponentsStyle";
import CardStyle from "assets/jss/material-dashboard-react/components/cardStyle";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import Button from "components/CustomButtons/Button";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import CardFooter from "components/Card/CardFooter";
import { useHistory, useParams } from "react-router-dom";
import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useSnackbar } from "notistack";
import SelectEnum from "components/selectEnum/SelectEnum";
import HistoryIcon from "components/icons/HistoryIcon";
import NFeInutilizacaoService from "./service/NFeInutilizacaoService";
import { NFeInutilizacao, DFModelo, NFeInutilizacaoStatusType } from "./model/NFeInutilizacao";
import { toRequest, fromResponse } from "./converter/NFeInutilizacaoConverter";
import { handlingResponseErrors } from "../../config/util";
import MESSAGES from "../../config/messages";
import Loading from "../../components/loading/Loading";
import TextInput from "../../components/textInput/TextInput";
import NFeInutilizacaoHistorico from "./components/dialog/NFeInutilizacaoHistorico";

const { generalMessages, naturezaOperacaoMessages } = MESSAGES;

const useCustomComponentsStyle = makeStyles(CustomComponentsStyle);
const useCardStyle = makeStyles(CardStyle);

// eslint-disable-next-line react/prop-types
export default function NFeInutilizacaoForm() {
  const routerParams = useParams();
  const classesCustomComponentsStyle = useCustomComponentsStyle();
  const classesCardStyle = useCardStyle();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [errors, setErrors] = useState({});
  const [readOnly, setReadOnly] = useState(false);
  const [loading, setLoading] = useState(false);

  const [openHistoricoNotaFiscalModal, setOpenHistoricoNotaFiscalModal] = useState(false);
  const [notaFiscalHistoricoList, setNotaFiscalHistoricoList] = useState([]);

  const [entity, setEntity] = useState(
    new NFeInutilizacao({
      active: true,
      modelo: DFModelo.NFE.code,
    })
  );

  const handleChange = useCallback(
    (field, value) => {
      setEntity({
        ...entity,
        [field]: value,
      });
    },
    [entity]
  );

  const handleOpenHistoricoNotaFiscalModal = useCallback(async () => {
    setOpenHistoricoNotaFiscalModal(true);
    try {
      setLoading(true);
      const { data } = await NFeInutilizacaoService.fetchHistorico(entity?.id);
      const historicoList = data?.data || [];
      setNotaFiscalHistoricoList(historicoList);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [entity?.id]);

  const handleCloseHistoricoNotaFiscalModal = useCallback(() => {
    setOpenHistoricoNotaFiscalModal(false);
    setNotaFiscalHistoricoList([]);
  }, []);

  const isTransmitida = useCallback(
    (status) => NFeInutilizacaoStatusType.TRANSMITIDA.code === status,
    []
  );

  const getById = useCallback(
    async (id) => {
      if (id != null) {
        const result = await NFeInutilizacaoService.fetchById(id);
        const response = fromResponse(result?.data?.data);

        setEntity(response);

        const { status } = response;
        const readOnlyByStatus = isTransmitida(status);
        setReadOnly(readOnlyByStatus);
      }
    },
    [isTransmitida]
  );

  const fetchById = useCallback(async () => {
    try {
      setLoading(true);
      const { id } = routerParams || {};
      await getById(id);
    } finally {
      setLoading(false);
    }
  }, [getById, routerParams]);

  useEffect(() => {
    fetchById();
  }, [fetchById]);

  const goBack = useCallback(() => {
    history.push("/app/nfe-inutilizacoes");
  }, [history]);

  const save = useCallback(async () => {
    const { id } = entity;
    let response = null;
    if (id != null) {
      response = await NFeInutilizacaoService.update(id, toRequest(entity));
    } else {
      response = NFeInutilizacaoService.save(toRequest(entity));
    }
    return response;
  }, [entity]);

  const onInutilizar = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await save();
      const id = data?.data?.id || null;
      /*
       Após salvar, seta o id, pois se a emissão falhar, a nota vai ficar sem id e na proxima tentativa vai criar uma nova (duplicando) 
       */
      setEntity({
        ...entity,
        id,
      });

      try {
        await NFeInutilizacaoService.inutilizar(id);
        enqueueSnackbar("Inutilização efetuada com sucesso!", {
          variant: "success",
          autoHideDuration: 3000,
        });
        setLoading(false);
        goBack();
      } catch (error) {
        /*
          Caso a emissão não funcionar, o version da entidade está mudando, precisa recarregar. 
          Assim, na proxima tentativa o version estará atualizado
        */
        getById(id);
        const { message } = handlingResponseErrors(error);
        enqueueSnackbar(message, {
          variant: "error",
          autoHideDuration: 3000,
        });
        setLoading(false);
      }
    } catch (error) {
      const { message } = handlingResponseErrors(error);
      enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
      setLoading(false);
    }
  }, [save, entity, enqueueSnackbar, goBack, getById]);

  const onSave = useCallback(async () => {
    try {
      setLoading(true);
      await save();
      enqueueSnackbar(generalMessages.saveSuccess, {
        variant: "success",
        autoHideDuration: 3000,
      });
      goBack();
    } catch (error) {
      const { message, validationErrors } = handlingResponseErrors(error);
      enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
      if (validationErrors != null) {
        setErrors(validationErrors);
      }
    } finally {
      setLoading(false);
    }
  }, [save, enqueueSnackbar, goBack]);

  const renderModelo = () => (
    <GridItem xs={12} sm={12} md={3}>
      <SelectEnum
        disabled={readOnly}
        label="Modelo"
        field="modelo"
        Enum={DFModelo}
        valueSelected={entity.modelo}
        handleChange={handleChange}
        errors={errors}
        required
      />
    </GridItem>
  );

  const renderSeries = () => (
    <GridItem xs={12} sm={12} md={3}>
      <TextInput
        autoFocus
        disabled={readOnly}
        id="serie"
        label="Série NFe"
        value={entity.serie}
        onChange={handleChange}
        required
        error={errors}
      />
    </GridItem>
  );

  const renderNumeroInicial = () => (
    <GridItem xs={12} sm={12} md={3}>
      <TextInput
        disabled={readOnly}
        id="numeroInicial"
        label="Número inicial"
        value={entity.numeroInicial}
        onChange={handleChange}
        required
        errors={errors}
      />
    </GridItem>
  );

  const renderNumeroFinal = () => (
    <GridItem xs={12} sm={12} md={3}>
      <TextInput
        disabled={readOnly}
        id="numeroFinal"
        label="Número final"
        value={entity.numeroFinal}
        onChange={handleChange}
        required
        errors={errors}
      />
    </GridItem>
  );

  const renderJustificativa = () => (
    <GridItem xs={12} sm={12} md={12}>
      <TextInput
        disabled={readOnly}
        id="justificativa"
        label="Justificativa"
        value={entity.justificativa}
        onChange={handleChange}
        required
        errors={errors}
      />
    </GridItem>
  );

  const isNew = useCallback((id) => id == null, []);
  return (
    <>
      <GridContainer style={{ justifyContent: "center" }}>
        <GridItem xs={12} sm={12} md={10}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classesCardStyle.cardTitle}>Inutilização Nota Fiscal</h4>
              <p className={classesCardStyle.cardCategory}>
                Cadastro de novas inutilizações de notas fiscais
              </p>
            </CardHeader>
            <CardBody>
              <Box display="flex" justifyContent="flex-end">
                {!isNew(entity.id) && (
                  <HistoryIcon
                    onClick={handleOpenHistoricoNotaFiscalModal}
                    style={{ padding: 6 }}
                  />
                )}
              </Box>
              <GridContainer>
                {[
                  renderModelo(),
                  renderSeries(),
                  renderNumeroInicial(),
                  renderNumeroFinal(),
                  renderJustificativa(),
                ]}
              </GridContainer>
            </CardBody>
            <CardFooter>
              <Box display="flex" justifyContent="space-between" width="100%">
                <Box display="flex">
                  <Button color="primary" onClick={goBack}>
                    {generalMessages.cancel}
                  </Button>
                </Box>
                {!readOnly && (
                  <Box display="flex">
                    <>
                      <Button color="primary" onClick={onSave}>
                        {generalMessages.save}
                      </Button>
                      <Button color="primary" onClick={onInutilizar}>
                        Salvar e inutilizar
                      </Button>
                    </>
                  </Box>
                )}
              </Box>
            </CardFooter>
          </Card>
        </GridItem>
        <NFeInutilizacaoHistorico
          open={openHistoricoNotaFiscalModal}
          onClose={handleCloseHistoricoNotaFiscalModal}
          items={notaFiscalHistoricoList}
        />

        <Loading loading={loading} />
      </GridContainer>
    </>
  );
}
