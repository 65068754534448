import axios from "axios";
import { APIS } from "../../../config/constants";
import BaseService from "../../../core/BaseService";

const { register: registerAPI } = APIS;

class PaymentMethodService extends BaseService {
  constructor() {
    super(registerAPI.paymentMethod);
  }

  fetchActives = () => axios.get(`${this.endpoint}/all-actives`);
}

export default new PaymentMethodService();
