/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import CustomComponentsStyle from "assets/jss/material-dashboard-react/components/customComponentsStyle";
import { makeStyles } from "@mui/styles";
import { Box, Button as ButtonUI, Grid, Divider, Typography, Modal } from "@mui/material";
import { useSnackbar } from "notistack";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { primaryColor } from "assets/jss/material-dashboard-react";
import DeleteIcon from "components/icons/DeleteIcon";
import Autocomplete from "components/autocomplete/Autocomplete";
import MESSAGES from "config/messages";
import PersonService from "views/person/service/PersonService";
import { RegistrationType } from "views/person/model/Person";
import PersonForm from "views/person/PersonForm";
import CurrencyTextInput from "../../../components/textInput/CurrencyTextInput";

const CommissionGoal = ({ items = [], handleChange, errors }) => {

  const { commissionMessages } = MESSAGES;

  const add = useCallback(() => {
    const newItem = { index: items.length };
    const newItems = [...items, newItem];
    handleChange(newItems);
  }, [handleChange, items]);

  const remove = useCallback(
    (i) => {
      let newItems = [...items];
      const index = newItems.findIndex((item) => item.index === i);
      newItems.splice(index, 1);
      // Changing the indexes
      newItems = newItems.map((item, idx) => ({ ...item, index: idx }));
      handleChange(newItems);
    },
    [handleChange, items]
  );

  const onChange = useCallback(
    (field, value, index) => {
      const newItems = [...items];

      const item = newItems[index];
      let newItem = {};

      if (field != null) {
        newItem = {
          ...item,
          [field]: value,
        };
      } else {
        newItem = {
          ...item,
          ...value,
        };
      }

      newItems[index] = newItem;
      handleChange(newItems);
    },
    [handleChange, items]
  );

  const renderAddButton = () => (
    <GridItem xs={12} sm={12} md={3}>
      <Box display="flex" style={{ marginTop: 12 }}>
        <ButtonUI style={{ color: primaryColor[0] }} onClick={add}>
          Adicionar outra faixa
        </ButtonUI>
      </Box>
    </GridItem>
  );

  const renderDelete = (item) => (
    <Box>
      <DeleteIcon onClick={() => remove(item.index)} />
    </Box>
  );

  const renderItem = (item) => (
    <GridContainer>
      <GridItem xs={12} sm={12} md={3}>
        <CurrencyTextInput
          id="startTrack"
          value={item.startTrack}
          label="Valor de inicio da meta"
          onChange={(field, value) => onChange("startTrack", value, item.index)}
          errors={errors}
        />
      </GridItem>
      <GridItem xs={12} sm={12} md={3}>
        <CurrencyTextInput
          id="endTrack"
          value={item.endTrack}
          label="Valor de fim da meta"
          onChange={(field, value) => onChange("endTrack", value, item.index)}
          errors={errors}
        />
      </GridItem>
      <GridItem xs={12} sm={12} md={2}>
        <CurrencyTextInput
          id="serviceCommission"
          currencySymbol="%"
          label={commissionMessages.serviceCommission}
          value={item.serviceCommission || "0"}
          onChange={(field, value) => onChange("serviceCommission", value, item.index)}
          errors={errors}
        />
      </GridItem>
      <GridItem xs={12} sm={12} md={2}>
        <CurrencyTextInput
          id="productCommission"
          currencySymbol="%"
          label={commissionMessages.productCommission}
          value={item.productCommission || "0"}
          onChange={(field, value) => onChange("productCommission", value, item.index)}
          errors={errors}
        />
      </GridItem>

      <GridItem xs={12} sm={12} md={1}>
        <Box
          style={{ marginTop: 6, alignItems: "center", height: "100%" }}
          flexDirection="row"
          display="flex"
        >
          {renderDelete(item)}
        </Box>
      </GridItem>
    </GridContainer>
  );

  const renderItems = () => {
    if (items.length === 0) {
      return add();
    }
    return items.map((item) => renderItem(item));
  };

  return (
    <Grid item xs={12} sm={12} md={12}>
      <GridItem xs={12} sm={12} md={12} style={{ marginTop: 30 }}>
        <Divider style={{ width: "100%", marginBottom: 4 }} />
        <Typography style={{ fontSize: 18, fontWeight: 500 }}>Valores e porcentagem de cada meta</Typography>
      </GridItem>
      {renderItems()}
      {renderAddButton()}
    </Grid>
  );
};

export default React.memo(CommissionGoal);
