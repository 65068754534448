import React, { useState, useEffect, useCallback, useRef } from "react";
import { makeStyles } from "@mui/styles";
import { Chip, TextField, MenuItem, Select, FormControl, Box } from "@mui/material";
import GridItem from "components/Grid/GridItem";
import CustomComponentsStyle from "assets/jss/material-dashboard-react/components/customComponentsStyle";
import GridContainer from "components/Grid/GridContainer";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import ButtonCustom from "components/CustomButtons/Button";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import CardStyle from "assets/jss/material-dashboard-react/components/cardStyle";
import AccordionFilters from "components/Accordion/AccordionFilters";
import { formatDate, isEmptyString } from "../../config/util";
import OrderService from "./service/OrderService";
import { OrderType, OrderTypeFilter } from "./model/Order";
import { NotaFiscalStatusType } from "../notaFiscal/model/NotaFiscal";
import MESSAGES from "../../config/messages";
import Loading from "../../components/loading/Loading";
import AddButton from "../../components/addButton/AddButton";
import EditIcon from "../../components/icons/EditIcon";
import SeeIcon from "../../components/icons/SeeIcon";
import DataGridCustom from "../../components/dataGrid/DataGridCustom";

const { generalMessages, orderMessages } = MESSAGES;
const useCardStyle = makeStyles(CardStyle);
const useCustomComponentsStyle = makeStyles(CustomComponentsStyle);
const initialSearch = {
  number: "",
  customerName: "",
  userName: "",
  orderType: OrderTypeFilter.ALL.code,
};

export default function OrderList() {
  const customGridRef = useRef();

  const classesCustomComponentsStyle = useCustomComponentsStyle();
  const classesuseCardStyle = useCardStyle();
  const history = useHistory();
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [search, setSearch] = useState(initialSearch);

  const [rowCount, setRowCount] = React.useState(0);

  const fetchAll = useCallback(
    async ({ searchParams, paginationModelParams, sorting }) => {
      setLoading(true);

      const pagination = paginationModelParams || customGridRef.current.getDefaultPaginationModel();

      const params = [];

      const { customerName = "", userName = "", number = "", orderType } = searchParams || {};

      if (!isEmptyString(customerName)) {
        params.push(`customerName=${customerName}`);
      }
      if (!isEmptyString(userName)) {
        params.push(`userName=${userName}`);
      }
      if (!isEmptyString(number)) {
        params.push(`number=${number}`);
      }
      if (orderType != null && orderType !== OrderTypeFilter.ALL.code) {
        params.push(`orderType=${orderType}`);
      }

      try {
        const result = await OrderService.fetchAll({
          params: params.join("&"),
          pagination,
          sorting,
        });
        const { data } = result;
        const { content, totalElements } = data;

        setRowCount(totalElements);

        setList(content);
      } catch (error) {
        enqueueSnackbar(generalMessages.error, {
          variant: "error",
        });
      } finally {
        setLoading(false);
      }
    },
    [enqueueSnackbar]
  );

  useEffect(() => {
    fetchAll({});
  }, [fetchAll]);

  const onKeyPress = useCallback(
    (ev) => {
      if (ev.which === 13) {
        fetchAll({ searchParams: search });
        customGridRef.current.resetPaginationModel();
      }
    },
    [fetchAll, search]
  );

  const goToForm = (params) => {
    const { id, cancelled, readOnly, orderType } = params;

    // if (cancelled) return;

    if (id) {
      history.push(`/app/order/${params?.id}`, {
        readOnly: readOnly || orderType === OrderType.SERVICE_ORDER.code || cancelled,
      });
    } else {
      history.push("/app/order");
    }
  };

  const formatterOrderType = (item) => {
    const { cancelled, orderType } = item;
    if (orderType != null && orderType !== undefined) {
      switch (orderType) {
        case OrderType.ORDER.code:
          return cancelled ? (
            <Chip
              size="small"
              label={orderMessages.orderCancelled}
              style={{ backgroundColor: "#e53935", color: "#ffff" }}
            />
          ) : (
            <Chip
              size="small"
              label={OrderType[orderType].name}
              style={{ backgroundColor: "#0e850b", color: "#ffff" }}
            />
          );
        case OrderType.NFE.code:
        case OrderType.NFCE.code:
          return cancelled ? (
            <Chip
              size="small"
              label={orderMessages.orderCancelled}
              style={{ backgroundColor: "#e53935", color: "#ffff" }}
            />
          ) : (
            <Chip
              component="a"
              onClick={() =>
                history.push(
                  `/app/${item?.orderType?.toLowerCase()}-emissao/${item?.notaFiscal?.id}`
                )
              }
              clickable
              size="small"
              label={`${OrderType[orderType].name} - ${
                NotaFiscalStatusType[item?.notaFiscal?.status]?.name
              }`}
              style={{ backgroundColor: "#4c74da", color: "#ffff" }}
            />
          );
        case OrderType.BUDGET.code:
          return cancelled ? (
            <Chip
              size="small"
              label={orderMessages.orderCancelled}
              style={{ backgroundColor: "#e53935", color: "#ffff" }}
            />
          ) : (
            <Chip
              size="small"
              label={OrderType[orderType].name}
              style={{ backgroundColor: "#AA810D", color: "#ffff" }}
            />
          );
        default:
          return "";
      }
    }
    return "";
  };

  const formatterOrderDate = (item) => {
    const { finalizationDate, createdAt, orderType } = item;
    const date = orderType === "ORDER" ? finalizationDate : createdAt;
    return formatDate(date);
  };

  // const formatterPaymentMethod = (value) => {
  //   if (value != null && value !== undefined) {
  //     return PaymentMethod[value].name;
  //   }
  //   return "";
  // };

  const columns = [
    {
      field: "orderNumber",
      headerName: orderMessages.orderNumber,
      valueGetter: ({ value }) => value,
      minWidth: 120,
      flex: 2,
    },
    {
      field: "createdAt",
      headerName: orderMessages.orderDate,
      flex: 2,
      minWidth: 150,
      renderCell: ({ row: item }) => formatterOrderDate(item),
    },
    {
      field: "customerName",
      headerName: orderMessages.customer,
      flex: 2,
      minWidth: 200,
      valueGetter: ({ value }) => value,
    },
    {
      field: "userName",
      headerName: orderMessages.generalStep.saller,
      flex: 2,
      minWidth: 200,
      valueGetter: ({ value }) => value,
    },
    {
      field: "orderType",
      headerName: orderMessages.type,
      flex: 1,
      minWidth: 120,
      renderCell: ({ row: item }) => formatterOrderType(item),
    },
    {
      field: "actions",
      headerName: generalMessages.actions,
      sortable: false,
      width: 100,
      disableClickEventBubbling: true,
      renderCell: ({ row: item }) => (
        <div
          style={{
            cursor: "pointer",
            justifyContent: "space-between",
            padding: "10px",
            display: "flex",
            flex: 1,
          }}
        >
          {item.orderType === OrderType.BUDGET.code ? (
            <>
              {!item.cancelled && <EditIcon onClick={() => goToForm({ id: item.id })} />}
              <SeeIcon onClick={() => goToForm({ id: item.id, readOnly: true })} />
            </>
          ) : (
            <SeeIcon onClick={() => goToForm({ id: item.id, readOnly: true })} />
          )}
        </div>
      ),
    },
  ];

  const handleChange = useCallback(
    (field, value) => {
      setSearch({
        ...search,
        [field]: value,
      });
    },
    [search]
  );

  const renderSelectPeriodSearch = () => (
    <FormControl variant="outlined" style={{ marginTop: 12, width: "100%" }} size="small">
      <Select
        autoWidth
        value={search.orderType}
        onChange={(event) => {
          const { value } = event.target;
          handleChange("orderType", value);
        }}
      >
        {Object.keys(OrderTypeFilter).map((key) => {
          const { code, name } = OrderTypeFilter[key];
          return (
            <MenuItem key={code} value={code}>
              {name}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );

  const onClear = useCallback(() => {
    fetchAll({ searchParams: initialSearch });
    setSearch(initialSearch);
    customGridRef.current.resetPaginationModel();
  }, [fetchAll]);

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card className="gridLayoutCard">
          <div className="gridLayoutContainer">
            <CardHeader color="primary">
              <h4 className={classesuseCardStyle.cardTitleList}>Vendas e Orçamentos</h4>
              <p className={classesuseCardStyle.cardCategoryList}>
                {orderMessages.ordersBudgetTable}
              </p>
            </CardHeader>

            <div className="gridLayoutFilters">
              <AccordionFilters screen="order_list">
                <GridContainer>
                  <GridItem xs={12} sm={12} md={4}>
                    <TextField
                      size="small"
                      onKeyPress={onKeyPress}
                      variant="outlined"
                      id="number"
                      label={orderMessages.orderNumber}
                      fullWidth
                      InputLabelProps={{
                        className: classesCustomComponentsStyle.labelTextField,
                      }}
                      className={classesCustomComponentsStyle.textField}
                      value={search.number == null ? "" : search.number}
                      onChange={(value) => handleChange("number", value.currentTarget.value)}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={10} />
                  <GridItem xs={12} sm={12} md={4}>
                    <TextField
                      size="small"
                      onKeyPress={onKeyPress}
                      variant="outlined"
                      id="customerName"
                      label={orderMessages.generalStep.customer}
                      fullWidth
                      InputLabelProps={{
                        className: classesCustomComponentsStyle.labelTextField,
                      }}
                      className={classesCustomComponentsStyle.textField}
                      value={search.customerName == null ? "" : search.customerName}
                      onChange={(value) => handleChange("customerName", value.currentTarget.value)}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    <TextField
                      size="small"
                      onKeyPress={onKeyPress}
                      variant="outlined"
                      id="userName"
                      label={orderMessages.generalStep.saller}
                      fullWidth
                      InputLabelProps={{
                        className: classesCustomComponentsStyle.labelTextField,
                      }}
                      className={classesCustomComponentsStyle.textField}
                      value={search.userName == null ? "" : search.userName}
                      onChange={(value) => handleChange("userName", value.currentTarget.value)}
                    />
                  </GridItem>

                  <GridItem xs={12} sm={12} md={2}>
                    {renderSelectPeriodSearch()}
                  </GridItem>
                  <GridItem
                    xs={12}
                    sm={12}
                    md={3}
                    style={{ marginTop: 6, flexBasis: 0, flexGrow: 1 }}
                  >
                    <Box display="flex" justifyContent="flex-end" flexGrow={1}>
                      <ButtonCustom
                        color="primary"
                        onClick={() => {
                          fetchAll({ searchParams: search });
                          customGridRef.current.resetPaginationModel();
                        }}
                      >
                        {generalMessages.search}
                      </ButtonCustom>
                      <ButtonCustom color="primary" onClick={onClear}>
                        Limpar
                      </ButtonCustom>
                    </Box>
                  </GridItem>
                </GridContainer>
              </AccordionFilters>
            </div>

            <div className="gridLayoutGrid">
              <DataGridCustom
                ref={customGridRef}
                onRowDoubleClick={({ row }) => goToForm(row)}
                rows={list}
                columns={columns}
                usePagination
                rowCount={rowCount}
                fetchAction={(params) =>
                  fetchAll({
                    ...params,
                    searchParams: search,
                  })
                }
              />
            </div>
            <div className="gridLayoutBottom">
              <AddButton onClick={() => goToForm({ id: null })} />
            </div>
          </div>
        </Card>
      </GridItem>
      <Loading loading={loading} />
    </GridContainer>
  );
}
