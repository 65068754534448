import FinancialAccounts from "../model/FinancialAccounts";

const toRequest = (request) => {
  const {
    id,
    name,
    standard,
    active,
    version,
  } = request;

  return {
    id,
    name,
    standard,
    active,
    version,
  };
};

const fromResponse = (response) => {
  if (response === null) return new FinancialAccounts();

  const {
    id,
    name,
    standard,
    active,
    version,
  } = response;

  return new FinancialAccounts({
    id,
    name,
    standard,
    active,
    version,
  });
};

export { toRequest, fromResponse };
