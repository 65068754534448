/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React from "react";
import { MenuItem, Menu } from "@mui/material";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";

const DropDownButtonIcon = ({ Icon, options = [], style }) => (
  <PopupState variant="popover" popupId="dropdown-popup-menu">
    {(popupState) => (
      <div style={style}>
        <Icon {...bindTrigger(popupState)} />
        <Menu {...bindMenu(popupState)}>
          {options.map((option) => (
            <MenuItem
              onClick={() => {
                option.action();
                popupState.close();
              }}
            >
              {option.label}
            </MenuItem>
          ))}
        </Menu>
      </div>
    )}
  </PopupState>
);

export default DropDownButtonIcon;
