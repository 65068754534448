/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useCallback, useEffect } from "react";
import { makeStyles } from "@mui/styles";

import CardStyle from "assets/jss/material-dashboard-react/components/cardStyle";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import Button from "components/CustomButtons/Button";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import CardFooter from "components/Card/CardFooter";
import { useHistory, useParams } from "react-router-dom";
import { FormControlLabel, FormGroup, Switch } from "@mui/material";
import { useSnackbar } from "notistack";
import UnitMeasurement from "./model/UnitMeasurement";
import { toRequest, fromResponse } from "./converter/UnitMeasurementConverter";
import UnitMeasurementService from "./service/UnitMeasurementService";
import { handlingResponseErrors } from "../../config/util";
import MESSAGES from "../../config/messages";
import Loading from "../../components/loading/Loading";
import TextInput from "../../components/textInput/TextInput";

const { generalMessages, unitMeasurementMessages } = MESSAGES;

const useCardStyle = makeStyles(CardStyle);

// eslint-disable-next-line react/prop-types
export default function UnitMeasurementForm({ onCancel, afterSave, name }) {
  const classesCardStyle = useCardStyle();
  const history = useHistory();
  const routerParams = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const [unit, setUnit] = useState(new UnitMeasurement({ name, active: true }));

  const fetchById = useCallback(async () => {
    try {
      setLoading(true);
      const { id } = routerParams || {};
      if (id != null) {
        const result = await UnitMeasurementService.fetchById(id);
        const response = fromResponse(result?.data?.data);
        setUnit(response);
      }
    } finally {
      setLoading(false);
    }
  }, [routerParams]);

  useEffect(() => {
    fetchById();
  }, [fetchById]);

  const handleChange = useCallback(
    (field, value) => {
      setUnit({
        ...unit,
        [field]: value,
      });
    },
    [unit]
  );

  const goBack = useCallback(() => {
    if (onCancel) {
      onCancel();
    } else {
      history.push("/app/units-measurement");
    }
  }, [history, onCancel]);

  const onSave = useCallback(async () => {
    try {
      setLoading(true);
      const { id } = unit;
      if (id != null) {
        await UnitMeasurementService.update(id, toRequest(unit));
      } else {
        const response = await UnitMeasurementService.save(toRequest(unit));
        if (afterSave) {
          afterSave(response?.data?.data);
        }
      }
      enqueueSnackbar(generalMessages.saveSuccess, {
        variant: "success",
        autoHideDuration: 3000,
      });
      goBack();
    } catch (error) {
      const { message, validationErrors } = handlingResponseErrors(error);
      enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
      if (validationErrors != null) {
        setErrors(validationErrors);
      }
    } finally {
      setLoading(false);
    }
  }, [unit, goBack, enqueueSnackbar, afterSave]);

  const renderSwitch = (value = false, field, label, disabled = false) => (
    <FormGroup style={{ paddingTop: "8px" }}>
      <FormControlLabel
        control={
          <Switch
            color="secondary"
            checked={value}
            onChange={(event) => handleChange(field, event.target.checked)}
          />
        }
        label={label}
        disabled={disabled}
      />
    </FormGroup>
  );

  return (
    <>
      <GridContainer style={{ justifyContent: "center" }}>
        <GridItem xs={12} sm={12} md={10}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classesCardStyle.cardTitle}>
                {unitMeasurementMessages.unitMeasurement}
              </h4>
              <p className={classesCardStyle.cardCategory}>
                {unitMeasurementMessages.unitMeasurementForm}
              </p>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={3}>
                  <TextInput
                    autoFocus
                    id="acronym"
                    label={unitMeasurementMessages.acronym}
                    value={unit.acronym}
                    onChange={handleChange}
                    required
                    maxLength={6}
                    errors={errors}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={9} />
                <GridItem xs={12} sm={12} md={12}>
                  <TextInput
                    autoFocus
                    id="name"
                    label={unitMeasurementMessages.name}
                    value={unit.name}
                    onChange={handleChange}
                    required
                    errors={errors}
                  />
                </GridItem>

                <GridItem xs={12} sm={12} md={12}>
                  {renderSwitch(unit.active, "active", "Ativo")}
                </GridItem>
              </GridContainer>
            </CardBody>
            <CardFooter>
              <Button color="primary" onClick={goBack}>
                {generalMessages.cancel}
              </Button>
              <Button color="primary" onClick={onSave}>
                {generalMessages.save}
              </Button>
            </CardFooter>
          </Card>
        </GridItem>
        <Loading loading={loading} />
      </GridContainer>
    </>
  );
}
