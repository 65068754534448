class PartilhaIcms {
  constructor(source = {}) {
    if (source == null) return this;

    this.id = source.id;
    this.possuiPartilhaIcmsInterestadual = source.possuiPartilhaIcmsInterestadual;
    this.partilhaIcmsInterestadualValores = source.partilhaIcmsInterestadualValores;
    this.version = source.version;
  }
}

export default PartilhaIcms;
