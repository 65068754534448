class UnitMeasurement {
  constructor(source = {}) {
    if (source == null) return this;

    this.id = source.id;
    this.acronym = source.acronym;
    this.name = source.name;
    this.version = source.version;
    this.active = source.active;
  }
}

export default UnitMeasurement;
