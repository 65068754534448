const Style = {
    emptyContainer: {
        backgroundColor: "#f0fcff",
        paddingLeft: 16,
        paddingRight: 16,
        paddingTop: 4,
        paddingBottom: 4,
        borderRadius: 8,
        color: "#696969"
    },
};

export default Style;
