/* eslint-disable react/prop-types */
import React, { useCallback } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Fab } from "@mui/material";
import GridContainer from "components/Grid/GridContainer";
import { primaryColor } from "../../assets/jss/material-dashboard-react";

const DEFAULT_PROPS = {
  title: "Confirmação",
  text: "Deseja confirmar a operação?",
  onClickConfirm: null,
  onClickCancel: null,
  textConfirm: "Confirmar",
  textCancel: "Cancelar",
};

const useConfirmDialog = () => {
  const [open, setOpen] = React.useState(false);
  const [props, setProps] = React.useState(DEFAULT_PROPS);
  const { title, text, onClickConfirm, onClickCancel, textConfirm, textCancel } = props;

  const showConfirmDialog = useCallback(
    (propsInstance) => {
      setOpen(true);
      setProps({
        ...props,
        ...propsInstance,
      });
    },
    [props]
  );

  const closeConfirmDialog = () => {
    setOpen(false);
  };

  const handleConfirm = useCallback(() => {
    if (onClickConfirm) {
      return onClickConfirm();
    }
    return null;
  }, [onClickConfirm]);

  const handleCancel = useCallback(() => {
    if (onClickCancel) {
      return onClickCancel();
    }
    return closeConfirmDialog();
  }, [onClickCancel]);

  const ConfirmDialog = () => (
    <>
      <Dialog disableEscapeKeyDown open={open}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <GridContainer>
            <div dangerouslySetInnerHTML={{ __html: text }} />
          </GridContainer>
        </DialogContent>
        <DialogActions>
          <div style={{ flexGrow: 1, display: "flex", justifyContent: "space-between" }}>
            <Button style={{ color: primaryColor[0] }} onClick={handleCancel}>
              {textCancel}
            </Button>

            <Button style={{ color: primaryColor[0] }} onClick={handleConfirm}>
              {textConfirm}
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </>
  );

  return { showConfirmDialog, closeConfirmDialog, ConfirmDialog };
};

export default useConfirmDialog;
