/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/button-has-type */
/* eslint-disable react/prop-types */
import React, { useCallback, useRef, useState } from "react";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";
import { Button } from "@mui/material";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faXmark } from "@fortawesome/free-solid-svg-icons";

const useEmpresaNaoAptaAlert = () => {
  const history = useHistory();
  const snackbarId = useRef(null);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const close = () => {
    closeSnackbar(snackbarId.current);
  };

  const goCompany = () => {
    history.push("/app/company");
    close();
  };

  // const action = (snackbarId) => (
  //   <>
  //     <Button
  //       size="small"
  //       style={{ color: "#FFFFFF" }}
  //       onClick={() => {
  //         goCompany();
  //         closeSnackbar(snackbarId);
  //       }}
  //     >
  //       Atualizar
  //     </Button>
  //     <FontAwesomeIcon
  //       icon={faXmark}
  //       onClick={() => closeSnackbar(snackbarId)}
  //       fontSize={20}
  //       title="Visualizar"
  //       color="#FFFFFF"
  //       // style={style}
  //     />
  //     <Button size="small" style={{ color: "#FFFFFF" }} onClick={() => {}}>
  //       Fechar
  //     </Button>
  //   </>
  // );

  const showEmpresaNaoAptaAlert = (errors) => {
    const key = enqueueSnackbar(
      <span>
        É necessário atualizar os dados a seguir para emitir a NF-e{" "}
        <ul>
          {errors.map((err) => (
            <li>{err.error}</li>
          ))}
        </ul>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <strong>
            <a href="javascript:;" onClick={close} style={{ color: "#FFFFFF" }}>
              Fechar
            </a>
          </strong>
        </div>
      </span>,
      {
        // action,
        variant: "error",
        // persist: true,
        autoHideDuration: 5000,
        preventDuplicate: true,
        disableWindowBlurListener: true,
      }
    );
    snackbarId.current = key;
  };

  return { showEmpresaNaoAptaAlert };
};

export default useEmpresaNaoAptaAlert;
