import axios from "axios";
import { APIS } from "../../../config/constants";
import BaseService from "../../../core/BaseService";

const { register: registerAPI } = APIS;

class AddressService extends BaseService {
  constructor() {
    super("");
  }

  fetchStates = () => axios.get(`${registerAPI.state}?size=50`);

  fetchCitiesByState = (stateId) => axios.get(`${registerAPI.city}/state/${stateId}`);
}

export default new AddressService();
