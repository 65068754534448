import {
  primaryColor,
  dangerColor,
  successColor,
  grayColor,
  defaultFont,
} from "assets/jss/material-dashboard-react.js";

const customComponentsStyle = {
  formControlSelect: {
    marginTop: "12px",
    position: "relative",
    verticalAlign: "unset",
  },
  formControlRadio: {
    color: primaryColor[0],
    margin: "10px 0 0 0",
    position: "relative",
    verticalAlign: "unset",
  },
  formLabel: {
    ...defaultFont,
    color: grayColor[3] + " !important",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "1.42857",
    letterSpacing: "unset",
  },
  formControlLabel: {
    ...defaultFont,
    color: grayColor[3] + " !important",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "1.42857",
    letterSpacing: "unset",
  },
  labelTextField: {
    fontSize: "14px",
  },
  textField: {
    marginTop: "12px !important",
    width: "100%",
  },

  sectionText: {
    color: `${primaryColor[1]}`,
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "400",
    fontFamily: "'Nunito', 'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: grayColor[1],
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

export default customComponentsStyle;
