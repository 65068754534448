import UnitConversion from "../model/UnitConversion";

const toRequest = (unitConversion) => {
  const { id, conversionFactor, sourceUnit, targetUnit, version, active } = unitConversion;

  return {
    id,
    conversionFactor,
    sourceUnit,
    targetUnit,
    version,
    active,
  };
};

const fromResponse = (response) => {
  if (response === null) return new UnitConversion();

  const { id, conversionFactor, sourceUnit, targetUnit, version, active } = response;

  return new UnitConversion({
    id,
    conversionFactor,
    sourceUnit,
    targetUnit,
    version,
    active,
  });
};

export { toRequest, fromResponse };
