import React from "react";
import { Switch, Route, Redirect, useHistory } from "react-router-dom";
// import ReactGA from "react-ga";
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import routes from "routes.js";
import bgImage from "assets/img/sidebar-2.jpg";

let ps;

const switchRoutes = (
  <Switch>
    {routes.map((prop, key) => {
      if (prop.layout === "/welcome") {
        // eslint-disable-next-line react/no-array-index-key
        return <Route path={prop.layout + prop.path} component={prop.component} key={key} />;
      }
      return null;
    })}
    <Redirect from="/app" to="/app/orders" />
  </Switch>
);

// const useStyles = makeStyles(styles);

export default function Welcome({ ...rest }) {
  // const history = useHistory();
  // styles
  // const classes = useStyles();
  // ref to help us initialize PerfectScrollbar on windows devices
  const mainPanel = React.createRef();
  // states and functions
  const [image] = React.useState(bgImage);
  const [color] = React.useState("blue");
  // TODO See here
  // TODO See here
  // TODO See here
  // TODO See here
  const [mobileOpen, setMobileOpen] = React.useState(false);
  document.title = `Kairum | Welcome`;

  // history.listen((location) => {
  //   ReactGA.pageview(location.pathname + location.search);
  //   console.log("ReactGA - Welcome layout");
  // });

  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };
  // initialize and destroy the PerfectScrollbar plugin
  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(mainPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
      document.body.style.overflow = "hidden";
    }
    window.addEventListener("resize", resizeFunction);
    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
      window.removeEventListener("resize", resizeFunction);
    };
  }, [mainPanel]);
  return <div ref={mainPanel}>{switchRoutes}</div>;
}
