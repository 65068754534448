/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useCallback, useRef } from "react";
import { makeStyles } from "@mui/styles";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import CardStyle from "assets/jss/material-dashboard-react/components/cardStyle";
import ButtonCustom from "components/CustomButtons/Button";
import TextInput from "components/textInput/TextInput";
import moment from "moment";
import { DatePicker } from "@mui/x-date-pickers";
import { Box, Chip, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import customComponentsStyle from "assets/jss/material-dashboard-react/components/customComponentsStyle";
import HistoryIcon from "components/icons/HistoryIcon";
import ManifestacaoIcon from "components/icons/ManifestacaoIcon";
import SeeIcon from "components/icons/SeeIcon";
import NotaFiscalHistorico from "views/notaFiscal/components/dialog/NotaFiscalHistorico";
import {
  NotaFiscalStatusType,
  NotaFiscalImportacaoOrigemType,
  NFEventoManifestacaoDestinatarioType,
} from "views/notaFiscal/model/NotaFiscal";
import { NFTipo } from "views/naturezaOperacao/model/NaturezaOperacao";
import DropDownButtonIcon from "components/dropDownButton/DropDownButtonIcon";
import useEmpresaNaoAptaAlert from "hooks/useEmpresaNaoAptaAlert";
import AccordionFilters from "components/Accordion/AccordionFilters";
import NotaFiscalService from "../notaFiscal/service/NotaFiscalService";
import MESSAGES from "../../config/messages";
import Loading from "../../components/loading/Loading";
import AddButton from "../../components/addButton/AddButton";
import { currencyFormatter, handlingResponseErrors } from "../../config/util";
import EditIcon from "../../components/icons/EditIcon";
import DataGridCustom from "../../components/dataGrid/DataGridCustom";
import NFeEntradaImportarXML from "./components/importarXML/NFeEntradaImportarXML";

const { generalMessages } = MESSAGES;

const useCardStyle = makeStyles(CardStyle);

const SearchAll = {
  All: {
    code: "ALL",
    name: "Todos",
  },
};

const initialSearch = {
  numero: null,
  nomeEmitente: null,
  data: null,
  status: SearchAll.All.code,
  schema: SearchAll.All.code,
  statusManifestacao: SearchAll.All.code,
  origemImportacao: SearchAll.All.code,
};

export default function NFeEntradaList() {
  const customGridRef = useRef();
  const classesuseCardStyle = useCardStyle();
  const useCustomComponentsStyle = makeStyles(customComponentsStyle);
  const classesCustomComponentsStyle = useCustomComponentsStyle();
  const history = useHistory();
  const [list, setList] = useState([]);
  const [search, setSearch] = useState(initialSearch);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [openHistoricoNotaFiscalModal, setOpenHistoricoNotaFiscalModal] = useState(false);
  const [openImportarXML, setOpenImportarXML] = useState(false);
  const [notaFiscalHistoricoList, setNotaFiscalHistoricoList] = useState([]);
  const { showEmpresaNaoAptaAlert } = useEmpresaNaoAptaAlert();
  const [rowCount, setRowCount] = React.useState(0);

  const handleChangeSearch = useCallback(
    (field, value) => {
      setSearch({
        ...search,
        [field]: value,
      });
    },
    [search]
  );

  const fetchAll = useCallback(
    async ({ searchParams, paginationModelParams, sorting }) => {
      setLoading(true);
      try {
        const params = [];
        const pagination =
          paginationModelParams || customGridRef.current.getDefaultPaginationModel();

        params.push(`queryField=tipo;${NFTipo.ENTRADA.code}`);

        if (searchParams.numero != null) {
          params.push(`queryField=numero;${searchParams.numero}`);
        }
        if (searchParams.nomeEmitente != null) {
          params.push(`queryField=nomeEmitente;${searchParams.nomeEmitente}`);
        }
        if (searchParams.data != null) {
          params.push(`queryField=dataHoraEmissao;${searchParams.data}`);
        }
        if (searchParams.status != null && searchParams.status !== SearchAll.All.code) {
          params.push(`queryField=status;${searchParams.status}`);
        }
        if (searchParams.schema != null && searchParams.schema !== SearchAll.All.code) {
          params.push(`queryField=notaFiscalImportada.schema;${searchParams.schema}`);
        }
        if (
          searchParams.statusManifestacao != null &&
          searchParams.statusManifestacao !== SearchAll.All.code
        ) {
          params.push(
            `queryField=notaFiscalImportada.statusManifestacao;${searchParams.statusManifestacao}`
          );
        }
        if (
          searchParams.origemImportacao != null &&
          searchParams.origemImportacao !== SearchAll.All.code
        ) {
          params.push(
            `queryField=notaFiscalImportada.origemImportacao;${searchParams.origemImportacao}`
          );
        }

        const result = await NotaFiscalService.searchWithParams({
          params: params.join("&"),
          pagination,
          sorting,
        });

        const { data } = result;
        const { content, totalElements } = data;

        setList(content);
        setRowCount(totalElements);
      } catch (error) {
        enqueueSnackbar(generalMessages.error, {
          variant: "error",
        });
      } finally {
        setLoading(false);
      }
    },
    [enqueueSnackbar]
  );

  useEffect(() => {
    fetchAll({ searchParams: initialSearch });
  }, [fetchAll]);

  const goToForm = async (params) => {
    const result = await NotaFiscalService.empresaAptaEmissao();
    if (result == null) {
      enqueueSnackbar("Ocorreu um erro!", {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
    const { errors, status } = result;
    if (status === 200) {
      return params?.id
        ? history.push(`/app/nfe-entrada/${params?.id}`)
        : history.push("/app/nfe-entrada");
    }
    if (status === 422) {
      return showEmpresaNaoAptaAlert(errors);
    }
    return null;
  };

  const goToFormWithoutValidation = async (params) =>
    params?.id ? history.push(`/app/nfe-entrada/${params?.id}`) : history.push("/app/nfe-entrada");

  const formatterOrigem = (item) => {
    const origemImportacao = item?.notaFiscalImportada?.origemImportacao;

    if (origemImportacao != null) {
      switch (origemImportacao) {
        case NotaFiscalImportacaoOrigemType.UPLOAD_XML.code:
          return (
            <Chip
              size="small"
              label={NotaFiscalImportacaoOrigemType[origemImportacao].name}
              style={{ backgroundColor: "#1186e3", color: "#ffff" }}
            />
          );

        case NotaFiscalImportacaoOrigemType.AUTOMATICA.code:
          return (
            <Chip
              size="small"
              label={NotaFiscalImportacaoOrigemType[origemImportacao].name}
              style={{ backgroundColor: "#0e850b", color: "#ffff" }}
            />
          );

        default:
          return "";
      }
    }

    if (item?.notaFiscalImportada == null) {
      return (
        <Chip
          size="small"
          label={NotaFiscalImportacaoOrigemType.NOTA_ENTRADA.name}
          style={{ backgroundColor: "#aa810d", color: "#ffff" }}
        />
      );
    }
    return "";
  };
  const formatterManifestacao = (item) => {
    const { notaFiscalImportada } = item;
    const statusManifestacao = notaFiscalImportada?.statusManifestacao;

    if (statusManifestacao != null && statusManifestacao !== undefined) {
      switch (statusManifestacao) {
        case NFEventoManifestacaoDestinatarioType.PENDENTE_DE_MANIFESTACAO.code:
          return (
            <Chip
              size="small"
              label={NFEventoManifestacaoDestinatarioType[statusManifestacao].name}
              style={{ backgroundColor: "#aa810d", color: "#ffff" }}
            />
          );

        case NFEventoManifestacaoDestinatarioType.CIENCIA_DA_EMISSAO.code:
          return (
            <Chip
              size="small"
              label={NFEventoManifestacaoDestinatarioType[statusManifestacao].name}
              style={{ backgroundColor: "#1186e3", color: "#ffff" }}
            />
          );

        case NFEventoManifestacaoDestinatarioType.CONFIRMACAO_DA_OPERACAO.code:
          return (
            <Chip
              size="small"
              label={NFEventoManifestacaoDestinatarioType[statusManifestacao].name}
              style={{ backgroundColor: "#0e850b", color: "#ffff" }}
            />
          );

        case NFEventoManifestacaoDestinatarioType.DESCONHECIMENTO_DA_OPERACAO.code:
          return (
            <Chip
              size="small"
              label={NFEventoManifestacaoDestinatarioType[statusManifestacao].name}
              style={{ backgroundColor: "#e53935", color: "#ffff" }}
            />
          );

        case NFEventoManifestacaoDestinatarioType.OPERACAO_NAO_REALIZADA.code:
          return (
            <Chip
              size="small"
              label={NFEventoManifestacaoDestinatarioType[statusManifestacao].name}
              style={{ backgroundColor: "#e53935", color: "#ffff" }}
            />
          );
        default:
          return "";
      }
    }
    return "";
  };

  const fornecedorFormatter = (item) => {
    const origemImportacao = item?.notaFiscalImportada?.origemImportacao;
    if (origemImportacao != null) {
      if (
        origemImportacao === NotaFiscalImportacaoOrigemType.UPLOAD_XML.code ||
        origemImportacao === NotaFiscalImportacaoOrigemType.AUTOMATICA.code
      ) {
        return item.nomeEmitente;
      }
    }
    return item.nomeDestinatario;
  };

  const formatterTipoSchemaNfe = (item) => {
    const notaFiscalImportada = item?.notaFiscalImportada;
    const schema = notaFiscalImportada?.schema;
    const { status } = item;

    if (notaFiscalImportada != null) {
      if (schema) {
        if (schema.startsWith("resNFe")) {
          return (
            <Chip
              size="small"
              label="Resumo"
              style={{ backgroundColor: "#1186e3", color: "#ffff" }}
            />
          );
        }

        if (schema.startsWith("procNFe")) {
          return (
            <Chip
              size="small"
              label="Completa"
              style={{ backgroundColor: "#0e850b", color: "#ffff" }}
            />
          );
        }
      }
    } else if (status != null && status !== undefined) {
      switch (status) {
        case NotaFiscalStatusType.CRIADA.code:
          return (
            <Chip
              size="small"
              label={NotaFiscalStatusType[status].name}
              style={{ backgroundColor: "#1186e3", color: "#ffff" }}
            />
          );

        case NotaFiscalStatusType.EMITIDA.code:
          return (
            <Chip
              size="small"
              label={NotaFiscalStatusType[status].name}
              style={{ backgroundColor: "#0e850b", color: "#ffff" }}
            />
          );

        case NotaFiscalStatusType.ERRO.code:
          return (
            <Chip
              size="small"
              label={NotaFiscalStatusType[status].name}
              style={{ backgroundColor: "#e53935", color: "#ffff" }}
            />
          );
        case NotaFiscalStatusType.CANCELADA.code:
          return (
            <Chip
              size="small"
              label={NotaFiscalStatusType[status].name}
              style={{ backgroundColor: "#aa810d", color: "#ffff" }}
            />
          );
        default:
          return "";
      }
    }
    return "";
  };

  const isVisualizacao = (item) => {
    const { status } = item;
    const schema = item?.notaFiscalImportada?.schema;

    if (!schema) {
      return (
        status === NotaFiscalStatusType.EMITIDA.code ||
        status === NotaFiscalStatusType.CANCELADA.code
      );
    }
    return schema.startsWith("procNFe");
  };

  const isPendenteManifestacao = (item) => {
    const { notaFiscalImportada } = item;
    const statusManifestacao = notaFiscalImportada?.statusManifestacao;

    return (
      statusManifestacao === NFEventoManifestacaoDestinatarioType.PENDENTE_DE_MANIFESTACAO.code
    );
  };
  const isCienciaEmissao = (item) => {
    const { notaFiscalImportada } = item;
    const statusManifestacao = notaFiscalImportada?.statusManifestacao;

    return statusManifestacao === NFEventoManifestacaoDestinatarioType.CIENCIA_DA_EMISSAO.code;
  };

  const handleOpenHistoricoNotaFiscalModal = useCallback(async (id) => {
    setOpenHistoricoNotaFiscalModal(true);
    try {
      setLoading(true);
      const { data } = await NotaFiscalService.fetchHistorico(id);
      const historicoList = data?.data || [];
      setNotaFiscalHistoricoList(historicoList);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, []);

  const handleCloseHistoricoNotaFiscalModal = useCallback(() => {
    setOpenHistoricoNotaFiscalModal(false);
    setNotaFiscalHistoricoList([]);
  }, []);

  const manifestar = useCallback(
    async (id, evento) => {
      try {
        setLoading(true);
        const response = await NotaFiscalService.manifestar(id, evento);
        const itemResult = response?.data?.data;
        setList((prevList) => {
          const newList = [...prevList];
          const i = newList.findIndex((item) => item.id === itemResult.id);
          newList[i] = itemResult;
          return newList;
        });
        enqueueSnackbar("Operação realizada com sucesso!", {
          variant: "success",
          autoHideDuration: 3000,
        });
      } catch (error) {
        const { message } = handlingResponseErrors(error);
        enqueueSnackbar(message, {
          variant: "error",
          autoHideDuration: 3000,
        });
      } finally {
        setLoading(false);
      }
    },
    [enqueueSnackbar]
  );

  const getOptions = useCallback(
    (item) => {
      const items = [];
      const { id } = item;
      const pendenteManifestacao = isPendenteManifestacao(item);
      const cienciaEmissao = isCienciaEmissao(item);

      if (pendenteManifestacao) {
        items.push({
          label: "Ciência da emissão",
          action: () => {
            manifestar(id, NFEventoManifestacaoDestinatarioType.CIENCIA_DA_EMISSAO.code);
          },
        });
      } else if (cienciaEmissao) {
        items.push({
          label: "Confirmação da Operação",
          action: () => {
            manifestar(id, NFEventoManifestacaoDestinatarioType.CONFIRMACAO_DA_OPERACAO.code);
          },
        });
        items.push({
          label: "Desconhecimento da Operação",
          action: () => {
            manifestar(id, NFEventoManifestacaoDestinatarioType.DESCONHECIMENTO_DA_OPERACAO.code);
          },
        });
        items.push({
          label: "Operação nao Realizada",
          action: () => {
            manifestar(id, NFEventoManifestacaoDestinatarioType.OPERACAO_NAO_REALIZADA.code);
          },
        });
      }

      return items;
    },
    [manifestar]
  );

  const getIconColumns = (item) => {
    const { notaFiscalImportada, status } = item;
    const schema = notaFiscalImportada?.schema;
    const origemImportacao = notaFiscalImportada?.origemImportacao;

    const pendenteManifestacao = isPendenteManifestacao(item);
    const cienciaEmissao = isCienciaEmissao(item);
    const visualizacao = isVisualizacao(item);

    const cols = [];

    if (pendenteManifestacao || (cienciaEmissao && schema?.startsWith("procNFe"))) {
      cols.push(
        <DropDownButtonIcon
          Icon={ManifestacaoIcon}
          options={getOptions(item)}
          style={{ padding: 6, marginTop: 6 }}
        />
      );
    }

    if (visualizacao) {
      cols.push(
        <SeeIcon
          onClick={() => goToFormWithoutValidation({ id: item?.id })}
          style={{ padding: 6 }}
        />
      );
    }

    if (
      notaFiscalImportada == null &&
      status !== NotaFiscalStatusType.EMITIDA.code &&
      status !== NotaFiscalStatusType.CANCELADA.code
    ) {
      cols.push(<EditIcon onClick={() => goToForm({ id: item?.id })} style={{ padding: 6 }} />);
    }

    cols.push(
      <HistoryIcon
        onClick={() => handleOpenHistoricoNotaFiscalModal(item?.id || null)}
        style={{ padding: 6 }}
      />
    );

    return cols;
  };

  const getColumns = () => {
    const columns = [
      {
        field: "nomeEmitente",
        headerName: "Fornecedor",
        flex: 2,
        minWidth: 120,
        valueGetter: ({ row: item }) => fornecedorFormatter(item),
      },
      {
        field: "totalNf",
        headerName: "Total",
        flex: 1,
        minWidth: 120,
        valueGetter: ({ value }) => currencyFormatter(value),
      },
      {
        field: "dataHoraEmissao",
        headerName: "Data/Hora emissão",
        minWidth: 200,
        flex: 1,
        valueGetter: ({ value }) => (!value ? "--" : moment(value).format("DD/MM/YYYY HH:mm")),
      },
      {
        field: "status",
        headerName: "Situação",
        width: 140,
        renderCell: ({ row: item }) => (
          <div
            style={{
              justifyContent: "center",
              padding: "10px",
              display: "flex",

              flex: 1,
            }}
          >
            {formatterTipoSchemaNfe(item)}
          </div>
        ),
      },
      {
        field: "origem",
        headerName: "Origem",
        width: 150,
        renderCell: ({ row: item }) => (
          <div
            style={{
              justifyContent: "center",
              padding: "10px",
              display: "flex",

              flex: 1,
            }}
          >
            {formatterOrigem(item)}
          </div>
        ),
      },
      {
        field: "manifestacao",
        headerName: "Manifestação",
        width: 220,
        renderCell: ({ row: item }) => (
          <div
            style={{
              justifyContent: "center",
              padding: "10px",
              display: "flex",

              flex: 1,
            }}
          >
            {formatterManifestacao(item)}
          </div>
        ),
      },
    ];

    columns.push({
      field: "actions",
      headerName: generalMessages.actions,
      sortable: false,
      width: 150,
      disableClickEventBubbling: true,
      renderCell: (params) => (
        <div
          style={{
            cursor: "pointer",
            justifyContent: "flex-end",
            alignItems: "center",
            padding: "10px",
            display: "flex",
            flex: 1,
          }}
        >
          {getIconColumns(params.row)}
        </div>
      ),
    });

    return columns;
  };

  const onSearch = useCallback(() => {
    fetchAll({ searchParams: search });
    customGridRef.current.resetPaginationModel();
  }, [fetchAll, search]);

  const onClear = useCallback(() => {
    fetchAll({ searchParams: initialSearch });
    customGridRef.current.resetPaginationModel();

    setSearch(initialSearch);
  }, [fetchAll]);

  const renderStatus = () => (
    <FormControl
      fullWidth
      className={classesCustomComponentsStyle.formControlSelect}
      size="small"
      variant="outlined"
    >
      <InputLabel>Situação</InputLabel>
      <Select
        label="Situação"
        autoWidth
        value={search.status == null ? "" : search.status}
        onChange={(event) => {
          const {
            target: { value },
          } = event;
          return handleChangeSearch("status", value);
        }}
      >
        <MenuItem key={SearchAll.All.code} value={SearchAll.All.code}>
          {SearchAll.All.name}
        </MenuItem>
        {Object.keys(NotaFiscalStatusType).map((key) => {
          const { code, name } = NotaFiscalStatusType[key];
          return (
            <MenuItem key={code} value={code}>
              {name}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );

  const renderTipo = () => (
    <FormControl
      fullWidth
      className={classesCustomComponentsStyle.formControlSelect}
      size="small"
      variant="outlined"
    >
      <InputLabel>Tipo</InputLabel>
      <Select
        label="Tipo"
        autoWidth
        value={search.schema == null ? "" : search.schema}
        onChange={(event) => {
          const {
            target: { value },
          } = event;
          return handleChangeSearch("schema", value);
        }}
      >
        <MenuItem key={SearchAll.All.code} value={SearchAll.All.code}>
          {SearchAll.All.name}
        </MenuItem>
        <MenuItem key="resNFe" value="resNFe">
          Resumo
        </MenuItem>
        <MenuItem key="procNFe" value="procNFe">
          Completa
        </MenuItem>
      </Select>
    </FormControl>
  );

  const renderStatusManifestacao = () => (
    <FormControl
      fullWidth
      className={classesCustomComponentsStyle.formControlSelect}
      size="small"
      variant="outlined"
    >
      <InputLabel>Manifestação</InputLabel>
      <Select
        label="Manifestaçãoação"
        autoWidth
        value={search.statusManifestacao == null ? "" : search.statusManifestacao}
        onChange={(event) => {
          const {
            target: { value },
          } = event;
          return handleChangeSearch("statusManifestacao", value);
        }}
      >
        <MenuItem key={SearchAll.All.code} value={SearchAll.All.code}>
          {SearchAll.All.name}
        </MenuItem>
        {Object.keys(NFEventoManifestacaoDestinatarioType).map((key) => {
          const { code, name } = NFEventoManifestacaoDestinatarioType[key];
          return (
            <MenuItem key={code} value={code}>
              {name}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );

  const renderOrigem = () => (
    <FormControl
      fullWidth
      className={classesCustomComponentsStyle.formControlSelect}
      size="small"
      variant="outlined"
    >
      <InputLabel>Origem</InputLabel>
      <Select
        label="Origem"
        autoWidth
        value={search.origemImportacao == null ? "" : search.origemImportacao}
        onChange={(event) => {
          const {
            target: { value },
          } = event;
          return handleChangeSearch("origemImportacao", value);
        }}
      >
        <MenuItem key={SearchAll.All.code} value={SearchAll.All.code}>
          {SearchAll.All.name}
        </MenuItem>
        {Object.keys(NotaFiscalImportacaoOrigemType).map((key) => {
          const { code, name } = NotaFiscalImportacaoOrigemType[key];
          return (
            <MenuItem key={code} value={code}>
              {name}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );

  const renderNumero = () => (
    <TextInput
      autoFocus
      onKeyPress={(e) => {
        if (e.which === 13) {
          onSearch();
        }
      }}
      label="Número"
      value={search.numero}
      onChange={(ev, value) => {
        handleChangeSearch("numero", value);
      }}
    />
  );

  const renderFornecedor = () => (
    <TextInput
      onKeyPress={(e) => {
        if (e.which === 13) {
          onSearch();
        }
      }}
      label="Fornecedor"
      value={search.nomeEmitente}
      onChange={(ev, value) => {
        handleChangeSearch("nomeEmitente", value);
      }}
    />
  );

  const renderData = () => (
    <DatePicker
      id="dataHoraEmissao"
      label="Data emissão"
      format="dd/MM/yyyy"
      slotProps={{ textField: { size: "small" } }}
      value={search?.data ? moment(search?.data).valueOf() : null}
      onChange={(date) => handleChangeSearch("data", moment(date))}
    />
  );

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card className="gridLayoutCard">
          <div className="gridLayoutContainer">
            <CardHeader color="primary">
              <h4 className={classesuseCardStyle.cardTitleList}>Notas fiscais de entrada</h4>
              <p className={classesuseCardStyle.cardCategoryList}>
                Lista de notas fiscais de entrada
              </p>
            </CardHeader>

            <div className="gridLayoutFilters">
              <AccordionFilters screen="nfe_entrada_list">
                <GridContainer>
                  {/* <GridItem xs={12} sm={12} md={2}>
              {renderNumero()}
            </GridItem> */}
                  <GridItem xs={12} sm={12} md={10}>
                    {renderFornecedor()}
                  </GridItem>
                  <GridItem xs={12} sm={12} md={2} style={{ marginTop: "12px" }}>
                    {renderData()}
                  </GridItem>
                  {/* <GridItem xs={12} sm={12} md={4} /> */}
                  <GridItem xs={12} sm={12} md={2}>
                    {renderTipo()}
                  </GridItem>
                  {/* <GridItem xs={12} sm={12} md={2}>
              {renderStatus()}
            </GridItem> */}
                  <GridItem xs={12} sm={12} md={2}>
                    {renderOrigem()}
                  </GridItem>
                  <GridItem xs={12} sm={12} md={2}>
                    {renderStatusManifestacao()}
                  </GridItem>

                  <GridItem xs={12} sm={12} md={4} style={{ marginTop: 6, flexBasis: 0 }}>
                    <Box display="flex">
                      <ButtonCustom color="primary" onClick={onSearch}>
                        {generalMessages.search}
                      </ButtonCustom>
                      <ButtonCustom color="primary" onClick={onClear}>
                        Limpar
                      </ButtonCustom>
                    </Box>
                  </GridItem>
                </GridContainer>
              </AccordionFilters>
            </div>
            <div className="gridLayoutGrid">
              <DataGridCustom
                ref={customGridRef}
                onRowDoubleClick={(params) => goToFormWithoutValidation(params.row)}
                rows={list}
                columns={getColumns()}
                usePagination
                rowCount={rowCount}
                fetchAction={(params) =>
                  fetchAll({
                    ...params,
                    searchParams: search,
                  })
                }
              />
            </div>
            <div className="gridLayoutBottom">
              <Box display="flex" justifyContent="flex-end">
                <ButtonCustom
                  style={{
                    backgroundColor: "#af2cc5",
                    color: "#FFFFFF",
                    marginTop: 8,
                    marginRight: 8,
                  }}
                  onClick={() => setOpenImportarXML(true)}
                >
                  Importar XML da NFe
                </ButtonCustom>
                <AddButton onClick={() => goToForm({ id: null, standard: false })} />
              </Box>
            </div>

            <NotaFiscalHistorico
              open={openHistoricoNotaFiscalModal}
              onClose={handleCloseHistoricoNotaFiscalModal}
              items={notaFiscalHistoricoList}
            />
            <NFeEntradaImportarXML open={openImportarXML} onClose={setOpenImportarXML} />
          </div>
        </Card>
        <Loading loading={loading} />
      </GridItem>
    </GridContainer>
  );
}
