import axios from "axios";
import { APIS } from "../../../config/constants";
import BaseService from "../../../core/BaseService";

const { register: registerAPI } = APIS;

class InventoryService extends BaseService {
  constructor() {
    super(registerAPI.inventory);
  }

  fetchAll = ({ term, barcode, pagination, sorting }) => {
    const { page, pageSize } = pagination;
    const { field = "createdAt", sort = "desc" } = sorting || {};
    return axios.get(
      `${this.endpoint}/search-inventories?size=${pageSize}&page=${page}&sort=${field},${sort}&term=${term}&barcode=${barcode}`
    );
  };

  findByProduct = (productId) => axios.get(`${this.endpoint}/product/${productId}`);
}

export default new InventoryService();
