import React, { useState, useCallback, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { FormControlLabel, FormGroup, Switch } from "@mui/material";
import CardStyle from "assets/jss/material-dashboard-react/components/cardStyle";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import Button from "components/CustomButtons/Button";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import CardFooter from "components/Card/CardFooter";
import { useHistory, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import moment from "moment";
import { DatePicker } from "@mui/x-date-pickers";
import { NotaFiscalPacoteDownload } from "./model/NotaFiscalPacoteDownload";
import { toRequest, fromResponse } from "./converter/NotaFiscalPacoteDownloadConverter";
import Service from "./service/NotaFiscalPacoteDownloadService";
import { handlingResponseErrors } from "../../config/util";
import MESSAGES from "../../config/messages";
import Loading from "../../components/loading/Loading";

const { generalMessages } = MESSAGES;

const useCardStyle = makeStyles(CardStyle);

// eslint-disable-next-line react/prop-types
export default function NotaFiscalPacoteDownloadForm() {
  const classesCardStyle = useCardStyle();
  const history = useHistory();
  const routerParams = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [errors, setErrors] = useState({});
  const [model, setModel] = useState(
    new NotaFiscalPacoteDownload({ active: true, startDate: moment(), endDate: moment() })
  );
  const [loading, setLoading] = useState(false);

  const handleChange = useCallback(
    (field, value) => {
      setModel({
        ...model,
        [field]: value,
      });
    },
    [model]
  );

  const goBack = useCallback(() => {
    history.push("/app/nfe-pacote-downloads");
  }, [history]);

  const onSave = useCallback(async () => {
    try {
      setLoading(true);
      const { id } = model;
      if (id != null) {
        await Service.update(id, toRequest(model));
      } else {
        await Service.save(toRequest(model));
      }
      enqueueSnackbar(generalMessages.saveSuccess, {
        variant: "success",
        autoHideDuration: 3000,
      });
      goBack();
    } catch (error) {
      const { message, validationErrors } = handlingResponseErrors(error);
      enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
      if (validationErrors != null) {
        setErrors(validationErrors);
      }
    } finally {
      setLoading(false);
    }
  }, [model, enqueueSnackbar, goBack]);

  const fetchById = useCallback(async () => {
    try {
      setLoading(true);
      const { id } = routerParams || {};
      if (id != null) {
        const result = await Service.fetchById(id);
        const response = fromResponse(result?.data?.data);
        setModel(response);
      }
    } finally {
      setLoading(false);
    }
  }, [routerParams]);

  useEffect(() => {
    fetchById();
  }, [fetchById]);

  const renderSwitch = (value = false, field, label, disabled = false) => (
    <FormGroup style={{ paddingTop: "8px" }}>
      <FormControlLabel
        control={
          <Switch
            color="secondary"
            checked={value}
            onChange={(event) => handleChange(field, event.target.checked)}
          />
        }
        label={label}
        disabled={disabled}
      />
    </FormGroup>
  );

  const render = () => (
    <>
      <GridItem xs={12} sm={12} md={3} style={{ marginTop: "12px" }}>
        <DatePicker
          id="startDate"
          label="Data início"
          format="dd/MM/yyyy"
          slotProps={{ textField: { size: "small" } }}
          value={model?.startDate ? moment(model.startDate).valueOf() : null}
          helperText={errors.startDate}
          onChange={(date) => handleChange("startDate", date)}
        />
      </GridItem>

      <GridItem xs={12} sm={12} md={3} style={{ marginTop: "12px" }}>
        <DatePicker
          id="endDate"
          label="Data fim"
          format="dd/MM/yyyy"
          slotProps={{ textField: { size: "small" } }}
          value={model?.endDate ? moment(model.endDate).valueOf() : null}
          helperText={errors.endDate}
          onChange={(date) => handleChange("endDate", date)}
        />
      </GridItem>
    </>
  );

  return (
    <>
      <GridContainer style={{ justifyContent: "center" }}>
        <GridItem xs={12} sm={12} md={10}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classesCardStyle.cardTitle}>Download Pacotes NF</h4>
              <p className={classesCardStyle.cardCategory}>
                Cadastro de download dos pacotes de NF
              </p>
            </CardHeader>
            <CardBody>
              <GridContainer>{render()}</GridContainer>
            </CardBody>
            <CardFooter>
              <Button color="primary" onClick={goBack}>
                {generalMessages.cancel}
              </Button>
              <Button color="primary" onClick={onSave}>
                {generalMessages.save}
              </Button>
            </CardFooter>
          </Card>
        </GridItem>
        <Loading loading={loading} />
      </GridContainer>
    </>
  );
}
