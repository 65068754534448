import React, { useState, useEffect, useCallback } from "react";
import { useDebounce } from "use-debounce";
import { makeStyles } from "@mui/styles";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import CardStyle from "assets/jss/material-dashboard-react/components/cardStyle";
import { NFIndicadorFormaPagamento, NFMeioPagamento } from "./model/PaymentMethod";
import Searchbar from "../../components/Searchbar/Searchbar";
import PaymentMethodService from "./service/PaymentMethodService";
import MESSAGES from "../../config/messages";
import { SEARCH } from "../../config/constants";
import Loading from "../../components/loading/Loading";
import AddButton from "../../components/addButton/AddButton";
import EditIcon from "../../components/icons/EditIcon";
import DataGridCustom from "../../components/dataGrid/DataGridCustom";

const { generalMessages, paymentMethodMessages } = MESSAGES;

const useCardStyle = makeStyles(CardStyle);

export default function PaymentMethodList() {
  const classesuseCardStyle = useCardStyle();
  const history = useHistory();
  const [list, setList] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchTermDebounce] = useDebounce(searchTerm, SEARCH.WAIT_INTERVAL);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const fetchAll = useCallback(async () => {
    if (searchTermDebounce !== "" && searchTermDebounce.length < SEARCH.MINIMUM_CHARACTERS) return;

    setLoading(true);
    try {
      const result = await PaymentMethodService.fetchAll(searchTermDebounce);
      const { data } = result;
      const { content } = data;
      setList(content);
    } catch (error) {
      enqueueSnackbar(generalMessages.error, {
        variant: "error",
      });
    } finally {
      setLoading(false);
    }
  }, [enqueueSnackbar, searchTermDebounce]);

  useEffect(() => {
    fetchAll();
  }, [fetchAll]);

  const goToForm = (params) =>
    params?.id
      ? history.push(`/app/payment-method/${params?.id}`)
      : history.push("/app/payment-method");

  const columns = [
    {
      field: "name",
      headerName: paymentMethodMessages.name,
      minWidth: 250,
      flex: 3,
    },

    {
      field: "nfFormOfPayment",
      headerName: "Forma de pagamento",
      flex: 2,
      minWidth: 250,
      valueGetter: ({ value }) => (value ? NFIndicadorFormaPagamento[value].name : ""),
    },
    {
      field: "nfPaymentMethod",
      headerName: "Meio de pagamento",
      flex: 2,
      minWidth: 250,
      valueGetter: ({ value }) => (value ? NFMeioPagamento[value].name : ""),
    },
    {
      field: "standard",
      headerName: paymentMethodMessages.standard,
      flex: 1,
      minWidth: 120,
      valueGetter: ({ value }) => (value ? "Sim" : "Não"),
    },
    {
      field: "active",
      headerName: paymentMethodMessages.active,
      flex: 1,
      minWidth: 120,
      valueGetter: ({ value }) => (value ? "Sim" : "Não"),
    },
    {
      field: "actions",
      headerName: generalMessages.actions,
      sortable: false,
      width: 100,
      disableClickEventBubbling: true,
      renderCell: (params) => (
        <div
          style={{
            cursor: "pointer",
            justifyContent: "center",
            padding: "10px",
            display: "flex",
            flex: 1,
          }}
        >
          <EditIcon onClick={() => goToForm({ id: params.row.id })} />
          {/* <DeleteOutlinedIcon index={params.row.id} onClick={() => onDelete(params.row.id)} /> */}
        </div>
      ),
    },
  ];

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card className="gridLayoutCard">
          <div className="gridLayoutContainer">
            <CardHeader color="primary">
              <h4 className={classesuseCardStyle.cardTitleList}>
                {paymentMethodMessages.payments}
              </h4>
              <p className={classesuseCardStyle.cardCategoryList}>
                {paymentMethodMessages.paymentsTable}
              </p>
            </CardHeader>

            <div className="gridLayoutFilters">
              <Searchbar
                onChange={setSearchTerm}
                searchTerm={searchTerm}
                placeholder={paymentMethodMessages.searchbar}
              />
            </div>

            <div className="gridLayoutGrid">
              <DataGridCustom onRowDoubleClick={goToForm} rows={list} columns={columns} />
            </div>

            <div className="gridLayoutBottom">
              <AddButton onClick={() => goToForm(null)} />
            </div>

          </div>
        </Card>
      </GridItem>
      <Loading loading={loading} />
    </GridContainer>
  );
}
