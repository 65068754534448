/* eslint-disable no-new */
import React, { useState, useEffect, useCallback, useRef } from "react";
import { makeStyles } from "@mui/styles";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import CardStyle from "assets/jss/material-dashboard-react/components/cardStyle";
import { Chip } from "@mui/material";
import useConfirmDialog from "components/confirm/ConfirmDialog";
import moment from "moment";
import DownloadIcon from "components/icons/DownloadIcon";
import DeleteIcon from "components/icons/DeleteIcon";
import Service from "./service/NotaFiscalPacoteDownloadService";
import MESSAGES from "../../config/messages";
import Loading from "../../components/loading/Loading";
import AddButton from "../../components/addButton/AddButton";
import { formatDateWithoutTime } from "../../config/util";
import DataGridCustom from "../../components/dataGrid/DataGridCustom";
import { NotaFiscalPacoteDownloadStatusType } from "./model/NotaFiscalPacoteDownload";

const { generalMessages } = MESSAGES;
const initialSearch = {
  startDate: moment(),
  endDate: moment(),
  active: true,
};

const useCardStyle = makeStyles(CardStyle);

export default function NotaFiscalPacoteDownloadList() {
  // const [search, setSearch] = useState(initialSearch);
  const customGridRef = useRef();
  const classesuseCardStyle = useCardStyle();
  const history = useHistory();
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [rowCount, setRowCount] = React.useState(0);
  const { showConfirmDialog, closeConfirmDialog, ConfirmDialog } = useConfirmDialog();

  // const handleChangeSearch = useCallback(
  //   (field, value) => {
  //     setSearch({
  //       ...search,
  //       [field]: value,
  //     });
  //   },
  //   [search]
  // );

  const fetchAll = useCallback(
    async ({ searchParams, paginationModelParams, sorting }) => {
      setLoading(true);
      const pagination = paginationModelParams || customGridRef.current.getDefaultPaginationModel();
      try {
        // const params = [];

        // const { startDate, endDate } = searchParams || {};

        // if (startDate != null) {
        //   params.push(`startDate=${formatDateWithoutTime(startDate)}`);
        // }
        // if (endDate != null) {
        //   params.push(`endDate=${formatDateWithoutTime(endDate)}`);
        // }

        const result = await Service.fetchAll({
          /* params: params.join("&"), */ pagination,
          sorting,
        });
        const { data } = result;
        const { content, totalElements } = data;

        setRowCount(totalElements);
        setList(content);
      } catch (error) {
        enqueueSnackbar(generalMessages.error, {
          variant: "error",
        });
      } finally {
        setLoading(false);
      }
    },
    [enqueueSnackbar]
  );

  useEffect(() => {
    fetchAll({ searchParams: "" });
  }, [fetchAll]);

  const goToForm = (params) =>
    params?.id
      ? history.push(`/app/nfe-pacote-download/${params?.id}`)
      : history.push("/app/nfe-pacote-download");

  const formatterStatus = (item) => {
    const { status } = item;
    if (status != null && status !== undefined) {
      switch (status) {
        case NotaFiscalPacoteDownloadStatusType.AGUARDANDO_PROCESSAMENTO.code:
          return (
            <Chip
              size="small"
              label={NotaFiscalPacoteDownloadStatusType.AGUARDANDO_PROCESSAMENTO.name}
              style={{ backgroundColor: "#AA810D", color: "#ffff" }}
            />
          );
        case NotaFiscalPacoteDownloadStatusType.EM_PROCESSAMENTO.code:
          return (
            <Chip
              size="small"
              label={NotaFiscalPacoteDownloadStatusType.EM_PROCESSAMENTO.name}
              style={{ backgroundColor: "#4c74da", color: "#ffff" }}
            />
          );
        case NotaFiscalPacoteDownloadStatusType.PRONTO.code:
          return (
            <Chip
              size="small"
              label={NotaFiscalPacoteDownloadStatusType.PRONTO.name}
              style={{ backgroundColor: "#0e850b", color: "#ffff" }}
            />
          );
        case NotaFiscalPacoteDownloadStatusType.ERRO.code:
          return (
            <Chip
              size="small"
              label={NotaFiscalPacoteDownloadStatusType.ERRO.name}
              style={{ backgroundColor: "#e53935", color: "#ffff" }}
            />
          );
        case NotaFiscalPacoteDownloadStatusType.NENHUMA_NF_ENCONTRADA.code:
          return (
            <Chip
              size="small"
              label={NotaFiscalPacoteDownloadStatusType.NENHUMA_NF_ENCONTRADA.name}
              style={{ backgroundColor: "#7b7878", color: "#ffff" }}
            />
          );

        default:
          return "";
      }
    }
    return "";
  };

  const onDownload = useCallback(
    async (id) => {
      try {
        setLoading(true);

        const response = await Service.download(id);
        const fileName = response?.headers["file-name"] || "nota_fiscal.zip";
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
      } catch (error) {
        if (
          error.request.responseType === "blob" &&
          error.response.data instanceof Blob &&
          error.response.data.type &&
          error.response.data.type.toLowerCase().indexOf("json") !== -1
        ) {
          new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => {
              const message =
                error.response.status === 428 ? reader?.result : generalMessages.error;
              enqueueSnackbar(message, {
                variant: "error",
                autoHideDuration: 3000,
              });
            };

            reader.onerror = () => {
              reject(error);
            };

            reader.readAsText(error.response.data);
          });
        }
      } finally {
        setLoading(false);
      }
    },
    [enqueueSnackbar]
  );

  const formatPeriod = (item) => {
    const { startDate, endDate } = item;
    if (startDate != null && endDate != null) {
      return `${formatDateWithoutTime(startDate)} até ${formatDateWithoutTime(endDate)}`;
    }
    return "";
  };

  const onDelete = useCallback(
    async (id) => {
      showConfirmDialog({
        title: "NFe download",
        text: generalMessages.deleteConfirm,
        onClickConfirm: async () => {
          try {
            await Service.delete(id);
            enqueueSnackbar(generalMessages.deleteSuccess, {
              variant: "success",
            });

            fetchAll({ searchParams: "" });
          } catch (error) {
            enqueueSnackbar(generalMessages.error, {
              variant: "error",
            });
          }
          closeConfirmDialog();
        },
      });
    },
    [showConfirmDialog, closeConfirmDialog, enqueueSnackbar, fetchAll]
  );

  /*
   AGUARDANDO_PROCESSAMENTO: Remover
    EM_PROCESSAMENTO: Nenhum
    PRONTO: Download e Remover
    ERRO: Remover
    NENHUMA_NF_ENCONTRADA: Remover

  */

  const showDeleteButton = useCallback((item) => {
    const { status } = item;
    if (
      status === NotaFiscalPacoteDownloadStatusType.AGUARDANDO_PROCESSAMENTO.code ||
      status === NotaFiscalPacoteDownloadStatusType.PRONTO.code ||
      status === NotaFiscalPacoteDownloadStatusType.ERRO.code ||
      status === NotaFiscalPacoteDownloadStatusType.NENHUMA_NF_ENCONTRADA.code
    ) {
      return true;
    }
    return false;
  }, []);

  const showDownloadButton = useCallback((item) => {
    const { status } = item;
    if (status === NotaFiscalPacoteDownloadStatusType.PRONTO.code) {
      return true;
    }
    return false;
  }, []);

  const columns = [];

  columns.push({
    field: "createdAt",
    headerName: "Data solicitação",
    minWidth: 180,
    flex: 2,
    renderCell: ({ row: item }) => formatDateWithoutTime(item?.createdAt),
  });

  columns.push({
    field: "period",
    headerName: "Período solicitado",
    minWidth: 180,
    flex: 2,
    renderCell: ({ row: item }) => formatPeriod(item),
  });

  columns.push({
    field: "status",
    headerName: "Status",
    minWidth: 120,
    flex: 1,
    renderCell: ({ row: item }) => formatterStatus(item),
  });

  columns.push({
    field: "actions",
    headerName: generalMessages.actions,
    sortable: false,
    width: 100,
    disableClickEventBubbling: true,
    renderCell: (params) => (
      <div
        style={{
          cursor: "pointer",
          justifyContent: "space-between",
          padding: "10px",
          display: "flex",
          flex: 1,
        }}
      >
        {showDownloadButton(params.row) && (
          <DownloadIcon onClick={() => onDownload(params.row.id)} />
        )}
        {showDeleteButton(params.row) && <DeleteIcon onClick={() => onDelete(params.row.id)} />}
      </div>
    ),
  });

  // const onClear = useCallback(() => {
  //   fetchAll({ searchParams: "" });
  //   setSearch(initialSearch);
  //   customGridRef.current.resetPaginationModel();
  // }, [fetchAll]);

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card className="gridLayoutCard">
          <div className="gridLayoutContainer">
            <CardHeader color="primary">
              <h4 className={classesuseCardStyle.cardTitleList}>Download Pacotes NF</h4>
              <p className={classesuseCardStyle.cardCategoryList}>
                Lista de cadastro de download dos pacotes de NF
              </p>
            </CardHeader>
            <div className="gridLayoutGrid">
              <DataGridCustom
                ref={customGridRef}
                onRowDoubleClick={goToForm}
                rows={list}
                columns={columns}
                usePagination
                rowCount={rowCount}
                fetchAction={(params) =>
                  fetchAll({
                    ...params,
                  })
                }
              />
            </div>
            <div className="gridLayoutBottom">
              <AddButton onClick={() => goToForm(null)}/>
            </div>
          </div>
        </Card>
      </GridItem>
      <Loading loading={loading} />
      <ConfirmDialog />
    </GridContainer>
  );
}
