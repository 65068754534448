import axios from "axios";
import { APIS } from "../../../config/constants";
import BaseService from "../../../core/BaseService";

const { register: registerAPI } = APIS;

class PersonService extends BaseService {
  constructor() {
    super(registerAPI.person);
  }

  fetchStates = () => axios.get(`${registerAPI.state}?size=50`);

  fetchCitiesByState = (stateId) => axios.get(`${registerAPI.city}/state/${stateId}`);

  fetchStandard = (registrationType) =>
    axios.get(`${registerAPI.person}/standard/${registrationType}`);

  fetchAll = ({ params, pagination, sorting }) => {
    const { page, pageSize } = pagination;
    const { field = "createdAt", sort = "desc" } = sorting || {};
    return axios.get(
      `${this.endpoint}/search?size=${pageSize}&page=${page}&sort=${field},${sort}&${params}`
    );
  };

  searchWithParams = (params) =>
    axios.get(
      `${this.endpoint}/search-with-params?size=30&sort=active,DESC&sort=code,ASC&${params}`
    );

  fetchByAutoComplete = (term, extras) => {
    const params = [];
    if (term != null && term !== "") {
      params.push(`term=${term}`);
    }

    if (extras != null && extras?.registrationType != null) {
      params.push(`registrationType=${extras.registrationType}`);
    }

    return this.searchWithParams(params.join("&"));
  };

  fetchCnpj = (cnpj, uf) => axios.get(`${this.endpoint}/import/cnpj/${cnpj}/uf/${uf}`);
}

export default new PersonService();
