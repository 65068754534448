/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import CustomComponentsStyle from "assets/jss/material-dashboard-react/components/customComponentsStyle";
import { makeStyles } from "@mui/styles";
import AutocompleteStyle from "components/autocomplete/AutocompleteStyle";
import { AUTOCOMPLETE } from "config/constants";
import {
  Box,
  Button as ButtonUI,
  Grid,
  Divider,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Chip,
  Modal,
} from "@mui/material";
import { useSnackbar } from "notistack";
import ProductForm from "views/product/ProductForm";
import TextInput from "components/textInput/TextInput";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { primaryColor } from "assets/jss/material-dashboard-react";
import DeleteIcon from "components/icons/DeleteIcon";
import CurrencyTextInput from "components/textInput/CurrencyTextInput";
import Autocomplete from "components/autocomplete/Autocomplete";
import ProductService from "views/product/service/ProductService";
import ProductIcon from "components/icons/ProductIcon";
import { currencyFormatter } from "config/util";
import NcmService from "views/ncm/service/NcmService";
import NotaFiscalService from "../service/NotaFiscalService";

import { NFTipo } from "../model/NotaFiscal";

import MESSAGES from "../../../config/messages";

const { autocompleteMessages } = MESSAGES;

const useAutocompleteStyle = makeStyles(AutocompleteStyle);

const NotaFiscalProduto = ({
  disabled,
  naturezaOperacao,
  items = [],
  handleChange,
  handleChangeTotais,
  errors,
  dadosGerais = {},
  dadosTransporte = {},
  dadosDestinatario = {},
  handleChangeDadosGerais,
}) => {
  const classesAutocompleteStyle = useAutocompleteStyle();
  const useCustomComponentsStyle = makeStyles(CustomComponentsStyle);
  const classesCustomComponentsStyle = useCustomComponentsStyle();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [onTypedChange, setOnTypedChange] = useState(null);
  const [quickAddProductModalOpen, setQuickAddProductModalOpen] = useState(false);
  const [currentIndexQuickAdd, setCurrentIndexQuickAdd] = useState(null);
  const [itemSelected, setItemSelected] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [impostoTotal, setImpostoTotal] = useState({});

  const shouldCalcImpostoTotal = useMemo(() => {
    if (naturezaOperacao?.id && items?.length >= 1 && items?.[0]?.produto) {
      return true;
    }
    return false;
  }, [items, naturezaOperacao?.id]);

  const calculaImpostoTotal = useCallback(async () => {
    if (shouldCalcImpostoTotal) {
      const data = {
        naturezaOperacao: {
          id: naturezaOperacao?.id || "",
        },
        notaFiscalProdutos: items,
        desconto: dadosGerais.desconto,
        identificadorLocalDestinoOperacao: dadosGerais.identificadorLocalDestinoOperacao,
        cidadeDestinatario: {
          id: dadosDestinatario?.cidadeDestinatario,
        },
        totalFrete: dadosTransporte?.totalFrete
      };

      const result = await NotaFiscalService.calculaImpostoTotal(data);
      const totais = result?.data?.data;
      setImpostoTotal(totais);
      handleChangeTotais(totais);
    } else {
      const totais = {
        totalBcIcms: 0,
        totalIcms: 0,
        totalFcp: 0,
        totalBcIcmsSt: 0,
        totalIcmsSt: 0,
        totalFcpSt: 0,
        totalBrutoProdutos: 0,
        totalFrete: 0,
        totalSeguro: 0,
        totalDesconto: 0.0,
        totalOutrasDespesas: 0,
        totalIi: 0,
        totalIpi: 0,
        totalIpiDevolvido: 0,
        totalPis: 0,
        totalCofins: 0,
        totalIcmsDesonerado: 0,
        totalFcpRetido: 0,
        totalFcpInterestadual: 0,
        icmsUfRemet: 0,
        icmsUfDestino: 0,
        totalNf: 0,
      };
      setImpostoTotal(totais);
      handleChangeTotais(totais);
    }
  }, [
    shouldCalcImpostoTotal,
    naturezaOperacao?.id,
    items,
    dadosGerais.desconto,
    dadosGerais.identificadorLocalDestinoOperacao,
    dadosDestinatario?.cidadeDestinatario,
    dadosTransporte?.totalFrete,
    handleChangeTotais,
  ]);

  useEffect(() => {
    calculaImpostoTotal();
  }, [items, calculaImpostoTotal]);

  const onChangeDesconto = useCallback(
    (desconto) => {
      const novosProdutos = items.map((produto) => {
        const valorProdutos = produto.valorUnitario * produto.quantidadeComercial;
        const valorTotalBruto = valorProdutos - valorProdutos * (desconto / 100);
        return {
          ...produto,
          valorTotalBruto,
        };
      });

      const valorFinal = novosProdutos.reduce(
        (acumulador, produto) => acumulador + produto.valorTotalBruto,
        0
      );
      const valorSemDesconto = novosProdutos.reduce(
        (acumulador, produto) => acumulador + produto.valorUnitario * produto.quantidadeComercial,
        0
      );
      const valorDesconto = valorSemDesconto - valorFinal;
      handleChangeDadosGerais({
        ...dadosGerais,
        desconto,
        valorDesconto,
      });

      handleChange(novosProdutos);
    },
    [dadosGerais, handleChange, handleChangeDadosGerais, items]
  );

  const add = useCallback(() => {
    const newItem = { index: items.length };
    const newItems = [...items, newItem];
    handleChange(newItems);
  }, [handleChange, items]);

  const remove = useCallback(
    (i) => {
      let newItems = [...items];
      const index = newItems.findIndex((item) => item.index === i);
      newItems.splice(index, 1);
      // Changing the indexes
      newItems = newItems.map((item, idx) => ({ ...item, index: idx }));
      handleChange(newItems);
    },
    [handleChange, items]
  );

  const calculeSubTotal = (valorUnitario, quantidadeComercial) => {
    let valorTotalBruto = valorUnitario * quantidadeComercial;
    valorTotalBruto = parseFloat(valorTotalBruto.toFixed(2));
    return valorTotalBruto;
  };

  const onChangeItemSelected = useCallback(
    (field, value) => {
      const newItem = {
        ...itemSelected,
        [field]: value,
      };

      newItem.valorTotalBruto = calculeSubTotal(newItem.valorUnitario, newItem.quantidadeComercial);

      setItemSelected(newItem);
    },
    [itemSelected]
  );

  const onChange = useCallback(
    (field, value, index) => {
      const newItems = [...items];

      const item = newItems[index];
      let newItem = {};

      if (field != null) {
        newItem = {
          ...item,
          [field]: value,
        };
      } else {
        newItem = {
          ...item,
          ...value,
        };
      }

      let valorTotalBruto = calculeSubTotal(newItem.valorUnitario, newItem.quantidadeComercial);
      valorTotalBruto = parseFloat(valorTotalBruto.toFixed(2));
      const desconto = dadosGerais?.desconto || 0;
      newItem.valorTotalBruto = valorTotalBruto - valorTotalBruto * (desconto / 100);

      newItems[index] = newItem;
      handleChange(newItems);
    },
    [dadosGerais?.desconto, handleChange, items]
  );

  const renderAddButton = () => (
    <GridItem xs={12} sm={12} md={3}>
      <Box display="flex" style={{ marginTop: 12 }}>
        <ButtonUI style={{ color: primaryColor[0] }} onClick={add}>
          Adicionar
        </ButtonUI>
      </Box>
    </GridItem>
  );

  const onConfirmProduct = useCallback(
    (selected) => {
      const { priceMinimum, finalValue, saleValue } = selected;

      onChange(null, selected, selected.index);

      setOpenModal(false);
      setItemSelected(null);
    },
    [onChange]
  );

  const renderModal = useCallback(() => {
    if (itemSelected == null) return <></>;
    const { produto = {}, valorUnitario, quantidadeComercial, valorTotalBruto } = itemSelected;

    return (
      <Dialog open={openModal}>
        <DialogTitle onClose={() => setOpenModal(false)}>Produto</DialogTitle>
        <DialogContent dividers>
          <GridContainer spacing={8} style={{ margin: "0 -15px" }}>
            <GridItem xs={8} sm={8} md={12}>
              <div style={{ fontSize: 20, fontWeight: "bold" }}>{produto.name || ""}</div>
            </GridItem>
            <GridItem xs={8} sm={8} md={4}>
              <CurrencyTextInput
                id="valorUnitario"
                onFocus={(e) => {
                  // Se for o primeiro campo da tela
                  setTimeout(() => {
                    e.target.setSelectionRange(0, 0);
                  }, 200);
                }}
                autoFocus
                label="Valor de venda"
                value={valorUnitario}
                onChange={onChangeItemSelected}
                required
                disabled={disabled}
              />
            </GridItem>

            <GridItem xs={8} sm={8} md={4}>
              <TextInput
                id="quantidadeComercial"
                label="Quantidade"
                value={quantidadeComercial}
                onChange={onChangeItemSelected}
                type="number"
                required
                disabled={disabled}
              />
            </GridItem>

            <GridItem xs={8} sm={8} md={4}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: 0,
                  textAlign: "center",
                }}
              >
                <div>Valor total</div>
                <div style={{ fontSize: 20, fontWeight: "bold" }}>
                  {currencyFormatter(valorTotalBruto)}
                </div>
              </div>
            </GridItem>
          </GridContainer>
        </DialogContent>
        <DialogActions>
          <div style={{ display: "flex", flex: 1, justifyContent: "space-between" }}>
            <Button
              color="primary"
              onClick={() => {
                setOpenModal(false);
                setItemSelected(null);
              }}
            >
              Cancelar
            </Button>
            <Button color="primary" onClick={() => onConfirmProduct(itemSelected)}>
              Confirmar
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    );
  }, [itemSelected, openModal, onChangeItemSelected, disabled, onConfirmProduct]);

  const beforeAutocompleteSelectProduct = useCallback(
    (value, index) => {
      if (value == null) {
        setItemSelected({
          ...value,
          index,
        });
        remove(index);
        return;
      }

      const { saleValue, name, ncm, id, enableForNf } = value;

      // if (checkProductAlreadyAdded(value)) return;

      // const productCalculate = calculeValues(value);
      setItemSelected({
        produto: {
          id,
          name,
          enableForNf,
        },
        ncm,
        quantidadeComercial: 1,
        valorUnitario: saleValue,
        valorTotalBruto: saleValue,
        index,
      });

      setOpenModal(true);
    },
    [remove]
  );

  const fetchProducts = useCallback((searchTerm) => {
    const queries = {
      service: false,
      name: searchTerm,
    };
    return ProductService.searchAutoComplete(queries);
  }, []);

  const afterSaveProduct = useCallback(
    async (product) => {
      setQuickAddProductModalOpen(false);
      const result = await ProductService.fetchByAutoComplete(null, { id: product.id });
      const { data } = result;
      const { content } = data;
      beforeAutocompleteSelectProduct(content[0], currentIndexQuickAdd);
    },
    [beforeAutocompleteSelectProduct, currentIndexQuickAdd]
  );

  const renderQuickAddProduct = () => (
    <Modal style={{ overflow: "auto" }} disableEscapeKeyDown open={quickAddProductModalOpen}>
      <ProductForm
        name={onTypedChange}
        onCancel={() => {
          setQuickAddProductModalOpen(false);
          setCurrentIndexQuickAdd(null);
        }}
        afterSave={(id) => afterSaveProduct(id)}
      />
    </Modal>
  );

  const renderQuickAddText = (index) => (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      className={classesAutocompleteStyle.emptyContainer}
    >
      <Box>{autocompleteMessages.emptyText}</Box>
      <Box>
        <ButtonUI
          style={{ color: primaryColor[0] }}
          onMouseDown={() => {
            setQuickAddProductModalOpen(true);
            setCurrentIndexQuickAdd(index);
          }}
        >
          Adicionar
        </ButtonUI>
      </Box>
    </Box>
  );

  const renderOptionProductSearch = (props, item, index) => {
    const { id, industryCode, name, brand } = item;

    if (id === AUTOCOMPLETE.QUICK_ADD) {
      return renderQuickAddText(index);
    }

    const option = [];
    if (industryCode !== null && industryCode !== "") {
      option.push(industryCode);
    }
    if (name !== null && name !== "") {
      option.push(name);
    }
    if (brand !== null && brand !== "") {
      option.push(brand);
    }
    const text = option.join(" - ");

    return (
      <Box
        {...props}
        display="flex"
        flexDirection="row"
        style={{ paddingTop: 6, paddingBottom: 6 }}
      >
        <Box style={{ paddingRight: 6 }}>
          <ProductIcon isService={item.service} />
        </Box>
        <Box>{text}</Box>
      </Box>
    );
  };

  const renderProduto = (item) => (
    <GridItem xs={12} sm={12} md={3}>
      <Autocomplete
        variant="outlined"
        fieldsToShowAfterSelectItem={["name"]}
        label="Produto"
        placeholder="Digite para pesquisar ..."
        // service={ProductService}
        fetchFunction={fetchProducts}
        onChange={(value) => beforeAutocompleteSelectProduct(value, item.index)}
        onTypedChange={(value) => setOnTypedChange(value)}
        renderOption={(props, option) => renderOptionProductSearch(props, option, item.index)}
        quickAdd
        value={disabled ? item.descricao : item.produto}
        error={errors[`notaFiscalProdutos[${item.index}].produto`]}
        disabled={disabled}
      />
    </GridItem>
  );

  const renderNcm = (item) => (
    <GridItem xs={12} sm={12} md={2}>
      <Box display="flex" flexGrow={1}>
        <Autocomplete
          maxLength={10}
          required
          freeSolo
          autoSelect
          width="100%"
          variant="outlined"
          fieldsToShowAfterSelectItem={["ncm", "description"]}
          label="NCM"
          placeholder="Digite para pesquisar os NCMs ..."
          service={NcmService}
          value={item.ncm}
          // error={errors.ncm}
          // helperText={errors.ncm}
          onChange={(value) => {
            if (typeof value === "string" || value == null) {
              onChange("ncm", value, item.index);
            } else {
              onChange("ncm", value.ncm, item.index);
            }
          }}
          error={errors[`notaFiscalProdutos[${item.index}].ncm`]}
          disabled={disabled}
        />
      </Box>
    </GridItem>
  );

  const renderValor = (item) => (
    <GridItem xs={12} sm={12} md={2}>
      <CurrencyTextInput
        decimalPlaces={3}
        id={`notaFiscalProdutos[${item.index}].valorUnitario`}
        label="Valor"
        value={item.valorUnitario || "0"}
        onChange={(field, value) => onChange("valorUnitario", value, item.index)}
        required
        errors={errors}
        disabled={disabled}
      />
    </GridItem>
  );

  const renderQuantidade = (item) => (
    <GridItem xs={12} sm={12} md={2}>
      <TextInput
        type="number"
        id={`notaFiscalProdutos[${item.index}].quantidadeComercial`}
        label="Quantidade"
        value={item.quantidadeComercial || "0"}
        onChange={(field, value) => onChange("quantidadeComercial", value, item.index)}
        required
        errors={errors}
        disabled={disabled}
      />
    </GridItem>
  );

  const renderSubtotal = (item) => (
    <GridItem xs={12} sm={12} md={2}>
      <CurrencyTextInput
        disabled
        decimalPlaces={3}
        id="valorTotalBruto"
        label="SubTotal"
        value={item.valorTotalBruto || "0"}
        onChange={(field, value) => onChange(field, value, item.index)}
        // errors={errors}
      />
    </GridItem>
  );

  const newMarker = (item) => (
    <Box marginLeft={2}>
      <Chip
        size="small"
        label="Não importado"
        style={{ color: "#08af0f", borderColor: "#08af0f" }}
        variant="outlined"
      />
    </Box>
  );

  const importedMarker = (item) => (
    <Box marginLeft={2}>
      <Chip
        size="small"
        label="Estoque lançado"
        style={{ color: "#2196f3", borderColor: "#2196f3" }}
        variant="outlined"
      />
    </Box>
  );

  const productNotEnableForNfMarker = () => (
    <GridItem xs={12} sm={12} md={12}>
      <Box
        style={{ marginTop: 3, alignItems: "center", height: "100%" }}
        flexDirection="row"
        display="flex"
      >
        <Typography style={{ whiteSpace: "normal", color: "#f32121", fontSize: "11px" }}>
          Não habilitado para NF
        </Typography>
      </Box>
    </GridItem>
  );

  const renderDesconto = () => (
    <GridItem xs={12} sm={12} md={2}>
      <CurrencyTextInput
        id="desconto"
        currencySymbol="%"
        label="Desconto (%)"
        value={dadosGerais?.desconto || 0}
        onChange={(field, value) => onChangeDesconto(value)}
        required
        errors={errors}
        disabled={disabled}
      />
    </GridItem>
  );

  const renderValorFinal = () => (
    <GridItem xs={12} sm={12} md={2}>
      <CurrencyTextInput
        decimalPlaces={3}
        id="valorFinal"
        label="Valor Total"
        value={impostoTotal?.totalBrutoProdutos - impostoTotal?.totalDesconto || "0"}
        disabled
      />
    </GridItem>
  );

  const renderDelete = (item) => (
    <Box>{!disabled && <DeleteIcon onClick={() => remove(item.index)} />}</Box>
  );
  const renderItem = (item) => (
    <GridContainer
      style={{
        backgroundColor:
          item?.produto?.enableForNf === false ? "rgba(255,7,0,0.25)" : "transparent",
        padding: "5px",
        borderRadius: "3px",
      }}
    >
      {renderProduto(item)}
      {renderNcm(item)}
      {renderValor(item)}
      {renderQuantidade(item)}
      {renderSubtotal(item)}
      <GridItem xs={12} sm={12} md={1}>
        <Box
          style={{ marginTop: 6, alignItems: "center", height: "100%" }}
          flexDirection="row"
          display="flex"
        >
          {renderDelete(item)}
          {dadosGerais?.tipo === NFTipo.ENTRADA && item?.produto == null && newMarker()}
          {item?.inventoryMovement != null && importedMarker()}
        </Box>
      </GridItem>
      {item?.produto?.enableForNf === false && productNotEnableForNfMarker()}
    </GridContainer>
  );

  const renderItems = () => {
    if (items.length === 0) {
      return add();
    }
    return items.map((item) => renderItem(item));
  };

  return (
    <Grid item xs={12} sm={12} md={12}>
      <GridItem xs={12} sm={12} md={12} style={{ marginTop: 30 }}>
        <Divider style={{ width: "100%", marginBottom: 4 }} />
        <Typography style={{ fontSize: 18, fontWeight: 500 }}>Produtos</Typography>
      </GridItem>
      {renderItems()}
      <GridContainer>
        {!disabled && renderAddButton()}
        <GridItem xs={12} sm={12} md={!disabled ? 4 : 7} />
        {renderDesconto()}
        {renderValorFinal()}
      </GridContainer>
      {renderModal()}
      {renderQuickAddProduct()}
    </Grid>
  );
};

export default React.memo(NotaFiscalProduto);
