/* eslint-disable no-console */
/* eslint-disable no-new */
import React, { useState, useCallback, useEffect } from "react";
import moment from "moment";
import { makeStyles } from "@mui/styles";
import CardStyle from "assets/jss/material-dashboard-react/components/cardStyle";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import Button from "components/CustomButtons/Button";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import CardFooter from "components/Card/CardFooter";
import { useHistory, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import AddressService from "components/address/service/AddressService";
import { Box, Paper } from "@mui/material";
import HistoryIcon from "components/icons/HistoryIcon";
import NotaFiscalDadosGerais from "views/notaFiscal/components/NotaFiscalDadosGerais";
import NotaFiscalCancelamentoModal from "views/notaFiscal/components/dialog/NotaFiscalCancelamentoModal";
import NotaFiscalTotais from "views/notaFiscal/components/NotaFiscalTotais";
import NotaFiscalVolumes from "views/notaFiscal/components/NotaFiscalVolumes";
import NotaFiscalFormaPagamento from "views/notaFiscal/components/NotaFiscalFormaPagamento";
import NotaFiscalRetencaoIcmsTransporte from "views/notaFiscal/components/NotaFiscalRetencaoIcmsTransporte";
import NotaFiscalTransporte from "views/notaFiscal/components/NotaFiscalTransporte";
import NotaFiscalProduto from "views/notaFiscal/components/NotaFiscalProduto";
import NotaFiscalEnderecoEntrega from "views/notaFiscal/components/NotaFiscalEnderecoEntrega";
import NotaFiscalDadosDestinatario from "views/notaFiscal/components/NotaFiscalDadosDestinatario";
import NotaFiscalHistorico from "views/notaFiscal/components/dialog/NotaFiscalHistorico";
import {
  NaturezaOperacaoPadraoType,
  getNaturezaOperacaoObservacao,
} from "views/naturezaOperacao/model/NaturezaOperacao";
import NaturezaOperacaoService from "views/naturezaOperacao/service/NaturezaOperacaoService";
import NotaFiscalEnvioEmailModal from "views/notaFiscal/components/dialog/NotaFiscalEnvioEmailModal";
import NotaFiscalInfoAdicionais from "views/notaFiscal/components/NotaFiscalInfoAdicionais";
import {
  NotaFiscal,
  NFFinalidade,
  NFTipo,
  NFOperacaoConsumidorFinal,
  NFIdentificadorLocalDestinoOperacao,
  NFIndicadorPresencaComprador,
  DocumentType,
  NFModalidadeFrete,
  NFIndicadorIEDestinatario,
  NotaFiscalStatusType,
  NFModelo,
} from "../notaFiscal/model/NotaFiscal";
import { fromResponse, toRequest } from "../notaFiscal/converter/NotaFiscalConverter";
import NotaFiscalService from "../notaFiscal/service/NotaFiscalService";
import { handlingResponseErrors } from "../../config/util";
import MESSAGES from "../../config/messages";
import Loading from "../../components/loading/Loading";

const { generalMessages } = MESSAGES;

const useCardStyle = makeStyles(CardStyle);

// eslint-disable-next-line react/prop-types
export default function NFCeEmissaoForm({ onCancel }) {
  const [loading, setLoading] = useState(false);
  const [motivoCancelamento, setMotivoCancelamento] = useState("");
  const [openCancelamentoNotaFiscalModal, setOpenCancelamentoNotaFiscalModal] = useState(false);
  const [openSendEmail, setOpenSendEmail] = useState(false);
  const classesCardStyle = useCardStyle();
  const history = useHistory();
  const routerParams = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [errors, setErrors] = useState({});
  const [states, setStates] = useState([]);
  const [readOnly, setReadOnly] = useState(null);
  const [ambiente, setAmbiente] = useState();
  const [avisoAvulsa, setAvisoAvulsa] = useState(false);

  const [notaFiscalHistoricoList, setNotaFiscalHistoricoList] = useState([]);
  const [naturezasOperacao, setNaturezasOperacao] = useState([]);

  const getDadosGeraisInitial = () => ({
    tipo: NFTipo.SAIDA.code,
    modelo: NFModelo.NFCE.code,
    operacaoConsumidorFinal: NFOperacaoConsumidorFinal.SIM.code,
    finalidade: NFFinalidade.NORMAL.code,
    identificadorLocalDestinoOperacao: NFIdentificadorLocalDestinoOperacao.OPERACAO_INTERNA.code,
    indicadorPresencaComprador: NFIndicadorPresencaComprador.OPERACAO_PRESENCIAL.code,
    desconto: 0,
  });

  const getDadosDestinatario = () => ({
    tipoDocumentoDestinatario: DocumentType.CNPJ.code,
    possuiEnderecoEntrega: false,
    indicadorIeDestinatario: NFIndicadorIEDestinatario.NAO_CONTRIBUINTE.code,
  });

  const getDadosTransporte = () => ({
    tipoDocumentoTransporte: DocumentType.CNPJ.code,
    modalidadeFreteTransporte: NFModalidadeFrete.SEM_OCORRENCIA_TRANSPORTE.code,
  });

  const getDadosVolumes = () => ({});

  const getDadosTotais = () => ({});

  const getDadosEnderecoEntrega = () => ({
    tipoDocumentoEnderecoEntrega: DocumentType.CPF.code,
  });

  const getDadosRetencaoIcmsTransporte = () => ({});

  const getDadosFormaPagamentoList = () => [
    {
      index: 0,
      vencimento: moment(),
    },
  ];

  const getDadosNotaFiscalProdutos = () => [];

  const [nfeEmissao, setNfeEmissao] = useState(
    new NotaFiscal({
      id: null,
      active: true,
    })
  );
  const [dadosGerais, setDadosGerais] = useState(getDadosGeraisInitial());
  const [dadosDestinatario, setDadosDestinatario] = useState(getDadosDestinatario());
  const [dadosTransporte, setDadosTransporte] = useState(getDadosTransporte());
  const [dadosVolume, setDadosVolume] = useState(getDadosVolumes());
  const [dadosTotais, setDadosTotais] = useState(getDadosTotais());
  const [dadosEnderecoEntrega, setDadosEnderecoEntrega] = useState(getDadosEnderecoEntrega());
  const [dadosRetencaoIcmsTransporte, setDadosRetencaoIcmsTransporte] = useState(
    getDadosRetencaoIcmsTransporte()
  );
  const [dadosFormaPagamentoList, setDadosFormaPagamentoList] = useState(
    getDadosFormaPagamentoList()
  );

  const [dadosNotaFiscalProdutos, setDadosNotaFiscalProdutos] = useState(
    getDadosNotaFiscalProdutos()
  );

  const [openHistoricoNotaFiscalModal, setOpenHistoricoNotaFiscalModal] = useState(false);

  const fetchStates = useCallback(async () => {
    try {
      setLoading(true);
      const result = await AddressService.fetchStates();
      const { data } = result;
      const { content } = data;
      setStates(content);
    } finally {
      setLoading(false);
    }
  }, []);

  const fetchNaturezasOperacao = useCallback(async () => {
    const { id } = routerParams || {};
    const result = await NaturezaOperacaoService.fetchAllWithoutTerm(NFTipo.SAIDA.code);
    const { data: dResult } = result;
    const naturezaList = dResult?.content || [];
    setNaturezasOperacao(naturezaList);
    // Apenas quando é uma nota nova
    if (!id) {
      const naturezaOperacao = naturezaList.find(
        (item) => item.padrao === NaturezaOperacaoPadraoType.CUPOM_FISCAL.code
      );
      if (naturezaOperacao) {
        setDadosGerais((prevDadosGerais) => {
          const newDadosGerais = {
            ...prevDadosGerais,
            naturezaOperacao,
          };

          return newDadosGerais;
        });

        const informacoesComplementares = getNaturezaOperacaoObservacao(naturezaOperacao);
        setNfeEmissao((prev) => ({
          ...prev,
          informacoesComplementares,
        }));
      }
    }
  }, [routerParams]);

  const fetchAmbiente = useCallback(async () => {
    const result = await NotaFiscalService.ambiente();
    setAmbiente(result?.data);
  }, []);

  useEffect(() => {
    fetchAmbiente();
    fetchStates();
    fetchNaturezasOperacao();
  }, [fetchStates, fetchNaturezasOperacao, fetchAmbiente]);

  const isCancelada = useCallback((status) => NotaFiscalStatusType.CANCELADA.code === status, []);

  const isEmitida = useCallback((status) => NotaFiscalStatusType.EMITIDA.code === status, []);

  const isNew = useCallback((id) => id == null, []);

  const getById = useCallback(
    async (id) => {
      if (id != null) {
        const result = await NotaFiscalService.fetchById(id);
        const response = fromResponse(result?.data?.data);
        const {
          dadosGerais: dadosGeraisResponse,
          dadosDestinatario: dadosDestinatarioResponse,
          notaFiscalFormaPagamentos: notaFiscalFormaPagamentosResponse,
          dadosVolume: dadosVolumeResponse,
          dadosTransporte: dadosTransporteResponse,
          dadosRetencaoIcmsTransporte: dadosRetencaoIcmsTransporteResponse,
          dadosEnderecoEntrega: dadosEnderecoEntregaResponse,
          notaFiscalProdutos: notaFiscalProdutosResponse,
          ...rest
        } = response;

        setNfeEmissao(rest);
        setDadosGerais(dadosGeraisResponse);
        setDadosDestinatario(dadosDestinatarioResponse);
        setDadosFormaPagamentoList(notaFiscalFormaPagamentosResponse);
        setDadosVolume(dadosVolumeResponse);
        setDadosTransporte(dadosTransporteResponse);
        setDadosRetencaoIcmsTransporte(dadosRetencaoIcmsTransporteResponse);
        setDadosEnderecoEntrega(dadosEnderecoEntregaResponse);
        setDadosNotaFiscalProdutos(notaFiscalProdutosResponse);

        const { status } = rest;
        const readOnlyByStatus = isEmitida(status) || isCancelada(status);
        setReadOnly(readOnlyByStatus);

        let avulsa = true;
        if (result?.data?.data?.order) {
          avulsa = !result?.data?.data?.order?.id;
        } else if (result?.data?.data?.serviceOrder) {
          avulsa = !result?.data?.data?.serviceOrder?.id;
        }
        setAvisoAvulsa(avulsa);
      } else {
        setAvisoAvulsa(true);
        setReadOnly(false);
      }
    },
    [isCancelada, isEmitida]
  );

  const fetchById = useCallback(async () => {
    try {
      setLoading(true);
      const { id } = routerParams || {};
      await getById(id);
    } finally {
      setLoading(false);
    }
  }, [getById, routerParams]);

  useEffect(() => {
    fetchById();
  }, [fetchById]);

  const handleChange = useCallback(
    (field, value) => {
      setNfeEmissao({
        ...nfeEmissao,
        [field]: value,
      });
    },
    [nfeEmissao]
  );

  // Usando um handler espefico devido a questão de carregar a observacao da natureza de operacao.
  const handleChangeNaturezaOperacao = useCallback((naturezaOperacao) => {
    const informacoesComplementares = getNaturezaOperacaoObservacao(naturezaOperacao);
    setDadosGerais((prev) => ({
      ...prev,
      naturezaOperacao,
    }));
    setNfeEmissao((prev) => ({
      ...prev,
      informacoesComplementares,
    }));
  }, []);

  const handleChangeDadosGerais = useCallback((value) => setDadosGerais(value), []);

  const handleChangeDadosDestinatario = useCallback((value) => setDadosDestinatario(value), []);

  const handleChangeDadosTransporte = useCallback((value) => setDadosTransporte(value), []);

  const handleChangeDadosVolume = useCallback((value) => setDadosVolume(value), []);

  const handleChangeEnderecoEntrega = useCallback((value) => setDadosEnderecoEntrega(value), []);

  const handleChangeDadosRetencaoIcmsTransporte = useCallback(
    (value) => setDadosRetencaoIcmsTransporte(value),
    []
  );

  const handleFormaPagamentoList = useCallback((value) => setDadosFormaPagamentoList(value), []);

  const handleChangeTotais = useCallback(
    (totais) => {
      const { totalNf } = totais;
      handleFormaPagamentoList((prevList) => {
        if (prevList.length === 1) {
          const newDadosFormaPagamentoList = [...prevList];

          newDadosFormaPagamentoList[0].valor = totalNf;
          return newDadosFormaPagamentoList;
        }
        return prevList;
      });

      setDadosTotais(totais);
    },
    [handleFormaPagamentoList]
  );

  const handleNotaFiscalProdutos = useCallback((value) => setDadosNotaFiscalProdutos(value), []);

  const goBack = useCallback(() => {
    if (onCancel) {
      onCancel();
    } else {
      history.push("/app/nfce-emissoes");
    }
  }, [history, onCancel]);

  const save = useCallback(async () => {
    const { id } = nfeEmissao;
    let response = null;

    if (id != null) {
      response = await NotaFiscalService.update(
        id,
        toRequest({
          entity: nfeEmissao,
          dadosGerais,
          dadosDestinatario,
          dadosEnderecoEntrega,
          dadosTransporte,
          dadosRetencaoIcmsTransporte,
          dadosVolume,
          dadosFormaPagamentoList,
          dadosNotaFiscalProdutos,
        })
      );
    } else {
      response = await NotaFiscalService.save(
        toRequest({
          entity: nfeEmissao,
          dadosGerais,
          dadosDestinatario,
          dadosEnderecoEntrega,
          dadosTransporte,
          dadosRetencaoIcmsTransporte,
          dadosVolume,
          dadosFormaPagamentoList,
          dadosNotaFiscalProdutos,
        })
      );
    }
    return response;
  }, [
    dadosDestinatario,
    dadosEnderecoEntrega,
    dadosFormaPagamentoList,
    dadosGerais,
    dadosNotaFiscalProdutos,
    dadosRetencaoIcmsTransporte,
    dadosTransporte,
    dadosVolume,
    nfeEmissao,
  ]);

  const onEmitir = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await save();
      const id = data?.data?.id || null;
      /*
       Após salvar, seta o id, pois se a emissão falhar, a nota vai ficar sem id e na proxima tentativa vai criar uma nova (duplicando) 
       */
      setNfeEmissao({
        ...nfeEmissao,
        id,
      });

      try {
        await NotaFiscalService.emitir(id);
        enqueueSnackbar("Nota fiscal emitida com sucesso!", {
          variant: "success",
          autoHideDuration: 3000,
        });
        setLoading(false);
        goBack();
      } catch (error) {
        /*
          Caso a emissão não funcionar, o version da entidade está mudando, precisa recarregar. 
          Assim, na proxima tentativa o version estará atualizado
        */
        getById(id);
        const { message } = handlingResponseErrors(error);
        enqueueSnackbar(message, {
          variant: "error",
          autoHideDuration: 3000,
        });
        setLoading(false);
      }
    } catch (error) {
      const { message } = handlingResponseErrors(error);
      enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
      setLoading(false);
    }
  }, [save, nfeEmissao, enqueueSnackbar, goBack, getById]);

  const onSave = useCallback(async () => {
    try {
      setLoading(true);
      await save();
      enqueueSnackbar("Nota fiscal salva com sucesso!", {
        variant: "success",
        autoHideDuration: 3000,
      });
      goBack();
    } catch (error) {
      const { message, validationErrors } = handlingResponseErrors(error);
      enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
      if (validationErrors != null) {
        setErrors(validationErrors);
      }
    } finally {
      setLoading(false);
    }
  }, [save, enqueueSnackbar, goBack]);

  const onCancelarNotaFiscal = useCallback(async () => {
    try {
      setLoading(true);

      const { id } = nfeEmissao;
      await NotaFiscalService.cancelarNotaFiscal(id, motivoCancelamento);
      enqueueSnackbar("Nota fiscal cancelada com sucesso!", {
        variant: "success",
        autoHideDuration: 3000,
      });
      goBack();
    } catch (error) {
      const { message, validationErrors } = handlingResponseErrors(error);
      enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
      if (validationErrors != null) {
        setErrors(validationErrors);
      }
    } finally {
      setLoading(false);
    }
  }, [nfeEmissao, motivoCancelamento, enqueueSnackbar, goBack]);

  const onPrintDanfe = useCallback(async () => {
    try {
      setLoading(true);

      const { id } = nfeEmissao;
      const response = await NotaFiscalService.printDanfe(id);
      const fileName = response?.headers["file-name"] || "DANFE.pdf";
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();

      // const file = new Blob([response.data], { type: "application/pdf" });
      // const fileURL = URL.createObjectURL(file);
      // const pdfWindow = window.open();
      // pdfWindow.location.href = fileURL;
    } catch (error) {
      if (
        error.request.responseType === "blob" &&
        error.response.data instanceof Blob &&
        error.response.data.type &&
        error.response.data.type.toLowerCase().indexOf("json") !== -1
      ) {
        new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = () => {
            const message = error.response.status === 428 ? reader?.result : generalMessages.error;
            enqueueSnackbar(message, {
              variant: "error",
              autoHideDuration: 3000,
            });
          };

          reader.onerror = () => {
            reject(error);
          };

          reader.readAsText(error.response.data);
        });
      }
    } finally {
      setLoading(false);
    }
  }, [nfeEmissao, enqueueSnackbar]);

  const onDownloadXML = useCallback(async () => {
    try {
      setLoading(true);

      const { id } = nfeEmissao;
      const response = await NotaFiscalService.xml(id);
      const fileName = response?.headers["file-name"] || "nota_fiscal.xml";
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();

      // const file = new Blob([response.data], { type: "application/pdf" });
      // const fileURL = URL.createObjectURL(file);
      // const pdfWindow = window.open();
      // pdfWindow.location.href = fileURL;
    } catch (error) {
      if (
        error.request.responseType === "blob" &&
        error.response.data instanceof Blob &&
        error.response.data.type &&
        error.response.data.type.toLowerCase().indexOf("json") !== -1
      ) {
        new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = () => {
            const message = error.response.status === 428 ? reader?.result : generalMessages.error;
            enqueueSnackbar(message, {
              variant: "error",
              autoHideDuration: 3000,
            });
          };

          reader.onerror = () => {
            reject(error);
          };

          reader.readAsText(error.response.data);
        });
      }
    } finally {
      setLoading(false);
    }
  }, [nfeEmissao, enqueueSnackbar]);

  const handleOpenHistoricoNotaFiscalModal = useCallback(async () => {
    setOpenHistoricoNotaFiscalModal(true);
    try {
      setLoading(true);
      const { data } = await NotaFiscalService.fetchHistorico(nfeEmissao.id);
      const historicoList = data?.data || [];
      setNotaFiscalHistoricoList(historicoList);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [nfeEmissao?.id]);

  const handleCloseHistoricoNotaFiscalModal = useCallback(() => {
    setOpenHistoricoNotaFiscalModal(false);
    setNotaFiscalHistoricoList([]);
  }, []);

  return (
    <>
      <GridContainer style={{ justifyContent: "center" }}>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classesCardStyle.cardTitle}>Cupom fiscal (NFC-e)</h4>
              <p className={classesCardStyle.cardCategory}>Emissão de cupons fiscais</p>
            </CardHeader>
            <CardBody>
              {readOnly === false && ambiente === "HOMOLOGACAO" && (
                <Paper
                  style={{
                    padding: "16px",
                    marginBottom: "16px",
                    backgroundColor: "#FCE4EC",
                    borderRadius: "8px",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  <strong>Atenção:</strong> Esta nota fiscal está sendo emitida em ambiente de
                  homologação.
                </Paper>
              )}

              {avisoAvulsa && (
                <Paper
                  style={{
                    padding: "16px",
                    marginBottom: "16px",
                    backgroundColor: "#E8F5E9",
                    borderRadius: "8px",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  <strong>Atenção:</strong> Uma nota fiscal avulsa é um documento fiscal emitido de
                  forma isolada, geralmente em casos excepcionais ou quando não há uma venda formal
                  realizada.
                  {"\n"}
                  Por favor, note que ao emitir uma nota fiscal avulsa, ela não terá impacto no seu
                  estoque ou nas transações financeiras da sua empresa.
                  {"\n"}
                  Para gerenciar suas vendas e estoque de forma eficaz, lembre-se de criar uma venda
                  e, em seguida, emitir a nota fiscal correspondente.
                </Paper>
              )}

              <Box
                display="flex"
                style={{
                  backgroundColor: "transparent",
                  padding: "8px",
                  borderRadius: "3px",
                  justifyContent: "flex-end",
                }}
              >
                {!isNew(nfeEmissao.id) && (
                  <HistoryIcon
                    onClick={handleOpenHistoricoNotaFiscalModal}
                    style={{ padding: 6 }}
                  />
                )}
              </Box>
              <NotaFiscalDadosGerais
                disabled={readOnly}
                data={dadosGerais}
                errors={errors}
                handleChange={handleChangeDadosGerais}
                handleChangeNaturezaOperacao={handleChangeNaturezaOperacao}
                naturezasOperacao={naturezasOperacao}
              />

              <NotaFiscalDadosDestinatario
                disabled={readOnly}
                data={dadosDestinatario}
                errors={errors}
                states={states}
                handleChange={handleChangeDadosDestinatario}
              />

              {dadosDestinatario.possuiEnderecoEntrega && (
                <NotaFiscalEnderecoEntrega
                  disabled={readOnly}
                  data={dadosEnderecoEntrega}
                  states={states}
                  errors={errors}
                  handleChange={handleChangeEnderecoEntrega}
                />
              )}

              <NotaFiscalProduto
                disabled={readOnly}
                naturezaOperacao={dadosGerais.naturezaOperacao}
                items={dadosNotaFiscalProdutos}
                errors={errors}
                handleChange={handleNotaFiscalProdutos}
                handleChangeTotais={handleChangeTotais}
                dadosGerais={dadosGerais}
                dadosTransporte={dadosTransporte}
                dadosDestinatario={dadosDestinatario}
                handleChangeDadosGerais={handleChangeDadosGerais}
              />

              <NotaFiscalFormaPagamento
                disabled={readOnly}
                items={dadosFormaPagamentoList}
                errors={errors}
                handleChange={handleFormaPagamentoList}
              />

              <NotaFiscalTransporte
                disabled={readOnly}
                data={dadosTransporte}
                errors={errors}
                states={states}
                handleChange={handleChangeDadosTransporte}
                handleChangeDadosVolume={handleChangeDadosVolume}
              />

              {dadosTransporte.possuiRetencaoIcmsTransporte && (
                <NotaFiscalRetencaoIcmsTransporte
                  disabled={readOnly}
                  data={dadosRetencaoIcmsTransporte}
                  states={states}
                  errors={errors}
                  handleChange={handleChangeDadosRetencaoIcmsTransporte}
                />
              )}

              {dadosTransporte.modalidadeFreteTransporte !==
                NFModalidadeFrete.SEM_OCORRENCIA_TRANSPORTE.code && (
                <NotaFiscalVolumes
                  disabled={readOnly}
                  data={dadosVolume}
                  errors={errors}
                  handleChange={handleChangeDadosVolume}
                />
              )}

              <NotaFiscalTotais
                data={dadosTotais}
                errors={errors}
                handleChange={handleChangeTotais}
              />

              <NotaFiscalInfoAdicionais
                disabled={readOnly}
                informacoesComplementares={nfeEmissao?.informacoesComplementares}
                handleChange={handleChange}
              />

              <NotaFiscalCancelamentoModal
                open={openCancelamentoNotaFiscalModal}
                onClose={() => setOpenCancelamentoNotaFiscalModal(false)}
                onCancel={onCancelarNotaFiscal}
                value={motivoCancelamento}
                handleChange={(field, value) => setMotivoCancelamento(value)}
                errors={errors}
              />
            </CardBody>
            <CardFooter>
              <Box display="flex" justifyContent="space-between" width="100%">
                <Box display="flex">
                  <Button color="primary" onClick={goBack}>
                    {generalMessages.cancel}
                  </Button>
                </Box>
                <Box display="flex">
                  {!isCancelada(nfeEmissao.status) &&
                    (readOnly ? (
                      <>
                        <Button
                          style={{ backgroundColor: "#ed0000" }}
                          onClick={() => setOpenCancelamentoNotaFiscalModal(true)}
                        >
                          Cancelar Nota Fiscal
                        </Button>
                        <Button color="primary" onClick={onPrintDanfe}>
                          Danfe
                        </Button>
                        <Button color="primary" onClick={() => setOpenSendEmail(true)}>
                          Enviar e-mail
                        </Button>
                        <Button color="primary" onClick={onDownloadXML}>
                          XML
                        </Button>
                      </>
                    ) : (
                      <>
                        <Button color="primary" onClick={onSave}>
                          {generalMessages.save}
                        </Button>
                        <Button color="primary" onClick={onEmitir}>
                          Salvar e emitir
                        </Button>
                      </>
                    ))}
                </Box>
              </Box>
            </CardFooter>
          </Card>
        </GridItem>
        <NotaFiscalHistorico
          open={openHistoricoNotaFiscalModal}
          onClose={handleCloseHistoricoNotaFiscalModal}
          items={notaFiscalHistoricoList}
        />
        <NotaFiscalEnvioEmailModal
          notaFiscalId={nfeEmissao?.id}
          emailInit={dadosDestinatario?.emailDestinatario}
          onClose={() => setOpenSendEmail(false)}
          open={openSendEmail}
        />
        <Loading loading={loading} />
      </GridContainer>
    </>
  );
}
