import React, { useCallback, useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { primaryColor } from "assets/jss/material-dashboard-react";
import GridItem from "components/Grid/GridItem";
import { useKeycloak } from "@react-keycloak/web";
import { Box, Button as ButtonUI, Typography } from "@mui/material";
import GridContainer from "components/Grid/GridContainer";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import CardStyle from "assets/jss/material-dashboard-react/components/cardStyle";
import MyPlan from "views/myPlan/MyPlan";
import LoginService from "views/login/service/LoginService";
import Button from "components/CustomButtons/Button";
import { isAdmin } from "../../config/util";

const useCardStyle = makeStyles(CardStyle);

export default function CheckIn() {
  const { keycloak, initialized } = useKeycloak();
  const isLoggedIn = keycloak?.authenticated;
  const classesuseCardStyle = useCardStyle();

  const [userMe, setUserMe] = useState(null);
  const freePlan = userMe?.plan?.freePlan || false;
  document.title = `Kairum | Regularizar plano`;

  const fetchMe = useCallback(async () => {
    const me = await LoginService.me();
    setUserMe(me?.data);
  }, []);

  useEffect(() => {
    if (initialized) {
      if (!isLoggedIn) {
        return keycloak.login();
      }
      return fetchMe();
    }
    return null;
  }, [initialized, isLoggedIn, keycloak, fetchMe]);

  if (!userMe) {
    return <></>;
  }

  const planRegularize = () => {
    const { plan } = userMe;
    const { urlMyPlanStripe } = plan;
    const { url } = urlMyPlanStripe;
    window.open(url, "_blank");
  };

  const getLayout = () => {
    if (isAdmin(userMe.roles)) {
      return (
        <>
          <Typography>
            Olá, seu plano não está regularizado, pressione o botão&nbsp;
            <b>QUERO REGULARIZAR MEU PLANO</b> para continuar com os ajustes.
          </Typography>
          <Typography style={{ marginTop: 20 }}>Equipe Kairum, agredece!</Typography>

          <div style={{ flexGrow: 1, display: "flex", justifyContent: "space-between" }}>
            <ButtonUI
              style={{ color: primaryColor[0] }}
              onClick={() => keycloak.logout({ redirectUri: window.location.origin })}
            >
              Voltar
            </ButtonUI>
            <ButtonUI style={{ color: primaryColor[0] }} onClick={planRegularize}>
              Quero regularizar meu plano
            </ButtonUI>
          </div>
        </>
      );
    }

    return (
      <>
        <Typography>Seu plano não é válido, entre em contato com o suporte!</Typography>
        <ButtonUI
          style={{ color: primaryColor[0] }}
          onClick={() => keycloak.logout({ redirectUri: window.location.origin })}
        >
          Voltar
        </ButtonUI>
      </>
    );
  };

  return (
    <div style={{ justifyContent: "center", display: "flex" }}>
      <GridContainer xs={8} sm={12} md={8}>
        <GridItem xs={12} sm={12} md={12}>
          {freePlan ? (
            <>
              <MyPlan userMe={userMe} />
              <GridItem xs={12} sm={12} md={12}>
                <Box style={{ display: "flex", justifyContent: "center", paddingBottom: 20 }}>
                  <Button
                    color="primary"
                    onClick={() => keycloak.logout({ redirectUri: window.location.origin })}
                  >
                    Sair da Kairum
                  </Button>
                </Box>
              </GridItem>
            </>
          ) : (
            <Card>
              <CardHeader color="primary">
                <h4 className={classesuseCardStyle.cardTitleList}>{userMe.companyName}</h4>
                <p className={classesuseCardStyle.cardCategoryList}>{userMe.name}</p>
              </CardHeader>

              <CardBody>{getLayout()}</CardBody>
            </Card>
          )}
        </GridItem>
      </GridContainer>
    </div>
  );
}
