/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useCallback, useEffect } from "react";
import { makeStyles } from "@mui/styles";

import CardStyle from "assets/jss/material-dashboard-react/components/cardStyle";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import Button from "components/CustomButtons/Button";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import CardFooter from "components/Card/CardFooter";
import { useHistory, useParams } from "react-router-dom";
import {Box, Divider, FormControlLabel, FormGroup, Switch, Typography} from "@mui/material";
import { useSnackbar } from "notistack";
import UnitConversion from "./model/UnitConversion";
import { toRequest, fromResponse } from "./converter/UnitConversionConverter";
import UnitConversionService from "./service/UnitConversionService";
import { handlingResponseErrors } from "../../config/util";
import MESSAGES from "../../config/messages";
import Loading from "../../components/loading/Loading";
import TextInput from "../../components/textInput/TextInput";
import Autocomplete from "../../components/autocomplete/Autocomplete";
import UnitMeasurementService from "../unitMeasurement/service/UnitMeasurementService";
import CurrencyTextInput from "../../components/textInput/CurrencyTextInput";

const { generalMessages} = MESSAGES;

const useCardStyle = makeStyles(CardStyle);

// eslint-disable-next-line react/prop-types
export default function UnitConversionForm({ onCancel, afterSave, name }) {
  const classesCardStyle = useCardStyle();
  const history = useHistory();
  const routerParams = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const [unit, setUnit] = useState(new UnitConversion({ name, active: true }));

  const fetchById = useCallback(async () => {
    try {
      setLoading(true);
      const { id } = routerParams || {};
      if (id != null) {
        const result = await UnitConversionService.fetchById(id);
        const response = fromResponse(result?.data?.data);
        setUnit(response);
      }
    } finally {
      setLoading(false);
    }
  }, [routerParams]);

  useEffect(() => {
    fetchById();
  }, [fetchById]);

  const handleChange = useCallback(
    (field, value) => {
      setUnit({
        ...unit,
        [field]: value,
      });
    },
    [unit]
  );

  const goBack = useCallback(() => {
    if (onCancel) {
      onCancel();
    } else {
      history.push("/app/units-conversion");
    }
  }, [history, onCancel]);

  const onSave = useCallback(async () => {
    try {
      setLoading(true);
      const { id } = unit;
      if (id != null) {
        await UnitConversionService.update(id, toRequest(unit));
      } else {
        const response = await UnitConversionService.save(toRequest(unit));
        if (afterSave) {
          afterSave(response?.data?.data);
        }
      }
      enqueueSnackbar(generalMessages.saveSuccess, {
        variant: "success",
        autoHideDuration: 3000,
      });
      goBack();
    } catch (error) {
      const { message, validationErrors } = handlingResponseErrors(error);
      enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
      if (validationErrors != null) {
        setErrors(validationErrors);
      }
    } finally {
      setLoading(false);
    }
  }, [unit, goBack, enqueueSnackbar, afterSave]);

  const renderSwitch = (value = false, field, label, disabled = false) => (
    <FormGroup style={{ paddingTop: "8px" }}>
      <FormControlLabel
        control={
          <Switch
            color="secondary"
            checked={value}
            onChange={(event) => handleChange(field, event.target.checked)}
          />
        }
        label={label}
        disabled={disabled}
      />
    </FormGroup>
  );

  return (
    <>
      <GridContainer style={{ justifyContent: "center" }}>
        <GridItem xs={12} sm={12} md={10}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classesCardStyle.cardTitle}>
                Unidade de conversão
              </h4>
              <p className={classesCardStyle.cardCategory}>
                Cadastro de novas unidades de conversão
              </p>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={5}>
                  <Box display="flex" flexGrow={1}>
                    <Autocomplete
                      width="100%"
                      variant="outlined"
                      fieldsToShowAfterSelectItem={["acronym", "name"]}
                      label="Unidade de Medida Original"
                      placeholder="Digite para pesquisar as unidades ..."
                      service={UnitMeasurementService}
                      value={unit.sourceUnit}
                      onChange={(value) => {
                        handleChange("sourceUnit", value);
                      }}
                      required
                      error={errors.sourceUnit}
                    />
                  </Box>
                </GridItem>
                <GridItem xs={12} sm={12} md={5}>
                  <Box display="flex" flexGrow={1}>
                    <Autocomplete
                      width="100%"
                      variant="outlined"
                      fieldsToShowAfterSelectItem={["acronym", "name"]}
                      label="Unidade de Medida Destino"
                      placeholder="Digite para pesquisar as unidades ..."
                      service={UnitMeasurementService}
                      value={unit.targetUnit}
                      onChange={(value) => {
                        handleChange("targetUnit", value);
                      }}
                      required
                      error={errors.targetUnit}
                    />
                  </Box>
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  <CurrencyTextInput
                    id="conversionFactor"
                    label="Fator de conversão"
                    currencySymbol=""
                    value={unit.conversionFactor}
                    onChange={handleChange}
                    required
                    maxLength={18}
                    errors={errors}
                  />
                </GridItem>
                {unit.sourceUnit && unit.targetUnit && unit.conversionFactor && (
                <GridItem xs={12} sm={12} md={12} style={{ marginTop: 12 }}>
                  <Divider style={{ width: "100%", marginBottom: 4 }} />
                  <Typography style={{ fontSize: 18, fontWeight: 500 }}>Ao importar um produto em seu estoque e fazer a conversão, significa que 1 {unit.sourceUnit?.name} vai se transformar em {unit.conversionFactor} {unit.targetUnit?.name}(s)</Typography>
                </GridItem>
                ) }

                <GridItem xs={12} sm={12} md={12}>
                  {renderSwitch(unit.active, "active", "Ativo")}
                </GridItem>
              </GridContainer>
            </CardBody>
            <CardFooter>
              <Button color="primary" onClick={goBack}>
                {generalMessages.cancel}
              </Button>
              <Button color="primary" onClick={onSave}>
                {generalMessages.save}
              </Button>
            </CardFooter>
          </Card>
        </GridItem>
        <Loading loading={loading} />
      </GridContainer>
    </>
  );
}
