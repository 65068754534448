/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from "react";
import moment from "moment";
import { makeStyles } from "@mui/styles";
import { DatePicker } from "@mui/x-date-pickers";
import { primaryColor } from "assets/jss/material-dashboard-react";
import AutocompleteStyle from "components/autocomplete/AutocompleteStyle";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import Autocomplete from "components/autocomplete/Autocomplete";
import { Modal, Box, ListItem, ListItemText, Button as ButtonUI } from "@mui/material";
import PersonForm from "views/person/PersonForm";
import PersonService from "views/person/service/PersonService";
import { RegistrationType } from "views/person/model/Person";
import UserService from "../../user/service/UserService";
import MESSAGES from "../../../config/messages";
import TextInput from "../../../components/textInput/TextInput";
import { AUTOCOMPLETE } from "../../../config/constants";

const useAutocompleteStyle = makeStyles(AutocompleteStyle);
const { autocompleteMessages } = MESSAGES;

const CHARACTER_LIMIT = 4000;

const { orderMessages } = MESSAGES;

const OrderGeneralStep = ({ onChange, order, errors }) => {
  const [quickAddCustomerModalOpen, setQuickAddCustomerModalOpen] = useState(false);
  const [onTypedChange, setOnTypedChange] = useState(null);
  const classesAutocompleteStyle = useAutocompleteStyle();

  const renderQuickAddCustomer = () => (
    <Modal style={{ overflow: "auto" }} disableEscapeKeyDown open={quickAddCustomerModalOpen}>
      <PersonForm
        name={onTypedChange}
        onCancel={() => setQuickAddCustomerModalOpen(false)}
        afterSave={(customer) => onChange("customer", customer)}
      />
    </Modal>
  );

  const renderQuickAddText = () => (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      className={classesAutocompleteStyle.emptyContainer}
    >
      <Box>{autocompleteMessages.emptyText}</Box>
      <Box>
        <ButtonUI
          style={{ color: primaryColor[0] }}
          onMouseDown={() => setQuickAddCustomerModalOpen(true)}
        >
          Adicionar
        </ButtonUI>
      </Box>
    </Box>
  );

  const renderOptionCustomer = (props, item) => {
    const { id, name } = item;

    if (id === AUTOCOMPLETE.QUICK_ADD) {
      return renderQuickAddText();
    }

    return (
      <ListItem {...props}>
        <ListItemText primary={name} />
      </ListItem>
    );
  };

  return (
    <>
      <GridContainer>
        <GridItem xs={12} sm={12} md={6}>
          <Box display="flex" flexGrow={1}>
            <Autocomplete
              width="100%"
              autoFocus
              variant="outlined"
              fieldsToShowAfterSelectItem={["name"]}
              label={orderMessages.generalStep.customer}
              placeholder={orderMessages.generalStep.autocompleteCustomer}
              service={PersonService}
              extraSearch={{ registrationType: RegistrationType.CUSTOMER.code }}
              value={order.customer}
              onChange={(value) => onChange("customer", value)}
              onTypedChange={(value) => setOnTypedChange(value)}
              required
              error={errors.customerId}
              helperText={errors.customerId}
              renderOption={renderOptionCustomer}
              quickAdd
            />
          </Box>
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <Autocomplete
            variant="outlined"
            fieldsToShowAfterSelectItem={["name"]}
            label={orderMessages.generalStep.saller}
            placeholder={orderMessages.generalStep.autocompleteSaller}
            service={UserService}
            value={order.user}
            onChange={(value) => onChange("user", value)}
            // renderOption={(item) => `${item.name}`}
            required
            error={errors.userId}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={3} style={{ marginTop: "12px" }}>
          <DatePicker
            id="expirationDate"
            label={orderMessages.generalStep.expirationDate}
            format="dd/MM/yyyy"
            slotProps={{ textField: { size: "small" } }}
            value={order?.expirationDate ? moment(order.expirationDate).valueOf() : null}
            helperText={errors.expirationDate}
            onChange={(date) => onChange("expirationDate", date)}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <TextInput
            id="additionalInformation"
            label={orderMessages.generalStep.additionalInformation}
            value={order.additionalInformation}
            maxLength={CHARACTER_LIMIT}
            onChange={onChange}
            isCounter
            multiline
            rows={4}
          />
        </GridItem>
      </GridContainer>
      {renderQuickAddCustomer()}
    </>
  );
};

export default React.memo(OrderGeneralStep);
