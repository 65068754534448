import axios from "axios";
import { APIS } from "../../../config/constants";
import BaseService from "../../../core/BaseService";

const { register: registerAPI } = APIS;

class CalendarService extends BaseService {
  constructor() {
    super(registerAPI.calendar);
  }

  fetchAll = () => axios.get(`${registerAPI.calendar}/all?size=100`);
}

export default new CalendarService();
