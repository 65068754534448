import React, { useState, useEffect, useCallback } from "react";
import { useDebounce } from "use-debounce";
import { makeStyles } from "@mui/styles";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import CardStyle from "assets/jss/material-dashboard-react/components/cardStyle";
import { Chip } from "@mui/material";
import HistoryIcon from "components/icons/HistoryIcon";
import SeeIcon from "components/icons/SeeIcon";
import NFeInutilizacaoService from "./service/NFeInutilizacaoService";
import MESSAGES from "../../config/messages";
import { SEARCH } from "../../config/constants";
import Loading from "../../components/loading/Loading";
import AddButton from "../../components/addButton/AddButton";
import EditIcon from "../../components/icons/EditIcon";
import DataGridCustom from "../../components/dataGrid/DataGridCustom";
import { DFModelo, NFeInutilizacaoStatusType } from "./model/NFeInutilizacao";
import NFeInutilizacaoHistorico from "./components/dialog/NFeInutilizacaoHistorico";

const { generalMessages } = MESSAGES;

const useCardStyle = makeStyles(CardStyle);

export default function NFeInutilizacaoList() {
  const classesuseCardStyle = useCardStyle();
  const history = useHistory();
  const [list, setList] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchTermDebounce] = useDebounce(searchTerm, SEARCH.WAIT_INTERVAL);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const [openHistoricoNotaFiscalModal, setOpenHistoricoNotaFiscalModal] = useState(false);
  const [notaFiscalHistoricoList, setNotaFiscalHistoricoList] = useState([]);

  const handleOpenHistoricoNotaFiscalModal = useCallback(async (id) => {
    setOpenHistoricoNotaFiscalModal(true);
    try {
      setLoading(true);
      const { data } = await NFeInutilizacaoService.fetchHistorico(id);
      const historicoList = data?.data || [];
      setNotaFiscalHistoricoList(historicoList);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, []);

  const handleCloseHistoricoNotaFiscalModal = useCallback(() => {
    setOpenHistoricoNotaFiscalModal(false);
    setNotaFiscalHistoricoList([]);
  }, []);

  const fetchAll = useCallback(async () => {
    if (searchTermDebounce !== "" && searchTermDebounce.length < SEARCH.MINIMUM_CHARACTERS) return;

    setLoading(true);
    try {
      const result = await NFeInutilizacaoService.fetchAll("");
      const { data } = result;
      const { content } = data;
      setList(content);
    } catch (error) {
      enqueueSnackbar(generalMessages.error, {
        variant: "error",
      });
    } finally {
      setLoading(false);
    }
  }, [enqueueSnackbar, searchTermDebounce]);

  useEffect(() => {
    fetchAll();
  }, [fetchAll]);

  const goToForm = (params) =>
    params?.id
      ? history.push(`/app/nfe-inutilizacao/${params?.id}`)
      : history.push("/app/nfe-inutilizacao");

  const formatterStatus = (item) => {
    const { status } = item;

    if (status != null && status !== undefined) {
      switch (status) {
        case NFeInutilizacaoStatusType.CRIADA.code:
          return (
            <Chip
              size="small"
              label={NFeInutilizacaoStatusType[status].name}
              style={{ backgroundColor: "#1186e3", color: "#ffff" }}
            />
          );

        case NFeInutilizacaoStatusType.TRANSMITIDA.code:
          return (
            <Chip
              size="small"
              label={NFeInutilizacaoStatusType[status].name}
              style={{ backgroundColor: "#0e850b", color: "#ffff" }}
            />
          );

        case NFeInutilizacaoStatusType.ERRO.code:
          return (
            <Chip
              size="small"
              label={NFeInutilizacaoStatusType[status].name}
              style={{ backgroundColor: "#e53935", color: "#ffff" }}
            />
          );
        default:
          return "";
      }
    }
    return "";
  };

  const readyOnly = (item) => {
    const { status } = item;
    return status === NFeInutilizacaoStatusType.TRANSMITIDA.code;
  };

  const columns = [
    {
      field: "serie",
      headerName: "Série",
      minWidth: 120,
      flex: 1,
    },
    {
      field: "modelo",
      headerName: "Série para venda de",
      minWidth: 250,
      flex: 1,
      renderCell: ({ row: item }) => DFModelo[item.modelo].name,
    },

    {
      field: "numeroInicial",
      headerName: "Número inicial",
      minWidth: 200,
      flex: 1,
    },

    {
      field: "numeroFinal",
      headerName: "Número final",
      minWidth: 200,
      flex: 1,
    },
    {
      field: "justificativa",
      headerName: "Justificativa",
      minWidth: 200,
      flex: 1,
    },
    {
      field: "status",
      headerName: "Situação",
      width: 150,
      renderCell: ({ row: item }) => (
        <div
          style={{
            justifyContent: "center",
            padding: "10px",
            display: "flex",

            flex: 1,
          }}
        >
          {formatterStatus(item)}
        </div>
      ),
    },
    {
      field: "actions",
      headerName: generalMessages.actions,
      sortable: false,
      width: 100,
      disableClickEventBubbling: true,
      renderCell: (params) => (
        <div
          style={{
            cursor: "pointer",
            justifyContent: "flex-end",
            padding: "10px",
            display: "flex",
            flex: 1,
          }}
        >
          <>
            {readyOnly(params.row) ? (
              <SeeIcon onClick={() => goToForm({ id: params.row.id })} style={{ padding: 6 }} />
            ) : (
              <EditIcon onClick={() => goToForm({ id: params.row.id })} style={{ padding: 6 }} />
            )}
            <HistoryIcon
              onClick={() => handleOpenHistoricoNotaFiscalModal(params.row?.id || null)}
              style={{ padding: 6 }}
            />
          </>
        </div>
      ),
    },
  ];

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card className="gridLayoutCard">
          <div className="gridLayoutContainer">
            <CardHeader color="primary">
              <h4 className={classesuseCardStyle.cardTitleList}>Inutilizações de Notas Fiscais</h4>
              <p className={classesuseCardStyle.cardCategoryList}>
                Lista de inutilizações de Notas Fiscais cadastradas
              </p>
            </CardHeader>

            <div className="gridLayoutGrid">
              <DataGridCustom onRowDoubleClick={goToForm} rows={list} columns={columns} />
            </div>

            <div className="gridLayoutBottom">
              <AddButton onClick={() => goToForm(null)} />
            </div>

            <NFeInutilizacaoHistorico
              open={openHistoricoNotaFiscalModal}
              onClose={handleCloseHistoricoNotaFiscalModal}
              items={notaFiscalHistoricoList}
            />
          </div>
        </Card>
      </GridItem>
      <Loading loading={loading} />
    </GridContainer>
  );
}
