class Company {
  constructor(source = {}) {
    if (source == null) return this;

    this.id = source.id;
    this.companyName = source.companyName;
    this.tradingName = source.tradingName;
    this.cnpj = source.cnpj;
    this.cpf = source.cpf;
    this.documentType = source.documentType;
    this.foundationDate = source.foundationDate;
    this.phone = source.phone;
    this.mobile = source.mobile;
    this.email = source.email;
    this.state = source.state;
    this.city = source.city;
    this.street = source.street;
    this.number = source.number;
    this.complement = source.complement;
    this.zipCode = source.zipCode;
    this.neighborhood = source.neighborhood;
    this.version = source.version;
    this.addressId = source.addressId;
    this.addressVersion = source.addressVersion;
    this.profileBusiness = source.profileBusiness;
    this.inscricaoEstadualSubstitutoTributario = source.inscricaoEstadualSubstitutoTributario;
    this.inscricaoEstadual = source.inscricaoEstadual;
  }
}

export default Company;
