import axios from "axios";
import { APIS } from "../../../config/constants";
import BaseService from "../../../core/BaseService";

const { register: registerAPI } = APIS;

class NFeImportacaoService extends BaseService {
  constructor() {
    super(registerAPI.notaFiscalImportacao);
  }

  import = (file) =>
    axios.post(`${this.endpoint}/import-file`, file, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

  save = (request, id) => axios.post(`${this.endpoint}/importar-estoque/${id}`, request);

  importarFornecedor = (id) => axios.post(`${this.endpoint}/importar-fornecedor/${id}`);

  importarTransportador = (id) => axios.post(`${this.endpoint}/importar-transportador/${id}`);

  importarDespesas = (id, data) => axios.post(`${this.endpoint}/importar-despesa/${id}`, data);
}

export default new NFeImportacaoService();
