/* eslint-disable react/prop-types */
import React from "react";
import { makeStyles } from "@mui/styles";
import CustomComponentsStyle from "assets/jss/material-dashboard-react/components/customComponentsStyle";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import GridItem from "components/Grid/GridItem";
import TextInput from "components/textInput/TextInput";
import CurrencyTextInput from "components/textInput/CurrencyTextInput";
import MESSAGES from "config/messages";
import GridContainer from "components/Grid/GridContainer";

import { SituacaoTributariaIssqn, YesNo } from "../model/NaturezaOperacao";
import SelectTipoCalculo from "./SelectTipoCalculo";

const { naturezaOperacaoMessages } = MESSAGES;
const CHARACTER_LIMIT = 4000;

const NOIssqnTab = ({ data, handleChange, errors = {} }) => {
  const useCustomComponentsStyle = makeStyles(CustomComponentsStyle);
  const classesCustomComponentsStyle = useCustomComponentsStyle();

  const {
    situacaoTributariaIssqn,
    aliquotaIssqn,
    baseCalculoIssqn,
    descontarIssIssqn,
    observacaoIssqn,
    tipoCalculoIssqn,
  } = data;

  // const renderTipoCalculo = () => (
  //   <GridItem xs={8} sm={8} md={3} style={{ marginTop: -6 }}>
  //     <SelectTipoCalculo
  //       field="tipoCalculoIssqn"
  //       valueSelected={tipoCalculoIssqn}
  //       handleChange={handleChange}
  //     />
  //   </GridItem>
  // );

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={3} style={{ marginTop: -6 }}>
        <FormControl
          fullWidth
          className={classesCustomComponentsStyle.formControlSelect}
          size="small"
          variant="outlined"
        >
          <InputLabel>{naturezaOperacaoMessages.issqn.situacaoTributariaIssqn}</InputLabel>
          <Select
            label={naturezaOperacaoMessages.issqn.situacaoTributariaIssqn}
            id="situacaoTributariaIssqn"
            autoWidth
            value={
              situacaoTributariaIssqn == null
                ? SituacaoTributariaIssqn.SELECIONE.value
                : situacaoTributariaIssqn
            }
            onChange={(event) => {
              const {
                target: { value },
              } = event;
              return handleChange(
                "situacaoTributariaIssqn",
                value === SituacaoTributariaIssqn.SELECIONE.value ? null : value
              );
            }}
            error={errors.situacaoTributariaIssqn}
            helperText={naturezaOperacaoMessages.issqn.situacaoTributariaIssqn}
          >
            {Object.keys(SituacaoTributariaIssqn).map((key) => {
              const { code, name } = SituacaoTributariaIssqn[key];
              return (
                <MenuItem key={code} value={code}>
                  {name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </GridItem>
      <GridItem xs={8} sm={8} md={3}>
        <CurrencyTextInput
          currencySymbol="%"
          id="aliquotaIssqn"
          label={naturezaOperacaoMessages.issqn.aliquotaIssqn}
          value={aliquotaIssqn || "0"}
          onChange={handleChange}
          errors={errors}
        />
      </GridItem>
      <GridItem xs={8} sm={8} md={3}>
        <CurrencyTextInput
          currencySymbol="%"
          decimalPlaces={4}
          id="baseCalculoIssqn"
          label={naturezaOperacaoMessages.issqn.baseCalculoIssqn}
          value={baseCalculoIssqn || "0"}
          onChange={handleChange}
          errors={errors}
        />
      </GridItem>
      <GridItem xs={12} sm={12} md={3} style={{ marginTop: -6 }}>
        <FormControl
          fullWidth
          className={classesCustomComponentsStyle.formControlSelect}
          size="small"
        >
          <InputLabel>{naturezaOperacaoMessages.issqn.descontarIssIssqn}</InputLabel>
          <Select
            id="descontarIssIssqn"
            autoWidth
            value={descontarIssIssqn === true ? YesNo.YES.code : YesNo.No.code}
            onChange={(event) => {
              const {
                target: { value },
              } = event;
              return handleChange("descontarIssIssqn", value);
            }}
            error={errors.descontarIssIssqn}
            helperText={errors.descontarIssIssqn}
          >
            {Object.keys(YesNo).map((key) => {
              const { code, name } = YesNo[key];
              return (
                <MenuItem key={code} value={code}>
                  {name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </GridItem>

      <GridItem xs={12} sm={12} md={12}>
        <TextInput
          id="observacaoIssqn"
          label={naturezaOperacaoMessages.issqn.observacaoIssqn}
          value={observacaoIssqn}
          maxLength={CHARACTER_LIMIT}
          onChange={handleChange}
          isCounter
          multiline
          rows={4}
        />
      </GridItem>

      {/* {renderTipoCalculo()} */}
    </GridContainer>
  );
};

export default React.memo(NOIssqnTab);
