/* eslint-disable react/prop-types */
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { makeStyles } from "@mui/styles";
import styles from "./iconStyle";

const useStyles = makeStyles(styles);

const NextIcon = ({ onClick }) => {
  const classes = useStyles();
  return (
    <FontAwesomeIcon
      icon={faAngleRight}
      onClick={onClick}
      className={classes.custom2}
      title="Próximo"
      color="#686868"
    />
  );
};

export default React.memo(NextIcon);
