import axios from "axios";
import { APIS } from "../../../config/constants";
import BaseService from "../../../core/BaseService";

const { register: registerAPI } = APIS;

class ProductService extends BaseService {
  constructor() {
    super(registerAPI.product);
  }

  fetchByBarCode = (term) => axios.get(`${this.endpoint}/search-by-barcode/${term}`);

  fetchAll = ({ term, pagination, sorting }) => {
    const { page, pageSize } = pagination;
    const { field = "createdAt", sort = "desc" } = sorting || {};
    return axios.get(
      `${this.endpoint}/search-products?size=${pageSize}&page=${page}&sort=${field},${sort}&term=${term}&service=false`
    );
  };

  fetchAllPriceSearch = (term) =>
    axios.get(
      `${this.endpoint}/search-products?size=100&sort=active,DESC&sort=name&term=${term}&service=false`
    );

  searchAutoComplete = (queries) => {
    const params = [];
    Object.keys(queries).forEach((key) => {
      const param = `${key};${queries[key]}`;
      params.push(param);
    });

    return axios.get(
      `${this.endpoint}/search?size=30&sort=name,ASC&queryField=${params.join(",")}`
    );
  };
}

export default new ProductService();
