/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import CustomComponentsStyle from "assets/jss/material-dashboard-react/components/customComponentsStyle";
import { useSnackbar } from "notistack";
import { DatePicker } from "@mui/x-date-pickers";
import AutocompleteStyle from "components/autocomplete/AutocompleteStyle";
import moment from "moment";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import Autocomplete from "components/autocomplete/Autocomplete";
import BigNumber from "bignumber.js";
import {
  Button as ButtonUI,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Typography,
  Box,
  Modal,
  ListItem,
} from "@mui/material";
import { AUTOCOMPLETE } from "config/constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import PersonForm from "views/person/PersonForm";
import InventoryMovementService from "../service/InventoryMovementService";
import { primaryColor } from "../../../assets/jss/material-dashboard-react";
import Loading from "../../../components/loading/Loading";
import MESSAGES from "../../../config/messages";
import {
  MovementType,
  UpdateCostType,
  UpdatePriceMinimumType,
  UpdatePriceType,
} from "../model/InventoryProduct";
import TextInput from "../../../components/textInput/TextInput";
import { currencyFormatter, handlingResponseErrors } from "../../../config/util";
import CurrencyTextInput from "../../../components/textInput/CurrencyTextInput";
import InventoryService from "../service/InventoryService";
import { RegistrationType } from "../../person/model/Person";
import PersonService from "../../person/service/PersonService";

const useAutocompleteStyle = makeStyles(AutocompleteStyle);

const { generalMessages, autocompleteMessages } = MESSAGES;

const useCustomComponentsStyle = makeStyles(CustomComponentsStyle);

const InventoryMovementModal = ({ open, onClose, productId }) => {
  const classesAutocompleteStyle = useAutocompleteStyle();
  const classesCustomComponentsStyle = useCustomComponentsStyle();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [product, setProduct] = useState({});
  const [onTypedChange, setOnTypedChange] = useState(null);
  const [quickAddPersonModalOpen, setQuickAddPersonModalOpen] = useState(false);

  const calcNewPriceMinimumMargin = (movementNewCost, productCost) =>
    !movementNewCost.isZero() && !productCost.isZero()
      ? movementNewCost
          .minus(productCost)
          .dividedBy(productCost)
          .multipliedBy(new BigNumber(100))
          .toFixed(2)
      : new BigNumber(0);

  const calcNewPriceMinimum = (productPriceMinimum, movementNewCost, productCost) => {
    const margem = calcNewPriceMinimumMargin(movementNewCost, productCost);
    const margemDeAumento = new BigNumber(margem).dividedBy(new BigNumber(100));
    const valorDoAcrescimo = productPriceMinimum.multipliedBy(margemDeAumento);
    return !movementNewCost.isZero()
      ? productPriceMinimum.plus(valorDoAcrescimo)
      : new BigNumber(0);
  };

  const calcNewPrice = (movementNewCost, productMargin) => {
    const margemDeAumento = new BigNumber(productMargin).dividedBy(new BigNumber(100));
    const valorDoAcrescimo = new BigNumber(movementNewCost).multipliedBy(margemDeAumento);
    return !movementNewCost.isZero() ? movementNewCost.plus(valorDoAcrescimo) : new BigNumber(0);
  };

  const calcNewCost = (movementQuantity, movementCostAverage, inventoryQuantity, productCost) =>
    movementQuantity
      .multipliedBy(movementCostAverage)
      .plus(inventoryQuantity.multipliedBy(productCost))
      .dividedBy(inventoryQuantity.plus(movementQuantity));

  const [movement, setMovement] = useState({
    date: new Date(),
    type: MovementType.ENTRY.code,
    updateCostType: UpdateCostType.ENTRY.code,
    updatePriceType: UpdatePriceType.MARGIN.code,
    updatePriceMinimumType: UpdatePriceMinimumType.MARGIN.code,
  });

  const [newPrices, setNewPrices] = useState({
    newPrice: new BigNumber(product?.product?.saleValue),
    newCost: new BigNumber(product?.product?.cost),
    newPriceMinimum: new BigNumber(product?.product?.priceMinimum),
    newPriceMinimumMargin: calcNewPriceMinimumMargin(
      new BigNumber(product?.product?.priceMinimum),
      new BigNumber(product?.product?.cost)
    ),
  });

  const fetch = useCallback(async () => {
    setLoading(true);
    try {
      const result = await InventoryService.findByProduct(productId);
      const { data } = result;
      setProduct(data || {});
    } catch (error) {
      enqueueSnackbar(error.response.status === 428 ? error.response.data : generalMessages.error, {
        variant: "error",
      });
    } finally {
      setLoading(false);
    }
  }, [enqueueSnackbar, productId]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  useEffect(() => {
    setMovement({
      date: new Date(),
      type: MovementType.ENTRY.code,
      updateCostType: UpdateCostType.ENTRY.code,
      updatePriceType: UpdatePriceType.MARGIN.code,
      updatePriceMinimumType: UpdatePriceMinimumType.MARGIN.code,
    });
    setNewPrices({
      newPrice: new BigNumber(product?.product?.saleValue),
      newCost: new BigNumber(product?.product?.cost),
      newPriceMinimum: new BigNumber(product?.product?.priceMinimum),
      newPriceMinimumMargin: calcNewPriceMinimumMargin(
        new BigNumber(product?.product?.priceMinimum),
        new BigNumber(product?.product?.cost)
      ),
    });
  }, [open]);

  const onSave = useCallback(async () => {
    try {
      setLoading(true);

      const { date } = movement;
      const newDate = moment(date).format();

      const request = {
        ...movement,
        ...newPrices,
        date: newDate,
        productId: product.product.id,
      };

      await InventoryMovementService.add(request);

      enqueueSnackbar(generalMessages.saveSuccess, {
        variant: "success",
        autoHideDuration: 3000,
      });
      onClose();
    } catch (error) {
      const { message, validationErrors } = handlingResponseErrors(error);
      enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
      if (validationErrors != null) {
        setErrors(validationErrors);
      }
    } finally {
      setLoading(false);
    }
  }, [movement, onClose, enqueueSnackbar, product, newPrices]);

  const handleChange = useCallback(
    (field, value) => {
      setMovement({
        ...movement,
        [field]: value,
      });
    },
    [movement]
  );

  const handleChangePrices = useCallback(
    (field, value) => {
      setNewPrices({
        ...newPrices,
        [field]: value,
      });
    },
    [newPrices]
  );

  const renderQuickAddPerson = () => (
    <Modal style={{ overflow: "auto" }} disableEscapeKeyDown open={quickAddPersonModalOpen}>
      <PersonForm
        name={onTypedChange}
        registrationTypeParam={RegistrationType.SUPPLIER.code}
        onCancel={() => setQuickAddPersonModalOpen(false)}
        afterSave={(Person) => handleChange("supplier", Person)}
      />
    </Modal>
  );

  useEffect(() => {
    let newPriceMinimum = new BigNumber(0);
    if (movement.updatePriceMinimumType === UpdatePriceMinimumType.MARGIN.code) {
      newPriceMinimum = calcNewPriceMinimum(
        new BigNumber(product?.product?.priceMinimum || 0),
        new BigNumber(newPrices?.newCost || 0),
        new BigNumber(product?.product?.cost || 0)
      );
    } else if (movement.updatePriceMinimumType === UpdatePriceMinimumType.NOT_UPDATE.code) {
      newPriceMinimum = product.product.priceMinimum;
    } else if (movement.updatePriceMinimumType === UpdatePriceMinimumType.CUSTOM.code) {
      newPriceMinimum = newPrices?.newPriceMinimum;
    }

    let newPrice = new BigNumber(0);
    if (movement.updatePriceType === UpdatePriceType.MARGIN.code) {
      newPrice = calcNewPrice(
        new BigNumber(newPrices?.newCost || 0),
        new BigNumber(product?.product?.margin || 0)
      );
    } else if (movement.updatePriceType === UpdatePriceType.NOT_UPDATE.code) {
      newPrice = product.product.saleValue;
    } else if (movement.updatePriceType === UpdatePriceType.CUSTOM.code) {
      newPrice = newPrices?.newPrice;
    }

    const newPriceMinimumMargin = calcNewPriceMinimumMargin(
      new BigNumber(newPrices?.newCost || 0),
      new BigNumber(product?.product?.cost || 0)
    );
    if (movement.type === MovementType.ENTRY.code) {
      setNewPrices({
        ...newPrices,
        newPrice,
        newPriceMinimum,
        newPriceMinimumMargin,
      });
    }
  }, [movement.type, newPrices.newCost, movement.updatePriceMinimumType, movement.updatePriceType]);

  useEffect(() => {
    if (movement.type === MovementType.ENTRY.code) {
      if (movement.updateCostType === UpdateCostType.ENTRY.code) {
        setNewPrices({
          ...newPrices,
          newCost: new BigNumber(movement?.costAverage || 0),
        });
      } else if (movement.updateCostType === UpdateCostType.NOT_UPDATE.code) {
        setNewPrices({
          ...newPrices,
          newCost: new BigNumber(product?.product?.cost || 0),
        });
      } else if (movement.updateCostType === UpdateCostType.MEDIUM.code) {
        setNewPrices({
          ...newPrices,
          newCost: calcNewCost(
            new BigNumber(movement?.quantity || 0),
            new BigNumber(movement?.costAverage || 0),
            new BigNumber(product?.quantity || 0),
            new BigNumber(product?.product?.cost || 0)
          ),
        });
      }
    }
  }, [movement.type, movement.quantity, movement.costAverage, movement.updateCostType]);

  const renderQuickAddText = () => (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      className={classesAutocompleteStyle.emptyContainer}
    >
      <Box>{autocompleteMessages.emptyText}</Box>
      <Box>
        <ButtonUI
          style={{ color: primaryColor[0] }}
          onMouseDown={() => {
            setQuickAddPersonModalOpen(true);
          }}
        >
          Adicionar
        </ButtonUI>
      </Box>
    </Box>
  );

  const renderOption = (props, item) => {
    const { id, name } = item;

    if (id === AUTOCOMPLETE.QUICK_ADD) {
      return renderQuickAddText();
    }

    return (
      <ListItem {...props}>
        <ListItemText primary={name} />
      </ListItem>
    );
  };

  const renderForm = () => (
    <>
      <GridItem xs={6} sm={4} md={4}>
        <ListItemText primary="Produto" secondary={product?.product?.name || ""} />
      </GridItem>
      <GridItem xs={6} sm={4} md={4}>
        <ListItemText primary="Estoque" secondary={product?.quantity || ""} />
      </GridItem>
      <GridItem xs={6} sm={4} md={4}>
        <ListItemText
          primary="Custo última entrada"
          secondary={currencyFormatter(product?.product?.lastCost || 0)}
        />
      </GridItem>
      <GridItem xs={6} sm={4} md={4}>
        <ListItemText
          primary="Custo médio"
          secondary={currencyFormatter(product?.product?.cost || 0)}
        />
      </GridItem>
      <GridItem xs={6} sm={4} md={4}>
        <ListItemText
          primary="Valor mínimo de venda"
          secondary={currencyFormatter(product?.product?.priceMinimum || 0)}
        />
      </GridItem>
      <GridItem xs={6} sm={4} md={4}>
        <ListItemText
          primary="Valor de venda"
          secondary={currencyFormatter(product?.product?.saleValue || 0)}
        />
      </GridItem>
      <Divider style={{ width: "100%", marginBottom: "16px" }} />
      <GridItem xs={12} sm={3} md={3}>
        <FormControl
          fullWidth
          className={classesCustomComponentsStyle.formControlSelect}
          variant="outlined"
          size="small"
        >
          <InputLabel>Tipo da movimentação</InputLabel>
          <Select
            label="Tipo da movimentação"
            id="type"
            autoWidth
            value={movement.type == null ? "" : movement.type}
            onChange={(value) => handleChange("type", value.target.value)}
            required
            error={errors.repeatType}
            helperText={errors.repeatType}
          >
            {Object.entries(MovementType).map((item) => {
              const repeat = item[1];
              return (
                <MenuItem key={repeat.code} value={repeat.code}>
                  {repeat.name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </GridItem>
      <GridItem xs={12} sm={12} md={3} style={{ marginTop: "12px" }}>
        <DatePicker
          id="date"
          label="Data"
          format="dd/MM/yyyy"
          slotProps={{ textField: { size: "small" } }}
          value={movement?.date ? moment(movement.date).valueOf() : null}
          onChange={(date) => handleChange("date", date)}
        />
      </GridItem>
      {movement.type !== MovementType.AVERAGE_COST.code && (
        <GridItem xs={12} sm={3} md={3}>
          <TextInput
            id="quantity"
            label="Quantidade"
            value={movement.quantity}
            onChange={handleChange}
            type="number"
            required
            errors={errors}
          />
        </GridItem>
      )}
      {movement.type === MovementType.ENTRY.code && (
        <>
          <GridItem xs={12} sm={3} md={3}>
            <CurrencyTextInput
              id="costAverage"
              label="Custo do produto"
              value={movement.costAverage}
              onChange={handleChange}
              required
              errors={errors}
            />
          </GridItem>
          <GridItem xs={12} sm={6} md={6}>
            <Box display="flex" flexGrow={1}>
              <Autocomplete
                width="100%"
                variant="outlined"
                fieldsToShowAfterSelectItem={["name"]}
                label="Fornecedor"
                placeholder="Digite para pesquisar os fornecedores..."
                service={PersonService}
                extraSearch={{ registrationType: RegistrationType.SUPPLIER.code }}
                value={movement.supplier}
                onChange={(value) => handleChange("supplier", value)}
                onTypedChange={(value) => setOnTypedChange(value)}
                required
                error={errors.supplier}
                renderOption={renderOption}
                quickAdd
              />
            </Box>
          </GridItem>
        </>
      )}
      {movement.type === MovementType.AVERAGE_COST.code && (
        <Grid container>
          <GridItem xs={12} sm={3} md={3}>
            <CurrencyTextInput
              id="newCost"
              label="Custo médio"
              value={newPrices?.newCost}
              onChange={handleChangePrices}
              required
              errors={errors}
            />
          </GridItem>
          <GridItem xs={12} sm={3} md={3}>
            <CurrencyTextInput
              id="newPriceMinimum"
              label="Novo preço mínimo"
              value={newPrices?.newPriceMinimum || 0}
              onChange={handleChangePrices}
              required
              errors={errors}
            />
          </GridItem>
          <GridItem xs={12} sm={3} md={3}>
            <CurrencyTextInput
              id="newPrice"
              label="Novo valor venda"
              value={newPrices?.newPrice || 0}
              onChange={handleChangePrices}
              required
              errors={errors}
            />
          </GridItem>
        </Grid>
      )}
      {movement.type === MovementType.ENTRY.code && (
        <>
          <GridContainer
            style={{
              width: "100%",
              borderRadius: "10px",
              backgroundColor: "antiquewhite",
              marginTop: "10px",
            }}
          >
            <GridItem
              xs={12}
              sm={4}
              md={4}
              style={{ height: "60px", marginTop: "10px", textAlign: "center" }}
            >
              <ListItemText
                primary="Novo valor de custo"
                secondary={currencyFormatter(newPrices?.newCost || "")}
              />
            </GridItem>
            <GridItem
              xs={12}
              sm={4}
              md={4}
              style={{ height: "60px", marginTop: "10px", textAlign: "center" }}
            >
              <ListItemText
                primary="Novo valor mínimo"
                secondary={currencyFormatter(newPrices?.newPriceMinimum || "")}
              />
            </GridItem>
            <GridItem
              xs={12}
              sm={4}
              md={4}
              style={{ height: "60px", marginTop: "10px", textAlign: "center" }}
            >
              <ListItemText
                primary="Novo valor de venda"
                secondary={currencyFormatter(newPrices?.newPrice || "")}
              />
            </GridItem>
          </GridContainer>

          <GridContainer style={{ marginTop: "10px" }}>
            <Accordion>
              <AccordionSummary
                expandIcon={<FontAwesomeIcon icon={faAngleDown} fontSize={20} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>Configurações avançadas</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <GridContainer>
                  <GridItem xs={12} sm={9} md={9} style={{ height: "70px" }}>
                    <FormControl
                      component="fieldset"
                      className={classesCustomComponentsStyle.formControlRadio}
                      size="small"
                    >
                      <FormLabel className={classesCustomComponentsStyle.formLabel}>
                        <span style={{ fontSize: 16, color: "#000000" }}>Atualizar custo por:</span>
                      </FormLabel>
                      <RadioGroup
                        row
                        name="updateCostType"
                        value={movement.updateCostType}
                        onChange={(value) =>
                          handleChange("updateCostType", value.currentTarget.value)
                        }
                      >
                        <FormControlLabel
                          value={UpdateCostType.ENTRY.code}
                          control={<Radio color="secondary" />}
                          style={{ minWidth: "260px" }}
                          label={
                            <Typography className={classesCustomComponentsStyle.formControlLabel}>
                              {UpdateCostType.ENTRY.name}
                            </Typography>
                          }
                        />
                        <FormControlLabel
                          value={UpdateCostType.MEDIUM.code}
                          control={<Radio color="secondary" />}
                          style={{ minWidth: "150px" }}
                          label={
                            <Typography className={classesCustomComponentsStyle.formControlLabel}>
                              {UpdateCostType.MEDIUM.name}
                            </Typography>
                          }
                        />
                        <FormControlLabel
                          value={UpdateCostType.NOT_UPDATE.code}
                          control={<Radio color="secondary" />}
                          label={
                            <Typography className={classesCustomComponentsStyle.formControlLabel}>
                              {UpdateCostType.NOT_UPDATE.name}
                            </Typography>
                          }
                        />
                      </RadioGroup>
                    </FormControl>
                  </GridItem>
                  <Divider style={{ width: "100%", marginTop: "5px", marginBottom: "5px" }} />
                  <GridItem xs={12} sm={9} md={9} style={{ height: "70px" }}>
                    <FormControl
                      component="fieldset"
                      className={classesCustomComponentsStyle.formControlRadio}
                      size="small"
                    >
                      <FormLabel className={classesCustomComponentsStyle.formLabel}>
                        <span style={{ fontSize: 16, color: "#000000" }}>
                          Atualizar preço mínimo de venda por:
                        </span>
                      </FormLabel>

                      <RadioGroup
                        row
                        name="updatePriceMinimumType"
                        value={movement?.updatePriceMinimumType}
                        onChange={(value) =>
                          handleChange("updatePriceMinimumType", value.currentTarget.value)
                        }
                      >
                        <FormControlLabel
                          value={UpdatePriceMinimumType.MARGIN.code}
                          control={<Radio color="secondary" />}
                          style={{ minWidth: "260px" }}
                          label={
                            <Typography className={classesCustomComponentsStyle.formControlLabel}>
                              {`${UpdatePriceMinimumType.MARGIN.name} (${newPrices?.newPriceMinimumMargin}%)`}
                            </Typography>
                          }
                        />
                        <FormControlLabel
                          value={UpdatePriceMinimumType.NOT_UPDATE.code}
                          control={<Radio color="secondary" />}
                          style={{ minWidth: "150px" }}
                          label={
                            <Typography className={classesCustomComponentsStyle.formControlLabel}>
                              {UpdatePriceMinimumType.NOT_UPDATE.name}
                            </Typography>
                          }
                        />
                        <FormControlLabel
                          value={UpdatePriceMinimumType.CUSTOM.code}
                          control={<Radio color="secondary" />}
                          label={
                            <Typography className={classesCustomComponentsStyle.formControlLabel}>
                              {UpdatePriceMinimumType.CUSTOM.name}
                            </Typography>
                          }
                        />
                      </RadioGroup>
                    </FormControl>
                  </GridItem>
                  {movement?.updatePriceMinimumType === UpdatePriceMinimumType.CUSTOM.code && (
                    <GridItem xs={12} sm={3} md={3}>
                      <CurrencyTextInput
                        id="newPriceMinimum"
                        label="Novo preço mínimo"
                        value={newPrices?.newPriceMinimum || 0}
                        onChange={handleChangePrices}
                        required
                        errors={errors}
                      />
                    </GridItem>
                  )}
                  <Divider style={{ width: "100%", marginTop: "5px", marginBottom: "5px" }} />
                  <GridItem xs={12} sm={9} md={9} style={{ height: "70px" }}>
                    <FormControl
                      component="fieldset"
                      className={classesCustomComponentsStyle.formControlRadio}
                      size="small"
                    >
                      <FormLabel className={classesCustomComponentsStyle.formLabel}>
                        <span style={{ fontSize: 16, color: "#000000" }}>
                          Atualizar preço de venda por:
                        </span>
                      </FormLabel>

                      <RadioGroup
                        row
                        name="updatePriceType"
                        value={movement?.updatePriceType}
                        onChange={(value) =>
                          handleChange("updatePriceType", value.currentTarget.value)
                        }
                      >
                        <FormControlLabel
                          value={UpdatePriceType.MARGIN.code}
                          control={<Radio color="secondary" />}
                          style={{ minWidth: "260px" }}
                          label={
                            <Typography className={classesCustomComponentsStyle.formControlLabel}>
                              {`${UpdatePriceType.MARGIN.name} (${product?.product?.margin}%)`}
                            </Typography>
                          }
                        />
                        <FormControlLabel
                          value={UpdatePriceType.NOT_UPDATE.code}
                          control={<Radio color="secondary" />}
                          style={{ minWidth: "150px" }}
                          label={
                            <Typography className={classesCustomComponentsStyle.formControlLabel}>
                              {UpdatePriceType.NOT_UPDATE.name}
                            </Typography>
                          }
                        />
                        <FormControlLabel
                          value={UpdatePriceType.CUSTOM.code}
                          control={<Radio color="secondary" />}
                          label={
                            <Typography className={classesCustomComponentsStyle.formControlLabel}>
                              {UpdatePriceType.CUSTOM.name}
                            </Typography>
                          }
                        />
                      </RadioGroup>
                    </FormControl>
                  </GridItem>
                  {movement?.updatePriceType === UpdatePriceType.CUSTOM.code && (
                    <GridItem xs={12} sm={3} md={3}>
                      <CurrencyTextInput
                        id="newPrice"
                        label="Novo valor venda"
                        value={newPrices?.newPrice || 0}
                        onChange={handleChangePrices}
                        required
                        errors={errors}
                      />
                    </GridItem>
                  )}
                </GridContainer>
              </AccordionDetails>
            </Accordion>
          </GridContainer>
        </>
      )}
    </>
  );

  return (
    <>
      <Dialog disableEscapeKeyDown open={open} fullWidth maxWidth="md">
        <DialogTitle>Nova movimentação</DialogTitle>
        <DialogContent>
          <GridContainer>{renderForm()}</GridContainer>
        </DialogContent>
        <DialogActions>
          <div style={{ flexGrow: 1, display: "flex", justifyContent: "space-between" }}>
            <Button style={{ color: primaryColor[0] }} onClick={onClose}>
              Cancelar
            </Button>
            <Button style={{ color: primaryColor[0] }} onClick={onSave}>
              Salvar
            </Button>
          </div>
        </DialogActions>
      </Dialog>
      <Loading loading={loading} />
      {renderQuickAddPerson()}
    </>
  );
};

export default React.memo(InventoryMovementModal);
