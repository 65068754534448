import React, { useState } from "react";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import AppRouter from "AppRouter";
import { BrowserRouter } from "react-router-dom";
import { SnackbarProvider } from "notistack";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { StyledEngineProvider, useMediaQuery } from "@mui/material"; // https://github.com/mui/material-ui/issues/29355#issuecomment-1563508973
import { ThemeProvider, createTheme, responsiveFontSizes } from "@mui/material/styles";

import locale from "date-fns/locale/pt-BR";

import UseContext from "./core/UserContext";

import keycloak from "./core/keycloak";

import "assets/css/material-dashboard-react.css?v=1.10.0";
import { breakpoints } from "./config/util";

const theme = createTheme({
  breakpoints,
});

let customTheme = createTheme({
  breakpoints: {
    ...breakpoints,
  },
  typography: {
    fontFamily: ["Nunito", "Roboto", "Helvetica", "Arial", "sans-serif"].join(","),
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          [theme.breakpoints.down("custom")]: {
            fontSize: "12px",
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          [theme.breakpoints.down("custom")]: {
            fontSize: "12px !important",
            height: "18px",
          },
        },
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        label: {
          [theme.breakpoints.down("custom")]: {
            fontSize: "12px !important",
          },
        },
      },
    },
    MuiInputLabel: {
      defaultProps: {
        sx: {
          [theme.breakpoints.down("custom")]: {
            fontSize: "12px !important",
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          [theme.breakpoints.down("custom")]: {
            padding: "8px 7px",
          },
        },
      },
      defaultProps: {
        sx: {
          [theme.breakpoints.down("custom")]: {
            fontSize: "12px !important",
          },
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        displayedRows: {
          [theme.breakpoints.down("custom")]: {
            fontSize: "12px !important",
          },
        },
      },
    },
  },
});

customTheme = responsiveFontSizes(customTheme);

// TODO Migrar LocalizationProvider (dayjs) https://stackoverflow.com/questions/71781235/change-name-of-month-in-material-ui-react

const App = () => {
  const [userLogged, setUserLogged] = useState(null);

  return (
    <ThemeProvider theme={customTheme}>
      <UseContext.Provider value={{ userLogged, setUserLogged }}>
        <StyledEngineProvider injectFirst>
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={locale}>
            <SnackbarProvider
              anchorOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              <ReactKeycloakProvider authClient={keycloak} initOptions={{ enableLogging: true }}>
                <BrowserRouter>
                  <AppRouter />
                </BrowserRouter>
              </ReactKeycloakProvider>
            </SnackbarProvider>
          </LocalizationProvider>
        </StyledEngineProvider>
      </UseContext.Provider>
    </ThemeProvider>
  );
};

export default App;
