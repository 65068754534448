/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useRef, useState } from "react";
import { makeStyles } from "@mui/styles";
import { ArrowKeyStepper, Table, Column } from "react-virtualized";
import VirtualizedListStyle from "./VirtualizedListStyle";
import "react-virtualized/styles.css";

const useStyle = makeStyles(VirtualizedListStyle);

const VirtualizedList = ({ list, columns, onSelectedItem, height = 400, emptyText, fnFocus }) => {
  const classes = useStyle();
  const tableRef = useRef(null);
  const containerRef = useRef(null);
  const [selectedRowIndex, setSelectedRowIndex] = useState(0);
  const rowCount = list.length;

  const getEmptyText = useCallback(() => {
    if (!emptyText) return "Nenhum item encontrado";
    return emptyText;
  }, [emptyText]);

  const onSelectRow = useCallback(
    (index) => {
      setSelectedRowIndex(index);
      if (onSelectedItem) {
        onSelectedItem(list[index]);
      }
    },
    [list, onSelectedItem]
  );

  const setFocus = useCallback(() => {
    console.log("VirtualizedList - setFocus");
    if (tableRef && tableRef.current) {
      // tableRef.current.childNodes[1].children[1].focus();
      const { className } = tableRef.current.Grid.props;
      const element = document.getElementsByClassName(className);
      if (element.length > 0 && element[0]) {
        element[0].focus();
      }
    }
  }, []);

  useEffect(() => {
    if (fnFocus) {
      fnFocus(setFocus);
    }
  }, [fnFocus, setFocus]);

  // useEffect(() => {
  //   setTimeout(() => setFocus(), 500);
  // }, [list, setFocus]);

  const getWidth = useCallback(() => {
    if (containerRef && containerRef.current) {
      return containerRef.current.offsetWidth;
    }
    return 0;
  }, [containerRef]);

  return (
    <div ref={containerRef}>
      <ArrowKeyStepper
        columnCount={1}
        rowCount={rowCount}
        isControlled
        mode="cells"
        scrollToRow={selectedRowIndex}
        scrollToColumn={0}
        onScrollToChange={({ scrollToRow }) => onSelectRow(scrollToRow)}
      >
        {({ onSectionRendered, scrollToRow }) => (
          <div style={{ display: "inline-block", width: "100%" }}>
            {rowCount > 0 ? (
              <Table
                ref={tableRef}
                height={height}
                width={getWidth()}
                headerHeight={40}
                rowHeight={50}
                rowCount={rowCount}
                rowGetter={({ index }) => list[index]}
                scrollToIndex={scrollToRow}
                onRowsRendered={({
                  overscanStartIndex,
                  overscanStopIndex,
                  startIndex,
                  stopIndex,
                }) => {
                  setFocus();
                  onSectionRendered({
                    rowStartIndex: startIndex,
                    rowStopIndex: stopIndex,
                  });
                }}
                onRowClick={({ index }) => onSelectRow(index)}
                rowClassName={({ index }) =>
                  selectedRowIndex === index ? [classes.row, classes.focusedRow] : classes.row
                }
              >
                {columns.map((col) => (
                  <Column label={col.label} dataKey={col.dataKey} width={col.width} flexGrow={1} />
                ))}
              </Table>
            ) : (
              <div
                style={{
                  height,
                  display: "flex",
                  textAlign: "center",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p>{getEmptyText()}</p>
              </div>
            )}
          </div>
        )}
      </ArrowKeyStepper>
    </div>
  );
};

export default React.memo(VirtualizedList);
