const DFModelo = {
  AVULSA: { code: "AVULSA", name: "Nota avulsa" },
  PRODUTOR: { code: "PRODUTOR", name: "Nota produtor" },
  NFE: { code: "NFE", name: "NF-e" },
  NFCE: { code: "NFCE", name: "NFC-e" },
  CTE: { code: "CTE", name: "CT-e" },
  MDFE: { code: "MDFE", name: "MDF-e" },
  CFE: { code: "CFE", name: "CF-e" },
  CTeOS: { code: "CTeOS", name: "CT-e OS" },
};

const NFeInutilizacaoStatusType = {
  CRIADA: {
    code: "CRIADA",
    name: "Criada",
  },
  TRANSMITIDA: {
    code: "TRANSMITIDA",
    name: "Transmitida",
  },
  ERRO: {
    code: "ERRO",
    name: "Erro",
  },
};

const NotaFiscalHistoricoTipoEventoType = {
  EMISSAO: {
    code: "EMISSAO",
    name: "Emissão",
  },
  CANCELAMENTO: {
    code: "CANCELAMENTO",
    name: "Cancelamento",
  },
  INUTILIZACAO: {
    code: "INUTILIZACAO",
    name: "Inutilização",
  },
  MANIFESTACAO: {
    code: "MANIFESTACAO",
    name: "Manifestação",
  },
};

class NFeInutilizacao {
  constructor(source = {}) {
    this.id = source.id;
    this.serie = source.serie;
    this.modelo = source.modelo;
    this.justificativa = source.justificativa;
    this.numeroInicial = source.numeroInicial;
    this.numeroFinal = source.numeroFinal;
    this.version = source.version;
    this.active = source.active;
    this.status = source.status;
  }
}

export { NFeInutilizacao, DFModelo, NFeInutilizacaoStatusType, NotaFiscalHistoricoTipoEventoType };
