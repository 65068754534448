/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React, { useState, useEffect, useCallback } from "react";
import { makeStyles } from "@mui/styles";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import { useHistory } from "react-router-dom";
import CardStyle from "assets/jss/material-dashboard-react/components/cardStyle";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  Chip,
  FormControlLabel,
  TextField,
  Typography,
  Autocomplete as AutocompleteMUI,
  InputAdornment,
} from "@mui/material";
import customComponentsStyle from "assets/jss/material-dashboard-react/components/customComponentsStyle";

import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import TextInput from "components/textInput/TextInput";
import CurrencyTextInput from "components/textInput/CurrencyTextInput";
import SelectEnum from "components/selectEnum/SelectEnum";
import {
  UpdateCostType,
  UpdatePriceType,
  UpdatePriceMinimumType,
} from "views/inventory/model/InventoryProduct";
import BigNumber from "bignumber.js";
import { primaryColor } from "assets/jss/material-dashboard-react";
import { currencyFormatter } from "../../../../config/util";

const useCardStyle = makeStyles(CardStyle);

const UpdateCostTypeEnum = {
  ENTRY: UpdateCostType.ENTRY,
  MEDIUM: UpdateCostType.MEDIUM,
  NOT_UPDATE: UpdateCostType.NOT_UPDATE,
};

const UpdatePriceMinimumTypeEnum = {
  MARGIN: UpdatePriceMinimumType.MARGIN,
  CUSTOM: UpdatePriceMinimumType.CUSTOM,
  NOT_UPDATE: UpdatePriceMinimumType.NOT_UPDATE,
};

const UpdatePriceTypeEnum = {
  MARGIN: UpdatePriceType.MARGIN,
  CUSTOM: UpdatePriceType.CUSTOM,
  NOT_UPDATE: UpdatePriceType.NOT_UPDATE,
};

const NFeLancarEstoqueProdutoExistente = ({ item, errors, handleChange, unitConversions }) => {
  const classesuseCardStyle = useCardStyle();
  const useCustomComponentsStyle = makeStyles(customComponentsStyle);
  const classesCustomComponentsStyle = useCustomComponentsStyle();
  const history = useHistory();

  const { id: index, produto, notaFiscal, produtoJaImportado } = item;

  function filterConversion(target, source, qtdImportada, qtdProduto) {
    let unitConversion = unitConversions?.filter(i => i?.targetUnit?.acronym === target && i?.sourceUnit?.acronym === source);
    if(unitConversion.length === 0) {
      unitConversion = [{"conversionFactor": qtdImportada?.dividedBy(qtdProduto)}]
    }
    handleChange("unidadeConversao", unitConversion, index);
    return unitConversion;
  }

  // Calculo novo custo médio
  useEffect(async () => {
    let {custo} = produto;

    if(produto?.id) {
      const unidadeMedidaProduto = produto?.unitMeasurement?.acronym;
      const unidadeMedidaProdutoNaNotaFiscal = item?.notaFiscal?.unidadeComercial;
      let valorUnitarioNaUnidadeMedidaDoProduto = new BigNumber(notaFiscal.valorUnitario);
      let quantidadeComercialNaUnidadeMedidaDoProduto = new BigNumber(notaFiscal.quantidadeComercial);

      if (unidadeMedidaProduto !== unidadeMedidaProdutoNaNotaFiscal) {
        const result = filterConversion(unidadeMedidaProduto, unidadeMedidaProdutoNaNotaFiscal, new BigNumber(item.quantidadeImportada), quantidadeComercialNaUnidadeMedidaDoProduto);
        const fatorConversao = new BigNumber(result[0]?.conversionFactor);

        valorUnitarioNaUnidadeMedidaDoProduto = valorUnitarioNaUnidadeMedidaDoProduto.dividedBy(fatorConversao);
        quantidadeComercialNaUnidadeMedidaDoProduto = quantidadeComercialNaUnidadeMedidaDoProduto.multipliedBy(fatorConversao);
      }

      if (item.updateCostType === UpdateCostType.ENTRY.code) {
        custo = valorUnitarioNaUnidadeMedidaDoProduto;
      } else if (item.updateCostType === UpdateCostType.MEDIUM.code) {
        const quantidade = produto?.inventory?.quantity || 0;
        const totalNF = new BigNumber(valorUnitarioNaUnidadeMedidaDoProduto).multipliedBy(quantidadeComercialNaUnidadeMedidaDoProduto);
        const totalExistente = new BigNumber(custo).multipliedBy(quantidade);

        const quantidadeTotal = quantidadeComercialNaUnidadeMedidaDoProduto.plus(quantidade);
        const total = totalNF.plus(totalExistente);
        const custoMedio = total.dividedBy(quantidadeTotal);
        custo = custoMedio.toNumber();
      }
    } else {
      custo = new BigNumber(notaFiscal.valorUnitario)
    }
    handleChange("custoMedio", custo, index);
  }, [
    handleChange,
    index,
    item.quantidadeComercial,
    item.updateCostType,
    item.valorUnitario,
    notaFiscal.quantidadeComercial,
    notaFiscal.valorUnitario,
    produto,
    unitConversions,
    item.quantidadeImportada,
  ]);

  // Calculo novo valor mínimo
  useEffect(() => {
    // [(valor no momento posterior ÷ valor no momento anterior) - 1] × 100
    const { custo } = produto;
    let { precoMinimo } = produto;

    if (item.updatePriceMinimumType === UpdatePriceMinimumType.MARGIN.code) {
      if (custo === 0) {
        precoMinimo = 0;
      } else {
        const percentual = new BigNumber(item.custoMedio).dividedBy(custo).minus(1);

        const priceMinimumBN = new BigNumber(precoMinimo);
        const valorPercentual = priceMinimumBN.multipliedBy(percentual);
        precoMinimo = priceMinimumBN.plus(valorPercentual).toNumber();
      }
    }

    handleChange("precoMinimo", precoMinimo, index);
  }, [handleChange, index, item.custoMedio, item.updatePriceMinimumType, produto]);

  // Calculo novo valor venda
  useEffect(() => {
    // [(valor no momento posterior ÷ valor no momento anterior) - 1] × 100
    const { custo } = produto;
    let { preco } = produto;

    if (item.updatePriceType === UpdatePriceTypeEnum.MARGIN.code) {
      if (custo === 0) {
        preco = 0;
      } else {
        const percentual = new BigNumber(item.custoMedio).dividedBy(custo).minus(1);

        const saleValueBN = new BigNumber(preco);
        const valorPercentual = saleValueBN.multipliedBy(percentual);
        preco = saleValueBN.plus(valorPercentual).toNumber();
      }
    }

    handleChange("preco", preco, index);
  }, [
    handleChange,
    index,
    item.custoMedio,
    item.updatePriceMinimumType,
    item.updatePriceType,
    produto,
  ]);

  useEffect(() => {
    handleChange("unidadeImportada", produto?.unitMeasurement?.acronym || item?.notaFiscal?.unidadeComercial, index);
    if(produto?.unitMeasurement?.acronym) {
      const quantidadeComercial = new BigNumber(item?.notaFiscal?.quantidadeComercial);
      const result = filterConversion(produto?.unitMeasurement?.acronym, item?.notaFiscal?.unidadeComercial, new BigNumber(item.quantidadeImportada), quantidadeComercial);
      const fatorConversao = new BigNumber(result[0]?.conversionFactor);
      const quantidadeImportada = quantidadeComercial.multipliedBy(fatorConversao);
      handleChange("quantidadeImportada", quantidadeImportada, index);
    } else {
      handleChange("quantidadeImportada", 0, index);
    }
  }, [handleChange, index, produto, unitConversions]);

  const marker = () => (
    <Box style={{ display: "flex", justifyContent: "flex-end" }}>
      <Chip
        size="small"
        label={`#${item.counter} Existente`}
        style={{
          color: "#ffffff",
          backgroundColor: "#2196f3",
          borderColor: "#2196f3",
        }}
        variant="outlined"
      />
    </Box>
  );

  const importedMarker = () => (
    <Box style={{ display: "flex", justifyContent: "flex-end" }}>
      <Chip
        size="small"
        label="Estoque lançado"
        style={{
          color: "#ffffff",
          backgroundColor: "#2196f3",
          borderColor: "#2196f3",
        }}
        variant="outlined"
      />
    </Box>
  );

  const renderTextTop = (text, value) => (
    <>
      <Typography style={{ fontWeight: 500, fontSize: 18 }}>{text}</Typography>
      <Typography style={{ fontWeight: 500, fontSize: 18, color: "#626262" }}>
        {currencyFormatter(value)}
      </Typography>
    </>
  );

  const renderTextBottom = (text, value) => (
    <>
      <Typography style={{ fontWeight: 500, fontSize: 18 }}>{text}</Typography>
      <Typography style={{ fontWeight: 500, fontSize: 18, color: "#31dc31" }}>
        {currencyFormatter(value)}
      </Typography>
    </>
  );

  const renderCustoMedio = () => (
    <GridItem xs={12} sm={12} md={4}>
      {renderTextTop("Custo médio", produto.custo)}
      <SelectEnum
        label="Atualizar custo por"
        field="updateCostType"
        Enum={UpdateCostTypeEnum}
        valueSelected={item.updateCostType}
        handleChange={(field, value) => handleChange(field, value, index)}
        errors={errors}
      />
      {renderTextBottom("Novo custo médio", item.custoMedio)}
    </GridItem>
  );

  const renderValorMinimoVenda = () => (
    <GridItem xs={12} sm={12} md={4} style={{ borderLeft: "solid 1px", borderColor: "#c2c2c2" }}>
      {renderTextTop("Valor mínimo de venda", produto.precoMinimo)}
      <SelectEnum
        label="Atualizar preço mínimo de venda por"
        field="updatePriceMinimumType"
        Enum={UpdatePriceMinimumTypeEnum}
        valueSelected={item.updatePriceMinimumType}
        handleChange={(field, value) => handleChange(field, value, index)}
        errors={errors}
      />

      {UpdatePriceMinimumTypeEnum.CUSTOM.code === item.updatePriceMinimumType ? (
        <CurrencyTextInput
          id="precoMinimo"
          label="Novo preço mínimo"
          value={item.precoMinimo}
          onChange={(field, value) => handleChange(field, value, index)}
          errors={errors}
        />
      ) : (
        renderTextBottom("Novo valor mínimo", item.precoMinimo)
      )}
    </GridItem>
  );

  const renderValorVenda = () => (
    <GridItem xs={12} sm={12} md={4} style={{ borderLeft: "solid 1px", borderColor: "#c2c2c2" }}>
      {renderTextTop("Valor de venda", produto.preco)}
      <SelectEnum
        label="Atualizar preço de venda por"
        field="updatePriceType"
        Enum={UpdatePriceTypeEnum}
        valueSelected={item.updatePriceType}
        handleChange={(field, value) => handleChange(field, value, index)}
        errors={errors}
      />
      {UpdatePriceTypeEnum.CUSTOM.code === item.updatePriceType ? (
        <CurrencyTextInput
          id="preco"
          label="Novo preço venda"
          value={item.preco}
          onChange={(field, value) => handleChange(field, value, index)}
          errors={errors}
        />
      ) : (
        renderTextBottom("Novo valor de venda", item.preco)
      )}
    </GridItem>
  );

  const handleChangeProdutoEncontrado = useCallback(
    (value, i) => {
      let itemSelected = {
        id: null,
        custo: 0,
        precoMinimo: 0,
        preco: 0,
        quantidade: 0,
      };
      if (value != null) {
        itemSelected = value;
      }
      handleChange("produto", itemSelected, i);
    },
    [handleChange]
  );

  const getLabelProdutosEncontrados = (itemProd) => {
    if (itemProd == null) return "";

    const { codigoFabricante, name, marca } = itemProd;
    const options = [];
    if (codigoFabricante) {
      options.push(codigoFabricante);
    }
    options.push(name);
    if (marca) {
      options.push(marca);
    }
    return options.join(" - ");
  };

  const renderProdutosEncontrados = () => (
    <GridItem xs={12} sm={12} md={4} style={{ marginBottom: 8 }}>
      <AutocompleteMUI
        disableListWrap
        id="produto"
        options={item.produtosEncontrados}
        size="small"
        getOptionLabel={getLabelProdutosEncontrados}
        value={item.produto == null ? "" : item.produto}
        onChange={(ev, value) => handleChangeProdutoEncontrado(value, index)}
        renderInput={(params) => (
          <TextField
            {...params}
            // autoFocus
            className={classesCustomComponentsStyle.textField}
            style={{ width: "100%" }}
            label="Produtos encontrados"
            variant="outlined"
            required
            // error={errors?.naturezaOperacao}
          />
        )}
      />
    </GridItem>
  );

  return (
    <Accordion
      style={{ flexGrow: 1 }}
      expanded={produtoJaImportado ? false : item.expandido}
      onChange={(ev) => ev.stopPropagation()}
    >
      <AccordionSummary
        style={{ cursor: "default" }}
        expandIcon={
          !produtoJaImportado && (
            <FontAwesomeIcon
              icon={faAngleDown}
              fontSize={22}
              cursor="pointer"
              onClick={() => handleChange("expandido", !item.expandido, index)}
            />
          )
        }
        aria-controls="panel1a-content"
      >
        {!produtoJaImportado && (
          <FormControlLabel
            control={
              <Checkbox
                color="secondary"
                checked={item.importarProduto}
                onClick={(ev) => {
                  ev.stopPropagation();
                  handleChange("importarProduto", ev.target.checked, index);
                }}
                style={{
                  color: primaryColor[0],
                }}
              />
            }
          />
        )}
        <GridContainer style={{ width: "100%" }}>
          <GridItem xs={12} sm={12} md={6}>
            <Typography style={{ display: "flex", alignItems: "center", flexGrow: 1 }}>
              {notaFiscal.descricao}
            </Typography>
          </GridItem>
          <GridItem xs={12} sm={12} md={3}>
            <Typography style={{ display: "flex", alignItems: "center", marginRight: 6 }}>
              {`Quantidade: ${notaFiscal.quantidadeComercial} (${notaFiscal.unidadeComercial})`}
            </Typography>
          </GridItem>

          <GridItem xs={12} sm={12} md={2}>
            {item.produtoJaImportado ? importedMarker() : marker()}
          </GridItem>

          {!item.produtoJaImportado && (
            <>
              {renderProdutosEncontrados()}
              <GridItem xs={12} sm={12} md={3}>
                <TextInput
                  style={{ marginTop: 12 }}
                  id="quantidadeImportada"
                  label="Quantidade a lançar"
                  value={item.quantidadeImportada}
                  onChange={(field, value) => handleChange(field, value, index)}
                  disabled={item?.unidadeConversao?.[0]?.sourceUnit}
                  errors={errors}
                  type="number"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">{item.unidadeImportada || item?.notaFiscal?.unidadeComercial}</InputAdornment>
                    ),
                  }}
                />
              </GridItem>
            </>
          )}
        </GridContainer>
      </AccordionSummary>
      <AccordionDetails>
        <GridContainer style={{ flexGrow: 1 }}>
          {renderCustoMedio()}
          {renderValorMinimoVenda()}
          {renderValorVenda()}
        </GridContainer>
      </AccordionDetails>
    </Accordion>
  );
};

export default React.memo(NFeLancarEstoqueProdutoExistente);
