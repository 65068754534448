/* eslint-disable no-new */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useCallback } from "react";
import { Box, Button, Link, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDollyFlatbed, faLock } from "@fortawesome/free-solid-svg-icons";
import { primaryColor } from "../../../assets/jss/material-dashboard-react";
import ReportService from "../service/ReportService";
import Loading from "../../../components/loading/Loading";
import MESSAGES from "../../../config/messages";

const { reportMessages, generalMessages } = MESSAGES;

// eslint-disable-next-line react/prop-types
export default function StockReport({ locked }) {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);

  const goReport = useCallback(async () => {
    try {
      setLoading(true);

      const response = await ReportService.printProducts();

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `Relatório de produtos.pdf`);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      if (
        error.request.responseType === "blob" &&
        error.response.data instanceof Blob &&
        error.response.data.type &&
        error.response.data.type.toLowerCase().indexOf("json") !== -1
      ) {
        new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = () => {
            const message = error.response.status === 428 ? reader?.result : generalMessages.error;
            enqueueSnackbar(message, {
              variant: "error",
              autoHideDuration: 3000,
            });
          };

          reader.onerror = () => {
            reject(error);
          };

          reader.readAsText(error.response.data);
        });
      }
    } finally {
      setLoading(false);
    }
  }, [enqueueSnackbar]);

  return (
    <Box display="flex" alignItems="center" padding="12px" width="100%">
      <Box style={{ width: 50 }}>
        <FontAwesomeIcon
          icon={faDollyFlatbed}
          color={primaryColor[0]}
          fontSize={35}
          style={{ paddingRight: 20 }}
        />
      </Box>
      <Box display="flex" justifyContent="space-between" width="100%" alignItems="center">
        <Box>
          <Typography style={{ fontSize: 16 }}> {reportMessages.products}</Typography>
        </Box>
        <Box>
          {locked ? (
            <FontAwesomeIcon
              icon={faLock}
              fontSize={30}
              color={primaryColor[0]}
              style={{ marginRight: 15 }}
            />
          ) : (
            <Button style={{ color: primaryColor[0] }} onClick={goReport}>
              Gerar
            </Button>
          )}
        </Box>
      </Box>

      <Loading loading={loading} />
    </Box>
  );
}
