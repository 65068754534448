/* eslint-disable react/prop-types */
import React, { useContext } from "react";
import { makeStyles } from "@mui/styles";
import CustomComponentsStyle from "assets/jss/material-dashboard-react/components/customComponentsStyle";
import GridItem from "components/Grid/GridItem";
import InputMask from "react-input-mask";
import { TextField } from "@mui/material";
import TextInput from "../../components/textInput/TextInput";
// import CurrencyTextInput from "../../components/textInput/CurrencyTextInput";

import UserContext from "../../core/UserContext";
import ProfileBusiness from "../company/model/ProfileBusiness";

// const { generalMessages, productMessages } = MESSAGES;

const useCustomComponentsStyle = makeStyles(CustomComponentsStyle);

export default function ExtraFields({ extraFieldsValue = {}, onChange, errors = {} }) {
  const { userLogged } = useContext(UserContext);
  const classesCustomComponentsStyle = useCustomComponentsStyle();

  const getMotorcycleMechanic = () => (
    <>
      <GridItem xs={12} sm={12} md={12}>
        <TextInput
          id="veiculo"
          label="Veiculo"
          value={extraFieldsValue.veiculo}
          onChange={onChange}
        />
      </GridItem>
      <GridItem xs={12} sm={12} md={4}>
        <InputMask
          // mask="aaa9*99"
          disabled={false}
          value={extraFieldsValue.placa}
          onChange={(value) => onChange("placa", value.currentTarget.value?.toUpperCase())}
        >
          <TextField
            size="small"
            variant="outlined"
            id="placa"
            label="Placa"
            fullWidth
            InputLabelProps={{
              className: classesCustomComponentsStyle.labelTextField,
            }}
            className={classesCustomComponentsStyle.textField}
          />
        </InputMask>
      </GridItem>

      <GridItem xs={12} sm={12} md={4}>
        <TextInput id="ano" label="Ano" value={extraFieldsValue.ano} onChange={onChange} />
      </GridItem>
      <GridItem xs={12} sm={12} md={4}>
        <TextInput id="km" label="KM" value={extraFieldsValue.km} onChange={onChange} />
      </GridItem>
    </>
  );

  const renderByProfileBusiness = () => {
    const profile = userLogged?.profileBusiness;
    switch (profile) {
      case ProfileBusiness.MOTORCYCLE_MECHANIC.code:
        return getMotorcycleMechanic();
      case ProfileBusiness.CAR_MECHANIC.code:
        return getMotorcycleMechanic();
      default:
        return <></>;
    }
  };

  return renderByProfileBusiness();
}
