/* eslint-disable react/prop-types */
import React from "react";

const YoutubeEmbed = ({ embedId, width = "400vh", height = "300vh" }) => (
  <div className="video-responsive">
    <iframe
      style={{ aspectRatio: "16 / 9", width: "100%" }}
      width={width}
      height={height}
      src={`https://www.youtube.com/embed/${embedId}`}
      border="0px"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title="Embedded youtube"
    />
  </div>
);

export default YoutubeEmbed;
